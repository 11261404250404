<template>
   <div class="addon-information-summary-wrapper hotel-order-detail-inner-section">
      <div class="addon-information-summary-inner">
         <div class="addon-information-table-wrapper">
            <div class="addon-information-table-inner">
               <div class="addon-information-table-header">
                  <div class="table-header-fixed-item">
                     <span>{{ $t("orderDetail_lblPassengerInfoRoomType") }}</span>
                  </div>
                  <div class="table-header-list">
                     <span>{{ $t("orderDetail_lblAdultCount") }}</span>
                     <span>{{ $t("orderDetail_lblChildCount") }}</span>
                     <span>{{ $t("orderDetail_lblEventDate") }}</span>
                     <span>{{ $t("orderDetail_lblAddonPrice") }}</span>
                     <span>{{ $t("orderDetail_lblAddon") }}</span>
                  </div>
               </div>
               <div class="addon-information-table-content">
                  <div class="addon-information-room-item" v-for="(roomItem, index) in addons" :key="index">
                     <div class="table-content-fixed-item">
                        <span>{{ roomItem.roomTypeName }}</span>
                     </div>
                     <div class="table-content-list">
                        <ul class="addon-information-list default-list">
                           <li class="addon-information-list-item">
                              <span>{{ roomItem.adultCount }}</span>
                              <span>{{ roomItem.childCount == 0 ? "-" : roomItem.childCount }}</span>
                              <span>{{ formatDate(roomItem.effectiveDate) }} </span>
                              <span>{{ roomItem.price | price(roomItem.currecy) }}</span>
                              <button @click="toggleAddon(index)">
                                 {{ $t("orderDetail_lblViewAddon") }}
                              </button>
                           </li>
                        </ul>
                        <div class="addOnCard">
                           <AddonInformationCard v-if="isCardVisible[index]" :addonInformations="roomItem" @close-popover="toggleAddon(index)" />
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>
import moment from "moment";
import AddonInformationCard from "./AddonInformationCard.vue";

export default {
   name: "AddonInformationSummary",
   props: {
      addons: { type: Array },
   },
   components: { AddonInformationCard },
   data() {
      return {
         isCardVisible: [], // Her roomItem için ayrı görünürlük durumu
      };
   },
   methods: {
      toggleAddon(index) {
         this.isCardVisible = this.isCardVisible.map((item, i) => {
            if (i === index) {
               return !item; // Aynı karta tekrar basıldığında aç/kapa
            } else {
               return false; // Diğer tüm kartları kapat
            }
         });
      },
      formatDate(date) {
         return moment(date).format("DD.MM.YYYY");
      },
   },
   created() {
      // Başlangıçta tüm kartları kapalı yapıyoruz
      this.isCardVisible = Array(this.addons.length).fill(false);
   },
};
</script>
<style lang="scss" scoped>
#addOnBtn {
   width: 50%;
   height: 1rem !important;
   font-size: 12px;
}
.addon-information-summary-wrapper {
   .addon-information-summary-inner {
      .addon-information-table-wrapper {
         .addon-information-table-inner {
            display: block;
            border: 1px solid #c4c4c4;
            border-radius: 10px;
            overflow: hidden;
            .addon-information-table-header {
               display: flex;
               justify-content: flex-start;
               align-items: center;
               padding: 16px 42px;
               background-color: #ededed;
               border-bottom: 1px solid #c4c4c4;

               span {
                  font-size: 14px;
                  color: #868788;
               }
               .table-header-fixed-item {
                  width: 15%;
                  max-width: 15%;
                  margin-right: 24px;
               }
               .table-header-list {
                  display: grid;
                  grid-template-columns: repeat(5, 1fr);
                  width: 85%;
                  padding: 0 32px;
               }
            }
            .addon-information-table-content {
               .addon-information-room-item {
                  display: flex;
                  justify-content: flex-start;
                  align-items: stretch;
                  padding: 16px 42px;
                  background-color: white;

                  &:last-child {
                     border-bottom: none;
                  }
                  .table-content-fixed-item {
                     width: 15%;
                     max-width: 15%;
                     margin-right: 24px;

                     & > span {
                        display: inline-flex;
                        justify-content: flex-start;
                        align-items: center;
                        width: 100%;
                        height: 100%;
                        font-weight: 700;
                     }
                  }
                  .table-content-list {
                     width: 85%;

                     .addon-information-list {
                        .addon-information-list-item {
                           display: grid;
                           grid-template-columns: repeat(5, 1fr);
                           align-items: center;
                           // border: 1px solid;
                           border-radius: 20px;
                           margin-bottom: 8px;
                           padding: 10px 32px;
                           font-weight: 500;

                           &:last-child {
                              margin-bottom: 0;
                           }

                           // &:nth-child(odd) {
                           //    background-color: #f5f5f5;
                           //    border-color: #f5f5f5;
                           // }
                           // &:nth-child(even) {
                           //    background-color: white;
                           //    border-color: #e2e2e2;
                           // }

                           & > span:last-child {
                              display: inline-flex;
                              justify-content: flex-start;
                           }
                           button {
                              background: #ffe3ff;
                              color: #da00e6;
                              font-weight: 600;
                              font-size: 14px;
                              border-radius: 8px;
                              width: fit-content;
                              padding: 4px 8px;
                           }
                        }
                     }
                     .addOnCard {
                        z-index: 1000;
                        position: absolute;
                        right: 0;
                        transform: translateX(3rem);
                     }
                  }
               }
            }
         }
         @media (max-width: 767.98px) {
            .addon-information-table-inner {
               display: flex;

               .addon-information-table-header {
                  display: flex;
                  flex-direction: column;
                  padding: 16px 0px;
                  .table-header-fixed-item {
                     margin-right: 0px;
                  }
                  .table-header-list {
                     display: flex;
                     height: 100%;
                     flex-direction: column;
                     width: auto;
                     justify-content: center;
                     & > span {
                        text-align: center !important;
                        margin-bottom: 8px;
                     }
                  }
               }
               .addon-information-table-content {
                  .addon-information-room-item {
                     flex-direction: column;
                     .table-content-fixed-item {
                        margin-bottom: 24px;
                        border-bottom: 1px solid #bfbfbf;
                        border-right: 0;
                        width: auto;
                        max-width: -webkit-fill-available;

                        &:last-child {
                           width: 85%;
                           max-width: 85%;
                           margin-top: 16px;
                        }
                     }
                     .addon-information-list.default-list {
                        .addon-information-list-item {
                           display: flex;
                           flex-direction: column;
                        }
                     }
                  }
               }
            }
         }
      }
   }
}
</style>
