<template>
   <div class="reissue-price-viewer-container">
      <div class="price-viewer-wrapper">
         <ul>
            <!-- <li>
               <span>Reissue Total Cost</span>
               <span>50.00€</span>
            </li> -->
            <li>
               <span>{{ $t("ancillariesServicesModal_lblTotalSalesAmount") }}</span>
               <span>{{ paymentChoice.totalAmount | price }}</span>
            </li>
            <li v-if="false">
               <span>{{ $t("ancillariesServicesModal_lblAgencyExtraComm") }}</span>
               <span>
                  <UIInput
                     v-model="ancillaryExtraCommission"
                     label=""
                     v-input="{ type: 'numeric', range: [0, 99], required: false, requiredMsg: 'dynamicErrorMessage' }"
               /></span>
            </li>
         </ul>
      </div>
   </div>
</template>

<script>
import UIInput from "@/components/unitary/form2/UIInput.vue";
export default {
   name: "AncillaryReissuePriceView",
   props: {
      paymentChoice: Object,
   },
   components: {
      UIInput,
   },
   data() {
      return {
         ancillaryExtraCommission: 0,
      };
   },
};
</script>

<style lang="scss" scoped>
.reissue-price-viewer-container {
   width: 100%;
   display: flex;
   justify-content: flex-end;
   .price-viewer-wrapper {
      // max-width: 350px;
      width: 300px;
      ul {
         border: 1px solid #d7d9db;
         border-radius: 0.75rem;
         padding: 0.5rem 1rem;
         li {
            display: flex;
            justify-content: space-between;
            border-bottom: 1px dotted #d7d9db;
            padding: 0.5rem 0;

            &:last-child {
               border: none !important;
            }
         }
      }
   }
}
</style>
