import Vue from "vue";
import "@/directives/validator-helpers.js";
import Validator from "@/directives/validator-functions";

// ReadMe
const validator = {
   bind: (el, binding, vnode) => {
      Validator.Initialize(el, binding, vnode);
   },

   unbind: (el, binding, vnode) => {
      Validator.Dispose(el, binding, vnode);
   },
};

Vue.directive("input", validator);
