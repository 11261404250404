<template>
   <div class="route-flight-number">
      <UIDatePicker
         :index="legIndex"
         :label="$t('orderDetail_transferDate')"
         :isDisabled="!editMode"
         :min="minDate"
         isUpdateMode
         v-input="{
            type: 'date',
            required: true,
            requiredMsg: $t('common_cannotBeEmpty'),
         }"
         @input="handlerTransferDate"
         v-model="leg.pickupDateTime" />
      <div v-if="leg.fromLocationType == 9 || leg.toLocationType == 9">
         <template v-if="leg.fromLocationType != 9 && leg.toLocationType == 9">
            <div class="time-picker-inputs">
               <UIInput
                  v-model="leg.departureFlightNumber"
                  inputModel="departure"
                  :index="legIndex"
                  @update-mode="handlerFlightNumberInput"
                  :label="setInputLabels(leg.toLocationType, leg.fromLocationType)"
                  :placeholder="'TK1234'"
                  v-input="{
                     type: 'alphanumeric',
                     len: [4, 9],
                     required: true,
                     requiredMsg: $t('common_cannotBeEmpty'),
                  }"
                  :disabled="!editMode"
                  updateMode />
               <TimePicker
                  :label="`${$t('TransferCheckout_lblDeparture')}`"
                  :type="`departure-${legIndex}`"
                  :disabled="!editMode"
                  :pickupDateTime="leg.departureFlightDateTime"
                  isUpdate
                  @pickup="handlerPickUpDate" />
            </div>
         </template>
         <template v-else-if="leg.fromLocationType == 9 && leg.toLocationType != 9">
            <div class="time-picker-inputs">
               <UIInput
                  v-model="leg.arrivalFlightNumber"
                  inputModel="arrival"
                  :index="legIndex"
                  updateMode
                  @update-mode="handlerFlightNumberInput"
                  :label="setInputLabels(leg.toLocationType, leg.fromLocationType)"
                  v-input="{
                     type: 'alphanumeric',
                     len: [4, 9],
                     required: true,
                     requiredMsg: $t('common_cannotBeEmpty'),
                  }"
                  :placeholder="'TK1234'"
                  :disabled="!editMode" />
               <TimePicker
                  :label="`${$t('TransferCheckout_lblArrival')}`"
                  :type="`arrival-${legIndex}`"
                  :disabled="!editMode"
                  isUpdate
                  :pickupDateTime="leg.arrivalFlightDateTime"
                  @pickup="handlerPickUpDate" />
            </div>
         </template>
         <template v-else>
            <div class="time-picker-inputs">
               <UIInput
                  id="arrivalFlightNumber"
                  v-model="leg.arrivalFlightNumber"
                  inputModel="arrival"
                  :index="legIndex"
                  updateMode
                  @update-mode="handlerFlightNumberInput"
                  :label="`${$t('common_arrivalFlightNumber')}`"
                  v-input="{
                     type: 'alphanumeric',
                     len: [4, 9],
                     required: true,
                     requiredMsg: $t('common_cannotBeEmpty'),
                  }"
                  :placeholder="'TK1234'"
                  :disabled="!editMode" />
               <div class="time-picker-margin">
                  <TimePicker
                     :label="`${$t('TransferCheckout_lblArrival')}`"
                     :type="`arrival-${legIndex}`"
                     :disabled="!editMode"
                     isUpdate
                     :pickupDateTime="leg.arrivalFlightDateTime"
                     @pickup="handlerPickUpDate" />
               </div>
               <UIInput
                  id="departureFlightNumber"
                  v-model="leg.departureFlightNumber"
                  inputModel="departure"
                  :index="legIndex"
                  updateMode
                  @update-mode="handlerFlightNumberInput"
                  :label="`${$t('common_departureFlightNumber')}`"
                  v-input="{
                     type: 'alphanumeric',
                     len: [4, 9],
                     required: true,
                     requiredMsg: $t('common_cannotBeEmpty'),
                  }"
                  :placeholder="'TK1234'"
                  :disabled="!editMode" />
               <TimePicker
                  :label="`${$t('TransferCheckout_lblDeparture')}`"
                  :type="`departure-${legIndex}`"
                  :disabled="!editMode"
                  isUpdate
                  :pickupDateTime="leg.departureFlightDateTime"
                  @pickup="handlerPickUpDate" />
            </div>
         </template>
      </div>
      <template v-if="leg.fromLocationType != 9 && leg.toLocationType != 9">
         <TimePicker
            :label="setTimePickerLabel(leg.toLocationType, leg.fromLocationType)"
            :pickupDateTime="leg.pickupDateTime"
            :disabled="!editMode"
            isUpdate
            :type="`pickup-${legIndex}`"
            @pickup="handlerPickUpDate" />
      </template>
   </div>
</template>

<script>
import moment from "moment";
import TimePicker from "@/components/unitary/form2/UITimePicker.vue";
import UIInput from "@/components/unitary/form2/UIInput.vue";
import UIDatePicker from "@/components/unitary/form2/UIDatePicker2.vue";
export default {
   name: "LegInputFields",
   props: {
      leg: Object,
      legs: Object,
      travelers: Array,
      legIndex: Number,
      editMode: Boolean,
      legInfoArr: Array,
      transferIds: Object,
   },
   components: {
      TimePicker,
      UIInput,
      UIDatePicker,
   },
   data() {
      return {
         // must be equal or greater than current pickupDateTime
         minDateInfo: this.leg.pickupDateTime,
      };
   },
   computed: {
      minDate() {
         return this.minDateInfo;
      },
   },
   watch: {
      "legs.1.pickupDateTime": function (newVal, oldVal) {
         if (newVal < this.legs[2].pickupDateTime) {
            oldVal;
         } else {
            this.legs[2].pickupDateTime = newVal;
            this.minDateInfo = this.leg.pickupDateTime;
         }
      },
   },
   methods: {
      setTimePickerLabel(toLocType, fromLocType) {
         return toLocType == 9 && fromLocType != 9
            ? this.$t("TransferCheckout_lblDeparture")
            : (toLocType != 9 && fromLocType != 9) || (toLocType == 9 && fromLocType == 9)
            ? this.$t("TransferCheckout_lblPickUp")
            : this.$t("TransferCheckout_lblArrival");
      },
      setInputLabels(toLocType, fromLocType) {
         return toLocType == 9 && fromLocType != 9
            ? this.$t("common_departureFlightNumber")
            : toLocType != 9 && fromLocType == 9
            ? this.$t("common_arrivalFlightNumber")
            : toLocType == 9 && fromLocType == 9
            ? this.$t("common_arrivalFlightNumber")
            : this.$t("common_flightNumber");
      },
      handlerPickUpDate(obj) {
         const { generatedDate, type } = obj;
         // arrival-1 for example
         let inputType = type.split("-")[0];

         switch (inputType) {
            case "pickup":
               this.legs[this.legIndex + 1].pickupDateTime = moment(generatedDate).format(this.$constants.dateTimeFormat);
               break;
            case "arrival":
               this.legs[this.legIndex + 1].arrivalFlightDateTime = moment(generatedDate).format(this.$constants.dateTimeFormat);
               break;
            case "departure":
               this.legs[this.legIndex + 1].departureFlightDateTime = moment(generatedDate).format(this.$constants.dateTimeFormat);
               break;
            default:
               this.legs[this.legIndex + 1].pickupDateTime = moment(generatedDate).format(this.$constants.dateTimeFormat);
         }
         this.updateStore();
      },
      handlerFlightNumberInput(val) {
         const { value, model, index } = val;
         switch (model) {
            case "arrival":
               this.legs[index + 1].arrivalFlightNumber = value;
               break;
            case "departure":
               this.legs[index + 1].departureFlightNumber = value;
               break;
            default:
               break;
         }
         this.updateStore();
      },
      handlerTransferDate(val) {
         const { date, index } = val;

         this.legs[index + 1].pickupDateTime = date;
         this.updateStore(index);
      },
      updateStore(legIndex) {
         let contactInfo = this.$store.state.transfer.passengerInfo;
         this.$store.commit("transfer/updateReissue", {
            orderId: this.transferIds.orderId,
            transfers: this.prepareTransferReissueData(legIndex),
            contactInfo: contactInfo,
            tnrId: this.transferIds.tnrId,
         });
      },
      prepareTransferReissueData(legIndex) {
         const self = this;
         let mappedTravelers = this.travelers.map((traveler) => ({
            id: traveler.id,
            name: traveler.name,
            surname: traveler.surname,
            type: traveler.type,
            gender: traveler.gender,
         }));
         let arr = this.legInfoArr.map((leg, index) => {
            return {
               id: leg.id,
               plannedPickupDatetime: legIndex == index ? leg.pickupDateTime.date : self.legs[index + 1].pickupDateTime,
               landingFlightNumber: leg.arrivalFlightNumber,
               landingDateTime: leg.arrivalFlightDateTime
                  ? this.updateTransferDate(
                       leg.arrivalFlightDateTime,
                       typeof leg.pickupDateTime == "object" ? leg.pickupDateTime.date : leg.pickupDateTime
                    )
                  : null,
               takeoffFlightNumber: leg.departureFlightNumber,
               takeoffDateTime: leg.departureFlightDateTime
                  ? this.updateTransferDate(
                       leg.departureFlightDateTime,
                       typeof leg.pickupDateTime == "object" ? leg.pickupDateTime.date : leg.pickupDateTime
                    )
                  : null,
               travelers: mappedTravelers,
            };
         });
         return arr;
      },
      updateTransferDate(val, pickupDate) {
         if (!val) return null;
         let pickupDateTime = moment.utc(pickupDate).startOf("day");
         let date = moment.utc(val);
         date.year(pickupDateTime.year());
         date.month(pickupDateTime.month());
         date.date(pickupDateTime.date());
         return date.format(this.$constants.dateTimeFormat);
      },
   },
};
</script>

<style lang="scss" scoped>
.route-flight-number {
   margin-top: 2rem;
   display: flex;
   justify-content: center;

   @media (max-width: 576px) {
      flex-direction: column;
   }

   .g-input-container {
      margin-right: 16px;
   }
   .time-picker-inputs {
      justify-content: space-evenly;
      display: flex;
      // display: grid;
      // grid-template-columns: 1fr 1fr;
      .time-picker-margin {
         margin-right: 1.5rem;
      }
      @media (max-width: 1200px) {
         display: grid;
         grid-template-columns: 1fr 1fr;
      }
      @media (max-width: 576px) {
         margin-top: 1rem;
         display: grid;
         grid-template-columns: 1fr;
      }
      .time-picker {
         margin-left: 16px;
         @media (max-width: 576px) {
            margin-left: 0;
            margin-top: 1rem;
         }
      }

      .g-input-container {
         margin-bottom: 1rem;
      }
   }
}
</style>
