<template>
   <div class="reissue-search-leg-body-container" :class="isSelected ? 'grid-temp' : ''">
      <!-- <template> -->
      <template v-if="!isSelected">
         <div class="reissue-search-leg-body-header">
            <section class="reissue-search-leg-body-header-col">{{ $t("ancillarReissue_lblFlightResultTitleAirline") }}</section>
            <section class="reissue-search-leg-body-header-col">{{ $t("ancillarReissue_lblFlightResultTitleFlightDate") }}</section>
            <section class="reissue-search-leg-body-header-col">{{ $t("ancillarReissue_lblFlightResultTitleFlightNu") }}</section>
            <section class="reissue-search-leg-body-header-col">{{ $t("ancillarReissue_lblFlightResultTitleFrom") }}</section>
            <section class="reissue-search-leg-body-header-col">{{ $t("ancillarReissue_lblFlightResultTitleTo") }}</section>
            <section class="reissue-search-leg-body-header-col">{{ $t("ancillarReissue_lblFlightResultTitleStop") }}</section>
            <section class="reissue-search-leg-body-header-col">{{ $t("ancillarReissue_lblFlightResultTitleDeparture") }}</section>
            <section class="reissue-search-leg-body-header-col">{{ $t("ancillarReissue_lblFlightResultTitleArrival") }}</section>
         </div>
      </template>

      <div class="leg-body-cards-wrapper">
         <template v-for="(flight, key, flightIndex) in flights">
            <AncillaryReissueSearchLegBodyCard
               :key="flightIndex"
               :flight="flight"
               :is-even="flightIndex % 2 == 0"
               :has-selection="isSelected"
               :airports="airports"
               :allow-details="allowDetails" />
         </template>
      </div>
   </div>
</template>

<script>
import AncillaryReissueSearchLegBodyCard from "@/components/structural/flight/order-detail/ancillaries/ancillary-reissue/AncillaryReissueSearchLegBodyCard.vue";
export default {
   name: "AncillaryReissueSearchLegBody",
   props: {
      flights: Object,
      airports: Object,
      legIndex: Number,
      isSelected: Boolean,
      allowDetails: Boolean,
   },
   components: {
      AncillaryReissueSearchLegBodyCard,
   },
};
</script>

<style lang="scss" scoped>
.reissue-search-leg-body-container {
   width: 100%;

   .reissue-search-leg-body-header,
   ::v-deep .ancillary-reissue-search-leg-body-main-container {
      display: grid;
      grid-template-columns: 1.5fr repeat(7, 1fr);
      padding: 0.25rem 0;
      padding-top: 0.5rem;
      align-items: center;

      section.reissue-search-leg-body-header-col {
         color: #454d49;
         font-size: 0.85rem;
         font-weight: 500;
         display: flex;
         align-items: center;
         // border: 1px solid;
      }
   }

   .reissue-search-leg-body-header {
      margin-top: 0.5rem;
   }

   &.grid-temp {
      background: #f4f6fa;
      ::v-deep .ancillary-reissue-search-leg-body-main-container {
         grid-template-columns: 1.5fr repeat(8, 1fr);
      }
   }

   .leg-body-cards-wrapper {
      max-height: 230px;
      max-height: 500px;
      overflow: hidden;
      overflow-y: scroll;
   }
}
</style>
