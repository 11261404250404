import { PRIVATE_HTTP } from "./index";
import router from "@/router/index.js";
import Popup from "@/gts-popup.js";
import i18n from "@/fmt/i18n.js";

//TODO:task.api ya da bu biri silinmeli
const TASKLIST_SEARCH_ENDPOINT = "api/task/listtasks";
const TASKLIST_GETTASK_ENDPOINT = "api/task/gettask";
const TASKLIST_ADDTASK_ENDPOINT = "api/task/addtask";

export var TaskListSearch = (requestModel) => {
   const promise = PRIVATE_HTTP().post(TASKLIST_SEARCH_ENDPOINT, requestModel);
   const responseData = promise
      .then((response) => response.data)
      .catch((error) => {
         if (error.response.status === 401) {
            if (router.currentRoute.path != "/login") {
               router.push("/login");
            }
         }
         // else
         // {
         //     console.log(error);
         // }
      });

   return responseData;
};
export var getTask = (requestModel) => {
   const promise = PRIVATE_HTTP().post(TASKLIST_GETTASK_ENDPOINT, requestModel);
   const responseData = promise
      .then((response) => response.data)
      .catch((error) => {
         if (error.response.status === 401) {
            if (router.currentRoute.path != "/login") {
               router.push("/login");
            }
         }
         // else
         // {
         //     console.log(error);
         // }
      });

   return responseData;
};
export var addTask = (requestModel) => {
   const promise = PRIVATE_HTTP().post(TASKLIST_ADDTASK_ENDPOINT, requestModel);
   const responseData = promise
      .then((response) => {
         if (!response.data.result.success) {
            let subTitle = i18n.t(
               `RESPONSE_${response.data.result.app}_${response.data.result.service}_${response.data.result.code}_${response.data.result.subCode}`
            );
            Popup.info(i18n.t("popup_informationTitle"), subTitle);
         }

         return response.data;
      })
      .catch((error) => {
         if (error.response.status === 401) {
            if (router.currentRoute.path != "/login") {
               router.push("/login");
            }
         }
         // else
         // {
         //     console.log(error);
         // }
      });

   return responseData;
};
