<template>
   <div id="main-wrapper">
      <slot />
   </div>
</template>

<script>
export default {
   name: "Main",
};
</script>

<style lang="scss" scoped>
html body .main-wrapper {
   width: 100%;

   // @media (min-width: 576px) {
   //     width: calc(100% - 64px);
   // }
}
</style>
