<template>
   <div class="rent-a-car-order-detail-inner-section rnr-summary-wrapper">
      <div class="rnr-summary-inner">
         <div class="rnr-summary-table-wrapper">
            <div class="rnr-summary-table-inner">
               <ul>
                  <li>
                     <span>{{ $t("orderDetail_lblRnrStatus") }}</span>
                     <span>
                        <i class="pnr-badge gts-badge-as-dot" :class="'ERacRnrStatus-' + rnr.status"></i>
                        {{ rnr.status | ERacRnrStatus }}
                     </span>
                  </li>
                  <li>
                     <span>{{ $t("orderDetail_lblProvider") }}</span>
                     <span>{{ rnr.providerName }}</span>
                  </li>
                  <li>
                     <span>{{ $t("orderDetail_lblRnr") }}</span>
                     <span>{{ rnr.rnr }}</span>
                  </li>
                  <li>
                     <span>{{ $t("orderDetail_lblProviderRnr") }}</span>
                     <span>{{ rnr.secondaryRnr }}</span>
                  </li>
                  <li>
                     <span>{{ $t("orderDetail_lblReserveDate") }}</span>
                     <span>{{ rnr.bookingDateTime | TimeFormat }}</span>
                     <span>{{ rnr.bookingDateTime | fullDateFormat }}</span>
                  </li>
                  <li>
                     <span>{{ $t("orderDetail_lblLastVoidDate") }}</span>
                     <span>{{ rnr.lastVoidDateTime | TimeFormat }}</span>
                     <span>{{ rnr.lastVoidDateTime | fullDateFormat }}</span>
                  </li>
                  <li>
                     <span>{{ $t("orderDetail_lblGrandTotal") }}</span>
                     <span>{{ total?.grandTotal | price(total?.currency) }}</span>
                  </li>
               </ul>
            </div>
         </div>
      </div>
   </div>
</template>

<script>
export default {
   name: "RentACarInformationSummary",
   props: {
      rnr: Object,
      total: Object,
   },
};
</script>

<style lang="scss" scoped>
.rnr-summary-wrapper {
   margin-bottom: 43px;
   // padding: 24px;
   .rnr-summary-inner {
      .rnr-summary-table-wrapper {
         .rnr-summary-table-inner {
            background-color: #f3f3f3;
            border: 1px solid #cccccc;
            width: 100%;
            border-radius: 16px;
            padding: 1.75rem 0.75rem;

            @media (min-width: 992px) {
               padding: 1.75rem 1.75rem;
            }
            ul {
               display: flex;
               flex-wrap: wrap;

               @media (min-width: 576px) {
                  display: grid;
                  grid-template-columns: 0.75fr 1fr 0.75fr 0.75fr 1fr 1fr 0.5fr;
               }

               li {
                  width: 50%;
                  display: flex;
                  flex-direction: column;
                  margin-bottom: 1.125rem;

                  @media (min-width: 576px) {
                     width: 100%;
                     margin-bottom: 0;
                  }

                  span {
                     font-size: var(--large);
                     font-weight: 600;
                     @media (min-width: 576px) {
                        font-size: var(--small);
                     }

                     &:first-child {
                        font-size: var(--small);
                        opacity: 0.8;
                        font-weight: 400;
                        border-bottom: 1px solid #ccc;
                        padding-bottom: 0.5rem;
                        margin-bottom: 0.5rem;
                     }

                     &:last-child {
                        display: flex;
                        i {
                           display: inline-flex;
                           flex-shrink: 0;
                           margin-right: 0.5rem;
                        }
                     }
                  }
                  .date-container {
                     display: flex;
                     flex-direction: column;
                     position: relative;

                     &.dateExpired {
                        color: #f15a73;
                        transform: scale(1.2);
                        transform-origin: left top;
                        font-weight: 800;
                        &::before {
                           // content: "!";
                        }
                     }
                  }
               }
            }
         }
         .rnr-summary-table-inne22 {
            display: block;
            border: 1px solid #c4c4c4;
            border-radius: 10px 10px 0 10px;
            overflow: hidden;
            .rnr-summary-table-header {
               display: grid;
               grid-template-columns: repeat(6, 1fr);
               padding: 16px 42px;
               background-color: #ededed;
               border-bottom: 1px solid #c4c4c4;

               & > span {
                  font-size: 14px;
                  color: #868788;
               }
            }
            .rnr-summary-table-content {
               display: grid;
               grid-template-columns: repeat(6, 1fr);
               padding: 16px 42px;
               background-color: #f5f5f5;

               & > span {
                  font-size: 16px;
                  font-weight: 700;
                  max-width: 119px;
                  word-break: break-word;

                  &.rnr-status {
                     position: relative;
                     display: inline-flex;
                     align-items: center;

                     > i {
                        margin-right: 0.5rem;
                     }
                  }
               }
            }
         }
         .rnr-summary-table-footer {
            width: 100%;
            display: flex;
            justify-content: flex-end;
            .rnr-summary-table-footer-inner {
               width: 80%;
               display: grid;
               grid-template-columns: repeat(6, 1fr);
               grid-column-gap: 16px;
               padding: 16px 24px;
               border: 1px solid #c4c4c4;
               border-top: none;
               border-radius: 0 0 8px 8px;
               .rnr-summary-table-footer-item {
                  display: inline-flex;
                  flex-direction: column;
                  border-right: 1px solid #d0d2d4;

                  &:last-child {
                     border-right: none;
                  }
                  & > span {
                     font-size: 14px;
                  }
                  & > span.item-label {
                     margin-bottom: 4px;
                     color: #868788;
                  }
                  & > span.item-text {
                     font-weight: 700;
                  }
               }
            }
         }
      }
   }
}
</style>
