import { PRIVATE_HTTP } from "./index";
import router from "@/router/index.js";
import Popup from "@/gts-popup.js";

// import i18n from "@/fmt/i18n.js";
const LIST_CUSTOMERS_ENDPOINT = "api/agency/listcustomers";
const SET_CUSTOMER_ENDPOINT = "api/agency/setcustomer";
const GET_CUSTOMER_ENDPOINT = "api/agency/getcustomer";

export var listCustomers = (requestModel) => {
    const promise = PRIVATE_HTTP().post(LIST_CUSTOMERS_ENDPOINT, requestModel);
    const responseData = promise
        .then((response) => response.data)
        .catch((error) => {
            if (error.response.status === 401) {
                if (router.currentRoute.path != "/login") {
                    router.push("/login");
                }
            } else {
                Popup.warning("listCustomer Catch Error", error);
            }
        });

    return responseData;
};

export var setCustomer = (requestModel) => {
    const promise = PRIVATE_HTTP().post(SET_CUSTOMER_ENDPOINT, requestModel);
    const responseData = promise
        .then((response) => {
            if (!response.data.result.success) {
                Popup.warning("setCustomer Response Result Error", response.data.result.code);
            }
            return response.data;
        })
        .catch((error) => {
            if (error.response.status === 401) {
                if (router.currentRoute.path != "/login") {
                    router.push("/login");
                }
            } else {
                Popup.warning("setCustomer Catch Error", error);
            }
        });

    return responseData;
};

export var getCustomer = (requestModel) => {
    const promise = PRIVATE_HTTP().post(GET_CUSTOMER_ENDPOINT, requestModel);
    const responseData = promise
        .then((response) => response.data)
        .catch((error) => {
            if (error.response.status === 401) {
                if (router.currentRoute.path != "/login") {
                    router.push("/login");
                }
            } else {
                Popup.warning("listCustomer Catch Error", error);
            }
        });

    return responseData;
};
