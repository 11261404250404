import Vue from "vue";
import Helpers from "./validator-helpers";

Vue.directive("date", {
   bind: (el) => {
      el.classList.add("v-date");
      // for input change
      const regex = /^[0-9-.]*$/;
      //input listener
      const input = (e) => {
         const regexTest = regex.test(e.target.value);
         if (regexTest) {
            e.target.value = Helpers.patternMatch({
               input: e.target.value,
               template: "xx.xx.xxxx",
            });
         } else {
            e.target.value = "";
         }
      };
      el.addEventListener("input", input);
   },
});

Vue.directive("price", {
   bind: (el, binding, vnode) => {
      if (el.tagName != "INPUT") {
         el = el.querySelector("input");
         el.classList.add(binding.rawName);
      } else {
         el.classList.add(binding.rawName);
      }

      //input listener
      const input = (e) => {
         const maxPrice = parseInt(e.target.dataset.max);
         const currentPrice = parseInt(e.target.value.replace(/[^\d,]/g, ""));

         if (maxPrice > currentPrice) {
            e.target.value = currentPrice;
         } else {
            e.target.value = maxPrice - 1;
         }
      };

      // watch for input updates
      vnode.context.$watch(
         vnode.data.directives[0].expression ? vnode.data.directives[0].expression : vnode.data.directives[1].expression,
         function (value) {
            const maxPrice = parseInt(el.dataset.max);

            if (maxPrice > value) {
               el.value = value;
            } else {
               el.value = maxPrice - 1;
            }
         },
         { immediate: false, deep: false }
      );

      el.addEventListener("input", input);
   },
});
