const response = {
   legs: {
      1: {
         index: "1",
         route: "",
         date: "",
         channelId: "",
         flightIdentifier: "",
         selectedBrandIdentifier: "",
         brands: {
            GTS_001: {
               index: "",
               selected: true,
               disabled: "",
               identifier: "",
               brandCode: "",
               brandName: "",
               baggageInfo: "",
               flightClass: "",
               cabinType: "",
               ssrs: [
                  {
                     type: "",
                     state: "",
                     info: "",
                     paxes: {
                        ADULT: "",
                     },
                  },
                  {
                     type: "",
                     state: "",
                     info: "",
                     paxes: {
                        ADULT: "",
                     },
                  },
                  {
                     type: "",
                     state: "",
                     info: "",
                     paxes: {
                        ADULT: "",
                     },
                  },
                  {
                     type: "",
                     state: "",
                     info: "",
                     paxes: {
                        ADULT: "",
                     },
                  },
                  {
                     type: "",
                     state: "",
                     info: "",
                     paxes: {
                        ADULT: "",
                     },
                  },
                  {
                     type: "",
                     state: "",
                     info: "",
                     paxes: {
                        ADULT: "",
                     },
                  },
                  {
                     type: "",
                     state: "",
                     info: "",
                     paxes: {
                        ADULT: "",
                     },
                  },
                  {
                     type: "",
                     state: "",
                     info: "",
                     paxes: {
                        ADULT: "",
                     },
                  },
                  {
                     type: "",
                     state: "",
                     info: "",
                     paxes: {
                        ADULT: "",
                     },
                  },
                  {
                     type: "",
                     state: "",
                     info: "",
                     paxes: {
                        ADULT: "",
                     },
                  },
                  {
                     type: "",
                     state: "",
                     info: "",
                     paxes: {
                        ADULT: "",
                     },
                  },
                  {
                     type: "",
                     state: "",
                     info: "",
                     paxes: {
                        ADULT: "",
                     },
                  },
                  {
                     type: "",
                     state: "",
                     info: "",
                     paxes: {
                        ADULT: "",
                     },
                  },
               ],
               total: "",
               currency: "",
            },
            GTS_002: {
               index: "",
               selected: false,
               disabled: "",
               identifier: "",
               brandCode: "",
               brandName: "",
               baggageInfo: "",
               flightClass: "",
               cabinType: "",
               ssrs: [
                  {
                     type: "",
                     state: "",
                     info: "",
                     paxes: {
                        ADULT: "",
                     },
                  },
                  {
                     type: "",
                     state: "",
                     info: "",
                     paxes: {
                        ADULT: "",
                     },
                  },
                  {
                     type: "",
                     state: "",
                     info: "",
                     paxes: {
                        ADULT: "",
                     },
                  },
                  {
                     type: "",
                     state: "",
                     info: "",
                     paxes: {
                        ADULT: "",
                     },
                  },
                  {
                     type: "",
                     state: "",
                     info: "",
                     paxes: {
                        ADULT: "",
                     },
                  },
                  {
                     type: "",
                     state: "",
                     info: "",
                     paxes: {
                        ADULT: "",
                     },
                  },
                  {
                     type: "",
                     state: "",
                     info: "",
                     paxes: {
                        ADULT: "",
                     },
                  },
                  {
                     type: "",
                     state: "",
                     info: "",
                     paxes: {
                        ADULT: "",
                     },
                  },
                  {
                     type: "",
                     state: "",
                     info: "",
                     paxes: {
                        ADULT: "",
                     },
                  },
                  {
                     type: "",
                     state: "",
                     info: "",
                     paxes: {
                        ADULT: "",
                     },
                  },
                  {
                     type: "",
                     state: "",
                     info: "",
                     paxes: {
                        ADULT: "",
                     },
                  },
                  {
                     type: "",
                     state: "",
                     info: "",
                     paxes: {
                        ADULT: "",
                     },
                  },
                  {
                     type: "",
                     state: "",
                     info: "",
                     paxes: {
                        ADULT: "",
                     },
                  },
               ],
               total: "",
               currency: "",
            },
            GTS_003: {
               index: "",
               selected: false,
               disabled: "",
               identifier: "",
               brandCode: "",
               brandName: "",
               baggageInfo: "",
               flightClass: "",
               cabinType: "",
               ssrs: [
                  {
                     type: "",
                     state: "",
                     info: "",
                     paxes: {
                        ADULT: "",
                     },
                  },
                  {
                     type: "",
                     state: "",
                     info: "",
                     paxes: {
                        ADULT: "",
                     },
                  },
                  {
                     type: "",
                     state: "",
                     info: "",
                     paxes: {
                        ADULT: "",
                     },
                  },
                  {
                     type: "",
                     state: "",
                     info: "",
                     paxes: {
                        ADULT: "",
                     },
                  },
                  {
                     type: "",
                     state: "",
                     info: "",
                     paxes: {
                        ADULT: "",
                     },
                  },
                  {
                     type: "",
                     state: "",
                     info: "",
                     paxes: {
                        ADULT: "",
                     },
                  },
                  {
                     type: "",
                     state: "",
                     info: "",
                     paxes: {
                        ADULT: "",
                     },
                  },
                  {
                     type: "",
                     state: "",
                     info: "",
                     paxes: {
                        ADULT: "",
                     },
                  },
                  {
                     type: "",
                     state: "",
                     info: "",
                     paxes: {
                        ADULT: "",
                     },
                  },
                  {
                     type: "",
                     state: "",
                     info: "",
                     paxes: {
                        ADULT: "",
                     },
                  },
                  {
                     type: "",
                     state: "",
                     info: "",
                     paxes: {
                        ADULT: "",
                     },
                  },
                  {
                     type: "",
                     state: "",
                     info: "",
                     paxes: {
                        ADULT: "",
                     },
                  },
                  {
                     type: "",
                     state: "",
                     info: "",
                     paxes: {
                        ADULT: "",
                     },
                  },
               ],
               total: "",
               currency: "",
            },
         },
         segments: [
            {
               index: "",
               cabinType: "",
               departureAirportCode: "",
               arrivalAirportCode: "",
               marketingAirlineCode: "",
               operatingAirlineCode: "",
               departureDateTime: "",
               arrivalDateTime: "",
               travelTime: "",
               flightNumber: "",
               flightClass: "",
               paxesBaggages: {
                  ADULT: "",
               },
               stopCount: "",
            },
            {
               index: "",
               cabinType: "",
               departureAirportCode: "",
               arrivalAirportCode: "",
               originAirport: {
                  IST: "",
               },
               destinationAirport: {
                  DUS: "",
               },
               marketingAirlineCode: "",
               operatingAirlineCode: "",
               departureDateTime: "",
               arrivalDateTime: "",
               travelTime: "",
               flightNumber: "",
               flightClass: "",
               paxesBaggages: {
                  ADULT: "",
               },
               stopCount: "",
            },
         ],
         amount: null,
      },
      2: {
         index: "2",
         route: "",
         date: "",
         channelId: "",
         flightIdentifier: "",
         selectedBrandIdentifier: "",
         brands: {
            GTS_001: {
               index: "",
               selected: false,
               disabled: "",
               identifier: "",
               brandCode: "",
               brandName: "",
               baggageInfo: "",
               flightClass: "",
               cabinType: "",
               ssrs: [
                  {
                     type: "",
                     state: "",
                     info: "",
                     paxes: {
                        ADULT: "",
                     },
                  },
                  {
                     type: "",
                     state: "",
                     info: "",
                     paxes: {
                        ADULT: "",
                     },
                  },
                  {
                     type: "",
                     state: "",
                     info: "",
                     paxes: {
                        ADULT: "",
                     },
                  },
                  {
                     type: "",
                     state: "",
                     info: "",
                     paxes: {
                        ADULT: "",
                     },
                  },
                  {
                     type: "",
                     state: "",
                     info: "",
                     paxes: {
                        ADULT: "",
                     },
                  },
                  {
                     type: "",
                     state: "",
                     info: "",
                     paxes: {
                        ADULT: "",
                     },
                  },
                  {
                     type: "",
                     state: "",
                     info: "",
                     paxes: {
                        ADULT: "",
                     },
                  },
                  {
                     type: "",
                     state: "",
                     info: "",
                     paxes: {
                        ADULT: "",
                     },
                  },
                  {
                     type: "",
                     state: "",
                     info: "",
                     paxes: {
                        ADULT: "",
                     },
                  },
                  {
                     type: "",
                     state: "",
                     info: "",
                     paxes: {
                        ADULT: "",
                     },
                  },
                  {
                     type: "",
                     state: "",
                     info: "",
                     paxes: {
                        ADULT: "",
                     },
                  },
                  {
                     type: "",
                     state: "",
                     info: "",
                     paxes: {
                        ADULT: "",
                     },
                  },
                  {
                     type: "",
                     state: "",
                     info: "",
                     paxes: {
                        ADULT: "",
                     },
                  },
               ],
               total: "",
               currency: "",
            },
            GTS_002: {
               index: "",
               selected: false,
               disabled: "",
               identifier: "",
               brandCode: "",
               brandName: "",
               baggageInfo: "",
               flightClass: "",
               cabinType: "",
               ssrs: [
                  {
                     type: "",
                     state: "",
                     info: "",
                     paxes: {
                        ADULT: "",
                     },
                  },
                  {
                     type: "",
                     state: "",
                     info: "",
                     paxes: {
                        ADULT: "",
                     },
                  },
                  {
                     type: "",
                     state: "",
                     info: "",
                     paxes: {
                        ADULT: "",
                     },
                  },
                  {
                     type: "",
                     state: "",
                     info: "",
                     paxes: {
                        ADULT: "",
                     },
                  },
                  {
                     type: "",
                     state: "",
                     info: "",
                     paxes: {
                        ADULT: "",
                     },
                  },
                  {
                     type: "",
                     state: "",
                     info: "",
                     paxes: {
                        ADULT: "",
                     },
                  },
                  {
                     type: "",
                     state: "",
                     info: "",
                     paxes: {
                        ADULT: "",
                     },
                  },
                  {
                     type: "",
                     state: "",
                     info: "",
                     paxes: {
                        ADULT: "",
                     },
                  },
                  {
                     type: "",
                     state: "",
                     info: "",
                     paxes: {
                        ADULT: "",
                     },
                  },
                  {
                     type: "",
                     state: "",
                     info: "",
                     paxes: {
                        ADULT: "",
                     },
                  },
                  {
                     type: "",
                     state: "",
                     info: "",
                     paxes: {
                        ADULT: "",
                     },
                  },
                  {
                     type: "",
                     state: "",
                     info: "",
                     paxes: {
                        ADULT: "",
                     },
                  },
                  {
                     type: "",
                     state: "",
                     info: "",
                     paxes: {
                        ADULT: "",
                     },
                  },
               ],
               total: "",
               currency: "",
            },
            GTS_003: {
               index: "",
               selected: false,
               disabled: "",
               identifier: "",
               brandCode: "",
               brandName: "",
               baggageInfo: "",
               flightClass: "",
               cabinType: "",
               ssrs: [
                  {
                     type: "",
                     state: "",
                     info: "",
                     paxes: {
                        ADULT: "",
                     },
                  },
                  {
                     type: "",
                     state: "",
                     info: "",
                     paxes: {
                        ADULT: "",
                     },
                  },
                  {
                     type: "",
                     state: "",
                     info: "",
                     paxes: {
                        ADULT: "",
                     },
                  },
                  {
                     type: "",
                     state: "",
                     info: "",
                     paxes: {
                        ADULT: "",
                     },
                  },
                  {
                     type: "",
                     state: "",
                     info: "",
                     paxes: {
                        ADULT: "",
                     },
                  },
                  {
                     type: "",
                     state: "",
                     info: "",
                     paxes: {
                        ADULT: "",
                     },
                  },
                  {
                     type: "",
                     state: "",
                     info: "",
                     paxes: {
                        ADULT: "",
                     },
                  },
                  {
                     type: "",
                     state: "",
                     info: "",
                     paxes: {
                        ADULT: "",
                     },
                  },
                  {
                     type: "",
                     state: "",
                     info: "",
                     paxes: {
                        ADULT: "",
                     },
                  },
                  {
                     type: "",
                     state: "",
                     info: "",
                     paxes: {
                        ADULT: "",
                     },
                  },
                  {
                     type: "",
                     state: "",
                     info: "",
                     paxes: {
                        ADULT: "",
                     },
                  },
                  {
                     type: "",
                     state: "",
                     info: "",
                     paxes: {
                        ADULT: "",
                     },
                  },
                  {
                     type: "",
                     state: "",
                     info: "",
                     paxes: {
                        ADULT: "",
                     },
                  },
               ],
               total: "",
               currency: "",
            },
         },
         segments: [
            {
               index: "",
               cabinType: "",
               departureAirportCode: "",
               arrivalAirportCode: "",
               marketingAirlineCode: "",
               operatingAirlineCode: "",
               departureDateTime: "",
               arrivalDateTime: "",
               travelTime: "",
               flightNumber: "",
               flightClass: "",
               paxesBaggages: {
                  ADULT: "",
               },
               stopCount: "",
            },
            {
               index: "",
               cabinType: "",
               departureAirportCode: "",
               arrivalAirportCode: "",
               originAirport: {
                  IST: "",
               },
               destinationAirport: {
                  DUS: "",
               },
               marketingAirlineCode: "",
               operatingAirlineCode: "",
               departureDateTime: "",
               arrivalDateTime: "",
               travelTime: "",
               flightNumber: "",
               flightClass: "",
               paxesBaggages: {
                  ADULT: "",
               },
               stopCount: "",
            },
         ],
         amount: null,
      },
   },
   paxes: {
      ADULT: {
         count: "",
         type: "",
         amount: {
            fare: "",
            taxE: "",
            taxI: "",
            airlineTotal: "",
            fareMarkups: "",
            otherMarkups: "",
            otherMarkdowns: "",
            grandTotal: "",
            currency: "",
            marksBreakdown: {
               sf: "",
               gc: "",
               ac: "",
               ace: "",
               pc: "",
               pce: "",
               vc: "",
               tf: "",
               rtf: "",
               dif: "",
            },
         },
      },
   },
   paymentTypes: [
      {
         index: "",
         channelId: "",
         type: "",
         total: "",
         markup: "",
         grandTotal: "",
         currency: "",
         availableBalance: "",
         availableCurrency: "",
         enabledTrxTypes: ["", ""],
         note: null,
      },
      {
         index: "",
         channelId: "",
         type: "",
         total: "",
         markup: "",
         grandTotal: "",
         currency: "",
         availableBalance: "",
         availableCurrency: null,
         enabledTrxTypes: ["", ""],
         note: null,
      },
      {
         index: "",
         channelId: "",
         type: "",
         total: "",
         markup: "",
         grandTotal: "",
         currency: "",
         availableBalance: "",
         availableCurrency: null,
         enabledTrxTypes: ["", ""],
         note: null,
      },
      {
         index: "",
         channelId: "",
         type: "",
         total: "",
         markup: "",
         grandTotal: "",
         currency: "",
         availableBalance: "",
         availableCurrency: null,
         enabledTrxTypes: ["", ""],
         note: null,
      },
   ],
   total: {
      total: "",
      agencyCommissionExtra: "",
      grandTotal: "",
      currency: "",
   },
   inputs: {
      docs: "",
      foid: "",
      tckn: "",
      hesCode: "",
      milesCard: "",
   },
   notes: {
      3: "",
      4: "",
   },
   warnings: {},
   pricePerLeg: true,
   pnrCount: "",
};

export default response;
