<template>
   <div class="time-picker g-input-container" v-click-outside="handlerClickOutside">
      <div class="time-picker-label">
         <label>{{ label }}</label>
      </div>
      <div class="time-picker-container">
         <div class="time-picker-wrapper" @click="handlerDropdown('h')" :class="{ active: time.hours.status }">
            <!-- <div v-if="!time.hours.status" class="picker-display">{{ time.hours.display }}</div> -->
            <UIInput
               :disabled="disabled"
               v-model="time.hours.display"
               class="picker-input"
               v-input="{ type: 'time', identifier: 'hours', required: true, requiredMsg: $t('common_cannotBeEmpty') }" />
            <div class="picker-dropdown-wrapper" v-if="time.hours.status">
               <span
                  v-for="(item, index) in time.hours.arr"
                  :key="index"
                  @click="handlerSelection(item, 'h')"
                  :class="item == time.hours.display ? 'selected' : ''">
                  {{ item }}
               </span>
            </div>
         </div>

         <div class="dot-seperator">:</div>

         <div class="time-picker-wrapper" @click="handlerDropdown('m')" :class="{ active: time.minutes.status }">
            <!-- <div v-if="!time.minutes.status" class="picker-display">{{ time.minutes.display }}</div> -->
            <UIInput
               :disabled="disabled"
               v-model="time.minutes.display"
               class="picker-input"
               v-input="{ type: 'time', identifier: 'minutes', required: true, requiredMsg: $t('common_cannotBeEmpty') }" />
            <div class="picker-dropdown-wrapper" v-if="time.minutes.status">
               <span
                  v-for="(item, index) in time.minutes.arr"
                  :key="index"
                  @click="handlerSelection(item, 'm')"
                  :class="item == time.minutes.display ? 'selected' : ''">
                  {{ item }}
               </span>
            </div>
         </div>
      </div>
   </div>
</template>

<script>
import moment from "moment";
import UIInput from "@/components/unitary/form2/UIInput.vue";

export default {
   name: "TimePicker",
   components: {
      UIInput,
   },
   props: {
      pickupDateTime: String,
      label: String,
      type: String,
      disabled: {
         type: Boolean,
         default: false,
      },
      isUpdate: {
         type: Boolean,
         default: false,
      },
   },
   data() {
      return {
         time: {
            hours: {
               arr: [],
               status: false,
               display: "",
            },
            minutes: {
               arr: [],
               status: false,
               display: "",
            },
         },
      };
   },
   created() {
      for (let i = 0; i < 24; i++) {
         this.time.hours.arr.push(String(i).padStart(2, "0"));
      }
      for (let i = 0; i < 60; i = i + 5) {
         this.time.minutes.arr.push(String(i).padStart(2, "0"));
      }

      this.time.hours.display = this.isUpdate ? this.pickupDateTime.split("T")[1].split(":")[0] : this.time.hours.arr[0];
      this.time.minutes.display = this.isUpdate ? this.pickupDateTime.split("T")[1].split(":")[1] : this.time.minutes.arr[0];
   },
   methods: {
      handlerClickOutside() {
         this.time.hours.status ? (this.time.hours.status = false) : null;
         this.time.minutes.status ? (this.time.minutes.status = false) : null;
      },
      handlerDropdown(identifier) {
         if (this.disabled) return;
         if (identifier == "m") {
            this.time.minutes.status = !this.time.minutes.status;
         } else {
            this.time.hours.status = !this.time.hours.status;
         }
      },
      handlerSelection(selection, identifier) {
         if (identifier == "m") {
            this.time.minutes.display = selection;
         } else {
            this.time.hours.display = selection;
         }
      },
   },
   watch: {
      time: {
         handler: function (val) {
            const generatedDate = moment(this.pickupDateTime);
            generatedDate.set("hour", parseInt(val.hours.display));
            generatedDate.set("minutes", parseInt(val.minutes.display));
            if (generatedDate.isValid() && parseInt(val.hours.display) <= 23 && parseInt(val.minutes.display) <= 59) {
               this.$emit("pickup", {
                  generatedDate: generatedDate._d,
                  type: this.type,
               });
            }
         },
         deep: true,
      },
      pickupDateTime: {
         handler: function (val) {
            if (val) {
               this.time.hours.display = this.pickupDateTime.split("T")[1].split(":")[0];
               this.time.minutes.display = this.pickupDateTime.split("T")[1].split(":")[1];
            }
         },
      },
   },
};
</script>

<style lang="scss" scoped>
.time-picker {
   font-family: "Roboto Mono", monospace;
   width: fit-content;

   label {
      font-weight: 500;
      font-family: "Roboto";
      margin-bottom: 0;
      margin-right: 0.5rem;
   }

   .time-picker-container {
      display: flex;
      // margin-top: 8px;
   }

   .time-picker-wrapper {
      font-size: 1.25rem;
      background: #ffffff;
      display: inline-block;
      padding: 0.4rem 0.5rem;
      height: 48px !important;
      border-radius: 0.5rem;
      position: relative;
      // border: 1px solid #eee;
      cursor: pointer;

      .picker-input {
         font-family: "Roboto Mono", monospace;
         width: 1.8rem;
         border: none;
         outline: none;

         ::v-deep {
            .g-form-warning-badge {
               width: min-content !important;
               font-size: 0.5rem !important;
               z-index: 2 !important;
            }
         }
      }

      .picker-dropdown-wrapper {
         position: absolute;
         top: 100%;
         background-color: inherit;
         max-height: 150px;
         overflow: hidden;
         overflow-y: scroll;
         text-align: center;
         box-shadow: rgba(99, 99, 99, 0.3) 0px 2px 8px 0px;
         z-index: 2;
         border-radius: 0.5rem;
         padding-bottom: 0.5rem;
         padding-top: 0.5rem;
         width: 4rem;
         span {
            display: block;
            font-size: 1.125rem;

            &:hover {
               background-color: #eee;
            }

            &.selected {
               background-color: #eee;
            }
         }
      }
   }

   .dot-seperator {
      font-size: var(--xlarge);
      margin-left: 1.3rem;
      margin-top: 0.6rem;
   }
}
</style>
