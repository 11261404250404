<template>
   <div id="smarttable-footer-container">
      <Section id="smarttable-footer-left-wrapper"> </Section>
      <Section id="smarttable-footer-right-wrapper">
         <template v-if="settings.pages.length > 1">
            <SmartTablePagination />
         </template>
      </Section>
   </div>
</template>

<script>
// import Pagination from "@/components/widgets/base/Pagination.vue";
import SmartTablePagination from "@/components/utils/SmartTable/helpers/SmartTablePagination.vue";

export default {
   name: "SmartTableFooter",
   props: {
      settings: Object,
   },
   components: {
      SmartTablePagination,
   },
   methods: {
      handlerUpdatePage() {
         // console.log(index);
         // this.$emit("options", index);
      },
   },
};
</script>

<style lang="scss" scoped>
#smarttable-footer-container {
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   align-items: center;
   width: 100%;
   // background: #eee;
   padding: 1rem 0;

   #smarttable-footer-left-wrapper,
   #smarttable-footer-right-wrapper {
      // border: 1px solid #ccc;
   }
}
</style>
