<template>
   <div class="old-pnr-informations-container">
      <span class="section-title">{{ $t("orderDetail_titleExPnrTitle") }}</span>
      <div class="old-pnr-table-wrapper">
         <div class="item-wrapper">
            <svg width="36" height="36" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
               <path
                  d="M22.7 13.5L20.7005 11.5L18.7 13.5M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C15.3019 3 18.1885 4.77814 19.7545 7.42909M12 7V12L15 14"
                  stroke="#363636"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round" />
            </svg>
         </div>
         <div class="item-wrapper">
            <span>{{ $t("orderDetail_lblExPnrId") }}</span>
            <span>{{ data.exPnrId }}</span>
         </div>
         <div class="item-wrapper">
            <span>{{ $t("orderDetail_lblExPnr") }}</span>
            <span>{{ data.exPnr }}</span>
         </div>
         <div class="item-wrapper">
            <span>{{ $t("orderDetail_lblExPnrOrderId") }}</span>
            <span>{{ data.exPnrOrderId }}</span>
         </div>
         <div class="item-wrapper">
            <!-- <router-link :to="'/order/detail/' + " tag="a" class="link">Go To Order Detail</router-link> -->
            <span class="jump-2-order" @click="handlerGotToOrder(data.exPnrOrderUuid)">
               <span>{{ $t("orderDetail_lblGoToOrderDetail") }}</span></span
            >
         </div>
      </div>
   </div>
</template>

<script>
export default {
   name: "OldPnrInformations",
   props: {
      data: Object,
   },
   methods: {
      handlerGotToOrder(url) {
         const isMobile = this.$store.state.app.clientInformations.isMobile;
         if (isMobile) {
            this.$router.push("/order/detail/" + url);
         } else {
            window.open("/order/detail/" + url);
         }
      },
   },
};
</script>

<style lang="scss" scoped>
.old-pnr-informations-container {
   //    border: 1px solid #e6c7ff;
   //    background-color: #e6c7ff50;
   //    padding: 0.5rem 1rem;
   //    border-radius: 1rem;

   &:hover {
      span {
         opacity: 1 !important;
      }
   }

   .old-pnr-table-wrapper {
      border: 1px solid #e6c7ff;
      background-color: #e6c7ff50;
      padding: 0.5rem 1rem;
      border-radius: 1rem;
      display: flex;
      justify-content: space-between;
      .item-wrapper {
         //  width: 5%;
         display: flex;
         flex-direction: column;
         //  align-items: center;
         justify-content: center;

         span {
            &:first-child {
               //        opacity: 0.6;
               font-size: var(--small);
            }
            &:last-child {
               font-weight: 600;
            }

            &.jump-2-order {
               opacity: 1 !important;
               color: #fff;
               font-weight: 500;
               padding: 0.5rem 1rem;
               border-radius: 24rem;
               background-color: #bd7ef2;
               cursor: pointer;
               transition: all 0.05s ease-in-out;

               &:hover {
                  transform: scale(1.05);
               }
            }
         }
      }
   }
}
</style>
