<template>
   <div>
      <div class="summary-container">
         <!-- <div class="summary-title-wrapper">
            <h1 class="summary-title">{{ $t("salesReportSummary_title") }}</h1>
            <div class="summary-eye" @click="handlerShowSummary">
               <UITooltip
                  :tooltip-title="$t('common_show')"
                  tooltip-icon="gts-icon icon-eye"
                  noPadding
                  tooltip-position="bottom"
                  no-padding
                  v-if="!showSummary">
               </UITooltip>
               <UITooltip
                  :tooltip-title="$t('common_hide')"
                  tooltip-icon="gts-icon icon-eye-closed"
                  noPadding
                  tooltip-position="bottom"
                  no-padding
                  v-else>
               </UITooltip>
            </div>
         </div> -->
         <div class="summary-wrapper" v-if="true">
            <div class="summary-row">
               <div class="summary-col">
                  <span></span>
               </div>
               <div class="summary-col" v-for="index in SummaryLength()" :key="index">
                  <span class="txt-bolder txt-center">{{ SummaryCol(index, true, activeSummaryLabel) }}</span>
               </div>
            </div>
            <div
               class="summary-row gts-badge-bg-lighten"
               v-for="(sum, key, index) in summary"
               :key="index"
               :class="SummaryStatus(sum, entityType, true)">
               <div class="summary-col">
                  <div class="summary-col-inner-row">
                     <span class="summary-badge gts-badge-bg" :class="SummaryStatus(sum, entityType, true)"></span>
                     <span class="summary-entity-status">{{ SummaryStatus(sum, entityType, false) }}</span>
                  </div>
               </div>
               <div class="summary-col" v-for="index in SummaryLength()" :key="index">
                  <span class="txt-right">{{ SummaryCol(index, false, activeSummaryLabel, sum) }}</span>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>
import i18n from "@/fmt/i18n.js";
// import UITooltip from "@/components/utils/GtsToolTips.vue";
export default {
   name: "SalesReportSummary",
   props: {
      summary: { type: Object },
      entityType: { type: String }, //flight, hotel, transfer, rentACar
   },
   components: {
      // UITooltip,
   },
   data() {
      return {
         // showSummary: false,
         activeSummaryLabel: null,
         //Labels and Contents
         flightLabels: {
            header: "flightSalesReport",
            1: { title: "pnrCount", content: "pnrCount" },
            2: { title: "paxCount", content: "paxCount" },
            3: { title: "totalAmount", content: "saleAmount", type: "price" },
            4: { title: "refundTotal", content: "reverseAmount", type: "price" },
            5: { title: "currency", content: "currency" },
         },
         hotelLabels: {
            header: "hotelSalesReport",
            1: { title: "voucherCount", content: "voucherCount" },
            2: { title: "roomCount", content: "roomCount" },
            3: { title: "guestCount", content: "guestCount" },
            4: { title: "agencyCommission", content: "agencyCommissionAmount", type: "price" },
            5: { title: "saleAmount", content: "saleAmount", type: "price" },
         },
         transferLabels: {
            header: "transferSalesReport",
            1: { title: "tnrCount", content: "tnrCount" },
            2: { title: "travelerCount", content: "travelerCount" },
            3: { title: "totalAmount", content: "saleAmount", type: "price" },
            4: { title: "refundTotal", content: "reverseAmount", type: "price" },
            5: { title: "currency", content: "currency" },
         },
         rentACarLabels: {
            header: "rentACarSalesReport",
            1: { title: "rnrCount", content: "rnrCount" },
            2: { title: "vehicleCount", content: "vehicleCount" },
            3: { title: "totalAmount", content: "saleAmount", type: "price" },
            4: { title: "refundTotal", content: "reverseAmount", type: "price" },
            5: { title: "currency", content: "currency" },
         },
      };
   },
   created() {
      this.activeSummaryLabel = this[`${this.entityType}Labels`];
   },
   methods: {
      // handlerShowSummary() {
      //    const self = this;
      //    self.showSummary = !self.showSummary;
      // },
      SummaryLength() {
         return Object.keys(this.activeSummaryLabel).length - 1; //Reason for -1 is because one of the elements in object is header. But we need the length of elements other than the header
      },
      SummaryCol(index, title, entityLabel, sum) {
         if (title) {
            return i18n.t(entityLabel.header + "_" + entityLabel[index].title);
         } else {
            if (entityLabel[index].type == "price") {
               return this.$options.filters.price(sum[entityLabel[index].content]);
            } else {
               return sum[entityLabel[index].content];
            }
         }
      },

      SummaryStatus(sum, entity, badge) {
         switch (entity) {
            case "flight":
               if (badge) {
                  return "EFltPnrStatus-" + sum.pnrStatus;
               } else {
                  return this.$options.filters.EFltPnrStatus(sum.pnrStatus);
               }
            case "hotel":
               if (badge) {
                  return "EHtlVoucherStatus-" + sum.voucherStatus;
               } else {
                  return this.$options.filters.EHtlVoucherStatus(sum.voucherStatus);
               }
            case "transfer":
               if (badge) {
                  return "ETrfTnrStatus-" + sum.tnrStatus;
               } else {
                  return this.$options.filters.ETrfTnrStatus(sum.tnrStatus);
               }
            case "rentACar":
               if (badge) {
                  return "ERacRnrStatus-" + sum.rnrStatus;
               } else {
                  return this.$options.filters.ERacRnrStatus(sum.rnrStatus);
               }
            default:
               return null;
         }
      },
   },
};
</script>

<style lang="scss">
.summary-container {
   display: flex;
   justify-content: center;
   flex-direction: column;
   align-items: center;
   .summary-title-wrapper {
      display: flex;
      align-items: center;
      .summary-title {
         font-size: var(--large);
         font-weight: 500;
      }
      .summary-eye {
         cursor: pointer;
         padding-left: 8px;
      }
   }
   .summary-wrapper {
      // width: 50%;
      border: 1px solid #eee;
      max-width: 1000px;
      border-radius: 0.5rem;
      padding: 1rem 2rem;
      background: #fff;
      box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
      width: 100%;
      @media (max-width: 576px) {
         width: 100%;
         padding: 0;
      }
      .summary-row {
         border-radius: 24rem;
         padding: 0;
         margin-bottom: 0.5rem;
         display: grid;
         grid-template-columns: repeat(6, 1fr) !important;
         @media (max-width: 576px) {
            grid-template-columns: repeat(5, 1fr);
         }

         .summary-col {
            border-right: 1px solid #eee;
            padding: 0.5rem 1rem;
            display: flex;
            align-items: center;

            @media (max-width: 576px) {
               // width: 84px;
               padding: 0.25rem 1rem;
            }

            span {
               display: block;
               width: 100%;
            }

            &:last-child {
               border: none !important;
            }
            .summary-col-inner-row {
               display: flex;
               align-items: center;

               .summary-badge {
                  width: 20px;
                  height: 20px;
                  display: block;
                  flex-shrink: 0;
                  border-radius: 50%;
               }

               .summary-entity-status {
                  font-weight: 500;
                  margin-left: 0.5rem;
                  text-align: left;
                  display: none;
                  @media (min-width: 768px) {
                     display: flex;
                  }
               }
            }
         }
      }
   }
}
</style>

<style lang="scss" scoped>
.summary-container {
   .summary-title-wrapper {
      .summary-eye {
         padding-left: 8px;
         .gts-tooltip {
            padding-top: 0px !important;
            padding-right: 0px !important;
            padding-left: 0px !important;
            padding: 0px !important;
         }
      }
   }
}
</style>
