<template>
   <div class="settings-page-wrapper">
      <Aside />
      <Main>
         <Navbar />
         <div class="settings-page-inner">
            <div class="settings-nav">
               <div class="settings-nav-list nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                  <button
                     v-if="roles.includes(15) && agencyUser"
                     :class="roles.includes(15) && agencyUser ? 'active' : ''"
                     class="settings-nav-list-item nav-link active"
                     id="nav-profile-tab"
                     data-bs-toggle="pill"
                     data-bs-target="#nav-profile"
                     type="button"
                     role="tab"
                     aria-controls="nav-profile"
                     aria-selected="true">
                     <!-- <i class="gts-icon icon-user-edit"></i> -->
                     <span class="settings-tabs-text">{{ $t("settings_profileSettings") }}</span>
                  </button>
                  <button
                     v-if="roles.includes(16)"
                     :class="!roles.includes(15) || (roles.includes(16) && !agencyUser) ? 'active' : ''"
                     class="settings-nav-list-item nav-link"
                     id="nav-user-authorization-tab"
                     data-bs-toggle="pill"
                     data-bs-target="#nav-user-authorization"
                     type="button"
                     role="tab"
                     aria-controls="nav-user-authorization"
                     aria-selected="true">
                     <!-- <i class="gts-icon icon-user-cog"></i> -->
                     <span class="settings-tabs-text">{{ $t("settings_userAuthorization") }}</span>
                  </button>
                  <button
                     v-if="roles.includes(17) && agencyUser"
                     :class="!roles.includes(15) && !roles.includes(16) && roles.includes(17) && agencyUser ? 'active' : ''"
                     class="settings-nav-list-item nav-link"
                     id="nav-customer-tab"
                     data-bs-toggle="pill"
                     data-bs-target="#nav-customer"
                     type="button"
                     role="tab"
                     aria-controls="nav-customer"
                     aria-selected="false">
                     <!-- <i class="gts-icon icon-user-cog"></i> -->
                     <span class="settings-tabs-text">{{ $t("settings_customerManagement") }}</span>
                  </button>
                  <button
                     v-if="roles.includes(18)"
                     :class="!roles.includes(15) && !roles.includes(16) && !roles.includes(17) && roles.includes(18) ? 'active' : ''"
                     class="settings-nav-list-item nav-link"
                     id="nav-user-settings-tab"
                     data-bs-toggle="pill"
                     data-bs-target="#nav-user-settings"
                     type="button"
                     role="tab"
                     aria-controls="nav-user-settings"
                     aria-selected="false">
                     <!-- <i class="gts-icon icon-user-cog"></i> -->
                     <span class="settings-tabs-text">{{ $t("settings_userSettings") }}</span>
                  </button>
               </div>
               <div class="settings-nav-content-list tab-content" id="v-pills-tabContent">
                  <section
                     v-if="roles.includes(15) && agencyUser"
                     :class="[!roles.includes(15) && agencyUser ? 'active' : '', profileSettings ? 'active' : '']"
                     class="settings-nav-content-list-item tab-pane fade show"
                     id="nav-profile"
                     role="tabpanel"
                     aria-labelledby="nav-profile-tab">
                     <ProfileSettings @set-user-settings-obj="setUserSettingsObj" />
                  </section>
                  <section
                     v-if="roles.includes(16)"
                     :class="[!roles.includes(15) || (roles.includes(16) && !agencyUser) ? 'active' : '', userAuthorization ? 'active' : '']"
                     class="settings-nav-content-list-item tab-pane fade show"
                     id="nav-user-authorization"
                     role="tabpanel"
                     aria-labelledby="nav-user-authorization-tab">
                     <UserAuthorization />
                  </section>
                  <section
                     v-if="roles.includes(17) && agencyUser"
                     :class="[
                        !roles.includes(15) && !roles.includes(16) && roles.includes(17) && agencyUser ? 'active' : '',
                        customerManagement ? 'active' : '',
                     ]"
                     class="settings-nav-content-list-item tab-pane fade show"
                     id="nav-customer"
                     role="tabpanel"
                     aria-labelledby="nav-customer-tab">
                     <CustomerManagement />
                  </section>
                  <section
                     v-if="roles.includes(18)"
                     :class="[
                        !roles.includes(15) && !roles.includes(16) && !roles.includes(17) && roles.includes(18) ? 'active' : '',
                        userSettings ? 'active' : '',
                     ]"
                     class="settings-nav-content-list-item tab-pane fade show"
                     id="nav-user-settings"
                     role="tabpanel"
                     aria-labelledby="nav-user-settings-tab">
                     <UserSettings />
                  </section>
               </div>
            </div>
         </div>
      </Main>
   </div>
</template>

<script>
import Aside from "@/components/unitary/Aside.vue";
import Navbar from "@/components/unitary/Navbar.vue";
import Main from "@/components/unitary/Main.vue";

import ProfileSettings from "@/pages/user/settings/ProfileSettings.vue";
import UserAuthorization from "@/pages/user/settings/UserAuthorization.vue";
import UserSettings from "@/pages/user/settings/UserSettings.vue";
import CustomerManagement from "@/components/structural/flight/common/CustomerManagement.vue";

export default {
   name: "SettingsPage",
   components: {
      Aside,
      Navbar,
      Main,
      ProfileSettings,
      UserAuthorization,
      UserSettings,
      CustomerManagement,
   },
   data() {
      return {
         userObj: {},
         roles: [],
         agencyUser: true,
         profileSettings: false,
         userAuthorization: false,
         customerManagement: false,
         userSettings: false,
      };
   },
   created() {
      this.roles = JSON.parse(localStorage.getItem("auths"));
      if (
         this.$store.state.user.user.onBehalfOfs &&
         this.$store.state.user.user.onBehalfOfs.agencyOnBehalfOfId &&
         this.$store.state.user.user.onBehalfOfs.agencyOnBehalfOfId == -1
      ) {
         this.agencyUser = false;
      } else {
         this.agencyUser = true;
      }
   },
   methods: {
      setUserSettingsObj(obj) {
         const self = this;
         self.userObj = obj;
      },
   },
   watch: {
      "$route.query.page": {
         handler: function (page) {
            this.profileSettings = false;
            this.userAuthorization = false;
            this.customerManagement = false;
            this.userSettings = false;
            switch (page) {
               case "profileSettings":
                  this.profileSettings = true;
                  break;
               case "userAuthorization":
                  this.userAuthorization = true;
                  break;
               case "customerManagement":
                  this.customerManagement = true;
                  break;
               case "userSettings":
                  this.userSettings = true;
                  break;
               default:
                  this.profileSettings = true;
                  break;
            }
         },
         immediate: true,
      },
   },
};
</script>

<style lang="scss" scoped>
.settings-page-wrapper {
   .settings-page-inner {
      .settings-nav {
         display: flex;
         align-items: flex-start;
         justify-content: flex-start;

         .settings-nav-list {
            margin-left: 240px;
            padding-top: 16px;
            padding-bottom: 16px;
            width: 168px;
            border-radius: 16px;
            background: #ffffff 0% 0% no-repeat padding-box;
            border: 1px solid #e6e6e6;
            box-shadow: 2px 2px 6px #5c75991a;

            .settings-nav-list-item {
               width: 100%;
               position: relative;
               display: flex;
               align-items: center;
               justify-content: flex-start;
               text-align: left;
               color: #373b42;

               .settings-tabs-text {
                  font-size: var(--small);
               }

               & > i {
                  min-width: 32px;
                  margin-right: 8px;
               }

               // &:last-child {
               //    border-bottom: none;
               // }
               &:hover {
                  background-color: #e9f0f9;
               }

               &.active {
                  background: none;
                  // color: #33b8ff;

                  .settings-tabs-text {
                     font-weight: 600;
                  }

                  // &::after {
                  //    content: "";
                  //    position: absolute;
                  //    right: 16px;
                  //    width: 9px;
                  //    height: 14px;
                  //    display: block;
                  //    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMy4zOCIgaGVpZ2h0PSI3LjU5NyIgdmlld0JveD0iMCAwIDEzLjM4IDcuNTk3Ij4KICA8cGF0aCBpZD0iYXJyb3dJbnB1dCIgZD0iTTEzLjI0OSwyMC44NzhhMS4xNDgsMS4xNDgsMCwwLDAsLjg0Ny0uMzU0bDUuMjQzLTUuNzM0YS44NjkuODY5LDAsMCwwLDAtMS4yMTRMMTMuOTExLDcuODQzYTEuMTkxLDEuMTkxLDAsMCwwLTEuNTMxLS4xMjQuODgxLjg4MSwwLDAsMC0uMTQxLDEuMzQ3bDQuODUyLDUuMTIyTDEyLjQsMTkuMzExYS44NjYuODY2LDAsMCwwLS4xNDYsMS4wMjJBMS4xMDcsMS4xMDcsMCwwLDAsMTMuMjQ5LDIwLjg3OFoiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDIwLjg3OCAtMTEuOTg5KSByb3RhdGUoOTApIiBmaWxsPSIjMzYzOTQwIi8+Cjwvc3ZnPgo=");
                  //    background-color: transparent;
                  //    background-size: contain;
                  //    background-repeat: no-repeat;
                  //    background-position: center center;
                  // }

                  &::before {
                     content: "";
                     position: absolute;
                     width: 4px;
                     height: 75%;
                     left: 0;
                     background-color: var(--accent-primary);
                     border-top-right-radius: 12px;
                     border-bottom-right-radius: 12px;
                  }
               }
            }

            @media (max-width: 768px) {
               .settings-nav-list-item {
                  &.active {
                     background: none;

                     .settings-tabs-text {
                        font-weight: 600;
                     }
                     &::before {
                        content: "";
                        position: absolute;
                        width: 0px;
                        height: 0%;
                        left: 0;
                        background-color: var(--accent-primary);
                        border-top-right-radius: 0px;
                        border-bottom-right-radius: 0px;
                     }
                     &:after {
                        content: "";
                        position: absolute;
                        bottom: 0;
                        background-color: var(--accent-primary);
                        height: 2px;
                        left: -3px;
                        right: -3px;
                        width: 25%;
                     }
                  }
               }
            }

            @media (max-width: 992px) {
               .settings-nav-list-item {
                  &.active {
                     background: none;

                     .settings-tabs-text {
                        font-weight: 600;
                     }
                     &::before {
                        content: "";
                        position: absolute;
                        width: 0px;
                        height: 0%;
                        left: 0;
                        background-color: var(--accent-primary);
                        border-top-right-radius: 0px;
                        border-bottom-right-radius: 0px;
                     }
                     &:after {
                        content: "";
                        position: absolute;
                        bottom: 0;
                        background-color: var(--accent-primary);
                        height: 2px;
                        left: -3px;
                        right: -3px;
                        width: 25%;
                     }
                  }
               }
            }
         }

         @media (max-width: 768px) {
            .settings-nav-list {
               // padding-top: 16px;
               // padding-bottom: 16px;
               // border-radius: 16px;
               // background: #ffffff 0% 0% no-repeat padding-box;
               // border: 1px solid #e6e6e6;
               // box-shadow: 2px 2px 6px #5c75991a;
               // margin-left: 8px;
               // margin-right: 8px;
               // width: 100%;
               display: none;
            }
         }

         @media (max-width: 992px) {
            .settings-nav-list {
               // padding-top: 16px;
               // padding-bottom: 16px;
               // border-radius: 16px;
               // background: #ffffff 0% 0% no-repeat padding-box;
               // border: 1px solid #e6e6e6;
               // box-shadow: 2px 2px 6px #5c75991a;
               // margin-left: 8px;
               // margin-right: 8px;
               // width: 100%;
               display: none;
            }
         }

         .settings-nav-content-list {
            width: 55%;
            margin-left: 32px;
            background: white;
            border: 1px solid #ddd;
            border-right: none;
            border-bottom: none;
            border-radius: 16px;
         }

         @media (max-width: 768px) {
            .settings-nav-content-list {
               width: 100%;
               margin-left: 8px;
               margin-right: 8px;
               background: white;
               border: 1px solid #ddd;
               border-right: none;
               border-bottom: none;
               border-radius: 16px;
            }
         }

         @media (max-width: 992px) {
            .settings-nav-content-list {
               width: 100%;
               margin-left: 8px;
               margin-right: 8px;
               background: white;
               border: 1px solid #ddd;
               border-right: none;
               border-bottom: none;
               border-radius: 16px;
            }
         }
      }

      @media (max-width: 768px) {
         .settings-nav {
            flex-direction: column;
         }
      }

      @media (max-width: 992px) {
         .settings-nav {
            flex-direction: column;
         }
      }
   }
}
.navbar-wrapper {
   margin-bottom: 0;
}
</style>
