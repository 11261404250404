<template>
   <div id="checkout-contact-container" :class="[isBorderavailable ? 'gts-box with-border' : '']">

      <div class="checkout-contact-wrapper">
            <UISelectBox
               :label="$t('common_countryPhoneCodeShort')"
               id="orderSelect"
               :options="CountryOptionList"
               v-model="Contact.phone.country"
               allow-search
               has-icons />
         <div  class="contact-input">
            <UIInput
               v-model="Contact.phone.city"
               custom-class="g-short-input"
               :label="$t('common_city')"
               v-input="{ type: 'numeric', len: [2, 4], required: true, requiredMsg: $t('common_cannotBeEmpty') }" />
         </div>
         <div class="contact-input" >
            <UIInput
               v-model="Contact.phone.number"
               :label="$t('common_phone')"
               v-input="{ type: 'numeric', len: [3, 8], required: true, requiredMsg: $t('common_cannotBeEmpty') }" />
         </div>
      </div>
            <div class="checkout-contact-information-wrapper" v-show="isflight">
         <p v-if="fillWithFirstPax">* {{ $t("checkoutContact_lblFilledWithFirstPaxInfos") }}</p>
         <p v-else>* {{ $t("checkoutContact_lblFilledWithAgentInfos") }}</p>
         <p>* {{ $t("checkoutContact_informationText") }}</p>
      </div>

   </div>
</template>

<script>

import UISelectBox from "@/components/unitary/form2/UISelectBox.vue";
import UIInput from "@/components/unitary/form2/UIInput.vue";
export default {
   name: "PhoneComponent",
   components: {
      UISelectBox,
      UIInput,
   },
   data() {
      return {
         countriesObj: [],
         countriesObjPlaceholder: [],
            defaultContact: {
            filled: false,
            email: null,
            phone: {},
         },
         contact: {
            phone: {
               country: null,
               city: "",
               number: "",
            },
         },
      };
   },
   props:{
      isBorderavailable: Boolean,
      contactInfo : Object,
   },
   watch: {
      contactPhone(){
         // console.log(this.contact)
         this.contactInfo = this.contact.phone
            this.$emit("update:contactInfo",this.contact.phone)
      },
      contactCity(){
         // console.log(this.contact)
         this.contactInfo = this.contact.phone
            this.$emit("update:contactInfo",this.contact.phone)
      },
      "$store.state.app.countryList": {
         handler: function (countries) {
            const self = this;

            countries.forEach((country) => {
               const rowObj = {
                  value: country.phoneCode,
                  name: `${country.phoneCode}`,
                  icon: country.code,
               };
               self.countriesObj.push(rowObj);
                const rowObjPlaceholder = {
                  value: country.phoneCode,
                  name: `${country.phoneCode} ${country.nameTr}`,
                  icon: country.code,
               };
               self.countriesObjPlaceholder.push(rowObjPlaceholder);
            });
         },
         immediate: true,
      },
   },
   computed: {
      contactCity(){
         return this.contact.phone.city
      },
      contactNumber(){
         return this.contact.phone.Number
      },


      Contact() {
         const self = this;
         if (self.editMode) {
            self.contact = self.$store.state.transfer.passengerInfo;
            return self.contact;
         }
         if (Object.keys(self.$store.state.transfer.passengerInfo).length !== 0 && self.$store.state.transfer.passengerInfo.constructor === Object) {
            self.contact = self.$store.state.transfer.passengerInfo;
         }
         self.contact.phone.country = "+49";
         return self.contact;
      },
      CountryOptionList() {
        //Ülke adı gerektiğinde burası değiştirilir
         const self = this;
         return self.countriesObj;
      },
   },
};
</script>

<style lang="scss" scoped>
.checkout-contact-header{
   display: flex;
   justify-content:space-between;
}
      #autofill-wrapper {
         background: #f6f5f5;
         padding: 0.5rem 1rem;
         border-radius: 0.5rem;
         border: 1px solid #cecece;
         display: flex;
         align-items: center;

         label {
            margin-right: 0.5rem;
            font-weight: 500;
            color: #363636;
            font-size: 14.5px;
         }
      }
#checkout-contact-container {
   .checkout-contact-wrapper {
      display: flex;
      flex-direction: flex;
      @media (min-width: 576px) {
         display: grid;
         grid-template-columns: 0.25fr 0.7fr 1.5fr;
         width: 100%;
      }
      @media (min-width: 768px) {
         width: 100%;
      }
      @media (min-width: 992px) {
         width: 100%;
      }

      .contact-input {
                 padding-right: 0.5rem;
        display: flex;
        width: 100%;
         margin: 0 0.5rem;
         display: flex;

         @media (min-width: 576px) {
            margin-bottom: 0;
         }

         ::v-deep {
            .g-input-container {
               width: 100%;
            }
         }

         ::v-deep .form-item {
            input {
               position: relative;
               display: flex;
               justify-content: flex-start;
               align-items: center;
               border: 1px solid #b1b2b3;
               border-radius: 0.5rem;
               box-sizing: border-box;
               background: #fff;
               height: 48px !important;
               max-height: 100% !important;
               border-width: 2px;
               outline: transparent solid 2px;
               outline-offset: 2px;
               width: 100%;
               text-indent: 1rem;
               font-size: var(--normal);

               &.g-short-input {
                  width: 80px !important;
               }
            }
         }
      }
   }
   @media (max-width: 767.98px) {
      .checkout-contact-wrapper {
         display: grid;
         .contact-input {
            display: block;
         }
      }
   }
   .checkout-contact-information-wrapper {
      margin-top: 2rem;
      margin-left: 0.5rem;
      p {
         font-size: var(--small);
         color: var(--txt-black);
      }
   }
}

 .form-wrapper {
            width: 10%;
            margin-bottom: 1.25rem;

            @media (max-width: 576px) {
                display: flex;
               width: 100%;
               padding: 0 !important;
               // border: 1px solid red !important;
            }

            ::v-deep {
               .g-input-container {
                  .gts-input-wrapper {
                     input {
                        background: #f7f9fc;
                        border: 2px solid #cacaca;
                     }
                  }
               }
            }

            &:last-child {
               width: 100%;

               textarea {
                  width: 100%;
                  height: 106px;
                  background: #f7f9fc;
                  border: 2px solid #cacaca;
                  border-radius: 0.5rem;
                  padding: 0.5rem 1rem;
               }
            }

            &.phone {
               display: flex;
               width: 100%;
               ::v-deep {
                  .g-select-box-container {
                     width: 142px;
                     margin-right: 1rem;
                  }
                  .g-input-container {
                     &:last-child {
                        margin-left: 1rem;
                     }
                  }
               }
            }
         }
</style>
