<template>
   <section class="hotel-order-detail-section passenger-information-wrapper">
      <div class="passenger-information-inner">
         <div class="hotel-order-detail-section-title">
            <h4>{{ $t("orderDetail_lblAddonInfoTitle") }}</h4>
         </div>
         <div class="hotel-order-detail-section-content">
            <AddonInformationSummary :addons="addons" :passengerList="passengers" />
         </div>
      </div>
   </section>
</template>

<script>
import AddonInformationSummary from "@/components/structural/hotel/order-detail/AddonInformationSummary.vue";

export default {
   name: "AddonInformation",
   props: {
      addons: { type: Array },
   },
   components: {
      AddonInformationSummary,
   },
   data() {
      return {
         passengers: [],
      };
   },
   created() {
      const self = this;
      self.passengers = this.passengerInformation;
   },
   watch: {
      passengerInformation: {
         handler: function (newPassengerInformation) {
            const self = this;
            self.passengers = newPassengerInformation;
         },
         deep: true,
      },
   },
};
</script>

<style lang="scss" scoped>
.passenger-information-wrapper {
   .passenger-information-inner {
      .hotel-order-detail-section-title {
         margin-bottom: 24px;
         & > h4 {
            font-weight: 700;
         }
      }
      .hotel-order-detail-section-content {
      }
   }
}
</style>
