<template>
   <div class="transferCheckout-page-wrapper">
      <Aside />
      <Main>
         <Navbar />

         <div class="container-lg px-md-2 px-lg-0">
            <div class="col px-md-2 px-lg-0">
               <div class="row gx-4">
                  <div class="col-12 col-lg-9" :class="isPageLoad ? '' : 'data-mask'">
                     <!-- Mobile Tnr Information -->
                     <!-- <CheckoutTnrInfoBadge /> -->

                     <!-- Transfer Informations -->
                     <TransferInformations
                        @update-to-agency-extra="transferCheckout"
                        :checkoutRequestModel="checkoutRequestModel"
                        :transfers="checkoutRequestModel.transfers"
                        :totalPrice="totalPrice" />

                     <!-- Price Informations -->
                     <!-- <PriceInformations /> -->

                     <!-- Traveler Informations -->
                     <TravelerInformations
                        :pickupDateTime="pickupDateTime"
                        :travelers="checkoutRequestModel.travellers"
                        @set-travelers="emitCookieData" />

                     <!-- Contact Informations -->
                     <!-- <CheckoutContact @update-to-contact="setCheckoutContact" /> -->
                     <CommonCheckoutContact :isShowMail="true" :isBorderavailable="true" @update-to-contact="setCheckoutContact" />

                     <!-- Agency Note -->
                     <CheckoutNotes :lastVoidDateTime="checkoutRequestModel.lastVoidDateTime" @update-to-agency-note="setCheckoutAgencyNote" />

                     <!-- Checkout Payment -->
                     <PaymentPurchaseWidget
                        entityType="transfer"
                        :checkoutRequest="checkoutRequestModel"
                        :paymentChoiceDetail="PaymentChoiceDetail"
                        v-if="PaymentChoiceDetail != null"
                        :funcValidateForm="funcValidateForm" />
                  </div>
                  <div class="col d-none d-lg-block col-lg-3">
                     <!-- Transfer Checkout -->
                     <CheckoutBasket :totalPrice="totalPrice" :transfers="checkoutRequestModel.transfers" />
                  </div>
               </div>
            </div>
         </div>
      </Main>
   </div>
</template>

<script>
import Aside from "@/components/unitary/Aside.vue";
import Navbar from "@/components/unitary/Navbar.vue";
import Main from "@/components/unitary/Main.vue";

import { generateUuid, setCookie, getCookie } from "@/utils.js";
import { transferService } from "@/service/index.js";
import Popup from "@/gts-popup.js";
import debounce from "lodash.debounce";
import TravelerInformations from "@/components/structural/transfer/checkout/TravelerInformations.vue";
import TransferInformations from "@/components/structural/transfer/checkout/TransferInformations.vue";
import CheckoutBasket from "@/components/structural/transfer/checkout/CheckoutBasket.vue";
// import CheckoutContact from "@/components/structural/transfer/checkout/CheckoutContact.vue";
import CheckoutNotes from "@/components/structural/transfer/checkout/CheckoutNotes.vue";
import PaymentPurchaseWidget from "@/components/structural/payment/PaymentPurchaseWidget.vue";
// import TransferDescriptions from "@/components/structural/transfer/checkout/TransferDescriptions.vue";
import CommonCheckoutContact from "@/components/unitary/CommonCheckoutContact.vue";

import moment from "moment";

export default {
   name: "TransferCheckoutPage",
   components: {
      Aside,
      Main,
      Navbar,
      CheckoutBasket,
      // CheckoutContact,
      CommonCheckoutContact,
      CheckoutNotes,
      TransferInformations,
      TravelerInformations,
      PaymentPurchaseWidget,
   },
   data() {
      return {
         checkoutResponse: Object,
         isPageLoad: false,
         refundable: false,
         checkoutRequestModel: {
            searchUuid: null,
            checkoutUuid: null,
            trxType: 1,
            transfers: {},
            travellers: [],
            contact: {
               index: 0,
               gender: 0,
               name: null,
               surname: null,
               email: null,
               phone: null,
               language: null,
            },
            payment: {
               paymentType: 1,
            },
            agencyNote: "",
            lastVoidDateTime: moment().add("days", -1)._d,
            totalPrice: {
               agencyCommissionExtra: 0,
               agencyCommissionRatio: 0,
            },
         },
         generatedCheckoutUuid: "",
         generatedSearchUuid: "",
         totalPrice: {},
         pickupDateTime: new Date(),
      };
   },
   computed: {
      PaymentChoiceDetail() {
         return this.checkoutResponse ? this.checkoutResponse.paymentChoiceDetail : null;
      },
   },
   mounted() {
      this.checkoutRequestModel.checkoutUuid = generateUuid();
      this.transferCheckout();
   },
   methods: {
      transferCheckout: debounce(function () {
         var self = this;
         self.checkoutRequestModel.searchUuid = this.$route.params.uuid;

         transferService
            .transferCheckout(self.checkoutRequestModel)
            .then((response) => {
               if (response.result.success) {
                  self.checkoutResponse = response;
                  self.isPageLoad = true;
                  self.checkoutRequestModel.transfers = response.transfers;
                  self.checkoutRequestModel.travellers = response.travellers;
                  self.checkoutRequestModel.contact = response.contact;
                  self.checkoutRequestModel.agencyNote = response.agencyNote;
                  self.pickupDateTime = moment(response.pickupDateTime)._d;
                  self.checkoutRequestModel.lastVoidDateTime = moment(response.lastVoidDateTime)._d;
                  self.totalPrice = response.totalPrice;
                  self.checkoutRequestModel.totalPrice = response.totalPrice;
                  self.handlerCookieData(response.travellers);
               } else {
                  if (response.result.code == "EXPIRED") {
                     Popup.result(response.result);
                  } else {
                     Popup.result(response.result);
                  }
               }
            })
            .then(() => {
               self.generatedCheckoutUuid = self.checkoutRequestModel.checkoutUuid;
               self.generatedSearchUuid = self.checkoutRequestModel.searchUuid;
               this.$store.commit("app/setIsProgressGifActive", false);
            });
      }, 1000),

      handlerCookieData(travelers) {
         const self = this;
         const currentTravelerCountCookie = {};

         for (const traveler of travelers) {
            const travelerType = traveler.type;
            currentTravelerCountCookie[travelerType] = (currentTravelerCountCookie[travelerType] || 0) + 1;
         }
         if (getCookie("travelerData")) {
            const travelerData = JSON.parse(getCookie("travelerData"));
            let travelerDataCount = {};
            for (const traveler of travelerData) {
               const travelerType = traveler.type;
               travelerDataCount[travelerType] = (travelerDataCount[travelerType] || 0) + 1;
            }
            if (JSON.stringify(currentTravelerCountCookie) === JSON.stringify(travelerDataCount) && travelerData) {
               self.$store.commit("transfer/setTravelerCookieData", travelerData);
            }
         }
         setCookie("travelerCount", JSON.stringify(currentTravelerCountCookie), 0, 1);
      },
      emitCookieData(travelers) {
         this.checkoutRequestModel.travellers = travelers;
      },
      setCheckoutContact(contactData) {
         this.checkoutRequestModel.contact.phone = contactData.phone;
         this.checkoutRequestModel.contact.email = contactData.email;
      },
      setCheckoutAgencyNote(note) {
         this.checkoutRequestModel.agencyNote = note;
      },
      funcValidateForm() {
         var requiredEls = document.querySelectorAll(".v-required");
         var arrayOfNodes = Array.from(requiredEls);
         var first = true;
         var isFormDone = arrayOfNodes.every((el) => {
            let result = el.dataset.isValid === "true";
            el.dataset.allowSubmit = result;
            if (first) {
               el.scrollIntoView();
               first = false;
            }
            return result;
         });
         if (isFormDone) document.body.classList.remove("g-form-invalid");
         else document.body.classList.add("g-form-invalid");
         return isFormDone;
      },
   },
   watch: {
      "checkoutRequestModel.travellers": {
         handler: function (travellers) {
            if (travellers != null && travellers.length > 0) {
               this.checkoutRequestModel.contact.name = travellers[0].name;
               this.checkoutRequestModel.contact.surname = travellers[0].surname;
            }
         },
         deep: true,
      },
   },
};
</script>

<style lang="scss">
.transferCheckout-page-wrapper {
   padding-bottom: 10rem;

   & .form-item {
      width: 100%;

      & > ::v-deep label {
         font-size: 16px;
         font-weight: 700;
         color: #363940;
      }
      textarea {
         resize: none;
      }
   }
   .transferCheckout-page-inner {
      position: relative;
      width: 100%;
      margin: auto;

      .transfer-checkout-inner-wrapper {
         width: 85%;
         max-width: 85%;

         .checkout-section {
            padding: 42px 32px;
            margin-bottom: 24px;
            background-color: white;
            border: 1px solid #cccccc;
            border-radius: 12px;

            &:last-child {
               margin-bottom: 24px;
            }

            .checkout-section-inner {
               .checkout-section-title {
                  margin-bottom: 16px;

                  h4 {
                     font-size: 16px;
                     font-weight: 600;
                     color: #363940;
                  }
               }
            }
         }
      }
   }
}
</style>
