<template>
   <div class="acc-page-container sub-page-wrapper">
      <Aside />
      <Main>
         <Navbar />

         <div class="container-fluid">
            <div class="row">
               <div class="col gts-box mx-3">
                  <h1 class="sub-page-header">{{ $t("accountTransactionList_title") }}</h1>

                  <div class="sub-page-row-wrapper row gy-3">
                     <div class="col-12 col-md-6 col-lg-2">
                        <UIDatePicker2
                           :label="$t('accountTransactionList_filter_lblFromDate')"
                           :min="minDate"
                           :max="maxDate"
                           v-model="searchQuery.date1" />
                     </div>
                     <div class="col-12 col-md-6 col-lg-2">
                        <UIDatePicker2
                           :label="$t('accountTransactionList_filter_lblToDate')"
                           :min="minDate"
                           :max="maxDate"
                           v-model="searchQuery.date2" />
                     </div>
                  </div>

                  <!--  CTA -->
                  <div class="sub-page-actions-row row">
                     <div class="sub-pages-actions-wrapper col-12">
                        <UIButton
                           className="default"
                           :text="$t('accountTransactionList_filter_btnList')"
                           @click.native="funcListTransactions(false)" />
                     </div>
                  </div>
               </div>
            </div>

            <div class="page-inner">
               <section class="page-section gts-box" v-if="show">
                  <GtsGrid :table-options="mainTableOptions" :title="$t('accountTransactionList_tblMain_title')" :item-per-page="-1" />
               </section>

               <section ref="sectionSummary" class="page-section gts-box" v-if="show">
                  <GtsGrid :table-options="summaryTableOptions" :title="$t('accountTransactionList_tblSumm_title')" :item-per-page="-1" />
               </section>
            </div>
         </div>
      </Main>
   </div>
</template>

<script>
import moment from "moment";
import i18n from "@/fmt/i18n.js";
import Popup from "@/gts-popup.js";
import { financeService } from "@/service/index.js";

import Main from "@/components/unitary/Main.vue";
import Aside from "@/components/unitary/Aside.vue";
import Navbar from "@/components/unitary/Navbar.vue";
import GtsGrid from "@/components/utils/GtsGrid.vue";
import UIButton from "@/components/unitary/Button.vue";
import UIDatePicker2 from "@/components/unitary/form2/UIDatePicker2.vue";

export default {
   name: "AccTransactionList",
   components: {
      Aside,
      Navbar,
      Main,
      GtsGrid,
      UIButton,
      UIDatePicker2,
   },
   data() {
      return {
         isFirstSearch: true,
         show: false,
         maxDate: moment().format(this.$constants.dateFormat),
         minDate: moment().subtract(1, "year").startOf("year").format(this.$constants.dateFormat),

         searchQuery: {
            date1: moment().format(this.$constants.dateFormat),
            date2: moment().format(this.$constants.dateFormat),
         },
         mainTableOptions: {
            columns: [
               { label: `${i18n.t("accountTransactionList_tblMain_thDebt")}` },
               { label: `${i18n.t("accountTransactionList_tblMain_thCredit")}` },
               { label: `${i18n.t("accountTransactionList_tblMain_thBalance")}` },
               { label: `${i18n.t("accountTransactionList_tblMain_thDate")}` },
               { label: `${i18n.t("accountTransactionList_tblMain_thTrxType")}` },
               { label: `${i18n.t("accountTransactionList_tblMain_thInfo")}` },
               { label: `${i18n.t("accountTransactionList_tblMain_thOrderId")}` },
               { label: `${i18n.t("accountTransactionList_tblMain_thEntity")}` },
               { label: `${i18n.t("accountTransactionList_tblMain_thProvider")}` },
               { label: `${i18n.t("accountTransactionList_tblMain_thUserEmail")}` },
            ],
            rows: [],
         },
         summaryTableOptions: {
            columns: [
               { label: `${i18n.t("accountTransactionList_tblSumm_thTrxType")}` },
               { label: `${i18n.t("accountTransactionList_tblSumm_thDebt")}` },
               { label: `${i18n.t("accountTransactionList_tblSumm_thCredit")}` },
               { label: `${i18n.t("accountTransactionList_tblSumm_thBalance")}` },
            ],
            rows: [],
         },
      };
   },
   methods: {
      getTableColumns() {
         return [
            { label: `${i18n.t("accountTransactionList_tblMain_thDebt")}` },
            { label: `${i18n.t("accountTransactionList_tblMain_thCredit")}` },
            { label: `${i18n.t("accountTransactionList_tblMain_thBalance")}` },
            { label: `${i18n.t("accountTransactionList_tblMain_thDate")}` },
            { label: `${i18n.t("accountTransactionList_tblMain_thTrxType")}` },
            { label: `${i18n.t("accountTransactionList_tblMain_thInfo")}` },
            { label: `${i18n.t("accountTransactionList_tblMain_thOrderId")}` },
            { label: `${i18n.t("accountTransactionList_tblMain_thEntity")}` },
            { label: `${i18n.t("accountTransactionList_tblMain_thProvider")}` },
            { label: `${i18n.t("accountTransactionList_tblMain_thUserEmail")}` },
         ];
      },
      getSummaryTableOptions() {
         return [
            { label: `${i18n.t("accountTransactionList_tblSumm_thTrxType")}` },
            { label: `${i18n.t("accountTransactionList_tblSumm_thDebt")}` },
            { label: `${i18n.t("accountTransactionList_tblSumm_thCredit")}` },
            { label: `${i18n.t("accountTransactionList_tblSumm_thBalance")}` },
         ];
      },
      updateTableColumns() {
         this.mainTableOptions.columns = this.getTableColumns();
         this.summaryTableOptions.columns = this.getSummaryTableOptions();
      },
      funcListTransactions(scrollToSummary) {
         const self = this;

         self.show = false;
         self.mainTableOptions.rows.length = 0;
         self.summaryTableOptions.rows.length = 0;
         self.$store.commit("app/setIsProgressGifActive", true);

         const request = {
            date1: self.searchQuery.date1,
            date2: self.searchQuery.date2,
         };

         financeService
            .listTransactions(request)
            .then((response) => {
               self.$store.commit("app/setIsProgressGifActive", false);

               if (!response.result.success) {
                  response.result.service = this.$options.name;
                  Popup.result(response.result);
                  return;
               }
               this.isFirstSearch = false;
               //HEADER_ROW
               this.mainTableOptions.rows.push({
                  debt: "",
                  credit: "",
                  balance: {
                     iteration: [this.$options.filters.price(response.balanceBegin, response.currency)],
                     className: "is-price",
                     filter: "price",
                  },
                  datetime: "",
                  trxType: {
                     iteration: [`${i18n.t("accountTransactionList_tblMain_thBalanceBegin")}`],
                     className: "txt-black",
                  },
                  info: "",
                  orderId: "",
                  entity: "",
                  provider: "",
                  userEmail: "",
                  tags: "",
               });

               //MAIN_TABLE
               response.trxes.map((trx) => {
                  this.mainTableOptions.rows.push({
                     debt: {
                        iteration: [this.$options.filters.price(trx.debt, trx.currency)],
                        className: "is-price",
                     },
                     credit: {
                        iteration: [this.$options.filters.price(trx.credit, trx.currency)],
                        className: "is-price",
                     },
                     balance: {
                        iteration: [this.$options.filters.price(trx.balance, trx.currency)],
                        className: "is-price",
                     },
                     datetime: this.$options.filters.FormatterDateTime(trx.datetime),
                     trxType: {
                        iteration: [self.$i18n.t("EAccTransactionTypeGroup_" + trx.trxType)],
                     },
                     info: trx.info,
                     orderId:
                        trx.orderId <= 0
                           ? ""
                           : {
                                iteration: [trx.orderId],
                                className: "order-router gts-animation-scale",
                                router: `/order/detail/${trx.orderUuid}`,
                             },
                     entity: trx.entity,
                     provider:
                        trx.entityType == 3
                           ? trx.channelOnUs && trx.channelOnUsContract
                              ? trx.provider
                              : i18n.t("accountTransactionList_tblMain_trProvider")
                           : trx.provider,
                     userEmail: trx.userEmail,
                     tags: JSON.stringify(trx),
                  });
               });

               //FOOTER_ROW_1
               this.mainTableOptions.rows.push({
                  debt: "",
                  credit: "",
                  balance: {
                     iteration: [response.balanceEnd],
                     className: "is-price",
                     filter: "price",
                  },
                  datetime: "",
                  trxType: {
                     iteration: [`${i18n.t("accountTransactionList_tblMain_thBalanceEnd")}`],
                     className: "txt-black",
                  },
                  info: "",
                  orderId: "",
                  entity: "",
                  provider: "",
                  userEmail: "",
                  tags: "",
               });

               //FOOTER_ROW_2
               this.mainTableOptions.rows.push({
                  debt: {
                     iteration: [response.trxDebt],
                     className: "is-price",
                     filter: "price",
                  },
                  credit: {
                     iteration: [response.trxCredit],
                     className: "is-price",
                     filter: "price",
                  },
                  balance: {
                     iteration: [response.balanceTrx],
                     className: "is-price",
                     filter: "price",
                  },
                  datetime: "",
                  trxType: {
                     iteration: [`${i18n.t("accountTransactionList_tblMain_thTrxSum")}`],
                     className: "txt-black",
                  },
                  info: "",
                  orderId: "",
                  entity: "",
                  provider: "",
                  userEmail: "",
                  tags: "",
               });

               //SUMMARY_TABLE
               Object.keys(response.summary).forEach(function (key) {
                  let item = response.summary[key];
                  self.summaryTableOptions.rows.push({
                     trxType: {
                        iteration: [self.$i18n.t("EAccTransactionTypeGroup_" + item.trxType)],
                     },
                     debt: {
                        iteration: [item.debt],
                        className: "is-price",
                        filter: "price",
                     },
                     credit: {
                        iteration: [item.credit],
                        className: "is-price",
                        filter: "price",
                     },
                     balance: {
                        iteration: [item.balance],
                        className: "is-price",
                        filter: "price",
                     },
                     tags: JSON.stringify(item),
                  });
               });

               self.show = true;
            })
            .then(() => {
               if (scrollToSummary) self.$refs.sectionSummary.scrollIntoView({ behavior: "smooth" });
            });
      },
   },
   watch: {
      "$i18n.locale"() {
         if (this.isFirstSearch == false) {
            this.funcListTransactions();
         }
         this.updateTableColumns();
      },
   },
   created() {
      if (this.$route.query.deposit) {
         this.funcListTransactions(true);
      }
   },
};
</script>

<style lang="scss" scoped>
.page-container {
   .page-inner {
      width: 90%;
      margin: auto;
      .page-form-row {
         display: grid;
         grid-template-columns: repeat(6, 1fr);
         grid-column-gap: 1rem;
      }
      .page-action-row {
         margin-top: 1rem;
         display: flex;
         justify-content: flex-end;
      }
   }
}
</style>
