<template>
   <div class="guest-dropdown-wrapper">
      <label>{{ label }}</label>
      <button class="guest-dropdown dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
         <span>
            <i class="gts-icon icon-room-pax icon"></i>
            {{ rooms.length }} {{ $t("hotelSearchGuest_phRoomCount") }}, {{ guestTotalCount.adult }} {{ $t("hotelSearchGuest_phAdultCount") }}
            <span v-if="guestTotalCount.child != 0">{{ "," }} {{ guestTotalCount.child }} {{ $t("hotelSearchGuest_phChildCouunt") }}</span>
         </span>
      </button>
      <div class="guest-dropdown-menu dropdown-menu" aria-labelledby="dropdownMenuButton1">
         <div class="guest-dropdown-menu-inner">
            <div class="guest-dropdown-menu-section guest-room-section" v-for="(roomItem, index) in rooms" :key="index">
               <div class="guest-room-section-inner">
                  <div class="guest-room-title">
                     <span>
                        <strong>{{ index + 1 }}. {{ $t("hotelSearchGuest_lblRoomCount") }}</strong>
                     </span>
                     <button id="btn_removeRoom" @click="handlerRemoveRoom(index)" v-if="index > 0">
                        {{ $t("hotelSearchGuest_btnRemoveRoom") }}
                     </button>
                  </div>
                  <div class="guest-info">
                     <div class="guest-info-item">
                        <div class="guest-info-label">
                           <span>{{ $t("hotelSearchGuest_lblAdult") }}</span>
                        </div>
                        <div class="guest-info-counter">
                           <button class="decrement" @click="handlerDecrementGuestCount('adults', index)" :disabled="roomItem.adults == 1">-</button>
                           <span class="count">{{ roomItem.adults }}</span>
                           <button class="increment" @click="handlerIncrementGuestCount('adults', index)">+</button>
                        </div>
                     </div>
                     <div class="guest-info-item">
                        <div class="guest-info-label">
                           <span>{{ $t("hotelSearchGuest_lblChild") }}</span>
                        </div>
                        <div class="guest-info-counter">
                           <button class="decrement" @click="handlerDecrementGuestCount('children', index)" :disabled="roomItem.children == 0">
                              -
                           </button>
                           <span class="count">{{ roomItem.children }}</span>
                           <button class="increment" @click="handlerIncrementGuestCount('children', index)">+</button>
                        </div>
                     </div>
                     <div class="guest-info-item" v-for="(childAgeItem, index) in roomItem.children" :key="index">
                        <div class="guest-info-label">
                           <span>{{ index + 1 }}. {{ $t("hotelSearchGuest_lblChild") }}</span>
                        </div>
                        <div class="guest-info-age">
                           <select
                              :name="`select_childrenAge-${index + 1}`"
                              :id="`select_childrenAge-${index + 1}`"
                              v-model="roomItem.childrenAges[index]">
                              <!-- <option disabled selected value="undefined">{{ "$t("hotelSearchGuest_lblChildAge")" }}</option> -->
                              <option disabled selected value="undefined">{{ $t("common_chooseChildAge") }}</option>
                              <option v-for="index in 19" :key="index" :value="index - 1">{{ index - 1 }}</option>
                           </select>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <div class="guest-dropdown-menu-section room-cta-section">
               <UIButton className="default" icon="add-leg" rounded="true" @click.native="handlerAddRoom" v-if="this.rooms.length < 5" />
            </div>
         </div>
      </div>
   </div>
</template>

<script>
import UIButton from "@/components/unitary/Button.vue";

export default {
   name: "SearchGuestComponent",
   components: {
      UIButton,
   },
   props: {
      label: String,
   },
   data() {
      return {
         rooms: [
            {
               adults: 2,
               children: 0,
               childrenAges: [],
            },
         ],
         guestTotalCount: {
            adult: 2,
            child: 0,
         },
      };
   },
   methods: {
      handlerRemoveRoom(index) {
         var self = this;
         event.stopPropagation();
         if (self.rooms.length > 1) {
            self.rooms.splice(index, 1);
         } else {
            return;
         }
      },
      handlerAddRoom() {
         var self = this;
         event.stopPropagation();
         // Tech-debt
         var guestObject = {
            adults: 2,
            children: 0,
            childrenAges: [],
         };
         if (self.rooms.length < 5) {
            self.rooms.push(guestObject);
         }
      },
      handlerDecrementGuestCount(guestType, index) {
         var self = this;
         event.stopPropagation();
         switch (guestType) {
            case "adults":
               if (self.rooms[index].adults === 0) {
                  return;
               } else {
                  self.rooms[index].adults -= 1;
               }
               break;
            case "children":
               if (self.rooms[index].children === 0) {
                  return;
               } else {
                  self.rooms[index].children -= 1;

                  if (self.rooms[index].children == 0) {
                     self.rooms[index].childrenAges = [];
                  } else if (self.rooms[index].children < self.rooms[index].childrenAges.length) {
                     self.rooms[index].childrenAges.splice(-1, 1);
                  }
               }
               break;
            default:
               break;
         }
      },
      handlerIncrementGuestCount(guestType, index) {
         var self = this;
         event.stopPropagation();
         switch (guestType) {
            case "adults":
               if (self.rooms[index].adults < 10) {
                  self.rooms[index].adults += 1;
               }
               break;
            case "children":
               if (self.rooms[index].children < 5) {
                  self.rooms[index].children += 1;
               }
               break;
         }
      },
   },
   created() {
      var self = this;

      this.$emit("update-to-rooms", self.rooms);
   },
   watch: {
      rooms: {
         handler: function (newValue) {
            var self = this;
            var adultCount = 0;
            var childCount = 0;

            for (var i = 0; i < newValue.length; i++) {
               adultCount += newValue[i].adults;
               childCount += newValue[i].children;
            }

            self.guestTotalCount = {
               adult: adultCount,
               child: childCount,
            };

            this.$emit("update-to-rooms", newValue);
         },
         deep: true,
      },
   },
};
</script>

<style lang="scss" scoped>
.guest-dropdown-wrapper {
   label {
      margin-bottom: 0.5rem;
      font-weight: 500;
   }
   .guest-dropdown {
      width: 100%;
      height: 50px;
      max-height: 50px;
      padding: 0 16px;
      border: 1px solid #cacbcc;
      background: none;
      border-radius: 12px;
      text-align: left;
      color: var(--txt-black);
      border: 1px solid #ccc;
      background: #f8f8f8;

      &::after {
         display: none;
      }

      & > span {
         display: flex;
         align-items: center;

         i {
            width: 28px !important;
            margin-right: 8px;
         }
      }
   }
   .guest-dropdown-menu {
      width: 215px;
      max-width: 215px;
      background-color: var(--c-primary);
      color: var(--txt-black);
      .guest-dropdown-menu-inner {
         padding: 20px 16px;
         border-radius: 0.5rem;
         box-shadow: rgba(0, 0, 0, 0.2) 0px 10px 30px 0px;
         .guest-dropdown-menu-section {
            margin-bottom: 24px;

            &:last-child {
               margin-bottom: 0;
            }
            .guest-room-section-inner {
               .guest-room-title {
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  width: 100%;
                  margin-bottom: 16px;

                  & > button {
                     background: none;
                     border: none;
                     color: #059fe9;
                  }
               }
               .guest-info {
                  .guest-info-item {
                     display: flex;
                     align-items: center;
                     justify-content: space-between;
                     margin-bottom: 16px;

                     &:last-child {
                        margin-bottom: 0;
                     }

                     .guest-info-label {
                     }
                     .guest-info-counter {
                        display: flex;
                        align-items: center;
                        border: 1px solid #cacbcc;
                        border-radius: 8px;

                        .decrement,
                        .increment {
                           display: inline-block;
                           padding: 4px 8px;
                           background: none;
                           border: none;
                           color: var(--txt-black);
                        }

                        .decrement {
                           border-right: 1px solid #cacbcc;
                        }
                        .count {
                           width: 32px;
                           max-width: 32px;
                           text-align: center;
                        }
                        .increment {
                           border-left: 1px solid #cacbcc;
                        }
                     }

                     .guest-info-age {
                        width: 45%;
                        select {
                           width: 100%;
                           padding: 4px 8px;
                           border: 1px solid #cacbcc;
                           border-radius: 8px;
                        }
                     }
                  }
               }
            }

            &.guest-room-section {
               padding-bottom: 20px;
               border-bottom: 1px solid #cacbcc;
            }
            &.room-cta-section {
               display: flex;
               justify-content: center;
               align-items: center;

               & > button {
                  background: none;
               }
            }
         }
      }
   }
}
</style>
