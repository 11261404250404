<template>
   <div class="widget-base-container" :class="(highlighter ? 'add-accent' : '', headless ? 'headless' : '')">
      <Header :properties="properties" v-if="!headless" side />
      <!--    widget-base-container drag için propları 
      @dragover.prevent
      @dragenter.prevent
      @dragstart="handleDragStart(properties)"
      @dragover="handleDragOver($event, properties)"
      @dragleave="handleDragLeave($event)"
      @dragend="handlerDragEnd($event, properties)"
      @drop="handlerDrop(properties)" -->

      <Body :properties="properties" @dynamicFeed="handlerDynamicFeed" />
      <div class="table-info-container" v-if="properties.type == 2 && !properties.pending">
         <span class="table-info-icon"></span>
         <span class="table-info-text">{{ this.$store.state.app.reportUpcomingsDateInfo }}</span>
      </div>
      <div class="table-info-container" v-if="properties.type == 3 && !properties.pending">
         <span class="table-info-icon"></span>
         <span class="table-info-text">{{ this.$store.state.app.reportGirosDateInfo + " / " + $t("dashboardGiro_giroInfoText") }}</span>
      </div>
   </div>
</template>

<script>
import Header from "@/components/widgets/base/Header.vue";
import Body from "@/components/widgets/base/Body.vue";

export default {
   name: "Base",
   props: {
      properties: Object,
      headless: Boolean,
   },
   data() {
      return {
         // draggable: false,
         highlighter: false,
      };
   },
   components: {
      Header,
      Body,
   },
   methods: {
      // handlerDraggableStatus(status) {
      //    this.draggable = status;
      // },
      // handleDragStart(widget) {
      //    this.$emit("dragFrom", { type: widget.type });
      // },
      // handleDragOver(ev, widget) {
      //    const dragFrom = this.$parent.dragFrom?.type;
      //    if (dragFrom != widget.type) {
      //       if (!ev.currentTarget.classList.contains("widget-droppable")) {
      //          ev.currentTarget.classList.add("widget-droppable");
      //       }
      //    }
      // },
      // handleDragLeave(ev) {
      //    ev.target.classList.remove("widget-droppable");
      // },
      // handlerDragEnd(ev, widget) {
      //    this.draggable = false;
      //    widget;
      // },
      // handlerDrop(widget) {
      //    this.$emit("dropTo", { type: widget.type });
      // },
      handlerDynamicFeed(fetch) {
         this.$emit("dynamicFeed", fetch);
      },
   },
   watch: {
      // draggable: {
      //    handler: function (status) {
      //       if (!status) {
      //          document.querySelectorAll(".widget-droppable").forEach((el) => {
      //             el.classList.remove("widget-droppable");
      //          });
      //       }
      //    },
      // },
   },
};
</script>

<style lang="scss">
.widget-base-container {
   background-color: #fff;
   box-shadow: rgba(0, 0, 0, 0.1) 0px 3px 12px;
   border-radius: 1rem;
   padding: 1.5rem 0;
   height: 100%;
   width: 100%;
   position: relative;

   .table-info-container {
      display: flex;
      align-items: center;
      position: absolute;
      bottom: 8px;
      left: 1rem;
      right: 1rem;
      height: 32px;
      background: #e3f2fc 0% 0% no-repeat padding-box;
      border: 1px solid #4da6ff33;
      border-radius: 0.5rem;
      padding: 1.125rem 0rem;
      span {
         &.table-info-icon {
            margin-left: 8px;
            display: flex;
            width: 16px !important;
            height: 16px !important;
            min-width: 16px !important;
            background-size: cover;
            background-image: url("data:image/svg+xml;base64,PHN2ZyBpZD0iR3JvdXBfMTE3OTIiIGRhdGEtbmFtZT0iR3JvdXAgMTE3OTIiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjE2IiBoZWlnaHQ9IjE2IiB2aWV3Qm94PSIwIDAgMTYgMTYiPgogIDxwYXRoIGlkPSJFbGxpcHNlXzEzMTMiIGRhdGEtbmFtZT0iRWxsaXBzZSAxMzEzIiBkPSJNOCwwQTgsOCwwLDEsMSwwLDgsOCw4LDAsMCwxLDgsMFoiIGZpbGw9IiMyZTY3YjgiLz4KICA8ZyBpZD0iR3JvdXBfMTE3NzEiIGRhdGEtbmFtZT0iR3JvdXAgMTE3NzEiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDYuNTc0IDMuODM0KSI+CiAgICA8cGF0aCBpZD0iUGF0aF8xMDM2OSIgZGF0YS1uYW1lPSJQYXRoIDEwMzY5IiBkPSJNMTcuMDM1LDE0LjgyM2wtMi4yNy4yODUtLjA4MS4zNzcuNDQ2LjA4MmMuMjkxLjA3LjM0OS4xNzQuMjg2LjQ2NWwtLjczMSwzLjQzOGMtLjE5Mi44ODkuMSwxLjMwOC44LDEuMzA4YTIuMDUzLDIuMDUzLDAsMCwwLDEuNDUyLS41OTNsLjA4Ny0uNDEyYTEuMSwxLjEsMCwwLDEtLjY4LjI0NGMtLjI3MywwLS4zNzItLjE5MS0uMy0uNTI4WiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTE0LjYyNyAtMTEuNzYyKSIgZmlsbD0iI2ZmZiIvPgogICAgPHBhdGggaWQ9IlBhdGhfMTAzNzAiIGRhdGEtbmFtZT0iUGF0aCAxMDM3MCIgZD0iTTE3LjczMyw4Ljg2NmEuOTkxLjk5MSwwLDEsMS0uOTkxLS45OTFBLjk5MS45OTEsMCwwLDEsMTcuNzMzLDguODY2WiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTE1LjI1NSAtNy44NzUpIiBmaWxsPSIjZmZmIi8+CiAgPC9nPgo8L3N2Zz4K");
         }
         &.table-info-text {
            margin-left: 8px;
            color: var(--primary);
            font-size: var(--xsmall);
            font-weight: 500;
         }
      }
   }

   &.headless {
      background-color: transparent !important;
      box-shadow: none;
      border-radius: none;
      padding: 0;
      height: 100%;
      width: 100%;

      ::v-deep {
         .widget-body-container {
            padding: 0 !important;
         }
      }
   }

   &.widget-droppable {
      border: 5px dashed #6ab9f7;
      opacity: 0.75;
   }
}
</style>

<style lang="scss" scoped>
.add-accent {
   filter: invert(1);
}
</style>
