<template>
    <div class="gts-progress-gif-wrapper">
        <div class="gts-progress-gif-inner">
            <img v-if="appTheme == 'dark-theme'" src="../../../public/assets/globeDarkMode.gif" alt="gts dark progress gif" />
            <img v-else src="../../../public/assets/globeLightMode.gif" alt="gts light progress gif" />
            <div class="gts-progress-message" v-if="Message">
                <p>{{ Message }}</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "ProgressGif",
    data() {
        return {
            appTheme: "",
        };
    },
    created() {
        const self = this;
        let currentAppTheme = localStorage.getItem("user-theme");

        if (currentAppTheme == "dark-theme" && currentAppTheme != null) {
            self.appTheme = currentAppTheme;
        } else {
            self.appTheme = "light-theme";
        }
    },
    computed: {
        Message() {
            const self = this;
            return self.$store.state.app.progress.message;
        },
    },
};
</script>

<style lang="scss" scoped>
.gts-progress-gif-wrapper {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 999;
    .gts-progress-gif-inner {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        & > img {
            width: 150px;
            max-width: 150px;
            border-radius: 50%;
        }

        .gts-progress-message {
            background: var(--c-primary);
            padding: 1.5rem 2rem;
            margin: 1.5rem 0rem;
            border-radius: 0.5rem;

            p {
                font-weight: 500;
                font-size: var(--large);
                max-width: 500px;
            }
        }
    }
}
</style>
