<template>
   <div class="transfer-sales-report-page-container sub-page-wrapper">
      <Aside />
      <Main>
         <Navbar />

         <div class="container-fluid">
            <div class="row">
               <div class="col gts-box mx-3">
                  <h1 class="sub-page-header">{{ $t("transferSalesReport_title") }}</h1>

                  <div class="sub-page-row-wrapper row gy-3">
                     <div class="col-12 col-md-6 col-lg-2">
                        <UISelectBox
                           :key="refreshKey3"
                           :label="$t('transferSalesReport_filter_lblReportType')"
                           :options="reportTypes"
                           v-model="filters.reportType"
                           light-theme />
                     </div>
                     <div class="col-12 col-md-6 col-lg-2">
                        <UIDatePicker2
                           :key="refreshKey1"
                           :label="$t('transferSalesReport_filter_lblFromDate')"
                           :min="filters.transferDateFrom.min"
                           :max="filters.transferDateFrom.max"
                           v-model="filters.transferDateFrom.value" />
                     </div>
                     <div class="col-12 col-md-6 col-lg-2">
                        <UIDatePicker2
                           :key="refreshKey2"
                           :label="$t('transferSalesReport_filter_lblToDate')"
                           :min="filters.transferDateTo.min"
                           :max="filters.transferDateTo.max"
                           v-model="filters.transferDateTo.value" />
                     </div>
                     <div class="col-12 col-md-6 col-lg-2">
                        <UIMultiSelect
                           :key="tnrStatusListKey"
                           :label="$t('transferSalesReport_filter_lblStatus')"
                           :options="tnrStatusList"
                           v-model="filters.tnrStatuses"
                           light-theme
                           allow-search />
                     </div>
                  </div>

                  <div class="sub-page-actions-row row">
                     <div class="sub-pages-actions-wrapper col-12">
                        <UIButton className="default" :text="$t('transferSalesReport_filter_btnList')" @click.native="funcTransferSalesReport" />
                     </div>
                  </div>
               </div>
            </div>

            <!-- Summary & Sale Report -->
            <template v-if="reportLoad">
               <!-- Sale Report Summary -->
               <ReportSummary :entityType="'transfer'" :summary="summary" />

               <!-- Sale Result Container -->
               <div class="smart-table-wrapper">
                  <SmartTable :options="mainTableOptions" />
               </div>
            </template>
         </div>
      </Main>
   </div>
</template>

<script>
import moment from "moment";
import i18n from "@/fmt/i18n.js";
import Popup from "@/gts-popup.js";
import { reportService } from "@/service/index.js";
import Main from "@/components/unitary/Main.vue";
import Aside from "@/components/unitary/Aside.vue";
import Navbar from "@/components/unitary/Navbar.vue";
import SmartTable from "@/components/utils/SmartTable/SmartTableComp.vue";
import UIButton from "@/components/unitary/Button.vue";
import UISelectBox from "@/components/unitary/form2/UISelectBox.vue";
import UIDatePicker2 from "@/components/unitary/form2/UIDatePicker2.vue";
import UIMultiSelect from "@/components/unitary/form2/UIMultiSelect.vue";
import ReportSummary from "@/components/unitary/SalesReportSummary.vue";

export default {
   name: "TransferSalesReport",
   components: {
      Aside,
      Navbar,
      Main,
      UISelectBox,
      UIDatePicker2,
      UIButton,
      SmartTable,
      UIMultiSelect,
      ReportSummary,
   },

   data() {
      return {
         isFirstSearch: true,
         reportLoad: false,
         refreshKey1: 1,
         refreshKey2: 5,
         refreshKey3: 7,
         tnrStatusListKey: 0,
         reportTypes: this.$GtsDropdownData.EReportTransferDateType(),
         tnrStatusList: [],
         summary: null,
         filters: {
            reportType: 1, //1 = SALE 2=TRANSFER-DATE
            tnrStatuses: [2, 3],
            transferDateFrom: {
               min: moment().subtract(2, "years").format(this.$constants.dateFormat),
               max: moment().format(this.$constants.dateFormat),
               value: moment().format(this.$constants.dateFormat),
            },
            transferDateTo: {
               min: moment().subtract(2, "years").format(this.$constants.dateFormat),
               max: moment().format(this.$constants.dateFormat),
               value: moment().format(this.$constants.dateFormat),
            },
         },
         mainTableOptions: {
            columns: [
               { label: `${i18n.t("transferSalesReport_tblMain_thOrderId")}`, status: true },
               { label: `${i18n.t("transferSalesReport_tblMain_thTnr")}`, status: true },
               { label: `${i18n.t("transferSalesReport_tblMain_thStatus")}`, status: true },
               { label: `${i18n.t("transferSalesReport_tblMain_thAgencyName")}`, status: true },
               { label: `${i18n.t("transferSalesReport_tblMain_thTransferDate")}`, status: true },
               { label: `${i18n.t("transferSalesReport_tblMain_thFrom")}`, status: true },
               { label: `${i18n.t("transferSalesReport_tblMain_thTo")}`, status: true },
               // { label: `${i18n.t("transferSalesReport_tblMain_thTransferType")}`, status: true },
               { label: `${i18n.t("transferSalesReport_tblMain_thVehicleType")}`, status: true },
               { label: `${i18n.t("transferSalesReport_tblMain_thTravelerCount")}`, status: true },
               { label: `${i18n.t("transferSalesReport_tblMain_thSaleDate")}`, status: true },
               { label: `${i18n.t("transferSalesReport_tblMain_thTravelerName")}`, status: true },
               { label: `${i18n.t("transferSalesReport_tblMain_thTravelerSurname")}`, status: true },
               { label: `${i18n.t("transferSalesReport_tblMain_thAgencyCommission")}`, status: true },
               { label: `${i18n.t("transferSalesReport_tblMain_thTotal")}`, status: true },
               { label: `${i18n.t("transferSalesReport_tblMain_thProvider")}`, status: true },
            ],
            rows: [],
            rules: {
               autoMerge: ["orderId"],
               dominantCell: "orderId",
               showOnMobile: [0, 2, 4, 5, 6],
            },
            options: {
               header: {
                  title: this.$options.name,
                  viewOptions: {
                     status: true,
                     perPage: 50,
                     selects: [
                        { name: `3 ${i18n.t("common_order")}`, value: 3 },
                        { name: `5 ${i18n.t("common_order")}`, value: 5 },
                        { name: `25 ${i18n.t("common_order")}`, value: 25 },
                        { name: `50 ${i18n.t("common_order")}`, value: 50 },
                        { name: `100 ${i18n.t("common_order")}`, value: 100 },
                        { name: `${i18n.t("common_all")}`, value: null },
                     ],
                  },
                  viewMode: "default",
                  saleDateIndex: 9,
               },
               footer: {
                  pagination: {
                     pages: [],
                     activepage: 1,
                  },
               },
            },
         },
      };
   },
   computed: {
      Direct() {
         return i18n.t("transferSalesReport_tblMain_tdChannelGts");
      },
      Provider() {
         return i18n.t("transferSalesReport_tblMain_tdChannelProvider");
      },
   },
   mounted() {
      this.updateTnrStatusList();
   },
   methods: {
      getTableColumns() {
         return [
            { label: `${i18n.t("transferSalesReport_tblMain_thOrderId")}`, status: true },
            { label: `${i18n.t("transferSalesReport_tblMain_thTnr")}`, status: true },
            { label: `${i18n.t("transferSalesReport_tblMain_thStatus")}`, status: true },
            { label: `${i18n.t("transferSalesReport_tblMain_thAgencyName")}`, status: true },
            { label: `${i18n.t("transferSalesReport_tblMain_thTransferDate")}`, status: true },
            { label: `${i18n.t("transferSalesReport_tblMain_thFrom")}`, status: true },
            { label: `${i18n.t("transferSalesReport_tblMain_thTo")}`, status: true },
            // { label: `${i18n.t("transferSalesReport_tblMain_thTransferType")}`, status: true },
            { label: `${i18n.t("transferSalesReport_tblMain_thVehicleType")}`, status: true },
            { label: `${i18n.t("transferSalesReport_tblMain_thTravelerCount")}`, status: true },
            { label: `${i18n.t("transferSalesReport_tblMain_thSaleDate")}`, status: true },
            { label: `${i18n.t("transferSalesReport_tblMain_thTravelerName")}`, status: true },
            { label: `${i18n.t("transferSalesReport_tblMain_thTravelerSurname")}`, status: true },
            { label: `${i18n.t("transferSalesReport_tblMain_thAgencyCommission")}`, status: true },
            { label: `${i18n.t("transferSalesReport_tblMain_thTotal")}`, status: true },
            { label: `${i18n.t("transferSalesReport_tblMain_thProvider")}`, status: true },
         ];
      },
      updateTableColumns() {
         this.mainTableOptions.columns = this.getTableColumns();
      },
      funcTransferSalesReport() {
         const self = this;

         self.reportLoad = false;
         self.summary = null;
         self.mainTableOptions.rows.length = 0;
         self.$store.commit("app/setIsProgressGifActive", true);

         let request = {
            dateType: self.filters.reportType,
            statuses: self.filters.tnrStatuses,
            date1: self.filters.transferDateFrom.value,
            date2: self.filters.transferDateTo.value,
         };

         reportService.transferSalesReport(request).then((response) => {
            self.$store.commit("app/setIsProgressGifActive", false);

            if (!response.result.success) {
               response.result.service = this.$options.name;
               Popup.result(response.result);
               return;
            }
            this.isFirstSearch = false;
            self.summary = response.summary;

            response.transfers.map((transfer) => {
               const rowObj = {
                  orderId: {
                     text: transfer.orderId,
                     class: `gts-badge smart-table-badge-1`,
                     url: `/order/detail/${transfer.orderUuid}`,
                  },
                  tnr: transfer.tnr,
                  tnrStatus: {
                     text: this.$options.filters.ETrfTnrStatus(transfer.tnrStatus),
                     class: `tnr-badge gts-badge ETrfTnrStatus-${transfer.tnrStatus}`,
                  },
                  agencyName: transfer.agencyName,
                  transferDate: {
                     text: this.$options.filters.FormatterDate(transfer.transferDate),
                     class: "",
                  },
                  from: transfer.from,
                  to: transfer.to,
                  transferType: {
                     text: this.$options.filters.ETrfTransferType(transfer.transferType),
                     class: "",
                  },
                  // vehicleType: {
                  //    text: this.$options.filters.ETrfVehicleType(transfer.vehicleType),
                  //    class: "",
                  // },
                  travelerCount: transfer.travelerCount,
                  saleDateTime: {
                     text: this.$options.filters.FormatterDate(transfer.saleDateTime),
                     class: "",
                  },
                  travelerName: transfer.travelerName,
                  travelerSurname: transfer.travelerSurname,
                  agencyCommission: {
                     text: this.$options.filters.price(transfer.agencyCommission, transfer.currency),
                     class: "price-display",
                  },
                  total: {
                     text: this.$options.filters.price(transfer.total, transfer.currency),
                     class: "price-display",
                  },
                  provider: transfer.provider,
               };
               self.mainTableOptions.rows.push(rowObj);
            });
            self.reportLoad = true;
         });
      },
      funcRefreshKeys() {
         this.refreshKey1++;
         this.refreshKey2++;
         this.refreshKey3++;
      },
      updateTnrStatusList() {
         this.tnrStatusList = [];
         for (let i = 1; i < 11; i++) {
            const rowObj = { value: i, name: this.$options.filters.ETrfTnrStatus(i) };
            this.tnrStatusList.push(rowObj);
         }
         this.tnrStatusListKey += 1;
         this.$nextTick(() => {
            this.tnrStatusList = this.tnrStatusList.slice();
         });
      },
   },
   watch: {
      "$i18n.locale"() {
         this.reportTypes = this.$GtsDropdownData.EReportTransferDateType();
         this.updateTnrStatusList();
         if (this.isFirstSearch == false) {
            this.funcTransferSalesReport();
         }
         this.updateTableColumns();
         this.funcRefreshKeys();
      },
      "filters.reportType"() {
         if (this.filters.reportType == 1) {
            this.filters.transferDateFrom.value = moment().format(this.$constants.dateFormat);
            this.filters.transferDateTo.value = moment().format(this.$constants.dateFormat);
            this.filters.transferDateFrom.min = moment().subtract(2, "years").format(this.$constants.dateFormat);
            this.filters.transferDateFrom.max = moment().format(this.$constants.dateFormat);
            this.filters.transferDateTo.min = moment().subtract(2, "years").format(this.$constants.dateFormat);
            this.filters.transferDateTo.max = moment().format(this.$constants.dateFormat);
         } else if (this.filters.reportType == 2) {
            this.filters.transferDateFrom.value = moment().format(this.$constants.dateFormat);
            this.filters.transferDateTo.value = moment().format(this.$constants.dateFormat);
            this.filters.transferDateFrom.min = moment().subtract(2, "years").format(this.$constants.dateFormat);
            this.filters.transferDateFrom.max = moment().add(2, "years").format(this.$constants.dateFormat);
            this.filters.transferDateTo.min = moment().subtract(2, "years").format(this.$constants.dateFormat);
            this.filters.transferDateTo.max = moment().add(2, "years").format(this.$constants.dateFormat);
         }
         this.funcRefreshKeys();
      },
   },
};
</script>

<style lang="scss" scoped>
.page-container {
   .page-inner {
      width: 90%;
      margin: auto;
      .page-form-row {
         display: grid;
         grid-template-columns: repeat(6, 1fr);
         grid-column-gap: 1rem;
      }
      .page-action-row {
         margin-top: 1rem;
         display: flex;
         justify-content: flex-end;
      }
   }
}
.transfer-sales-report {
   .summary-row {
      grid-template-columns: repeat(5, 1fr) !important;
   }
   .summary-col {
      padding: 0.125rem 1rem !important;
   }
}
</style>
