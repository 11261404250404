<template>
   <div class="transfer-card-container" :data-vehicle-id="transferItem.offerId">
      <div class="transfer-card-body-wrapper">
         <!-- Vehicle Image -->
         <div class="transfer-card-image-wrapper d-none d-lg-block" :class="showImageOnMobile ? 'd-md-block' : ''">
            <img
               class="vehicle-image"
               :src="$constants.cdn + '/transfer/vehicle_type/' + transferItem.vehicle.vehicleTypeId + '.png'"
               :srcset="`${$constants.cdn + '/transfer/vehicle_type/' + transferItem.vehicle.vehicleTypeId + '.png'} 300w, 
                        ${$constants.cdn + '/transfer/vehicle_type/' + transferItem.vehicle.vehicleTypeId + '.png'} 600w, 
                        ${$constants.cdn + '/transfer/vehicle_type/' + transferItem.vehicle.vehicleTypeId + '.png'} 900w`"
               sizes="
                        (max-width: 300px) 300px,
                        (max-width: 600px) 600px,
                        (max-width: 900px) 900px,"
               loading="lazy"
               alt="Vehicle Image" />
            <div class="vehicle-representative">
               <span class="representative-text">{{ $t("transfer_RepresentativeVehicle") }}</span>
            </div>
         </div>

         <div class="transfer-card-informations-actions">
            <!-- Transfer Informations -->
            <div class="transfer-card-informations-wrapper">
               <div class="vehicle-informations-wrapper">
                  <span class="vehicle-type">{{ transferItem.vehicle.type | ETrfVehicleType }}</span>
                  <span class="vehicle-info">{{ transferItem.vehicle.info }} {{ $t("transfer_phOrSimilar") }}</span>
               </div>
               <!-- Facilities Wrapper -->
               <div class="vehicle-facilities-wrapper">
                  <div class="icon-wrapper">
                     <i class="gts-icon icon-transfer-seat"></i>
                     <span>{{ $t("TransferCard_lblMax") }} {{ transferItem.vehicle.capacity }}</span>
                  </div>

                  <div class="icon-wrapper">
                     <i class="gts-icon icon-transfer-baggage"></i>
                     <span>{{ transferItem.vehicle.baggage }} {{ $t("TransferCard_lblBaggage") }}</span>
                  </div>
               </div>
            </div>

            <!-- Action Button -->
            <div class="transfer-card-action-wrapper">
               <div class="transfer-card-action-inner-wrapper">
                  <div class="price-wrapper">
                     <span class="total-fare"> {{ transferItem.price.totalFare | price(transferItem.price.currency) }}</span>
                  </div>

                  <div class="select-vehicle-button-wrapper">
                     <UIButton
                        v-if="!isSelectedVehicle"
                        className="default_outline"
                        :text="$t('TransferCard_btnSelectCar')"
                        @click.native="handleSelectTransfer(transferItem.offerId)" />
                     <UIButton
                        v-else
                        className="default_outline"
                        :text="$t('TransferCard_btnRemoveCar')"
                        @click.native="handleDeleteTransfer(transferItem.offerId)" />
                  </div>
               </div>
            </div>
         </div>
      </div>
      <div class="transfer-card-footer-wrapper">
         <!-- <span class="card-information-txt"> {{ $t("TransferCard_lblCardInformationText") }} </span> -->
         <div class="card-facilities-icon-wrapper">
            <span><i class="gts-icon icon-transfer-air-condition icon-sm" /></span>
            <span><i class="gts-icon icon-transfer-lightning icon-sm" /></span>
         </div>
      </div>
   </div>
</template>

<script>
import UIButton from "@/components/unitary/Button.vue";
export default {
   name: "TransferCardComponent",
   props: {
      index: Number,
      transferItem: Object,
   },
   components: {
      UIButton,
   },
   data() {
      return {
         isSelectedVehicle: false,
         showImageOnMobile: false,
      };
   },
   created() {
      if (!this.$parent.IsRoundTrip) {
         this.showImageOnMobile = true;
      }
   },

   methods: {
      handleSelectTransfer(offerId) {
         const self = this;
         if (this.index == 1) {
            self.$store.commit("transfer/updateBasket", {
               departureOfferId: offerId,
               returnOfferId: self.$store.state.transfer.basket.returnOfferId,
            });
            self.isSelectedVehicle = true;
         } else if (this.index == 2) {
            self.$store.commit("transfer/updateBasket", {
               departureOfferId: self.$store.state.transfer.basket.departureOfferId,
               returnOfferId: offerId,
            });
            self.isSelectedVehicle = true;
         }
         document
            .querySelector(this.index == 1 ? ".transfer-route-1" : ".transfer-route-2")
            .querySelectorAll(".transfer-card-container")
            .forEach((card) => {
               card.classList.add("d-none");
            });
         document
            .querySelector(this.index == 1 ? ".transfer-route-1" : ".transfer-route-2")
            .querySelectorAll(`[data-vehicle-id='${offerId}']`)
            .forEach((card) => {
               card.classList.remove("d-none");
            });
      },
      handleDeleteTransfer() {
         const self = this;
         if (this.index == 1) {
            self.$store.commit("transfer/updateBasket", {
               departureOfferId: 0,
               returnOfferId: self.$store.state.transfer.basket.returnOfferId,
            });
            self.isSelectedVehicle = false;
         } else if (this.index == 2) {
            self.$store.commit("transfer/updateBasket", {
               departureOfferId: self.$store.state.transfer.basket.departureOfferId,
               returnOfferId: 0,
            });
            self.isSelectedVehicle = false;
         }
         document
            .querySelector(this.index == 1 ? ".transfer-route-1" : ".transfer-route-2")
            .querySelectorAll(".transfer-card-container")
            .forEach((card) => {
               card.classList.remove("d-none");
            });
      },
   },
   watch: {
      "$store.state.transfer.basket": {
         handler: function (res) {
            res.departureOfferId == 0 && res.returnOfferId == 0 ? (this.isSelectedVehicle = false) : undefined;
         },
      },
   },
};
</script>

<style lang="scss" scoped>
.transfer-card-container {
   position: relative;
   border: 1px solid #ccc;
   background-color: #f9f9fb;
   margin-bottom: 1rem;
   border-radius: 1rem;

   .transfer-card-body-wrapper {
      display: flex;
      padding: 1rem 0.5rem;
      justify-content: space-between;
      .transfer-card-image-wrapper {
         position: relative;
         top: -20px;
         left: -10px;
         margin: 0;
         .vehicle-image {
            max-height: 182px;
            position: absolute;
         }
      }
      .transfer-card-informations-actions {
         display: flex;
         width: 60%;
         justify-content: space-between;
         @media (max-width: 992px) {
            width: 100%;
         }
         .transfer-card-informations-wrapper {
            // margin-left: 1rem;
            display: flex;
            justify-content: space-between;
            flex-direction: column;
            .vehicle-informations-wrapper {
               display: flex;
               flex-direction: column;
               line-height: 1.5rem;
               margin-top: 0.5rem;
               span.vehicle-type {
                  font-size: var(--xlarge);
                  font-weight: 600;
               }
               span.vehicle-info {
                  font-size: var(--small);
                  font-weight: 400;
                  text-decoration: underline;
               }
            }
            .vehicle-facilities-wrapper {
               display: flex;
               $iconSize: 32px;

               .icon-wrapper {
                  display: flex;
                  align-items: center;
                  margin-right: 1rem;
                  i {
                     width: $iconSize;
                     height: $iconSize;
                  }
                  span {
                     font-size: var(--small);
                     font-weight: 500;
                     margin-left: 0.25rem;
                  }
               }
            }
         }
         .transfer-card-action-wrapper {
            padding: 0.5rem;
            display: flex;
            .transfer-card-action-inner-wrapper {
               background: #fff;
               border: 1px solid #ccc;
               border-radius: 1rem;
               padding: 0.5rem 1rem;
               display: flex;
               flex-direction: column;
               justify-content: center;

               .price-wrapper {
                  display: flex;
                  justify-content: center;
                  margin-bottom: 0.25rem;
                  .total-fare {
                     font-size: var(--xxlarge);
                     font-weight: 800;
                  }
                  .currency {
                     font-size: var(--xxlarge);
                     font-weight: 600;
                  }
               }

               .select-vehicle-button-wrapper {
                  ::v-deep {
                     button {
                        padding: 0.25rem 1rem !important;
                        font-size: var(--normal);
                        font-weight: 600;
                        height: auto !important;
                     }
                  }
               }
            }
         }
      }
   }

   .transfer-card-footer-wrapper {
      background-color: #eee;
      border-bottom-left-radius: 1rem;
      border-bottom-right-radius: 1rem;
      padding: 0.25rem 1rem;
      display: flex;
      justify-content: flex-end;

      .card-information-txt {
         font-size: var(--small);
         color: #5a5a5a;
         text-decoration: underline;
      }

      .card-facilities-icon-wrapper {
         display: flex;
      }
   }
}
</style>
