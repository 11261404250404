<template>
   <div id="flight-lazy-load">
      <lottie-animation :speed="0.75" path="assets/animations/lazyLoad.json" />
   </div>
</template>

<script>
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue";
export default {
   name: "FlightLazyLoad",
   components: {
      LottieAnimation,
   },
};
</script>

<style lang="scss" scoped>
#flight-lazy-load {
   position: fixed;
   bottom: 1.5rem;
   left: 0;
   right: 0;
   margin: auto;
   width: 160px;
   padding: 0.5rem 1rem;
   border-radius: 3rem;
   background-color: #ffffff;
   border: 1px solid #ccd7e6;
   z-index: 99;
   @media (min-width: 992px) {
      width: 200px;
      bottom: 1.5rem;
   }
}
</style>
