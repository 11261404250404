import { PUBLIC_HTTP } from "@/service/index";
import store from "@/store";
import debounce from "lodash.debounce";

const TERMINAL_JSLOGGER_ENDPOINT = "api/terminal/jslogger";
const SERVER_LOGGER_MIN_LEVEL = 4;

class JsLogger {
   /**
    * @constructor JsLogger
    */
   constructor() {
      this.initLogger();
   }

   initLogger() {
      this.writeLog = debounce((level, args) => {
         // console.dir("jsLogger::level: " + level + ", user:" + JSON.stringify(user) + " args:" + JSON.stringify(args));
         if (process.env.VUE_APP_ENV === "DEV" || process.env.VUE_APP_ENV === "PROD") {
            var user = {};
            if (store) user = store.state.user.user;

            // send server
            console.error(args[4]);
            if (level <= SERVER_LOGGER_MIN_LEVEL) {
               PUBLIC_HTTP.post(TERMINAL_JSLOGGER_ENDPOINT, {
                  level: level,
                  user: user,
                  message: JSON.stringify(args),
                  errorMsg: args[1] ? args[1] : "unidentified",
                  whereIs: args[3] ? args[3] : "unidentified",
                  component: args[4] ? args[4] : "unidentified vue component",
                  browser: {
                     url: window.location.href,
                     screenSize: `${window.innerWidth} X ${window.innerHeight}`,
                     navigator: window.navigator.userAgent,
                  },
               })
                  .then((response) => response.data)
                  .catch((error) => {
                     if (error && error.response && error.response.status === 401)
                        console.error("has error on logger write to server err:" + JSON.stringify(args));
                  });
            }
         }
      }, 2500);
   }
}

// FATAL = 1,
// ERROR = 2,
// WARN = 3,
// INFO = 4,
// DEBUG = 5
JsLogger.prototype.debug = function () {
   this.writeLog(5, arguments);
};

JsLogger.prototype.info = function () {
   this.writeLog(4, arguments);
};

JsLogger.prototype.warn = function () {
   this.writeLog(3, arguments);
};

JsLogger.prototype.error = function () {
   this.writeLog(2, arguments);
};

JsLogger.prototype.fatal = function () {
   this.writeLog(1, arguments);
};

/** Creating the instance of logger */
const logger = new JsLogger();

export { logger };
