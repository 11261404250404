import { PRIVATE_HTTP } from "./index";
import router from "@/router/index.js";

const DASHBOARD_SAVE = "api/dashboard/setdashboard";
const DASHBOARD_RESERVATIONS = "api/dashboard/GetReservations";
const DASHBOARD_UPCOMINGS = "api/dashboard/GetUpcomings";
const DASHBOARD_REPORT_GIROS = "api/dashboard/GetReportGiros";
const DASHBOARD_AGENCY_BALANCE = "api/dashboard/GetAgencyBalance";
const DASHBOARD_ANNOUNCEMENTS = "api/dashboard/GetAnnouncements";
const CHARTER_FLIGHTS = "api/dashboard/listcharterflights";
const EXPIRED_RESERVATIONS = "api/dashboard/listexpiredreservations";

export var GetReservations = (requestModel) => {
   const promise = PRIVATE_HTTP(20000).post(DASHBOARD_RESERVATIONS, requestModel);
   const responseData = promise
      .then((response) => response.data)
      .catch((error) => {
         if (error.response.status === 401) {
            if (router.currentRoute.path != "/login") {
               router.push("/login");
            }
         }
      });

   return responseData;
};

export var GetUpcomings = (requestModel) => {
   const promise = PRIVATE_HTTP(20000).post(DASHBOARD_UPCOMINGS, requestModel);
   const responseData = promise
      .then((response) => response.data)
      .catch((error) => {
         if (error.response.status === 401) {
            if (router.currentRoute.path != "/login") {
               router.push("/login");
            }
         }
      });

   return responseData;
};

export var GetReportGiros = (requestModel) => {
   const promise = PRIVATE_HTTP(20000).post(DASHBOARD_REPORT_GIROS, requestModel);
   const responseData = promise
      .then((response) => response.data)
      .catch((error) => {
         if (error.response.status === 401) {
            if (router.currentRoute.path != "/login") {
               router.push("/login");
            }
         }
      });

   return responseData;
};

export var GetAgencyBalance = (requestModel) => {
   const promise = PRIVATE_HTTP(20000).post(DASHBOARD_AGENCY_BALANCE, requestModel);
   const responseData = promise
      .then((response) => response.data)
      .catch((error) => {
         if (error.response.status === 401) {
            if (router.currentRoute.path != "/login") {
               router.push("/login");
            }
         }
      });

   return responseData;
};

export var GetAnnouncements = (requestModel) => {
   const promise = PRIVATE_HTTP(20000).post(DASHBOARD_ANNOUNCEMENTS, requestModel);
   const responseData = promise
      .then((response) => response.data)
      .catch((error) => {
         if (error.response.status === 401) {
            if (router.currentRoute.path != "/login") {
               router.push("/login");
            }
         }
      });

   return responseData;
};

export var setDashboard = (requestModel) => {
   const promise = PRIVATE_HTTP().post(DASHBOARD_SAVE, requestModel);
   const responseData = promise
      .then((response) => response.data)
      .catch((error) => {
         if (error.response.status === 401) {
            if (router.currentRoute.path != "/login") {
               router.push("/login");
            }
         }
      });

   return responseData;
};
export var expiredReservations = (requestModel) => {
   const promise = PRIVATE_HTTP().post(EXPIRED_RESERVATIONS, requestModel);
   const responseData = promise
      .then((response) => response.data)
      .catch((error) => {
         if (error.response.status === 401) {
            if (router.currentRoute.path != "/login") {
               router.push("/login");
            }
         }
      });

   return responseData;
};
export var charterFlights = (requestModel) => {
   const promise = PRIVATE_HTTP().post(CHARTER_FLIGHTS, requestModel);
   const responseData = promise
      .then((response) => response.data)
      .catch((error) => {
         if (error.response.status === 401) {
            if (router.currentRoute.path != "/login") {
               router.push("/login");
            }
         }
      });

   return responseData;
};
