<template>
   <div id="rent-a-car-page-wrapper">
      <Aside />
      <Main>
         <Navbar />
         <div id="rent-a-car-search-wrapper">
            <!-- {{ filters }} -->

            <div class="row gx-3 gx-md-2 gx-lg-3 gx-xxl-3">
               <div id="rent-a-car-filter-column" class="col-md-1 col-lg d-none d-xxl-block">
                  <!-- FlightSearch Filters -->
                  <RentACarFilters :data="Cars" v-if="response?.result.success" @filters="handlerFilters" />
               </div>
               <div class="col-lg-12 col-xl-10 col-xxl-9">
                  <!-- Common Search Tab -->
                  <SearchTab :active-tab="4" @search-response="handlerSearchResponse" />

                  <!-- FlightSearch Resul t-->
                  <!-- {{ Cars }} -->
                  <!-- {{ selection }} -->
                  <!-- {{ response }} -->
                  <div class="rent-a-card-wrapper mt-5">
                     <template v-for="(car, index) in Cars">
                        <RentACard
                           :key="index"
                           :car="car"
                           :search-uuid="response.searchUuid"
                           @selection="handlerSelection"
                           ref="carLists"
                           :currency="currency" />
                     </template>
                  </div>
               </div>
               <div class="col-md-1 col-lg d-xl-block">
                  <!-- FlightBasket -->
                  <transition name="fade" mode="out-in">
                     <RentACarBasket :basket="selection" v-if="selection && isBasketActive" :currency="currency" />
                  </transition>
                  <!-- <button @click="handlerCheckout">checkout</button> -->
               </div>
            </div>
         </div>
      </Main>
   </div>
</template>

<script>
// import { rentacarService } from "@/service/index.js";
// import { generateUuid } from "@/utils.js";

import Aside from "@/components/unitary/Aside.vue";
import Navbar from "@/components/unitary/Navbar.vue";
import Main from "@/components/unitary/Main.vue";

import SearchTab from "@/components/unitary/SearchTab.vue";

import RentACarFilters from "@/components/structural/rentacar/RentACarFilters.vue";
import RentACard from "@/components/structural/rentacar/search/RentACarCard.vue";

import RentACarBasket from "@/components/structural/rentacar/search/RentACarBasket.vue";

// import RentACarDummy from "@/pages/rentacar/RentACarDummy.js";

export default {
   name: "RentaCarSearch",
   components: {
      Aside,
      Navbar,
      Main,
      SearchTab,
      RentACarFilters,
      RentACard,
      RentACarBasket,
   },
   data() {
      return {
         response: null,
         filters: null,
         selection: null,
         isBasketActive: false,
      };
   },
   created() {
      // const a = {
      //    uuid: generateUuid(),
      //    pickUpLocationId: 1,
      //    dropOffLocationId: 1,
      //    pickUpDateTime: "2023-10-01T10:00",
      //    dropOffDateTime: "2023-10-03T11:00",
      //    agencyCommissionExtra: 10.0,
      // };
      // rentacarService.rentacarSearch(a).then((response) => {
      //    this.response = response.vehicles;
      // });
      // console.log(rentacarService, a);
   },
   computed: {
      currency() {
         return this.response?.currency;
      },
      Cars() {
         return this.response?.vehicles;
      },
   },
   methods: {
      handlerSelection(selectionUuid) {
         this.handlerGenerateCars();
         this.$set(this.response.vehicles[selectionUuid], "selected", true);
      },
      handlerSearchResponse(response) {
         this.response = this.selection = this.selection = null;

         const self = this;
         // setTimeout(() => {

         // }, 10);
         self.response = response;
         this.handlerGenerateCars();
      },
      handlerFilters(filters) {
         this.filters = filters;
         // console.log("handlerFilters", filters);
      },
      handlerGenerateCars() {
         if (this.response?.vehicles) {
            Object.keys(this.response.vehicles).forEach((vehicleUuid) => {
               this.$set(this.response.vehicles[vehicleUuid], "selected", false);
               this.$set(this.response.vehicles[vehicleUuid], "showAncillaries", false);
            });
         }
      },
      handlerUnselectAll() {
         Object.keys(this.response.vehicles).forEach((vehicle) => {
            this.response.vehicles[vehicle].selected = false;
            this.response.vehicles[vehicle].showAncillaries = false;
         });
      },
   },
   watch: {
      "$store.state.rentacar.isSearching": {
         handler: function (status) {
            if (status) {
               this.response = null;
            }
         },
      },
      selection: {
         handler: function (selection) {
            if (selection == null) {
               this.handlerUnselectAll();
            } else {
               this.isBasketActive = true;
            }
         },
         deep: true,
      },
   },
};
</script>

<style lang="scss" scoped>
#rent-a-car-search-wrapper {
}
</style>
