// import Vue from "vue";
const state = {
   isSearching: false,
   searchQuery: {},
   agencyCommissionExtra: 0,
};
const mutations = {
   setIsSearching(state, data) {
      state.isSearching = data;
   },
   setSearchQuery(state, data) {
      state.searchQuery = data;
   },
   updateAgencyCommissionExtra(state, data) {
      state.agencyCommissionExtra = data;
   },
};
export const rentacar = {
   namespaced: true,
   state,
   mutations,
};
