const facilityList = {
    baseUrl: "https://cdn.reisetech.io/hotel_facility/",
    facilities: [
        {
            id: 359,
            groupId: 10,
            priority: 5,
            code: "Balkon",
            nameTr: "Balkon",
            nameEn: "Balcony",
            nameDe: "Balkon",
            groupNameTr: "Oda Özellikleri",
            groupNameEn: "Room Features\t",
            groupNameDe: "Zimmerausstattung"
        },
        {
            id: 360,
            groupId: 10,
            priority: 6,
            code: "Fransız Balkon",
            nameTr: "Fransız Balkon",
            nameEn: "French Balcony",
            nameDe: "Französischer Balkon",
            groupNameTr: "Oda Özellikleri",
            groupNameEn: "Room Features\t",
            groupNameDe: "Zimmerausstattung"
        },
        {
            id: 348,
            groupId: 5,
            priority: 10,
            code: "Ulaşım",
            nameTr: "Tramvay",
            nameEn: "Tram",
            nameDe: "Straßenbahn",
            groupNameTr: "Otel Özellikleri",
            groupNameEn: "Hotel Features\t",
            groupNameDe: "Hotelausstattung"
        },
        {
            id: 358,
            groupId: 5,
            priority: 10,
            code: "ŞM",
            nameTr: "Şehir Merkezinde",
            nameEn: "In the City Center",
            nameDe: "im Stadtzentrum",
            groupNameTr: "Otel Özellikleri",
            groupNameEn: "Hotel Features\t",
            groupNameDe: "Hotelausstattung"
        },
        {
            id: 350,
            groupId: 5,
            priority: 11,
            code: "Ulaşım-Metro",
            nameTr: "Metro",
            nameEn: "Metro",
            nameDe: "Metro",
            groupNameTr: "Otel Özellikleri",
            groupNameEn: "Hotel Features\t",
            groupNameDe: "Hotelausstattung"
        },
        {
            id: 218,
            groupId: 12,
            priority: 12,
            code: "swimming_pool_heated",
            nameTr: "Isıtmalı Havuz",
            nameEn: "Heated Pool",
            nameDe: "Beheizter Pool",
            groupNameTr: "Aktiviteler",
            groupNameEn: "Sport / Entertainment",
            groupNameDe: "Sport / Unterhaltung\t"
        },
        {
            id: 67,
            groupId: 8,
            priority: 13,
            code: "directly_beach",
            nameTr: "Denize Sıfır",
            nameEn: "Directly By The Beach",
            nameDe: "Direkte Strandlage",
            groupNameTr: "Plaj",
            groupNameEn: "Beach",
            groupNameDe: "Strand"
        },
        {
            id: 117,
            groupId: 9,
            priority: 13,
            code: "water_slide",
            nameTr: "Su Kaydırağı",
            nameEn: "Waterslide",
            nameDe: "Wasserrutsche",
            groupNameTr: "Otel İmkanları",
            groupNameEn: "Facilities",
            groupNameDe: "Einrichtungen"
        },
        {
            id: 103,
            groupId: 9,
            priority: 13,
            code: "wifi2",
            nameTr: "Wi-Fi",
            nameEn: "WI-FI",
            nameDe: "WLAN",
            groupNameTr: "Otel İmkanları",
            groupNameEn: "Facilities",
            groupNameDe: "Einrichtungen"
        },
        {
            id: 104,
            groupId: 9,
            priority: 14,
            code: "restaurant_rs",
            nameTr: "Oda Servisi",
            nameEn: "Room Service",
            nameDe: "Zimmerservice",
            groupNameTr: "Otel İmkanları",
            groupNameEn: "Facilities",
            groupNameDe: "Einrichtungen"
        },
        {
            id: 127,
            groupId: 9,
            priority: 14,
            code: "domestic_pet_u5kg",
            nameTr: "Evcil Hayvan (5 kg altı)",
            nameEn: "Pet (Under 5 Kg)",
            nameDe: "Haustier (unter 5 kg)",
            groupNameTr: "Otel İmkanları",
            groupNameEn: "Facilities",
            groupNameDe: "Einrichtungen"
        },
        {
            id: 176,
            groupId: 12,
            priority: 14,
            code: "swimming_pool_indoor",
            nameTr: "Kapalı Havuz ",
            nameEn: "Indoor Pool",
            nameDe: "Innenpool",
            groupNameTr: "Aktiviteler",
            groupNameEn: "Sport / Entertainment",
            groupNameDe: "Sport / Unterhaltung\t"
        },
        {
            id: 177,
            groupId: 12,
            priority: 14,
            code: "swimming_pool_outdoor",
            nameTr: "Açık Havuz",
            nameEn: "Outdoor Pool",
            nameDe: "Außenpool",
            groupNameTr: "Aktiviteler",
            groupNameEn: "Sport / Entertainment",
            groupNameDe: "Sport / Unterhaltung\t"
        },
        {
            id: 118,
            groupId: 9,
            priority: 14,
            code: "Engellilerin İhtiyaçlarına Uygun",
            nameTr: "Engellilerin İhtiyaçlarına Uygun",
            nameEn: "Disability-friendly",
            nameDe: "Barrierefrei",
            groupNameTr: "Otel İmkanları",
            groupNameEn: "Facilities",
            groupNameDe: "Einrichtungen"
        },
        {
            id: 91,
            groupId: 9,
            priority: 14,
            code: "night_club",
            nameTr: "Gece Klübü",
            nameEn: "Night Club",
            nameDe: "Discothek",
            groupNameTr: "Otel İmkanları",
            groupNameEn: "Facilities",
            groupNameDe: "Einrichtungen"
        },
        {
            id: 43,
            groupId: 6,
            priority: 14,
            code: "spa_wellness_hotel ",
            nameTr: "Termal-SPA Otel",
            nameEn: "Thermal - Spa Hotel",
            nameDe: "Thermen- und Wellnesshotel",
            groupNameTr: "Otel Tipi",
            groupNameEn: "Hotel Type",
            groupNameDe: "Hotel Typ"
        },
        {
            id: 69,
            groupId: 8,
            priority: 14,
            code: "beach_seperate",
            nameTr: "Özel Plaj Alanı",
            nameEn: "Seperate Beach",
            nameDe: "Hoteleigener Strandabschnitt",
            groupNameTr: "Plaj",
            groupNameEn: "Beach",
            groupNameDe: "Strand"
        },
        {
            id: 70,
            groupId: 8,
            priority: 14,
            code: "İskele",
            nameTr: "İskele",
            nameEn: "Pier",
            nameDe: "Badesteg",
            groupNameTr: "Plaj",
            groupNameEn: "Beach",
            groupNameDe: "Strand"
        },
        {
            id: 180,
            groupId: 12,
            priority: 14,
            code: "swimming_pool_childrens",
            nameTr: "Çocuk Havuzu ",
            nameEn: "Children's Pool",
            nameDe: "Kinderbecken",
            groupNameTr: "Aktiviteler",
            groupNameEn: "Sport / Entertainment",
            groupNameDe: "Sport / Unterhaltung\t"
        },
        {
            id: 223,
            groupId: 12,
            priority: 14,
            code: "Animasyon",
            nameTr: "Animasyon",
            nameEn: "Entertainment",
            nameDe: "Unterhaltung",
            groupNameTr: "Aktiviteler",
            groupNameEn: "Sport / Entertainment",
            groupNameDe: "Sport / Unterhaltung\t"
        },
        {
            id: 225,
            groupId: 12,
            priority: 14,
            code: "turkish_bath",
            nameTr: "Hamam",
            nameEn: "Hammam",
            nameDe: "Hamam",
            groupNameTr: "Aktiviteler",
            groupNameEn: "Sport / Entertainment",
            groupNameDe: "Sport / Unterhaltung\t"
        },
        {
            id: 298,
            groupId: 15,
            priority: 14,
            code: "Mini Kulüp",
            nameTr: "Mini Kulüp",
            nameEn: "Mini Club",
            nameDe: "Miniclub",
            groupNameTr: "Bebek/Çocuk İmkanlar",
            groupNameEn: "Facilities For Babies&Kids",
            groupNameDe: "\tEinrichtungen für Babys und Kinder"
        },
        {
            id: 296,
            groupId: 15,
            priority: 14,
            code: "Bebek Bakıcısı",
            nameTr: "Bebek Bakıcısı",
            nameEn: "Baby Sitter",
            nameDe: "Babysitter",
            groupNameTr: "Bebek/Çocuk İmkanlar",
            groupNameEn: "Facilities For Babies&Kids",
            groupNameDe: "\tEinrichtungen für Babys und Kinder"
        },
        {
            id: 303,
            groupId: 15,
            priority: 14,
            code: "Çocuk Animasyonu",
            nameTr: "Çocuk Animasyonu",
            nameEn: "Children Entertainment",
            nameDe: "Kinderanimation",
            groupNameTr: "Bebek/Çocuk İmkanlar",
            groupNameEn: "Facilities For Babies&Kids",
            groupNameDe: "\tEinrichtungen für Babys und Kinder"
        },
        {
            id: 228,
            groupId: 12,
            priority: 15,
            code: "spa_wellness_thermal",
            nameTr: "Termal",
            nameEn: "Thermal",
            nameDe: "Thermal",
            groupNameTr: "Aktiviteler",
            groupNameEn: "Sport / Entertainment",
            groupNameDe: "Sport / Unterhaltung\t"
        },
        {
            id: 229,
            groupId: 12,
            priority: 15,
            code: "swimming_pool_outdoor_heated",
            nameTr: "Açık Isıtmalı Havuz",
            nameEn: "Outdoor Heated Pool",
            nameDe: "Beheizter Außenpool",
            groupNameTr: "Aktiviteler",
            groupNameEn: "Sport / Entertainment",
            groupNameDe: "Sport / Unterhaltung\t"
        },
        {
            id: 230,
            groupId: 12,
            priority: 15,
            code: "swimming_pool_indoor_heated",
            nameTr: "Kapalı Isıtmalı Havuz",
            nameEn: "Indoor Heated Pool",
            nameDe: "Beheiztes Hallenbad",
            groupNameTr: "Aktiviteler",
            groupNameEn: "Sport / Entertainment",
            groupNameDe: "Sport / Unterhaltung\t"
        },
        {
            id: 231,
            groupId: 12,
            priority: 15,
            code: "swimming_pool_outdoor_thermal",
            nameTr: "Açık Termal Havuz",
            nameEn: "Outdoor Thermal Pool",
            nameDe: "Thermalbad im Freien",
            groupNameTr: "Aktiviteler",
            groupNameEn: "Sport / Entertainment",
            groupNameDe: "Sport / Unterhaltung\t"
        },
        {
            id: 232,
            groupId: 12,
            priority: 15,
            code: "swimming_pool_indoor_thermal",
            nameTr: "Kapalı Termal Havuz",
            nameEn: "Indoor Thermal Pool",
            nameDe: "Thermalhallenbad",
            groupNameTr: "Aktiviteler",
            groupNameEn: "Sport / Entertainment",
            groupNameDe: "Sport / Unterhaltung\t"
        },
        {
            id: 178,
            groupId: 12,
            priority: 15,
            code: "swimming_pool",
            nameTr: "Tatlı Su Havuzu",
            nameEn: "Swimming Pool",
            nameDe: "Pool",
            groupNameTr: "Aktiviteler",
            groupNameEn: "Sport / Entertainment",
            groupNameDe: "Sport / Unterhaltung\t"
        },
        {
            id: 179,
            groupId: 12,
            priority: 15,
            code: "swimming_pool_saltwater",
            nameTr: "Tuzlu Su Havuzu ",
            nameEn: "Saltwater Pool",
            nameDe: "Salzwasserpool",
            groupNameTr: "Aktiviteler",
            groupNameEn: "Sport / Entertainment",
            groupNameDe: "Sport / Unterhaltung\t"
        },
        {
            id: 320,
            groupId: 12,
            priority: 15,
            code: "ws_watersports",
            nameTr: "Su Sporları",
            nameEn: "Water Sports",
            nameDe: "Wassersport",
            groupNameTr: "Aktiviteler",
            groupNameEn: "Sport / Entertainment",
            groupNameDe: "Sport / Unterhaltung\t"
        },
        {
            id: 307,
            groupId: 12,
            priority: 15,
            code: "spa_wellness",
            nameTr: "SPA Merkezi",
            nameEn: "SPA Center",
            nameDe: "SPA-Zentrum",
            groupNameTr: "Aktiviteler",
            groupNameEn: "Sport / Entertainment",
            groupNameDe: "Sport / Unterhaltung\t"
        },
        {
            id: 62,
            groupId: 8,
            priority: 20,
            code: "beach_sandy",
            nameTr: "Kumluk Plaj",
            nameEn: "Sandy Beach",
            nameDe: "Sandstrand",
            groupNameTr: "Plaj",
            groupNameEn: "Beach",
            groupNameDe: "Strand"
        },
        {
            id: 63,
            groupId: 8,
            priority: 20,
            code: "beach_pebble",
            nameTr: "Çakıl Plaj",
            nameEn: "Pebble Beach",
            nameDe: "Kiesstrand",
            groupNameTr: "Plaj",
            groupNameEn: "Beach",
            groupNameDe: "Strand"
        },
        {
            id: 73,
            groupId: 8,
            priority: 20,
            code: "beach_sandy_pebble",
            nameTr: "Kum ve Çakıl Plaj",
            nameEn: "Sandy and Pebble Beach",
            nameDe: "Sand- und Kiesstrand",
            groupNameTr: "Plaj",
            groupNameEn: "Beach",
            groupNameDe: "Strand"
        },
        {
            id: 226,
            groupId: 12,
            priority: 20,
            code: "turkish_bath2",
            nameTr: "VIP Hamam",
            nameEn: "VIP Hammam",
            nameDe: "VIP Hamam",
            groupNameTr: "Aktiviteler",
            groupNameEn: "Sport / Entertainment",
            groupNameDe: "Sport / Unterhaltung\t"
        },
        {
            id: 185,
            groupId: 12,
            priority: 20,
            code: "jakuzi_bath",
            nameTr: "Jakuzi ",
            nameEn: "Jacuzzi ",
            nameDe: "Whirlpool",
            groupNameTr: "Aktiviteler",
            groupNameEn: "Sport / Entertainment",
            groupNameDe: "Sport / Unterhaltung\t"
        },
        {
            id: 187,
            groupId: 12,
            priority: 20,
            code: "spa_wellness_steam",
            nameTr: "Buhar Odası",
            nameEn: "Steam Room",
            nameDe: "Dampfbad",
            groupNameTr: "Aktiviteler",
            groupNameEn: "Sport / Entertainment",
            groupNameDe: "Sport / Unterhaltung\t"
        },
        {
            id: 227,
            groupId: 12,
            priority: 25,
            code: "Spor Salonu",
            nameTr: "Spor Salonu",
            nameEn: "Gym",
            nameDe: "Fitnessraum",
            groupNameTr: "Aktiviteler",
            groupNameEn: "Sport / Entertainment",
            groupNameDe: "Sport / Unterhaltung\t"
        },
        {
            id: 189,
            groupId: 12,
            priority: 25,
            code: "spa_wellness_spackage",
            nameTr: "Özel Spa Paketleri ",
            nameEn: "Special Spa Package",
            nameDe: "Spezielles Spa-Paket",
            groupNameTr: "Aktiviteler",
            groupNameEn: "Sport / Entertainment",
            groupNameDe: "Sport / Unterhaltung\t"
        },
        {
            id: 305,
            groupId: 15,
            priority: 25,
            code: "swimming_pool_childrens2",
            nameTr: "Çocuk Havuzu",
            nameEn: "Children Swimming Pool",
            nameDe: "Kinderschwimmbad",
            groupNameTr: "Bebek/Çocuk İmkanlar",
            groupNameEn: "Facilities For Babies&Kids",
            groupNameDe: "\tEinrichtungen für Babys und Kinder"
        },
        {
            id: 306,
            groupId: 15,
            priority: 25,
            code: "water_slide2",
            nameTr: "Çocuk Aquapark",
            nameEn: "Children Aquapark",
            nameDe: "Aquapark für Kinder",
            groupNameTr: "Bebek/Çocuk İmkanlar",
            groupNameEn: "Facilities For Babies&Kids",
            groupNameDe: "\tEinrichtungen für Babys und Kinder"
        },
        {
            id: 213,
            groupId: 12,
            priority: 25,
            code: "Eğlence Programları ",
            nameTr: "Eğlence Programları ",
            nameEn: "Entertainment Programme",
            nameDe: "Unterhaltungsprogramm",
            groupNameTr: "Aktiviteler",
            groupNameEn: "Sport / Entertainment",
            groupNameDe: "Sport / Unterhaltung\t"
        },
        {
            id: 214,
            groupId: 15,
            priority: 25,
            code: "Çocuk Programları ",
            nameTr: "Çocuk Programları ",
            nameEn: "Children's Entertainment",
            nameDe: "Kinderanimation",
            groupNameTr: "Bebek/Çocuk İmkanlar",
            groupNameEn: "Facilities For Babies&Kids",
            groupNameDe: "\tEinrichtungen für Babys und Kinder"
        },
        {
            id: 112,
            groupId: 15,
            priority: 25,
            code: "Çocuk Klübü",
            nameTr: "Çocuk Klübü",
            nameEn: "Kids Club",
            nameDe: "Kinderclub",
            groupNameTr: "Bebek/Çocuk İmkanlar",
            groupNameEn: "Facilities For Babies&Kids",
            groupNameDe: "\tEinrichtungen für Babys und Kinder"
        },
        {
            id: 222,
            groupId: 12,
            priority: 25,
            code: "Sinema",
            nameTr: "Sinema",
            nameEn: "Cinema",
            nameDe: "Kino",
            groupNameTr: "Aktiviteler",
            groupNameEn: "Sport / Entertainment",
            groupNameDe: "Sport / Unterhaltung\t"
        },
        {
            id: 181,
            groupId: 5,
            priority: 25,
            code: "bar_pool",
            nameTr: "Havuz Bar",
            nameEn: "Pool Bar",
            nameDe: "Poolbar",
            groupNameTr: "Otel Özellikleri",
            groupNameEn: "Hotel Features\t",
            groupNameDe: "Hotelausstattung"
        },
        {
            id: 363,
            groupId: 10,
            priority: 25,
            code: "In Room Fitness Kit",
            nameTr: "Oda İçi Fitness Seti",
            nameEn: "In Room Fitness Kit",
            nameDe: "Fitness-Set im Zimmer",
            groupNameTr: "Oda Özellikleri",
            groupNameEn: "Room Features\t",
            groupNameDe: "Zimmerausstattung"
        },
        {
            id: 364,
            groupId: 10,
            priority: 26,
            code: "In Room Mini Executive Buffet",
            nameTr: "Oda İçi Executive İkramlar",
            nameEn: "In Room Mini Executive Buffet",
            nameDe: "Mini-Executive-Buffet im Zimmer",
            groupNameTr: "Oda Özellikleri",
            groupNameEn: "Room Features\t",
            groupNameDe: "Zimmerausstattung"
        },
        {
            id: 365,
            groupId: 10,
            priority: 27,
            code: "Kid Basket",
            nameTr: "Çocuklara Özel İkramlar",
            nameEn: "Special Basket For Children",
            nameDe: "Spezieller Korb für Kinder",
            groupNameTr: "Oda Özellikleri",
            groupNameEn: "Room Features\t",
            groupNameDe: "Zimmerausstattung"
        },
        {
            id: 100,
            groupId: 9,
            priority: 30,
            code: "Konferans Salonu",
            nameTr: "Konferans Salonu",
            nameEn: "Conference Room",
            nameDe: "Konferenzraum",
            groupNameTr: "Otel İmkanları",
            groupNameEn: "Facilities",
            groupNameDe: "Einrichtungen"
        },
        {
            id: 107,
            groupId: 9,
            priority: 30,
            code: "health",
            nameTr: "Sağlık Odası",
            nameEn: "Medical Assistance",
            nameDe: "Medizinische Hilfe",
            groupNameTr: "Otel İmkanları",
            groupNameEn: "Facilities",
            groupNameDe: "Einrichtungen"
        },
        {
            id: 110,
            groupId: 9,
            priority: 30,
            code: "car_park",
            nameTr: "Açık Otopark",
            nameEn: "Car Park",
            nameDe: "Parkplatz",
            groupNameTr: "Otel İmkanları",
            groupNameEn: "Facilities",
            groupNameDe: "Einrichtungen"
        },
        {
            id: 111,
            groupId: 9,
            priority: 30,
            code: "car_garage",
            nameTr: "Kapalı Otopark",
            nameEn: "Garage",
            nameDe: "Garage",
            groupNameTr: "Otel İmkanları",
            groupNameEn: "Facilities",
            groupNameDe: "Einrichtungen"
        },
        {
            id: 347,
            groupId: 11,
            priority: 30,
            code: "Executive Lounge",
            nameTr: "Executive Lounge",
            nameEn: "Executive Lounge",
            nameDe: "Executive Lounge",
            groupNameTr: "Yiyecek İçecek Servisi",
            groupNameEn: "Food & Beverage Service\t",
            groupNameDe: "Speisen- und Getränkeservice\t"
        },
        {
            id: 329,
            groupId: 12,
            priority: 30,
            code: "Sorf",
            nameTr: "Sörf",
            nameEn: "Surfing",
            nameDe: "Surfing",
            groupNameTr: "Aktiviteler",
            groupNameEn: "Sport / Entertainment",
            groupNameDe: "Sport / Unterhaltung\t"
        },
        {
            id: 330,
            groupId: 12,
            priority: 30,
            code: "Kareoke",
            nameTr: "Kareoke",
            nameEn: "Kareoke",
            nameDe: "Kareoke",
            groupNameTr: "Aktiviteler",
            groupNameEn: "Sport / Entertainment",
            groupNameDe: "Sport / Unterhaltung\t"
        },
        {
            id: 331,
            groupId: 12,
            priority: 30,
            code: "sauna",
            nameTr: "Sauna",
            nameEn: "Sauna",
            nameDe: "Sauna",
            groupNameTr: "Aktiviteler",
            groupNameEn: "Sport / Entertainment",
            groupNameDe: "Sport / Unterhaltung\t"
        },
        {
            id: 65,
            groupId: 8,
            priority: 30,
            code: "sun_bed2",
            nameTr: "Şezlong",
            nameEn: "Sun Loungers",
            nameDe: "Liegestuhl",
            groupNameTr: "Plaj",
            groupNameEn: "Beach",
            groupNameDe: "Strand"
        },
        {
            id: 66,
            groupId: 8,
            priority: 30,
            code: "beach_umbrella_sun",
            nameTr: "Güneş Şemsiyesi",
            nameEn: "Parasols",
            nameDe: "Sonnenschirm",
            groupNameTr: "Plaj",
            groupNameEn: "Beach",
            groupNameDe: "Strand"
        },
        {
            id: 71,
            groupId: 8,
            priority: 30,
            code: "beach_umbrella_canopy",
            nameTr: "Gölgelik",
            nameEn: "Canopy",
            nameDe: "Baldachin",
            groupNameTr: "Plaj",
            groupNameEn: "Beach",
            groupNameDe: "Strand"
        },
        {
            id: 72,
            groupId: 8,
            priority: 30,
            code: "beach_bar",
            nameTr: "Beach Bar",
            nameEn: "Beach Bar",
            nameDe: "Strandbar",
            groupNameTr: "Plaj",
            groupNameEn: "Beach",
            groupNameDe: "Strand"
        },
        {
            id: 68,
            groupId: 8,
            priority: 30,
            code: "Sahil Yolu Üzeri",
            nameTr: "Plaj ve Otel Arasında Yol Mevcut",
            nameEn: "Seperated By Road From Beach",
            nameDe: "Getrennt durch Straße vom Strand",
            groupNameTr: "Plaj",
            groupNameEn: "Beach",
            groupNameDe: "Strand"
        },
        {
            id: 76,
            groupId: 9,
            priority: 30,
            code: "24 Saat Resepsiyon Hizmeti",
            nameTr: "24 Saat Resepsiyon Hizmeti",
            nameEn: "24 Hours Reception Service",
            nameDe: "24 Stunden Rezeption Service",
            groupNameTr: "Otel İmkanları",
            groupNameEn: "Facilities",
            groupNameDe: "Einrichtungen"
        },
        {
            id: 78,
            groupId: 9,
            priority: 30,
            code: "Kasa_",
            nameTr: "Kasa",
            nameEn: "Safe-Box",
            nameDe: "Safe",
            groupNameTr: "Otel İmkanları",
            groupNameEn: "Facilities",
            groupNameDe: "Einrichtungen"
        },
        {
            id: 79,
            groupId: 9,
            priority: 30,
            code: "Döviz Değişim",
            nameTr: "Döviz Değişim",
            nameEn: "Currency Exchange",
            nameDe: "Wechselstube",
            groupNameTr: "Otel İmkanları",
            groupNameEn: "Facilities",
            groupNameDe: "Einrichtungen"
        },
        {
            id: 92,
            groupId: 9,
            priority: 30,
            code: "Amfitiyatro",
            nameTr: "Amfitiyatro",
            nameEn: "Theatre",
            nameDe: "Theater",
            groupNameTr: "Otel İmkanları",
            groupNameEn: "Facilities",
            groupNameDe: "Einrichtungen"
        },
        {
            id: 93,
            groupId: 9,
            priority: 30,
            code: "Casino",
            nameTr: "Casino",
            nameEn: "Casino",
            nameDe: "Casino",
            groupNameTr: "Otel İmkanları",
            groupNameEn: "Facilities",
            groupNameDe: "Einrichtungen"
        },
        {
            id: 94,
            groupId: 9,
            priority: 30,
            code: "Oyun Salonu",
            nameTr: "Oyun Salonu",
            nameEn: "Game Center",
            nameDe: "Spielezentrum",
            groupNameTr: "Otel İmkanları",
            groupNameEn: "Facilities",
            groupNameDe: "Einrichtungen"
        },
        {
            id: 95,
            groupId: 9,
            priority: 30,
            code: "restaurant",
            nameTr: "Restoran(lar)",
            nameEn: "Restaurants",
            nameDe: "Restaurant",
            groupNameTr: "Otel İmkanları",
            groupNameEn: "Facilities",
            groupNameDe: "Einrichtungen"
        },
        {
            id: 83,
            groupId: 9,
            priority: 30,
            code: "lifts",
            nameTr: "Asansör",
            nameEn: "Lifts",
            nameDe: "Aufzug",
            groupNameTr: "Otel İmkanları",
            groupNameEn: "Facilities",
            groupNameDe: "Einrichtungen"
        },
        {
            id: 86,
            groupId: 9,
            priority: 30,
            code: "mini_market",
            nameTr: "Minimarket",
            nameEn: "Minimarket",
            nameDe: "Minimarkt",
            groupNameTr: "Otel İmkanları",
            groupNameEn: "Facilities",
            groupNameDe: "Einrichtungen"
        },
        {
            id: 121,
            groupId: 9,
            priority: 30,
            code: "24 Saat Güvenlik",
            nameTr: "24 Saat Güvenlik",
            nameEn: "24 Hours Security",
            nameDe: "24 Stunden Sicherheit",
            groupNameTr: "Otel İmkanları",
            groupNameEn: "Facilities",
            groupNameDe: "Einrichtungen"
        },
        {
            id: 122,
            groupId: 9,
            priority: 30,
            code: "luggage",
            nameTr: "Bagaj Odası",
            nameEn: "Baggage Room",
            nameDe: "Gepäckraum",
            groupNameTr: "Otel İmkanları",
            groupNameEn: "Facilities",
            groupNameDe: "Einrichtungen"
        },
        {
            id: 123,
            groupId: 9,
            priority: 30,
            code: "parking_valet",
            nameTr: "Vale Park",
            nameEn: "Valet Parking",
            nameDe: "Parkservice",
            groupNameTr: "Otel İmkanları",
            groupNameEn: "Facilities",
            groupNameDe: "Einrichtungen"
        },
        {
            id: 88,
            groupId: 9,
            priority: 30,
            code: "Kuaför",
            nameTr: "Kuaför",
            nameEn: "Hairdresser",
            nameDe: "Friseur",
            groupNameTr: "Otel İmkanları",
            groupNameEn: "Facilities",
            groupNameDe: "Einrichtungen"
        },
        {
            id: 89,
            groupId: 9,
            priority: 30,
            code: "bars",
            nameTr: "Bar(lar)",
            nameEn: "Bar(s)",
            nameDe: "Bar",
            groupNameTr: "Otel İmkanları",
            groupNameEn: "Facilities",
            groupNameDe: "Einrichtungen"
        },
        {
            id: 125,
            groupId: 9,
            priority: 30,
            code: "Araç Kiralama",
            nameTr: "Araç Kiralama",
            nameEn: "Rent A Car",
            nameDe: "Autovermietung",
            groupNameTr: "Otel İmkanları",
            groupNameEn: "Facilities",
            groupNameDe: "Einrichtungen"
        },
        {
            id: 126,
            groupId: 9,
            priority: 30,
            code: "Kütüphane",
            nameTr: "Kütüphane",
            nameEn: "Library",
            nameDe: "Bibliothek",
            groupNameTr: "Otel İmkanları",
            groupNameEn: "Facilities",
            groupNameDe: "Einrichtungen"
        },
        {
            id: 99,
            groupId: 15,
            priority: 30,
            code: "Restoranda Çocuk İskemlesi",
            nameTr: "Restoranda Çocuk İskemlesi",
            nameEn: "Restaurant With High-Chairs For Children",
            nameDe: "Restaurant mit Hochstühlen für Kinder",
            groupNameTr: "Bebek/Çocuk İmkanlar",
            groupNameEn: "Facilities For Babies&Kids",
            groupNameDe: "\tEinrichtungen für Babys und Kinder"
        },
        {
            id: 188,
            groupId: 12,
            priority: 30,
            code: "spa_wellness_massage",
            nameTr: "Masaj Salonu ",
            nameEn: "Massage Service",
            nameDe: "Massage Service",
            groupNameTr: "Aktiviteler",
            groupNameEn: "Sport / Entertainment",
            groupNameDe: "Sport / Unterhaltung\t"
        },
        {
            id: 224,
            groupId: 12,
            priority: 30,
            code: "spa_wellness_saltroom",
            nameTr: "Tuz Odası",
            nameEn: "Salt Room",
            nameDe: "Salzraum",
            groupNameTr: "Aktiviteler",
            groupNameEn: "Sport / Entertainment",
            groupNameDe: "Sport / Unterhaltung\t"
        },
        {
            id: 221,
            groupId: 12,
            priority: 30,
            code: "Solaryum",
            nameTr: "Solaryum",
            nameEn: "Tanning Studio/Solarium",
            nameDe: "Sonnenstudio/Solarium",
            groupNameTr: "Aktiviteler",
            groupNameEn: "Sport / Entertainment",
            groupNameDe: "Sport / Unterhaltung\t"
        },
        {
            id: 304,
            groupId: 15,
            priority: 35,
            code: "Mini Disko",
            nameTr: "Mini Disko",
            nameEn: "Mini Disco",
            nameDe: "Minidisco",
            groupNameTr: "Bebek/Çocuk İmkanlar",
            groupNameEn: "Facilities For Babies&Kids",
            groupNameDe: "\tEinrichtungen für Babys und Kinder"
        },
        {
            id: 297,
            groupId: 15,
            priority: 35,
            code: "Çocuk Oyun Alanı",
            nameTr: "Çocuk Oyun Alanı",
            nameEn: "Children's Play Area",
            nameDe: "Kinderspielplatz",
            groupNameTr: "Bebek/Çocuk İmkanlar",
            groupNameEn: "Facilities For Babies&Kids",
            groupNameDe: "\tEinrichtungen für Babys und Kinder"
        },
        {
            id: 299,
            groupId: 15,
            priority: 35,
            code: "Bebek Arabası",
            nameTr: "Bebek Arabası",
            nameEn: "Stroller",
            nameDe: "Kinderwagen",
            groupNameTr: "Bebek/Çocuk İmkanlar",
            groupNameEn: "Facilities For Babies&Kids",
            groupNameDe: "\tEinrichtungen für Babys und Kinder"
        },
        {
            id: 300,
            groupId: 15,
            priority: 35,
            code: "Çocuk Büfesi",
            nameTr: "Çocuk Büfesi",
            nameEn: "Children Buffet",
            nameDe: "Kinderbuffet",
            groupNameTr: "Bebek/Çocuk İmkanlar",
            groupNameEn: "Facilities For Babies&Kids",
            groupNameDe: "\tEinrichtungen für Babys und Kinder"
        },
        {
            id: 301,
            groupId: 15,
            priority: 35,
            code: "Mama Sandalyesi",
            nameTr: "Mama Sandalyesi",
            nameEn: "Children's High Chair",
            nameDe: "Kinderhochstuhl",
            groupNameTr: "Bebek/Çocuk İmkanlar",
            groupNameEn: "Facilities For Babies&Kids",
            groupNameDe: "\tEinrichtungen für Babys und Kinder"
        },
        {
            id: 302,
            groupId: 15,
            priority: 35,
            code: "Su Isıtıcı(Mama İçin)",
            nameTr: "Su Isıtıcı(Mama İçin)",
            nameEn: "Water Heater(For Babies)",
            nameDe: "Wasserkocher (für Babys)",
            groupNameTr: "Bebek/Çocuk İmkanlar",
            groupNameEn: "Facilities For Babies&Kids",
            groupNameDe: "\tEinrichtungen für Babys und Kinder"
        },
        {
            id: 362,
            groupId: 9,
            priority: 40,
            code: "Araç Şarj Ünitesi",
            nameTr: "Araç Şarj Ünitesi",
            nameEn: "Vehicle Charging Unit",
            nameDe: "Fahrzeug-Ladegerät",
            groupNameTr: "Otel İmkanları",
            groupNameEn: "Facilities",
            groupNameDe: "Einrichtungen"
        },
        {
            id: 143,
            groupId: 10,
            priority: 45,
            code: "Halı ",
            nameTr: "Halı ",
            nameEn: "Carpet",
            nameDe: "Teppichboden",
            groupNameTr: "Oda Özellikleri",
            groupNameEn: "Room Features\t",
            groupNameDe: "Zimmerausstattung"
        },
        {
            id: 147,
            groupId: 10,
            priority: 45,
            code: "Lounge ",
            nameTr: "Lounge ",
            nameEn: "Lounge ",
            nameDe: "Lounge ",
            groupNameTr: "Oda Özellikleri",
            groupNameEn: "Room Features\t",
            groupNameDe: "Zimmerausstattung"
        },
        {
            id: 148,
            groupId: 10,
            priority: 45,
            code: "Balkon / Teras ",
            nameTr: "Balkon / Teras ",
            nameEn: "Balcony/ Terrace",
            nameDe: "Balkon/ Terrasse",
            groupNameTr: "Oda Özellikleri",
            groupNameEn: "Room Features\t",
            groupNameDe: "Zimmerausstattung"
        },
        {
            id: 129,
            groupId: 10,
            priority: 45,
            code: "Duş",
            nameTr: "Duş",
            nameEn: "Shower",
            nameDe: "Dusche",
            groupNameTr: "Oda Özellikleri",
            groupNameEn: "Room Features\t",
            groupNameDe: "Zimmerausstattung"
        },
        {
            id: 130,
            groupId: 10,
            priority: 45,
            code: "Küvet",
            nameTr: "Küvet",
            nameEn: "Bathtub",
            nameDe: "Badewanne",
            groupNameTr: "Oda Özellikleri",
            groupNameEn: "Room Features\t",
            groupNameDe: "Zimmerausstattung"
        },
        {
            id: 132,
            groupId: 10,
            priority: 45,
            code: "Saç Kurutma Makinesı",
            nameTr: "Saç Kurutma Makinesı ",
            nameEn: "Hair Dryer",
            nameDe: "Haartrockner",
            groupNameTr: "Oda Özellikleri",
            groupNameEn: "Room Features\t",
            groupNameDe: "Zimmerausstattung"
        },
        {
            id: 133,
            groupId: 10,
            priority: 45,
            code: "Telefon",
            nameTr: "Telefon",
            nameEn: "Direct Dial Telephone",
            nameDe: "Direktwahltelefon",
            groupNameTr: "Oda Özellikleri",
            groupNameEn: "Room Features\t",
            groupNameDe: "Zimmerausstattung"
        },
        {
            id: 134,
            groupId: 10,
            priority: 45,
            code: "Kablolu / Uydu TV ",
            nameTr: "Kablolu / Uydu TV ",
            nameEn: "Satellite/Cable TV",
            nameDe: "Satelliten-/Kabelfernsehen",
            groupNameTr: "Oda Özellikleri",
            groupNameEn: "Room Features\t",
            groupNameDe: "Zimmerausstattung"
        },
        {
            id: 159,
            groupId: 10,
            priority: 45,
            code: "Parke",
            nameTr: "Parke",
            nameEn: "Parquet",
            nameDe: "Parkettboden",
            groupNameTr: "Oda Özellikleri",
            groupNameEn: "Room Features\t",
            groupNameDe: "Zimmerausstattung"
        },
        {
            id: 145,
            groupId: 10,
            priority: 45,
            code: "Kasa ",
            nameTr: "Kasa ",
            nameEn: "Safe Box",
            nameDe: "Tresorfach",
            groupNameTr: "Oda Özellikleri",
            groupNameEn: "Room Features\t",
            groupNameDe: "Zimmerausstattung"
        },
        {
            id: 155,
            groupId: 10,
            priority: 45,
            code: "Kahve Makinası ",
            nameTr: "Kahve Makinası ",
            nameEn: "Tea/Coffee Maker",
            nameDe: "Tee-/ Kaffeezubereiter",
            groupNameTr: "Oda Özellikleri",
            groupNameEn: "Room Features\t",
            groupNameDe: "Zimmerausstattung"
        },
        {
            id: 317,
            groupId: 12,
            priority: 50,
            code: "Futbol",
            nameTr: "Futbol",
            nameEn: "Football",
            nameDe: "Fußball",
            groupNameTr: "Aktiviteler",
            groupNameEn: "Sport / Entertainment",
            groupNameDe: "Sport / Unterhaltung\t"
        },
        {
            id: 318,
            groupId: 12,
            priority: 50,
            code: "Plaj Futbolu",
            nameTr: "Plaj Futbolu",
            nameEn: "Beach Football",
            nameDe: "Beach-Fußball",
            groupNameTr: "Aktiviteler",
            groupNameEn: "Sport / Entertainment",
            groupNameDe: "Sport / Unterhaltung\t"
        },
        {
            id: 319,
            groupId: 12,
            priority: 50,
            code: "Hentbol",
            nameTr: "Hentbol",
            nameEn: "Handball",
            nameDe: "Handball",
            groupNameTr: "Aktiviteler",
            groupNameEn: "Sport / Entertainment",
            groupNameDe: "Sport / Unterhaltung\t"
        },
        {
            id: 321,
            groupId: 12,
            priority: 50,
            code: "Jeep Safari",
            nameTr: "Jeep Safari",
            nameEn: "Jeep Safari",
            nameDe: "Jeep Safari",
            groupNameTr: "Aktiviteler",
            groupNameEn: "Sport / Entertainment",
            groupNameDe: "Sport / Unterhaltung\t"
        },
        {
            id: 36,
            groupId: 6,
            priority: 50,
            code: "Apart Otel",
            nameTr: "Apart Otel",
            nameEn: "Apartment Hotel",
            nameDe: "Aparthotel",
            groupNameTr: "Otel Tipi",
            groupNameEn: "Hotel Type",
            groupNameDe: "Hotel Typ"
        },
        {
            id: 37,
            groupId: 6,
            priority: 50,
            code: "Bungalov Kompleksi",
            nameTr: "Bungalov Kompleksi",
            nameEn: "Bungalow Complex",
            nameDe: "Bungalowanlage",
            groupNameTr: "Otel Tipi",
            groupNameEn: "Hotel Type",
            groupNameDe: "Hotel Typ"
        },
        {
            id: 38,
            groupId: 6,
            priority: 50,
            code: "Konuk Evi",
            nameTr: "Konuk Evi",
            nameEn: "Guesthouse",
            nameDe: "Gästehaus",
            groupNameTr: "Otel Tipi",
            groupNameEn: "Hotel Type",
            groupNameDe: "Hotel Typ"
        },
        {
            id: 39,
            groupId: 6,
            priority: 50,
            code: "Köy Evi",
            nameTr: "Köy Evi",
            nameEn: "Country House",
            nameDe: "Gästehaus",
            groupNameTr: "Otel Tipi",
            groupNameEn: "Hotel Type",
            groupNameDe: "Hotel Typ"
        },
        {
            id: 40,
            groupId: 6,
            priority: 50,
            code: "Kayak Oteli",
            nameTr: "Kayak Oteli",
            nameEn: "Ski Hotel",
            nameDe: "Skihotel",
            groupNameTr: "Otel Tipi",
            groupNameEn: "Hotel Type",
            groupNameDe: "Hotel Typ"
        },
        {
            id: 41,
            groupId: 6,
            priority: 50,
            code: "Tatil Köyü",
            nameTr: "Tatil Köyü",
            nameEn: "Club Resort",
            nameDe: "Club Resort",
            groupNameTr: "Otel Tipi",
            groupNameEn: "Hotel Type",
            groupNameDe: "Hotel Typ"
        },
        {
            id: 42,
            groupId: 6,
            priority: 50,
            code: "Çiftlik Evi",
            nameTr: "Çiftlik Evi",
            nameEn: "Village House",
            nameDe: "Dorfhaus",
            groupNameTr: "Otel Tipi",
            groupNameEn: "Hotel Type",
            groupNameDe: "Hotel Typ"
        },
        {
            id: 322,
            groupId: 6,
            priority: 50,
            code: "Mağara Otel",
            nameTr: "Mağara Otel",
            nameEn: "Cave Hotel",
            nameDe: "Höhlenhotel",
            groupNameTr: "Otel Tipi",
            groupNameEn: "Hotel Type",
            groupNameDe: "Hotel Typ"
        },
        {
            id: 323,
            groupId: 6,
            priority: 50,
            code: "Taş Otel",
            nameTr: "Taş Otel",
            nameEn: "Stone Hotel",
            nameDe: "Steinernes Hotel",
            groupNameTr: "Otel Tipi",
            groupNameEn: "Hotel Type",
            groupNameDe: "Hotel Typ"
        },
        {
            id: 326,
            groupId: 6,
            priority: 50,
            code: "Villa",
            nameTr: "Villa",
            nameEn: "Villa",
            nameDe: "Villa",
            groupNameTr: "Otel Tipi",
            groupNameEn: "Hotel Type",
            groupNameDe: "Hotel Typ"
        },
        {
            id: 310,
            groupId: 6,
            priority: 50,
            code: "Butik Otel",
            nameTr: "Butik Otel",
            nameEn: "Boutique Hotel",
            nameDe: "Boutique-Hotel",
            groupNameTr: "Otel Tipi",
            groupNameEn: "Hotel Type",
            groupNameDe: "Hotel Typ"
        },
        {
            id: 312,
            groupId: 6,
            priority: 50,
            code: "Resort",
            nameTr: "Resort Otel",
            nameEn: "Resort Hotel",
            nameDe: "Resort Hotel",
            groupNameTr: "Otel Tipi",
            groupNameEn: "Hotel Type",
            groupNameDe: "Hotel Typ"
        },
        {
            id: 313,
            groupId: 6,
            priority: 50,
            code: "Tema Oteli",
            nameTr: "Tema Oteli",
            nameEn: "Theme Hotel",
            nameDe: "Themenhotel",
            groupNameTr: "Otel Tipi",
            groupNameEn: "Hotel Type",
            groupNameDe: "Hotel Typ"
        },
        {
            id: 314,
            groupId: 6,
            priority: 50,
            code: "Tasarım Otel",
            nameTr: "Tasarım Otel",
            nameEn: "Design Hotel",
            nameDe: "Design Hotel",
            groupNameTr: "Otel Tipi",
            groupNameEn: "Hotel Type",
            groupNameDe: "Hotel Typ"
        },
        {
            id: 44,
            groupId: 6,
            priority: 50,
            code: "Golf Oteli",
            nameTr: "Golf Oteli",
            nameEn: "Golf Hotel",
            nameDe: "Golfhotel",
            groupNameTr: "Otel Tipi",
            groupNameEn: "Hotel Type",
            groupNameDe: "Hotel Typ"
        },
        {
            id: 45,
            groupId: 6,
            priority: 50,
            code: "Casino Oteli",
            nameTr: "Casino Oteli",
            nameEn: "Casino Hotel",
            nameDe: "Casinohotel",
            groupNameTr: "Otel Tipi",
            groupNameEn: "Hotel Type",
            groupNameDe: "Hotel Typ"
        },
        {
            id: 46,
            groupId: 6,
            priority: 50,
            code: "Havaalanı Oteli",
            nameTr: "Havaalanı Oteli",
            nameEn: "Airport Hotel",
            nameDe: "Flughafenhotel",
            groupNameTr: "Otel Tipi",
            groupNameEn: "Hotel Type",
            groupNameDe: "Hotel Typ"
        },
        {
            id: 48,
            groupId: 6,
            priority: 50,
            code: "Tarihi Otel",
            nameTr: "Tarihi Otel",
            nameEn: "Historic Hotel",
            nameDe: "historisches Hotel",
            groupNameTr: "Otel Tipi",
            groupNameEn: "Hotel Type",
            groupNameDe: "Hotel Typ"
        },
        {
            id: 49,
            groupId: 6,
            priority: 50,
            code: "Toplantı Oteli",
            nameTr: "Toplantı Oteli",
            nameEn: "Conference Hotel",
            nameDe: "Tagungshotel",
            groupNameTr: "Otel Tipi",
            groupNameEn: "Hotel Type",
            groupNameDe: "Hotel Typ"
        },
        {
            id: 50,
            groupId: 6,
            priority: 50,
            code: "Hostel",
            nameTr: "Hostel",
            nameEn: "Hostel",
            nameDe: "Hostel",
            groupNameTr: "Otel Tipi",
            groupNameEn: "Hotel Type",
            groupNameDe: "Hotel Typ"
        },
        {
            id: 51,
            groupId: 6,
            priority: 50,
            code: "Dağ Oteli",
            nameTr: "Dağ Oteli",
            nameEn: "Mountain Hotel",
            nameDe: "Berghotel",
            groupNameTr: "Otel Tipi",
            groupNameEn: "Hotel Type",
            groupNameDe: "Hotel Typ"
        },
        {
            id: 53,
            groupId: 6,
            priority: 50,
            code: "Business Otel",
            nameTr: "Business Otel",
            nameEn: "Business Hotel",
            nameDe: "Geschäftshotel",
            groupNameTr: "Otel Tipi",
            groupNameEn: "Hotel Type",
            groupNameDe: "Hotel Typ"
        },
        {
            id: 325,
            groupId: 12,
            priority: 50,
            code: "Parasailling",
            nameTr: "Parasailling",
            nameEn: "Parasailling",
            nameDe: "Parasailling",
            groupNameTr: "Aktiviteler",
            groupNameEn: "Sport / Entertainment",
            groupNameDe: "Sport / Unterhaltung\t"
        },
        {
            id: 215,
            groupId: 12,
            priority: 50,
            code: "Tenis ",
            nameTr: "Tenis ",
            nameEn: "Tennis ",
            nameDe: "Tennisplatz",
            groupNameTr: "Aktiviteler",
            groupNameEn: "Sport / Entertainment",
            groupNameDe: "Sport / Unterhaltung\t"
        },
        {
            id: 216,
            groupId: 12,
            priority: 50,
            code: "Badminton ",
            nameTr: "Badminton ",
            nameEn: "Badminton ",
            nameDe: "Badminton ",
            groupNameTr: "Aktiviteler",
            groupNameEn: "Sport / Entertainment",
            groupNameDe: "Sport / Unterhaltung\t"
        },
        {
            id: 219,
            groupId: 12,
            priority: 50,
            code: "Aerobik ",
            nameTr: "Aerobik ",
            nameEn: "Aerobics",
            nameDe: "Aerobic",
            groupNameTr: "Aktiviteler",
            groupNameEn: "Sport / Entertainment",
            groupNameDe: "Sport / Unterhaltung\t"
        },
        {
            id: 220,
            groupId: 12,
            priority: 50,
            code: "Dart ",
            nameTr: "Dart ",
            nameEn: "Darts",
            nameDe: "Dart",
            groupNameTr: "Aktiviteler",
            groupNameEn: "Sport / Entertainment",
            groupNameDe: "Sport / Unterhaltung\t"
        },
        {
            id: 184,
            groupId: 12,
            priority: 50,
            code: "Su Jimnastiği ",
            nameTr: "Su Jimnastiği ",
            nameEn: "Aqua Aerobics",
            nameDe: "Wasseraerobic",
            groupNameTr: "Aktiviteler",
            groupNameEn: "Sport / Entertainment",
            groupNameDe: "Sport / Unterhaltung\t"
        },
        {
            id: 190,
            groupId: 12,
            priority: 50,
            code: "ws_banana",
            nameTr: "Muz-Bot ",
            nameEn: "Banana Boat",
            nameDe: "Banana Boat",
            groupNameTr: "Aktiviteler",
            groupNameEn: "Sport / Entertainment",
            groupNameDe: "Sport / Unterhaltung\t"
        },
        {
            id: 191,
            groupId: 12,
            priority: 50,
            code: "ws_skiing",
            nameTr: "Su Kayağı ",
            nameEn: "Water Skiing",
            nameDe: "Wasserski",
            groupNameTr: "Aktiviteler",
            groupNameEn: "Sport / Entertainment",
            groupNameDe: "Sport / Unterhaltung\t"
        },
        {
            id: 192,
            groupId: 12,
            priority: 50,
            code: "ws_jetski",
            nameTr: "Jet-Ski ",
            nameEn: "Jet-Ski ",
            nameDe: "Jet-Ski ",
            groupNameTr: "Aktiviteler",
            groupNameEn: "Sport / Entertainment",
            groupNameDe: "Sport / Unterhaltung\t"
        },
        {
            id: 193,
            groupId: 12,
            priority: 50,
            code: "ws_speedboating",
            nameTr: "Hız Botu ",
            nameEn: "Speed Boating",
            nameDe: "Schnellboot",
            groupNameTr: "Aktiviteler",
            groupNameEn: "Sport / Entertainment",
            groupNameDe: "Sport / Unterhaltung\t"
        },
        {
            id: 194,
            groupId: 12,
            priority: 50,
            code: "ws_diving",
            nameTr: "Dalış",
            nameEn: "Diving",
            nameDe: "Tauchen",
            groupNameTr: "Aktiviteler",
            groupNameEn: "Sport / Entertainment",
            groupNameDe: "Sport / Unterhaltung\t"
        },
        {
            id: 195,
            groupId: 12,
            priority: 50,
            code: "ws_windsurfing",
            nameTr: "Rüzgar Sörfü ",
            nameEn: "Wind Surfing",
            nameDe: "Windsurfen",
            groupNameTr: "Aktiviteler",
            groupNameEn: "Sport / Entertainment",
            groupNameDe: "Sport / Unterhaltung\t"
        },
        {
            id: 196,
            groupId: 12,
            priority: 50,
            code: "ws_sailing",
            nameTr: "Yelkenli ",
            nameEn: "Sailing",
            nameDe: "Segeln",
            groupNameTr: "Aktiviteler",
            groupNameEn: "Sport / Entertainment",
            groupNameDe: "Sport / Unterhaltung\t"
        },
        {
            id: 197,
            groupId: 12,
            priority: 50,
            code: "ws_katamaran",
            nameTr: "Katamaran ",
            nameEn: "Catamaran",
            nameDe: "Katamaran ",
            groupNameTr: "Aktiviteler",
            groupNameEn: "Sport / Entertainment",
            groupNameDe: "Sport / Unterhaltung\t"
        },
        {
            id: 198,
            groupId: 12,
            priority: 50,
            code: "ws_canoe",
            nameTr: "Kano",
            nameEn: "Canoe",
            nameDe: "Kanu",
            groupNameTr: "Aktiviteler",
            groupNameEn: "Sport / Entertainment",
            groupNameDe: "Sport / Unterhaltung\t"
        },
        {
            id: 199,
            groupId: 12,
            priority: 50,
            code: "ws_pedalo",
            nameTr: "Deniz Bisikleti",
            nameEn: "Pedalo",
            nameDe: "Pedaloverleih",
            groupNameTr: "Aktiviteler",
            groupNameEn: "Sport / Entertainment",
            groupNameDe: "Sport / Unterhaltung\t"
        },
        {
            id: 200,
            groupId: 12,
            priority: 50,
            code: "Masa Tenisi ",
            nameTr: "Masa Tenisi ",
            nameEn: "Table Tennis",
            nameDe: "Tischtennis",
            groupNameTr: "Aktiviteler",
            groupNameEn: "Sport / Entertainment",
            groupNameDe: "Sport / Unterhaltung\t"
        },
        {
            id: 201,
            groupId: 12,
            priority: 50,
            code: "Squash ",
            nameTr: "Squash ",
            nameEn: "Squash ",
            nameDe: "Squash ",
            groupNameTr: "Aktiviteler",
            groupNameEn: "Sport / Entertainment",
            groupNameDe: "Sport / Unterhaltung\t"
        },
        {
            id: 202,
            groupId: 12,
            priority: 50,
            code: "Jimnastik",
            nameTr: "Jimnastik",
            nameEn: "Gym",
            nameDe: "Fitnessraum",
            groupNameTr: "Aktiviteler",
            groupNameEn: "Sport / Entertainment",
            groupNameDe: "Sport / Unterhaltung\t"
        },
        {
            id: 203,
            groupId: 12,
            priority: 50,
            code: "Ok-Yay ",
            nameTr: "Ok-Yay ",
            nameEn: "Archery",
            nameDe: "Bogenschießen",
            groupNameTr: "Aktiviteler",
            groupNameEn: "Sport / Entertainment",
            groupNameDe: "Sport / Unterhaltung\t"
        },
        {
            id: 204,
            groupId: 12,
            priority: 50,
            code: "Binicilik ",
            nameTr: "Binicilik ",
            nameEn: "Horse Riding",
            nameDe: "Reiten",
            groupNameTr: "Aktiviteler",
            groupNameEn: "Sport / Entertainment",
            groupNameDe: "Sport / Unterhaltung\t"
        },
        {
            id: 205,
            groupId: 12,
            priority: 50,
            code: "Bisiklet ",
            nameTr: "Bisiklet ",
            nameEn: "Bcycle",
            nameDe: "Radfahren/ Mountainbiken",
            groupNameTr: "Aktiviteler",
            groupNameEn: "Sport / Entertainment",
            groupNameDe: "Sport / Unterhaltung\t"
        },
        {
            id: 206,
            groupId: 12,
            priority: 50,
            code: "Basketbol ",
            nameTr: "Basketbol ",
            nameEn: "Basketball",
            nameDe: "Basketball",
            groupNameTr: "Aktiviteler",
            groupNameEn: "Sport / Entertainment",
            groupNameDe: "Sport / Unterhaltung\t"
        },
        {
            id: 207,
            groupId: 12,
            priority: 50,
            code: "Voleybol ",
            nameTr: "Plaj Voleybolu",
            nameEn: "Beach Volleyball",
            nameDe: "Strandvolleyball",
            groupNameTr: "Aktiviteler",
            groupNameEn: "Sport / Entertainment",
            groupNameDe: "Sport / Unterhaltung\t"
        },
        {
            id: 208,
            groupId: 12,
            priority: 50,
            code: "Bilardo ",
            nameTr: "Bilardo ",
            nameEn: "Billiards/Snooker",
            nameDe: "Billard/ Snooker",
            groupNameTr: "Aktiviteler",
            groupNameEn: "Sport / Entertainment",
            groupNameDe: "Sport / Unterhaltung\t"
        },
        {
            id: 209,
            groupId: 12,
            priority: 50,
            code: "Bocce ",
            nameTr: "Bocce ",
            nameEn: "Boccia",
            nameDe: "Boccia",
            groupNameTr: "Aktiviteler",
            groupNameEn: "Sport / Entertainment",
            groupNameDe: "Sport / Unterhaltung\t"
        },
        {
            id: 210,
            groupId: 12,
            priority: 50,
            code: "Bovling ",
            nameTr: "Bovling ",
            nameEn: "Bowling",
            nameDe: "Bowling",
            groupNameTr: "Aktiviteler",
            groupNameEn: "Sport / Entertainment",
            groupNameDe: "Sport / Unterhaltung\t"
        },
        {
            id: 211,
            groupId: 12,
            priority: 50,
            code: "Mini Golf",
            nameTr: "Mini Golf",
            nameEn: "Mini Golf",
            nameDe: "Minigolf",
            groupNameTr: "Aktiviteler",
            groupNameEn: "Sport / Entertainment",
            groupNameDe: "Sport / Unterhaltung\t"
        },
        {
            id: 212,
            groupId: 12,
            priority: 50,
            code: "Golf ",
            nameTr: "Golf ",
            nameEn: "Golf ",
            nameDe: "Golf ",
            groupNameTr: "Aktiviteler",
            groupNameEn: "Sport / Entertainment",
            groupNameDe: "Sport / Unterhaltung\t"
        },
        {
            id: 31,
            groupId: 5,
            priority: 70,
            code: "Sessiz Konum",
            nameTr: "Sessiz Konum",
            nameEn: "Quiet Location",
            nameDe: "Ruhige Lage",
            groupNameTr: "Otel Özellikleri",
            groupNameEn: "Hotel Features\t",
            groupNameDe: "Hotelausstattung"
        },
        {
            id: 324,
            groupId: 11,
            priority: 70,
            code: "İthal İçecekler Dahil",
            nameTr: "İthal İçecekler Dahil",
            nameEn: "Import Drinks Included",
            nameDe: "Importgetränke inklusive",
            groupNameTr: "Yiyecek İçecek Servisi",
            groupNameEn: "Food & Beverage Service\t",
            groupNameDe: "Speisen- und Getränkeservice\t"
        },
        {
            id: 171,
            groupId: 11,
            priority: 70,
            code: "Herşey Dahil",
            nameTr: "Herşey Dahil",
            nameEn: "All Inclusive",
            nameDe: "Alles Inklusive",
            groupNameTr: "Yiyecek İçecek Servisi",
            groupNameEn: "Food & Beverage Service\t",
            groupNameDe: "Speisen- und Getränkeservice\t"
        },
        {
            id: 173,
            groupId: 11,
            priority: 70,
            code: "restaurant_spdiet",
            nameTr: "Diyet Büfesi",
            nameEn: "Diet Buffet",
            nameDe: "Diät-Buffet",
            groupNameTr: "Yiyecek İçecek Servisi",
            groupNameEn: "Food & Beverage Service\t",
            groupNameDe: "Speisen- und Getränkeservice\t"
        },
        {
            id: 368,
            groupId: 10,
            priority: 85,
            code: "Anti Alerjik Oda",
            nameTr: "Anti Alerjik Oda",
            nameEn: "Anti Allergic Room",
            nameDe: "Antiallergisches Zimmer",
            groupNameTr: "Oda Özellikleri",
            groupNameEn: "Room Features\t",
            groupNameDe: "Zimmerausstattung"
        },
        {
            id: 241,
            groupId: 5,
            priority: 90,
            code: "Alışveriş Merkezine Yakın",
            nameTr: "Alışveriş Merkezine Yakın",
            nameEn: "Shopping Opportunities",
            nameDe: "In der Nähe des Einkaufszentrums",
            groupNameTr: "Otel Özellikleri",
            groupNameEn: "Hotel Features\t",
            groupNameDe: "Hotelausstattung"
        },
        {
            id: 33,
            groupId: 5,
            priority: 99,
            code: "Şömine",
            nameTr: "Şömine",
            nameEn: "Fireplace",
            nameDe: "Kamin",
            groupNameTr: "Otel Özellikleri",
            groupNameEn: "Hotel Features\t",
            groupNameDe: "Hotelausstattung"
        },
        {
            id: 333,
            groupId: 5,
            priority: 99,
            code: "Şehir Merkezine Yakın",
            nameTr: "Şehir Merkezine Yakın",
            nameEn: "Near City Center",
            nameDe: "In der Nähe des Stadtzentrums",
            groupNameTr: "Otel Özellikleri",
            groupNameEn: "Hotel Features\t",
            groupNameDe: "Hotelausstattung"
        },
        {
            id: 334,
            groupId: 6,
            priority: 99,
            code: "Şehir Oteli",
            nameTr: "Şehir Oteli",
            nameEn: "City Hotel",
            nameDe: "Stadthotel",
            groupNameTr: "Otel Tipi",
            groupNameEn: "Hotel Type",
            groupNameDe: "Hotel Typ"
        },
        {
            id: 64,
            groupId: 8,
            priority: 99,
            code: "beach_rocky",
            nameTr: "Kayalık Plaj",
            nameEn: "Rocky Beach",
            nameDe: "Felsstrand",
            groupNameTr: "Plaj",
            groupNameEn: "Beach",
            groupNameDe: "Strand"
        },
        {
            id: 90,
            groupId: 9,
            priority: 99,
            code: "Roocar",
            nameTr: "Pub",
            nameEn: "Pub",
            nameDe: "Pub",
            groupNameTr: "Otel İmkanları",
            groupNameEn: "Facilities",
            groupNameDe: "Einrichtungen"
        },
        {
            id: 124,
            groupId: 9,
            priority: 99,
            code: "Havaalanı Servisi",
            nameTr: "Havaalanı Servisi",
            nameEn: "Airport Shuttle",
            nameDe: "Flughafentransfer",
            groupNameTr: "Otel İmkanları",
            groupNameEn: "Facilities",
            groupNameDe: "Einrichtungen"
        },
        {
            id: 87,
            groupId: 9,
            priority: 99,
            code: "store",
            nameTr: "Dükkanlar",
            nameEn: "Stores",
            nameDe: "Geschäfte",
            groupNameTr: "Otel İmkanları",
            groupNameEn: "Facilities",
            groupNameDe: "Einrichtungen"
        },
        {
            id: 85,
            groupId: 9,
            priority: 99,
            code: "Gazete Büfesi",
            nameTr: "Gazete Büfesi",
            nameEn: "Newspaper Buffet",
            nameDe: "Zeitungsbuffet",
            groupNameTr: "Otel İmkanları",
            groupNameEn: "Facilities",
            groupNameDe: "Einrichtungen"
        },
        {
            id: 119,
            groupId: 9,
            priority: 99,
            code: "restaurant_sm",
            nameTr: "Sigara İçilebilen Restoran Bölümü",
            nameEn: "Restaurant With Smoking Area",
            nameDe: "Restaurant mit Raucherbereich",
            groupNameTr: "Otel İmkanları",
            groupNameEn: "Facilities",
            groupNameDe: "Einrichtungen"
        },
        {
            id: 120,
            groupId: 9,
            priority: 99,
            code: "Belboy Servisi",
            nameTr: "Belboy Servisi",
            nameEn: "Bellboy Service",
            nameDe: "Hotelpagenservice",
            groupNameTr: "Otel İmkanları",
            groupNameEn: "Facilities",
            groupNameDe: "Einrichtungen"
        },
        {
            id: 97,
            groupId: 9,
            priority: 99,
            code: "restaurant_ns",
            nameTr: "Sigara İçilmeyen Restoran Bölümü",
            nameEn: "Restaurant with Non-Smoking Area",
            nameDe: "Restaurant mit Nichtraucherbereich",
            groupNameTr: "Otel İmkanları",
            groupNameEn: "Facilities",
            groupNameDe: "Einrichtungen"
        },
        {
            id: 98,
            groupId: 9,
            priority: 99,
            code: "restaurant_ac",
            nameTr: "Klimalı Restoran (lar)",
            nameEn: "Restaurant With Air-Conditioning",
            nameDe: "Restaurant mit Klimaanlage",
            groupNameTr: "Otel İmkanları",
            groupNameEn: "Facilities",
            groupNameDe: "Einrichtungen"
        },
        {
            id: 114,
            groupId: 9,
            priority: 99,
            code: "TV Odası",
            nameTr: "TV Odası",
            nameEn: "TV Room",
            nameDe: "Fernsehraum",
            groupNameTr: "Otel İmkanları",
            groupNameEn: "Facilities",
            groupNameDe: "Einrichtungen"
        },
        {
            id: 115,
            groupId: 9,
            priority: 99,
            code: "laundry_service",
            nameTr: "Kuru Temizleme ",
            nameEn: "Laundry Facilities",
            nameDe: "Wäscherei",
            groupNameTr: "Otel İmkanları",
            groupNameEn: "Facilities",
            groupNameDe: "Einrichtungen"
        },
        {
            id: 116,
            groupId: 9,
            priority: 99,
            code: "domestic_pet",
            nameTr: "Evcil Hayvanlar",
            nameEn: "Pets",
            nameDe: "Haustiere",
            groupNameTr: "Otel İmkanları",
            groupNameEn: "Facilities",
            groupNameDe: "Einrichtungen"
        },
        {
            id: 80,
            groupId: 9,
            priority: 99,
            code: "Vestiyer",
            nameTr: "Vestiyer",
            nameEn: "Cloakroom",
            nameDe: "Garderobe",
            groupNameTr: "Otel İmkanları",
            groupNameEn: "Facilities",
            groupNameDe: "Einrichtungen"
        },
        {
            id: 74,
            groupId: 9,
            priority: 99,
            code: "air_condition",
            nameTr: "Klima",
            nameEn: "Air Conditioning",
            nameDe: "Klimaanlage",
            groupNameTr: "Otel İmkanları",
            groupNameEn: "Facilities",
            groupNameDe: "Einrichtungen"
        },
        {
            id: 309,
            groupId: 11,
            priority: 99,
            code: "Sadece Oda",
            nameTr: "Sadece Oda",
            nameEn: "Room Only",
            nameDe: "Nur Zimmer",
            groupNameTr: "Yiyecek İçecek Servisi",
            groupNameEn: "Food & Beverage Service\t",
            groupNameDe: "Speisen- und Getränkeservice\t"
        },
        {
            id: 332,
            groupId: 11,
            priority: 99,
            code: "Alkolsüz Her şey Dahil",
            nameTr: "Alkolsüz Her şey Dahil",
            nameEn: "Non-Alcohol All Inclusive",
            nameDe: "alkoholfreies All-Inclusive",
            groupNameTr: "Yiyecek İçecek Servisi",
            groupNameEn: "Food & Beverage Service\t",
            groupNameDe: "Speisen- und Getränkeservice\t"
        },
        {
            id: 311,
            groupId: 12,
            priority: 99,
            code: "Plaj Voleybolu",
            nameTr: "Plaj Voleybolu",
            nameEn: "Beach Volleyball",
            nameDe: "Beach-Volleyball",
            groupNameTr: "Aktiviteler",
            groupNameEn: "Sport / Entertainment",
            groupNameDe: "Sport / Unterhaltung\t"
        },
        {
            id: 308,
            groupId: 12,
            priority: 99,
            code: "İnternet Salonu",
            nameTr: "İnternet Salonu",
            nameEn: "Internet-Lounge",
            nameDe: "Internet-Lounge",
            groupNameTr: "Aktiviteler",
            groupNameEn: "Sport / Entertainment",
            groupNameDe: "Sport / Unterhaltung\t"
        },
        {
            id: 174,
            groupId: 11,
            priority: 99,
            code: "restaurant_spmenu",
            nameTr: "Özel Menü",
            nameEn: "Special Menu",
            nameDe: "Spezialmenü",
            groupNameTr: "Yiyecek İçecek Servisi",
            groupNameEn: "Food & Beverage Service\t",
            groupNameDe: "Speisen- und Getränkeservice\t"
        },
        {
            id: 175,
            groupId: 11,
            priority: 99,
            code: "Geleneksel Kahvaltı",
            nameTr: "Geleneksel Kahvaltı",
            nameEn: "Continental Breakfast",
            nameDe: "Kontinentales Frühstück",
            groupNameTr: "Yiyecek İçecek Servisi",
            groupNameEn: "Food & Beverage Service\t",
            groupNameDe: "Speisen- und Getränkeservice\t"
        },
        {
            id: 156,
            groupId: 10,
            priority: 99,
            code: "Çamaşır Makinası ",
            nameTr: "Çamaşır Makinası ",
            nameEn: "Washing Machine",
            nameDe: "Waschmaschine",
            groupNameTr: "Oda Özellikleri",
            groupNameEn: "Room Features\t",
            groupNameDe: "Zimmerausstattung"
        },
        {
            id: 146,
            groupId: 10,
            priority: 99,
            code: "Oda Çıkış Temizliği ",
            nameTr: "Oda Çıkış Temizliği ",
            nameEn: "Final Cleaning",
            nameDe: "Endreinigung",
            groupNameTr: "Oda Özellikleri",
            groupNameEn: "Room Features\t",
            groupNameDe: "Zimmerausstattung"
        },
        {
            id: 160,
            groupId: 11,
            priority: 99,
            code: "Yarım Pansiyon",
            nameTr: "Yarım Pansiyon",
            nameEn: "Half Board",
            nameDe: "Halbpension",
            groupNameTr: "Yiyecek İçecek Servisi",
            groupNameEn: "Food & Beverage Service\t",
            groupNameDe: "Speisen- und Getränkeservice\t"
        },
        {
            id: 161,
            groupId: 11,
            priority: 99,
            code: "Tam Pansiyon",
            nameTr: "Tam Pansiyon",
            nameEn: "Full Board",
            nameDe: "Vollpension",
            groupNameTr: "Yiyecek İçecek Servisi",
            groupNameEn: "Food & Beverage Service\t",
            groupNameDe: "Speisen- und Getränkeservice\t"
        },
        {
            id: 162,
            groupId: 11,
            priority: 99,
            code: "Açık Büfe Kahvaltı",
            nameTr: "Açık Büfe Kahvaltı",
            nameEn: "Breakfast Buffet",
            nameDe: "Frühstücksbuffet",
            groupNameTr: "Yiyecek İçecek Servisi",
            groupNameEn: "Food & Beverage Service\t",
            groupNameDe: "Speisen- und Getränkeservice\t"
        },
        {
            id: 163,
            groupId: 11,
            priority: 99,
            code: "Kahvaltı",
            nameTr: "Kahvaltı",
            nameEn: "Breakfast ",
            nameDe: "Frühstück",
            groupNameTr: "Yiyecek İçecek Servisi",
            groupNameEn: "Food & Beverage Service\t",
            groupNameDe: "Speisen- und Getränkeservice\t"
        },
        {
            id: 164,
            groupId: 11,
            priority: 99,
            code: "restaurant_lunch",
            nameTr: "Öğle Yemeği",
            nameEn: "Lunch",
            nameDe: "Mittag",
            groupNameTr: "Yiyecek İçecek Servisi",
            groupNameEn: "Food & Beverage Service\t",
            groupNameDe: "Speisen- und Getränkeservice\t"
        },
        {
            id: 165,
            groupId: 11,
            priority: 99,
            code: "alacarte_lunch",
            nameTr: "Öğle Yemeği (A La Carte)",
            nameEn: "Lunch (A La Carte)",
            nameDe: "Mittag (A La Carte)",
            groupNameTr: "Yiyecek İçecek Servisi",
            groupNameEn: "Food & Beverage Service\t",
            groupNameDe: "Speisen- und Getränkeservice\t"
        },
        {
            id: 167,
            groupId: 11,
            priority: 99,
            code: "restaurant_slunch",
            nameTr: "Öğle Yemeği (Set Menü)",
            nameEn: "Lunch (Set Menu)",
            nameDe: "Mittagessen (Set Menü)",
            groupNameTr: "Yiyecek İçecek Servisi",
            groupNameEn: "Food & Beverage Service\t",
            groupNameDe: "Speisen- und Getränkeservice\t"
        },
        {
            id: 168,
            groupId: 11,
            priority: 99,
            code: "restaurant_dbuffet",
            nameTr: "Açık Büfe Akşam Yemeği",
            nameEn: "Dinner Buffet",
            nameDe: "Abendbuffet",
            groupNameTr: "Yiyecek İçecek Servisi",
            groupNameEn: "Food & Beverage Service\t",
            groupNameDe: "Speisen- und Getränkeservice\t"
        },
        {
            id: 169,
            groupId: 11,
            priority: 99,
            code: "alacarte_dinner",
            nameTr: "Akşam Yemeği (A La Carte)",
            nameEn: "Dinner Buffet (A La Carte)",
            nameDe: "Abendbuffet (A La Carte)",
            groupNameTr: "Yiyecek İçecek Servisi",
            groupNameEn: "Food & Beverage Service\t",
            groupNameDe: "Speisen- und Getränkeservice\t"
        },
        {
            id: 170,
            groupId: 11,
            priority: 99,
            code: "restaurant_sdinner",
            nameTr: "Akşam Yemeği ( Set Menü)",
            nameEn: "Dinner Buffet (Set Menu)",
            nameDe: "Abendbuffet (Set Menü)",
            groupNameTr: "Yiyecek İçecek Servisi",
            groupNameEn: "Food & Beverage Service\t",
            groupNameDe: "Speisen- und Getränkeservice\t"
        },
        {
            id: 135,
            groupId: 10,
            priority: 99,
            code: "Radyo ",
            nameTr: "Radyo ",
            nameEn: "Radio",
            nameDe: "Radio",
            groupNameTr: "Oda Özellikleri",
            groupNameEn: "Room Features\t",
            groupNameDe: "Zimmerausstattung"
        },
        {
            id: 137,
            groupId: 10,
            priority: 99,
            code: "wifi",
            nameTr: "İnternet ",
            nameEn: "İnternet ",
            nameDe: "Internet ",
            groupNameTr: "Oda Özellikleri",
            groupNameEn: "Room Features\t",
            groupNameDe: "Zimmerausstattung"
        },
        {
            id: 138,
            groupId: 10,
            priority: 99,
            code: "Yemek Pişirme Köşesi",
            nameTr: "Yemek Pişirme Köşesi",
            nameEn: "Kitchenette",
            nameDe: "Küchenzeile",
            groupNameTr: "Oda Özellikleri",
            groupNameEn: "Room Features\t",
            groupNameDe: "Zimmerausstattung"
        },
        {
            id: 139,
            groupId: 10,
            priority: 99,
            code: "Minibar ",
            nameTr: "Minibar ",
            nameEn: "Minibar ",
            nameDe: "Minibar ",
            groupNameTr: "Oda Özellikleri",
            groupNameEn: "Room Features\t",
            groupNameDe: "Zimmerausstattung"
        },
        {
            id: 140,
            groupId: 10,
            priority: 99,
            code: "Buzdolabı",
            nameTr: "Buzdolabı",
            nameEn: "Fridge",
            nameDe: "Kühlschrank",
            groupNameTr: "Oda Özellikleri",
            groupNameEn: "Room Features\t",
            groupNameDe: "Zimmerausstattung"
        },
        {
            id: 142,
            groupId: 10,
            priority: 99,
            code: "Fayans",
            nameTr: "Fayans",
            nameEn: "Tiling",
            nameDe: "Fliesen",
            groupNameTr: "Oda Özellikleri",
            groupNameEn: "Room Features\t",
            groupNameDe: "Zimmerausstattung"
        },
        {
            id: 149,
            groupId: 10,
            priority: 99,
            code: "TV ",
            nameTr: "TV ",
            nameEn: "TV ",
            nameDe: "TV ",
            groupNameTr: "Oda Özellikleri",
            groupNameEn: "Room Features\t",
            groupNameDe: "Zimmerausstattung"
        },
        {
            id: 151,
            groupId: 10,
            priority: 99,
            code: "air_condition_self_regulated",
            nameTr: "Klima (Kendinden Ayarlanır) ",
            nameEn: "Air Conditioning (Individually Regulated)",
            nameDe: "Klimaanlage (individuell regulierbar)",
            groupNameTr: "Oda Özellikleri",
            groupNameEn: "Room Features\t",
            groupNameDe: "Zimmerausstattung"
        },
        {
            id: 152,
            groupId: 10,
            priority: 99,
            code: "Kaloriferler (Kendinden Ayarlanır) ",
            nameTr: "Kaloriferler (Kendinden Ayarlanır) ",
            nameEn: "Heating (Individually Regulated)",
            nameDe: "Heizung (Individuell Regulierbar)",
            groupNameTr: "Oda Özellikleri",
            groupNameEn: "Room Features\t",
            groupNameDe: "Zimmerausstattung"
        },
        {
            id: 153,
            groupId: 10,
            priority: 99,
            code: "Fırın ",
            nameTr: "Fırın ",
            nameEn: "Oven",
            nameDe: "Backofen",
            groupNameTr: "Oda Özellikleri",
            groupNameEn: "Room Features\t",
            groupNameDe: "Zimmerausstattung"
        },
        {
            id: 154,
            groupId: 10,
            priority: 99,
            code: "Mikrodalga ",
            nameTr: "Mikrodalga ",
            nameEn: "Microwave",
            nameDe: "Mikrowelle",
            groupNameTr: "Oda Özellikleri",
            groupNameEn: "Room Features\t",
            groupNameDe: "Zimmerausstattung"
        },
        {
            id: 144,
            groupId: 10,
            priority: 99,
            code: "air_condition_ing",
            nameTr: "Merkezi Isıtma",
            nameEn: "Air Conditioning (Centrally Regulated)",
            nameDe: "Klimaanlage (Zentral Geregelt)",
            groupNameTr: "Oda Özellikleri",
            groupNameEn: "Room Features\t",
            groupNameDe: "Zimmerausstattung"
        },
        {
            id: 108,
            groupId: 9,
            priority: 99,
            code: "Bisiklet Odası",
            nameTr: "Bisiklet Odası",
            nameEn: "Bicycle Cellar",
            nameDe: "Fahrradkeller",
            groupNameTr: "Otel İmkanları",
            groupNameEn: "Facilities",
            groupNameDe: "Einrichtungen"
        },
        {
            id: 109,
            groupId: 9,
            priority: 99,
            code: "Bisiklet Kiralama",
            nameTr: "Bisiklet Kiralama",
            nameEn: "Bicycle Hire",
            nameDe: "Fahrradverleih",
            groupNameTr: "Otel İmkanları",
            groupNameEn: "Facilities",
            groupNameDe: "Einrichtungen"
        },
        {
            id: 14,
            groupId: 5,
            priority: 99,
            code: "Ana Cadde Üzeri",
            nameTr: "Ana Cadde Üzeri",
            nameEn: "Located on main road",
            nameDe: "An der Hauptstraße gelegen",
            groupNameTr: "Otel Özellikleri",
            groupNameEn: "Hotel Features\t",
            groupNameDe: "Hotelausstattung"
        },
        {
            id: 268,
            groupId: 9,
            priority: 99,
            code: "restaurant_focrs",
            nameTr: "Ücretsiz Oda Servisi",
            nameEn: "Room Service Free Of Charge",
            nameDe: "Zimmerservice kostenlos",
            groupNameTr: "Otel İmkanları",
            groupNameEn: "Facilities",
            groupNameDe: "Einrichtungen"
        },
        {
            id: 346,
            groupId: 10,
            priority: 99,
            code: "Yağmur Duş",
            nameTr: "Yağmur Duş",
            nameEn: "Rain Shower",
            nameDe: "Rain Shower",
            groupNameTr: "Oda Özellikleri",
            groupNameEn: "Room Features\t",
            groupNameDe: "Zimmerausstattung"
        },
        {
            id: 128,
            groupId: 10,
            priority: 99,
            code: "Banyo",
            nameTr: "Banyo",
            nameEn: "Bathroom",
            nameDe: "Bad",
            groupNameTr: "Oda Özellikleri",
            groupNameEn: "Room Features\t",
            groupNameDe: "Zimmerausstattung"
        },
        {
            id: 113,
            groupId: 15,
            priority: 99,
            code: "Oyun Alanı",
            nameTr: "Oyun Alanı",
            nameEn: "Playground",
            nameDe: "Kinderspielplatz",
            groupNameTr: "Bebek/Çocuk İmkanlar",
            groupNameEn: "Facilities For Babies&Kids",
            groupNameDe: "\tEinrichtungen für Babys und Kinder"
        },
        {
            id: 367,
            groupId: 10,
            priority: 200,
            code: "Su Isıtıcısı",
            nameTr: "Su Isıtıcısı",
            nameEn: "Kettle",
            nameDe: "Wasserkocher",
            groupNameTr: "Oda Özellikleri",
            groupNameEn: "Room Features\t",
            groupNameDe: "Zimmerausstattung"
        }
    ]
};

export default facilityList;