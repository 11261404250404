<template>
   <div class="smart-table-container" style="overflow-x: auto">
      <SmartTableHeader @options="handlerHeaderOptions" :settings="options.options.header" @update-mode="handleModeUpdate" @viewmode="handlerViewModeReset" />
      <SmartTableBody :table="options" :pagination="footerOptions" ref="smarttable" />
      <SmartTableFooter @options="handlerFooterOptions" @activepage="handlerUpdatePage" :settings="footerOptions" />
   </div>
</template>

<script>
import SmartTableHeader from "@/components/utils/SmartTable/helpers/SmartTableHeader.vue";
import SmartTableBody from "@/components/utils/SmartTable/helpers/SmartTableBody.vue";
import SmartTableFooter from "@/components/utils/SmartTable/helpers/SmartTableFooter.vue";
export default {
   name: "SmartTable",
   props: {
      options: Object,
   },
   components: {
      SmartTableHeader,
      SmartTableBody,
      SmartTableFooter,
   },
   data() {
      return {
         headerOptions: null,
         footerOptions: {
            pages: [],
            viewMode: null,
            activepage: 1,
         },
      };
   },
   mounted() {
      this.handlerGeneratePaginations();
   },
   computed: {
      AutoMergeStatus() {
         return Array.isArray(this.options.rules.autoMerge) && this.options.rules.autoMerge.length > 0;
      },
   },
   methods: {
      handlerViewModeReset(status) {
         if (status) {
            this.footerOptions.viewMode = this.options.rows.length;
            this.footerOptions.activepage = 1;
         } else {
            this.footerOptions.viewMode = this.options.options.header.viewOptions.perPage;
         }
      },
      handleModeUpdate(value) {
         this.options.options.header.viewMode = value;
         this.$emit("table-mode", this.options.options.header.viewMode);
      },
      handlerHeaderOptions(options) {
         this.headerOptions = options;
      },
      handlerFooterOptions(options) {
         this.footerOptions.pagination.activepage = options;
      },
      handlerGeneratePaginations() {
         this.footerOptions.pages = [];

         const countAutoMerge = Object.keys(this.$refs.smarttable.rowspans).length;
         const countNoMerge = this.$refs.smarttable.Rows.length;
         const count = this.AutoMergeStatus ? countAutoMerge : countNoMerge;
         const perPage = this.options.options.header.viewOptions.perPage;
         const totalPage = Math.ceil(count / perPage);

         this.footerOptions.viewMode = perPage;

         // console.log(count, perPage, totalPage);

         if (totalPage != 0) {
            for (let i = 1; i <= totalPage; i++) {
               this.footerOptions.pages.push(i);
            }
         }
      },
      handlerUpdatePage(index) {
         this.footerOptions.activepage = index;
         // console.log("handlerUpdatePage", index);
         // this.$emit("options", index);
      },
   },
   watch: {
      "options.options.header.viewOptions.perPage": {
         handler: function (val) {
            console.log("watcher", val);
            this.handlerGeneratePaginations();
            // console.log("options watcher", val);
         },
         deep: true,
      },
   },
};
</script>

<style lang="scss" scoped>
.smart-table-container {
   background-color: #fff;
   box-shadow: rgba(0, 0, 0, 0.1) 0px 3px 12px;
   border-radius: 1rem;
   padding: 1rem;
}
</style>
