import store from "@/store";

class VersionChecker {
   constructor() {
      this.currentVersion = process.env.PACKAGE_VERSION;
      this.versionSeperator = ".";
   }

   checkVersion(lastVersion) {
      if (!lastVersion && store && store.state && store.state.app && store.state.app.spaLastVersion) lastVersion = store.state.app.spaLastVersion;

      return !lastVersion || this.currentVersion === lastVersion;
   }

   // Returns -1 if leastVersion is smaller or greater than current.
   compareVersion(leastVersion) {
      if (!leastVersion) {
         return 0;
      }

      var packageVersion = process.env.PACKAGE_VERSION;

      // vnum stores each numeric
      // part of version
      var vnum1 = 0,
         vnum2 = 0;

      // loop until both string are
      // processed
      for (var i = 0, j = 0; i < packageVersion.length || j < leastVersion.length; ) {
         // storing numeric part of
         // version 1 in vnum1
         while (i < packageVersion.length && packageVersion[i] != this.versionSeperator) {
            vnum1 = vnum1 * 10 + (packageVersion[i] - "0");
            i++;
         }

         // storing numeric part of
         // version 2 in vnum2
         while (j < leastVersion.length && leastVersion[j] != this.versionSeperator) {
            vnum2 = vnum2 * 10 + (leastVersion[j] - "0");
            j++;
         }

         if (vnum2 > vnum1 || vnum2 < vnum1) {
            return -1;
         }

         // if equal, reset variables and
         // go for next numeric part
         vnum1 = vnum2 = 0;
         i++;
         j++;
      }

      return 0;
   }

   checkAndUpdateStorageVersion(spaVersion) {
      if (!spaVersion) {
         return;
      }
      var storedVersion = localStorage.getItem("spaVersion");
      if (!storedVersion) {
         localStorage.setItem("spaVersion", spaVersion);
         return;
      }

      if (storedVersion === spaVersion) return;

      localStorage.setItem("spaVersion", spaVersion);
      this.reloadApp();
   }

   reloadApp() {
      if (process.env.VUE_APP_ENV == "LOCAL") return;
      location.reload(true);
   }
}

const versionChecker = new VersionChecker();

export { versionChecker };
