<template>
   <div class="add-on-popover-c">
      <div class="add-on-popover-wrapper">
         <div class="close-btn-wrapper">
            <i class="gts-icon icon-close-rounded icon-s close-btn" @click="closePopover()"></i>
         </div>
         <div v-if="AddonPopover">
            <div class="add-on-popover-content-wrapper">
               <!-- Image Gallery -->
               <div class="add-on-image-gallery-wrapper">
                  <div v-for="(img, imgIndex) in AddonPopover.images" :key="imgIndex" class="add-on-img-wrapper">
                     <img :src="img" v-if="imgIndex === AddonPopover.activeImg" />
                  </div>
                  <div v-if="AddonPopover.images.length > 1">
                     <button class="btn-prev" @click="handlerImageGallery(-1)">
                        <i class="gts-icon icon-arrow icon-s"></i>
                     </button>
                     <button class="btn-next" @click="handlerImageGallery(1)">
                        <i class="gts-icon icon-arrow icon-s"></i>
                     </button>
                  </div>
               </div>
               <div v-for="(item, itemIndex) in handlerInformationText(AddonPopover.disabledChildAges)" :key="itemIndex" class="age-restriction">
                  <i class="gts-icon icon-info-circle-orange icon-sm"></i>
                  <span>{{ item.msg }}</span>
               </div>
               <div class="add-on-informations-wrapper">
                  <span class="add-on-title">{{ AddonPopover.title }}</span>

                  <!-- Updated Description Section -->
                  <div class="description">
                     <template v-if="descriptionAsList.length">
                        <ul>
                           <li v-for="(item, index) in visibleDescriptionAsList" :key="index">{{ item }}</li>
                        </ul>
                     </template>
                     <template v-else>
                        {{ AddonPopover.isExpanded ? AddonPopover.description : truncatedDescription }}
                     </template>
                  </div>

                  <span class="read-more" v-if="shouldShowReadMore" @click="toggleDescription">
                     {{ AddonPopover.isExpanded ? $t("orderDetail_addOnReadLess") : $t("orderDetail_addOnReadMore") }}
                  </span>

                  <div class="add-on-date-price-wrapper">
                     <div class="calender-addon">
                        <i class="gts-icon icon-calendar-pink"> </i>
                        <span class="add-on-date">
                           {{ AddonPopover.date }}
                        </span>
                     </div>
                     <div class="money-addon">
                        <i class="gts-icon icon-money"> </i>
                        <span class="add-on-price">{{ AddonPopover.price }}</span>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>
export default {
   name: "AddonPopover",
   props: {
      addonInformations: Object,
   },
   data() {
      return {
         addOn: null,
         characterLimit: 100,
      };
   },
   created() {
      this.addOn = {
         title: this.addonInformations.title,
         activeImg: 0,
         images: [this.addonInformations.image1Url, this.addonInformations.image2Url].filter((url) => url),
         description: this.addonInformations.description,
         isExpanded: false,
         date: this.$options.filters.FormatterDate(this.addonInformations.effectiveDate),
         price: this.$options.filters.price(this.addonInformations.price, this.addonInformations.currency),
         disabledChildAges: this.addonInformations.childAges,
      };
   },
   computed: {
      AddonPopover() {
         return {
            ...this.addOn,
            visibleContent: this.addOn.isExpanded
               ? this.addOn.description
               : this.addOn.description.length > this.characterLimit
               ? this.addOn.description.substring(0, this.characterLimit) + "..."
               : this.addOn.description,
         };
      },
      truncatedDescription() {
         return this.addOn.description.length > this.characterLimit
            ? this.addOn.description.substring(0, this.characterLimit) + "..."
            : this.addOn.description;
      },
      descriptionAsList() {
         return this.addOn.description.includes("\n") ? this.addOn.description.split("\n") : [];
      },
      visibleDescriptionAsList() {
         const combinedLength = this.descriptionAsList.join("").length;
         if (!this.addOn.isExpanded && combinedLength > this.characterLimit) {
            let charCount = 0;
            return this.descriptionAsList.filter((item) => {
               charCount += item.length;
               return charCount <= this.characterLimit;
            });
         }
         return this.descriptionAsList;
      },
      shouldShowReadMore() {
         return this.addOn.description.length > this.characterLimit || this.descriptionAsList.join("").length > this.characterLimit;
      },
   },
   methods: {
      closePopover() {
         this.$emit("close-popover", false);
      },
      handlerImageGallery(val) {
         if (this.addOn.images.length > 0) {
            this.addOn.activeImg = (this.addOn.activeImg + val + this.addOn.images.length) % this.addOn.images.length;
         }
      },
      toggleDescription() {
         this.addOn.isExpanded = !this.addOn.isExpanded;
      },
      handlerInformationText(ages) {
         return ages.map((item) => {
            const ageRange = `${item.fromAge}-${item.toAge}`;
            let msg = "hotelRoomDetail_lblAddOnInfo";
            if (item.disabled) msg += "Disabled";
            if (item.free) msg += "Free";
            return { msg: this.$t(msg).replace("#AGE_RANGE#", ageRange) };
         });
      },
   },
};
</script>

<style lang="scss" scoped>
.add-on-popover-c {
   position: relative;
   padding-top: 0.5rem;
   padding-bottom: 0.25rem;
   .add-on-popover-wrapper {
      z-index: 100;
      position: absolute;
      background: #fff;
      border: 1px solid #e6e6e6;
      box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 12px -3px;
      border-radius: 1rem;
      min-width: 400px;
      right: 20px;

      .close-btn-wrapper {
         background: white;
         border-radius: 100%;
         position: absolute;
         right: 0;
         z-index: 99;
         right: 0rem;
         top: 0rem;
         transform: translateY(-0.75rem) translateX(0.75rem);

         cursor: pointer;
      }

      .add-on-popover-content-wrapper {
         padding: 1.25rem;

         .add-on-image-gallery-wrapper {
            position: relative;
            .add-on-img-wrapper {
               img {
                  border-radius: 0.5rem;
               }
            }

            button {
               background-color: #fff;
               position: absolute;
               top: 0;
               bottom: 0;
               height: 50px;
               margin: auto 0.5rem;
               border-radius: 0.5rem;
               border: 1px solid #eee;

               &.btn-prev {
                  i {
                     transform: rotate(180deg);
                  }
               }

               &.btn-next {
                  right: 0;
               }
            }
         }
         .add-on-informations-wrapper {
            .add-on-title {
               font-weight: 600;
               font-size: 1.4rem;
            }
            .add-on-date-price-wrapper {
               display: flex;
               justify-content: space-between;
               align-items: center;
               margin-top: 1rem;
               .add-on-date {
                  margin-top: 0.25rem;
                  text-align: center;
                  font-size: 1.125rem;
                  font-weight: 700;
                  color: #da00e6;
                  padding-left: 0.5rem;
               }
               .add-on-price {
                  color: #da00e6;
                  font-size: 1.25rem;
                  font-weight: 700;
                  padding-left: 0.5rem;
                  margin-top: 0.15rem;
               }
               .calender-addon {
                  display: flex;
                  flex-direction: row;
               }
               .money-addon {
                  display: flex;
                  flex-direction: row;
               }
            }
         }
         .age-restriction {
            display: flex;
            margin: 0.5rem 0 !important;
            max-width: 100%;
            align-items: center;
            font-size: 14px;
            color: #ff6a00;
            border: 1px solid #ff990086;
            margin-top: auto;
            border-radius: 0.75rem;
            background: #ffe6000c;
            padding: 0.5rem;
            span {
               padding-left: 10px;
            }
         }
      }
      .add-on-popover-footer-controller {
         background-color: #d546ec;
         padding: 1rem;
         display: flex;
         justify-content: center;
         align-items: center;
         border-bottom-left-radius: 1rem;
         border-bottom-right-radius: 1rem;

         .addon-bullet {
            cursor: pointer;
            display: inline-block;
            width: 36px;
            height: 12px;
            border-radius: 24rem;
            background-color: #fff;
            margin: 0 0.25rem;
            opacity: 0.6;

            &.active {
               opacity: 1;
            }
         }
      }
   }
}

.description {
   margin: 0.5rem 0;
   font-size: 0.95rem;
   color: #666;
   overflow: hidden;
   word-break: break-word;

   ul {
      list-style-type: disc;
      padding-left: 20px;
   }

   li {
      margin-bottom: 0.5rem;
   }
}

.read-more {
   color: #000;
   font-weight: bold;
   cursor: pointer;
   margin-top: auto;
   text-decoration: underline;
   margin-bottom: 0.5rem;
}
</style>
