// Import the required dependencies
import Vue from "vue";
// Vue directive definition
Vue.directive("uihelper", {
   // Called when the bound element is inserted into the DOM
   inserted: function (el, binding) {
      // Apply the tooltip based on the directive value
      setTimeout(() => {
         applyTooltip(el, binding.value);
      }, 500);
   },

   // Called when the bound element's value updates
   componentUpdated: function (el, binding) {
      // Reapply the tooltip when the value updates
      applyTooltip(el, binding.value);
   },
});

// Helper function to apply the tooltip based on the directive value
function applyTooltip(el, value) {
   if (value && typeof value === "object") {
      // Extract the message and position from the value object
      const { msg, position } = value;

      // Create the tooltip element
      const tooltip = document.createElement("div");
      tooltip.className = `gts-ui-helper ${position}`;
      tooltip.textContent = msg;

      // Add a class to the bound element to handle positioning
      el.classList.add("gts-ui-helper-wrapper");

      // Append the tooltip to the element
      el.appendChild(tooltip);

      // Position the tooltip relative to the element
      positionTooltip(el, tooltip, position);

      // Show the tooltip on mouseover
      el.addEventListener("mouseover", function () {
         tooltip.style.visibility = "visible";
      });

      // Hide the tooltip on mouseout
      el.addEventListener("mouseout", function () {
         // tooltip.style.visibility = "hidden";
      });
   }
}

// Helper function to position the tooltip relative to the element
function positionTooltip(el, tooltip, position) {
   const elRect = el.getBoundingClientRect();

   tooltip.style.position = "absolute";

   switch (position) {
      case "top":
         tooltip.style.top = elRect.top - tooltip.offsetHeight + "px";
         tooltip.style.left = elRect.left + elRect.width / 2 - tooltip.offsetWidth / 2 + "px";
         break;
      case "right":
         tooltip.style.top = elRect.top + elRect.height / 2 - tooltip.offsetHeight / 2 + "px";
         tooltip.style.left = elRect.left + elRect.width + "px";
         break;
      case "bottom":
         tooltip.style.top = elRect.top + elRect.height + "px";
         tooltip.style.left = elRect.left + elRect.width / 2 - tooltip.offsetWidth / 2 + "px";
         break;
      case "left":
         tooltip.style.top = elRect.top + elRect.height / 2 - tooltip.offsetHeight / 2 + "px";
         tooltip.style.left = elRect.left - tooltip.offsetWidth + "px";
         break;
      default:
         tooltip.style.top = elRect.top + elRect.height + "px";
         tooltip.style.left = elRect.left + elRect.width / 2 - tooltip.offsetWidth / 2 + "px";
         break;
   }
}
