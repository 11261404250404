import Popup from "@/gts-popup.js";
import { flightService } from "@/service/index.js";
import { hotelService } from "@/service/index.js";
import { transferService } from "@/service/index.js";
import { nextTickControl } from "./nexTick";
import { setCookie } from "@/utils.js";
import { showProgressGif, hideProgressGif } from "./Helpers/paymentHelper.js";

function openPopupElement(customUrl = "/loading") {
   var width = 500;
   var height = 500;
   var screenWidth = window.screen.width;
   var screenHeight = window.screen.height;
   var left = (screenWidth - width) / 2;
   var top = (screenHeight - height) / 2;
   return window.open(customUrl, "paymentPostContentForm", `location=no, left=${left}, top=${top}, width=${width}, height=${height}`);
}

function handlePaymentContent(paymentPostContent, windowPopupElement) {
   if (paymentPostContent.startsWith("http")) {
      windowPopupElement.location.href = paymentPostContent;
   } else {
      windowPopupElement.document.write(paymentPostContent);
   }
}

function createRequestObject(type, req) {
   switch (type) {
      case "hotel":
         return { ...req, rooms: req.rooms };
      case "transfer":
         return { ...req, travellers: req.travellers };
      case "flight":
         return { ...req };
      default:
         return req;
   }
}

function handleResponse(self, response, serviceType, windowPopupElement) {
   response.result.service = `TICKETRESERVED_${serviceType.toUpperCase()}`;
   if (!response.result.success) {
      Popup.result(response.result).then(() => {
         if (response.result.subCode == "PRICE_DIFFERENCE") window.location.reload();
      });
      self.paymentUuid = null;
      self.callback3D = false;
      if (windowPopupElement) {
         windowPopupElement.close();
      }
   } else {
      if (response.result.subCode == "PAYMENT_POST") {
         self.paymentPostContent = response.paymentPostContent;
         self.paymentUuid = response.paymentUuid;
         handlePaymentContent(response.paymentPostContent, windowPopupElement);
         nextTickControl(self, windowPopupElement);
      } else {
         if (windowPopupElement) {
            windowPopupElement.close();
         }
         const popupCb = () => {
            window.location.reload();
         };
         Popup.result(response.result, popupCb).then(() => {
            window.location.reload();
         });
      }
   }
}

function funcTicketReservedFlight(self, req) {
   let request = createRequestObject("flight", req);
   let msg = self.$i18n.t(`${self.$options.name}_progressGif_TicketReservedFlight`);
   let windowPopupElement;

   showProgressGif(msg);

   if (req.paymentType != 1 && !self.callback3D) {
      windowPopupElement = openPopupElement();
   }

   flightService.flightTicketReserved(request).then((response) => {
      hideProgressGif();
      handleResponse(self, response, "flight", windowPopupElement);
   });
}
function funcTicketPartialReservedHotel(self, req) {
   let request = createRequestObject("hotel", req);
   let msg = self.$i18n.t(`${self.$options.name}_progressGif_TicketReservedHotel`);
   let windowPopupElement;

   showProgressGif(msg);

   if (req.payment.paymentType != 1 && !self.callback3D) {
      windowPopupElement = openPopupElement();
   }

   hotelService.hotelTicketPartialReserved(request).then((response) => {
      setCookie("rooms", JSON.stringify(request.rooms), 0, 1);
      hideProgressGif();
      handleResponse(self, response, "hotel", windowPopupElement);
   });
}
function funcTicketReservedHotel(self, req) {
   let request = createRequestObject("hotel", req);
   let msg = self.$i18n.t(`${self.$options.name}_progressGif_TicketReservedHotel`);
   let windowPopupElement;

   showProgressGif(msg);

   if (req.payment.paymentType != 1 && !self.callback3D) {
      windowPopupElement = openPopupElement();
   }

   hotelService.hotelTicketReserved(request).then((response) => {
      setCookie("rooms", JSON.stringify(request.rooms), 0, 1);
      hideProgressGif();
      handleResponse(self, response, "hotel", windowPopupElement);
   });
}

function funcTicketReservedTransfer(self, req) {
   let request = createRequestObject("transfer", req);
   let msg = self.$i18n.t(`${self.$options.name}_progressGif_TicketReservedTransfer`);
   let windowPopupElement;

   showProgressGif(msg);

   if (req.payment.paymentType != 1 && !self.callback3D) {
      windowPopupElement = openPopupElement();
   }

   transferService.transferTicketReserved(request).then((response) => {
      setCookie("travelerData", JSON.stringify(request.travellers), 0, 1);
      hideProgressGif();
      handleResponse(self, response, "transfer", windowPopupElement);
   });
}

export { funcTicketReservedFlight, funcTicketReservedHotel, funcTicketReservedTransfer, funcTicketPartialReservedHotel };
