const airlineHexColor = {
  99: "#33427c",
  "4Y": "#7ccce2",
  A3: "#1f325b",
  "6E": "#001b94",
  A9: "#e51a3a",
  AA: "#344457",
  AF: "#1f2b50",
  AJ: "#33427c",
  AT: "#c31632",
  AY: "#1b489b",
  BA: "#cf2517",
  B6: "#003876",
  BJ: "#fec93d",
  CA: "#ec1f24",
  CD: "#eb2227",
  CI: "#ca0b39",
  D8: "#d51939",
  CX: "#005d63",
  DE: "#f8ad08",
  DL: "#991932",
  DY: "#d51939",
  EK: "#d71c23",
  EI: "#428c7d",
  EE: "#075c9c",
  ET: "#ca212e",
  EY: "#c89905",
  EW: "#b5185f",
  FH: "#0855a3",
  FI: "#9a4a25",
  FR: "#ffe716",
  FZ: "#036597",
  G9: "#e51643",
  GF: "#a19061",
  GB: "#ed2225",
  HX: "#ed1d24",
  HF: "#a0c441",
  HC: "#007f38",
  IB: "#f7ba0f",
  JL: "#ce0909",
  JQ: "#ff5716",
  JU: "#1a2f52",
  KL: "#1fa0db",
  LG: "#19b1bc",
  LH: "#19213c",
  LO: "#24356c",
  LX: "#cc2128",
  MF: "#008ac5",
  ME: "#067c5f",
  MS: "#202e56",
  N4: "#ce212c",
  N7: "#283645",
  OS: "#d82116",
  NH: "#263d89",
  NP: "#c1af7a",
  PC: "#fcc200",
  PK: "#20492b",
  PR: "#ea0001",
  QF: "#eb151c",
  RO: "#2a3877",
  QR: "#5c0d33",
  S7: "#bdd01d",
  SK: "#050599",
  SN: "#e4202f",
  SU: "#05539c",
  TP: "#cc2c34",
  TK: "#e51b29",
  UA: "#18329f",
  U2: "#ff6700",
  UT: "#004990",
  UU: "#0868a0",
  VN: "#c89211",
  VS: "#db0b2f",
  VA: "#e0e0e0",
  VW: "#003b79",
  W6: "#ffffff",
  VY: "#666666",
  W9: "#C5037C",
  WH: "#231f20",
  WS: "#00aaa6",
  WX: "#791521",
  WY: "#b5995a",
  XC: "#e6292b",
  XK: "#0069b0",
  XQ: "#11448f",
};

export default airlineHexColor;
