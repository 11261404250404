<template>
   <div class="gts-slider-wrapper">
      <div class="gts-slider-inner">
         <div class="gts-gallery-wrapper">
            <div class="gallery-cta-button left-arrow" @click="handlerLeftArrow">
               <i class="gts-icon icon-arrow-left" />
            </div>
            <div class="gallery-list">
               <template v-for="(hotelImage, index) in hotelImages">
                  <div class="gallery-list-item" v-if="currentImage === index" :key="index" v-cloak>
                     <img
                        :src="hotelImage.medium"
                        loading="lazy"
                        alt="hotel gallery image"
                        :class="hotelImages[currentImage]?.large ? 'cursor-pointer' : ''"
                        @click="withLargeGallery ? handlerLightbox() : null"
                        @error="($event.target.src = defaultImageThumbnail), ($event.target.srcset = defaultImageThumbnail)" />
                  </div>
               </template>
               <span class="gallery-list-counter">{{ currentImage + 1 }} / {{ hotelImages.length }}</span>
            </div>
            <div class="gallery-cta-button right-arrow" @click="handlerRightArrow">
               <i class="gts-icon icon-arrow-right" />
            </div>
            <div class="gallery-cta-button large-viewer" :class="!hotelImages[currentImage]?.large ? 'hidden' : ''">
               <i class="gts-icon icon-aside-search" @click="handlerLightbox()" />
            </div>
         </div>
         <!-- Large Image Viewer Section -->
         <transition name="fade" mode="out-in">
            <div @click.stop="isLightBoxOpen = !isLightBoxOpen" class="light-box__bg" v-if="isLightBoxOpen && withLargeGallery"></div>
         </transition>
         <div v-if="isLightBoxOpen && withLargeGallery">
            <div class="light-box__close" @click.stop="isLightBoxOpen = !isLightBoxOpen">
               <i class="gts-icon icon-close-rounded white-icon" />
            </div>
            <div class="light-box__prev light-box__btn" @click="handlerLeftArrow">
               <i class="gts-icon icon-arrow-left" />
            </div>

            <div v-if="isLightBoxOpen" class="light-box__container">
               <transition name="fade" mode="out-in">
                  <img :key="currentImage - 'l-gallery'" :src="handlerLightboxImg()" class="light-box__container__img" />
               </transition>
            </div>
            <div class="light-box__next light-box__btn" @click="handlerRightArrow">
               <i class="gts-icon icon-arrow-right" />
            </div>
         </div>
         <!-- Large Image Viewer Section End  -->
         <div class="gts-mini-gallery-wrapper" v-if="withMiniGallery">
            <div class="mini-gallery-list">
               <div
                  class="mini-gallery-item"
                  :class="currentImage === index && 'active'"
                  v-for="(hotelImage, index) in hotelImages"
                  :key="index"
                  @click="handlerSelectImage(index)">
                  <img
                     :src="hotelImage.small"
                     loading="lazy"
                     alt="hotel gallery image"
                     @error="($event.target.src = defaultImageThumbnail), ($event.target.srcset = defaultImageThumbnail)" />
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>
import defaultImageThumbnail from "@/assets/images/noimg.svg";

export default {
   name: "UIGtsSliderComponent",
   props: {
      hotelImages: { type: Array },
      withMiniGallery: { type: Boolean },
      withLargeGallery: { type: Boolean },
   },
   data() {
      return {
         currentImage: 0,
         defaultImageThumbnail: defaultImageThumbnail,
         isLightBoxOpen: false,
         pressedKey: null,
      };
   },
   created() {
      document.addEventListener("keydown", this.handleKeydown);
      if (!this.hotelImages[0].medium) {
         this.currentImage = this.hotelImages.length - 1;
      }
   },
   beforeDestroy() {
      document.removeEventListener("keydown", this.handleKeydown);
   },
   methods: {
      handlerSelectImage(index) {
         var self = this;
         if (index == self.currentImage) self.handlerLightbox();
         if (!self.hotelImages[index].medium) {
            self.currentImage = index + 1;
            return;
         }
         self.currentImage = index;
      },
      handlerLeftArrow() {
         var self = this;
         self.pressedKey = 37;

         if (self.currentImage <= 0) {
            self.currentImage = this.hotelImages.length - 1;
         } else {
            self.currentImage--;
         }

         if (self.currentImage == 0) self.currentImage = this.hotelImages.length - 1;

         !self.hotelImages[self.currentImage].medium && self.hotelPhotoChecker();
      },
      handlerRightArrow() {
         var self = this;
         //navigation null check both medium and large galleries
         self.pressedKey = 39;

         if (self.currentImage == this.hotelImages.length - 1) {
            self.currentImage = 0;
         } else {
            self.currentImage++;
         }
         !self.hotelImages[self.currentImage].medium && self.hotelPhotoChecker();
      },
      handlerLightbox() {
         this.hotelImages[this.currentImage].large ? (this.isLightBoxOpen = !this.isLightBoxOpen) : this.handlerLightboxImg();
      },
      handlerLightboxImg() {
         const self = this;
         if (!self.hotelImages[self.currentImage].large) {
            self.pressedKey && self.hotelPhotoChecker();
         }
         return self.hotelImages[self.currentImage].large;
      },
      hotelPhotoChecker() {
         const self = this;
         // 37: left arrow, 39: right arrow
         if (self.isLightBoxOpen) {
            self.nullPhotoSkipper("large");
         } else {
            self.nullPhotoSkipper("medium");
         }
      },
      nullPhotoSkipper(size) {
         var self = this;
         if (self.pressedKey == 37) {
            for (let i = self.currentImage; i >= 0; i--) {
               if (!self.hotelImages[i][size]) self.currentImage--;
               else break;
            }
         }
         if (self.pressedKey == 39) {
            for (let i = self.currentImage; i < self.hotelImages.length; i++) {
               if (!self.hotelImages[i][size]) self.currentImage++;
               else break;
            }
         }
      },
      handleKeydown(e) {
         switch (e.keyCode) {
            case 37:
               this.handlerLeftArrow();
               break;
            case 39:
               this.handlerRightArrow();
               break;
            case 27:
               this.isLightBoxOpen ? (this.isLightBoxOpen = false) : null;
               break;
         }
      },
   },
   watch: {
      isLightBoxOpen: function (val) {
         if (val && this.isLightBoxOpen) {
            document.body.style.overflow = "hidden";
         } else {
            document.body.style.overflow = "auto";
         }
      },
   },
};
</script>

<style lang="scss" scoped>
.no-scroll {
   overflow: hidden;
}
.fade-enter-active,
.fade-leave-active {
   transition: opacity 0.2s;
}
.fade-enter,
.fade-leave-to {
   opacity: 0;
}
.gts-slider-wrapper {
   .gts-slider-inner {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
      @media (min-width: 576px) {
         flex-direction: row;
      }

      .gts-gallery-wrapper {
         width: 100%;
         display: flex;
         align-items: center;
         position: relative;
         @media (min-width: 576px) {
            width: 37%;
         }

         .gallery-cta-button {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            width: 32px;
            height: 15%;
            background-color: rgba(255, 255, 255, 0.9);
            color: black;
            cursor: pointer;

            & > i {
               width: 12px !important;
            }

            &.left-arrow {
               left: 0;
               border-radius: 0 8px 8px 0;
            }
            &.right-arrow {
               right: 0;
               border-radius: 8px 0 0 8px;
            }

            &.large-viewer {
               right: 9px;
               bottom: 16px;
               padding: 0.5rem;
               width: 38px;
               height: 38px;
               border-radius: 0.5rem;
               i {
                  width: 22px !important;
                  height: 22px !important;
               }
            }

            &.hidden {
               display: none !important;
            }
         }
         .gallery-list {
            display: flex;
            align-items: flex-start;
            justify-content: center;
            flex-wrap: nowrap;
            width: 100%;
            overflow: hidden;

            .gallery-list-item {
               width: 100%;
               min-width: 100%;

               .cursor-pointer {
                  cursor: pointer;
               }

               & > img {
                  width: 100%;
                  height: 350px;
                  max-height: 350px;
                  border-radius: 8px;
                  object-fit: cover;
               }
            }
            .gallery-list-counter {
               position: absolute;
               bottom: 16px;
               padding: 4px 16px;
               background-color: rgba(255, 255, 255, 0.9);
               border-radius: 16px;
               font-size: 14px;
               font-weight: 600;
               color: #363940;
            }
         }
      }
      .gts-mini-gallery-wrapper {
         width: 100%;
         @media (min-width: 576px) {
            width: 60%;
         }
         .mini-gallery-list {
            display: flex;
            flex-wrap: wrap;
            overflow-y: scroll;
            width: 100%;
            height: auto;
            max-height: initial;

            @media (min-width: 576px) {
               height: 350px;
               max-height: 350px;
            }

            .mini-gallery-item {
               width: 96px;
               max-width: 96px;
               height: 80px;
               max-height: 80px;
               margin-right: 0.125rem;
               border: 4px solid transparent;
               border-radius: 4px;
               cursor: pointer;

               @media (min-width: 576px) {
                  margin-right: 24px;
               }

               &.active {
                  border-color: var(--accent-secondary);
               }

               &:last-child {
                  margin-right: 0;
               }
               & > img {
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
               }
            }
         }
      }
      .light-box {
         &__container {
            align-items: center;
            display: flex;
            height: 100%;
            justify-content: center;
            left: 0px;
            padding: 10px;
            position: fixed;
            top: 0px;
            width: 100%;
            z-index: 2000;
            scale: 1.3;
            background-color: rgba(0, 0, 0, 0.8);
            img {
               align-self: center;
            }
         }
         &__btn {
            background-size: contain;
            background-position: center;
            align-self: center;
            padding: 15px;
         }
         &__close,
         &__btn {
            cursor: pointer;
         }
         &__close,
         &__btn {
            z-index: 3000;
            background-color: rgba(255, 255, 255, 0.9);
            color: black;
            border-radius: 50%;
            position: fixed;
            top: 50%;
            vertical-align: bottom;
            margin-right: -10px;
            padding: 10px;
         }
         &__next {
            right: 20px;
         }
         &__prev {
            left: 20px;
         }
         &__close {
            cursor: pointer;
            position: fixed;
            filter: brightness(5);
            background: transparent !important;
            top: 30px;
            left: 87%;
         }
      }
   }
}
</style>
