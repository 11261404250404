<template>
   <div class="flight-form-wrapper g-input-container" v-click-outside="handlerOutClick">
      <label>{{ label }}</label>
      <div class="flightform-inner-wrapper">
         <div class="inner-container" @click="dropdownStatue = !dropdownStatue">
            <div class="label-container">
               <input type="text" :tabindex="tabindex" />
               <div v-if="handleDataCount.length == 0">
                  <span>{{ $t("common_nothingSelected") }}</span>
               </div>
               <div v-else-if="handleDataCount.length > 1">
                  <span v-if="label == 'Airlines'">{{ handleDataCount.length + " " + $t("searchComponentFlight_airlinesSelected") }}</span>
                  <span v-else-if="selectIdentifier == 'stops'">{{ handlerStopFMTs(handleDataCount.length) }}</span>
                  <!-- <span v-else-if="selectIdentifier == 'stops'">{{ $t("common_zwischenstopps") }}</span> -->
                  <span v-else>{{ handleDataCount.length + " " + $t("common_itemsSelected") }}</span>
               </div>
               <div v-else>
                  <span v-for="(data, index) in handleDataCount" :key="index">{{ data.name }}</span>
               </div>
            </div>
            <i class="gts-icon icon-arrow-down icon-xs"></i>
         </div>
         <div class="gts-dropdown" v-if="dropdownStatue">
            <div class="controls-container" v-if="showControls">
               <input type="text" v-model="searchquery" />

               <i class="gts-icon icon-close-rounded gts-animation icon-xs" @click="handlerClearInput" v-if="searchquery"></i>
               <button @click="handlerSelectAll(allselected)" :class="allselected ? 'deselectall' : 'selectall'">
                  {{ allselected ? $t("common_deselectAll") : $t("common_selectAll") }}
               </button>
            </div>

            <ul>
               <li v-for="(data, index) in handleDatas" :key="index" :class="data.selected ? 'selected' : 'unselected'" @click="handlerSelect(index)">
                  {{ data.name }}
               </li>
            </ul>
         </div>
      </div>
   </div>
</template>

<script>
export default {
   name: "FligtSelectForms",
   props: {
      label: String,
      showControls: Boolean,
      tabindex: Number,
      selectIdentifier: String,
   },
   data() {
      return {
         allselected: true,
         dropdownStatue: false,
         searchquery: "",
         datas: [],
      };
   },

   computed: {
      handleDatas() {
         const self = this;
         if (self.showControls) {
            let query = self.searchquery;

            return self.datas.filter((data) => data.searchStrings.toLowerCase().includes(query.toLowerCase()));
         } else {
            return self.datas;
         }
      },
      handleDataCount() {
         const self = this;
         return self.datas.filter((selected) => selected.selected == true);
      },
   },
   methods: {
      handlerStopFMTs(count) {
         // return count;
         return `${count} ${this.$i18n.t("common_zwischenstopps")}`;
      },
      handlerSelect(index) {
         const self = this;

         if (self.selectIdentifier != "airlines") {
            self.datas[index].selected = !self.datas[index].selected;
            if (self.handleDataCount == 0) {
               self.datas[index].selected = true;
            }
         } else {
            index = self.handleDatas[index].index;
            self.datas[index].selected = !self.datas[index].selected;
         }
         const selectedItems = self.datas.filter((data) => {
            return data.selected;
         });

         const arrSelectedItems = Array.from(selectedItems, (data) => {
            return data.value;
         });

         self.$emit("update-selection", arrSelectedItems);
      },
      handlerSelectAll(val) {
         const self = this;
         self.allselected = !self.allselected;
         if (!val) {
            self.datas.forEach((data) => {
               data.selected = true;
            });
         } else {
            self.datas.forEach((data) => {
               data.selected = false;
            });
         }

         self.$emit("update-selection", []);
      },
      handlerClearInput() {
         const self = this;
         self.searchquery = "";
      },
      handlerOutClick() {
         const self = this;
         self.dropdownStatue = false;
      },
      handlerAirlineList() {
         const self = this;
         self.datas = [];
         const tempArr = [];
         const superiorAirlines = [
            {
               iataCode: "TK",
               iataLabel: "TK",
               icaoCode: "THY",
               icaoLabel: "THY",
               nameTr: "Türk Hava Yolları",
               nameEn: "Turkish Airlines",
               nameDe: "Turkish Airlines",
            },
            {
               iataCode: "VF",
               iataLabel: "VF",
               icaoCode: "VF",
               icaoLabel: "VF",
               nameTr: "AJet",
               nameEn: "AJet",
               nameDe: "AJet",
            },
            {
               iataCode: "PC",
               iataLabel: "PC",
               icaoCode: "PGT",
               icaoLabel: "PGT",
               nameTr: "Pegasus Airlines",
               nameEn: "Pegasus Airlines",
               nameDe: "Pegasus Airlines",
            },
            {
               iataCode: "XQ",
               iataLabel: "XQ",
               icaoCode: "SXS",
               icaoLabel: "SXS",
               nameTr: "SunExpress",
               nameEn: "SunExpress",
               nameDe: "SunExpress",
            },
            {
               iataCode: "XC",
               iataLabel: "XC",
               icaoCode: "CAI",
               icaoLabel: "CAI",
               nameTr: "Corendon Airlines",
               nameEn: "Corendon Airlines",
               nameDe: "Corendon Airlines",
            },
            {
               iataCode: "FH",
               iataLabel: "FH",
               icaoCode: "FHY",
               icaoLabel: "FHY",
               nameTr: "Freebird Airlines",
               nameEn: "Freebird Airlines",
               nameDe: "Freebird Airlines",
            },
            {
               iataCode: "EW",
               iataLabel: "EW",
               icaoCode: "EWG",
               icaoLabel: "EWG",
               nameTr: "Eurowings",
               nameEn: "Eurowings",
               nameDe: "Eurowings",
            },
            {
               iataCode: "LH",
               iataLabel: "LH",
               icaoCode: "DLH",
               icaoLabel: "DLH",
               nameTr: "Lufthansa",
               nameEn: "Lufthansa",
               nameDe: "Lufthansa",
            },
            {
               iataCode: "OS",
               iataLabel: "OS",
               icaoCode: "AUA",
               icaoLabel: "AUA",
               nameTr: "Austrian",
               nameEn: "Austrian",
               nameDe: "Austrian",
            },
            {
               iataCode: "LX",
               iataLabel: "LX",
               icaoCode: "SWR",
               icaoLabel: "SWR",
               nameTr: "SWISS",
               nameEn: "SWISS",
               nameDe: "SWISS",
            },
            {
               iataCode: "CD",
               iataLabel: "CD",
               icaoCode: "CND",
               icaoLabel: "CND",
               nameTr: "Corendon Dutch Havayolu ",
               nameEn: "Corendon Dutch Airlines ",
               nameDe: "Corendon Dutch Airlines",
            },
            {
               iataCode: "XR",
               iataLabel: "XR",
               icaoCode: "CXI",
               icaoLabel: "CXI",
               nameTr: "Corendon Airlines Europe",
               nameEn: "Corendon Airlines Europe",
               nameDe: "Corendon Airlines Europe",
            },
         ];

         superiorAirlines.forEach((superiorAirlines) => {
            const rowObj = {
               name: superiorAirlines.nameTr,
               value: superiorAirlines.iataCode,
               searchStrings: superiorAirlines.nameTr + superiorAirlines.nameEn + superiorAirlines.nameDe + superiorAirlines.iataCode,
               selected: true,
               index: null,
            };
            tempArr.push(rowObj);
         });

         self.$store.state.app.airlineList.forEach((airline) => {
            let a = superiorAirlines.filter((a) => a.iataCode == airline.iataCode);

            if (a.length == 0) {
               const rowObj = {
                  name: airline.nameTr,
                  value: airline.iataCode,
                  searchStrings: airline.nameTr + airline.nameEn + airline.nameDe + airline.iataCode,
                  selected: true,
                  index: null,
               };
               tempArr.push(rowObj);
            }
         });

         tempArr.map((airline, index) => {
            const rowObj = {
               name: airline.name,
               value: airline.value,
               searchStrings: airline.searchStrings,
               selected: true,
               index: index,
            };

            self.datas.push(rowObj);
         });
      },
   },
   watch: {
      selectIdentifier: {
         handler: function (identifier) {
            const self = this;
            switch (identifier) {
               case "cabintypes":
                  self.datas = [
                     { name: this.$i18n.t(`enum_economy`), value: 1, selected: true },
                     { name: this.$i18n.t(`enum_business`), value: 2, selected: true },
                  ];
                  break;
               case "stops":
                  self.datas = [
                     { name: this.$i18n.t("searchFiltersFlight_noneStop"), value: 0, selected: true },
                     { name: `1 ${this.$i18n.t("searchFiltersFlight_stop")}`, value: 1, selected: true },
                     { name: `2 ${this.$i18n.t("searchFiltersFlight_stop")}`, value: 2, selected: false },
                  ];
                  break;
               case "airlines":
                  self.handlerAirlineList();
                  break;
            }
         },
         immediate: true,
      },
      "$store.state.app.airlineList": {
         handler: function () {
            const self = this;
            if (self.selectIdentifier == "airlines") {
               self.handlerAirlineList();
            }
         },
      },
   },
};
</script>

<style lang="scss" scoped>
.flight-form-wrapper {
   .flightform-inner-wrapper {
      .inner-container {
         display: flex;
         width: 100%;
         justify-content: space-between;
         align-items: center;
         cursor: pointer;

         input {
            background: transparent;
            border: 0;
            outline: 0;
            position: absolute;
            opacity: 0;
            cursor: pointer;
            pointer-events: none;
         }
      }
      .gts-dropdown {
         position: absolute;
         left: 0;
         right: 0;
         padding: 0;
         border-radius: 8px;
         background-clip: padding-box;
         background-color: #fff;
         top: 100%;
         box-shadow: rgba(0, 0, 0, 0.2) 0px 10px 30px 0px;
         width: auto;
         overflow: hidden;
         z-index: 5;
         top: 50px;
         min-width: 100%;
         @media (min-width: 992px) {
            width: fit-content;
         }

         ul {
            max-height: 350px;
            width: auto;
            overflow-y: auto;
            li {
               padding: 0.25rem 0.75rem;
               position: relative;
               font-weight: 500;
               font-size: 1rem;
               margin: 0.25rem 0;
               padding-right: 2.5rem;
               cursor: pointer;
               border-bottom: 1px solid #eee;

               &.selected:after {
                  content: "*";
                  text-indent: -999em;
                  position: absolute;
                  top: 0;
                  bottom: 0;
                  margin: auto;
                  right: 0.75rem;
                  width: 14px;
                  height: 14px;
                  background-color: var(--primary);
                  border-radius: 50%;
               }

               &:hover {
                  background-color: rgba(13, 12, 34, 0.05);
               }
            }
         }

         .controls-container {
            margin: 0.5rem 1rem;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid #ccc;
            border-radius: 24rem;
            box-sizing: border-box;
            overflow: hidden;
            padding: 0.25rem;

            input {
               width: 100%;
               outline: none;
               border: none;
            }

            button {
               border-radius: 24rem;
               font-weight: 500;
               padding: 0.25rem 1rem;
               border: none;
               width: 200px !important;

               &.selectall {
                  background-color: var(--primary);
                  color: #fff;
               }
               &.deselectall {
                  background-color: var(--accent-gray);
               }
            }
            i {
               flex-shrink: 0;
               cursor: pointer;
               margin-right: 0.5rem;
            }
         }
      }
   }
}
</style>
