<template>
   <div class="ancillary-dropdown-c" :class="[dropdownStatus ? 'dropdown-wide' : '', selected ? 'selected' : '']" v-click-outside="handlerClickOutside">
      <div class="ancillary-dropdown-cta-wrapper" :class="identifier">
         <div class="ancillary-dropdown-selection-box">
            <span v-if="selected == null" class="txt-placeholder" @click.self="handlerDropdown">{{ $t(`ancillariesServices_select${identifier}`) }} </span>
            <span v-else>{{ selectedItemName }} </span>
            <i class="ancillary-dropdown-seclection-icon" :class="selected == null ? 'box-arrow' : 'box-close'" @click.self="selected == null ? handlerDropdown() : handlerDeleteSelected()"></i>
         </div>
         <template v-if="dropdownStatus">
            <div class="ancillary-dropdown-items">
               <div class="ancillary-dropdown-item-wrapper" v-for="(item, index) in items" :key="index" @click="handlerSelectItem(item)" :class="selected == item.name ? 'selected' : ''">
                  <div class="information-wrapper">
                     <div class="item-name">
                        <span>{{ item.name }}</span>
                        <span>{{ item.price | price(item.currency) }}</span>
                     </div>
                     <div class="item-description">{{ item.description }}</div>
                  </div>
               </div>
            </div>
         </template>
      </div>
   </div>
</template>

<script>
export default {
   name: "AncillaryDropdown",
   props: {
      identifier: String,
      items: Array,
   },
   data() {
      return {
         selected: null,
         selectedItemName: null,
         dropdownStatus: false,
      };
   },
   created() {
      this.items.forEach((item) => {
         if (item.selected) {
            this.selected = item.value;
            this.selectedItemName = item.name;
         }
      });
   },
   methods: {
      handlerClickOutside() {
         this.dropdownStatus = false;
      },
      handlerDeleteSelected() {
         this.items.forEach((item) => {
            item.selected = false;
         });

         this.$emit("unselect", this.selected);
         this.selected = null;
         this.selectedItemName = null;
      },
      handlerDropdown() {
         if (this.items.length == 0) {
            return;
         }
         this.dropdownStatus = !this.dropdownStatus;
      },
      handlerSelectItem(item) {
         item.selected = !item.selected;
         this.selected = item.value;
         this.selectedItemName = item.name;
         this.$emit("selected", item.value);
         this.dropdownStatus = false;
      },
   },
};
</script>

<style lang="scss" scoped>
.ancillary-dropdown-c {
   position: relative;
   padding: 0.5rem;
   border: 1px solid #b2bac7;
   border-radius: 0.5rem;
   background-color: #fff;
   width: fit-content;
   min-width: 190px;

   &.dropdown-wide {
      width: 100%;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
   }

   &.selected {
      // border-color: var(--accent-primary);
      // background-color: #f2fff4;
   }

   .ancillary-dropdown-cta-wrapper {
      .ancillary-dropdown-items {
         position: absolute;
         left: 0;
         right: 0;
         top: 100%;
         z-index: 999;
         background-color: #fff;
         outline: 1px solid #b2bac7;
         box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
         border-bottom-right-radius: 0.5rem;
         border-bottom-left-radius: 0.5rem;
         .ancillary-dropdown-item-wrapper {
            display: flex;
            padding: 0.5rem 1rem;
            border-bottom: 1.5px solid #ccc;

            &:last-child {
               border-bottom: none;
            }
            .information-wrapper {
               width: 100%;
            }
            .item-name {
               width: 100%;
               font-weight: 500;
               display: flex;
               justify-content: space-between;
               align-items: center;

               i {
                  width: 16px;
                  height: 16px;
                  border-radius: 100%;
                  margin: 0;
               }
            }
            .item-description {
               font-size: 0.8rem;
            }

            i {
               width: 35px;
               height: 50px;
               margin-right: 1rem;
               display: flex;
               flex-shrink: 0;
               background-repeat: no-repeat;
               &.icon-wheelchair {
                  background-image: url("data:image/svg+xml;base64,..."); // SVG code here
               }
            }

            &:hover {
               background-color: #eee;
               cursor: pointer;
            }

            &.selected {
               color: var(--primary);
               border-left: 5px solid var(--primary);
               .item-name {
                  i {
                     background: red;
                  }
               }
            }
         }
      }

      .ancillary-dropdown-selection-box {
         display: flex;
         justify-content: space-between;
         align-items: center;
         cursor: pointer;

         span {
            font-weight: 500;
            color: #3c4450;

            &.txt-placeholder {
               font-weight: 400;
               opacity: 0.65;
            }
         }
         .ancillary-dropdown-seclection-icon {
            margin-left: 1rem;
            width: 24px;
            height: 24px;
            display: block;
            background-position: center;
            background-repeat: no-repeat;

            &.box-arrow {
               background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjUiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNSAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE1LjYyNSAyTDkuMzc1MDIgMkM0LjE2NjY5IDIgMi4wODMzNiA0IDIuMDgzMzYgOUwyLjA4MzM2IDE1QzIuMDgzMzUgMjAgNC4xNjY2OSAyMiA5LjM3NTAyIDIyTDE1LjYyNSAyMkMyMC44MzM0IDIyIDIyLjkxNjcgMjAgMjIuOTE2NyAxNUwyMi45MTY3IDlDMjIuOTE2NyA0IDIwLjgzMzQgMiAxNS42MjUgMloiIHN0cm9rZT0iIzNDNDQ1MCIgc3Ryb2tlLXdpZHRoPSIxLjUiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8cGF0aCBkPSJNMTYuMTc3MSAxMC41NDAxTDEyLjUgMTQuMDYwMUw4LjgyMjkgMTAuNTQwMSIgc3Ryb2tlPSIjM0M0NDUwIiBzdHJva2Utd2lkdGg9IjEuNSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPgo=");
            }

            &.box-close {
               background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjUiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNSAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE1LjQ0NzkgOS4xNjk5Nkw5LjU1MjEgMTQuODMiIHN0cm9rZT0iIzNDNDQ1MCIgc3Ryb2tlLXdpZHRoPSIxLjUiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8cGF0aCBkPSJNOS41NTIxIDkuMTY5OTZMMTUuNDQ3OSAxNC44MyIgc3Ryb2tlPSIjM0M0NDUwIiBzdHJva2Utd2lkdGg9IjEuNSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+CjxwYXRoIGQ9Ik0xNS42MjUgMkw5LjM3NTAyIDJDNC4xNjY2OSAyIDIuMDgzMzYgNCAyLjA4MzM2IDlMMi4wODMzNiAxNUMyLjA4MzM1IDIwIDQuMTY2NjkgMjIgOS4zNzUwMiAyMkwxNS42MjUgMjJDMjAuODMzNCAyMiAyMi45MTY3IDIwIDIyLjkxNjcgMTVMMjIuOTE2NyA5QzIyLjkxNjcgNCAyMC44MzM0IDIgMTUuNjI1IDJaIiBzdHJva2U9IiMyMzI3MkUiIHN0cm9rZS13aWR0aD0iMS41IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9zdmc+Cg==");
            }
         }
      }
   }
}
</style>
