<template>
   <div class="ui-badge-container" :class="{ warning, error }">
      <i class="gts-icon icon-status-info"></i>
      <span>{{ msg }}</span>
   </div>
</template>

<script>
export default {
   name: "UIBadge2",
   props: {
      msg: String,
      warning: Boolean,
      error: Boolean,
   },
};
</script>

<style lang="scss" scoped>
@keyframes badge {
   0% {
      opacity: 1;
   }
   50% {
      opacity: 1;
   }
   100% {
      opacity: 0;
   }
}
.ui-badge-container {
   border-radius: 0.5rem;
   border-style: solid;
   border-width: 1px;
   padding: 1rem 1.125rem;
   display: flex;
   align-items: center;
   animation-timing-function: ease-in-out;
   animation-name: badge;
   animation-duration: 0.5s;
   animation-iteration-count: 3;

   i {
      $infoIcon: 38px !important;
      width: $infoIcon;
      height: $infoIcon;
      filter: brightness(0.25);
      margin-right: 0.75rem;
   }

   span {
      font-weight: 500;
      font-size: var(--large);
      color: #0f2d40;
   }

   &.warning {
      background-color: #fff7d3;
      border-color: #ecc813;
   }
   &.error {
      background-color: #fbdddd;
      border-color: #ff4c4c;
   }
}
</style>
