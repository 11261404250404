<template>
   <div class="filter-section-container">
      <div class="filter-section-title-wrapper">
         <span class="filter-section-title">{{ $t("hotelFilter_lblBoardType") }}</span>
         <span class="filter-section-toggle" @click="handlerClearAll">{{ $t("searchFiltersHotel_deselectAll") }}</span>
      </div>

      <template v-for="(boardtype, key, index) in sortedBoardTypes">
         <div class="filter-item" :key="index" v-if="parseInt(index) <= maxLength">
            <UICheckBox v-model="boardtype.checked" :id="boardtype.id" :label="setLabelText(boardtype)" hotel @input="handlerCheckClick" />
            <span class="filter-count" :data-boardtype-count="boardtype.id">{{ boardtype.count }}</span>
         </div>
      </template>

      <button class="show-more-filter" @click="handlerExpandFilter">
         {{ maxLength == 8 ? $t("hotelFilter_btnShowLess") : $t("hotelFilter_btnShowMore") }}
      </button>
   </div>
</template>

<script>
import UICheckBox from "@/components/unitary/form2/UICheckBox.vue";
export default {
   name: "FilterFacilities",
   props: {
      boardTypes: {
         type: Object,
         required: true,
      },
   },
   data() {
      return {
         maxLength: 5,
         activeLang: null,
      };
   },
   components: {
      UICheckBox,
   },
   methods: {
      handlerCheckClick() {
         this.$emit("click", true);
      },
      handlerClearAll() {
         Object.keys(this.boardTypes).forEach((boardtype) => {
            this.boardTypes[boardtype].checked = false;
         });
         this.handlerCheckClick();
      },
      handlerExpandFilter() {
         if (this.maxLength == 5) {
            this.maxLength = 8;
         } else {
            this.maxLength = 5;
         }
      },
      setLabelText(boardtype) {
         return this.activeLang == "en" ? boardtype.nameEn : this.activeLang == "tr" ? boardtype.nameTr : boardtype.nameDe;
      },
   },
   watch: {
      "$i18n.locale": {
         handler: function (locale) {
            this.activeLang = locale;
         },
         immediate: true,
      },
   },
   computed: {
      sortedBoardTypes() {
         let sortedBoardTypes = Object.values(this.boardTypes).sort((a, b) => a.priority - b.priority);
         let sortedObj = {};
         sortedBoardTypes.forEach((boardtype, i) => {
            sortedObj[i + 1] = boardtype;
         });
         return sortedObj;
      },
   },
};
</script>

<style lang="scss" scoped>
.show-more-filter {
   border: 1px solid #ccc;
   font-size: var(--small);
   background-color: #eee !important;
   border-radius: 0.25rem;
   display: flex;
   align-items: center;
   display: block;
   width: 100%;
   padding: 0.25rem 0;
   font-weight: 500;
   cursor: pointer;
}
</style>
