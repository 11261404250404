<template>
   <div id="service-center-container">
      <b-tabs type="is-boxed" :animated="false" v-model="tab">
         <!-- REQUEST SECTION -->
         <b-tab-item>
            <template #header>
               <i class="gts-icon icon-service-center-request" v-if="false"></i>
               {{ $t("serviceCenterComponent_tabServiceCenterRequestForm_label") }}
            </template>
            <ServiceCenterRequestForm :order-id="orderId" />
         </b-tab-item>

         <!-- MY NOTES SECTION -->
         <b-tab-item v-if="showTask">
            <template #header>
               <i class="gts-icon icon-task-chat" v-if="false"></i>
               {{ $t("serviceCenterComponent_tabTaskChat_label") }}
            </template>
            <TaskChat :order-id="orderId" @show-task="handlerHideTask" />
         </b-tab-item>

         <!-- CHAT SECTION -->
         <b-tab-item>
            <template #header>
               <i class="gts-icon icon-task-note" v-if="false"></i>
               {{ $t("serviceCenterComponent_tabMyNotes_label") }}
            </template>
            <Notes :order-id="orderId" />
         </b-tab-item>
      </b-tabs>
   </div>
</template>

<script>
import ServiceCenterRequestForm from "@/components/unitary/order/ServiceCenter/RequestForm.vue";
import TaskChat from "@/components/unitary/order/ServiceCenter/TaskChat.vue";
import Notes from "@/components/unitary/order/ServiceCenter/Notes.vue";
export default {
   name: "ServiceCenter",
   props: {
      orderId: [Number, String],
   },
   components: {
      ServiceCenterRequestForm,
      TaskChat,
      Notes,
   },
   data() {
      return {
         tab: 0,
         showTask: true,
      };
   },

   methods: {
      handlerHideTask(showtask) {
         const self = this;
         self.showTask = showtask;
      },
   },
   watch: {
      showTask: {
         handler: function (status) {
            if (status) {
               this.tab = 1;
            } else {
               this.tab = 0;
            }
         },
         immediate: true,
      },
   },
};
</script>

<style lang="scss" scoped>
#service-center-container {
   margin: 2rem 0;
}
</style>

<style lang="scss">
#service-center-container > .b-tabs > .tabs > ul > li > a {
   font-weight: 500;
   color: var(--txt-ghost);
   background-color: #eff3f7 !important;
}

#service-center-container > .b-tabs > .tab-content {
   padding: 0 !important;
}

#service-center-container > .b-tabs > .tabs > ul > li.is-active > a {
   color: var(--txt-black) !important;
   background-color: var(--txt-black) !important;
   background-color: #fff !important;
   font-size: var(--large);
}
</style>
