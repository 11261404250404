import { render, staticRenderFns } from "./CheckoutPnrInfoBadge.vue?vue&type=template&id=3d1686ce&scoped=true&"
import script from "./CheckoutPnrInfoBadge.vue?vue&type=script&lang=js&"
export * from "./CheckoutPnrInfoBadge.vue?vue&type=script&lang=js&"
import style0 from "./CheckoutPnrInfoBadge.vue?vue&type=style&index=0&id=3d1686ce&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3d1686ce",
  null
  
)

export default component.exports