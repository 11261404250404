<template>
   <div class="filter-section-container">
      <div class="filter-section-title-wrapper">
         <span class="filter-section-title">{{ $t("hotelFilter_lblPriceTitle") }}</span>
         <span class="filter-section-toggle" @click="handlerClearAll">{{ $t("searchFiltersHotel_deselectAll") }}</span>
      </div>

      <!-- Price Check Boxes -->
      <template v-for="(price, key, index) in prices">
         <div class="filter-item" :key="index">
            <UICheckBox v-model="price.checked" :id="price.id" :label="price.name + ' ' + filterCurrency" hotel @input="handlerCheckClick" />
            <span class="filter-count">{{ price.count }}</span>
         </div>
      </template>

      <!-- Price Range Slider Section -->
      <div class="price-range-container">
         <!-- Price Range Title & Infos -->
         <div class="price-range-title-wrapper">
            <span class="price-range-title">{{ $t("searchFiltersHotel_lblCustomPriceRange") }}</span>
            <span class="filter-section-title" @click="handlerClearAll">{{ $t("searchFiltersHotel_deselectAll") }}</span>
         </div>

         <div class="price-range-inputs-wrapper">
            <div class="input-wrapper">
               <label for="price-min">Min </label>
               <input type="number" id="price-min" step="50" v-model="MinPrice" />
            </div>
            <div class="input-wrapper">
               <label for="price-min">Max</label>
               <input type="number" id="price-max" step="50" min="100" v-model="MaxPrice" />
            </div>
         </div>

         <div class="price-range-slider-wrapper">
            <b-field>
               <b-slider v-model="Slider" :min="0" :max="priceSlider.pricerange.maxPrice" :step="50" lazy @input="handlerPriceSlider"> </b-slider>
            </b-field>
         </div>
      </div>
   </div>
</template>

<script>
import UICheckBox from "@/components/unitary/form2/UICheckBox.vue";
import debounce from "lodash.debounce";
export default {
   Name: "PriceFilter",
   components: {
      UICheckBox,
   },
   props: {
      prices: {
         type: Object,
         required: true,
      },
      priceRange: Object,
      priceSlider: Object,
   },
   data() {
      return {
         install: 0,
         price: {
            minPrice: Number,
            maxPrice: Number,
         },
         slider: [],
      };
   },
   computed: {
      filterCurrency() {
         return this.$store.state.hotel.filterCurrency;
      },
      MinPrice: {
         get() {
            return this.price.minPrice;
         },
         set(val) {
            this.price.minPrice = Number(val);
         },
      },
      MaxPrice: {
         get() {
            return this.price.maxPrice;
         },
         set(val) {
            this.price.maxPrice = Number(val);
         },
      },
      Slider: {
         get() {
            const minPrice = this.price.minPrice;
            const maxPrice = this.price.maxPrice;
            // const minPrice = this.priceSlider.filterrequest.priceFrom != 0 ? this.priceSlider.filterrequest.priceFrom : this.price.minPrice;
            // const maxPrice = this.priceSlider.filterrequest.priceTo != 0 ? this.priceSlider.filterrequest.priceTo : this.price.maxPrice;
            return [minPrice, maxPrice];
         },
         set(slider) {
            // console.log(slider);
            // this.priceSlider.filterrequest.priceFrom = slider[0];
            // this.priceSlider.filterrequest.priceTo = slider[1];
            this.price.minPrice = slider[0];
            this.price.maxPrice = slider[1];
         },
      },
   },
   methods: {
      handlerCheckClick() {
         this.$emit("click", true);
      },
      handlerClearAll() {
         Object.keys(this.prices).forEach((price) => {
            this.prices[price].checked = false;
         });
         this.price.minPrice = 0;
         this.price.maxPrice = this.highestPrice;
         this.install = 0;
         this.$emit("clear", true);
      },
      handlerPriceSlider: debounce(function () {
         // this.price.minPrice = this.slider[0];
         // this.price.maxPrice = this.slider[1];
         if (this.install > 0) {
            this.$parent.$parent.$parent.handlerPriceGeneratorSlider(this.Slider[0], this.Slider[1]);
         }

         this.install++;
      }, 1500),
   },
   watch: {
      price: {
         handler: function (val) {
            if (val.maxPrice <= val.minPrice) {
               this.price.minPrice = 0;
            }
         },
         deep: true,
      },
   },
};
</script>

<style lang="scss" scoped>
.filter-section-container {
   .price-range-container {
      display: flex;
      flex-direction: column;
      margin-top: 1.5rem;

      .price-range-title-wrapper {
         display: flex;
         justify-content: space-between;

         .price-range-title {
            font-weight: 500;
            opacity: 0.5;
            margin-bottom: 0.5rem;
         }
         .filter-section-title {
            font-size: var(--small);
            font-weight: 500;
            color: var(--accent-primary);
            cursor: pointer;
         }
      }

      .price-range-slider-wrapper {
         padding: 0 0.5rem;
         margin-top: 0.5rem;
      }

      .price-range-price-info {
         font-weight: 600;
         font-size: 1.125rem;
      }

      .price-range-inputs-wrapper {
         display: flex;
         justify-content: space-between;
         .input-wrapper {
            display: flex;
            flex-direction: column;
            label {
               font-size: 0.85rem;
               font-weight: 600;
               color: #363636;
            }
            input {
               width: 80px;
               border-radius: 0.25rem;
               border: 1px solid #ccc;
               text-indent: 0.5rem;
               font-weight: 600;
            }
         }
      }
   }
}
</style>
<style lang="scss">
.filter-section-container {
   .b-slider {
      .b-slider-track {
         height: 0.25rem;
         background-color: #e6e6e6;

         .b-slider-fill {
            background: var(--secondary) !important;
            border: 3px solid var(--secondary) !important;
         }

         .tooltip-trigger {
            .b-slider-thumb {
               border-radius: 50%;
               border: none !important;
               background-color: var(--secondary);
            }
         }
         .tooltip-content {
            background-color: #45494d;
         }
      }
   }
}
</style>
