var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"route-flight-number"},[_c('UIDatePicker',{directives:[{name:"input",rawName:"v-input",value:({
         type: 'date',
         required: true,
         requiredMsg: _vm.$t('common_cannotBeEmpty'),
      }),expression:"{\n         type: 'date',\n         required: true,\n         requiredMsg: $t('common_cannotBeEmpty'),\n      }"}],attrs:{"index":_vm.legIndex,"label":_vm.$t('orderDetail_transferDate'),"isDisabled":!_vm.editMode,"min":_vm.minDate,"isUpdateMode":""},on:{"input":_vm.handlerTransferDate},model:{value:(_vm.leg.pickupDateTime),callback:function ($$v) {_vm.$set(_vm.leg, "pickupDateTime", $$v)},expression:"leg.pickupDateTime"}}),(_vm.leg.fromLocationType == 9 || _vm.leg.toLocationType == 9)?_c('div',[(_vm.leg.fromLocationType != 9 && _vm.leg.toLocationType == 9)?[_c('div',{staticClass:"time-picker-inputs"},[_c('UIInput',{directives:[{name:"input",rawName:"v-input",value:({
                  type: 'alphanumeric',
                  len: [4, 9],
                  required: true,
                  requiredMsg: _vm.$t('common_cannotBeEmpty'),
               }),expression:"{\n                  type: 'alphanumeric',\n                  len: [4, 9],\n                  required: true,\n                  requiredMsg: $t('common_cannotBeEmpty'),\n               }"}],attrs:{"inputModel":"departure","index":_vm.legIndex,"label":_vm.setInputLabels(_vm.leg.toLocationType, _vm.leg.fromLocationType),"placeholder":'TK1234',"disabled":!_vm.editMode,"updateMode":""},on:{"update-mode":_vm.handlerFlightNumberInput},model:{value:(_vm.leg.departureFlightNumber),callback:function ($$v) {_vm.$set(_vm.leg, "departureFlightNumber", $$v)},expression:"leg.departureFlightNumber"}}),_c('TimePicker',{attrs:{"label":`${_vm.$t('TransferCheckout_lblDeparture')}`,"type":`departure-${_vm.legIndex}`,"disabled":!_vm.editMode,"pickupDateTime":_vm.leg.departureFlightDateTime,"isUpdate":""},on:{"pickup":_vm.handlerPickUpDate}})],1)]:(_vm.leg.fromLocationType == 9 && _vm.leg.toLocationType != 9)?[_c('div',{staticClass:"time-picker-inputs"},[_c('UIInput',{directives:[{name:"input",rawName:"v-input",value:({
                  type: 'alphanumeric',
                  len: [4, 9],
                  required: true,
                  requiredMsg: _vm.$t('common_cannotBeEmpty'),
               }),expression:"{\n                  type: 'alphanumeric',\n                  len: [4, 9],\n                  required: true,\n                  requiredMsg: $t('common_cannotBeEmpty'),\n               }"}],attrs:{"inputModel":"arrival","index":_vm.legIndex,"updateMode":"","label":_vm.setInputLabels(_vm.leg.toLocationType, _vm.leg.fromLocationType),"placeholder":'TK1234',"disabled":!_vm.editMode},on:{"update-mode":_vm.handlerFlightNumberInput},model:{value:(_vm.leg.arrivalFlightNumber),callback:function ($$v) {_vm.$set(_vm.leg, "arrivalFlightNumber", $$v)},expression:"leg.arrivalFlightNumber"}}),_c('TimePicker',{attrs:{"label":`${_vm.$t('TransferCheckout_lblArrival')}`,"type":`arrival-${_vm.legIndex}`,"disabled":!_vm.editMode,"isUpdate":"","pickupDateTime":_vm.leg.arrivalFlightDateTime},on:{"pickup":_vm.handlerPickUpDate}})],1)]:[_c('div',{staticClass:"time-picker-inputs"},[_c('UIInput',{directives:[{name:"input",rawName:"v-input",value:({
                  type: 'alphanumeric',
                  len: [4, 9],
                  required: true,
                  requiredMsg: _vm.$t('common_cannotBeEmpty'),
               }),expression:"{\n                  type: 'alphanumeric',\n                  len: [4, 9],\n                  required: true,\n                  requiredMsg: $t('common_cannotBeEmpty'),\n               }"}],attrs:{"id":"arrivalFlightNumber","inputModel":"arrival","index":_vm.legIndex,"updateMode":"","label":`${_vm.$t('common_arrivalFlightNumber')}`,"placeholder":'TK1234',"disabled":!_vm.editMode},on:{"update-mode":_vm.handlerFlightNumberInput},model:{value:(_vm.leg.arrivalFlightNumber),callback:function ($$v) {_vm.$set(_vm.leg, "arrivalFlightNumber", $$v)},expression:"leg.arrivalFlightNumber"}}),_c('div',{staticClass:"time-picker-margin"},[_c('TimePicker',{attrs:{"label":`${_vm.$t('TransferCheckout_lblArrival')}`,"type":`arrival-${_vm.legIndex}`,"disabled":!_vm.editMode,"isUpdate":"","pickupDateTime":_vm.leg.arrivalFlightDateTime},on:{"pickup":_vm.handlerPickUpDate}})],1),_c('UIInput',{directives:[{name:"input",rawName:"v-input",value:({
                  type: 'alphanumeric',
                  len: [4, 9],
                  required: true,
                  requiredMsg: _vm.$t('common_cannotBeEmpty'),
               }),expression:"{\n                  type: 'alphanumeric',\n                  len: [4, 9],\n                  required: true,\n                  requiredMsg: $t('common_cannotBeEmpty'),\n               }"}],attrs:{"id":"departureFlightNumber","inputModel":"departure","index":_vm.legIndex,"updateMode":"","label":`${_vm.$t('common_departureFlightNumber')}`,"placeholder":'TK1234',"disabled":!_vm.editMode},on:{"update-mode":_vm.handlerFlightNumberInput},model:{value:(_vm.leg.departureFlightNumber),callback:function ($$v) {_vm.$set(_vm.leg, "departureFlightNumber", $$v)},expression:"leg.departureFlightNumber"}}),_c('TimePicker',{attrs:{"label":`${_vm.$t('TransferCheckout_lblDeparture')}`,"type":`departure-${_vm.legIndex}`,"disabled":!_vm.editMode,"isUpdate":"","pickupDateTime":_vm.leg.departureFlightDateTime},on:{"pickup":_vm.handlerPickUpDate}})],1)]],2):_vm._e(),(_vm.leg.fromLocationType != 9 && _vm.leg.toLocationType != 9)?[_c('TimePicker',{attrs:{"label":_vm.setTimePickerLabel(_vm.leg.toLocationType, _vm.leg.fromLocationType),"pickupDateTime":_vm.leg.pickupDateTime,"disabled":!_vm.editMode,"isUpdate":"","type":`pickup-${_vm.legIndex}`},on:{"pickup":_vm.handlerPickUpDate}})]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }