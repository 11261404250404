export const ancillaryConversion = (self, ancillaries) => {
   const ancillariesArr = [];

   Object.keys(ancillaries).map((ancillary) => {
      const rowObj = {
         name: self.$options.filters.ERacAncillaryType(ancillaries[ancillary].ancillaryType),
         key: ancillary,
         type: ancillaries[ancillary].ancillaryType,
         count: ancillaries[ancillary].ancillaryCount,
         icon: self.$options.filters.ERacAncillaryTypeIcon(ancillaries[ancillary].ancillaryType),
         totalPrice: self.$options.filters.price(ancillaries[ancillary].totalPrice),
         pricePerDay: self.$options.filters.price(ancillaries[ancillary].pricePerDay),
         tooltip: self.$i18n.t(`renatacar_tooltip${ancillary}`),
      };
      ancillariesArr.push(rowObj);
   });

   return ancillariesArr;
};

export const ancillaryConversionOrderDetail = (self, ancillaries) => {
   const ancillariesArr = [];

   Object.keys(ancillaries).map((ancillary) => {
      const rowObj = {
         name: self.$options.filters.ERacAncillaryType(ancillaries[ancillary].type),
         key: ancillary,
         type: ancillaries[ancillary].type,
         count: ancillaries[ancillary].count,
         icon: self.$options.filters.ERacAncillaryTypeIcon(ancillaries[ancillary].type),
         totalPrice: self.$options.filters.price(ancillaries[ancillary].totalPrice),
         pricePerDay: self.$options.filters.price(ancillaries[ancillary].pricePerDay),
      };
      ancillariesArr.push(rowObj);
   });

   return ancillariesArr;
};

export const ancillaryRequestModelGenerator = (self, arr) => {
   const ancillaries = {};
   arr.forEach((ancillary) => {
      if (ancillary.count > 0) {
         ancillaries[ancillary.key] = ancillary.count;
      }
   });

   return ancillaries;
};

///DeleteBelow
export const ancillaryType = function (type) {
   return type == 1 ? true : false;
};

export const ancillaryGenerator = function (self, ancillaries) {
   var ancillaryArr = [];
   Object.keys(ancillaries).map((ancillary) => {
      const rowObj = {
         ancillaryName: self.$options.filters.ERacAncillaryType(ancillaries[ancillary].ancillaryType),
         ancillaryType: ancillaries[ancillary].ancillaryType,
         icon: self.$options.filters.ERacAncillaryTypeIcon(ancillaries[ancillary].ancillaryType),
         totalPrice: self.$options.filters.price(ancillaries[ancillary].totalPrice),
         pricePerDay: self.$options.filters.price(ancillaries[ancillary].pricePerDay),
         selected: false,
      };
      if (ancillaryType(ancillaries[ancillary].ancillaryType)) {
         rowObj.augmentable = true;
         rowObj.count = ancillary;
      }
      ancillaryArr.push(rowObj);
   });

   return ancillaryArr;
};

export const ancillaryRequestModelGenerator2 = (self, arr) => {
   const ancillaries = {};

   arr.forEach((ancillary) => {
      if (ancillary.augmentable) {
         if (ancillary.count > 0) {
            const ancillaryPropName = self.$options.filters.ERacAncillaryEnums(ancillary.ancillaryType);
            ancillaries[ancillaryPropName] = ancillary.count;
         }
      } else if (ancillary.selected) {
         const ancillaryPropName = self.$options.filters.ERacAncillaryEnums(ancillary.ancillaryType);
         ancillaries[ancillaryPropName] = 1;
      }
   });

   return ancillaries;
};
