import Vue from "vue";
import Vuex from "vuex";

import { app } from "@/store/app.module";
import { hotel } from "@/store/hotel.module";
import { flight } from "@/store/flight.module";
import { seatmap } from "@/store/seatmap.module";
import { transfer } from "@/store/transfer.module";
import { rentacar } from "@/store/rentacar.module";
import { user } from "@/store/user.module";
import { customer } from "@/store/customer.module";
import { dashboard } from "@/store/dashboard.module";
import { finance } from "@/store/finance.module";
import { order } from "@/store/order.module";
import { direction } from "@/store/direction.module";
Vue.use(Vuex);

const store = new Vuex.Store({
   modules: {
      app,
      hotel,
      flight,
      seatmap,
      transfer,
      rentacar,
      user,
      customer,
      dashboard,
      finance,
      order,
      direction,
   },
});

export default store;
