<template>
   <section class="order-summary-header-wrapper gts-badge-border masked-border-2" :class="'EFltPnrStatus-' + orderSummary.status">
      <div
         id="order-status-badge"
         class="gts-badge-bg masked-background-dark masked-borderless"
         :class="'EFltPnrStatus-' + orderSummary.status"></div>

      <div class="order-summary-header-inner">
         <div class="order-summary-header-section">
            <div class="section-inner">
               <div class="section-item">
                  <span class="section-item-label">{{ $t("orderDetailPage_orderSummaryHeader_orderIdLabel") }}</span>
                  <span class="section-item-text masked">{{ orderSummary.orderId }}</span>
               </div>
               <div class="section-item">
                  <span class="section-item-label">{{ $t("orderDetailPage_orderSummaryHeader_statusLabel") }}</span>
                  <span class="section-item-text masked">{{ orderSummary.status | enumOrderStatus }} </span>
               </div>
            </div>
         </div>
         <div class="order-summary-header-section masked-background">
            <div class="section-inner">
               <!-- AGENCY ID -->
               <div class="section-item">
                  <span class="section-item-label">{{ $t("orderDetailPage_orderSummaryHeader_agencyIdLabel") }}</span>
                  <span class="section-item-text masked">{{ orderSummary.agency.agencyId }}</span>
               </div>

               <!-- USER EMAIL -->
               <div class="section-item">
                  <span class="section-item-label">{{ $t("orderDetailPage_orderSummaryHeader_userEmailLabel") }}</span>
                  <template v-for="(contact, index) in orderSummary.users">
                     <span class="section-item-text masked" :key="index">
                        {{ contact.email }}
                     </span>
                  </template>
               </div>
               <!-- <div class="section-item">
                  <span class="section-item-label">{{ $t("orderDetailPage_orderSummaryHeader_passengerEmailLabel") }}</span>
                  <span class="section-item-text masked">{{ orderSummary.passengerEmail }}</span>
               </div> -->
               <div class="section-item">
                  <span class="section-item-label">{{ $t("orderDetailPage_orderSummaryHeader_contactEmailLabel") }}</span>
                  <template v-for="(contact, index) in orderSummary.contacts">
                     <span class="section-item-text masked" :key="index">
                        {{ contact.email }}
                     </span>
                  </template>
               </div>
               <div class="section-item">
                  <span class="section-item-label">{{ $t("orderDetailPage_orderSummaryHeader_agencyNameLabel") }}</span>
                  <span class="section-item-text masked">{{ orderSummary.agency.agencyName }}</span>
               </div>
               <div class="section-item">
                  <span class="section-item-label">{{ $t("orderDetailPage_orderSummaryHeader_userPhoneLabel") }}</span>
                  <!-- <span class="section-item-text masked">{{ orderSummary.agency.agencyPhone }}</span> -->
                  <template v-for="(user, index) in orderSummary.users">
                     <span class="section-item-text masked" :key="index">
                        {{ $options.filters.phone(user.phone) }}
                     </span>
                  </template>
               </div>
               <!-- <div class="section-item">
                  <span class="section-item-label">{{ $t("orderDetailPage_orderSummaryHeader_passengerPhoneLabel") }}</span>
                  <span class="section-item-text masked">{{ orderSummary.passengerPhone }}</span>
               </div> -->
               <div class="section-item">
                  <span class="section-item-label">{{ $t("orderDetailPage_orderSummaryHeader_contactPhoneLabel") }}</span>
                  <template v-for="(contact, index) in orderSummary.contacts">
                     <span class="section-item-text masked" :key="index">
                        {{ $options.filters.phone(contact.phone) }}
                     </span>
                  </template>
               </div>
            </div>
         </div>
      </div>
   </section>
</template>

<script>
export default {
   name: "OrderSummaryHeader",
   props: {
      orderSummaryObj: { type: Object },
   },
   data() {
      return {
         orderSummary: {
            agencyId: "",
            agencyName: "",
            orderId: "",
            passengerEmail: "",
            passengerPhone: "",
            status: "",
            userEmail: "",
            userPhone: "",
         },
      };
   },
   created() {
      const self = this;
      self.orderSummary = this.orderSummaryObj;
   },
   watch: {
      orderSummaryObj: {
         handler: function (newOrderSummaryObj) {
            const self = this;
            self.orderSummary = newOrderSummaryObj;
         },
         deep: true,
      },
   },
};
</script>

<style lang="scss" scoped>
.order-summary-header-wrapper {
   width: 100%;
   position: relative;
   padding: 16px 28px !important;
   border: 2px solid #91f2ba;
   background-color: white;
   border-radius: 16px;

   @media (min-width: 576px) {
      padding: 20px 42px !important;
   }
   @media (min-width: 768px) {
      padding: 20px 1.5rem !important;
   }

   #order-status-badge {
      content: "*";
      text-indent: -999em;
      position: absolute;
      width: 8px;
      left: 0;
      top: 10%;
      bottom: 10%;
      border-top-right-radius: 12px;
      border-bottom-right-radius: 12px;
   }

   .order-summary-header-inner {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 100%;
      @media (min-width: 576px) {
         flex-direction: row;
      }
      .order-summary-header-section {
         position: relative;

         &:before,
         &:after {
            content: "*";
            position: absolute;
            width: 16px;
            height: 16px;
            border-radius: 50%;
            background: var(--c-mode-background);
            position: absolute;
            right: -6px;
            text-indent: -999em;
            display: none;

            @media (min-width: 576px) {
               display: block;
            }
         }

         &:before {
            top: -28px;
         }
         &:after {
            bottom: -28px;
         }

         &:first-child {
            width: 100%;
            margin-right: 2rem;
            background-position: right;
            background-size: 2px 1rem;
            background-repeat: repeat-y;
            background-image: none;

            @media (min-width: 576px) {
               width: 20%;
               background-image: linear-gradient(var(--c-mode-background) 33%, rgba(255, 255, 255, 0) 0%);
            }

            .section-inner {
               margin-top: 24px;
               .section-item:first-child {
                  margin-bottom: 1rem;
               }
            }
         }

         &:last-child {
            width: 100%;
            background-color: #edf1f5;
            border-radius: 16px;

            .section-inner {
               display: grid;
               grid-template-columns: repeat(2, 1fr);
               row-gap: 24px;
               padding: 16px 12px;

               @media (min-width: 576px) {
                  grid-template-columns: repeat(2, 1fr);
                  padding: 24px 42px;
               }
               @media (min-width: 768px) {
                  grid-template-columns: 1fr repeat(2, 1.5fr);
                  padding: 24px 1.5rem;
               }

               .section-item-text {
                  font-size: var(--normal) !important;

                  @media (min-width: 576px) {
                     font-size: var(--large) !important;
                  }
               }
            }
            &:before,
            &:after {
               display: none !important;
            }
         }
         .section-inner {
            display: flex;
            flex-direction: row;

            @media (min-width: 576px) {
               flex-direction: column;
            }
            .section-item {
               display: inline-flex;
               flex-direction: column;
               justify-content: flex-start;
               align-items: flex-start;
               width: 100%;

               @media (min-width: 576px) {
                  width: auto;
               }

               .section-item-label {
                  margin-bottom: 0.125rem;
                  font-size: 14px;
               }
               .section-item-text {
                  font-size: var(--xlarge);
                  font-weight: 700;
                  line-height: initial;
               }
            }
         }
      }
   }
}
</style>
