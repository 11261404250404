<template>
   <div>
      <OTPModal v-show="showDetailModal" :noBorder="true" :hasSlot="true" @close-modal="closeDetailModalFunc" :modalSize="'heighted'">
         <div class="Modal-header">
            <div class="first-phase" v-if="phase == 1">
               <div class="modal-content-wrapper">
                  <div class="otp-layer-first-phase">
                     <div id="start-screen-img" class="first-phase-img">
                        <img src="@/assets/images/Otp-guide-screen-1.png" />
                     </div>
                     <div class="first-phase-texts">
                        <span>{{ $t("tokenDirection_lblExtraSecurityLayer") }}</span>
                        <p>{{ $t("tokenDirection_lblExtraSecurityLayerInfo1") }}</p>
                        <p class="first-phase-active" v-html="phase1InfoText"></p>
                     </div>
                  </div>
               </div>
               <div class="button-wrapper">
                  <div>
                     <UIButton
                        className="default_outline"
                        :text="$t('tokenDirection_lblSkip')"
                        :notRounded="false"
                        @click.native="closeDetailModalFunc" />
                  </div>
                  <div>
                     <UIButton :className="'default'" :text="$t('tokenDirection_lblLetsGo')" :notRounded="false" @click.native="goNextPage()" />
                  </div>
               </div>
            </div>
            <div v-if="phase == 2 || phase == 3 || phase == 4 || phase == 5">
               <div class="second-phase" v-if="phase == 2">
                  <div class="modal-content-wrapper">
                     <div class="phase-header">
                        <span>{{ $t("tokenDirection_lblAppInstallation") }}</span>
                        <p class="guide-text">{{ $t("tokenDirection_lblAppInstallationGuideText") }}</p>
                        <p class="guide-text-or">
                           {{ $t("tokenDirection_lblAppInstallationGuideTextOrVisit").replace("#VALUE#", ``) }}
                           <a href="https://https://reisetech.io/agent-resitech-app?qr=google-authenticator" target="_blank"
                              >reisetech.io/agent-redirect-app</a
                           >
                        </p>
                     </div>
                     <div class="qr-screen">
                        <div class="qr"><img src="@/assets/images/qr-code.svg" alt="" /></div>
                        <div class="line"></div>
                        <div class="store-link">
                           <div class="app-store">
                              <i class="gts-icon icon-apple-icon"></i>
                              <div class="icon-texts">
                                 <span>{{ $t("tokenDirection_lblAppInstallationDownloadAppStore") }}</span>
                                 <p>App Store</p>
                              </div>
                           </div>
                           <div class="google-play">
                              <i class="gts-icon icon-PlayStore-icon"></i>
                              <div class="icon-texts">
                                 <span>{{ $t("tokenDirection_lblAppInstallationDownloadPlayStore") }}</span>
                                 <p>Google Play</p>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div class="qr-below-info">
                        <p>
                           {{ $t("tokenDirection_lblAppInstallationQrBelowInfo") }}
                        </p>
                     </div>
                  </div>
               </div>
               <div class="second-phase" v-if="phase == 3">
                  <div class="modal-content-wrapper">
                     <div class="phase-header">
                        <span> {{ $t("tokenDirection_lblGeneratingVerificationCode") }}</span>
                        <ul class="content-list">
                           <li>
                              <span>{{ $t("tokenDirection_lblGeneratingVerificationCodeText1") }}</span>
                           </li>
                           <li>
                              <span v-html="phase3Plus"></span>
                           </li>
                           <li>
                              <span>{{ $t("tokenDirection_lblGeneratingVerificationCodeText3") }}</span>
                           </li>
                        </ul>
                     </div>
                     <div class="phase3-screen">
                        <div class="guide-qr" v-html="tOtpQrHtml"></div>
                        <div class="guide-animation">
                           <div id="phase3-gif">
                              <img src="@/assets/images/Otp-guide-screen-3anim.gif" />
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <div class="second-phase" v-if="phase == 4">
                  <div class="modal-content-wrapper">
                     <div class="phase-header">
                        <span>{{ $t("tokenValidate_lblTitle") }}</span>
                        <!-- <span>{{ $t("tokenDirection_lblLoginWithToken") }}</span> -->
                        <ul class="content-list">
                           <li>
                              <span>{{ $t("tokenValidate_lblInfoText1") }}</span>
                           </li>
                           <li>
                              <span>{{ $t("tokenValidate_lblInfoText2") }}</span>
                           </li>
                        </ul>
                     </div>
                     <div class="phase4-token-field">
                        <UIDigitInput
                           :is-error="digitInputError"
                           :clearAllDigits="clearAllDigits"
                           @keyup.enter.native="login"
                           @update-digit-code="
                              (newDigitCode) => {
                                 this.OtpCode = newDigitCode;
                              }
                           " />
                     </div>
                  </div>
               </div>
               <div class="second-phase" v-if="phase == 5">
                  <div class="modal-content-wrapper">
                     <div class="phase-header">
                        <span>{{ $t("tokenDirection_lblLoginWithToken") }}</span>
                        <ul class="content-list">
                           <!-- <li>
                              <span>{{ $t("tokenDirection_lblLoginWithTokenText1") }}</span>
                           </li> -->
                           <li>
                              <span v-html="phase4InfoText"></span>
                           </li>
                        </ul>
                     </div>
                     <div class="token-field">
                        <div id="phase4-img">
                           <img src="@/assets/images/Otp-guide-screen-4.png" />
                        </div>
                     </div>
                  </div>
               </div>
               <div id="second-phase-footer">
                  <div class="progress">
                     <div class="progress-tick" :class="phase >= 2 ? '' : 'progress-tick-deactive-color'" @click="switchPages(2)"></div>
                     <div class="progress-tick" :class="phase >= 3 ? '' : 'progress-tick-deactive-color'" @click="switchPages(3)"></div>
                     <div class="progress-tick" :class="phase >= 4 ? '' : 'progress-tick-deactive-color'" @click="switchPages(4)"></div>
                     <div class="progress-tick" :class="phase == 5 ? '' : 'progress-tick-deactive-color'" @click="switchPages(5)"></div>
                  </div>
                  <div class="go-next" v-if="phase == 2">
                     <UIButton
                        style="border: 2px solid white; color: white"
                        className="otp_outline"
                        :text="$t('tokenDirection_lblAppInstallationOpenToken')"
                        :notRounded="false"
                        @click.native="goNextPage()" />
                  </div>
                  <div class="go-next" v-else>
                     <div v-if="this.phase == 4">
                        <UIButton
                           style="border: 2px solid white; color: white"
                           className="otp_outline"
                           :disabled="OtpCode.length != 6"
                           :text="$t('tokenDirection_lblAppInstallationValidateToken')"
                           :notRounded="false"
                           @click.native="ActivateToken()" />
                     </div>
                     <div v-else>
                        <UIButton
                           style="border: 2px solid white; width: 2rem"
                           className="otp_outline"
                           :icon="'gts-icon icon-arrow-full icon-sm icon-white'"
                           :notRounded="false"
                           @click.native="goNextPage()" />
                     </div>
                  </div>
               </div>
            </div>

            <div class="first-phase" style="margin-top: -1.5rem" v-if="phase == 6">
               <div class="modal-content-wrapper">
                  <div class="otp-layer-first-phase">
                     <div id="last-screen-img" class="first-phase-img">
                        <img src="@/assets/images/Otp-guide-screen-5.png" />
                     </div>
                     <div class="first-phase-texts">
                        <span>{{ $t("tokenDirection_lblSupportService") }}</span>
                        <p v-html="phase5InfoTextFirst"></p>
                     </div>
                  </div>
               </div>
               <div class="button-wrapper-last-phase">
                  <div>
                     <UIButton
                        className="default"
                        :text="$t('tokenDirection_lblLoginWithToken')"
                        :notRounded="false"
                        @click.native="routeToLogin()" />
                  </div>
               </div>
            </div>
         </div>
      </OTPModal>
   </div>
</template>
<script>
import OTPModal from "@/components/unitary/form2/UIModal.vue";
import { userService } from "@/service/index.js";
import UIButton from "@/components/unitary/Button.vue";
import { getCookie, setCookie } from "@/utils";
import UIDigitInput from "@/components/unitary/DigitInput";
export default {
   name: "OtpModal",
   components: {
      OTPModal,
      UIButton,
      UIDigitInput,
   },
   data() {
      return {
         showDetailModal: true,
         phase: 1,
         tOtpQrHtml: "",
         OtpCode: "",
         digitInputError: false,
         clearAllDigits: false,
         isValidatedOtp: false,
      };
   },
   methods: {
      ActivateToken() {
         if (this.OtpCode.length != 6) {
            this.digitInputError = true;
            this.clearAllDigits = true;
         } else {
            var query = {
               totp: this.OtpCode.toString(),
            };
            userService.userEnableOtp(query).then((response) => {
               if (!response.result.success) {
                  this.digitInputError = true;
                  this.clearAllDigits = true;
                  this.OtpCode = "";
                  return;
               }
               this.isValidatedOtp = true;
               this.phase++;
            });
         }
      },
      closeDetailModalFunc() {
         let userCookieData = JSON.parse(getCookie("OtpControl"));
         if (userCookieData == null) {
            this.showDetailModal = false;
            setCookie("OtpControl", false, 7);
         } else {
            userCookieData.isTodaysFirstLogin = false;
            setCookie("OtpControl", JSON.stringify(userCookieData), 7);
            this.showDetailModal = false;
         }
      },
      routeToLogin() {
         this.$router.push("/logout");
      },
      switchPages(val) {
         if (val > 3 && this.isValidatedOtp) {
            this.phase = val;
         } else if (val < 5) {
            this.phase = val;
         }
         if (val == 3 && this.tOtpQrHtml == "") {
            userService.userEnableOtp({}).then((response) => {
               this.tOtpQrHtml = response;
            });
         }
      },
      goNextPage() {
         if (this.phase <= 6) {
            if (this.phase >= 3 && this.isValidatedOtp) {
               this.phase++;
            } else if (this.phase < 4) {
               this.phase++;
            }
         }
         if (this.phase == 3 && this.tOtpQrHtml == "") {
            userService.userEnableOtp({}).then((response) => {
               this.tOtpQrHtml = response;
            });
         }
      },
      showDetailModalFunc() {
         this.phase = 1;
         this.showDetailModal = true;
      },
   },
   computed: {
      phase1InfoText: function () {
         var letsGoText = this.$t("tokenDirection_lblLetsGo");
         var boldLetsGoText = "&nbsp;<span style='font-weight: 600;'>" + letsGoText + "</span>";
         return this.$t("tokenDirection_lblExtraSecurityLayerInfo2").replace("#VALUE#", boldLetsGoText);
      },

      phase3Plus: function () {
         var boldLetsGoText = "<span style='font-weight: 600;'>+</span>";
         return this.$t("tokenDirection_lblGeneratingVerificationCodeText2").replace("#VALUE#", boldLetsGoText);
      },
      phase4InfoText: function () {
         var boldLetsGoText =
            "<span style='font-weight: 500;text-decoration:underline;'><a style='color:black' href='https://agent.gtsfly.de' target='_blank'>agent.gtsfly.de</a></span>";
         return this.$t("tokenDirection_lblLoginWithTokenText2").replace("#VALUE#", boldLetsGoText);
      },
      phase5InfoTextFirst: function () {
         var boldLetsGoText =
            "<span style='font-weight: 500;text-decoration:underline;cursor:default;'>" +
            this.$options.filters.phone(this.$constants.brand.callcenter) +
            "</span>";

         return this.$t("tokenDirection_lblExtraSecurityLayerLastPageInfo").replace("#VALUE#", boldLetsGoText);
      },
   },
   created() {
      let getUserCookieData = JSON.parse(getCookie("OtpControl"));

      if (getUserCookieData.cssCode != "gtsfly") {
         this.showDetailModal = false;
         return;
      }

      if (getUserCookieData.isOtpEnabled == true) {
         this.showDetailModal = false;
         setCookie("OtpControl", false, 1);
      }
      if (!getUserCookieData.isOtpEnabled && getUserCookieData.isTodaysFirstLogin) {
         this.showDetailModal = true;
      } else if (!getUserCookieData.isOtpEnabled && !getUserCookieData.isTodaysFirstLogin) {
         this.showDetailModal = false;
      }
   },
   watch: {
      phase: function (newValue) {
         newValue;
      },
   },
};
</script>
<style lang="scss" scoped>
#start-screen-img {
   width: 90%;
   margin: 0 auto;
}
#last-screen-img {
   width: 70%;
   margin: 0 auto;
   margin-top: 2rem;
}
#phase4-img {
   width: 75%;
   margin: 0 auto;
}
.Modal-header {
   display: flex;
   flex-direction: column;
   .first-phase {
      display: flex;
      flex-direction: column;
      .modal-content-wrapper {
         .otp-layer-first-phase {
            .first-phase-texts {
               padding: 1.25rem;
               display: flex;
               flex-direction: column;
               justify-content: center;
               align-items: center;
               span {
                  font-weight: 600;
                  font-size: 1.5rem;
               }
               p {
                  text-align: center;
                  width: 100%;
                  font-size: 14px;
               }
               .active {
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  align-items: center;
               }
               .first-phase-active {
                  display: flex;
                  justify-content: center;
                  align-items: center;
               }
               a {
                  color: black;
                  text-decoration: underline !important;
               }
            }
         }
      }
      .button-wrapper {
         display: flex;
         justify-content: space-evenly;
         padding-top: 1.5rem;
         width: 80%;
         margin-left: 10%;
      }
      .button-wrapper-last-phase {
         margin-top: 1rem;
         display: flex;
         justify-content: center;
         align-items: center;
      }
   }
   .second-phase {
      .modal-content-wrapper {
         .phase-header {
            margin-top: 1rem;
            span {
               font-weight: 600;
               font-size: 1.5rem;
            }
            .guide-text {
               margin-top: 0.5rem;
            }
            .guide-text-or {
               margin-top: 1rem;
               a {
                  color: black;
                  text-decoration: underline !important;
               }
            }
            p {
               font-size: 15px;
            }
            .content-list {
               font-size: 15px;
               list-style-type: decimal;
               font-weight: 600;
               padding: 1rem;
               li {
                  margin-bottom: 1rem;
                  padding-left: 0.25rem;
                  span {
                     font-size: 14px;
                     font-weight: 400;
                  }
               }
            }
         }
         .phase3-screen {
            display: flex;
            justify-content: space-evenly;
            .guide-animation {
               margin-top: -1rem;
               width: 130px;
               height: 180px;
               margin-right: 3.5rem;
            }
            .guide-qr {
               width: 180px;
               height: 180px;
            }
         }
         .qr-screen {
            padding-top: 2rem;
            display: flex;
            justify-content: space-evenly;
            .qr {
               margin-top: -0.75rem;
               width: 180px;
               height: 152px;
            }
            .line {
               margin-top: 6px;
               width: 1px;
               height: 140px;
               border-left: 2px dashed #6b6a6a;
               opacity: 0.7;
            }
            .store-link {
               display: flex;
               flex-direction: column;
               width: 200px;
               height: 152px;
               .app-store {
                  margin-top: 1rem;
                  padding: 0.5rem;
                  display: flex;
                  .icon-texts {
                     margin-top: -5px;
                     display: flex;
                     flex-direction: column;
                     margin-left: 1rem;
                  }
               }
               .google-play {
                  margin-left: 4px;
                  padding: 0.5rem;
                  display: flex;
                  .icon-texts {
                     display: flex;
                     margin-top: -5px;
                     flex-direction: column;
                     margin-left: 0.8rem;
                  }
               }
               span {
                  font-size: 14px;
               }
               p {
                  font-weight: 600;
                  font-size: 20px;
               }
            }
         }
         .qr-below-info {
            display: flex;
            justify-content: center;
            margin-top: 2rem;
            p {
               text-align: center;
               font-size: 12px;
               width: 90%;
            }
         }
         .phase4-token-field {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-top: 5rem;
         }
      }
   }
   #second-phase-footer {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 6rem;
      background-color: #3c67b2;
      display: flex;
      justify-content: center;
      align-items: center;
      .progress {
         margin-top: 1.5rem;
         background-color: #3c67b2;
         align-items: center;
         display: flex;
         margin-left: 3rem;
         .progress-tick {
            height: 0.6rem;
            width: 1.9rem;
            border-radius: 1rem;
            background-color: white;
            margin: 0.25rem;
            cursor: pointer;
         }
         .progress-tick-deactive-color {
            background-color: #8aa4d1;
         }
      }
      .go-next {
         margin-right: 2rem;
      }
   }
}
</style>
