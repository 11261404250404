<template>
   <section class="flight-selection-inputs-wrapper" @click.self="handlerCloseAutoComplete">
      <template v-if="showInputs">
         <AncillaryReissueInputLocation
            ref="departureAirport"
            :label="$t('ancillariesServicesModal_lblDepartureAirport')"
            :placeholder="$t('ancillariesServicesModal_lblDepartureAirportPlaceholder')"
            :autocomplete-input="flight.departureAirportPlaceholder"
            @selected-airport="handlerDepartureAirport" />
         <span class="flight-selection-swap" @click="handlerChange">
            <i class="gts-icon icon-swap icon-sm"></i>
         </span>
         <AncillaryReissueInputLocation
            ref="arrivalAirport"
            :label="$t('ancillariesServicesModal_lblArrivalAirport')"
            :placeholder="$t('ancillariesServicesModal_lblArrivalAirportPlaceholder')"
            :autocomplete-input="flight.arrivalAirportPlaceholder"
            @selected-airport="handlerArrivalAirport" />

         <AncillaryReissueDatepicker
            ref="departureDate"
            :label="$t('ancillariesServicesModal_lblDepartureDate')"
            :datepicker-input="flight.departureDate"
            :min-date-limit="minDateCtrl"
            @selected-date="handlerSelectedDate" />
      </template>
   </section>
</template>

<script>
import AncillaryReissueDatepicker from "@/components/structural/flight/order-detail/ancillaries/ancillary-reissue/AncillaryReissueDatepicker.vue";
import AncillaryReissueInputLocation from "@/components/structural/flight/order-detail/ancillaries/ancillary-reissue/AncillaryReissueInputLocation.vue";
export default {
   name: "AncillaryReissueInputsWrapper",
   props: {
      flight: Object,
      minDateCtrl: [String, Boolean],
   },
   components: {
      AncillaryReissueInputLocation,
      AncillaryReissueDatepicker,
   },
   data() {
      return {
         showInputs: true,
         flightModel: this.flight,
         legRequestModel: {
            fromDestination: this.flight.departureAirport,
            toDestination: this.flight.arrivalAirport,
            departureDate: this.flight.departureDate,
            flightId: this.flight.flightId,
            flightIndex: this.flight.flightIndex,
         },
      };
   },

   methods: {
      handlerChange() {
         this.showInputs = false;
         const departureAirport = this.flightModel.departureAirport;
         const arrivalAirport = this.flightModel.arrivalAirport;
         const departureAirportPlaceholder = this.flightModel.departureAirportPlaceholder;
         const arrivalAirportPlaceholder = this.flightModel.arrivalAirportPlaceholder;

         this.flightModel.departureAirport = arrivalAirport;
         this.flightModel.arrivalAirport = departureAirport;
         this.flightModel.arrivalAirportPlaceholder = departureAirportPlaceholder;
         this.flightModel.departureAirportPlaceholder = arrivalAirportPlaceholder;

         this.legRequestModel.fromDestination = arrivalAirport;
         this.legRequestModel.toDestination = departureAirport;

         setTimeout(() => {
            this.showInputs = true;
            // console.log("handlerChange", this.legRequestModel, departureAirport, arrivalAirport);
         }, 1);
      },
      handlerCloseAutoComplete() {
         // console.log("handlerCloseAutoComplete", this.$refs.departureAirport.autocompleteInput);
         this.$refs.departureAirport.inputStatus = false;
         this.$refs.arrivalAirport.inputStatus = false;
         this.$refs.departureDate.handlerHideDatePicker();
      },
      handlerDepartureAirport(airportName) {
         this.legRequestModel.fromDestination = airportName;
      },
      handlerArrivalAirport(airportName) {
         this.legRequestModel.toDestination = airportName;
      },
      handlerSelectedDate(departureDate) {
         this.legRequestModel.departureDate = departureDate;
      },
   },
   watch: {
      legRequestModel: {
         handler: function (model) {
            this.$emit("leg-request-model", model);
         },
         deep: true,
         immediate: true,
      },
   },
};
</script>

<style lang="scss" scoped>
.flight-selection-inputs-wrapper {
   .flight-selection-swap {
      // border: 1px solid red;
      cursor: pointer;
      display: flex;
      align-items: center;
      margin: 0 0.5rem;
      @media (max-width: 576px) {
         justify-content: center;
      }
   }
}
</style>
