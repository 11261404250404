import TokenService from "./token.api";
import * as userService from "./user.api";

const REFRESH_TOKEN_ENDPOINT = "/api/user/refreshjwt";
let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
   failedQueue.forEach((prom) => {
      if (error) {
         prom.reject(error);
      } else {
         prom.resolve(token);
      }
   });

   failedQueue = [];
};

const refreshToken = async () => {
   if (isRefreshing) {
      return new Promise((resolve, reject) => {
         failedQueue.push({ resolve, reject });
      });
   }

   isRefreshing = true;

   const currentJwt = TokenService.getJwtToken();
   const refreshToken = TokenService.getRefreshToken();

   if (!currentJwt || !refreshToken) {
      isRefreshing = false;
      throw new Error("JWT token or refresh token is missing. Please login again.");
   }

   try {
      const refreshTokenResponse = await userService.refreshToken({
         jwt: currentJwt,
         refreshToken: refreshToken,
      });

      const newJwt = refreshTokenResponse.jwt;
      const newJwtDuration = refreshTokenResponse.jwtDuration;
      const newRefreshToken = refreshTokenResponse.jwtRefreshToken;
      TokenService.setJwtInfo(newJwt, newJwtDuration, newRefreshToken);

      processQueue(null, newJwt);
      return newJwt;
   } catch (error) {
      processQueue(error, null);
      throw error;
   } finally {
      isRefreshing = false;
   }
};

export { refreshToken, REFRESH_TOKEN_ENDPOINT };
