import { PRIVATE_HTTP } from "./index";
// import axios from "axios";
import router from "@/router/index.js";

// const PNR_DOC_BACKEND_ENDPOINT = "http://localhost:3000/api/template/pnr";
const PNR_DOC_FRONT_ENDPOINT = "api/document/viewdoc";

// export var pnrDocBackend = (requestModel) => {
//   const promise = axios.post(PNR_DOC_BACKEND_ENDPOINT, requestModel);
//   const resData = promise
//     .then((res) => res.data)
//     .catch((error) => {
//       if (error.response.status === 401) {
//         if(router.currentRoute.path != "/login"){
//                     router.push("/login");
//                 }
//       } else {
//         console.log(error);
//       }
//     });
//   return resData;
// };

export var pnrDocFrontend = (requestModel) => {
  const promise = PRIVATE_HTTP().post(PNR_DOC_FRONT_ENDPOINT, requestModel);
  const responseData = promise
    .then((response) => response.data)
    .catch((error) => {
      if (error.response.status === 401) {
        if(router.currentRoute.path != "/login"){
                    router.push("/login");
                }
      }
    });
  return responseData;
};
