<template>
   <div class="balance-update-summary-wrapper">
      <div class="balance-update-summary-inner">
         <div class="balance-update-summary-item">
            <span class="summary-label">{{ $t("balanceUpdate_lblLoan") }}</span>
            <span class="summary-content">{{ this.info.loan | price(this.info.currency) }}</span>
         </div>
         <div class="balance-update-summary-item">
            <span class="summary-label">{{ $t("balanceUpdate_lblAvailableBalance") }}</span>
            <span class="summary-content">{{ this.info.availableBalance | price(this.info.currency) }}</span>
         </div>
         <div class="balance-update-summary-item">
            <span class="summary-label">{{ $t("balanceUpdate_lblBalance") }}</span>
            <span class="summary-content">{{ this.info.balance | price(this.info.currency) }}</span>
         </div>
      </div>
   </div>
</template>

<script>
export default {
   name: "BalanceUpdateSummary",
   props: {
      info: { type: Object },
   },
};
</script>

<style lang="scss" scoped>
.balance-update-summary-wrapper {
   width: 100%;
   background-color: #d7ffdd;
   border: 1px solid #00ff29;
   border-radius: 5px;
   @media (max-width: 576px) {
      min-width: 100%;
   }

   .balance-update-summary-inner {
      padding: 16px;

      .balance-update-summary-item {
         width: 100%;
         display: inline-flex;
         justify-content: space-between;

         .summary-label {
            &::after {
               content: ":";
               display: inline-block;
               margin-left: 4px;
            }
         }
         .summary-content {
            font-weight: 700;

            & > .currency {
               margin-left: 4px;
            }
         }
      }
   }
}
</style>
