<template>
   <div class="transfer-page-wrapper">
      <Aside />
      <Main>
         <Navbar />
         <div id="transfer-search-wrapper">
            <div class="row gx-3 gx-md-2 gx-lg-3 gx-xxl-3">
               <div id="transfer-filter-column" class="col-md-1 col-lg d-none d-xxl-block">
                  <!-- Transfer Filters -->
                  <Transition name="bigdata" mode="in-out">
                     <TransferFilters
                        :searchResponse="searchResponse"
                        @filterResponse="handlerFilteredTransfers"
                        v-if="searchResponse && searchResponse.transfers" />
                  </Transition>
               </div>

               <div class="col-lg-12 col-xl-10 col-xxl-9">
                  <Search :activeTab="3" @search-response="handlerSearchResponse" />
                  <Transition name="bigdata" mode="in-out">
                     <TransferSearchResult
                        :searchResponse="searchResponse"
                        :filteredTransfers="filteredTransfers"
                        v-if="filteredTransfers && filteredTransfers.departure.length > 0" />
                  </Transition>
               </div>

               <div class="col-md-1 col-lg d-xl-block">
                  <Transition>
                     <TransferBasket :isRoundTrip="searchResponse.isRoundTrip" v-if="isBasketActive" />
                  </Transition>
               </div>
            </div>
         </div>
      </Main>
   </div>
</template>

<script>
import { transferService } from "@/service/index.js";
import { generateUuid } from "@/utils.js";
import Aside from "@/components/unitary/Aside.vue";
import Navbar from "@/components/unitary/Navbar.vue";
import Main from "@/components/unitary/Main.vue";

import TransferFilters from "@/components/structural/transfer/search/TransferFilters.vue";
import Search from "@/components/unitary/SearchTab.vue";
import TransferSearchResult from "@/components/structural/transfer/search/TransferSearchResult.vue";
import TransferBasket from "@/components/structural/transfer/common/TransferBasket.vue";

export default {
   name: "TransferSearch",
   components: {
      Aside,
      Navbar,
      Main,
      Search,
      TransferSearchResult,
      TransferBasket,
      TransferFilters,
   },
   data() {
      return {
         searchResponse: null,
         basketResponse: null,
         filteredTransfers: null,
         isBasketActive: false,
      };
   },
   methods: {
      handlerSearchResponse(response) {
         this.searchResponse = response;
      },
      handlerSelectBasket() {
         let basketQuery = {
            departureOfferId: this.$store.state.transfer.basket.departureOfferId,
            returnOfferId: this.$store.state.transfer.basket.returnOfferId,
            searchUuid: this.searchResponse?.uuid,
            uuid: generateUuid(),
         };
         transferService
            .transferSelect(basketQuery)
            .then((response) => {
               if (response.result.success) {
                  let totalPrice = 0;
                  let currency = "EUR";
                  let transfers = [];
                  for (var channel in response.transfers) {
                     for (var transfer of response.transfers[channel]) {
                        transfers.push(transfer);
                        totalPrice += transfer.price.totalFare;
                        currency = transfer.price.currency;
                     }
                  }
                  this.basketResponse = {
                     id: response.id,
                     route: {
                        from: response.transfers[1][0].from.destinationName.split(",")[0].trim(),
                        to: response.transfers[1][0].to.destinationName.split(",")[0].trim(),
                        id: response.transfers[1][0].routeId,
                     },
                     routeTo: response.transfers[1][0].to.destinationName,
                     transfers: transfers.sort((a, b) => (a.routeId == response.routeId && b.routeId != response.routeId ? -1 : 1)),
                     totalPrice: totalPrice,
                     totalPriceCurrency: currency,
                     uuid: response.uuid,
                  };
                  this.isBasketActive = true;
                  this.$store.commit("transfer/updateBasketResponse", this.basketResponse);
               }
               this.$store.commit("app/setIsProgressGifActive", false);
            })
            .catch((err) => {
               console.log("~ Transfer Select Error => ", err);
               this.$store.commit("app/setIsProgressGifActive", false);
            });
      },
      handlerTriggerBasket() {
         this.$store.commit("app/setIsProgressGifActive", true);
         this.handlerSelectBasket();
      },
      handlerFilteredTransfers(filteredTransfers) {
         this.filteredTransfers = filteredTransfers;
      },
   },

   watch: {
      "$store.state.transfer.basket": {
         handler: function (basket) {
            if (basket && (basket.departureOfferId > 0 || basket.returnOfferId > 0)) {
               this.basketResponse = true;
               this.handlerTriggerBasket();
            } else {
               this.basketResponse = null;
               this.isBasketActive = false;
            }
         },
         deep: true,
      },
   },
};
</script>

<style lang="scss" scoped>
.transfer-page-wrapper {
   .transfer-page-inner {
      margin: auto;
      margin-top: 3rem;
   }
}
</style>
