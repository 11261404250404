<template>
   <div id="add-widget-container">
      <div id="widget-side-bar">
         <div class="widget-side-bar-header">
            <div class="widget-side-bar-title-container">
               <i class="gts-icon icon-arrange-widget"></i>
               <span class="widget-side-bar-title">{{ $t("addWidget_lblAddWidget") }} </span>
            </div>
            <i class="gts-icon icon-close-rounded gts-animation" @click="handlerClose"></i>
         </div>

         <div class="widget-side-bar-body">
            <template v-for="(widget, key, index) in widgets">
               <div class="widget-placeholder-container" :class="widget.active ? 'widget-active' : ''" :key="index" @click="handlerToggleWidget(key)">
                  <div class="widget-placeholder"></div>
                  <div class="widget-information-title">{{ widget.title }}</div>
                  <!-- <p class="widget-information-text">{{ widget.text }}</p> -->
                  <UIButton className="success" text="add" v-if="false" />
                  <UIButton className="warning" text="add" v-if="false" />
               </div>
            </template>
         </div>

         <div class="widget-side-bar-footer">
            <UIButton className="default" :text="$t('addWidget_btnSave')" @click.native="handlerSaveWidgets" />
         </div>
      </div>
   </div>
</template>

<script>
import UIButton from "@/components/unitary/Button.vue";
export default {
   name: "AddWidget",

   components: {
      UIButton,
   },
   mounted() {
      let widgetList = this.$parent.$parent.controller.widgets;
      widgetList.forEach((widget) => {
         // console.log(widget);
         this.widgets[widget].active = true;
      });
   },
   data() {
      return {
         show: true,
         widgets: {
            101: {
               id: 101,
               title: this.$i18n.t(`addWidget_lblFlightReservationsTitle`),
               text: this.$i18n.t(`addWidget_lblFlightReservationsDescription`),
               active: false,
            },
            102: {
               id: 102,
               title: this.$i18n.t(`addWidget_lblFlightForthComingTitle`),
               text: this.$i18n.t(`addWidget_lblFlightForthComingDescription`),
               active: false,
            },
            103: {
               id: 103,
               title: this.$i18n.t(`addWidget_lblFlightPerformanceTitle`),
               text: this.$i18n.t(`addWidget_lblFlightPerformanceDescription`),
               active: false,
            },
            104: {
               id: 104,
               title: this.$i18n.t(`addWidget_lblFlightGirosTitle`),
               text: this.$i18n.t(`addWidget_lblFlightGirosDescription`),
               active: false,
            },
            201: {
               id: 201,
               title: this.$i18n.t(`addWidget_lblHotelReservationsTitle`),
               text: this.$i18n.t(`addWidget_lblHotelReservationsDescription`),
               active: false,
            },
            202: {
               id: 202,
               title: this.$i18n.t(`addWidget_lblHotelCheckInTitle`),
               text: this.$i18n.t(`addWidget_lblHotelCheckInDescription`),
               active: false,
            },
            203: {
               id: 203,
               title: this.$i18n.t(`addWidget_lblHotelPerformanceTitle`),
               text: this.$i18n.t(`addWidget_lblHotelPerformanceDescription`),
               active: false,
            },
            301: {
               id: 301,
               title: this.$i18n.t(`addWidget_lblTranferWidgetTitle`),
               text: this.$i18n.t(`addWidget_lblTranferWidgetDescription`),
               active: false,
            },
            401: {
               id: 401,
               title: this.$i18n.t(`addWidget_lblRentACarWidgetTitle`),
               text: this.$i18n.t(`addWidget_lblRentACarWidgetDescription`),
               active: false,
            },
         },
      };
   },
   methods: {
      handlerToggleWidget(key) {
         const self = this;
         self.widgets[key].active = !self.widgets[key].active;
      },
      handlerClose() {
         // const self = this;
         this.$parent.$parent.controller.status = false;
         // self.$emit("hide-widget-panel", true);
      },
      handlerSaveWidgets() {
         const self = this;
         const arr = [];
         Object.keys(self.widgets).forEach((widget) => {
            if (self.widgets[widget].active) {
               arr.push(self.widgets[widget].id);
            }
         });
         self.$emit("save-widgets", arr);
      },
   },
   watch: {
      // widgetList: {
      //    handler: function (widgetList) {
      //       widgetList.forEach((widget) => {
      //          this.widgets[widget].active = true;
      //       });
      //    },
      //    immediate: true,
      // },
   },
};
</script>

<style lang="scss" scoped>
#add-widget-container {
   position: fixed;
   right: 0;
   top: 0;
   left: 0;
   bottom: 0;
   z-index: 999;
   background: #00000040;

   #widget-side-bar {
      width: 100vw;
      background: #fff;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      padding: 1.5rem 0;

      @media (min-width: 768px) {
         width: 60vw;
      }
      @media (min-width: 992px) {
         width: 37vw;
      }
      .gts-icon {
         cursor: pointer;
      }
      .widget-side-bar-header {
         position: relative;
         margin-bottom: 1rem;
         display: flex;
         align-items: center;
         padding-left: 2rem;
         padding-right: 2rem;
         padding-bottom: 1rem;
         border-bottom: 1px solid #ccc;
         justify-content: space-between;
         .widget-side-bar-title-container {
            display: flex;
            i {
               filter: brightness(0);
               opacity: 0.8;
               transform: rotate(180deg);
            }
            .widget-side-bar-title {
               margin-left: 1rem;
               font-size: var(--xlarge);
               font-weight: 500;
            }
         }
      }
      .widget-side-bar-footer {
         position: fixed;
         bottom: 2rem;
         right: 2rem;
      }

      .widget-side-bar-body {
         display: grid;
         grid-template-columns: 1fr 1fr 1fr;
         grid-column-gap: 1.5rem;
         grid-row-gap: 2rem;
         padding-left: 2rem;
         padding-right: 2rem;

         .widget-placeholder-container {
            min-width: 50%;
            border-radius: 1rem;
            min-height: 200px;
            // background-color: #f0f2f3;
            background-color: #ebebeb;
            padding: 2rem 2rem;
            text-align: left;
            cursor: pointer;
            transform: scale(1);
            transition-property: transform;
            transition-timing-function: ease-in-out;
            transition-duration: 0.15s;
            border: 2px solid transparent;
            box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

            &:hover {
               transform: scale(1.05);
            }

            .widget-placeholder {
               background: #fdfcfc;
               width: 100%;
               height: 70px;
               border-radius: 0.5rem;
               margin-bottom: 1rem;
               //     box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
            }

            .widget-information-title {
               font-size: var(--normal);
               font-weight: 500;
            }
            .widget-information-text {
               font-size: var(--small);
               color: var(--txt-ghost);
            }

            &.widget-active {
               border: 2px solid var(--success);
            }
         }
      }
   }
}
</style>
