<template>
    <div class="user-settings-page-wrapper">
        <div class="user-settings-page-inner">
            <div class="user-settings-section-header">
                <div class="user-settings-section-header-inner">
                    <div class="section-title">
                        <h4>{{ $t("userSettings_lblUserSettings") }}</h4>
                    </div>
                </div>
            </div>
            <div class="user-settings-section-content">
                <div class="user-settings-section-content-inner">
                    <div class="content-section-wrapper">
                        <section class="user-information-content content-section-item">
                            <div class="content-section-item-inner">
                                <div class="section-description content-section-inner-item">
                                    <h4>{{ userInfo.fullName }}</h4>
                                    <p>{{ userInfo.email }}</p>
                                </div>
                                <div class="section-form content-section-inner-item">
                                    <label>{{ $t("userSettings_lblUserEmail") }}</label>
                                    <UISwitch
                                        @update-switch-status="updateSwitchStatus"
                                        @change.native="triggerSwitchStatus"
                                        :switchInitStatus="request.settings.emailInform"
                                    ></UISwitch>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { userService } from "@/service/index.js";
import UISwitch from "@/components/unitary/Switch.vue";

export default {
    name: "UserSettings",
    components: {
        UISwitch,
    },
    data: function () {
        return {
            userInfo: {
                fullName: this.$store.state.user.user.name + " " + this.$store.state.user.user.surname,
                email: this.$store.state.user.user.email,
            },
            request: {
                id: this.$store.state.user.user.id,
                settings: {
                    emailInform: false,
                },
            },
        };
    },
    methods: {
        updateSwitchStatus(status) {
            const self = this;
            self.request.settings.emailInform = status;
        },
        triggerSwitchStatus() {
            const self = this;

            const setUserSettingsRequestModel = self.request;
            userService.setUserSettings(setUserSettingsRequestModel).then((response) => {
        if (response.result.success) {
            const settingSet = {
                        type: "SUCCESS",
                        message: this.$i18n.t(`userSettings_mailSet`),
                        count: false,
                    };
                    this.$store.commit("app/setNotification", settingSet);
        }
        else{
            const settingSet = {
                        type: "WARNING",
                        message: this.$i18n.t(`userSettings_mailSetError`),
                        count: false,
                    };
                    this.$store.commit("app/setNotification", settingSet);
                    self.request.settings.emailInform = !setUserSettingsRequestModel.settings.emailInform;
            // console.log('SetMailSettings Error:::');

        }
        document.body.style.cursor='default;';
        })}
    },
    created(){
        const self = this;
        const getUserSettingsRequestModel = {
                id: self.request.id
            };

            userService.getUserSettings(getUserSettingsRequestModel).then((response) => {
                if(response.result.success){
                    self.request.settings.emailInform = response.settings.emailInform;
                }
            });
    }
    // created(){
    //     const self = this;

    //     userService.getProfile({}).then((response) => {
    //         if(response.result.success){
    //             let userProfile = response.profile;
    //             self.userInfo.fullName = `${userProfile.userName} ${userProfile.userSurname}`;
    //             self.userInfo.email = userProfile.userEmail;
    //             self.request.id = userProfile.userId;
    //         }
    //     }).then(() => {
    //         const getUserSettingsRequestModel = {
    //             id: self.request.id
    //         };

    //         userService.getUserSettings(getUserSettingsRequestModel).then((response) => {
    //             if(response.result.success){
    //                 self.request.settings.emailInform = response.settings.emailInform;
    //             }
    //         });
    //     });
    // }
};
</script>

<style lang="scss" scoped>
.user-settings-page-wrapper {
    .user-settings-page-inner {
        padding: 24px;

        .user-settings-section-header {
            margin-bottom: 32px;
            border-bottom: 1px solid #ddd;
            .user-settings-section-header-inner {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding-bottom: 24px;

                .section-title {
                    h4 {
                        font-size: 24px;
                        color: #373b42;
                    }
                }
                .section-cta {
                }
            }
        }
        .user-settings-section-content {
            .user-settings-section-content-inner {
                .content-section-wrapper {
                    margin-bottom: 48px;
                    .content-section-item {
                        padding: 48px 0;
                        border-bottom: 1px solid #ddd;

                        &:first-child {
                            padding-top: 0;
                        }

                        .content-section-item-inner {
                            display: flex;
                            align-items: flex-start;
                            .content-section-inner-item {
                                &.section-description {
                                    width: 25%;
                                    max-width: 25%;
                                    // background: green;

                                    h4 {
                                        margin-bottom: 8px;
                                        font-size: 16px;
                                        font-weight: 500;
                                        color: #373b42;
                                    }
                                    p {
                                        font-size: 12px;
                                        width: 220px;
                                        max-width: 220px;
                                    }
                                }
                                &.section-form {
                                    width: 50%;
                                    max-width: 50%;

                                    .section-form-wrapper {
                                        .form-row {
                                            width: 100%;
                                            justify-content: space-between;
                                            margin-bottom: 24px;
                                            .form-item {
                                                width: 47.5%;
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        &.profile-image-content {
                            .profile-image-inner {
                                display: flex;
                                align-items: center;
                                justify-content: space-between;
                                .profile-image-item {
                                    &.profile-thumbnail-image {
                                        span {
                                            width: 127px;
                                            height: 127px;
                                            display: flex;
                                            align-items: center;
                                            justify-content: center;
                                            background-color: #6e93c6;
                                            border-radius: 50%;
                                            font-size: 48px;
                                            color: white;
                                        }
                                    }
                                    &.profile-thumbnail-image-upload {
                                        width: 70%;
                                        height: 100%;
                                        min-height: 160px;
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
                                        background-color: #f2f5f8;
                                        border: 2px dashed #b1b2b3;
                                        border-radius: 8px;
                                        .profile-thumbnail-image-upload-inner {
                                            display: flex;
                                            flex-direction: column;
                                            align-items: center;
                                            justify-content: center;
                                            text-align: center;

                                            span {
                                                font-size: 20px;
                                                font-weight: 500;
                                            }

                                            button {
                                                margin-top: 8px;
                                                background-color: white;

                                                &:hover {
                                                    color: #266cbf;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        &.user-information-content {
                        }
                        &.agency-information-content {
                        }
                        &.location-information-content {
                            .section-form-wrapper {
                                .form-row:last-child {
                                    .form-item {
                                        width: 100% !important;
                                    }
                                }
                            }
                        }
                    }
                }
                .content-cta-wrapper {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    & > button {
                        margin-right: 32px;

                        &:last-child {
                            margin-right: 0;
                        }
                    }
                }
            }
        }
    }
}
</style>
