export const flightModelGenerator = function (flights) {
   const flightArr = [];
   flights.map((flight) => {
      const rowObj = {
         flightId: flight.id,
         flightIndex: flight.index,
         departureAirportConstant: flight.departureAirportCode,
         departureAirport: flight.departureAirportCode,
         departureAirportPlaceholder: flight.departureAirportName,
         arrivalAirportConstant: flight.arrivalAirportCode,
         arrivalAirport: flight.arrivalAirportCode,
         arrivalAirportPlaceholder: flight.arrivalAirportName,
         marketingAirlineCode: flight.operatingAirlineCode,
         departureDateConstant: flight.departureDateTime,
         departureDate: flight.departureDateTime,
         arrivalDate: flight.arrivalDateTime,
         paxCount: 4,
         showInputs: false,
         disabled: false,
      };
      flightArr.push(rowObj);
   });

   return flightArr;
};
