<template>
   <div class="yanyana-container" @click="handlerYanYana">
      <span class="msg">{{ $t("seatMapModal_lblYanyana") }} </span>
   </div>
</template>

<script>
export default {
   name: "YanYana",
   props: {
      segmentId: [Number, Function],
   },
   data() {
      return {};
   },
   methods: {
      handlerYanYana() {
         this.$store.dispatch("seatmap/selectYanyana", { id: this.segmentId });
      },
   },
};
</script>

<style lang="scss" scoped>
.yanyana-container {
   padding: 0.5rem 1rem;
   background-color: var(--accent-primary);
   border-radius: 0.5rem;
   z-index: 999;
   // border: 1px solid #eee;
   color: #fff;
   font-size: var(--normal);
   font-weight: 600;
   cursor: pointer;
   &:hover {
      background-color: var(--primary);
   }
   span {
      white-space: nowrap;
   }
}
</style>
