<template>
   <!-- :class="isValid ? '' : 'g-form-invalid'" -->

   <div class="rent-a-car-location-input-container" :class="isValid || location.length > 2 ? '' : 'g-form-invalid'" v-click-outside="handlerOutClick">
      <i class="gts-icon icon-location-point-solid icon-sm"></i>

      <input
         type="text"
         :placeholder="$t('rentacar_locationSearchlbl')"
         class="v-required"
         @input="handleSearchInput"
         v-model="LocationName"
         v-on:keyup.delete="handlerClearInput" />
      <div class="g-form-validation">{{ $t("common_cannotBeEmpty") }}</div>
      <ul v-if="dropDownStatus">
         <li v-for="(location, index) in locationList" :key="index" @click="handlerSelectLocation(location)">
            {{ location.locationName }}
         </li>
      </ul>
   </div>
</template>

<script>
import debounce from "lodash.debounce";
import { rentacarService } from "@/service/index.js";

export default {
   name: "RentACarLocationInput",
   props: {
      identifier: Number,
      isValid: Boolean,
   },
   data() {
      return {
         dropDownStatus: false,
         locationList: [],
         location: "",
      };
   },
   computed: {
      LocationName: {
         get() {
            return this.location.toUpperCase();
         },
         set(value) {
            this.location = value;
         },
      },
      LocationListLength() {
         return this.locationList.length > 0 ? true : false;
      },
   },
   methods: {
      handlerClearInput() {
         this.location = "";
      },
      handlerOutClick() {
         this.dropDownStatus = false;
      },
      handlerSelectLocation(location) {
         this.$parent.$emit("location", { location, identifier: this.identifier });
         this.location = location.locationName;
         this.dropDownStatus = false;
      },
      handleSearchInput: debounce(function (value) {
         if (value.target.value.length >= 3) {
            var searchAutocompleteReqModel = {
               keyword: value.target.value.clearSpaces(),
            };

            rentacarService.rentacarAutocomplete(searchAutocompleteReqModel).then((response) => {
               this.locationList = response.locations;
               this.dropDownStatus = true;
            });
         }
      }, 600),
   },
   watch: {
      LocationName: {
         handler: function (val) {
            this.$emit("clear-input", val.length === 0);
         },
      },
   },
};
</script>
<style lang="scss" scoped>
.rent-a-car-location-input-container {
   height: 100%;
   display: flex;
   align-items: center;
   padding-left: 0.5rem;

   input {
      display: flex;
      width: 100%;
      height: 100%;
      border: none;
      outline: none;
      background-color: transparent;
      text-indent: 0.5rem;
      text-transform: capitalize;
      font-size: var(--small);

      &:focus,
      &:active {
         border: none;
         outline: none;
         background-color: transparent;
      }
   }
   ul {
      background-color: var(--c-primary);
      box-shadow: rgba(0, 0, 0, 0.2) 0px 10px 30px 0px;
      border-radius: 0.5rem;
      // padding: 0.35rem 1rem;
      position: absolute;
      top: 105%;
      width: 100%;
      z-index: 99;
      li {
         font-size: var(--small);
         padding: 0.35rem 0.75rem;
         border-bottom: 1px solid #eee;
         cursor: pointer;

         &:hover {
            background-color: #4da6ff;
            color: #fff;
         }
      }
   }
   .location-error-msg {
      position: absolute;
      left: 0;
      top: 100%;
   }
}
</style>
