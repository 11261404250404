<template>
   <div id="grand-total-wrapper">
      <div class="transfer-vehicle-wrapper">
         <div class="transfer-vehicle-inner-wrapper" v-for="(legPriceItems, index) in LegPrices" :key="`vehicle-inner-${index}`">
            <div class="vehicle-label" :class="'label-' + legPriceItems.legIndex" :key="`vehicle-${index}`">
               <span class="label-circle"></span>
               <span>{{ legPriceItems.legIndex }}. {{ $t("TransferInformations_lblCar") }}</span>
            </div>
            <span :key="`total-${legPriceItems.legIndex}`">{{ legPriceItems.totalAmount | price(legPriceItems.currency) }}</span>
         </div>
      </div>
      <div id="grand-total-inner-container">
         <PriceWidget :totalPriceInfo="PriceInformation" label="transferOrderDetail" />
      </div>
   </div>
</template>

<script>
import PriceWidget from "@/components/unitary/PriceWidget.vue";
export default {
   name: "GrandTotal",
   props: {
      grandTotal: Number,
      saleTotal: Number,
      reverseTotal: Number,
      legPrices: Object,
   },
   components: {
      PriceWidget,
   },
   computed: {
      LegPrices() {
         let legArr = [];
         if (Object.values(this.legPrices).length > 1) {
            legArr.push(Object.values(this.legPrices)[0][0]);
            legArr.push(Object.values(this.legPrices)[1][0]);
         } else {
            legArr.push(Object.values(this.legPrices)[0][0]);
         }
         return legArr;
      },
      PriceInformation() {
         return {
            reverseTotal: this.reverseTotal,
            total: this.grandTotal,
            agencyCommission: this.calculateAgencyCommission(),
            baseAmount: this.calculateBaseAmount(),
            currency: "EUR",
         };
      },
   },
   methods: {
      calculateBaseAmount() {
         let baseAmount = 0;
         Object.values(this.legPrices).map((legPrice) => {
            baseAmount = baseAmount + legPrice[0].baseAmount;
         });
         return baseAmount;
      },
      calculateAgencyCommission() {
         let agencyCommission = 0;
         Object.values(this.legPrices).map((legPrice) => {
            agencyCommission = agencyCommission + legPrice[0].agencyCommission;
         });
         return agencyCommission;
      },
   },
};
</script>

<style lang="scss" scoped>
#grand-total-wrapper {
   padding: 32px 32px 0px 0px;

   @media (max-width: 576px) {
      padding: 0.5rem;
      padding-top: 1rem;
   }

   .transfer-vehicle-wrapper {
      margin-bottom: 1rem;
      width: 228px;
      margin-left: auto;
      .transfer-vehicle-inner-wrapper {
         display: grid;
         grid-template-columns: 2fr 1fr;
         background: #f2f2f2;
         border-radius: 24rem;
         padding: 0.25rem 0.5rem;
         margin-bottom: 0.5rem;

         .vehicle-label {
            display: flex;
            span {
               &:first-child {
                  background: #fff;
                  width: 22px;
                  height: 22px;
                  display: block;
                  border-radius: 50%;
                  margin-right: 0.5rem;
               }
               &:last-child {
                  font-weight: 500;
               }
            }

            &.label-1 {
               .label-circle {
                  border: 6px solid #4dfec4;
               }
            }
            &.label-2 {
               .label-circle {
                  border: 6px solid #363636;
               }
            }
         }

         > span {
            text-align: right;
         }
      }
   }
}
</style>
