<template>
   <div
      class="reissue-search-leg-header-container"
      :class="['leg-' + header.index, isSelected ? 'is-selected' : '', header.allow ? '' : 'no-allowance']">
      <section class="reissue-search-leg-header-prev-date"></section>

      <div class="leg-header-center-wrapper">
         <section class="reissue-search-leg-header-title">{{ header.title }} - {{ header.date | FormatterDate }}</section>
         <section class="reissue-search-leg-header-count" v-if="!isSelected">
            ({{ header.count }} {{ $t("ancillariesServicesModal_lblSearchResultCount") }})
         </section>
      </div>

      <section class="reissue-search-leg-header-next-date"></section>
   </div>
</template>

<script>
export default {
   name: "AncillaryReissueSearchLegHeader",
   props: {
      header: Object,
      isSelected: Boolean,
   },
   data() {
      return {};
   },
};
</script>

<style lang="scss" scoped>
.reissue-search-leg-header-container {
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   width: 100%;
   background-color: #1378bf;
   padding: 0.75rem 1rem;
   border-radius: 6px;

   &.is-selected {
      background-color: #14cc7f;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
   }
   &.no-allowance {
      background-color: #ccc;
   }
   .leg-header-center-wrapper {
      display: flex;
      align-items: center;
      .reissue-search-leg-header-title {
         color: #fff;
         font-size: 1.125rem;
         font-weight: 500;
      }
      .reissue-search-leg-header-count {
         margin-left: 0.5rem;
         font-size: 0.85rem;
         color: #fff;
      }
   }
}
</style>
