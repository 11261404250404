import Vue from "vue";
const state = {
   searchResult: [],
   price: {
      minPrice: 0,
      maxPrice: 0,
   },
   searchResultRecommendedHotel: [],
   searchResultStatus: null,
   basketResponse: {},
   searchUuid: null,
   selectedRooms: {},
   checkout: {},
   isFilterActive: false,
   isSearching: false,
   isSearchingCheck: false,
   searchQuery: null,
   reSetSearchQuery: null,
   facilitiesArr: null,
   hotelStarCategory: [],
   hotelFilterSearch: "",
   facilitiesBaseUrl: "",
   isPartialPaymentExist: false,
   isCheckoutPageLoaded: false,
   hotelFilter: {
      // facilitiesBaseUrl: "",
      facilities: {},
      boardTypes: {},
      stars: {},
      prices: {},
      recommendeds: {},
      discounted: {},
      isPartialPaymentExist: {},
   },
   datePickerStatus: false,
   isOnUsFilterSearch: false,
   isCalendarTriggered: false,
   isFirstSearch: true,
   filterCurrency: "",
};

const mutations = {
   updateSearchResult(state, data) {
      state.searchResult = data;
   },
   updateMinMaxPrice(state, data) {
      state.price.minPrice = data.minPrice;
      state.price.maxPrice = data.maxPrice;
   },
   updateSearchResultRecommendedHotel(state, data) {
      state.searchResultRecommendedHotel = data;
   },
   updateSearchResultStatus(state, data) {
      Vue.set(state, "searchResultStatus", data);
   },
   updateBasketResponse(state, data) {
      state.basketResponse = data;
   },
   updateSelectedRooms(state, data) {
      state.selectedRooms = data;
   },
   generateSearchUuid(state, data) {
      state.searchUuid = data;
   },
   setSelectedRooms(state, room) {
      state.selectedRooms = room;
   },
   setIsSearching(state, data) {
      state.isSearching = data;
   },
   setIsSearchingCheck(state, data) {
      state.isSearchingCheck = data;
   },
   setSearchQuery(state, data) {
      state.searchQuery = data;
   },
   reSetSearchQuery(state, data) {
      state.reSetSearchQuery = data;
   },
   /* setCheckoutGuestInfo(state, data){
        state.checkout.guest = data
    }, */

   /* Hotel Filter Store Mutations Start */
   setHotelFacilitiesBaseUrl(state, data) {
      state.facilitiesBaseUrl = data;
   },
   setHotelFilterFacilitiesArr(state, data) {
      state.facilitiesArr = data;
   },
   setHotelFilterBoardTypes(state, data) {
      state.hotelFilter.boardTypes = data;
   },
   setHotelCheckoutPageLoadStatus(state, data) {
      state.isCheckoutPageLoaded = data;
   },
   setOnUsFilterStatus(state, data) {
      state.isOnUsFilterSearch = data;
   },
   setHotelStarCategoryClear(state, data) {
      state.hotelStarCategory.splice(data, 1);
   },
   setHotelStarCategoryClearAll(state) {
      state.hotelStarCategory = [];
   },
   setHotelStarCategory(state, data) {
      state.hotelStarCategory.push(data);
   },
   setIsCalendarTriggered(state, data) {
      state.isCalendarTriggered = data;
   },
   setIsFirstSearch(state, data) {
      state.isFirstSearch = data;
   },
   setFilterCurrency(state, data) {
      state.filterCurrency = data;
   },
   setHotelFilterShowOnlyRecommendedHotel(state, data) {
      state.hotelFilter.showOnlyRecommendedHotels = data;
   },
   setHotelFilterStatus(state, data) {
      Vue.set(state, "isFilterActive", data);
   },
   setFacilityCount(state, data) {
      Vue.set(state.hotelFilter.facilities[data.id], "count", data.count);
   },
   /* Hotel Filter Store Mutations End */
};

const actions = {
   updateSearchResult: function (commit, data) {
      commit("updateSearchResult", data);
   },
   updateSearchResultRecommendedHotel: function (commit, data) {
      commit("updateSearchResultRecommendedHotel", data);
   },
   // updateSearchResultStatus: function (commit, data) {
   //    commit("updateSearchResultStatus", data);
   // },
   updateBasketResponse: function (commit, data) {
      commit("updateBasketResponse", data);
   },
   setHotelCheckoutPageLoadStatus: function (commit, data) {
      commit("setHotelCheckoutPageLoadStatus", data);
   },
   updateSelectedRooms: function (commit, data) {
      commit("updateSelectedRooms", data);
   },
   generateSearchUuid: function (commit, uuid) {
      commit("generateSearchUuid", uuid);
   },
   setSelectedRooms: function (commit, room) {
      commit("setSelectedRooms", room);
   },
   setIsSearching: function (commit, data) {
      commit("setIsSearching", data);
   },
   setIsSearchingCheck: function (commit, data) {
      commit("setIsSearchingCheck", data);
   },
   setOnUsFilterStatus: function (commit, data) {
      commit("setOnUsFilterStatus", data);
   },
   setHotelStarCategory: function (commit, data) {
      commit("setHotelStarCategory", data);
   },
   setIsCalendarTriggered: function (commit, data) {
      commit("setIsCalendarTriggered", data);
   },
   setIsFirstSearch: function (commit, data) {
      commit("setIsFirstSearch", data);
   },
   setFilterCurrency: function (commit, data) {
      commit("setFilterCurrency", data);
   },
   setSearchQuery: function (commit, data) {
      commit("setSearchQuery", data);
   },
   reSetSearchQuery: function (commit, data) {
      commit("reSetSearchQuery", data);
   },
   /*     setCheckoutGuestInfo: function(commit, data){
        commit('setCheckoutGuestInfo', data);
    }, */

   /* Hotel Filter Store Actions Start */
   setHotelFacilitiesBaseUrl: function (commit, data) {
      commit("setHotelFacilitiesBaseUrl", data);
   },
   setHotelFilterFacilities: function (commit, data) {
      commit("setHotelFilterFacilities", data);
   },
   setHotelFilterBoardTypes: function (commit, data) {
      commit("setHotelFilterBoardTypes", data);
   },
   setHotelFilterShowOnlyRecommendedHotel: function (commit, data) {
      commit("setHotelFilterShowOnlyRecommendedHotel", data);
   },
   resetHotelFiltersCount: function (commit) {
      commit.state.hotelFilterSearch = "";
      const hotelFilter = commit.state.hotelFilter;
      Object.entries(hotelFilter).forEach(([a, prop]) => {
         Object.entries(prop).forEach(([b, filter]) => {
            a;
            b;

            Vue.set(filter, "count", 0);
            Vue.set(filter, "checked", false);
         });
      });
   },

   /* Hotel Filter Store Actions End */
};

const getters = {
   getSearchResult: (state) => state.searchResult,
   getSearchResultRecommendedHotel: (state) => state.searchResultRecommendedHotel,
   getSearchResultStatus: (state) => state.searchResultStatus,
   getSearchResultFilter: (state) => state.searchResultFilter,
   getBasketResponse: (state) => state.basketResponse,
   getSearchUuid: (state) => state.searchUuid,
   setSelectedRooms: (state) => state.selectedRooms,
   //setCheckoutGuestInfo: state => state.checkout.guest,

   /* Hotel Filter Store Getter Start */
   setHotelFacilitiesBaseUrl: (state) => state.hotelFilter.facilitiesBaseUrl,
   setHotelFilterFacilities: (state) => state.hotelFilter.facilities,
   setHotelFilterBoardTypes: (state) => state.hotelFilter.boardTypes,
   setHotelFilterShowOnlyRecommendedHotel: (state) => state.hotelFilter.showOnlyRecommendedHotels,
   /* Hotel Filter Store Getter Start */
};

export const hotel = {
   namespaced: true,
   state,
   mutations,
   actions,
   getters,
};
