<template>
    <div class="html-viewer-container">
        <div class="toggle-viewer-wrapper">
            <span class="toggle-viewer" @click="viewer = !viewer">show guide</span>
        </div>
        <code v-if="viewer">
            <span class="value"> Value: &#8594; {{ data.value }} </span>

            <div class="html-code">{{ data.html }}</div>

            <div class="import-code">Import {{ data.import.name }} from "{{ data.import.from }}"</div>

            <table class="props-table">
                <thead>
                    <tr>
                        <th>name</th>
                        <th>type</th>
                        <th>vals</th>
                        <th>default</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(td, index) in data.description" :key="index">
                        <td>{{ td.name }}</td>
                        <td>{{ td.type }}</td>
                        <td>{{ td.val }}</td>
                        <td>{{ td.default }}</td>
                    </tr>
                </tbody>
            </table>
        </code>
    </div>
</template>

<script>
export default {
    name: "CodeViewer",
    props: {
        data: Object,
    },
    data() {
        return {
            viewer: false,
        };
    },
};
</script>

<style lang="scss" scoped>
.html-viewer-container {
    font-family: monospace;
    .toggle-viewer-wrapper {
        display: flex;
        justify-content: flex-end;
        .toggle-viewer {
            color: var(--accent-primary);
            text-decoration: underline;
            cursor: pointer;
        }
    }
    code {
        background: #eff7ff;
        margin: 1rem 0;
        display: block;
        padding: 1rem 2rem;
        border: 1px solid #cbe0f5;
        border-radius: 0.5rem;

        .html-code {
            margin-top: 1rem;
            color: green;
        }
        .value {
            margin-top: 1rem;
            display: block;
            color: var(--gray-lighten);
        }
    }
    .import-code {
        margin-top: 1rem;
        background-color: #1e1e1e;
        color: #d079ca;
        font-size: var(--xsmall);
        white-space: nowrap;
        padding: 0.25rem 0.5rem;
        border-radius: 0.25rem;
    }
    .props-table {
        margin-top: 1rem;
        width: 100%;
        background: #fff;
        th,
        td {
            padding: 0.25rem 0.5rem;
            border: 1px dotted #ccc;
            color: #363636;
        }
        th {
            background: #fff;
            font-weight: 600;
        }
    }
}
</style>
