<template>
   <div class="gts-switch-wrapper">
      <input @change="triggerSwitchChange" id="checkbox" type="checkbox" class="switch-checkbox" />
      <span class="switch-left-text">
         <slot name="switch-left-text"></slot>
      </span>
      <label for="checkbox" class="switch-label" :class="isSwitchActive ? 'is-active' : 'is-deactive'">
         <div class="switch-toggle" :class="isSwitchActive ? 'is-active' : 'is-deactive'"></div>
      </label>
      <span class="switch-right-text">
         <slot name="switch-right-text"></slot>
      </span>
   </div>
</template>

<script>
export default {
   name: "UISwitch",
   props: {
      switchInitStatus: { type: Boolean },
   },
   data() {
      return {
         isSwitchActive: false,
      };
   },
   methods: {
      triggerSwitchChange() {
         const self = this;
         self.isSwitchActive = !self.isSwitchActive;
         this.$emit("update-switch-status", self.isSwitchActive);
      },
   },
   created() {
      const self = this;

      self.isSwitchActive = this.switchInitStatus;
      this.$emit("update-switch-status", self.isSwitchActive);
   },
   watch: {
      switchInitStatus(newStatus) {
         const self = this;
         self.isSwitchActive = newStatus;
      },
   },
};
</script>

<style lang="scss" scoped>
.gts-switch-wrapper {
   display: flex;
   align-items: center;

   & > span {
      &.switch-left-text {
         margin-right: 8px;
      }
      &.switch-right-text {
         margin-left: 8px;
      }
   }

   .switch-checkbox {
      display: none;
   }

   .switch-label {
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;
      width: 70px;
      padding: 4px;
      border: 1px solid;
      border-radius: 50px;
      transition: background 0.3s ease;
      cursor: pointer;
      z-index: 1;

      &.is-active {
         background: #5cb6f9;
         border-color: #5cb6f9;
      }
      &.is-deactive {
         background: white;
         border-color: #bfbfbf;
      }
   }

   .switch-toggle {
      width: 24px;
      height: 24px;
      background-color: white;
      border-radius: 50%;
      transform: translateX(0);
      transition: transform 0.3s ease, background-color 0.5s ease;

      &.is-active {
         transform: translateX(38px);
      }
      &.is-deactive {
         background-color: #bfbfbf;
      }
   }
}
</style>
