<template>
   <div class="gsa-selection" v-click-outside="handlerClickOutside">
      <span>{{ $t("onBehalfOfComponent_lblAgency") }}</span>

      <br />
      <div class="gsa-select-box" @click="dropdown = !dropdown" :class="AllowSelection ? '' : 'not-allowed'">
         <div class="gsa-label-container">
            <span>{{ Selected }}</span>
         </div>
         <div class="gsa-label-action-container" v-if="AllowSelection">
            <i class="gts-icon icon-close-rounded icon-xs" v-if="ReturnToGsa && !isDisabled" @click.self="handlerReturnToGsa"></i>
            <i class="gts-icon icon-arrow-down icon-xs"></i>
         </div>
      </div>
      <div class="gsa-dropdown-container" v-if="dropdown && AllowSelection && !isDisabled">
         <div class="gsa-input-container">
            <input type="text" v-if="AllowSearch" v-model="search" :placeholder="$t('onBehalfOfComponent_phAgency')" />
         </div>

         <ul class="gsa-dropdown-list-wrapper">
            <li
               v-for="(agency, index) in SelectList"
               :key="index"
               @click="handlerSelect(index)"
               :class="selection == agency.name ? 'selected-agency' : ''">
               <span>{{ agency.name }}</span>

               <i class="gts-icon icon-store icon-xs" v-if="agency.salesOffice"></i>
            </li>
         </ul>
      </div>
   </div>
</template>

<script>
// import { agencyService } from "@/service/index.js";
import { globalService, TokenService } from "@/service/index.js";
import { generateUuid } from "@/utils.js";

export default {
   name: "GsaSelection",
   props: {
      list: Array,
      selectedAgency: Number,
      isDisabled: Boolean,
   },
   data() {
      return {
         dropdown: false,
         selection: null,
         search: "",
      };
   },
   created() {
      const self = this;
      self.selection = self.$store.state.user.user.agencyName;
   },

   computed: {
      SelectedAgency() {
         const selectedAgency = this.list.find((agency) => {
            return agency.id == this.selectedAgency;
         });
         return selectedAgency?.name;
      },
      ReturnToGsa() {
         const self = this;
         if (self.$store.state.user.user.onBehalfOfs.agencyOnBehalfOfId == -1) {
            return false;
         } else {
            return true;
         }
      },
      SelectList() {
         const self = this;
         return self.list
            .sort((a, b) => a.name.localeCompare(b.name))
            .filter((agency) => agency.name.toLowerCase().includes(self.search.toLowerCase()));
      },
      AllowSearch() {
         return true;
      },
      Selected() {
         if (this.SelectedAgency == undefined) {
            return this.$i18n.t(`onBehalfOfComponent_phSelectAgency`);
         } else {
            return this.SelectedAgency;
         }
         // const self = this;
         // if (self.selection != null && self.selection.match(/^ *$/) == null) {
         //    return self.selection;
         // } else {
         //    return this.$i18n.t(`onBehalfOfComponent_phSelectAgency`);
         // }
      },

      AllowSelection() {
         const self = this;
         if (self.$store.state.user.user.onBehalfOfs.agencyOnBehalfOfId == -1 || self.$store.state.user.user.agencyId == -1) {
            return true;
         } else {
            return false;
         }
      },
   },
   methods: {
      handlerSelect(index) {
         const self = this;
         self.$store.commit("app/setIsProgressGifActive", true);

         let query = {
            onBehalfOfAgencyId: self.SelectList[index].id,
            onBehalfOfGsaId: self.$store.state.user.user.onBehalfOfs.gsaOnBehalfOfId,
            language: self.$store.state.user.user.language,
            uuid: generateUuid(),
         };

         globalService.setWorkingParams(query).then((res) => {
            if (res.result.success && res.result.code == "SUCCESS") {
               //Update JWT Token
               TokenService.setJwtInfo(res.jwt, res.jwtDuration, res.jwtRefreshToken);

               self.selection = self.SelectList[index].name;
               self.dropdown = false;
               window.location.reload();
            }
         });
      },
      handlerReturnToGsa() {
         const self = this;

         self.$store.commit("app/setIsProgressGifActive", true);
         self.dropdown = false;
         let query = {
            onBehalfOfAgencyId: -1,
            onBehalfOfGsaId: self.$store.state.user.user.onBehalfOfs.gsaOnBehalfOfId,
            language: self.$store.state.user.user.language,
            uuid: generateUuid(),
         };

         globalService.setWorkingParams(query).then((res) => {
            if (res.result.success && res.result.code == "SUCCESS") {
               //Update JWT Token
               TokenService.setJwtInfo(res.jwt, res.jwtDuration, res.jwtRefreshToken);

               window.location.reload();
            }
         });
      },
      handlerClickOutside() {
         const self = this;
         self.dropdown = false;
      },
   },
};
</script>

<style lang="scss" scoped>
.gsa-selection {
   position: relative;
   .gsa-select-box {
      border: 1px solid #ccc;
      background: #f8f8f8;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding: 0 0.75rem;
      box-sizing: border-box;
      border-radius: 0.5rem;
      position: relative;
      min-height: 50px;
      cursor: pointer;

      .gsa-label-container {
         span {
            font-size: var(--small);
            color: var(--txt-black);
            font-weight: 500;
         }
      }
      .gsa-label-action-container {
         display: flex;

         i {
            &:first-child {
               margin-right: 0.5rem;
            }
         }
      }

      &.not-allowed {
         background: #f4f4f4;
         .gsa-label-container {
            span {
               color: var(--txt-ghost);
            }
         }
      }
   }
   .gsa-dropdown-container {
      border: 1px solid #ccc;
      background: #fff;
      left: 0;
      right: 0;
      padding: 0.5rem 0rem;
      position: absolute;
      z-index: 5;
      border-radius: 0px 0px 12px 12px;
      top: calc(100% - 5px);
      max-height: 60vh;
      overflow: hidden;
      overflow-y: auto;

      .gsa-input-container {
         padding: 0 1rem;
         margin-bottom: 0.5rem;

         input {
            width: 100%;
            border: 1px solid #ccc;
            background: #fff;
            border-radius: 0.55rem;
            min-height: 36px;
            text-indent: 0.5rem;
            font-size: var(--small);
            &:focus {
               outline: none;
               // background-color: var(--pastel-primary-lightest);
               border: 1px solid #aaa;
            }
         }
      }
      ul.gsa-dropdown-list-wrapper {
         li {
            border-bottom: 1px solid #eee;
            padding: 0.5rem 1rem;
            font-size: var(--small);
            white-space: nowrap;
            width: 100%;
            cursor: pointer;
            display: flex;
            justify-content: space-between;
            align-items: center;

            &:hover {
               background-color: var(--hover);
            }

            &.selected-agency {
               font-weight: 600;
               background-color: var(--hover);
            }
         }
      }
   }
}
</style>
