<template>
   <div class="balance-update-page-wrapper">
      <Aside />
      <Main>
         <Navbar />
         <div class="balance-update-page-inner">
            <section class="page-section balance-update-page-inner-item">
               <PageSubHeader :sectionTitle="$t('balanceUpdate_title')">
                  <BalanceUpdateSummary :info="balanceUpdateSummary" v-cloak />
               </PageSubHeader>
            </section>
            <PaymentPurchaseWidget entityType="finance" :paymentChoiceDetail="paymentChoices" />
         </div>
      </Main>
   </div>
</template>

<script>
import { financeService } from "@/service/index.js";
import Popup from "@/gts-popup.js";
import Aside from "@/components/unitary/Aside.vue";
import Navbar from "@/components/unitary/Navbar.vue";
import Main from "@/components/unitary/Main.vue";
import BalanceUpdateSummary from "@/components/structural/finance/BalanceUpdateSummary.vue";
import PaymentPurchaseWidget from "@/components/structural/payment/PaymentPurchaseWidget.vue";
import PageSubHeader from "@/components/unitary/PageSubHeader.vue";

export default {
   name: "BalanceUpdate",
   components: {
      Aside,
      Navbar,
      Main,
      PageSubHeader,
      BalanceUpdateSummary,
      PaymentPurchaseWidget,
   },
   data() {
      return {
         fmtGroupCode: "BalanceUpdate",
         balanceUpdateSummary: {
            availableBalance: "",
            loan: "",
            balance: "",
            currency: "",
         },
         paymentChoices: {},
      };
   },
   created() {
      let initQuery = {
         paymentUuid: "",
         trxType: 1,
         paymentType: 0,
         vposId: "0",
         amount: 0,
         currency: "",
         note: "",
         creditcard: {},
      };

      this.$store.commit("app/showProgressGif");
      financeService.updateBalance(initQuery).then((response) => {
         this.$store.commit("app/hideProgressGif");

         if (!response.result.success) {
            response.result.service = this.$options.name;
            Popup.result(response.result);
            return;
         }

         this.balanceUpdateSummary.availableBalance = response.availableBalance;
         this.balanceUpdateSummary.loan = response.loan;
         this.balanceUpdateSummary.balance = response.balance;
         this.balanceUpdateSummary.currency = response.currency;

         this.paymentChoices = response.paymentChoiceDetail;
      });
   },
};
</script>

<style lang="scss" scoped>
.balance-update-page-wrapper {
   .balance-update-page-inner {
      width: 90%;
      margin: auto;

      .balance-update-page-inner-item:not(:first-child) {
         background-color: white;
         border-radius: 8px;
         padding: 48px;
         margin-bottom: 16px;
      }
      .balance-update-page-inner-item {
         ::v-deep .balance-update-summary-wrapper {
            width: 50%;
         }
      }

      .search-inputs {
         margin-bottom: 42px;
      }
      .search-cta {
         display: flex;
         justify-content: flex-end;
      }
   }
}
</style>
