<template>
   <div class="guest-wrapper">
      <div class="guest-inner">
         <div class="guest-list">
            <template v-for="(pax, index) in paxes">
               <GuestItem
                  :key="index"
                  :roomIndex="roomIndex"
                  :customerNationality="customerNationality"
                  :pax="pax"
                  :guestItemIndex="index"
                  @update-to-room-guest-info="setGuestInfo"
                  :contactModel="contactModel" />
            </template>
         </div>
      </div>
   </div>
</template>

<script>
import GuestItem from "@/components/structural/hotel/checkout/GuestItem.vue";

export default {
   name: "RoomGuest",
   props: {
      roomIndex: { type: Number },
      contactModel: { type: Object },
      customerNationality: { String },
      paxes: Array,
   },
   components: {
      GuestItem,
   },
   /* data(){
        return{
            roomGuests: this.paxes
        }
    }, */
   methods: {
      setGuestInfo(guestInfo) {
         this.paxes[guestInfo.guestItemIndex] = guestInfo;
         //return this.$store.commit('hotel/setCheckoutGuestInfo', self.roomGuests);
      },
   },
};
</script>

<style lang="scss" scoped>
.guest-wrapper {
   background-color: #f7f7f7;
   border: 1px solid #cccccc;
   border-radius: 8px;

   .guest-inner {
      padding: 1rem;
      padding-top: 1.5rem;

      .guest-list {
      }
   }
}
</style>
