<template>
   <div class="terms-of-use-container">
      <span class="terms-of-use-title">Cookie Policy</span>
      <div class="terms-of-use">
         <div id="cookie-container" class="inner-overflow">
            <span style="height: 100px; display: block"></span>
            <p><b>A) The definition and the scope of data processing.</b></p>
            <p>
               Our webpage uses cookies. These cookies are text filed recorded on computer system of a user by internet browser. In case that a user
               calls a webpage, a cookie is recorded on operating system of that user. That cookie consists of a characteristics squence (string)
               ensuring net identification of a borwser when that webpage is called again.
            </p>
            <p>
               We use cookies to make our webpage a user-friendly one. Some elements of our internet pages are required to be identified also afre
               changing called browser or page.
            </p>
            <p>Below indicated data is recorded and transferred in cookies:</p>
            <ol>
               <li>Language settings</li>
               <li>The products at shopping cart</li>
               <li>Log-in information</li>
            </ol>
            <p>We use cookies analyzing internet surfing habit of a user.</p>
            <p>By doing so, below indicated data can also ve transfered:</p>
            <ol>
               <li>A term entered for a search</li>
               <li>Frequency of calling a page</li>
               <li>Using webpage functions</li>
            </ol>
            <p>
               Data of a user collected in this way is identified with nicknames with technical measures (pseudonymised). Fort his reason, it would
               not be possible to associate date with a user making call. Data is not retained together with other data of a user.
            </p>
            <p>
               In case that our wbepage is called, the users are informed rergarding analysis purposed use of cookies with infobanner and they are
               informed about this data protection statement. In this sense, they are also informed how to prevent this with browser settings.
            </p>
            <p><b>B) Legal base of data processing</b></p>
            <p>Legal base of data processing with cookiies is Paragraph 1lit.f of Article 6 of EU-General Data Protection Legislation (DSGVO).</p>
            <p>
               In case of approval from a user is given, legal base of personal data processing iwth using cookies for analysis puporse, is Paragraph
               1lit.a of Article 6 of EU-General Data Protection Legislation (DSGVO).
            </p>
            <p><b>C) The purpose of data processing</b></p>
            <p>
               The purpose of using required cookies is technically to ease webpage us efor the users. Some functions of our internet page cannot be
               used without cookies. For this purpose, it is required to recognize a web browser after changing page.
            </p>
            <p>Use of cookies is required for following applications:</p>
            <ol>
               <li>Shopping cart</li>
               <li>Transferring/recording language settings</li>
               <li>Recording searching terms</li>
            </ol>
            <p>User data taken with required cookies technically, is not used for preparing user profiles.</p>
            <p>
               Analysis cookies are used for improving quality of our webpage and contents. Thanks to analysis cookies, we learn how the webpage is
               used and thus, we can improve our offer continuosly.
            </p>
            <p>
               For these purposes, we have justified interest about processing personal data as per Paragraph 1lit.f of Article 6 of EU-General Data
               Protection Legislation (DSGVO).
            </p>
            <p><b>E) Retention period, objection and disposal option</b></p>
            <p>
               Cookies are recorded on user’s computer and transferred to our page. Fort his areason, you have full control about using cookies as a
               user. When your internet browser settings are changes, you can deactivate cookies transfer or you can limit it. Previously recorded
               cookies can always be deleted. It can also be made automatically. In case that cookies are deactivated for our webpage, it will not be
               possible to use all functions of our wbpage with full scope.
            </p>
            <p>Transferring flash cookies will be possible with changing browser settings as well as changing FLash Player settings.</p>

            <span style="height: 100px; display: block"></span>
         </div>
      </div>
   </div>
</template>

<script>
export default {
   name: "CookiePolicy",
};
</script>

<style lang="scss" scoped>
.cookie-policy {
}
</style>
