<template>
   <div class="schmetterling-sales-report-page-container sub-page-wrapper">
      <Aside />
      <Main>
         <Navbar />

         <div class="container-fluid">
            <div class="row">
               <div class="col gts-box mx-3">
                  <h1 class="sub-page-header">{{ $t("schmetterlingSalesReport_title") }}</h1>

                  <div class="sub-page-row-wrapper row gy-3">
                     <div class="col-12 col-md-6 col-lg-2">
                        <UIDatePicker2
                           :label="$t('schmetterlingSalesReport_filter_lblFromDate')"
                           :min="filters.date1.min"
                           :max="filters.date1.max"
                           v-model="filters.date1.value" />
                     </div>
                     <div class="col-12 col-md-6 col-lg-2">
                        <UIDatePicker2
                           :label="$t('schmetterlingSalesReport_filter_lblToDate')"
                           :min="filters.date2.min"
                           :max="filters.date2.max"
                           v-model="filters.date2.value" />
                     </div>
                     <div class="col-12 col-md-6 col-lg-2">
                        <UIInput
                           :label="$t('schmetterlingSalesReport_filter_lblReservationNo')"
                           v-model="filters.reservationNo"
                           :placeholder="$t('schmetterlingSalesReport_filter_plhReservationNo')"
                           v-input="{ type: 'alphanumeric', len: [0, 20], required: false }" />
                     </div>

                     <div class="col-12 col-md-6 col-lg-3">
                        <UISelectBox
                           :key="schmetterlingStatusKey"
                           :label="$t('schmetterlingSalesReport_filter_lblStatus')"
                           :options="schmetterlingStatusList"
                           v-model="filters.schmetterlingStatus"
                           light-theme />
                     </div>
                  </div>

                  <!--  CTA -->
                  <div class="sub-page-actions-row row">
                     <div class="sub-pages-actions-wrapper col-12">
                        <UIButton
                           className="default"
                           :text="$t('schmetterlingSalesReport_filter_btnList')"
                           @click.native="funcSchmetterlingSalesReport" />
                     </div>
                  </div>
               </div>
            </div>

            <!-- Sale Report -->
            <template v-if="reportLoad">
               <!-- Sale Result Container -->
               <section class="smart-table-wrapper">
                  <SmartTable :options="mainTableOptions" />
               </section>
            </template>
         </div>
      </Main>
   </div>
</template>

<script>
import moment from "moment";
import i18n from "@/fmt/i18n.js";
import Popup from "@/gts-popup.js";
import { reportService } from "@/service/index.js";
import Main from "@/components/unitary/Main.vue";
import Aside from "@/components/unitary/Aside.vue";
import Navbar from "@/components/unitary/Navbar.vue";
import SmartTable from "@/components/utils/SmartTable/SmartTableComp.vue";
import UISelectBox from "@/components/unitary/form2/UISelectBox.vue";
import UIButton from "@/components/unitary/Button.vue";
import UIDatePicker2 from "@/components/unitary/form2/UIDatePicker2.vue";
import UIInput from "@/components/unitary/form2/UIInput.vue";

export default {
   name: "SchmetterlingSalesReport",
   components: {
      Aside,
      Navbar,
      Main,
      UIDatePicker2,
      UIButton,
      SmartTable,
      UIInput,
      UISelectBox,
   },

   data() {
      return {
         schmetterlingStatusKey: 0,
         reportLoad: false,
         schmetterlingStatusList: this.$GtsDropdownData.ESchmetterlingOrderStatus(true),
         filters: {
            date1: {
               min: moment().subtract(6, "year").format(this.$constants.dateFormat),
               max: moment().format(this.$constants.dateFormat),
               value: moment().format(this.$constants.dateFormat),
            },
            date2: {
               min: moment().subtract(6, "year").format(this.$constants.dateFormat),
               max: moment().format(this.$constants.dateFormat),
               value: moment().format(this.$constants.dateFormat),
            },
            reservationNo: "",
            schmetterlingStatus: -1,
         },
         mainTableOptions: {
            columns: [
               { label: `${i18n.t("schmetterlingSalesReport_tblMain_thStatus")}`, status: true },
               { label: `${i18n.t("schmetterlingSalesReport_tblMain_thUser")}`, status: true, identifier: "user" },
               { label: `${i18n.t("schmetterlingSalesReport_tblMain_thCustomerNameSurname")}`, status: true },
               { label: `${i18n.t("schmetterlingSalesReport_tblMain_thReservationNo")}`, status: true },
               { label: `${i18n.t("schmetterlingSalesReport_tblMain_thTourOperator")}`, status: true },
               { label: `${i18n.t("schmetterlingSalesReport_tblMain_thTotalPrice")}`, status: true },
               { label: `${i18n.t("schmetterlingSalesReport_tblMain_thCheckInDateTime")}`, status: true },
               { label: `${i18n.t("schmetterlingSalesReport_tblMain_thCheckOutDateTime")}`, status: true },
            ],
            rows: [],
            rules: {
               autoMerge: ["orderId"],
               dominantCell: "orderId",
               showOnMobile: [0, 2, 4, 7],
               // highlightedColumns: [5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15],
            },
            options: {
               header: {
                  title: this.$options.name,
                  viewOptions: {
                     status: true,
                     perPage: 50,
                     selects: [
                        { name: `3 ${i18n.t("common_order")}`, value: 3 },
                        { name: `5 ${i18n.t("common_order")}`, value: 5 },
                        { name: `25 ${i18n.t("common_order")}`, value: 25 },
                        { name: `50 ${i18n.t("common_order")}`, value: 50 },
                        { name: `100 ${i18n.t("common_order")}`, value: 100 },
                        { name: `${i18n.t("common_all")}`, value: null },
                     ],
                  },
                  viewMode: null,
                  saleDateIndex: 9,
               },
               footer: {
                  pagination: {
                     pages: [],
                     activepage: 1,
                  },
               },
            },
         },
      };
   },
   computed: {},
   mounted() {
      this.updateSchmetterlingStatusList();
   },
   methods: {
      updateSchmetterlingStatusList() {
         this.schmetterlingStatusList = this.$GtsDropdownData.ESchmetterlingOrderStatus(true);
      },
      getTableColumns() {
         return [
            { label: `${i18n.t("schmetterlingSalesReport_tblMain_thStatus")}`, status: true },
            { label: `${i18n.t("schmetterlingSalesReport_tblMain_thUser")}`, status: true, identifier: "user" },
            { label: `${i18n.t("schmetterlingSalesReport_tblMain_thCustomerNameSurname")}`, status: true },
            { label: `${i18n.t("schmetterlingSalesReport_tblMain_thReservationNo")}`, status: true },
            { label: `${i18n.t("schmetterlingSalesReport_tblMain_thTourOperator")}`, status: true },
            { label: `${i18n.t("schmetterlingSalesReport_tblMain_thTotalPrice")}`, status: true },
            { label: `${i18n.t("schmetterlingSalesReport_tblMain_thCheckInDateTime")}`, status: true },
            { label: `${i18n.t("schmetterlingSalesReport_tblMain_thCheckOutDateTime")}`, status: true },
         ];
      },
      updateTableColumns() {
         this.mainTableOptions.columns = this.getTableColumns();
      },
      funcSchmetterlingSalesReport() {
         this.reportLoad = false;
         this.mainTableOptions.rows.length = 0;
         this.$store.commit("app/setIsProgressGifActive", true);

         let request = {
            date1: this.filters.date1.value + "T00:00:00",
            date2: this.filters.date2.value + "T23:59:59",
            reservationNo: this.filters.reservationNo == "" ? null : this.filters.reservationNo,
            status: this.filters.schmetterlingStatus,
         };

         reportService.schmetterlingSalesReport(request).then((response) => {
            this.$store.commit("app/setIsProgressGifActive", false);

            if (!response.result.success) {
               response.result.service = this.$options.name;
               Popup.result(response.result);
               return;
            }

            response.showModels.map((model) => {
               const rowObj = {
                  status: {
                     text: this.$options.filters.ESchmetterlingOrderStatus(model.status),
                     class: `schmetterling-badge gts-badge ESchmetterlingOrderStatus-${model.status}`,
                  },
                  user: model.user,
                  customerName: model.customerName,
                  reservationNo: model.reservationNo,
                  tourOperator: model.tourOperator,
                  totalPrice: {
                     text: this.$options.filters.price(model.totalPrice),
                     class: "price-display",
                  },
                  checkInDateTime: {
                     text: this.$options.filters.fullDateFormat(model.checkInDateTime),
                     class: "",
                  },
                  checkOutDateTime: {
                     text: this.$options.filters.fullDateFormat(model.checkOutDateTime),
                     class: "",
                  },
               };
               this.mainTableOptions.rows.push(rowObj);
            });
            this.reportLoad = true;
         });
      },
   },
   watch: {
      "$i18n.locale"() {
         this.updateTableColumns();
         this.updateSchmetterlingStatusList();
         this.schmetterlingStatusKey++;
      },
   },
};
</script>

<style lang="scss" scoped>
.page-container {
   .page-inner {
      width: 90%;
      margin: auto;
      .page-form-row {
         display: grid;
         grid-template-columns: repeat(6, 1fr);
         grid-column-gap: 1rem;
      }
      .page-action-row {
         margin-top: 1rem;
         display: flex;
         justify-content: flex-end;
      }
   }
}
</style>
