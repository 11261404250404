<template>
   <div class="form-item">
      <label :for="id" v-if="labelText">{{ labelText }}</label>
      <textarea
         name="gts-textarea"
         :id="id"
         :placeholder="placeHolder"
         @input="$emit('input', $event.target.value)"
         :maxlength="maxlength"
         :cols="cols"
         :rows="rows"></textarea>
   </div>
</template>

<script>
export default {
   name: "UITextarea",
   props: {
      placeHolder: { String },
      id: { String },
      labelText: { String },
      maxlength: { String },
      cols: {
         type: String,
         default: "20",
      },
      rows: {
         type: String,
         default: "5",
      },
   },
};
</script>

<style lang="scss" scoped>
.form-item {
   width: max-content;
   height: max-content;

   & > label {
      margin-bottom: 8px;
   }

   & > textarea {
      width: 100%;
      padding: 8px 16px;
      border: 1px solid #b1b2b3;
      border-radius: 16px;
      font-size: 14px;
      color: #363940;
      outline: none;
      transition: 0.15s ease-in-out;

      &::placeholder {
         color: #6d6e6f;
      }

      &:focus,
      &:active {
         border-color: #33b8ff;
         box-shadow: 0 3px 5px 0 rgba(51, 184, 255, 0.5);
      }
   }
}
</style>
