<template>
   <div class="ssr-selection-container">
      <!-- Commented out bcz no extra ssr is presence -->

      <Badge2 :msg="$t('flightCheckout_SSR_rezervationDisabled')" warning v-if="!ReserveBtnStatus" />
      <br />
      <SsrSelectionBox v-for="(ssr, ssrIndex) in SSRS" :key="ssrIndex" :ssr="ssr" @ssr-selection="handlerSsrSelection" @show-badge="ShowBadge" />
   </div>
</template>

<script>
import Badge2 from "@/components/unitary/Badge2.vue";
import { flightService } from "@/service/index.js";
import SsrSelectionBox from "@/components/structural/flight/checkout/brands/SsrSelectionBox.vue";
export default {
   name: "SsrSelection",
   props: {
      ssrs: Object,
      flightIdentifer: String,
      selectedBrand: String,
   },
   components: {
      SsrSelectionBox,
      Badge2,
   },
   data() {
      return {
         showBadge: false,
         generatedSsrList: {},
      };
   },
   computed: {
      ReserveBtnStatus() {
         // return this.$store.state.app.flight.checkout.response.paymentChoiceDetail.paymentChoices;
         return this.$store.state.flight.checkout.response.paymentChoiceDetail.paymentChoices.some((item) => item.trxTypes.includes(2) == true);
      },
      SSRS() {
         return this.generatedSsrList;
      },
   },

   methods: {
      ShowBadge(status) {
         this.showBadge = status;
      },
      handlerSsrSelection(ssr) {
         this.$store.commit("app/setIsProgressGifActive", true);

         this.$store.commit("flight/updateCheckoutSsr", {
            flightIdentifier: this.flightIdentifer,
            identifier: ssr.identifier,
            status: ssr.status,
         });

         let query = this.$store.state.flight.checkout.request;
         console.log(query);

         flightService.checkout(query).then((response) => {
            this.$store.commit("flight/updateCheckoutResponse", response.checkoutResponse);
            this.$store.commit("flight/updateCheckoutRequest", response.checkoutRequest);
            this.$store.commit("flight/updateCheckoutUuid", response.checkoutUuid);
            this.$store.commit("app/setIsProgressGifActive", false);
            this.$store.state.app.progress.message = "";
         });
      },
      handlerGenerateSsrModels(extraSsrs) {
         // Ana obj modeli burda generate ediliyor
         Object.keys(extraSsrs).forEach((item) => {
            const isExpandable = Object.keys(extraSsrs[item]).length > 1 ? true : false;
            const rowObj = {
               ssrName: item,
               isExpandable,
               icon: String,
               ssrItems: {},
            };

            // Her bir ssr'ı item olarak ana obj modelindeki parant'ına liste olarak ekliyoruz
            Object.keys(extraSsrs[item]).forEach((subItem, index) => {
               //ikon ve açıklama için için ssrtype alıyourz
               if (index == 0) {
                  const ssrType = extraSsrs[item][subItem].ssrType;
                  const ssrDescription = extraSsrs[item][subItem].info;
                  const ssrPrice = extraSsrs[item][subItem].total;

                  rowObj.icon = `ssr-${this.$options.filters.EFltAncillaryType(ssrType)}`;
                  rowObj.ssrDescription = ssrDescription;
                  rowObj.ssrPrice = ssrPrice;
               }
               rowObj.ssrItems[subItem] = {
                  item: subItem,
                  itemDisplayValue: `${extraSsrs[item][subItem].value} ${extraSsrs[item][subItem].unit}`,
                  info: extraSsrs[item][subItem].info,
                  itemIdentifier: subItem,
                  selected: extraSsrs[item][subItem].selected,
                  price: extraSsrs[item][subItem].total,
               };
            });

            this.$set(this.generatedSsrList, item, rowObj);
         });
      },
   },
   watch: {
      ssrs: {
         handler: function (val) {
            if (val != undefined) {
               this.handlerGenerateSsrModels(this.ssrs);
            }
         },
         immediate: true,
         deep: true,
      },
   },
};
</script>

<style lang="scss" scoped>
$transitionAnim: all 0.15s ease-in-out;
.ssr-selection-container {
   margin: 0 3rem;
   margin-top: 3.5rem;

   h6 {
      display: block;
      width: 100%;
      text-align: center;
      margin-bottom: 1rem;
      font-size: var(--large);
      font-weight: 500;
   }
}
</style>
