<template>
   <div class="add-on-popover-c">
      <div class="add-on-info-wrapper">
         <i @mouseenter="handlerPopover(true)"></i>
         <div class="add-on-infos">
            <span class="add-on-label">{{ $t("hotelBasket_lblAddOnPrice") }}</span>
            <span class="add-on-price">{{ $options.filters.price(addonInformations.addOnTotal, addonInformations.addOnCurrency) }}</span>
         </div>
      </div>
      <div class="add-on-popover-wrapper" v-if="popover">
         <div class="close-btn-wrapper">
            <i class="gts-icon icon-close-rounded icon-s close-btn" @click="handlerPopover(false)"></i>
         </div>

         <template v-for="(addon, addonIndex) in AddonPopover">
            <div class="add-on-popover-content-wrapper" :key="addonIndex" v-if="addonIndex == activeAddOn">
               <!-- Image Gallery -->
               <div class="add-on-image-gallery-wrapper">
                  <div class="add-on-img-wrapper" :src="img" alt="" v-for="(img, imgIndex) in addon.images" :key="imgIndex">
                     <img :src="img" v-if="imgIndex == addon.activeImg" />
                  </div>
                  <template v-if="addon.images.length > 1">
                     <button class="btn-prev" @click="handlerImageGallery(-1, addon)">
                        <i class="gts-icon icon-arrow icon-s"></i>
                     </button>
                     <button class="btn-next" @click="handlerImageGallery(1, addon)">
                        <i class="gts-icon icon-arrow icon-s"></i>
                     </button>
                  </template>
               </div>

               <div class="add-on-informations-wrapper">
                  <span class="add-on-title">{{ addon.title }}</span>
                  <p class="add-on-content add-on-horizontal-view-item-content" v-html="addon.content"></p>

                  <div class="add-on-date-price-wrapper">
                     <span class="add-on-date">{{ addon.date }}</span>
                     <span class="add-on-price">{{ addon.price }}</span>
                  </div>

                  <div class="add-on-horizontal-view-item-info-box-outer-wrapper">
                     <template v-for="(item, itemIndex) in handlerInformationText(addon.disabledChildAges)">
                        <div class="add-on-horizontal-view-item-info-box-wrapper" :key="itemIndex">
                           <i class="info-icon"></i>
                           <span>{{ item.msg }}</span>
                        </div>
                     </template>
                  </div>
               </div>
            </div>
         </template>

         <div class="add-on-popover-footer-controller">
            <template v-for="(addon, addonIndex) in AddonPopover">
               <span class="addon-bullet" :class="{ active: addonIndex == activeAddOn }" @click="handlerActiveAddon(addonIndex)" :key="addonIndex"></span>
            </template>
         </div>
      </div>
   </div>
</template>

<script>
export default {
   name: "AddonPopover",
   props: {
      addonInformations: Object,
   },
   data() {
      return {
         popover: false,
         activeAddOn: 0,

         addOns: [],
      };
   },
   created() {
      function convertNewlinesToListItems(description) {
         // Split the description by newline characters
         const lines = description.split("\n");

         // Wrap each line in <li> tags
         const listItems = lines.map((line) => `<li>${line}</li>`).join("");

         // Wrap the list items in a <ul> tag
         return `<ul>${listItems}</ul>`;
      }
      this.addOns = this.addonInformations.addOns.map((addon) => {
         return {
            title: addon.title,
            activeImg: 0,
            images: [addon?.image1Url, addon?.image2Url].filter((url) => url !== null),
            content: convertNewlinesToListItems(addon.description),
            date: this.$options.filters.FormatterDate(addon.effectiveDate),
            price: this.$options.filters.price(addon.price, addon.currency),
            disabledChildAges: addon.disabledChildAges,
         };
      });
   },
   computed: {
      AddonPopover() {
         return this.addOns;
      },
   },
   methods: {
      handlerPopover(val) {
         this.popover = val;
         //  if (val) {
         //     this.popover = val;
         //  } else {
         //     setTimeout(() => {
         //        this.popover = val;
         //     }, 2000);
         //  }
      },
      handlerActiveAddon(index) {
         this.activeImg = 0;
         this.activeAddOn = index;
      },
      handlerImageGallery(val, img) {
         if (img.images.length === 0) {
            return;
         }
         img.activeImg += val;
         if (img.activeImg >= img.images.length) {
            img.activeImg = 0;
         } else if (img.activeImg < 0) {
            img.activeImg = img.images.length - 1;
         }
      },
      handlerInformationText(obj) {
         return obj.map((item) => {
            const ageRange = `${item.fromAge}-${item.toAge}`;
            let msg = "hotelRoomDetail_lblAddOnInfo";

            if (item.disabled) {
               msg += "Disabled";
            }
            if (item.free) {
               msg += "Free";
            }

            return {
               msg: this.$t(msg).replace("#AGE_RANGE#", ageRange),
            };
         });
      },
   },
};
</script>

<style lang="scss" scoped>
.add-on-popover-c {
   position: relative;
   padding-top: 0.5rem;
   padding-bottom: 0.25rem;
   .add-on-info-wrapper {
      display: flex;

      i {
         background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNC42MTQiIGhlaWdodD0iMjQuNjE0IiB2aWV3Qm94PSIwIDAgMjQuNjE0IDI0LjYxNCI+CiAgPGcgaWQ9Ikdyb3VwXzEyMDQ1IiBkYXRhLW5hbWU9Ikdyb3VwIDEyMDQ1IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMTMyMCAtNDQwKSI+CiAgICA8ZyBpZD0iR3JvdXBfMTE2MTAiIGRhdGEtbmFtZT0iR3JvdXAgMTE2MTAiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDEzMzAuNDg0IDQ0NS42OTkpIj4KICAgICAgPHBhdGggaWQ9IlBhdGhfMTAzMzMiIGRhdGEtbmFtZT0iUGF0aCAxMDMzMyIgZD0iTTE4LjMsMTQuODIzbC0zLjQ2My40MzQtLjEyNC41NzUuNjguMTI2Yy40NDQuMTA2LjUzMi4yNjYuNDM2LjcwOWwtMS4xMTYsNS4yNDVjLS4yOTMsMS4zNTYuMTU5LDEuOTk1LDEuMjIyLDEuOTk1YTMuMTMyLDMuMTMyLDAsMCwwLDIuMjE1LS45bC4xMzMtLjYyOWExLjY3NSwxLjY3NSwwLDAsMS0xLjAzOC4zNzJjLS40MTYsMC0uNTY3LS4yOTItLjQ2LS44MDZaIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMTQuNjI3IC0xMC4xNTMpIiBmaWxsPSIjZGEwMGU2Ii8+CiAgICAgIDxwYXRoIGlkPSJQYXRoXzEwMzM0IiBkYXRhLW5hbWU9IlBhdGggMTAzMzQiIGQ9Ik0xOC43NzUsOS4zODdhMS41MTIsMS41MTIsMCwxLDEtMS41MTItMS41MTJBMS41MTIsMS41MTIsMCwwLDEsMTguNzc1LDkuMzg3WiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTE0Ljk5MiAtNy44NzUpIiBmaWxsPSIjZGEwMGU2Ii8+CiAgICA8L2c+CiAgICA8cGF0aCBpZD0iRWxsaXBzZV8xMzE1IiBkYXRhLW5hbWU9IkVsbGlwc2UgMTMxNSIgZD0iTTEyLjMwNywxLjM4NUExMC45MjMsMTAuOTIzLDAsMCwwLDQuNTg0LDIwLjAzMSwxMC45MjMsMTAuOTIzLDAsMCwwLDIwLjAzMSw0LjU4NGExMC44NTEsMTAuODUxLDAsMCwwLTcuNzIzLTMuMm0wLTEuMzg1QTEyLjMwNywxMi4zMDcsMCwxLDEsMCwxMi4zMDcsMTIuMzA3LDEyLjMwNywwLDAsMSwxMi4zMDcsMFoiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDEzMjAgNDQwKSIgZmlsbD0iI2RhMDBlNiIvPgogIDwvZz4KPC9zdmc+Cg==");
         background-size: contain;
         background-repeat: no-repeat;
         width: 24px;
         height: 24px;
         margin-right: 0.5rem;
      }
      .add-on-infos {
         display: flex;
         flex-direction: column;
         align-items: flex-end;
         span {
            text-align: right;
            background-color: #feddff;
            color: #da00e6;
            display: inline-block;
            width: fit-content;
            padding: 0 0.25rem;

            &.add-on-label {
               margin-bottom: 0.25rem;
            }

            &.add-on-price {
               font-size: 1.25rem;
               font-weight: 900;
            }
         }
      }
   }
   .add-on-popover-wrapper {
      z-index: 100;
      position: absolute;
      background: #fff;
      border: 1px solid #e6e6e6;
      //       overflow: hidden;
      box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 12px -3px;
      //       padding: 1rem;
      border-radius: 1rem;
      min-width: 400px;
      right: 20px;
      //       margin-top: 0.5rem;

      .close-btn-wrapper {
         background: white;
         border-radius: 100%;
         padding: 0.25rem;
         position: absolute;
         right: 0;
         z-index: 99;
         right: 0.5rem;
         top: 0.5rem;
         cursor: pointer;
      }

      .add-on-popover-content-wrapper {
         padding: 1.25rem;
         //  padding-top: 2rem;
         //  border: 1px solid;

         .add-on-image-gallery-wrapper {
            position: relative;
            .add-on-img-wrapper {
               img {
                  border-radius: 0.5rem;
               }
            }

            button {
               background-color: #fff;
               position: absolute;
               top: 0;
               bottom: 0;
               height: 50px;
               margin: auto 0.5rem;
               border-radius: 0.5rem;
               border: 1px solid #eee;

               &.btn-prev {
                  i {
                     transform: rotate(180deg);
                  }
               }

               &.btn-next {
                  right: 0;
               }
            }
         }
         .add-on-informations-wrapper {
            .add-on-title {
               font-weight: 600;
               font-size: 1.45rem;
            }
            .add-on-date-price-wrapper {
               display: flex;
               justify-content: space-between;
               align-items: center;
               margin-top: 1rem;
               margin-bottom: 1rem;
               .add-on-date {
                  background-color: #feddff;
                  padding: 0 0.125rem;
                  font-size: 1.125rem;
                  font-weight: 700;
               }
               .add-on-price {
                  color: #da00e6;
                  font-size: 1.45rem;
                  font-weight: 700;
               }
            }
         }
      }
      .add-on-popover-footer-controller {
         background-color: #d546ec;
         padding: 1rem;
         display: flex;
         justify-content: center;
         align-items: center;
         border-bottom-left-radius: 1rem;
         border-bottom-right-radius: 1rem;

         .addon-bullet {
            cursor: pointer;
            display: inline-block;
            width: 36px;
            height: 12px;
            border-radius: 24rem;
            background-color: #fff;
            margin: 0 0.25rem;
            opacity: 0.6;

            &.active {
               opacity: 1;
            }
         }
      }
   }
}
</style>
