<template>
   <div class="ancillary-reissue-search-leg-body-main-container">
      <section class="reissue-search-leg-body-header-col">
         <img class="airline-logo" :src="handlerAirlineImg(flight.marketingAirlineCode)" alt="" @error="handlerUnknownImg($event)" width="32" />
         <div class="brand-wrapper ml-2">
            <span class="txt-bold"> {{ $t(`airlineEnum_${flight.marketingAirlineCode}`) }}</span>
            <span class="brand-name"> {{ flight.brandName }}</span>
         </div>
         <!-- <span class="ml-2 txt-bold"> {{ flight.marketingAirlineCode }}</span> -->
      </section>
      <section class="reissue-search-leg-body-header-col">
         <span>{{ flight.segments[0].departureDateTime | FormatterDate }}</span>
      </section>
      <section class="reissue-search-leg-body-header-col">
         <span> {{ flight.segments[0].flightNumber }} </span>
      </section>
      <section class="reissue-search-leg-body-header-col">
         <span class="txt-bold">{{ flight.segments[0].departureAirportCode }}</span>
      </section>
      <section class="reissue-search-leg-body-header-col">
         <span class="txt-bold">{{ flight.segments[flight.segments.length - 1].arrivalAirportCode }}</span>
      </section>
      <section class="reissue-search-leg-body-header-col">
         <span class="txt-bold">{{ flight.segments.length - 1 == 0 ? "nostop" : flight.segments.length - 1 }}</span>
      </section>
      <section class="reissue-search-leg-body-header-col">
         <span class="time-departure">{{ flight.segments[0].departureDateTime | FormatterTime }}</span>
      </section>
      <section class="reissue-search-leg-body-header-col">
         <span class="time-arrival">{{ flight.segments[flight.segments.length - 1].arrivalDateTime | FormatterTime }}</span>
      </section>
      <section class="reissue-search-leg-body-header-col" v-if="hasSelection">
         <button class="reissue-cancel-selection">{{ $t("ancillariesServicesModal_lblCancelSelection") }}</button>
      </section>
   </div>
</template>

<script>
export default {
   name: "AncillaryReissueSearchLegBodyCardMain",
   props: {
      flight: Object,
      hasSelection: Boolean,
   },
   methods: {
      handlerAirlineImg(airlineLogo) {
         return `${this.$constants.cdn}/airline_34x34/${airlineLogo}.svg`;
      },
      handlerUnknownImg(ev) {
         ev.target.src = `${this.$constants.cdn}/airline_34x34/00.svg`;
      },
   },
};
</script>

<style lang="scss" scoped>
.ancillary-reissue-search-leg-body-main-container {
   // display: grid;
   // grid-template-columns: 1.5fr repeat(7, 1fr);
   // padding: 0.25rem 0;
   // padding-top: 0.5rem;
   position: relative;

   .brand-wrapper {
      display: flex;
      flex-direction: column;
      align-items: left;
      white-space: nowrap;
      .brand-name {
         font-size: var(--xsmall);
         opacity: 0.5;
         // position: absolute;
         // background-color: #363636;
         // color: #fff;

         // padding: 0.125rem 0.5rem;
         // border-radius: 24rem;
         // // left: 0;
         // top: 0;
         // right: 0;
      }
   }

   button.reissue-cancel-selection {
      background-color: #ffffff;
      border: 1px solid #dfe0e6;
      white-space: nowrap;
      border-radius: 0.5rem;
      padding: 0.5rem 0.5rem;
      color: #5e5e5e;
      font-weight: 600;

      &:hover {
         background-color: #363636;
         color: #fff;
      }
   }
}
</style>
