const gtsGridPdfStyle = `
body {
    padding: 0;
    margin: 0;
    background: #fff;
}

body table {
    display:table!important;
    font-family: sans-serif;
    width: 100%;
    border-collapse: collapse;
}

body table thead {
    background: #266cbf;
}

body table thead tr th {
    color: #fff;
    padding: 0.75rem 0.5rem;
    border: 1px dotted #ccc;
    font-size: 12px;
    text-align: left;
}

body table tbody tr td {
    color: black;
    padding: 0.25rem 0.5rem;
    border: 1px dotted #ccc;
    font-size: 12px;
}

body table tbody tr td span {
    display: block;
}

body table tbody tr td span.pnr-badge {
    border: 1px solid #eee;
    padding: 0.25rem;
    border-radius: 24rem;
    text-align: center;
    white-space: nowrap;
}

body table tbody tr td a {
    text-decoration: none;
    color: black;
}

body table tbody tr:nth-child(even) {
    background: #f1f1f1;
}
/*# sourceMappingURL=style.css.map */
`;

export default gtsGridPdfStyle;
