<template>
   <div>
      <div class="order-error-container" v-for="(order, index) in orderError" :key="index">
         <div class="order-error-inner-container">
            <span>{{ $t(`RESPONSE_${order.app}_${order.service}_${order.code}_${order.subCode}`) }}</span>
            <div class="order-error-informations">
               <span>{{ order.dateTime | FormatterDD_MMMM_YYYY }}</span> -

               <span>{{ order.email }}</span>
            </div>
         </div>
      </div>
   </div>
</template>

<script>
export default {
   name: "OrderError",
   props: {
      orderError: Array,
   },
};
</script>

<style lang="scss" scoped>
.order-error-container {
   border: 1px solid #c8a6de;
   background: #f6edfb;
   border-radius: 0.5rem;
   padding: 0.75rem 1rem;
   margin-top: 1.5rem;

   .order-error-informations {
      span {
         font-weight: 600;
      }
   }
}
</style>
