<template>
   <div :id="[newClass ? newId : 'checkout-contact-container']" :class="[isBorderavailable ? 'gts-box with-border' : '']">
      <div class="checkout-contact-header">
         <span class="section-title" v-show="isBorderavailable">{{ $t("checkoutContact_contactInformations") }} </span>
         <!--Flight Autofill wrapper -->
         <div id="autofill-wrapper" v-if="isflight">
            <label v-if="fillWithFirstPax">{{ $t("checkoutContact_bntFillWithFirstPaxInfo") }}</label>
            <label v-else>{{ $t("checkoutContact_bntFillWithAgentInfo") }}</label>

            <b-switch v-model="fillWithFirstPax"> </b-switch>
         </div>
      </div>

      <!--Flight Checkout Contact -->
      <div class="checkout-contact-wrapper">
         <div class="contact-input">
            <UISelectBox
               :label="$t('common_countryPhoneCode')"
               id="orderSelect"
               :options="CountryOptionList"
               v-model="contact.phone.country"
               allow-search
               has-icons />
         </div>
         <div class="contact-input">
            <UIInput
               v-model="contact.phone.city"
               custom-class="g-short-input"
               :placeholder="$t('common_city')"
               :label="$t('common_city')"
               v-input="{ type: 'numeric', len: [2, 4], required: true, requiredMsg: $t('common_cannotBeEmpty') }" />
         </div>
         <div class="contact-input">
            <UIInput
               v-model="contact.phone.number"
               :label="$t('common_phone')"
               :placeholder="$t('common_phone')"
               v-input="{ type: 'numeric', len: [3, 8], required: true, requiredMsg: $t('common_cannotBeEmpty') }" />
         </div>
         <div class="contact-input" v-if="isShowMail">
            <UIInput
               v-model="contact.email"
               :label="$t('common_email')"
               :placeholder="$t('common_email')"
               lowercase
               v-input="{ type: 'email', len: [3, 320], required: true, requiredMsg: $t('common_cannotBeEmpty') }" />
         </div>
      </div>
      <div class="checkout-contact-information-wrapper" v-show="isflight">
         <p v-if="fillWithFirstPax">* {{ $t("checkoutContact_lblFilledWithFirstPaxInfos") }}</p>
         <p v-else>* {{ $t("checkoutContact_lblFilledWithAgentInfos") }}</p>
         <p>* {{ $t("checkoutContact_informationText") }}</p>
      </div>
   </div>
</template>

<script>
import debounce from "lodash.debounce";
import UISelectBox from "@/components/unitary/form2/UISelectBox.vue";
import UIInput from "@/components/unitary/form2/UIInput.vue";
export default {
   name: "CommonCheckoutContact",
   components: {
      UISelectBox,
      UIInput,
   },
   props: {
      isShowAutofill: Boolean,
      isRentaCar: Boolean,
      isBorderavailable: Boolean,
      isCustomClass: Boolean,
      newClass: String,
      newId: String,
      isShowMail: Boolean,
      isflight: Boolean,
   },
   data() {
      return {
         isThisFlight: this.isflight,
         fillWithFirstPax: false,
         defaultContact: {
            filled: false,
            email: null,
            phone: {},
         },
         countriesObj: [],
         phone: null,
         email: null,
         contact: {
            phone: {
               country: "+49",
               city: "",
               number: "",
               iso: "",
            },
            email: "",
         },
      };
   },
   computed: {
      CountryOptionList() {
         const self = this;
         return self.countriesObj;
      },
   },
   methods: {
      handlerUpdateContact: debounce(function () {
         if (this.isThisFlight) {
            this.$store.state.flight.checkout.request.contact.phone = this.phone;
            this.$store.state.flight.checkout.request.contact.email = this.email;

            this.contact.phone.country = this.phone.country;
            this.contact.phone.city = this.phone.city;
            this.contact.phone.number = this.phone.number;
            this.contact.email = this.email;
         }
      }),
      handlerFillWithFirstPax() {
         if (this.isThisFlight) {
            const paxInformations = this.$store.state.flight.checkout.request?.passengers[0];

            if (paxInformations.phone != null || paxInformations.email != null) {
               this.phone = paxInformations.phone;
               this.email = paxInformations.email;

               this.contact.phone.country = this.phone.country;
               this.contact.phone.city = this.phone.city;
               this.contact.phone.number = this.phone.number;
               this.contact.email = this.email;
            }
         }
      },
      handlerFillWithAgentInfo() {
         if (this.isThisFlight) {
            // const paxInformations = this.$store.state.flight.checkout.request.contact;
            this.phone = this.defaultContact.phone;
            this.email = this.defaultContact.email;
         }
         // console.log("handlerFillWithAgentInfo", paxInformations);
      },
   },

   watch: {
      fillWithFirstPax: {
         handler: function (status) {
            if (this.isThisFlight) {
               if (status) {
                  this.handlerFillWithFirstPax();
               } else {
                  this.handlerFillWithAgentInfo();
               }
            }
         },
      },
      "$store.state.flight.checkout.request.contact": {
         handler: function (contact) {
            if (this.isThisFlight) {
               const self = this;
               self.phone = contact?.phone;
               self.email = contact?.email;

               if (!this.defaultContact.filled) {
                  this.defaultContact.email = contact?.email;
                  this.defaultContact.phone = contact?.phone;
                  if (contact?.email || contact?.phone) {
                     this.defaultContact.filled = true;
                  }
               }
            }
         },
         deep: true,
         immediate: true,
      },
      phone: {
         handler: function () {
            if (this.isThisFlight) {
               this.handlerUpdateContact();
            }
         },
         deep: true,
      },
      email: {
         handler: function () {
            if (this.isThisFlight) {
               this.handlerUpdateContact();
            }
         },
         deep: true,
      },
      contact: {
         handler: function (newValue) {
            let iso = this.CountryOptionList.find((item) => {
               return item.value == newValue.phone.country;
            });

            const rowObj = {
               city: newValue.city,
               email: newValue.email,
               number: newValue.number,
               phone: {
                  city: newValue.phone.city.numeric(),
                  country: newValue.phone.country,
                  number: newValue.phone.number,
                  iso: iso.icon.toUpperCase(),
               },
            };
            if (this.isThisFlight) {
               this.$store.state.flight.checkout.request.contact.phone = rowObj.phone;
               this.$store.state.flight.checkout.request.contact.email = rowObj.email;
            }
            this.$emit("update-to-contact", rowObj);
         },
         deep: true,
      },
      "$store.state.app.countryList": {
         handler: function (countries) {
            const self = this;

            countries.forEach((country) => {
               const rowObj = {
                  value: country.phoneCode,
                  name: `${country.phoneCode} (${country.nameTr})`,
                  icon: country.code,
               };
               self.countriesObj.push(rowObj);
            });
         },
         immediate: true,
      },
   },
};
</script>

<style lang="scss" scoped>
.checkout-contact-header {
   display: flex;
   justify-content: space-between;
}
#autofill-wrapper {
   background: #f6f5f5;
   padding: 0.5rem 1rem;
   border-radius: 0.5rem;
   border: 1px solid #cecece;
   display: flex;
   align-items: center;

   label {
      margin-right: 0.5rem;
      font-weight: 500;
      color: #363636;
      font-size: 14.5px;
   }
}
#checkout-contact-container {
   .checkout-contact-wrapper {
      display: flex;
      flex-direction: flex;

      @media (min-width: 576px) {
         display: grid;
         grid-template-columns: 1fr 0.75fr 1.25fr 1.25fr;
         width: 70%;
      }
      @media (min-width: 768px) {
         width: 100%;
      }
      @media (min-width: 992px) {
         width: 80%;
      }

      .contact-input {
         margin: 0 0.5rem;
         display: flex;

         @media (min-width: 576px) {
            margin-bottom: 0;
         }

         ::v-deep {
            .g-input-container {
               width: 100%;
            }
         }

         ::v-deep .form-item {
            input {
               position: relative;
               display: flex;
               justify-content: flex-start;
               align-items: center;
               border: 1px solid #b1b2b3;
               border-radius: 0.5rem;
               box-sizing: border-box;
               background: #fff;
               height: 48px !important;
               max-height: 100% !important;
               border-width: 2px;
               outline: transparent solid 2px;
               outline-offset: 2px;
               width: 100%;
               text-indent: 1rem;
               font-size: var(--normal);

               &.g-short-input {
                  width: 80px !important;
               }
            }
         }
      }
   }
   @media (max-width: 767.98px) {
      .checkout-contact-wrapper {
         display: block;
         .contact-input {
            display: block;
         }
      }
   }
   .checkout-contact-information-wrapper {
      margin-top: 2rem;
      margin-left: 0.5rem;
      p {
         font-size: var(--small);
         color: var(--txt-black);
      }
   }
}
#rent-a-car-driver-informations-container {
   position: relative;
   .customer-management-wrapper {
      position: absolute;
      z-index: 99;
      width: 100%;
      border: 1px solid #ccc;
      border-radius: 1rem;
      right: 0;
      box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;

      @media (min-width: 992px) {
         width: 800px;
      }
   }
   .form-container {
      // background: red;
      display: flex;
      flex-wrap: wrap;
      width: 90%;
      @media (max-width: 576px) {
         width: 100%;
      }

      .form-wrapper {
         width: 10%;
         margin-bottom: 1.25rem;

         @media (max-width: 576px) {
            width: 100%;
            padding: 0 !important;
            // border: 1px solid red !important;
         }

         ::v-deep {
            .g-input-container {
               .gts-input-wrapper {
                  input {
                     background: #f7f9fc;
                     border: 2px solid #cacaca;
                  }
               }
            }
         }

         &:last-child {
            width: 100%;

            textarea {
               width: 100%;
               height: 106px;
               background: #f7f9fc;
               border: 2px solid #cacaca;
               border-radius: 0.5rem;
               padding: 0.5rem 1rem;
            }
         }

         &.phone {
            display: flex;
            width: 100%;
            ::v-deep {
               .g-select-box-container {
                  width: 142px;
                  margin-right: 1rem;
               }
               .g-input-container {
                  &:last-child {
                     margin-left: 1rem;
                  }
               }
            }
         }
      }
   }
}
</style>
