<template>
   <div class="traveler-row-container" v-click-outside="handlerFillCustomer" ref="travelerRow">
      <section class="customer-management-wrapper" v-if="showFihrist">
         <CustomerManagement ref="customerListModal" @set-customer="handlerSetCustomer" show-close />
      </section>
      <div class="traveler-row-inner-wrapper">
         <!-- TRAVELER HEADER WITH TRAVELER TYPE -->
         <div class="traveler-row-header">
            {{ traveler.index }}. {{ $t("common_passenger") }}
            {{ traveler.type | ETrfTravelerType }}
         </div>

         <div class="traveler-row-basic">
            <div class="traveler-row-wrapper">
               <!-- Gender Selection -->
               <div class="traveler-column">
                  <UISelectBox :options="GenderList" v-model="traveler.gender" light-theme />
               </div>

               <!-- Traveler Name -->
               <div class="traveler-column">
                  <UIInput
                     v-model="traveler.name"
                     @set-customer="handlerSetCustomer"
                     :customers="customerList"
                     ac-tag="name"
                     :placeholder="$t('common_name')"
                     autocomplete
                     v-input="{ type: 'alphabetic', len: [2, 30], required: true, requiredMsg: $t('common_cannotBeEmpty') }" />
               </div>

               <!-- Traveler Surname -->
               <div class="traveler-column">
                  <UIInput
                     v-model="traveler.surname"
                     @set-customer="handlerSetCustomer"
                     :customers="customerList"
                     ac-tag="surname"
                     :placeholder="$t('common_surname')"
                     autocomplete
                     v-input="{ type: 'alphabetic', len: [2, 30], required: true, requiredMsg: $t('common_cannotBeEmpty') }" />
               </div>

               <!-- <div class="pax-column">
                  <UIDatePicker
                     v-model="traveler.birthdate"
                     :placeholder="$t('common_birthday')"
                     :min="DatePickerControl.min"
                     :max="DatePickerControl.max"
                     :class="[traveler.birthdate.length != 10 ? '' : 'g-form-valid']"
                     v-required />
               </div> -->

               <!-- Pax Action -->

               <div class="pax-column pax-action">
                  <button class="pax-row-add-customer" @click="triggerCustomerListModal">
                     <i class="gts-icon icon-user-search"></i>
                  </button>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>
import CustomerManagement from "@/components/structural/flight/common/CustomerManagement.vue";
// import UIDatePicker from "@/components/unitary/form2/UIDatePicker2.vue";
import UISelectBox from "@/components/unitary/form2/UISelectBox.vue";
import UIInput from "@/components/unitary/form2/UIInput.vue";
import moment from "moment";
import { customerService } from "@/service/index.js";
export default {
   name: "TravelerRow",
   components: {
      UISelectBox,
      UIInput,
      // UIDatePicker,
      CustomerManagement,
   },
   props: {
      traveler: Object,
      pickupDateTime: Date,
   },
   data() {
      return {
         pickUpDate: null,
         details: false,
         customerList: [],
         showFihrist: false,
         customerEdit: {
            status: false,
            watcher: null,
         },
         inputsDisabled: false,
      };
   },
   created() {
      const self = this;
      customerService.listCustomers({ status: 1 }).then((res) => {
         self.customerList = res.customers;
      });
      self.traveler.gender = 1;
      self.traveler.birthdate = moment(self.traveler.birthdate).format("YYYY-MM-DD");
   },
   computed: {
      GenderList() {
         const self = this;
         return [
            { name: self.$i18n.t("common_male"), value: 1 },
            { name: self.$i18n.t("common_female"), value: 2 },
         ];
      },
      CountryList() {
         const self = this;
         let activeLanguage = self.$store.state.user.user.language;

         let selectorStr = "";
         switch (activeLanguage) {
            case 1:
               selectorStr = "Tr";
               break;

            case 2:
               selectorStr = "En";
               break;

            case 3:
               selectorStr = "De";
               break;

            default:
               selectorStr = "En";
               break;
         }

         return self.$store.state.app.countryList.map((data) => {
            var obj = {
               name: data[`name${selectorStr}`],
               value: data.code.toUpperCase(),
               icon: data.code,
            };
            return obj;
         });
      },
      DatePickerControl() {
         const self = this;
         switch (self.traveler.type) {
            case 2:
               //child
               return {
                  min: moment(self.pickupDateTime).subtract(12, "years").add(1, "day").format(this.$constants.dateFormat),
                  max: moment(self.pickupDateTime).subtract(2, "years").subtract(1, "day").format(this.$constants.dateFormat),
               };
            case 3:
               //infant
               return {
                  min: moment(self.pickupDateTime).subtract(2, "years").add(1, "day").format(this.$constants.dateFormat),
                  max: moment(new Date()).subtract(1, "day").format(this.$constants.dateFormat),
               };

            default:
               return {
                  min: moment(self.pickupDateTime).subtract(100, "years").format(this.$constants.dateFormat),
                  max: moment(self.pickupDateTime).subtract(12, "years").subtract(1, "day").format(this.$constants.dateFormat),
               };
         }
      },
   },
   methods: {
      triggerCustomerListModal() {
         const self = this;
         self.showFihrist = true;
      },
      handlerFillCustomer() {
         const self = this;
         if (self.traveler.name != null || self.traveler.surname != null) {
            self.traveler.name;
            self.traveler.surname;
         }
      },
      handlerSetCustomer(customer) {
         const self = this;

         if (this.customerEdit.watcher != null) {
            this.customerEdit.watcher();
         }

         self.traveler.id = customer.id;
         self.traveler.gender = customer.gender;
         self.traveler.birthdate = moment(customer.birthdate).format("YYYY-MM-DD");
         self.traveler.name = customer.name?.alphabetic();
         self.traveler.surname = customer.surname?.alphabetic();
         self.traveler.nationality = customer.nationality;
         self.traveler.nationalIdentity = customer.nationalIdentity;
         self.traveler.passport = customer.passport;
         self.traveler.passport.country = customer.nationality;
         self.traveler.hesCode = customer.hesCode;
         self.traveler.loyaltyCards = customer.loyaltyCards;
      },
   },
   watch: {
      "traveler.birthdate": {
         handler: function (birthdate) {
            this.traveler.birthdate = moment(birthdate).format("YYYY-MM-DD");
         },
      },
   },
};
</script>

<style lang="scss" scoped>
.traveler-row-container {
   display: flex;
   flex-direction: column;
   position: relative;
   margin: 0.5rem 0;

   .customer-management-wrapper {
      position: absolute;
      z-index: 99;
      width: 800px;
      border: 1px solid #ccc;
      border-radius: 1rem;

      right: 0;
      box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
   }
   .traveler-row-inner-wrapper {
      background: #f8f8f8;
      border: 1px solid #ccc;
      margin: 0.5rem 0;
      padding: 1rem;
      padding-top: 1.5rem;
      border-radius: 0.5rem;
      position: relative;

      .traveler-row-header {
         position: absolute;
         top: -1rem;
         left: 1rem;
         background: #fff;
         border: 1px solid #ccc;
         border-radius: 16px;
         padding: 0.25rem 1rem;
         font-size: var(--small);
         font-weight: 600;
      }

      .traveler-row-basic,
      .traveler-row-detail {
         .traveler-row-wrapper {
            display: grid;
            grid-template-columns: 1fr;
            padding: 0.75rem 0;

            @media (min-width: 576px) {
               grid-template-columns: repeat(5, calc(100% / 5.7)) 10%;
            }
            .traveler-column {
               position: relative;
               margin: 0 0.5rem;
               margin-bottom: 0.5rem;

               @media (min-width: 576px) {
                  margin-bottom: 0rem;
               }

               .traveler-row-detail-toggle {
                  border-radius: 50%;
                  width: 38px;
                  height: 38px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
               }
               .traveler-row-add-customer {
                  background: none !important;
               }

               &.traveler-action {
                  display: flex;
                  align-items: center;
                  justify-content: flex-end;
                  margin-left: 0.5rem;
                  // background: #ccc;

                  @media (min-width: 576px) {
                     justify-content: space-around;
                     width: 100%;
                  }
               }
            }
         }
      }
   }
}

.pax-action {
   display: flex;
   margin-left: 1rem;
   button {
      background: none !important;
   }
}
</style>
