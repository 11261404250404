<template>
   <div id="language-control-box-wrapper" @mouseover="dropdownBoxActive = true" @mouseleave="dropdownBoxActive = false">
      <div id="language-dropdown">
         <span class="active-language">{{ activeLanguage }}</span>
         <i class="gts-icon icon-arrow icon-xs"></i>
      </div>
      <div id="language-dropdown-box" v-if="dropdownBoxActive">
         <ul>
            <li
               v-for="(lang, index) in languages"
               :key="index"
               :data-lang="lang"
               :class="activeLanguage == lang ? 'active-item' : ''"
               @click="setLanguage(lang)">
               <i :class="`flag-icon flag-icon-${lang.toLowerCase()}`"></i>
               <span>{{ lang }}</span>
            </li>
         </ul>
      </div>
   </div>
</template>

<script>
import i18n from "@/fmt/i18n.js";
// import { globalFmt } from "@/fmt/languages";
import { globalService, TokenService } from "@/service/index.js";
import { generateUuid } from "@/utils.js";

export default {
   name: "LanguageControlBox",
   data() {
      return {
         activeLanguage: null,
         languages: Object.keys(i18n.messages),
         dropdownBoxActive: false,
      };
   },

   methods: {
      setLanguage(lang) {
         const self = this;
         let setWorkingParamsRequestModel = {
            onBehalfOfAgencyId: self.$store.state.user.user.onBehalfOfs.agencyOnBehalfOfId,
            onBehalfOfGsaId: self.$store.state.user.user.onBehalfOfs.gsaOnBehalfOfId,
            language: null,
            uuid: generateUuid(),
         };

         switch (lang) {
            case "tr":
               setWorkingParamsRequestModel.language = 1;
               break;

            case "en":
               setWorkingParamsRequestModel.language = 2;
               break;

            case "de":
               setWorkingParamsRequestModel.language = 3;
               break;

            default:
               setWorkingParamsRequestModel.language = 1;
               break;
         }

         globalService.setWorkingParams(setWorkingParamsRequestModel).then((response) => {
            if (response.result.success) {
               TokenService.setJwtInfo(response.jwt, response.jwtDuration, response.jwtRefreshToken);

               this.$store.state.user.user.language = setWorkingParamsRequestModel.language;
               self.activeLanguage = lang;
               this.$i18n.locale = lang;
               if (this.$route.name == "FlightCheckout") {
                  window.location.reload();
               }
            }
         });
         // globalFmt();
         self.dropdownBoxActive = false;
      },
   },
   watch: {
      "$store.state.user.user.language": {
         handler: function (language) {
            const self = this;
            switch (language) {
               case 1:
                  self.activeLanguage = "tr";
                  break;

               case 2:
                  self.activeLanguage = "en";
                  break;

               case 3:
                  self.activeLanguage = "de";
                  break;

               default:
                  self.activeLanguage = "en";
                  break;
            }
         },
         immediate: true,
      },
   },
};
</script>

<style lang="scss" scoped>
#language-control-box-wrapper {
   display: none;
   flex-direction: column;
   position: relative;
   border: 1px solid #ccc;
   border-top: none;
   border-bottom: none;
   height: 100%;
   justify-content: center;
   align-items: center;
   padding: 0 2rem;
   margin: 0 1.5rem;
   cursor: pointer;

   @media (min-width: 768px) and (max-width: 991.98px) {
      padding: 0 1rem;
      margin: 0 1rem;
   }
   @media (min-width: 576px) {
      display: flex;
   }

   #language-dropdown {
      display: flex;
      flex-direction: row;
      align-items: center;

      span.active-language {
         text-transform: uppercase;
         min-width: 30px !important; // avoid shake
         font-size: 1rem;
         text-align: center;
      }

      i {
         transform: rotate(90deg);
         margin-left: 0.5rem;
      }
   }
   @keyframes dropdown {
      from {
         opacity: 0;
         top: 110%;
      }
      to {
         opacity: 1;
         top: 100%;
      }
   }
   #language-dropdown-box {
      animation-name: dropdown;
      animation-timing-function: ease-in-out;
      animation-duration: 0.15s;
      position: absolute;
      left: 0;
      right: 0;
      border-radius: 8px;
      background-clip: padding-box;
      background-color: #fff;
      top: 100%;
      box-shadow: rgba(0, 0, 0, 0.2) 0px 10px 30px 0px;
      &:before {
         content: "";
         position: absolute;
         top: -6px;
         left: 50%;
         width: 0;
         height: 0;
         -webkit-transform: translateX(-50%);
         -ms-transform: translateX(-50%);
         transform: translateX(-50%);
         border-right: 6px solid transparent;
         border-bottom: 6px solid #fff;
         border-left: 6px solid transparent;
      }
      ul {
         padding: 0.75rem 0;
         li {
            text-transform: uppercase;
            padding: 0.5rem 1rem;
            cursor: pointer;
            display: flex;

            &.active-item {
               font-weight: 800;

               &:hover {
               }
            }

            &:hover {
               background-color: rgba(13, 12, 34, 0.05);
            }

            span {
               margin-left: 0.75rem;
            }
            i {
               transform: scale(1.2);
            }
         }
      }
   }
}
</style>
