<template>
   <div class="flight-infographic-container">
      <div class="flight-infographic-wrapper">
         <div class="flight-infographic-segment-container">
            <template v-if="connection > 1">
               <template v-for="(segment, key, index) in segments">
                  <div
                     class="flight-infographic-segment-wrapper"
                     :key="index"
                     v-if="parseInt(index) == 0 || parseInt(index) == parseInt(Object.keys(segments).length) - 1">
                     <div class="airport-name-wrapper" :class="segment.hasCityTransfer ? 'city-transfer' : ''">
                        <span class="airport-name" v-if="segment.hasCityTransfer">{{ segment.airport2 }}</span>
                        <span class="airport-name">{{ segment.airport }}</span>
                     </div>
                     <span class="location-mark"></span>

                     <div class="time-wrapper" :class="segment.time2 ? 'connection-time' : ''">
                        <span class="time">{{ segment.time }}</span>
                        <span class="time">{{ segment.time2 }}</span>
                     </div>
                  </div>

                  <div
                     class="seperator-connection-info"
                     :key="index + key"
                     v-if="parseInt(index) == 0"
                     @mouseenter="showConnections = true"
                     @mouseleave="showConnections = false">
                     <div class="connection-info-wrapper">
                        <span class="connection-info-rounded" v-if="!showConnections">{{ connection }}</span>

                        <template v-else>
                           <span class="connection-info">{{ connection }} Aktarma</span>
                           <ul class="connection-list">
                              <template v-for="(segment, key, connectionIndex) in segments">
                                 <li
                                    :key="connectionIndex"
                                    v-if="parseInt(connectionIndex) != 0 && parseInt(connectionIndex) != parseInt(Object.keys(segments).length) - 1">
                                    {{ segment.airport }}
                                 </li>
                              </template>
                           </ul>
                        </template>
                     </div>
                  </div>
               </template>
            </template>

            <template v-else>
               <template v-for="(segment, key, index) in segments">
                  <div class="flight-infographic-segment-wrapper" :class="'connection-' + index" :key="index">
                     <div class="airport-name-wrapper" :class="segment.hasCityTransfer ? 'city-transfer' : ''">
                        <span class="airport-name" v-if="segment.hasCityTransfer">{{ segment.airport2 }}</span>
                        <span class="airport-name" :class="segment.highlighted ? 'highlighted-airport-name' : ''">{{ segment.airport }}</span>
                     </div>
                     <span class="location-mark"></span>

                     <div class="time-wrapper" :class="segment.time2 ? 'connection-time' : ''">
                        <span class="time">{{ segment.time }}</span>
                        <span class="time">{{ segment.time2 }}</span>
                     </div>
                  </div>

                  <div class="seperator-container" :key="index + key" v-if="parseInt(index) != parseInt(Object.keys(segments).length) - 1">
                     <span class="flight-number">{{ segment.flight }}</span>
                     <div class="flight-icon-container">
                        <i class="gts-icon icon-flight-icon icon-sm"></i>
                     </div>
                  </div>
               </template>
            </template>
         </div>
      </div>

      <div class="waiting-time-wrapper" :class="!transferTime ? '' : 'show-transfer-time'">
         <span class="waiting-time-txt"> {{ $t("searchComponentFlight_waitingTime") }} : </span>
         <span class="waiting-time">{{ transferTime }}</span>
      </div>
   </div>
</template>

<script>
export default {
   name: "FlightCardInfoGraphic",
   props: {
      segments: {
         type: Object,
         required: true,
      },
      transferTime: [String, Boolean],
      connection: Number,
   },
   data() {
      return {
         showConnections: false,
      };
   },
};
</script>

<style lang="scss" scoped>
.flight-infographic-container {
   width: 100%;
   margin: 0 1rem;
   margin-left: 0;
   max-width: 100%;
   @media (min-width: 992px) {
      margin: 0 1rem;
   }
   @media (min-width: 1400px) {
      margin: 0 1.5rem;
   }

   .flight-infographic-wrapper {
      position: relative;
      display: flex;
      justify-content: space-between;
      width: 100%;

      &:before {
         content: "";
         position: absolute;
         top: 0;
         bottom: 0;
         left: 0;
         right: 0;
         margin: auto;
         height: 2px;
         width: 100%;
         background-color: var(--secondary);
      }
      .flight-infographic-segment-container {
         width: 100%;
         display: flex;
         justify-content: space-between;
         .flight-infographic-segment-wrapper {
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;
            margin: 0 0.5rem;
            width: fit-content;
            @media (max-width: 1800px) {
               margin: 0;
            }

            &:first-child {
               margin-left: 0;
               padding: 0;
               align-items: flex-start;
            }
            &:last-child {
               margin-right: 0;
               padding: 0;
               align-items: flex-end;
            }
            span.flight-number {
               position: absolute;
               right: 0;
            }
            .airport-name-wrapper {
               display: flex;

               &.city-transfer {
                  span {
                     color: var(--secondary) !important;
                     margin: 0.25rem !important;
                     font-weight: 500 !important;
                  }
               }
               span.airport-name {
                  margin: 0.25rem 0;
                  min-height: 32px;

                  &.highlighted-airport-name {
                     color: var(--accent-primary);
                  }
               }
               span.airport-name {
                  display: flex;
                  flex-direction: column;
                  justify-content: flex-end;
                  font-size: var(--small);
                  color: var(--txt-gray);
                  font-weight: 400;
               }
            }
            .time-wrapper {
               display: flex;
               min-height: 32px;
               margin: 0.25rem 0;

               &.connection-time {
                  display: flex;
                  padding-top: 0.25rem;

                  span.time {
                     margin: 0 0.25rem;
                     font-size: var(--small);
                     &:first-child {
                        margin-left: 0 !important;
                     }
                     &:last-child {
                        margin-right: 0 !important;
                     }
                  }
               }
               span.time {
                  font-size: 1.2rem;
                  font-weight: 500;
               }
            }
            @keyframes locationMark {
               0% {
                  transform: scale(1);
               }
               100% {
                  transform: scale(1.2);
               }
            }
            span.location-mark {
               $locationMark: 14px;
               width: $locationMark;
               height: $locationMark;
               background-color: var(--secondary-lighten);
               border-radius: 50%;
               display: flex;
               align-items: center;
               justify-content: center;
               animation-name: locationMark;
               animation-duration: 1s;
               animation-timing-function: ease-in-out;
               animation-iteration-count: infinite;
               animation-direction: alternate;

               &:before {
                  $locationMark: 8px;
                  width: $locationMark;
                  height: $locationMark;
                  background-color: var(--secondary);
                  content: "";
                  border-radius: inherit;
               }
            }
         }
         .seperator-container {
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;
            .flight-number {
               font-size: var(--xsmall);
               color: var(--txt-ghost);
               font-weight: 500;
            }
            .flight-icon-container {
               position: absolute;
               top: 0;
               bottom: 0;
               margin: auto;
               height: fit-content;
               background: #f5f5f9;
               padding: 0.25rem;
               transition-property: all;
               transition-timing-function: ease-in-out;
               transition-duration: 1ms;
               i {
               }
            }
         }
         .seperator-connection-info {
            position: relative;
            width: 100%;
            .connection-info-wrapper {
               background-color: var(--secondary);
               color: #fff;
               padding: 0.25rem 0.5rem;
               font-weight: 500;
               position: absolute;
               top: 0;
               bottom: 0;
               right: 0;
               left: 0;
               margin: auto;
               width: fit-content;
               white-space: nowrap;
               height: fit-content;
               border-radius: 1rem;
               outline: 4px solid var(--secondary-lighten);

               .connection-info-rounded {
                  color: #fff;
                  font-weight: 600;
               }
               span.connection-info {
                  width: 100%;
                  text-align: center;
                  display: block;
                  font-size: var(--xsmall);
               }

               ul.connection-list {
                  display: flex;
                  li {
                     font-size: var(--small);
                     font-weight: 500;
                     margin: 0 0.25rem;
                  }
               }
            }
         }
      }
   }

   .waiting-time-wrapper {
      display: flex;
      justify-content: center;
      visibility: hidden;

      &.show-transfer-time {
         visibility: visible !important;
      }

      span.waiting-time-txt,
      span.waiting-time {
         font-size: var(--small);
         color: var(--txt-gray);
      }
      span.waiting-time-txt {
         font-weight: 500;
      }
      span.waiting-time {
      }
   }
}
</style>
