const state = {
   orderUuid: "",
};

const mutations = {
   updateOrderUuid(state, data) {
      state.orderUuid = data;
   },
};

const actions = {
   updateOrderUuid: function (commit, data) {
      commit("updateOrderUuid", data);
   },
};

const getters = {
   getOrderUuid: (state) => state.orderUuid,
};

export const order = {
   namespaced: true,
   state,
   mutations,
   actions,
   getters,
};
