import { PRIVATE_HTTP } from "./index";
import router from "@/router/index.js";

const SALE_LISTTASK_ENDPOINT = "api/task/listtasks";
const SALE_GETTASK_ENDPOINT = "api/task/gettask";
const SALE_ADDTASK_ENDPOINT = "api/task/addtask";

export var listTasks = (requestModel) => {
    const promise = PRIVATE_HTTP().post(SALE_LISTTASK_ENDPOINT, requestModel);
    const responseData = promise
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            if (error.response.status === 401) {
                if (router.currentRoute.path != "/login") {
                    router.push("/login");
                }
            } 
            // else 
            // {
            //     console.log(error);
            // }
        });

    return responseData;
};

export var getTask = (requestModel) => {
    const promise = PRIVATE_HTTP().post(SALE_GETTASK_ENDPOINT, requestModel);
    const responseData = promise
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            if (error.response.status === 401) {
                if (router.currentRoute.path != "/login") {
                    router.push("/login");
                }
            } 
            // else 
            // {
            //     console.log(error);
            // }
        });

    return responseData;
};

export var addTask = (requestModel) => {
    const promise = PRIVATE_HTTP().post(SALE_ADDTASK_ENDPOINT, requestModel);
    const responseData = promise
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            if (error.response.status === 401) {
                if (router.currentRoute.path != "/login") {
                    router.push("/login");
                }
            } 
            // else 
            // {
            //     console.log(error);
            // }
        });

    return responseData;
};
