export default {
   paxCountForType(target, type, self) {
      return Object.keys(self.divider[target]).filter((item) => {
         return self.divider[target][item].type == type;
      }).length;
   },
   generatePaxIds(self) {
      const arr = [];
      Object.keys(self.divider.target).forEach((item) => {
         if (self.divider.target[item].name != null) {
            arr.push(self.divider.target[item].id);
         }
         if (self.divider.target[item].infant?.name != null) {
            arr.push(self.divider.target[item].infant.id);
         }
      });
      return arr;
   },
};
