import Vue from "vue";
import { generateUuid } from "@/utils.js";
const state = {
   isModalInitialized: false,
   paymentStatus: false,
   pnr: {
      id: "",
      status: false,
   },
   segments: {},
   selectedPax: {
      name: "",
      nameAbbr: "",
      paxId: "",
   },
   stepList: [],
   selectSeatQ: {
      uuid: generateUuid(),
      searchUuid: "",
      flightsSegmentsPaxesSeats: {},
   },
   result: {},
   hasInfant: {
      status: false,
      ids: {},
      parentIndex: [],
   },
};

const getInitials = (name) => {
   const words = name.split(" ");
   return words.map((word) => word.charAt(0).toUpperCase()).join("");
};

const mutations = {
   setPaymentStatus(state, data) {
      state.paymentStatus = data;
   },
   setStatus(state, data) {
      state.pnr.id = data.id;
      state.pnr.status = data.status;
   },

   setResult(state, data) {
      state.result = data;
   },
   isModalInitialized(state, data) {
      state.isModalInitialized = data;
   },
   setPaxSeat(state, data) {
      Vue.set(state.segments[data.segmentId].paxes[data.paxId], "seat", data.seat);
      Vue.set(state.segments[data.segmentId].paxes[data.paxId], "initialSeat", true);
   },
   setHasInfant(state, data) {
      state.hasInfant.status = true;
      Vue.set(state.hasInfant.ids, data.id, {
         id: data.id,
         parentIndex: data.parentIndex,
      });
   },
   setSegments(state, data) {
      Vue.set(state.segments, data.id, data);
   },

   updatePaxSelection(state, data) {
      const segment = state.segments[data.segmentId];
      if (segment && segment.paxes) {
         Object.keys(segment.paxes).forEach((paxId) => {
            Vue.set(segment.paxes[paxId], "selected", false);
         });

         Vue.set(segment.paxes[data.paxId], "selected", true);
         state.selectedPax.name = data.paxName;
         state.selectedPax.nameAbbr = getInitials(data.paxName);
         state.selectedPax.paxId = data.paxId;
      }
   },

   selectSeat(state, data) {
      const segment = state.segments[data.segmentId];
      const selectedPax = state.segments[data.segmentId].paxes[state.selectedPax.paxId];

      if (selectedPax && !selectedPax.initialSeat) {
         //Infant Controll
         if (state.hasInfant.status) {
            const infantList = state.hasInfant.ids;

            let parentHasInfant = null;

            Object.keys(infantList).some((infant) => {
               if (infantList[infant].parentIndex == selectedPax.paxIndex) {
                  parentHasInfant = infantList[infant];
                  return true;
               }
               return null;
            });

            if (parentHasInfant != null) {
               const infant = state.segments[data.segmentId].paxes[parentHasInfant.id];
               Vue.set(infant, "seat", data.name);
            }
         }

         // Find the next pax
         const paxIds = Object.keys(segment.paxes);
         const currentIndex = paxIds.indexOf(state.selectedPax.paxId.toString());

         if (currentIndex !== -1 && currentIndex < paxIds.length - 1) {
            const nextPaxId = paxIds[currentIndex + 1];
            const nextPax = segment.paxes[nextPaxId];

            if (nextPax.paxTypeEnum != 3) {
               Vue.set(selectedPax, "selected", false);
               Vue.set(nextPax, "selected", true);
               // Update state.selectedPax with the next pax
               state.selectedPax.name = nextPax.paxName;
               state.selectedPax.paxId = parseInt(nextPaxId);
            }
         }

         Vue.set(selectedPax, "seat", data.name);
         Vue.set(selectedPax, "price", data.price);
         Vue.set(selectedPax, "identifier", data.identifier);
      }
   },

   initialUpdateSeatList(state, segmentId) {
      const segment = state.segments[segmentId];

      // console.log("initialUpdateSeatListtempObj", state.segments);

      if (segment && segment.paxes) {
         const tempObj = {};
         Object.keys(segment.paxes).forEach((paxId) => {
            if (segment.paxes[paxId].seat !== null && segment.paxes[paxId].paxTypeEnum != 3) {
               tempObj[segment.paxes[paxId].seat] = {
                  selected: true,
                  paxName: getInitials(segment.paxes[paxId].paxName),
                  paxNameFull: segment.paxes[paxId].paxName,
               };
            }
         });

         // console.log("initialUpdateSeatListtempObj", tempObj);
         Vue.set(segment, "selectedSeats", tempObj);
      }
   },
   updateSeatList(state, data) {
      const segment = state.segments[data.segmentId];
      if (segment && segment.paxes) {
         const tempObj = {};
         Object.keys(segment.paxes).forEach((paxId) => {
            if (segment.paxes[paxId].seat !== null && segment.paxes[paxId].paxTypeEnum != 3) {
               tempObj[segment.paxes[paxId].seat] = {
                  selected: true,
                  paxName: getInitials(segment.paxes[paxId].paxName),
                  paxNameFull: segment.paxes[paxId].paxName,
               };
            }
         });
         Vue.set(segment, "selectedSeats", tempObj);
      }
   },

   cancelSeatSelection(state, data) {
      const segment = state.segments[data.segmentId];
      if (segment) {
         Vue.delete(segment.selectedSeats, data.seat);
         const pax = segment.paxes[data.paxId];

         if (pax) {
            if (state.hasInfant.status) {
               const infantList = state.hasInfant.ids;
               let parentHasInfant = null;

               Object.keys(infantList).some((infant) => {
                  if (infantList[infant].parentIndex == pax.paxIndex) {
                     parentHasInfant = infantList[infant];
                     return true;
                  }
                  return null;
               });

               if (parentHasInfant != null) {
                  const infant = state.segments[data.segmentId].paxes[parentHasInfant.id];
                  Vue.set(infant, "seat", null);
                  Vue.set(infant, "price", null);
                  Vue.set(infant, "cabinType", null);
               }

               // const infant = state.segments[data.segmentId].paxes[state.hasInfant.id];
               // Vue.set(infant, "seat", null);
               // Vue.set(infant, "price", null);
               // Vue.set(infant, "cabinType", null);
            }

            Vue.set(pax, "seat", null);
            Vue.set(pax, "price", null);
            Vue.set(pax, "cabinType", null);
         }
      }
   },
   selectFirstPax(state, data) {
      const paxes = state.segments[data.id].paxes;
      const dataArray = Object.entries(paxes);

      let selectedPaxIndex;
      let selectedPaxEntry;
      let i = 0;
      do {
         if (i >= dataArray.length) {
            Vue.set(state.selectedPax, "name", "");
            Vue.set(state.selectedPax, "nameAbbr", "");
            Vue.set(state.selectedPax, "paxId", "");
            return;
         }
         [selectedPaxIndex, selectedPaxEntry] = dataArray[i];
         i++;
      } while (selectedPaxEntry.initialSeat);

      if (selectedPaxEntry.paxTypeEnum != 3) {
         Vue.set(paxes[selectedPaxIndex], "selected", true);
         Vue.set(state.selectedPax, "name", selectedPaxEntry.paxName);
         Vue.set(state.selectedPax, "nameAbbr", getInitials(selectedPaxEntry.paxName));
         Vue.set(state.selectedPax, "paxId", selectedPaxEntry.paxId);
      }
   },

   pushStep(state, data) {
      state.stepList.push(data);
   },

   resetSteps(state) {
      state.stepList = [];
      state.segments = {};
      Vue.set(state.selectSeatQ, "searchUuid", {});
      Vue.set(state.selectSeatQ, "flightsSegmentsPaxesSeats", {});
   },

   setStep(state, data) {
      for (let i = 0; i < state.stepList.length; i++) {
         if (state.stepList[i].index === data.index) {
            state.stepList[i].status = data.status;
            break;
         }
      }
   },

   selectSeatQSearchUui(state, searchUuid) {
      state.selectSeatQ.searchUuid = searchUuid;
   },

   setSegmentsPaxesSeats(state, data) {
      if (!state.selectSeatQ.flightsSegmentsPaxesSeats[data.flightKey]) {
         Vue.set(state.selectSeatQ.flightsSegmentsPaxesSeats, data.flightKey, {});
      }

      if (!state.selectSeatQ.flightsSegmentsPaxesSeats[data.flightKey][data.identifier]) {
         Vue.set(state.selectSeatQ.flightsSegmentsPaxesSeats[data.flightKey], data.identifier, {});
      }

      Object.assign(state.selectSeatQ.flightsSegmentsPaxesSeats[data.flightKey][data.identifier], data.rowObj);
   },
   deleteSegmentsPaxesSeat(state, data) {
      if (state.selectSeatQ.flightsSegmentsPaxesSeats[data.flightKey] && state.selectSeatQ.flightsSegmentsPaxesSeats[data.flightKey][data.identifier]) {
         if (state.selectSeatQ.flightsSegmentsPaxesSeats[data.flightKey] && state.selectSeatQ.flightsSegmentsPaxesSeats[data.flightKey][data.identifier]) {
            Vue.delete(state.selectSeatQ.flightsSegmentsPaxesSeats[data.flightKey][data.identifier], data.paxIndex);

            // Clean up empty objects if necessary
            if (Object.keys(state.selectSeatQ.flightsSegmentsPaxesSeats[data.flightKey][data.identifier]).length === 0) {
               Vue.delete(state.selectSeatQ.flightsSegmentsPaxesSeats[data.flightKey], data.identifier);
            }
            if (Object.keys(state.selectSeatQ.flightsSegmentsPaxesSeats[data.flightKey]).length === 0) {
               Vue.delete(state.selectSeatQ.flightsSegmentsPaxesSeats, data.flightKey);
            }
         }
      }
   },
};
const actions = {
   updateSegments({ commit }, data) {
      commit("setSegments", data);
   },
   updatePaxSelection({ commit }, data) {
      commit("updatePaxSelection", data);
   },
   selectSeat({ commit }, data) {
      commit("selectSeat", data);
      commit("updateSeatList", data);
   },
   selectFirstPax({ state, commit }, data) {
      state;
      commit("selectFirstPax", data);
   },
   selectYanyana({ state, commit, dispatch }, data) {
      commit;
      const segment = state.segments[data.id];

      if (segment) {
         const firstPax = Object.keys(segment.paxes)[0];
         const selectedPax = segment.paxes[firstPax];

         if (selectedPax && selectedPax.seat) {
            const selectedSeat = selectedPax.seat;
            const rowNumber = parseInt(selectedSeat.match(/\d+/)[0]);
            const startSeatLetter = selectedSeat.match(/[A-Z]/)[0];
            const seatLetters = ["A", "B", "C", "D", "E", "F"];
            const startSeatIndex = seatLetters.indexOf(startSeatLetter);

            // console.log(selectedSeat, rowNumber, startSeatLetter, startSeatIndex);

            const paxList = Object.values(segment.paxes);
            let nextSeatIndex = startSeatIndex;

            for (let i = 1; i < paxList.length; i++) {
               let newSeatFound = false;

               // Check if paxTypeEnum is not equal to 3
               if (paxList[i].paxTypeEnum !== 3) {
                  // İlk olarak sağda boş koltuk ara
                  while (!newSeatFound && nextSeatIndex + 1 < seatLetters.length) {
                     nextSeatIndex++;
                     const newSeatLetter = seatLetters[nextSeatIndex];
                     const newSeat = `${rowNumber}${newSeatLetter}`;

                     // Koltuğu segmentin sıralarında bul
                     const row = segment.rows.find((row) => row.rowNumber === rowNumber);

                     if (row) {
                        const seatGroup = row.seatGroups.find((group) => group.seats.some((seat) => seat.name === newSeat));
                        if (seatGroup) {
                           const seatInfo = seatGroup.seats.find((seat) => seat.name === newSeat);

                           if (seatInfo && seatInfo.status === "available") {
                              paxList[i].seat = newSeat;
                              paxList[i].price = seatInfo.price;
                              paxList[i].identifier = seatInfo.identifier;
                              newSeatFound = true;
                           }
                        }
                     }
                  }

                  // Sağda koltuk bulamazsa solda koltuk ara
                  if (!newSeatFound) {
                     nextSeatIndex = startSeatIndex;

                     while (!newSeatFound && nextSeatIndex - 1 >= 0) {
                        nextSeatIndex--;
                        const newSeatLetter = seatLetters[nextSeatIndex];
                        const newSeat = `${rowNumber}${newSeatLetter}`;

                        // Koltuğu segmentin sıralarında bul
                        const row = segment.rows.find((row) => row.rowNumber === rowNumber);
                        if (row) {
                           const seatGroup = row.seatGroups.find((group) => group.seats.some((seat) => seat.name === newSeat));
                           if (seatGroup) {
                              const seatInfo = seatGroup.seats.find((seat) => seat.name === newSeat);
                              if (seatInfo && seatInfo.status === "available") {
                                 paxList[i].seat = newSeat;
                                 paxList[i].price = seatInfo.price;
                                 paxList[i].identifier = seatInfo.identifier;
                                 newSeatFound = true;
                              }
                           }
                        }
                     }
                  }

                  // Aynı satırda boş koltuk bulamazsa bir sonraki satırda ara
                  if (!newSeatFound) {
                     let nextRowNumber = rowNumber + 1;
                     nextSeatIndex = startSeatIndex;

                     while (!newSeatFound && nextRowNumber <= segment.rows.length) {
                        const newSeatLetter = seatLetters[nextSeatIndex];
                        const newSeat = `${nextRowNumber}${newSeatLetter}`;

                        // Koltuğu segmentin sıralarında bul
                        const row = segment.rows.find((row) => row.rowNumber === nextRowNumber);
                        if (row) {
                           const seatGroup = row.seatGroups.find((group) => group.seats.some((seat) => seat.name === newSeat));
                           if (seatGroup) {
                              const seatInfo = seatGroup.seats.find((seat) => seat.name === newSeat);
                              if (seatInfo && seatInfo.status === "available") {
                                 paxList[i].seat = newSeat;
                                 paxList[i].price = seatInfo.price;
                                 paxList[i].identifier = seatInfo.identifier;
                                 newSeatFound = true;
                              }
                           }
                        }

                        if (!newSeatFound) {
                           nextRowNumber++;
                        }
                     }
                  }

                  // Bir sonraki yolcu için nextSeatIndex'i güncelle
                  nextSeatIndex = seatLetters.indexOf(paxList[i].seat.match(/[A-Z]/)[0]);
               }
            }
         }
      }

      dispatch("updateYanyana", data);
   },
   updateYanyana({ commit, state }, data) {
      const segment = state.segments[data.id];
      if (segment && segment.paxes) {
         const tempObj = {};
         Object.keys(segment.paxes).forEach((paxId) => {
            if (segment.paxes[paxId].seat !== null) {
               tempObj[segment.paxes[paxId].seat] = {
                  selected: true,
                  paxName: getInitials(segment.paxes[paxId].paxName),
                  paxNameFull: segment.paxes[paxId].paxName,
               };
               commit("setSegmentsPaxesSeats", {
                  flightKey: segment.flightKey,
                  identifier: segment.index,
                  rowObj: {
                     [segment.paxes[paxId].paxIndex]: segment.paxes[paxId].identifier,
                  },
               });
               console.log("commitCodeWorksHere", segment.paxes[paxId]);
            }
         });
         Vue.set(segment, "selectedSeats", tempObj);
      }
   },
};

export const seatmap = {
   namespaced: true,
   state,
   mutations,
   actions,
};
