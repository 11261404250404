<template>
   <div class="g-input-container" v-click-outside="handlerClickOutside">
      <label v-if="label">{{ label }} </label>
      <!-- INPUT TEXT WRAPPER -->

      <div class="g-input-text-wrapper" v-if="!inline">
         <input
            type="text"
            :class="outOfRange ? 'out-of-range' : ''"
            @input="handlerInputChange($event)"
            @click="handlerShowDatepicker"
            :placeholder="placeholder"
            v-date
            :disabled="isDisabled"
            :value="DateDisplay" />
         <div class="g-input-actions-wrapper">
            <i class="gts-icon icon-close-rounded icon-xs gts-animation" @click="handlerClearInput" v-if="allowClear"></i>
         </div>
         <div class="g-form-validation">{{ $t("common_cannotBeEmpty") }}</div>
      </div>
      <!-- DATEPICKER WRAPPER -->
      <div class="g-datepicker-wrapper" v-if="datepicker.status">
         <b-datepicker
            inline
            :day-names="WeekDays"
            v-model="datepicker.value"
            :focused-date="date"
            :nearby-month-days="false"
            :min-date="datepicker.min"
            :max-date="datepicker.max"
            :first-day-of-week="1"
            @input="handlerDatePickerChange">
            <template #header>
               <b-field>
                  <div class="datepicker-gts-header">
                     <button class="button-prev" @click="handlerPrevMonth" :class="datepicker.navigations.prev ? '' : 'invisible'">
                        <i class="gts-icon icon-arrow icon-xs gts-animation"></i>
                     </button>
                     <template v-if="customHeader">
                        <span class="month-name">{{ MonthNames }} {{ Year }}</span>
                     </template>
                     <template v-else>
                        <div class="datepicker-selects-wrapper">
                           <UISelectBox
                              id="pnr-status"
                              :options="datepicker.months"
                              v-model="datepicker.selectedMonth"
                              @input="handlerSelectMonth"
                              light-theme
                              deny-click-outside />

                           <UISelectBox
                              id="pnr-status"
                              :options="datepicker.years"
                              v-model="datepicker.selectedYear"
                              @input="handlerSelectYear"
                              light-theme
                              deny-click-outside />
                        </div>
                     </template>

                     <button class="button-next" @click="handlerNextMonth" :class="datepicker.navigations.next ? '' : 'invisible'">
                        <i class="gts-icon icon-arrow icon-xs gts-animation"></i>
                     </button>
                  </div>
               </b-field>
            </template>
         </b-datepicker>
      </div>
   </div>
</template>

<script>
import moment from "moment";
import { isValidDate } from "@/helper/flight.js";
import UISelectBox from "@/components/unitary/form2/UISelectBox.vue";

const displayFormat = "DD.MM.YYYY";

export default {
   name: "UIDatepicker2",
   props: {
      value: String,
      label: String,
      placeholder: String,
      min: String,
      max: String,
      allowClear: Boolean,
      customHeader: Boolean,
      inline: Boolean,
      isDisabled: Boolean,
      index: Number,
      isUpdateMode: Boolean,
   },
   components: {
      UISelectBox,
   },
   data() {
      return {
         date: this.min ? moment(this.min, this.$constants.dateFormat)._d : moment()._d,
         datepicker: {
            value: null,
            status: false,
            //limit min || max date if :min || :max is not exist
            max: this.max ? moment(this.max, this.$constants.dateFormat)._d : moment().add(10, "years")._d,
            min: this.min ? moment(this.min, this.$constants.dateFormat)._d : moment().subtract(99, "years")._d,
            navigations: {
               prev: true,
               next: true,
            },
            months: [],
            years: [],
            selectedMonth: null,
            selectedYear: null,
         },
         monthArr: [],
         outOfRange: null,
      };
   },
   created() {
      // generate years from :min && :max
      // this.handlerGenerateYear();
   },
   computed: {
      WeekDays() {
         return moment().locale(this.$i18n.locale)._locale._weekdaysMin;
      },
      DateDisplay() {
         if (isValidDate(this.value)) {
            return moment(this.value, this.$constants.dateFormat).format(displayFormat);
         } else {
            return "";
         }
      },
      MonthNames() {
         const monthIndex = moment(this.date).format("MM");
         return this.monthArr[parseInt(monthIndex) - 1].name;
      },
      Year() {
         return moment(this.date).format("YYYY");
      },
   },
   methods: {
      handlerShowDatepicker() {
         this.datepicker.status = true;
      },
      handlerHideDatepicker() {
         this.datepicker.status = false;
      },
      handlerEmit(dateStr) {
         if (isValidDate(dateStr)) {
            if (this.isUpdateMode) {
               this.$emit("input", {
                  date: moment(dateStr, displayFormat).format(this.$constants.dateTimeFormat),
                  index: this.index,
               });
               this.handlerHideDatepicker();
               return;
            }
            this.$emit("input", moment(dateStr, displayFormat).format(this.$constants.dateFormat));
            this.handlerHideDatepicker();
         }
      },
      handlerInputChange(ev) {
         if (ev.target.value.length == 0) {
            this.handlerClearInput();
         } else {
            this.handlerEmit(ev.target.value);
         }
      },
      handlerDatePickerChange() {
         const selectedDate = moment(this.datepicker.value).format(displayFormat);
         this.handlerEmit(selectedDate);
      },
      handlerIsOutOfRange() {
         const smallerThanMin = this.datepicker.value < this.datepicker.min;
         const biggerThanMax = this.datepicker.value > this.datepicker.max;

         if (smallerThanMin || biggerThanMax) {
            this.$emit("input", "");
            this.datepicker.value = this.date = this.datepicker.min;
            this.datepicker.status = true;
            this.outOfRange = true;
         } else {
            this.outOfRange = false;
         }
      },
      handlerClearInput() {
         this.date = moment()._d;
         this.$emit("input", "");
         this.handlerHideDatepicker();
      },

      handlerGenerateYear() {
         const minYear = moment(this.datepicker.min).format("YYYY");
         const maxYear = moment(this.datepicker.max).format("YYYY");
         this.datepicker.years = [];
         for (let i = Number(minYear); i <= Number(maxYear); i++) {
            this.datepicker.years.push({ name: String(i), value: String(i) });
         }
      },
      handlerGenerateMonth() {
         const selectedMonth = this.datepicker.selectedMonth;
         const minYear = parseInt(moment(this.datepicker.min).format("YYYY"));
         const maxYear = parseInt(moment(this.datepicker.max).format("YYYY"));
         const minMonth = parseInt(moment(this.datepicker.min).format("MM")) - 1;
         const maxMonth = parseInt(moment(this.datepicker.max).format("MM"));
         const monthArrLength = this.monthArr.length;
         const currentYear = parseInt(this.datepicker.selectedYear);

         if (currentYear == minYear) {
            this.datepicker.months = [];

            if (selectedMonth < minMonth) {
               this.datepicker.selectedMonth = minMonth;
               this.date.setMonth(minMonth);
            }

            for (let i = minMonth; i < monthArrLength; i++) {
               this.datepicker.months.push({ name: this.monthArr[i].name, value: i });
            }
         } else if (currentYear == maxYear) {
            this.datepicker.months = [];

            if (selectedMonth > maxMonth - 1) {
               this.datepicker.selectedMonth = maxMonth - 1;
               this.date.setMonth(maxMonth - 1);
            }

            for (let i = 0; i < maxMonth; i++) {
               this.datepicker.months.push({ name: this.monthArr[i].name, value: i });
            }
         } else {
            this.datepicker.months = [];
            for (let i = 0; i < monthArrLength; i++) {
               this.datepicker.months.push({ name: this.monthArr[i].name, value: i });
            }
         }
      },
      handlerSelectYear() {
         this.date = new Date(this.date);
         this.date.setYear(this.datepicker.selectedYear);
         this.handlerGenerateMonth();
      },
      handlerSelectMonth() {
         this.date = new Date(this.date);
         this.date.setMonth(this.datepicker.selectedMonth);
      },

      handlerPrevMonth() {
         this.date = new Date(this.date);
         this.date.setDate(1);
         this.date.setMonth(this.date.getMonth() - 1);
         const newMonth = parseInt(moment(this.date).format("MM")) - 1;
         this.datepicker.selectedMonth = newMonth;
      },
      handlerNextMonth() {
         this.date = new Date(this.date);
         this.date.setDate(1);
         this.date.setMonth(this.date.getMonth() + 1);
         const newMonth = parseInt(moment(this.date).format("MM")) - 1;
         this.datepicker.selectedMonth = newMonth;
      },
      handlerClickOutside() {
         if (!this.inline) {
            this.handlerHideDatepicker();
         }
      },
      handlerNavigationUpdate() {
         const minDate = moment(this.datepicker.min).format("MM.YYYY");
         const maxDate = moment(this.datepicker.max).format("MM.YYYY");
         const currentDate = moment(this.date).format("MM.YYYY");

         this.datepicker.selectedYear = moment(this.date).format("YYYY");
         this.datepicker.selectedMonth = parseInt(moment(this.date).format("MM")) - 1;

         this.handlerGenerateMonth();
         this.handlerGenerateYear();

         if (maxDate == currentDate) {
            this.datepicker.navigations.next = false;
         } else {
            this.datepicker.navigations.next = true;
         }
         if (minDate == currentDate) {
            this.datepicker.navigations.prev = false;
         } else {
            this.datepicker.navigations.prev = true;
         }
      },
   },
   watch: {
      value: {
         handler: function (val) {
            if (isValidDate(val)) {
               this.datepicker.value = this.date = moment(val, this.$constants.dateFormat)._d;
               this.datepicker.selectedMonth = this.date.getMonth();
               this.datepicker.selectedYear = this.date.getFullYear();
               //check if the value is between :min & :max
               this.handlerIsOutOfRange();
            } else {
               this.date = this.min ? moment(this.min, this.$constants.dateFormat)._d : moment()._d;
               this.$emit("input", null);
            }
         },
         immediate: true,
      },
      "$i18n.locale": {
         handler: function () {
            this.monthArr = this.$GtsDropdownData.dateMonthArray();
         },
         immediate: true,
      },

      date: {
         handler: function () {
            this.handlerNavigationUpdate();
         },
         immediate: true,
      },
      "datepicker.status": {
         handler: function (status) {
            this.handlerGenerateMonth();
            if (status && this.outOfRange) {
               this.$emit("input", "");
               this.datepicker.value = this.date = this.datepicker.min;
            }
         },
      },
      min: {
         handler: function (min) {
            this.datepicker.min = moment(min, this.$constants.dateFormat)._d;
            this.handlerNavigationUpdate();
         },
      },
      max: {
         handler: function (max) {
            this.datepicker.max = moment(max, this.$constants.dateFormat)._d;
            this.handlerNavigationUpdate();
         },
      },
   },
};
</script>

<style lang="scss" scoped>
.g-input-container {
   position: relative;
   label {
      margin-bottom: 0.5rem;
      font-weight: 500;
   }
   .g-input-text-wrapper {
      position: relative;
      input {
         position: relative;
         display: flex;
         justify-content: flex-start;
         align-items: center;
         border: 1px solid #b1b2b3;
         border-radius: 0.5rem;
         box-sizing: border-box;
         background: #fff;
         height: 48px !important;
         max-height: 100% !important;
         border-width: 2px;
         outline: 2px solid transparent;
         width: 100%;
         text-indent: 1rem;
         font-size: var(--normal);
         color: var(--txt-black-constant);
         font-weight: 500;

         &:focus {
            border: 1px solid var(--accent-primary) !important;
            outline: 0.25rem solid var(--accent-primary-lightest);
         }

         &:disabled {
            background: #eee;
         }

         &.out-of-range {
            background: #fcf1f1;
            border: 2px solid #f07c7c;
         }
      }

      .g-input-actions-wrapper {
         position: absolute;
         height: fit-content;
         top: 0;
         right: 0.75rem;
         bottom: 0;
         margin: auto;
         display: flex;
         cursor: pointer;

         > i {
            margin-right: 0.5rem;

            &:last-child {
               margin-right: 0 !important;
            }
         }
      }
   }
   .g-datepicker-wrapper {
      position: absolute;
      z-index: 99;
   }
}

.datepicker-selects-wrapper {
   display: flex;

   > div {
      margin-right: 0.5rem;

      &:last-child {
         margin-right: 0rem !important;
      }
   }
}
</style>
