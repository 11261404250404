<template>
   <div class="daterange-picker-container">
      <label>{{ label }}</label>

      <date-range-picker
         class="gts-date-range-wrapper"
         ref="picker"
         :opens="datePickerConfig.opens"
         :ranges="datePickerConfig.ranges"
         :autoApply="datePickerConfig.autoApply"
         :locale-data="datePickerConfig.localeDate"
         :minDate="datePickerConfig.minDate"
         :dateFormat="dateFormat"
         @start-selection="handlerSelectionStart"
         @hover-date="handlerHoverDate"
         v-model="dateRange"
         :key="pickerKey">
         <template v-slot:input="">
            <div class="gts-date-range-input" v-if="calendarType == 'default'">
               <div class="gts-date-range-input-inner">
                  <div class="date-range-startDate-section date-range-section">
                     <div class="onlyDay">
                        <span>{{ dateRange.startDate | onlyDay }}</span>
                     </div>
                     <div class="date-group">
                        <span>{{ dateRange.startDate | onlyMonth }}</span>
                        <span>{{ dateRange.startDate | onlyYear }}</span>
                     </div>
                  </div>
                  <div class="date-range-endDate-section date-range-section">
                     <div class="onlyDay">
                        <span>{{ dateRange.endDate | onlyDay }}</span>
                     </div>
                     <div class="date-group">
                        <span>{{ dateRange.endDate | onlyMonth }}</span>
                        <span>{{ dateRange.endDate | onlyYear }}</span>
                     </div>
                  </div>
               </div>
            </div>
            <div v-if="calendarType == 'availability-calendar'">
               <input type="hidden" :value="pickerX" />
               <UIButton className="default" text="müsaitlik takvimi" />
            </div>
         </template>
      </date-range-picker>
   </div>
</template>

<script>
import i18n from "@/fmt/i18n.js";
import moment from "moment";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import { hotelService } from "@/service/index.js";

export const dateTR = {
   days: ["Paz", "Pts", "Sal", "Çar", "Per", "Cum", "Cts"],
   month: ["Ocak", "Şubat", "Mart", "Nisan", "Mayıs", "Haziran", "Temmuz", "Ağustos", "Eylül", "Ekim", "Kasım", "Aralık"],
};
export const dateEN = {
   days: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
   month: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
};
export const dateDE = {
   days: ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"],
   month: ["Januar", "Februar", "März", "April", "Mai", "Juni", "Juli", "August", "September", "Oktober", "November", "Dezember"],
};

export const dateRU = {
   days: ["Вос", "Пон", "Вт", "Ср", "Чет", "Пят", "Суб"],
   month: ["Январь", "Февраль", "Март", "Апрель", "Май", "Июнь", "Июль", "Август", "Сентябрь", "Октябрь", "Ноябрь", "Декабрь"],
};

export default {
   name: "GtsDateRangePickerComponent",
   components: {
      DateRangePicker,
   },
   props: {
      calendarType: { type: String },
      label: String,
   },
   data() {
      return {
         datePickerConfig: {
            opens: "right",
            autoApply: true,
            ranges: false,
            localeDate: {
               format: "dd.mm.yyyy",
               daysOfWeek: null,
               monthNames: null,
               firstDay: 1,
            },
            minDate: new Date(),
         },
         dateRange: {
            startDate: "",
            endDate: "",
         },
         stopDates: [
            // { name: 'StopDate-1', date: moment().add(20, 'days') },
            // { name: 'StopDate-2', date: moment().add(21, 'days') },
            // { name: 'StopDate-3', date: moment().add(22, 'days') },
            // { name: 'StopDate-4', date: moment().add(23, 'days') },
            // { name: 'StopDate-5', date: moment().add(30, 'days') }
         ],
         publicHolidays: [
            // { name: 'holiday-1', date: moment().add(35, 'days') },
            // { name: 'holiday-2', date: moment().add(36, 'days') },
            // { name: 'holiday-3', date: moment().add(37, 'days') },
            // { name: 'holiday-4', date: moment().add(38, 'days') },
            // { name: 'holiday-5', date: moment().add(39, 'days') }
         ],
         pickerKey: 0,
      };
   },
   methods: {
      dateFormat(classes, date) {
         var self = this;

         for (var i = 0; i < self.stopDates.length; i++) {
            if (moment(date).format("DD.MM.YYYY") == moment(self.stopDates[i].date).format("DD.MM.YYYY")) {
               classes.disabled = true;
            }
         }

         return classes;
      },
      forceRerenderer() {
         const self = this;
         self.pickerKey += 1;
      },
      handlerSelectionStart(startDate) {
         this.dateRange.startDate = startDate;
         // this.datePickerConfig.ranges.startDate = startDate;
      },

      handlerHoverDate(hoverDate) {
         const hoverDateMonth = moment(hoverDate).format("MM");
         const selectedDateMonth = moment(this.dateRange.startDate).format("MM");
         if (hoverDate < this.dateRange.startDate && hoverDateMonth == selectedDateMonth) {
            this.$refs.picker.start = moment(this.dateRange.startDate)._d;
         }
      },
   },
   created() {
      var self = this;

      var localCheckIn = hotelService.getSearchCheckinDate();
      var localCheckOut = hotelService.getSearchCheckoutDate();

      if (localCheckIn || localCheckOut) {
         self.dateRange.startDate = moment(localCheckIn);
         self.dateRange.endDate = moment(localCheckOut);
      } else {
         self.dateRange.startDate = moment();
         self.dateRange.endDate = moment().add(3, "days");
      }

      switch (i18n.locale) {
         case "tr":
            self.datePickerConfig.localeDate.daysOfWeek = dateTR.days;
            self.datePickerConfig.localeDate.monthNames = dateTR.month;
            break;
         case "en":
            self.datePickerConfig.localeDate.daysOfWeek = dateEN.days;
            self.datePickerConfig.localeDate.monthNames = dateEN.month;
            break;
         case "de":
            self.datePickerConfig.localeDate.daysOfWeek = dateDE.days;
            self.datePickerConfig.localeDate.monthNames = dateDE.month;
            break;
         case "ru":
            self.datePickerConfig.localeDate.daysOfWeek = dateRU.days;
            self.datePickerConfig.localeDate.monthNames = dateRU.month;
            break;
         default:
            self.datePickerConfig.localeDate.daysOfWeek = dateDE.days;
            self.datePickerConfig.localeDate.monthNames = dateDE.month;
            break;
      }

      this.$emit("update-to-date", self.dateRange);
   },
   watch: {
      dateRange: {
         handler: function (newValue) {
            const self = this;
            var startDateMoment = moment(newValue.startDate).format(self.$constants.dateFormat);
            var endDateMoment = moment(newValue.endDate).format(self.$constants.dateFormat);
            if (startDateMoment == endDateMoment || endDateMoment < startDateMoment) {
               self.dateRange.endDate = moment(newValue.startDate).add(1, "day")._d;
               self.$emit("update-to-date", { startDate: newValue.startDate, endDate: moment(newValue.startDate).add(1, "day")._d });
            } else {
               self.$emit("update-to-date", { startDate: newValue.startDate, endDate: newValue.endDate });
            }
         },
         deep: true,
      },
      pickerKey: {
         handler: function () {
            const self = this;

            switch (i18n.locale) {
               case "tr":
                  self.datePickerConfig.localeDate.daysOfWeek = dateTR.days;
                  self.datePickerConfig.localeDate.monthNames = dateTR.month;
                  break;
               case "en":
                  self.datePickerConfig.localeDate.daysOfWeek = dateEN.days;
                  self.datePickerConfig.localeDate.monthNames = dateEN.month;
                  break;
               case "de":
                  self.datePickerConfig.localeDate.daysOfWeek = dateDE.days;
                  self.datePickerConfig.localeDate.monthNames = dateDE.month;
                  break;
               case "ru":
                  self.datePickerConfig.localeDate.daysOfWeek = dateRU.days;
                  self.datePickerConfig.localeDate.monthNames = dateRU.month;
                  break;
               default:
                  self.datePickerConfig.localeDate.daysOfWeek = dateDE.days;
                  self.datePickerConfig.localeDate.monthNames = dateDE.month;
                  break;
            }
         },
         deep: true,
      },
   },
};
</script>

<style lang="scss" scoped>
.daterange-picker-container {
   display: flex;
   flex-direction: column;
   min-width: 175px;
   label {
      margin-bottom: 0.5rem;
      font-weight: 500;
      white-space: nowrap;
   }
}
</style>

<style lang="scss">
.reportrange-text {
   padding: 0 !important;
   .gts-date-range-input-inner {
      display: flex;
      justify-content: space-around;
      .date-range-section {
         display: flex;
         position: relative;
         align-items: center;
         padding: 0 1rem;

         &:first-child {
            &::after {
               content: "";
               position: absolute;
               top: 20%;
               bottom: 20%;
               right: 0;
               margin: auto;
               border-right: 1px solid #cacbcc;
            }
         }

         &:last-child {
            border-right: none !important;
            padding-left: 0.25rem;
         }

         .onlyDay {
            span {
               font-size: var(--xxlarge);
               font-weight: 600;
            }
         }
         .date-group {
            display: flex;
            flex-direction: column;
            line-height: 16px !important;
            padding-left: 0.25rem;
            span {
               font-size: var(--xsmall);

               &:first-child {
                  font-weight: 500;
               }
            }
         }
      }
   }
}
</style>
