<template>
   <div class="room-info-wrapper room-info-item data-mask">
      <div class="room-info-inner">
         <div class="room-card-wrapper">
            <div class="room-card-inner">
               <span class="room-card-badge"></span>
               <div class="room-card-section room-informations">
                  <div class="room-information-item">
                     <span>{{ $t("hotelCheckout_lblRoomName") }}</span>
                     <span class="room-name masked"></span>
                  </div>
                  <div class="room-information-item">
                     <span>{{ $t("hotelCheckout_lblBoardType") }}</span>
                     <span class="room-board-type masked"></span>
                  </div>
               </div>
               <div class="room-card-section room-date">
                  <div class="room-date-content">
                     <div class="room-date-item checkin-date">
                        <div class="room-date-icon">
                           <i class="gts-icon icon-calendar-checkin"></i>
                        </div>
                        <div class="room-date-content">
                           <span>{{ $t("hotelCheckout_lblCheckin") }}</span>
                           <span class="date masked"></span>
                        </div>
                     </div>
                     <div class="room-date-item checkout-date">
                        <div class="room-date-icon">
                           <i class="gts-icon icon-calendar-checkout"></i>
                        </div>
                        <div class="room-date-content">
                           <span>{{ $t("hotelCheckout_lblCheckout") }}</span>
                           <span class="date masked"></span>
                        </div>
                     </div>
                  </div>
                  <div class="room-date-accordion-icon">
                     <i class="gts-icon icon-arrow-rounded-down"></i>
                  </div>
               </div>
               <div class="room-card-section room-price">
                  <div class="room-price-info">
                     <span>{{ $t("hotelCheckout_lblRoomTotal") }}</span>
                     <span class="price masked"></span>
                  </div>
               </div>
            </div>
            <div class="room-card-footer">
               <div class="room-card-footer-inner masked">
                  <i class="gts-icon icon-information"></i>
               </div>
            </div>
            <div class="room-guest-wrapper masked"></div>
         </div>
      </div>
   </div>
</template>

<script>
export default {
   name: "RoomInfoCardSkeleton",
};
</script>

<style lang="scss" scoped>
.room-info-wrapper {
   .room-info-inner {
      .room-card-wrapper {
         border: 1px solid #cccccc;
         border-radius: 8px;
         margin-bottom: 1.5rem;
         overflow: hidden;

         .room-card-inner {
            width: 100%;
            display: grid;
            grid-template-columns: 1fr 1.7fr 1fr;
            width: 100%;
            padding: 16px 32px;
            border-bottom: 1px solid #cccccc;
            position: relative;

            .room-card-badge {
               position: absolute;
               $distance: 1rem;
               left: 0;
               top: $distance;
               bottom: $distance;
               margin: auto;
               width: 5px;
               background: #e0e7f0;
               border-radius: 0.5rem;
               border-top-left-radius: 0;
               border-bottom-left-radius: 0;
            }

            .room-card-section {
               &.room-informations {
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  // border-right: 1px dotted #cccccc;

                  .room-information-item {
                     display: flex;
                     flex-direction: column;
                     margin-bottom: 16px;

                     &:last-child {
                        margin-bottom: 0;
                     }

                     .room-name,
                     .room-board-type {
                        width: 35%;
                     }
                  }
               }
               &.room-date {
                  display: flex;
                  height: 100%;
                  padding: 0 2rem;
                  border-right: 1px solid #cccccc;
                  border-left: 1px solid #cccccc;
                  // padding-right: 2rem;

                  .room-date-item {
                     display: flex;
                     align-items: center;
                     margin-bottom: 16px;

                     &:last-child {
                        margin-bottom: 0;
                     }
                     .room-date-icon {
                        margin-right: 16px;
                     }
                     .room-date-content {
                        display: flex;
                        flex-direction: column;
                        width: 100%;
                        .date {
                           font-size: 16px;
                           font-weight: 700;
                           color: #363940;
                           width: 40%;
                        }
                     }
                  }
                  .room-date-content {
                     width: 90%;
                     height: 100%;
                  }
                  .room-date-accordion-icon {
                     display: flex;
                     align-items: center;
                     justify-content: center;
                  }
               }
               &.room-price {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  .room-price-info {
                     display: flex;
                     flex-direction: column;
                     justify-content: flex-start;
                     width: max-content;
                  }
               }
            }
         }
         .room-card-footer {
            &-inner {
               &.masked {
                  width: 50% !important;
               }
            }
         }
      }
      .room-guest-wrapper {
         padding: 0;
         padding-top: 1.5rem;
         margin-bottom: 2rem;
         margin-top: 3.5rem;
         position: relative;
         border-radius: 8px;
         height: 81px;
      }
   }
}
</style>
