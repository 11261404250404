<template>
   <div class="rent-a-car-date-picker-container gts-input-form" v-click-outside="handlerOutClick">
      <!-- Input Wrapper -> Main form -->

      <div class="gts-input-wrapper" @click="handlerDropdown">
         <i class="gts-icon icon-rent-a-car-datepicker icon-sm"></i>
         <span class="input-value">{{ DateFormat }}</span>
      </div>

      <UIDatePicker2 v-model="date" inline ref="datePicker" :min="minDate" custom-header />

      <!-- :min="searchQuery.flightDate1.min" -->
   </div>
</template>

<script>
import UIDatePicker2 from "@/components/unitary/form2/UIDatePicker2.vue";
import moment from "moment";
export default {
   name: "RentACarDatePicker",
   props: {
      value: String,
      minDate: String,
   },
   components: {
      UIDatePicker2,
   },
   data() {
      return {
         date: this.value,
         min: this.minDate,
      };
   },
   computed: {
      DateFormat() {
         return moment(this.value, this.$constants.dateFormat).format("DD.MM.YYYY");
      },
   },

   methods: {
      handlerDropdown() {
         this.$refs.datePicker.datepicker.status = true;
      },
      handlerOutClick() {
         this.$refs.datePicker.datepicker.status = false;
      },
   },
   watch: {
      value: {
         handler: function (val) {
            this.date = val;
         },
      },
      date: {
         handler: function (date) {
            this.$emit("date", date);
         },
      },
   },
};
</script>

<style lang="scss" scoped>
.rent-a-car-date-picker-container {
   width: 100%;
   min-width: 160px;
   .gts-input-wrapper {
   }
   span.input-value {
      font-size: var(--normal);
      font-weight: 500;
   }
}
</style>
