const state = {
   basket: {
      departureOfferId: 0,
      returnOfferId: 0,
   },
   basketResponse: {},
   searchUuid: null,
   checkout: {},
   reissue: {},
   passengerInfo: {},
   editMode: false,
   canReissue: false,
   isSearching: false,
   searchQuery: null,
   cookieData: {
      travelers: null,
   },
};

const mutations = {
   updateBasket(state, data) {
      // state.basket.departureOfferId = data.departureOfferId ? data.departureOfferId : state.basket.departureOfferId;
      // state.basket.returnOfferId = data.returnOfferId ? data.returnOfferId : state.basket.returnOfferId;
      state.basket = data;
   },
   updateBasketResponse(state, data) {
      state.basketResponse = data;
   },
   generateSearchUuid(state, data) {
      state.searchUuid = data;
   },
   updateReissue(state, data) {
      state.reissue = data;
   },
   updateReissueTransfersList(state, data) {
      state.reissue.transfers = data;
   },
   updateReissueAgencyNote(state, data) {
      state.reissue.agencyNote = data;
   },
   updatePassengerInfo(state, data) {
      state.passengerInfo = data;
   },
   updateEditMode(state, data) {
      state.editMode = data;
   },
   setCanReissue(state, data) {
      state.canReissue = data;
   },
   setTravelerCookieData(state, data) {
      state.cookieData.travelers = data;
   },
   setIsSearching(state, data) {
      state.isSearching = data;
   },
   setSearchQuery(state, data) {
      state.searchQuery = data;
   },
};

const actions = {
   updateBasket: function (commit, data) {
      commit("updateBasket", data);
   },
   updateBasketResponse: function (commit, data) {
      commit("updateBasketResponse", data);
   },
   generateSearchUuid: function (commit, uuid) {
      commit("generateSearchUuid", uuid);
   },
   updateReissue: function (commit, data) {
      commit("updateReissue", data);
   },
   updateReissueTransfersList: function (commit, data) {
      commit("updateReissueTransfersList", data);
   },
   updateReissueAgencyNote: function (commit, data) {
      commit("updateReissueAgencyNote", data);
   },
   updatePassengerInfo: function (commit, data) {
      commit("updatePassengerInfo", data);
   },
   updateEditMode: function (commit, data) {
      commit("updateEditMode", data);
   },
   setCanReissue: function (commit, data) {
      commit("setCanReissue", data);
   },
   setTravelerCookieData: function (commit, data) {
      commit("setTravelerCookieData", data);
   },
   setIsSearching: function (commit, data) {
      commit("setIsSearching", data);
   },
   setSearchQuery: function (commit, data) {
      commit("setSearchQuery", data);
   },
};

const getters = {
   getBasketResponse: (state) => state.basketResponse,
   getSearchUuid: (state) => state.searchUuid,
   getReissueRequest: (state) => state.reissue,
   getPassengerInfo: (state) => state.passengerInfo,
   getEditMode: (state) => state.editMode,
   getCanReissue: (state) => state.canReissue,
};

export const transfer = {
   namespaced: true,
   state,
   mutations,
   actions,
   getters,
};
