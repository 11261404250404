<template>
   <div id="task-chat-container" class="p-md-2 p-lg-4">
      <b-tabs type="is-boxed" :animated="false" v-model="chatTab">
         <!-- TABS LOOPS  -->
         <b-tab-item headerClass="chat-inner-wrapper" v-for="(task, index) in Tasks" :key="index">
            <template #header>
               <span v-if="task.id == -1"> {{ $options.filters.enumTaskStatus(-1).text }}</span>
               <div class="chat-header-container" v-else>
                  <div class="tab-left-container">
                     <span class="entityIdentifier">{{ task.entityIdentifer }}</span>
                     <span class="statue-container">
                        <span class="statue"> {{ $options.filters.enumTaskStatus(task.statue).text }} </span>
                        <span class="sub-statue">
                           {{ $options.filters.enumTaskSubStatus(task.subStatue).text }}
                        </span>
                     </span>
                  </div>
                  <div class="tab-right-container">
                     <span class="gts-badge" :class="[task.statue == 1 ? 'EFltPnrStatus-3' : 'EFltPnrStatus-4']">
                        {{ $options.filters.enumTaskType(task.type).text }}
                     </span>
                  </div>
               </div>
            </template>
            <ChatTab :task-id="task.id" :order-id="task.orderId" :tab-informations="task" :is-active="chatTab == index ? true : false" />
         </b-tab-item>
      </b-tabs>
   </div>
</template>

<script>
import { notesService } from "@/service/index.js";

import ChatTab from "@/components/unitary/order/ServiceCenter/ChatTab.vue";
export default {
   name: "TaskChat",
   props: {
      orderId: [String, Number],
   },
   components: {
      ChatTab,
   },
   data() {
      return {
         tasks: [],
         chatTab: null,
      };
   },
   computed: {
      Tasks() {
         const self = this;
         return self.tasks;
      },
   },
   watch: {
      orderId: {
         handler: function (orderId) {
            const self = this;
            self;

            const tasks = [
               {
                  id: -1,
                  orderId: orderId,
               },
            ];
            const listTasksQuery = {
               orderId: orderId,
            };

            notesService
               .listTasks(listTasksQuery)
               .then((res) => {
                  if (res.tasks.length == 0) {
                     self.$emit("show-task", false);
                  } else {
                     res.tasks.map((task) => {
                        const tabModel = {
                           id: task.id,
                           orderId: task.orderId,
                           entityIdentifer: task.entityIdentifier,
                           statue: task.status,
                           subStatue: task.subStatus,
                           type: task.type,
                           visibility: task.visibility,
                           openedDatetime: task.openedDatetime,
                           insertedUserEmail: task.insertedUserEmail,
                           insertedUserId: task.insertedUserId,
                           description: task.description,
                        };
                        tasks.push(tabModel);
                     });
                  }
               })
               .then(() => {
                  self.tasks = tasks;
               })
               .then(() => {
                  self.chatTab = 0;
               });
         },
      },
      "$store.state.flight.orderDetail.focusedTask": {
         handler: function (taskId) {
            this.tasks.forEach((task, index) => {
               if (task.id == taskId) {
                  this.chatTab = index;
               }
            });
         },
      },
   },
};
</script>
<style lang="scss" scoped>
#task-chat-container {
   background: #fff;
   padding: 2rem;

   .chat-header-container {
      display: flex;
      align-items: center;
      padding: 0 0.5rem;

      .tab-left-container {
         display: flex;
         flex-direction: column;

         .entityIdentifier {
            font-weight: 600;
         }

         .statue-container {
            text-transform: capitalize;

            span {
               &:first-child {
                  &::after {
                     content: "";
                     position: absolute;
                  }
                  &::before {
                     content: "" !important;
                     position: absolute;
                  }
               }
               &:last-child {
                  &::before {
                     content: ",";
                  }
               }
            }
         }
      }
      .tab-right-container {
         padding-left: 1rem;
         span {
            border-radius: 24rem;
            text-align: center;
            padding: 0.125rem 1rem;
            text-transform: capitalize;
            font-weight: 500;
            margin-left: 0.75rem;
         }
      }
   }
}
</style>

<style lang="scss">
// #task-chat-container > .b-tabs > .tabs > ul > li > a {
//     font-weight: 500;
//     background-color: #f4f4f4 !important;
// }
#task-chat-container {
   .b-tabs {
      .tab-content {
         padding: 0 !important;
      }
      .tabs {
         ul {
            li {
               &:not(:first-child) {
                  margin-left: 8px;
               }
               height: 100% !important;
               border: 1px solid #c7d4ed;
               border-radius: 14px 14px 0px 0px;

               a {
                  height: 100% !important;
                  min-width: 70px;
               }
            }
         }
      }
   }
}

#task-chat-container > .b-tabs > .tabs > ul > li.is-active > a {
   color: var(--txt-black);
   background-color: #c9d6ed !important;
   border: 1px solid #c7d4ed;
   border-radius: 14px 14px 0px 0px;
}
#task-chat-container > .b-tabs > .tabs > ul > li > a:hover {
   border-radius: 14px 14px 0px 0px;
}
</style>
