<template>
   <div class="gts-tooltip" @mouseover="handlerMouseMovement(true)" @mouseleave="handlerMouseMovement(false)" @click="tooltipInvisible" ref="containerWidth" :style="containerStyle">
      <i :class="tooltipIcon" v-if="tooltipIcon"></i>
      <slot></slot>
      <span class="tooltip-base" style="z-index: 99999 !important" :class="handlerTooltipPos" v-if="hover && !hide" ref="base" v-bind:style="generatedPosition">
         {{ tooltipTitle }}
      </span>
   </div>
</template>

<script>
export default {
   name: "UITooltip",
   props: {
      tooltipTitle: { type: String },
      tooltipIcon: { type: [String, Boolean], default: false },
      tooltipPosition: { type: String },
      tooltipMargin: {
         type: Number,
         default: 10,
      },
      hide: Boolean,
      noPadding: Boolean,
   },
   data() {
      return {
         hover: false,
         generatedPosition: {
            left: null,
         },
      };
   },
   computed: {
      containerStyle() {
         return this.noPadding ? "padding: 0!important; padding-bottom: .5rem;" : "";
      },
      handlerTooltipPos() {
         let pos = this.tooltipPosition;
         if (pos === undefined) {
            pos = "right";
         }
         return pos;
      },
   },
   methods: {
      handlerMouseMovement(movement) {
         this.hover = movement;
      },
      tooltipInvisible() {
         this.hover = false;
      },
   },
};
</script>

<style lang="scss" scoped>
$animation-left-pos: 75px;

@keyframes right {
   from {
      opacity: 0;
      left: $animation-left-pos + 10px;
   }
   to {
      opacity: 1;
      left: $animation-left-pos;
   }
}

@keyframes bottom {
   from {
      opacity: 0;
      top: 100%;
   }
   to {
      opacity: 1;
      top: 100%;
   }
}

.gts-tooltip {
   position: relative;
   display: flex;
   justify-content: center;
   align-items: center;
   width: 100%;
   padding: 1rem 0rem;
   // cursor: pointer;
   // z-index: 99;

   span.tooltip-base {
      position: absolute;
      white-space: nowrap;
      background-color: var(--accent-primary);
      color: var(--txt-negative);
      padding: 0.5rem 1rem;
      margin: auto;
      border-radius: 0.125rem;
      font-size: var(--normal);
      font-weight: 400;
      width: auto !important;

      &:before {
         content: "*";
         text-indent: -999em;
         position: absolute;
         background-color: var(--accent-primary);
         width: 8px;
         height: 8px;
         margin: auto;
         transform: rotate(45deg);
      }

      &.right {
         animation: right 0.375s ease-in-out;
         left: $animation-left-pos;

         &:before {
            left: -4px;
            top: 0;
            bottom: 0;
         }
      }
      &.bottom {
         animation: bottom 0.375s ease-in-out;
         top: 100%;

         &:before {
            top: -4px;
            left: 0;
            right: 0;
         }
      }
   }
}
</style>
