<template>
   <div class="order-detail-view-wrapper order-detail-transfer-view">
      <div class="order-detail-view-inner">
         <RentACarInformation
            :orderId="orderId"
            :pageLoad="pageLoad"
            :total="rnrDetail.total"
            :rnrId="RnrId"
            :rnr="rnrDetail.rnr"
            :driver="rnrDetail.driver" />
         <DriverInformations :driver="rnrDetail.driver" />
         <CarInformations :car="rnrDetail.vehicle" :ancillariesObj="rnrDetail.ancillaries" :price="PriceInformations" />
         <div v-if="rnrDetail.rnr.agencyNote">
            <OrderDetailNotes :agencyNote="rnrDetail.rnr.agencyNote" />
         </div>
      </div>
      <PaymentPurchaseWidget entityType="rentacar" :entityId="RnrId" :paymentChoiceDetail="PaymentChoiceDetail" />
   </div>
</template>

<script>
import RentACarInformation from "@/components/structural/rentacar/order-detail/RentACarInformation.vue";
import DriverInformations from "@/components/structural/rentacar/order-detail/DriverInformations.vue";
import CarInformations from "@/components/structural/rentacar/order-detail/CarInformations.vue";
import OrderDetailNotes from "@/components/structural/rentacar/order-detail/OrderDetailNotes.vue";
import PaymentPurchaseWidget from "@/components/structural/payment/PaymentPurchaseWidget.vue";

export default {
   name: "OrderDetailRentACarView",
   components: {
      RentACarInformation,
      DriverInformations,
      OrderDetailNotes,
      CarInformations,
      PaymentPurchaseWidget,
   },
   props: {
      rnrDetail: Object,
      orderId: Number,
      pageLoad: Boolean,
   },
   computed: {
      PriceInformations() {
         return {
            currency: this.rnrDetail?.total?.currency,
            agencyCommission: this.rnrDetail?.total?.agencyCommissionTotal,
            grandTotal: this.rnrDetail?.total?.grandTotal,
            saleTotal: this.rnrDetail?.total?.saleTotal,
            reverseTotal: this.rnrDetail?.total?.reverseTotal,
            paymentFeeTotal: this.rnrDetail?.total?.paymentFeeTotal,
         };
      },
      RnrId() {
         return this.rnrDetail ? this.rnrDetail.rnr.id : 0;
      },
      PaymentChoiceDetail() {
         return this.rnrDetail ? this.rnrDetail.paymentChoiceDetail : null;
      },
   },
};
</script>

<style lang="scss" scoped>
.order-detail-view-wrapper {
   .order-detail-view-inner {
      .reissue-btn-wrapper {
         display: flex;
         justify-content: center;
      }
      .transfer-order-detail-section {
         margin-bottom: 42px;

         &:last-child {
            margin-bottom: 0;
         }
      }
   }
}
</style>
