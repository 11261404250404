<template>
   <div id="facilities-modal-container">
      <div id="facilities-modal-wrapper">
         <section id="facilities-modal-header">
            <span class="facilities-modal-title">{{ $t("hotelFacilitiesModal_lblTitle") }}</span>
            <div class="facilities-modal-close-button" @click="handlerClosePopup">x</div>
         </section>
         <section id="facilities-modal-body">
            <!-- HOTEL FACILITIES SIDEBAR -->
            <div class="facilities-modal-body-sidebar-container">
               <div class="sidebar-search-wrapper">
                  <!-- {{ searchQuery }} -->
                  <input type="text" if="facilitySearch" v-model="searchQuery" :placeholder="$t('common_search')" />
               </div>
               <div class="sidebar-tabs-wrapper">
                  <template v-for="(tab, index) in tabs">
                     <div class="sidebar-tab" :key="index" @click="handlerSetTab(index)" :class="activeTab == index ? 'active' : ''">
                        {{ tab.name }}
                     </div>
                  </template>
               </div>
            </div>
            <!-- HOTEL FACILITIES BODY -->
            <div class="facilities-modal-body-facilities-list-container">
               <div class="facilities-list-wrapper">
                  <template v-for="(facility, index) in Facilities">
                     <div
                        class="facility-box"
                        :key="index"
                        :class="facility.checked ? 'checked' : 'unchecked'"
                        v-show="facility.groupId == activeTab || activeTab == 0"
                        @click="handlerSelectFacilities(facility)">
                        <div class="facility-box-info-wrapper">
                           <div class="facility-icon-wrapper">
                              <!-- <img :src="facility.img" alt="" @error="$event.target.src = `${$constants.cdn}/hotel_facility/no_icon.svg`" /> -->
                           </div>
                           <span class="facility-name"> {{ facility["name" + lang] }} </span>
                        </div>
                        <span class="facility-count">({{ facility.count }})</span>
                     </div>
                  </template>
               </div>
            </div>
         </section>
         <section id="facilities-modal-footer">
            <UIButton
               className="grayscale gts-btn-animation"
               :text="SelectAll ? $t('CLEAR ALL') : $t('SELECT ALL')"
               @click.native="handlerSelectAll()" />
            <UIButton className="alert gts-btn-animation" :text="$t('CLOSE')" @click.native="handlerClosePopup" />
         </section>
      </div>
   </div>
</template>

<script>
import UIButton from "@/components/unitary/Button.vue";
export default {
   name: "FacilitiesModal",
   props: {
      facilities: Object,
   },
   components: {
      UIButton,
   },
   data() {
      return {
         searchQuery: "",
         tabs: {},
         activeTab: 0,
         includedFacilities: [],
         lang: "",
      };
   },
   created() {
      this.handlerGenerateTab(this.facilities);
      var localeLang = this.$i18n.locale;
      this.lang = localeLang.charAt(0).toUpperCase() + localeLang.charAt(1);
   },
   computed: {
      Facilities() {
         // this.rows.filter((row) => JSON.stringify(row).toUpperCase().includes(this.search.toUpperCase()));
         // return this.facilities((row) => JSON.stringify(row).toUpperCase().includes(this.searchQuery.toUpperCase()));
         // var localeLang = this.$i18n.locale;
         // localeLang = localeLang.charAt(0).toUpperCase() + localeLang.charAt(1);

         // let a = Object.entries(this.facilities).filter((row, index) => {
         //    console.log(row, index);
         //    return row[1].name.includes("Yetişkin") ? row[1] : null;
         // });

         // console.log(localeLang, a);

         Object.filter = (obj, predicate) =>
            Object.keys(obj)
               .filter((key) => predicate(obj[key]))
               .reduce((res, key) => ((res[key] = obj[key]), res), {});

         var filtered = Object.filter(this.facilities, (row) =>
            row["name" + this.lang].toUpperCase().clearTurkishChars().includes(this.searchQuery.toUpperCase().clearTurkishChars())
         );

         return filtered;
      },
      SelectAll() {
         const obj = this.facilities;

         const a = Object.keys(obj).every((item) => {
            return obj[item].checked;
         });
         return a;
      },
   },
   methods: {
      handlerGenerateTab(obj) {
         this.$set(this.tabs, 0, {
            name: "All",
            id: 0,
         });

         Object.keys(obj).forEach((item) => {
            this.$set(this.tabs, obj[item].groupId, {
               name: obj[item].groupNameTr,
               id: obj[item].groupId,
            });
         });
      },
      handlerSetTab(index) {
         this.activeTab = index;
      },
      handlerSelectFacilities(val) {
         val.checked = !val.checked;
      },
      handlerSelectAll() {
         const obj = this.facilities;
         if (this.SelectAll) {
            Object.keys(obj).forEach((item) => {
               obj[item].checked = false;
            });
         } else {
            Object.keys(obj).forEach((item) => {
               obj[item].checked = true;
            });
         }
      },
      handlerClosePopup() {
         this.includedFacilities = [];
         const obj = this.facilities;
         Object.keys(obj).forEach((item) => {
            if (obj[item].checked) {
               this.includedFacilities.push(obj[item].id);
            }
         });

         this.$emit("close", {
            status: true,
            includedFacilities: this.includedFacilities,
         });
      },
   },
   watch: {
      searchQuery: {
         handler: function (query) {
            if (query.length > 0) {
               this.activeTab = 0;
            }
         },
      },
   },
};
</script>

<style lang="scss" scoped>
#facilities-modal-container {
   position: fixed;
   left: 0;
   right: 0;
   top: 0;
   bottom: 0;
   background-color: #00000050;
   z-index: 9999;

   #facilities-modal-wrapper {
      position: absolute;
      inset: 0 0 0 0;
      margin: auto;
      background-color: #fff;
      width: 68vw;
      height: 80vh;
      border-radius: 1rem;
      border: 1px solid #e6e6e6;
      // padding: 2.25rem 2rem;
      display: flex;
      flex-direction: column;
      align-items: stretch;

      section {
         height: 100%;
         // border: 1px solid #eee;
         flex-shrink: 1;
         height: fit-content;

         &#facilities-modal-header {
            display: flex;
            justify-content: space-between;
            border-bottom: 1px solid #ccc;
            padding: 1.25rem 2rem;
            margin-bottom: 1rem;

            .facilities-modal-title {
               font-size: 1.5rem;
               font-weight: 600;
            }
            .facilities-modal-close-button {
               background: #ccc;
               width: 32px;
               height: 32px;
               display: flex;
               justify-content: center;
               align-items: center;
               border-radius: 24rem;
            }
         }

         &#facilities-modal-body {
            flex-grow: 1;
            display: flex;
            flex-direction: row;
            overflow: hidden;
            padding: 1rem 2rem;

            .facilities-modal-body-sidebar-container,
            .facilities-modal-body-facilities-list-container {
               // border: 1px solid blue;
               padding: 1rem;
               padding-top: 0;
               &.facilities-modal-body-facilities-list-container {
                  flex-grow: 1;
                  overflow: hidden;
                  overflow-y: auto;

                  .facilities-list-wrapper {
                     display: grid;
                     grid-template-columns: repeat(3, 1fr);
                     column-gap: 2.5rem;
                     row-gap: 0.75rem;
                     // background: #eee;

                     .facility-box {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        background: #fff;
                        cursor: pointer;
                        height: 52px;
                        border-width: 1.5px;
                        border-style: solid;
                        border-color: #ccc;
                        border-radius: 0.5rem;
                        padding: 0.25rem 0.75rem;

                        .facility-icon-wrapper {
                           width: 33px;
                           display: flex;
                           justify-content: center;
                        }

                        .facility-box-info-wrapper {
                           display: flex;
                           align-items: center;
                           .facility-name {
                              margin-left: 1rem;
                              color: #363636;
                              font-size: 0.95rem;
                              font-weight: 500;
                           }
                        }
                        span.facility-count {
                           font-size: 0.85rem;
                        }
                        &.checked {
                           background-color: #fff8f2;
                           border-color: #ffc999;

                           span.facility-name,
                           span.facility-count {
                              color: #ff9133;
                           }

                           img {
                              filter: invert(50%) sepia(130%) saturate(350%) hue-rotate(350deg) brightness(110%) contrast(90%);
                           }
                        }
                     }
                  }
               }

               &.facilities-modal-body-sidebar-container {
                  width: fit-content;
                  height: 500px;
                  background-color: #f2f2f2;
                  border: 1.5px solid #ededed;
                  border-radius: 1rem;
                  padding: 2rem 0.75rem;
                  margin-right: 1rem;

                  .sidebar-search-wrapper {
                     margin-bottom: 1.5rem;
                     input {
                        width: 200px;
                        border-radius: 24rem;
                        outline: 0;
                        border: 2px solid #ccc;
                        height: 40px;
                        text-indent: 1rem;
                        color: #363636;
                        text-transform: uppercase;
                        font-size: var(--small);
                        background-color: #fff;

                        &:focus {
                           border-color: var(--accent-primary-lighten);
                           background-color: #fff;
                           font-weight: 500;
                        }

                        &::-webkit-input-placeholder {
                           text-transform: capitalize;
                        }
                     }
                  }

                  .sidebar-tab {
                     border-bottom: 1px solid #dedede;
                     margin: 0.5rem 0;
                     padding: 0.25rem 0.75rem;
                     font-size: 0.95rem;
                     white-space: nowrap;
                     cursor: pointer;

                     &:last-child {
                        border: none !important;
                     }

                     &.active {
                        font-weight: 500;
                        background-color: #e1e1e1;
                        border-radius: 24rem;
                        // color: #fff;
                     }
                  }
               }
            }
         }

         &#facilities-modal-footer {
            display: flex;
            justify-content: flex-end;
            border-top: 1px solid #ccc;
            padding: 1.25rem 2rem;

            button {
               margin: 0 1rem;
            }
         }
      }
   }
}
</style>
