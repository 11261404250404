import { render, staticRenderFns } from "./AncillarySeatMapPassengerWrapper.vue?vue&type=template&id=5db85233&scoped=true&"
import script from "./AncillarySeatMapPassengerWrapper.vue?vue&type=script&lang=js&"
export * from "./AncillarySeatMapPassengerWrapper.vue?vue&type=script&lang=js&"
import style0 from "./AncillarySeatMapPassengerWrapper.vue?vue&type=style&index=0&id=5db85233&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5db85233",
  null
  
)

export default component.exports