class Template{
    getInvoiceTableTemplate(){
        return `
            .vgt-table th.sortable button,
            .vgt-global-search,
            .cta-group-column{
                display: none;
            }
            table{
                width: 100%;
                font-size: 16px;
                border-collapse: collapse;
                background-color: #fff;
                width: 100%;
                max-width: 100%;
                table-layout: auto;
                border: 1px solid #dcdfe6;
            }
            th{
                background-color: white;
            }
            th > span{ 
                color: #4a78b7;
            }
            tr{}
            tr:nth-of-type(odd){
                background-color: #f4f8ff;
            }
            tr:nth-of-type(even){
                background-color: white;
            }
            tr > td{
                border: 1px solid #dcdfe6;
                vertical-align: middle;
            }
        `;
    }
    getInvoiceExcelTemplate(){
        return `
            .vgt-table th.sortable button,
            .vgt-global-search,
            .cta-group-column{
                display: none;
            }
        `;
    }
}

export default new Template();