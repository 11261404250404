<template>
   <div id="notification-container" @click="handlerNotificationStatus" v-click-outside="handlerOutClick">
      <div id="notification-dropdown">
         <Transition name="fade">
            <span class="notification-info-box" v-if="NotificationCount != 0 ? true : false">{{ NotificationCount }}</span>
         </Transition>
         <UITooltip
            :tooltip-title="$t('aside_notifications')"
            tooltip-icon="gts-icon icon-notification-list icon-navbar gts-animation"
            tooltip-position="bottom"></UITooltip>
      </div>
      <div id="notification-dropdown-box" v-if="dropdown">
         <NotificationContent :per-page="5" />
      </div>
   </div>
</template>

<script>
import UITooltip from "@/components/utils/GtsToolTips.vue";
import NotificationContent from "@/components/unitary/NotificationContent.vue";
import { isIOS } from "@/helper/common.js";

export default {
   name: "NotificationPanel",
   components: {
      UITooltip,
      NotificationContent,
   },
   data() {
      return {
         dropdown: false,
         allowNotification: false,
      };
   },
   created() {
      const self = this;
      if (!("Notification" in window) || isIOS()) return;
      const notificationStatus = Notification.permission;
      if (notificationStatus == "granted") {
         self.allowNotification = true;
      } else {
         self.allowNotification = false;
      }
   },

   computed: {
      NotificationCount() {
         const self = this;
         return self.$store.state.app.notification.count;
      },
   },

   methods: {
      handlerNotificationStatus() {
         this.dropdown = true;
      },
      handlerOutClick() {
         const self = this;
         self.dropdown = false;
      },
   },
};
</script>

<style lang="scss" scoped>
#notification-container {
   position: relative;
   margin: 0 0.5rem;
   height: 100%;
   display: flex;
   align-items: center;
   cursor: pointer;

   @media (min-width: 768px) {
      position: relative;
   }

   #notification-dropdown {
      .notification-info-box {
         position: absolute;
         top: 16px;
         right: -12px;
         width: 28px;
         height: 28px;
         background-color: #ff4c4c;
         border: 1px solid #fff;
         display: flex;
         align-items: center;
         justify-content: center;
         border-radius: 50%;
         text-align: center;
         font-weight: 500;
         color: #fff;
         font-size: 1rem;
         z-index: 101;
      }

      @keyframes bell {
         // from {
         //   transform: rotate(10deg);
         // }
         // to {
         //   transform: rotate(-10deg);
         // }
      }
      ::v-deep i.icon-notification-list {
         transform-origin: top;
         animation-name: bell;
         animation-timing-function: ease-in-out;
         animation-duration: 0.35s;
         animation-iteration-count: infinite;
         animation-direction: alternate;
      }
   }
   #notification-dropdown-box {
      animation-name: dropdown;
      animation-timing-function: ease-in-out;
      animation-duration: 0.15s;

      border-radius: 8px;
      background-clip: padding-box;
      background-color: #fff;
      top: 100%;
      box-shadow: rgba(0, 0, 0, 0.2) 0px 10px 30px 0px;

      padding: 1.5rem 0;

      position: fixed;
      left: 2rem;
      right: 2rem;
      z-index: 1000;
      top: 85px;
      @media (min-width: 768px) {
         position: absolute;
         left: auto;
         right: 0;
         min-width: 400px;
         top: 100%;
      }

      padding-bottom: 0;
      .np-list-control-panel,
      .np-list-container {
         padding: 0;
      }

      .np-list-control-panel {
         display: flex;
         align-items: center;
         justify-content: space-between;
         padding-bottom: 0.5rem;
         padding-left: 1.5rem;
         padding-right: 1.5rem;
         margin-bottom: 0.75rem;
         button {
            display: flex;
            align-items: center;
            background: transparent !important;
            padding-right: 0;
            span {
               margin-left: 0.25rem;
               font-size: var(--small);
               font-weight: 500;
               color: var(--txt-black);
            }
         }

         h6 {
            font-weight: 500;
            font-size: var(--large);
         }
      }

      .np-list-container {
         .g-loader-wrapper {
            display: flex;
            justify-content: center;
            span {
               margin: 1.5rem;
            }
         }
         .no-notification-wrapper {
            padding: 1rem 1.5rem;

            span {
               font-size: var(--normal);
               color: var(--txt-ghost);
               font-weight: 500;
               text-align: center;
               width: 100%;
               display: block;
            }
         }
         .np-list-wrapper {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 1rem 1.5rem;
            border-bottom: 1px solid #eee;

            i.icon-custom {
               flex-shrink: 0;
               width: 42px !important;
               height: 42px !important;
            }

            .np-list-content-wrapper {
               display: flex;
               flex-direction: column;
               width: 100%;
               margin-left: 0.75rem;
               .np-list-message {
                  font-size: var(--small);
                  font-weight: 600;
                  line-height: 1.25rem;
               }
               .np-list-message-time {
                  font-size: var(--xsmall);
                  text-transform: capitalize;
               }
            }

            .np-list-action-wrapper {
               i {
                  filter: brightness(0);
                  opacity: 0.6;
                  transform: scale(0.8);
               }
            }

            &:last-child {
               border-bottom: none !important;
            }

            &.unread {
               background-color: var(--secondary-lightest);
            }
            &:hover {
               background-color: var(--hover);
            }
         }
      }

      .np-list-filter-wrapper {
         display: flex;
         padding: 0 1.5rem;
         border-bottom: 2px solid #eee;

         span {
            color: var(--txt-ghost);
            margin-right: 1.5rem;
            font-size: var(--small);
            font-weight: 400;
            text-transform: capitalize;
            position: relative;
            padding: 0.75rem 0;

            i {
               background-color: #eee;
               color: #a2a2a2;
               display: inline-block;
               text-align: center;
               width: 20px;
               border-radius: 4px;
               font-size: var(--xsmall);
            }

            &:after {
               content: "";
               position: absolute;
               bottom: 0;
               height: 2px;
               background: #eee;
               left: -3px;
               right: -3px;
               bottom: -2px;
            }

            &.active {
               font-weight: 500;
               color: var(--txt-black);

               i {
                  background-color: #363636;
                  color: var(--txt-negative);
               }

               &:after {
                  background: #363636;
               }
            }

            &:last-child {
               margin-right: 0;
            }
         }
      }
      &:before {
         content: "";
         position: absolute;
         top: -6px;
         right: 0.5rem;
         width: 0;
         height: 0;
         transform: translateX(-50%);
         border-right: 6px solid transparent;
         border-bottom: 6px solid #fff;
         border-left: 6px solid transparent;
      }
   }
}
</style>

<style lang="scss">
.np-list-container {
   nav.tabs {
      display: none;
   }
}
</style>
