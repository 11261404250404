<template>
   <div class="price-information-summary-wrapper hotel-order-detail-inner-section">
      <div class="price-information-summary-inner">
         <div class="price-information-table-wrapper">
            <div class="price-information-table-inner">
               <div class="price-information-table-header">
                  <div class="table-header-fixed-item">
                     <span>{{ $t("orderDetail_lblRoomType") }}</span>
                  </div>
                  <div class="table-header-list">
                     <span>{{ $t("orderDetail_lblAccommodationDate") }}</span>
                     <span>{{ $t("orderDetail_lblAgencyPrice") }}</span>
                     <span>{{ $t("orderDetail_lblAgencyCommission") }}</span>
                     <span>{{ $t("orderDetail_lblTotalPrice") }}</span>
                  </div>
                  <div class="table-header-fixed-item" v-if="size > 4"></div>
               </div>
               <div class="price-information-table-content">
                  <div class="price-information-room-item" v-for="(priceItem, index) in prices" :key="index">
                     <div class="table-content-fixed-item">
                        <span class="masked">{{ priceItem.channelRoomName }}</span>
                     </div>
                     <div class="table-content-list">
                        <ul class="price-information-list default-list" v-if="priceItem.roomPrices.length <= 4">
                           <li class="price-information-list-item" v-for="(roomPrice, index) in priceItem.roomPrices" :key="index">
                              <span class="masked">{{ roomPrice.accommodationDate | fullDateFormat }}</span>
                              <span class="masked">{{ roomPrice.baseAmount | price(roomPrice.currency) }}</span>
                              <span class="masked">{{ roomPrice.agencyCommission | price(roomPrice.currency) }}</span>
                              <span class="total-price masked">{{ roomPrice.totalAmount | price(roomPrice.currency) }}</span>
                           </li>
                        </ul>
                        <ul class="price-information-list default-list" v-else>
                           <li class="price-information-list-item first-item">
                              <span class="masked">{{ priceItem.roomPrices[0].accommodationDate | fullDateFormat }}</span>
                              <span class="masked">{{ priceItem.roomPrices[0].baseAmount | price(priceItem.roomPrices[0].currency) }}</span>
                              <span class="masked">{{ priceItem.roomPrices[0].agencyCommission | price(priceItem.roomPrices[0].currency) }}</span>
                              <span class="total-price masked">{{
                                 priceItem.roomPrices[0].totalAmount | price(priceItem.roomPrices[0].currency)
                              }}</span>
                           </li>
                           <div class="price-information-detail-list" :class="isDetailListActive ? 'active' : ''">
                              <template v-for="(roomPrice, index) in priceItem.roomPrices">
                                 <li v-if="index != 0 && index != priceItem.roomPrices.length - 1" :key="index" class="price-information-list-item">
                                    <span class="masked">{{ roomPrice.accommodationDate | fullDateFormat }}</span>
                                    <span class="masked">{{ roomPrice.baseAmount | price(roomPrice.currency) }}</span>
                                    <span class="masked">{{ roomPrice.agencyCommission | price(roomPrice.currency) }}</span>
                                    <span class="total-price masked">{{ roomPrice.totalAmount | price(roomPrice.currency) }}</span>
                                 </li>
                              </template>
                           </div>
                           <li class="price-information-list-item last-item">
                              <span class="masked">{{
                                 priceItem.roomPrices[priceItem.roomPrices.length - 1].accommodationDate | fullDateFormat
                              }}</span>
                              <span class="masked">{{
                                 priceItem.roomPrices[priceItem.roomPrices.length - 1].baseAmount
                                    | price(priceItem.roomPrices[priceItem.roomPrices.length - 1].currency)
                              }}</span>
                              <span class="masked">{{
                                 priceItem.roomPrices[priceItem.roomPrices.length - 1].agencyCommission
                                    | price(priceItem.roomPrices[priceItem.roomPrices.length - 1].currency)
                              }}</span>
                              <span class="total-price masked">{{
                                 priceItem.roomPrices[priceItem.roomPrices.length - 1].totalAmount
                                    | price(priceItem.roomPrices[priceItem.roomPrices.length - 1].currency)
                              }}</span>
                           </li>
                        </ul>
                     </div>
                     <div class="table-content-fixed-item" v-if="priceItem.roomPrices.length >= 4" @click="triggerDetailList">
                        <i class="gts-icon icon-arrow-rounded-up" v-if="isDetailListActive"></i>
                        <i class="gts-icon icon-arrow-rounded-down" v-else></i>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>
export default {
   name: "PriceInformationSummary",
   props: {
      priceList: { type: Array },
   },
   data() {
      return {
         prices: [],
         size: 7,
         isDetailListActive: false,
      };
   },
   created() {
      const self = this;

      self.prices = this.priceList;
   },
   methods: {
      triggerDetailList() {
         const self = this;
         self.isDetailListActive = !self.isDetailListActive;
      },
   },
   watch: {
      priceList: {
         handler: function (newPriceList) {
            const self = this;
            self.prices = newPriceList;
         },
         deep: true,
      },
   },
};
</script>

<style lang="scss" scoped>
.price-information-summary-wrapper {
   .price-information-summary-inner {
      .price-information-table-wrapper {
         .price-information-table-inner {
            display: block;
            border: 1px solid #c4c4c4;
            border-radius: 10px;
            overflow: hidden;
            .price-information-table-header {
               display: flex;
               justify-content: flex-start;
               align-items: center;
               padding: 16px 42px;
               background-color: #ededed;
               border-bottom: 1px solid #c4c4c4;

               span {
                  font-size: 14px;
                  color: #868788;
               }

               .table-header-fixed-item {
                  width: 15%;
                  max-width: 15%;
                  margin-right: 24px;

                  &:last-child {
                     width: 5%;
                     max-width: 5%;
                     margin-right: 0;
                  }
               }
               .table-header-list {
                  display: grid;
                  grid-template-columns: repeat(4, 1fr);
                  width: 85%;
                  padding: 0 32px;

                  & > span {
                     text-align: right;
                     &:first-child {
                        text-align: left;
                     }
                  }
               }
            }
            .price-information-table-content {
               .price-information-room-item {
                  display: flex;
                  justify-content: flex-start;
                  align-items: stretch;
                  padding: 16px 42px;
                  background-color: white;
                  border-bottom: 1px solid #c4c4c4;

                  &:last-child {
                     border-bottom: none;
                  }
                  .table-content-fixed-item {
                     width: 15%;
                     max-width: 15%;
                     margin-right: 24px;

                     &:last-child {
                        width: 5%;
                        max-width: 5%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-right: none;
                        margin-right: 0;
                        cursor: pointer;
                     }

                     & > span {
                        display: inline-flex;
                        justify-content: flex-start;
                        align-items: center;
                        width: 100%;
                        height: 100%;
                        font-weight: 700;
                     }
                  }
                  .table-content-list {
                     width: 78%;
                  }

                  .price-information-list.default-list {
                     .price-information-list-item {
                        display: grid;
                        grid-template-columns: 1.25fr 1fr 1.25fr 1.6fr;
                        border-radius: 20px;
                        margin-bottom: 8px;
                        padding: 10px 32px;

                        &:last-child {
                           margin-bottom: 0;
                        }

                        // &:nth-child(odd) {
                        //    background-color: #f5f5f5;
                        //    border-color: #f5f5f5;
                        // }
                        // &:nth-child(even) {
                        //    background-color: white;
                        //    border-color: #e2e2e2;
                        // }

                        span {
                           text-align: right;
                           font-weight: 500;

                           &:first-child {
                              text-align: left;
                              font-weight: 500;
                           }
                        }
                        span.total-price {
                           font-weight: 800;
                        }
                     }

                     .price-information-detail-list {
                        max-height: 0;
                        overflow: hidden;
                        transition: max-height 0.5s ease-out;

                        &.active {
                           max-height: 100vh;
                           transition: max-height 0.5s ease-in;
                        }
                        & > .price-information-list-item:last-child {
                           margin-bottom: 8px;
                        }
                     }
                  }
               }
            }
         }
         @media (max-width: 767.98px) {
            .price-information-table-inner {
               display: flex;

               .price-information-table-header {
                  display: flex;
                  flex-direction: column;
                  padding: 16px 0px;
                  .table-header-fixed-item {
                     margin-right: 0px;
                  }
                  .table-header-list {
                     display: flex;
                     height: 100%;
                     flex-direction: column;
                     width: auto;
                     justify-content: center;
                     & > span {
                        text-align: center !important;
                        margin-bottom: 8px;
                     }
                  }
               }
               .price-information-table-content {
                  .price-information-room-item {
                     flex-direction: column;
                     padding: 16px 15px;
                     .table-content-fixed-item {
                        margin-bottom: 24px;
                        border-bottom: 1px solid #bfbfbf;
                        border-right: 0;
                        width: auto;
                        max-width: -webkit-fill-available;

                        &:last-child {
                           width: 85%;
                           max-width: 85%;
                           margin-top: 16px;
                        }
                     }
                     .price-information-list.default-list {
                        .price-information-list-item {
                           display: flex;
                           flex-direction: column;
                        }
                     }
                  }
               }
            }
         }
      }
   }
}
</style>
