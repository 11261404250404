<template>
   <div class="transfer-order-detail-inner-section tnr-summary-wrapper">
      <div class="tnr-summary-inner">
         <div class="tnr-summary-table-wrapper">
            <div class="tnr-summary-table-inner">
               <ul>
                  <li>
                     <span>{{ $t("orderDetail_lblTnrStatus") }}</span>
                     <span>
                        <i class="pnr-badge gts-badge-as-dot" :class="'ETrfTnrStatus-' + tnr.status"></i>
                        {{ tnr.status | ETrfTnrStatus }}
                     </span>
                  </li>
                  <li>
                     <span>{{ $t("orderDetail_lblProvider") }}</span>
                     <span>{{ tnr.providerName }}</span>
                  </li>
                  <li>
                     <span>{{ $t("orderDetail_lblTnr") }}</span>
                     <span>{{ tnr.tnr }}</span>
                  </li>
                  <li>
                     <span>{{ $t("orderDetail_lblTicketDate") }}</span>
                     <span>{{ tnr.ticketDateTime | fullDateFormat }} - {{ tnr.ticketDateTime | TimeFormat }}</span>
                  </li>
                  <li>
                     <span>{{ $t("orderDetail_lblLastVoidDate") }}</span>
                     <span>{{ tnr.lastVoidDateTime | fullDateFormat }} - {{ tnr.lastVoidDateTime | TimeFormat }}</span>
                  </li>
                  <li>
                     <span>{{ $t("orderDetail_lblGrandTotal") }}</span>
                     <span>{{ tnr.total | price(tnr.currency) }}</span>
                  </li>
               </ul>
            </div>
         </div>
      </div>
   </div>
</template>

<script>
export default {
   name: "TransferInformationSummary",
   props: {
      tnr: Object,
   },
};
</script>

<style lang="scss" scoped>
.tnr-summary-wrapper {
   margin-bottom: 43px;
   // padding: 24px;
   .tnr-summary-inner {
      .tnr-summary-table-wrapper {
         .tnr-summary-table-inner {
            background-color: #f3f3f3;
            border: 1px solid #cccccc;
            width: 100%;
            border-radius: 16px;
            padding: 1.75rem 0.75rem;

            @media (min-width: 992px) {
               padding: 1.75rem 1.75rem;
            }
            ul {
               display: flex;
               flex-wrap: wrap;

               @media (min-width: 576px) {
                  display: grid;
                  grid-template-columns: 0.5fr 0.75fr 0.75fr 1fr 1fr 0.5fr;
               }

               li {
                  width: 50%;
                  display: flex;
                  flex-direction: column;
                  margin-bottom: 1.125rem;

                  @media (min-width: 576px) {
                     width: 100%;
                     margin-bottom: 0;
                  }

                  span {
                     font-size: var(--large);
                     font-weight: 600;
                     @media (min-width: 576px) {
                        font-size: var(--small);
                     }

                     &:first-child {
                        font-size: var(--small);
                        opacity: 0.8;
                        font-weight: 400;
                        border-bottom: 1px solid #ccc;
                        padding-bottom: 0.5rem;
                        margin-bottom: 0.5rem;
                     }

                     &:last-child {
                        display: flex;
                        i {
                           display: inline-flex;
                           flex-shrink: 0;
                           margin-right: 0.5rem;
                        }
                     }
                  }
                  .date-container {
                     display: flex;
                     flex-direction: column;
                     position: relative;

                     &.dateExpired {
                        color: #f15a73;
                        transform: scale(1.2);
                        transform-origin: left top;
                        font-weight: 800;
                        &::before {
                           // content: "!";
                        }
                     }
                  }
               }
            }
         }
         .tnr-summary-table-inne22 {
            display: block;
            border: 1px solid #c4c4c4;
            border-radius: 10px 10px 0 10px;
            overflow: hidden;
            .tnr-summary-table-header {
               display: grid;
               grid-template-columns: repeat(6, 1fr);
               padding: 16px 42px;
               background-color: #ededed;
               border-bottom: 1px solid #c4c4c4;

               & > span {
                  font-size: 14px;
                  color: #868788;
               }
            }
            .tnr-summary-table-content {
               display: grid;
               grid-template-columns: repeat(6, 1fr);
               padding: 16px 42px;
               background-color: #f5f5f5;

               & > span {
                  font-size: 16px;
                  font-weight: 700;
                  max-width: 119px;
                  word-break: break-word;

                  &.tnr-status {
                     position: relative;
                     display: inline-flex;
                     align-items: center;

                     > i {
                        margin-right: 0.5rem;
                     }
                  }
               }
            }
         }
         .tnr-summary-table-footer {
            width: 100%;
            display: flex;
            justify-content: flex-end;
            .tnr-summary-table-footer-inner {
               width: 80%;
               display: grid;
               grid-template-columns: repeat(6, 1fr);
               grid-column-gap: 16px;
               padding: 16px 24px;
               border: 1px solid #c4c4c4;
               border-top: none;
               border-radius: 0 0 8px 8px;
               .tnr-summary-table-footer-item {
                  display: inline-flex;
                  flex-direction: column;
                  border-right: 1px solid #d0d2d4;

                  &:last-child {
                     border-right: none;
                  }
                  & > span {
                     font-size: 14px;
                  }
                  & > span.item-label {
                     margin-bottom: 4px;
                     color: #868788;
                  }
                  & > span.item-text {
                     font-weight: 700;
                  }
               }
            }
         }
      }
   }
}
</style>
