<template>
   <div id="smarttable-export-container">
      <button class="gts-animation" @click="triggerExportPrint">
         <i class="gts-icon icon-document-print gts-animation"></i>
      </button>
      <button class="gts-animation" @click="triggerExportExcel">
         <i class="gts-icon icon-document-excel gts-animation"></i>
      </button>
   </div>
</template>

<script>
import gtsGridPdfStyle from "@/assets/css/gtsGridPdf.js";
import Template from "@/assets/css/gtsDataTablePrintTemplates.js";
import moment from "moment";

function cleanTableElements(parentItem) {
   if (!parentItem) return parentItem;

   const elements = parentItem.querySelectorAll("td i,a,button,span");

   if (!elements || elements.length == 0) return parentItem;

   elements.forEach((element) => {
      element = cleanTableElements(element);

      if (element.parentElement) element.parentElement.innerHTML = element.innerHTML;
   });

   return parentItem;
}
export default {
   name: "SmartTableExportButtons",
   props: {
      tableTitle: String,
   },
   data() {
      return {};
   },
   methods: {
      triggerExportPrint() {
         const divToPrint = this.$parent.$parent.$refs.smarttable.$refs.print;
         const newPrintWindow = window.open("", "Print");
         newPrintWindow.document.write(`<html><head><style>${gtsGridPdfStyle}</style></head><body>${divToPrint.outerHTML}</body></html>`);
         newPrintWindow.print();
         newPrintWindow.close();
      },
      //Burası test sonrası silinecek
      triggerExportExcel2() {
         const self = this;
         const divToPrint = this.$parent.$parent.$refs.smarttable.$refs.print;
         const tableType = divToPrint.dataset.tabletype;
         const exportItemDetailsToExcel = self.exportItemDetailsToExcel;

         let styleContent = "";

         switch (tableType) {
            case "INVOICE":
               styleContent = Template.getInvoiceExcelTemplate();
               break;

            default:
               styleContent = "";
               break;
         }

         //const vgtTableWrapper = divToPrint.querySelector("table#vgt-table");
         const vgtTableWrapper = divToPrint;
         let cloneTable = vgtTableWrapper.cloneNode(true);

         if (!exportItemDetailsToExcel) {
            const gTableItemDetails = cloneTable.querySelectorAll("td .g-table-item-details");
            gTableItemDetails.forEach((detailDiv) => {
               detailDiv.remove();
            });
         }

         cloneTable = cleanTableElements(cloneTable);

         let excelContent = `<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40">`;
         excelContent += `<head>`;
         excelContent += `<!--[if gte mso 9]>`;
         excelContent += `<xml>`;
         excelContent += `<x:ExcelWorkbook>`;
         excelContent += `<x:ExcelWorksheets>`;
         excelContent += `<x:ExcelWorksheet>`;
         excelContent += `<x:Name>${this.tableTitle}</x:Name>`;
         excelContent += `<x:WorksheetOptions>`;
         excelContent += `<x:DisplayGridlines/>`;
         excelContent += `</x:WorksheetOptions>`;
         excelContent += `</x:ExcelWorksheet>`;
         excelContent += `</x:ExcelWorksheets>`;
         excelContent += `</x:ExcelWorkbook>`;
         excelContent += `</xml>`;
         excelContent += `<![endif]-->`;
         excelContent += `<meta http-equiv="content-type" content="text/plain; charset=UTF-8"/>`;
         excelContent += `<style>${styleContent}</style>`;
         excelContent += `</head>`;
         excelContent += `<body>`;
         excelContent += cloneTable.outerHTML;
         excelContent += `</body>`;
         excelContent += `</html>`;

         let csvContent = "data:application/vnd.ms-excel," + excelContent;

         var encodedUri = encodeURI(csvContent);
         var link = document.createElement("a");
         link.setAttribute("href", encodedUri);
         link.setAttribute("download", `${this.tableTitle} - ${moment().format("DD.MM.YYYY")}.xls`);
         document.body.appendChild(link);
         link.click();
      },
      triggerExportExcel() {
         // const divToPrint = this.$parent.$parent.$refs.smarttable.$refs.print;
         // const tableType = divToPrint.dataset.tabletype;

         // console.log(tableType);
         const { rows, columns } = this.$parent.$parent._props.options;

         // TableHeaderHTML
         const tableHeaderHTML = `<tr>${columns.map((col) => `<th>${col.label}</th>`).join("")}</tr>`;

         // TableBodyHTML
         const tableBodyHTML = rows
            .map((row) => {
               const tableRow = Object.keys(row)

                  .map((label) => `<td>${typeof row[label] == "object" ? row[label]?.text : row[label]}</td>`)
                  .join("");
               return `<tr>${tableRow}</tr>`;
            })
            .join("");

         // Complete Table HTML
         const tableHTML = `<table><thead>${tableHeaderHTML}</thead><tbody>${tableBodyHTML}</tbody></table>`;

         // Construct Excel file content
         let excelContent = `<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40">`;
         excelContent += `<head>`;
         excelContent += `<!--[if gte mso 9]>`;
         excelContent += `<xml>`;
         excelContent += `<x:ExcelWorkbook>`;
         excelContent += `<x:ExcelWorksheets>`;
         excelContent += `<x:ExcelWorksheet>`;
         excelContent += `<x:Name>${this.tableTitle}</x:Name>`;
         excelContent += `<x:WorksheetOptions>`;
         excelContent += `<x:DisplayGridlines/>`;
         excelContent += `</x:WorksheetOptions>`;
         excelContent += `</x:ExcelWorksheet>`;
         excelContent += `</x:ExcelWorksheets>`;
         excelContent += `</x:ExcelWorkbook>`;
         excelContent += `</xml>`;
         excelContent += `<![endif]-->`;
         excelContent += `<meta http-equiv="content-type" content="text/plain; charset=UTF-8"/>`;
         excelContent += `</head>`;
         excelContent += `<body>`;
         excelContent += tableHTML;
         excelContent += `</body>`;
         excelContent += `</html>`;

         // Convert to data URI and trigger download
         let csvContent = "data:application/vnd.ms-excel," + encodeURIComponent(excelContent);
         let link = document.createElement("a");
         link.setAttribute("href", csvContent);
         link.setAttribute("download", `${this.tableTitle} - ${moment().format("DD.MM.YYYY")}.xls`);
         document.body.appendChild(link);
         link.click();
         document.body.removeChild(link);
      },
   },
};
</script>

<style lang="scss" scoped>
#smarttable-export-container {
   margin-left: 1rem;
   display: flex;
   button {
      background: transparent !important;
   }
}
</style>
