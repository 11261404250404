<template>
   <div class="chat-bubble-container">
      <section class="bubble-container">
         <section class="bubble-content">
            <span class="user-email">
               {{ Bubble.author }}
            </span>
            <p class="note-container" v-if="!isEditActive">{{ Bubble.note }}</p>
            <textarea name="" id="" cols="30" rows="5" v-model="Bubble.note" v-else></textarea>

            <span class="note-informations"> {{ $t("chatBubbleComponent_sent") }}: {{ Bubble.insertedDatetime | fullDateTextFormat }} </span>
         </section>

         <!-- BUBBLE ACTIONS -->

         <section class="jump-to" v-if="jumpTo">
            <button @click="handlerJumpTo(Bubble.taskId)" class="gts-animation">
               <i class="gts-icon icon-link-external icon-sm"></i>
            </button>
         </section>

         <div class="bubble-actions-wrapper">
            <section class="bubble-actions" v-if="!jumpTo && !isEditActive">
               <button @click="isEditActive = !isEditActive" class="gts-animation">
                  <i class="gts-icon icon-edit-note icon-sm"></i>
               </button>
               <button @click="handlerDeleteBubble()" class="gts-animation">
                  <i class="gts-icon icon-delete-note icon-sm"></i>
               </button>
            </section>
            <section class="bubble-actions" v-if="isEditActive">
               <button @click="handlerSaveBubble" class="gts-animation">
                  <i class="gts-icon icon-save-rounded icon-sm"></i>
               </button>
               <button @click="isEditActive = false" class="gts-animation">
                  <i class="gts-icon icon-close-rounded icon-sm"></i>
               </button>
            </section>
         </div>
      </section>

      <section class="bubble-informations">
         <div class="profile-photo-fakie">
            {{ Bubble.author | fakiePhoto }}
         </div>
         <span class="bubble-date">{{ Bubble.insertedDatetime | TimeFormat }} </span>
      </section>
   </div>
</template>

<script>
import { notesService } from "@/service/index.js";
export default {
   name: "ChatBubble",
   props: {
      bubble: Object,
      jumpTo: Boolean,
   },
   data() {
      return {
         isEditActive: false,
      };
   },
   computed: {
      Bubble() {
         const self = this;
         return self.bubble;
      },
   },
   methods: {
      handlerDeleteBubble() {
         const self = this;
         let query = {
            orderId: self.bubble.orderId,
            noteId: self.bubble.id,
            dbState: 2,
            note: self.bubble.note,
            id: self.bubble.id,
            taskId: self.bubble.taskId,
         };
         notesService.setNote(query).then((response) => {
            if (response.result.success) {
               self.$emit("has-update", true);
            }
         });
      },
      handlerSaveBubble() {
         const self = this;
         let query = {
            orderId: self.bubble.orderId,
            noteId: self.bubble.id,
            dbState: 1,
            note: self.bubble.note,
            id: self.bubble.id,
            taskId: self.bubble.taskId,
         };
         notesService.setNote(query).then((response) => {
            if (response.result.success) {
               self.isEditActive = false;
               self.$emit("has-update", true);
            }
         });
      },
      handlerJumpTo(taskId) {
         this.$store.commit("flight/updateFocusedTask", taskId);
      },
   },
};
</script>

<style lang="scss" scoped>
.chat-bubble-container {
   display: flex;
   width: 70%;
   justify-content: flex-start;
   @media (min-width: 992px) {
      width: 40%;
   }
   .bubble-container {
      order: 2;
      border-radius: 16px;
      border-top-left-radius: 0;
      background-color: #e2e8f2;
      padding: 1rem 2rem;
      display: flex;
      width: 100%;
      justify-content: space-between;

      .bubble-content {
         display: flex;
         flex-direction: column;
         justify-content: space-between;
         margin-right: 1rem;
         .user-email {
            font-weight: 600;
            opacity: 0.7;
         }

         .note-container {
            min-height: 50px;
            &::first-letter {
               text-transform: capitalize;
            }
         }

         textarea {
            border-radius: 0.75rem;
            background: #fff;
            border: 1px solid #335f9d;
            color: var(--txt-black);
            padding: 0.5rem;

            &:focus {
               border: 1px solid var(--accent-primary);
               outline: 0.25rem solid #33b8ff38;
            }
         }
      }
      .bubble-actions-wrapper {
         height: 100%;
         display: flex;
         align-items: center;
         .bubble-actions {
            background: #00000010;
            border-radius: 10rem;
            padding: 0.5rem;
            display: none;
            flex-direction: column;
            justify-content: space-around;
            height: 100px;
            button {
               background: none !important;
            }
         }
      }
      .jump-to {
         display: flex;
         align-items: center;
         justify-content: center;
         button {
            background: none !important;
         }
      }
   }
   .bubble-informations {
      margin-right: 1rem;
      order: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      .profile-photo-fakie {
         background: var(--primary);
         width: 48px;
         height: 48px;
         line-height: 48px;
         border-radius: 50%;
         flex-grow: 0;
         flex-shrink: 0;
         color: #fff;
         text-align: center;
      }
      .bubble-date {
         font-size: var(--small);
         font-weight: 600;
      }
   }
}

.chat-inner {
   justify-content: flex-end !important;
   .chat-bubble-wrapper {
      order: 1;
      border-radius: 16px;
      border-top-right-radius: 0;
      background-color: var(--primary);
      color: #fff;

      .bubble-actions,
      .jump-to {
         button {
            i {
               filter: invert(1);
               cursor: pointer;
            }
         }
      }
   }
   .bubble-informations {
      margin-right: 0 !important;
      margin-left: 1rem;
      order: 2;
   }
}
</style>
