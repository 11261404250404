<template>
   <div id="checkout-pnr-information-container">
      <i class="gts-icon icon-status-info"></i>
      <span>{{ PnrInformations(handlerPnrCount) }}</span>
   </div>
</template>

<script>
export default {
   name: "CheckoutPnrInfoBadge",
   computed: {
      handlerPnrCount() {
         return this.$store.state.flight.checkout.response.pnrCount;
      },
   },
   methods: {
      PnrInformations(val) {
         return this.$t("checkoutBasket_basket_pnrInfos").replace("#VALUE#", val);
      },
   },
};
</script>

<style lang="scss" scoped>
#checkout-pnr-information-container {
   background-color: #03ecac;
   border-color: #02bc89;
   border-radius: 0.5rem;
   border-style: solid;
   border-width: 1px;
   padding: 1rem 1.125rem;
   display: flex;
   align-items: center;

   @media (min-width: 992px) {
      display: none;
   }

   i {
      $infoIcon: 28px !important;
      width: $infoIcon;
      height: $infoIcon;
      filter: brightness(0.25);
      margin-right: 0.75rem;
   }

   span {
      font-weight: 600;
      font-size: var(--large);
      color: #0f2d40;
   }
}
</style>
