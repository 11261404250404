<template>
   <div id="flight-filters-container">
      <template v-for="(filter, filterIndex) in filters">
         <FlightFilterLeg :filter-options="filter" :key="filterIndex" />
      </template>
   </div>
</template>

<script>
import FlightFilterLeg from "@/components/structural/flight/search/filters/FlightFilterLeg.vue";
export default {
   name: "FlightFilters",
   props: {
      legs: Object,
   },
   components: {
      FlightFilterLeg,
   },
   data() {
      return {
         filters: null,
      };
   },

   methods: {
      handlerGenerateFiltersPrimitive() {
         this.filters = {};
         //Create filters for each leg from filterPrimitive
         for (let [index, leg] of Object.entries(this.legs)) {
            const filterPrimitive = {
               routes: leg.route,
               date: leg.date,
               index: index,
               filters: {
                  airlines: {},
                  cabintype: {},
                  connections: {},
                  baggage: {},
                  hours: {
                     departure: [0, 24],
                     arrival: [0, 24],
                  },
               },
            };
            this.$set(this.filters, index, filterPrimitive);
         }
      },

      handlerGenerateFilters(legIndex, flight) {
         // Generate Airline Filters
         const airlineObj = this.filters[legIndex].filters.airlines;
         const isAirlineExist = Object.prototype.hasOwnProperty.call(airlineObj, flight.marketingAirlineCode);
         if (!isAirlineExist) {
            const airlineFilterObj = {
               airlineName: flight.marketingAirlineCode,
               count: 0,
               checked: true,
            };
            this.$set(airlineObj, flight.marketingAirlineCode, airlineFilterObj);
         }

         // Generate Cabintype Filters
         const cabinType = this.filters[legIndex].filters.cabintype;
         const isCabinTypeExist = Object.prototype.hasOwnProperty.call(cabinType, flight.cabinType);
         if (!isCabinTypeExist) {
            this.$set(cabinType, flight.cabinType, { type: flight.cabinType, checked: true });
         }

         // Generate Baggage Filters
         const baggage = this.filters[legIndex].filters.baggage;
         var isBaggageExist, baggagePropName, baggageFmt;
         if (flight.baggageInfo == "0KG" || flight.baggageInfo == "0PC" || flight.baggageInfo == "-1KG") {
            isBaggageExist = Object.prototype.hasOwnProperty.call(baggage, "nobaggage");
            baggagePropName = "nobaggage";
            baggageFmt = this.$i18n.t("searchFiltersFlight_noBaggage");
         } else {
            isBaggageExist = Object.prototype.hasOwnProperty.call(baggage, "withbaggage");
            baggagePropName = "withbaggage";
            baggageFmt = this.$i18n.t("searchFiltersFlight_withBaggage");
         }
         if (!isBaggageExist) {
            this.$set(baggage, baggagePropName, { name: baggageFmt, checked: true });
         }

         // Generate Connection Filters
         const connections = this.filters[legIndex].filters.connections;
         const isConnectionExist = Object.prototype.hasOwnProperty.call(connections, flight.segments.length - 1);
         if (!isConnectionExist) {
            this.$set(connections, flight.segments.length - 1, { connectionName: flight.segments.length - 1, checked: true });
         }

         this.$store.commit("flight/updateFilters", { index: legIndex, filter: this.filters[legIndex] });
      },
   },
   watch: {
      legs: {
         handler: function (legs) {
            if (this.filters == null) {
               this.handlerGenerateFiltersPrimitive();
               for (let [legIndex, leg] of Object.entries(legs)) {
                  for (let [flightIndex, flight] of Object.entries(leg.flights)) {
                     legIndex;
                     flightIndex;
                     flight;
                     this.handlerGenerateFilters(legIndex, flight);
                  }
               }
            } else {
               for (let [legIndex, leg] of Object.entries(legs)) {
                  for (let [flightIndex, flight] of Object.entries(leg.flights)) {
                     legIndex;
                     flightIndex;
                     flight;
                     this.handlerGenerateFilters(legIndex, flight);
                  }
               }
            }
         },
         immediate: true,
         deep: true,
      },
   },
};
</script>

<style lang="scss" scoped>
#flight-filters-container {
   position: sticky;
   top: 1rem;
   height: fit-content;
}
</style>
