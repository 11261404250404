<template>
    <div class="page-subheader-wrapper">
        <div class="page-subheader-inner">
            <div class="page-subheader-title">
                <h2>{{ sectionTitle }}</h2>
            </div>
            <div class="page-subheader-slot">
                <div class="page-subheader-slot-inner">
                    <slot></slot>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'UIPageSubHeader',
    props:{
        sectionTitle: { String }
    }
}
</script>

<style lang="scss" scoped>
    .page-subheader-wrapper{
        margin-bottom: 24px;
        background-color: white;
        border-radius: 8px;
        
        .page-subheader-inner{
            padding: 48px;
            .page-subheader-title{
                margin-bottom: 48px;
                h2{
                    margin: 0;
                    font-size: 30px;
                    font-weight: 800;
                }
            }
            .page-subheader-slot{
                .page-subheader-slot-inner{
                    .page-subheader-navigate-wrapper{
                        .page-subheader-navigate-inner{
                            .navigate-list{
                                width: 100%;
                                display: flex;
                                padding-bottom: 16px;
                                margin-bottom: 32px;
                                border-bottom: 1px solid #cbcccd;

                                .navigate-list-item{
                                    display: flex;
                                    align-items: center;
                                    justify-content: flex-start;
                                    margin-right: 24px;
                                    
                                    &:last-child{
                                        margin-right: 0;
                                    }
                                    & > input{
                                        margin-right: 8px;
                                    }
                                }
                            }
                            .navigate-content{
                                display: flex;
                                align-items: center;
                                justify-content: space-between;
                                .navigate-search-item{
                                    display: flex;
                                    flex-direction: column;
                                    width: 30%;
                                }
                                .navigate-search-cta{}
                            }
                        }
                    }
                }
            }
        }
    }
</style>