<template>
   <div class="calendar-overlay">
      <div>
         <div class="wrapper">
            <div class="wrapper-header">
               <div class="selected-days-value">
                  <h1 style="font-weight: bolder; font-size: 16px">
                     {{
                        displaySelectedDate.secondDate == ""
                           ? displaySelectedDate.nights
                           : displaySelectedDate.nights + " " + $t("hotelCard_lblNights")
                     }}
                  </h1>
                  <span style="height: 20px">
                     {{ displaySelectedDate.firstDate + displaySelectedDate.secondDate }}
                  </span>
               </div>
               <div id="popup-close-btn" @click="close"></div>
            </div>
            <div class="spacer"></div>
            <div v-if="!isLoaded">
               <div class="monthTitle">
                  <div class="icons">
                     <span
                        id="prev"
                        v-if="currentDate <= presentDate ? false : true"
                        class="g-select-box-arrow gts-icon icon-arrow-left icon-xs"
                        @click="previousMonth()" />
                     <div :class="currentDate <= presentDate ? 'current-date-off' : 'current-date'">
                        <p id="firstMonthLabel">{{ CurrentMonthName }}</p>
                     </div>
                     <div class="current-date">
                        <p>{{ NextMonthName }}</p>
                     </div>
                     <span id="next" class="g-select-box-arrow gts-icon icon-arrow-right icon-xs" @click="nextMonth()"> </span>
                  </div>
               </div>
               <div class="wrapper-month" @mouseleave="clearBetweens">
                  <div class="months" @mouseleave="clearBetweens">
                     <div class="calendar">
                        <ul class="weeks">
                           <li>{{ weekDays[0] }}</li>
                           <li>{{ weekDays[1] }}</li>
                           <li>{{ weekDays[2] }}</li>
                           <li>{{ weekDays[3] }}</li>
                           <li>{{ weekDays[4] }}</li>
                           <li>{{ weekDays[5] }}</li>
                           <li>{{ weekDays[6] }}</li>
                        </ul>

                        <ul class="days">
                           <li
                              v-for="(day, index) in days"
                              :key="index"
                              :class="{
                                 IsToday: day.IsToday,
                                 textDecoration: day.textDecoration,
                                 inactive: day.inactive,
                                 blink: day.blink,
                                 active: day.active,
                                 between: day.between,
                              }"
                              @click="selectDate(day)"
                              @mouseover="toggleBetween(day)">
                              {{ day.number }}
                           </li>
                        </ul>
                     </div>
                  </div>
                  <div id="secondMonth" class="months" @mouseleave="clearBetweens">
                     <div class="calendar">
                        <ul class="weeks">
                           <li>{{ weekDays[0] }}</li>
                           <li>{{ weekDays[1] }}</li>
                           <li>{{ weekDays[2] }}</li>
                           <li>{{ weekDays[3] }}</li>
                           <li>{{ weekDays[4] }}</li>
                           <li>{{ weekDays[5] }}</li>
                           <li>{{ weekDays[6] }}</li>
                        </ul>
                        <ul class="days">
                           <li
                              v-for="(day, index) in nextMonthDays"
                              :key="index"
                              :class="{
                                 inactive: day.inactive,
                                 active: day.active,
                                 textDecoration: day.textDecoration,
                                 blink: day.blink,
                                 between: day.between,
                              }"
                              @click="selectDate(day)"
                              @mouseover="toggleBetween(day)">
                              {{ day.number }}
                           </li>
                        </ul>
                     </div>
                  </div>
               </div>
               <div class="wrapper-bottom">
                  <div class="spacer"></div>
                  <div class="bottom-items">
                     <div class="dateAlert">
                        <i
                           :class="
                              isInvalidRestriction
                                 ? 'gts-icon icon-info-alert icon-m'
                                 : isSearchActive
                                 ? 'gts-icon icon-validated'
                                 : 'gts-icon icon-gts-info-icon-black icon-m'
                           "></i>
                        <span :class="isInvalidRestriction ? 'not-validated' : isSearchActive ? 'validated' : ''"
                           ><p>{{ validatedLabel }}</p></span
                        >
                     </div>
                     <!-- button default değil disabled gibi olucak -->
                     <div id="wrapperBtn">
                        <UIButton
                           className="hotel"
                           :text="$t('common_search')"
                           @click.native="handlergetHotelSearch()"
                           :isDisabled="!isSearchActive"
                           :notRounded="true" />
                     </div>
                  </div>
               </div>
            </div>
            <!-- // loader -->
            <div class="widget-loader-wrapper" v-else>
               <span class="g-radial-loader"> </span>
            </div>
         </div>
      </div>
   </div>
</template>

<script>
import { hotelService } from "@/service/index.js";
import UIButton from "@/components/unitary/Button.vue";
import i18n from "@/fmt/i18n.js";
export const dateTR = {
   days: ["Paz", "Pts", "Sal", "Çar", "Per", "Cum", "Cts"],
   month: ["Ocak", "Şubat", "Mart", "Nisan", "Mayıs", "Haziran", "Temmuz", "Ağustos", "Eylül", "Ekim", "Kasım", "Aralık"],
};
export const dateEN = {
   days: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
   month: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
};
export const dateDE = {
   days: ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"],
   month: ["Januar", "Februar", "März", "April", "Mai", "Juni", "Juli", "August", "September", "Oktober", "November", "Dezember"],
};
export default {
   props: {
      roomDetail: { type: Object },
      isRecommendedRoom: { type: Boolean },
      hotelId: { type: Number },
   },
   components: {
      UIButton,
   },
   data() {
      return {
         isInvalidRestriction: false,
         weekDays: [],
         isLoaded: false,
         restrictionsFmts: {
            stopType: this.$t("hotelCard_lblCalendarStopType"),
            minStay: this.$t("hotelCard_lblCalendarMinStay"),
            release: this.$t("hotelCard_lblCalendarRelease"),
            between: this.$t("hotelCard_lblCalendarBetween"),
            success: this.$t("hotelCard_lblCalendarSuccess"),
         },
         checkRestrictionStatus: {
            stopType: false,
            minStay: false,
            release: false,
            between: false,
         },
         isSearchActive: false,
         TodaysDate: null,
         validatedLabel: this.$t("hotelCard_lblCalendarInfo"),
         searchDates: {
            firstSearchDate: "",
            secondSearchDate: "",
         },
         searchisRecommendedRoom: this.isRecommendedRoom,
         room: this.roomDetail,
         restrictionEnabled: false,
         monthHolder: ["", ""],
         presentDate: null,
         CurrentMonthName: "",
         NextMonthName: "",
         currentDate: new Date(),
         nextDate: new Date(),
         days: [],
         nextMonthDays: [],
         firstSelectedDate: null,
         secondSelectedDate: null,
         displaySelectedDate: {
            firstDate: "",
            secondDate: "",
            nights: this.$t("hotelCard_lblCalendarSelectNights"),
         },
         months: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
      };
   },
   created() {
      this.getPresentDate();
      switch (i18n.locale) {
         case "tr":
            this.weekDays = dateTR.days;
            this.months = dateTR.month;
            break;
         case "en":
            this.weekDays = dateEN.days;
            this.months = dateEN.month;
            break;
         case "de":
            this.weekDays = dateDE.days;
            this.months = dateDE.month;
            break;
         default:
            this.weekDays = dateDE.days;
            this.monthss = dateDE.month;
            break;
      }
   },
   methods: {
      validatedTexts() {
         if (this.checkRestrictionStatus.stopType) {
            this.isSearchActive = false;
            this.validatedLabel = this.restrictionsFmts.stopType;
            this.isInvalidRestriction = true;
         } else if (this.checkRestrictionStatus.minStay) {
            this.isSearchActive = false;
            this.isInvalidRestriction = true;
            this.validatedLabel = this.restrictionsFmts.minStay.replace("#VALUE#", this.firstSelectedDate.minStayCount);
         } else if (this.checkRestrictionStatus.release) {
            this.isSearchActive = false;
            this.isInvalidRestriction = true;
            this.validatedLabel = this.restrictionsFmts.release.replace("#VALUE#", this.firstSelectedDate.releaseDayCount);
         } else if (this.checkRestrictionStatus.between) {
            this.isSearchActive = false;
            this.isInvalidRestriction = true;
            this.validatedLabel = this.restrictionsFmts.between;
         } else {
            this.isInvalidRestriction = false;
            this.validatedLabel = this.restrictionsFmts.success;
         }
      },
      findDates() {
         for (let index = 0; index < this.days.length; index++) {
            if (this.days[index].number == 1) {
               this.searchDates.firstSearchDate = this.days[index];
               break;
            }
         }
         for (let index = this.nextMonthDays.length - 1; index >= 0; index--) {
            if (this.nextMonthDays[index].number !== "") {
               this.searchDates.secondSearchDate = this.nextMonthDays[index];
               break;
            }
         }
      },
      // Tarih aralığı seçildikten sonra buton için yazılan search fonksiyonu
      handlergetHotelSearch() {
         this.$store.state.hotel.reSetSearchQuery;
         var hotelSearchquery = this.$store.state.hotel.reSetSearchQuery;
         hotelSearchquery.checkInDate = this.firstSelectedDate.reqDate;
         hotelSearchquery.checkOutDate = this.secondSelectedDate.reqDate;

         this.$store.commit("hotel/reSetSearchQuery", hotelSearchquery);
         this.$store.commit("hotel/setIsCalendarTriggered", true);
      },
      // component yüklendiğinde ve aylar arası geçiş yapıldığında takvimin günlerini restrictionlara göre günceller
      handlergetAvailableHotels() {
         this.isLoaded = true;
         this.findDates();
         var query = {
            fromDate: this.searchDates.firstSearchDate.reqDate,
            toDate: this.searchDates.secondSearchDate.reqDate,
            roomIdentifier: this.room.roomIdentifier,
            searchUuid: this.$store.state.hotel.searchUuid,
            recommended: this.searchisRecommendedRoom,
            hotelId: this.hotelId,
         };
         hotelService
            .hotelRoomAvailable(query)
            .then((response) => {
               if (!response.result.success) {
                  this.isLoaded = false;

                  return;
               } else {
                  this.checkRestrictions(this.days, response);
                  this.checkRestrictions(this.nextMonthDays, response);
                  this.closeReleasedDays();
                  this.isLoaded = false;
                  this.closeUntitledDays();
               }
            })
            .finally(() => {
               this.isLoaded = false;
            });
      },
      checkRestrictions(selectedCalendar, res) {
         for (let date in res.availabilities) {
            for (let index = 0; index < selectedCalendar.length; index++) {
               if (selectedCalendar[index].reqDate == res.availabilities[date].effectiveDate.split("T")[0]) {
                  if (res.availabilities[date].stopType == 2 || res.availabilities[date].stopType == 3) {
                     selectedCalendar[index].inactive = true;
                     selectedCalendar[index].textDecoration = true;
                  } else {
                     selectedCalendar[index].releaseDayCount = res.availabilities[date].releaseDayCount;
                     selectedCalendar[index].minStayCount = res.availabilities[date].minStayCount;
                     selectedCalendar[index].stopType = res.availabilities[date].stopType;
                     selectedCalendar[index].availableQuota = res.availabilities[date].availableQuota;
                  }
                  if (res.availabilities[date].availableQuota <= 0) {
                     selectedCalendar[index].inactive = true;
                     selectedCalendar[index].textDecoration = true;
                  }
               }
            }
         }

         //release günü kapatma
      },
      // genelleştir
      closeUntitledDays() {
         for (let index = 0; index < this.days.length; index++) {
            if (
               this.days[index].availableQuota == null &&
               this.days[index].minStayCount == null &&
               this.days[index].releaseDayCount == null &&
               this.days[index].stopType == null
            ) {
               this.days[index].textDecoration = true;
               this.days[index].inactive = true;
            }
         }
         for (let index = 0; index < this.nextMonthDays.length; index++) {
            if (
               this.nextMonthDays[index].availableQuota == null &&
               this.nextMonthDays[index].minStayCount == null &&
               this.nextMonthDays[index].releaseDayCount == null &&
               this.nextMonthDays[index].stopType == null
            ) {
               this.nextMonthDays[index].textDecoration = true;
               this.nextMonthDays[index].inactive = true;
            }
         }
      },
      closeReleasedDays() {
         if (this.TodaysDate.monthIndex == this.days[15].monthIndex) {
            const relCount = this.TodaysDate.releaseDayCount;
            for (let index = 1; index < relCount + 2; index++) {
               this.days[this.TodaysDate.number + index].textDecoration = true;
               this.days[this.TodaysDate.number + index].inactive = true;
            }
         }
      },
      close() {
         this.$emit("close");
      },
      clearBetweens() {
         if (this.secondSelectedDate == null) {
            this.deactivateAllBetween();
            this.firstSelectedDate.active = true;
         }
      },
      toggleBetween(day) {
         // ikinci ay için between kapatma
         if (this.secondSelectedDate == null) {
            if (this.firstSelectedDate != null) {
               if (this.firstSelectedDate.monthIndex == day.monthIndex) {
                  if (day != this.firstSelectedDate) {
                     this.deactivateAllBetween();
                     this.activeCustomBetween(this.firstSelectedDate, day);
                     this.firstSelectedDate.active = true;
                     if (this.secondSelectedDate != null) {
                        this.secondSelectedDate.active = true;
                     }
                  }
               } else {
                  this.deactivateAllBetween();
                  this.activeCustomBetween(this.firstSelectedDate, day);
                  this.firstSelectedDate.active = true;
                  if (this.secondSelectedDate != null) {
                     this.secondSelectedDate.active = true;
                  }
               }
            }
         }
      },
      activeCustomBetween(startDate, endDate) {
         if (startDate && endDate) {
            if (startDate.month !== endDate.month) {
               const startMonthDays = startDate.month === this.days[15].month ? this.days : this.nextMonthDays;
               const endMonthDays = endDate.month === this.days[15].month ? this.days : this.nextMonthDays;

               startMonthDays.forEach((day) => {
                  if (day.month === startDate.month && day.number > startDate.number && day.number != "") {
                     day.between = true;
                  }
               });

               endMonthDays.forEach((day) => {
                  if (day.month === endDate.month && day.number < endDate.number && day.number != "") {
                     day.between = true;
                  }
               });
            } else {
               const smallerDate = startDate.number < endDate.number ? startDate : endDate;
               const greaterDate = startDate.number < endDate.number ? endDate : startDate;
               if (startDate.month == this.days[15].month && endDate.month == this.days[15].month) {
                  for (let i = 0; i < this.days.length; i++) {
                     const day = this.days[i];

                     if (
                        day.number > smallerDate.number &&
                        day.number < greaterDate.number &&
                        day.month === startDate.month &&
                        day.year === startDate.year
                     ) {
                        day.between = true;
                     } else {
                        day.between = false;
                     }
                  }
               } else if (startDate.month == this.nextMonthDays[15].month && endDate.month == this.nextMonthDays[15].month) {
                  for (let i = 0; i < this.nextMonthDays.length; i++) {
                     const day = this.nextMonthDays[i];

                     if (
                        day.number > smallerDate.number &&
                        day.number < greaterDate.number &&
                        day.month === startDate.month &&
                        day.year === startDate.year
                     ) {
                        day.between = true;
                     } else {
                        day.between = false;
                     }
                  }
               }
            }
         }
      },
      getPresentDate() {
         this.presentDate = new Date();
      },
      getMonthStartDay(year, month) {
         return new Date(year, month, 1).getDay();
      },
      getMonthEndDay(year, month) {
         return new Date(year, month + 1, 0).getDay();
      },
      populateNextMonthDays() {
         this.nextMonthDays = this.populateMonthDays(1);
         this.NextMonthName = this.nextMonthDays[15].month + " " + this.nextMonthDays[15].year;
         this.monthHolder[1] = this.nextMonthDays[15].month;
      },
      populateDays() {
         this.days = this.populateMonthDays(0);
         this.CurrentMonthName = this.days[15].month + " " + this.days[15].year;
         this.monthHolder[0] = this.days[15].month;
         for (let index = 0; index < this.days.length; index++) {
            if (this.days[index].IsToday == true) {
               this.TodaysDate = this.days[index];
            }
         }
      },
      populateMonthDays(offset) {
         const targetDate = new Date(this.currentDate);
         targetDate.setMonth(targetDate.getMonth() + offset);

         const targetMonth = targetDate.getMonth();
         const targetYear = targetDate.getFullYear();
         const totalDays = new Date(targetYear, targetMonth + 1, 0).getDate();
         const firstDayOfMonth = new Date(targetYear, targetMonth, 1);
         const startDay = (firstDayOfMonth.getDay() + 6) % 7;
         const reqDate = targetYear + "-" + (targetMonth < 10 ? "0" + (targetMonth + 1) : targetMonth);

         const monthDays = [];

         for (let i = 0; i < startDay; i++) {
            monthDays.push({
               number: "",
               inactive: true,
               month: this.months[targetMonth],
               monthIndex: targetMonth,
               year: targetYear,
               between: false,
               reqDate: reqDate + "-" + (i < 10 ? "0" + i : i),
               releaseDayCount: null,
               minStayCount: null,
               stopType: null,
               availableQuota: null,
            });
         }

         for (let i = 1; i <= totalDays; i++) {
            const currentDate = new Date(targetYear, targetMonth, i);
            const isBeforePresentDate = currentDate < this.presentDate;
            const isToday = currentDate.toDateString() === this.presentDate.toDateString();

            monthDays.push({
               number: i,
               active: false,
               month: this.months[targetMonth],
               monthIndex: targetMonth,
               year: targetYear,
               between: false,
               inactive: isBeforePresentDate && !isToday,
               textDecoration: isBeforePresentDate && !isToday,
               IsToday: isToday,
               reqDate: reqDate + "-" + (i < 10 ? "0" + i : i),
               releaseDayCount: null,
               minStayCount: null,
               stopType: null,
               availableQuota: null,
               blink: false,
            });
         }

         const endDay = new Date(targetYear, targetMonth, totalDays).getDay();
         for (let i = endDay + 1; i < 7; i++) {
            monthDays.push({
               number: "",
               active: false,
               inactive: true,
               month: this.months[targetMonth],
               monthIndex: targetMonth,
               year: targetYear,
               between: false,
               reqDate: reqDate + "-" + (i < 10 ? "0" + i : i),
               releaseDayCount: null,
               minStayCount: null,
               stopType: null,
               availableQuota: null,
            });
         }
         return monthDays;
      },
      previousMonth() {
         const currentMonth = this.currentDate.getMonth();
         this.currentDate.setMonth(currentMonth - 1);
         this.populateDays();
         this.populateNextMonthDays();
         this.firstSelectedDate = null;
         this.secondSelectedDate = null;
         this.handlergetAvailableHotels();
      },
      nextMonth() {
         const currentMonth = this.currentDate.getMonth();
         this.currentDate.setMonth(currentMonth + 1);
         this.populateNextMonthDays();
         this.populateDays();
         this.firstSelectedDate = null;
         this.secondSelectedDate = null;
         this.handlergetAvailableHotels();
      },
      selectDate(selectedDay) {
         if (this.monthHolder[1] == "January") {
            if (!this.firstSelectedDate) {
               this.firstSelectedDate = selectedDay;
               selectedDay.active = true;
            } else if (!this.secondSelectedDate) {
               if (selectedDay.monthIndex == this.firstSelectedDate.monthIndex) {
                  if (selectedDay.number < this.firstSelectedDate.number) {
                     this.firstSelectedDate.active = false;
                     this.firstSelectedDate = selectedDay;
                     selectedDay.active = true;
                     this.firstSelectedDate.active = true;
                     return;
                  } else if (selectedDay.number === this.firstSelectedDate.number) {
                     this.firstSelectedDate = null;
                     selectedDay.active = false;
                     return;
                  } else {
                     this.secondSelectedDate = selectedDay;
                     selectedDay.active = true;
                     return;
                  }
               } else if (selectedDay.monthIndex < this.firstSelectedDate.monthIndex) {
                  this.secondSelectedDate = selectedDay;
                  selectedDay.active = true;
                  return;
               } else if (selectedDay.monthIndex > this.firstSelectedDate.monthIndex) {
                  this.firstSelectedDate.active = false;
                  this.firstSelectedDate = selectedDay;
                  selectedDay.active = true;
                  this.firstSelectedDate.active = true;
               }
            } else {
               if (selectedDay.monthIndex == this.secondSelectedDate.monthIndex && selectedDay.monthIndex == this.firstSelectedDate.monthIndex) {
                  if (selectedDay.number < this.firstSelectedDate.number) {
                     this.firstSelectedDate.active = false;
                     this.firstSelectedDate = selectedDay;
                     selectedDay.active = true;
                     this.firstSelectedDate.active = true;
                  } else if (selectedDay.number > this.secondSelectedDate.number) {
                     this.secondSelectedDate.active = false;
                     this.secondSelectedDate = selectedDay;
                     selectedDay.active = true;
                     this.secondSelectedDate.active = true;
                  } else if (selectedDay.number == this.firstSelectedDate.number) {
                     this.secondSelectedDate = null;
                     this.firstSelectedDate = null;
                     selectedDay.active = false;
                  } else if (selectedDay.number == this.secondSelectedDate.number) {
                     this.secondSelectedDate = null;
                     selectedDay.active = false;
                  } else {
                     this.secondSelectedDate.active = false;
                     this.secondSelectedDate = selectedDay;
                     selectedDay.active = true;
                     this.secondSelectedDate.active = true;
                  }
               } else if (selectedDay.monthIndex < this.secondSelectedDate.monthIndex && selectedDay.monthIndex < this.firstSelectedDate.monthIndex) {
                  this.secondSelectedDate.active = false;
                  this.secondSelectedDate = selectedDay;
                  selectedDay.active = true;
                  this.secondSelectedDate.active = true;
               } else if (
                  selectedDay.monthIndex == this.secondSelectedDate.monthIndex &&
                  selectedDay.monthIndex < this.firstSelectedDate.monthIndex
               ) {
                  if (selectedDay.number == this.secondSelectedDate.number) {
                     this.secondSelectedDate.active = false;
                     this.secondSelectedDate = null;
                  } else {
                     this.secondSelectedDate.active = false;
                     this.secondSelectedDate = selectedDay;
                     selectedDay.active = true;
                     this.secondSelectedDate.active = true;
                  }
               } else if (
                  selectedDay.monthIndex == this.firstSelectedDate.monthIndex &&
                  selectedDay.monthIndex > this.secondSelectedDate.monthIndex
               ) {
                  if (this.firstSelectedDate.number == selectedDay.number) {
                     this.secondSelectedDate = null;

                     this.firstSelectedDate = null;
                  } else if (selectedDay.number < this.firstSelectedDate.number) {
                     this.firstSelectedDate.active = false;
                     this.firstSelectedDate = selectedDay;
                     selectedDay.active = true;
                     this.firstSelectedDate.active = true;
                  } else {
                     this.secondSelectedDate.active = false;
                     this.secondSelectedDate = selectedDay;
                     selectedDay.active = true;
                     this.secondSelectedDate.active = true;
                  }
               } else if (selectedDay.monthIndex > this.firstSelectedDate.monthIndex && selectedDay.monthIndex > this.secondSelectedDate.monthIndex) {
                  this.firstSelectedDate.active = false;
                  this.firstSelectedDate = selectedDay;
                  selectedDay.active = true;
                  this.firstSelectedDate.active = true;
               }
            }
         }
         ////////
         else {
            if (!this.firstSelectedDate) {
               this.firstSelectedDate = selectedDay;
               selectedDay.active = true;
            } else if (!this.secondSelectedDate) {
               if (selectedDay.monthIndex == this.firstSelectedDate.monthIndex) {
                  if (selectedDay.number < this.firstSelectedDate.number) {
                     this.firstSelectedDate.active = false;
                     this.firstSelectedDate = selectedDay;
                     selectedDay.active = true;
                     this.firstSelectedDate.active = true;
                     return;
                  } else if (selectedDay.number === this.firstSelectedDate.number) {
                     this.firstSelectedDate = null;
                     selectedDay.active = false;
                     return;
                  } else {
                     this.secondSelectedDate = selectedDay;
                     selectedDay.active = true;
                     return;
                  }
               } else if (selectedDay.monthIndex > this.firstSelectedDate.monthIndex) {
                  this.secondSelectedDate = selectedDay;
                  selectedDay.active = true;
                  return;
               } else if (selectedDay.monthIndex < this.firstSelectedDate.monthIndex) {
                  this.firstSelectedDate.active = false;
                  this.firstSelectedDate = selectedDay;
                  selectedDay.active = true;
                  this.firstSelectedDate.active = true;
               }
            } else {
               if (selectedDay.monthIndex == this.secondSelectedDate.monthIndex && selectedDay.monthIndex == this.firstSelectedDate.monthIndex) {
                  if (selectedDay.number < this.firstSelectedDate.number) {
                     this.firstSelectedDate.active = false;
                     this.firstSelectedDate = selectedDay;
                     selectedDay.active = true;
                     this.firstSelectedDate.active = true;
                  } else if (selectedDay.number > this.secondSelectedDate.number) {
                     this.secondSelectedDate.active = false;
                     this.secondSelectedDate = selectedDay;
                     selectedDay.active = true;
                     this.secondSelectedDate.active = true;
                  } else if (selectedDay.number == this.firstSelectedDate.number) {
                     this.secondSelectedDate = null;
                     this.firstSelectedDate = null;

                     selectedDay.active = false;
                  } else if (selectedDay.number == this.secondSelectedDate.number) {
                     this.secondSelectedDate = null;
                     selectedDay.active = false;
                  } else {
                     this.secondSelectedDate.active = false;
                     this.secondSelectedDate = selectedDay;
                     selectedDay.active = true;
                     this.secondSelectedDate.active = true;
                  }
               } else if (selectedDay.monthIndex > this.secondSelectedDate.monthIndex && selectedDay.monthIndex > this.firstSelectedDate.monthIndex) {
                  this.secondSelectedDate.active = false;
                  this.secondSelectedDate = selectedDay;
                  selectedDay.active = true;
                  this.secondSelectedDate.active = true;
               } else if (
                  selectedDay.monthIndex == this.secondSelectedDate.monthIndex &&
                  selectedDay.monthIndex > this.firstSelectedDate.monthIndex
               ) {
                  if (selectedDay.number == this.secondSelectedDate.number) {
                     this.secondSelectedDate.active = false;
                     this.secondSelectedDate = null;
                  } else {
                     this.secondSelectedDate.active = false;
                     this.secondSelectedDate = selectedDay;
                     selectedDay.active = true;
                     this.secondSelectedDate.active = true;
                  }
               } else if (
                  selectedDay.monthIndex == this.firstSelectedDate.monthIndex &&
                  selectedDay.monthIndex < this.secondSelectedDate.monthIndex
               ) {
                  if (this.firstSelectedDate.number == selectedDay.number) {
                     this.secondSelectedDate.active = false;
                     this.firstSelectedDate = null;
                     this.secondSelectedDate = null;
                  } else if (selectedDay.number < this.firstSelectedDate.number) {
                     this.firstSelectedDate.active = false;
                     this.firstSelectedDate = selectedDay;
                     selectedDay.active = true;
                     this.firstSelectedDate.active = true;
                  } else {
                     this.secondSelectedDate.active = false;
                     this.secondSelectedDate = selectedDay;
                     selectedDay.active = true;
                     this.secondSelectedDate.active = true;
                  }
               } else if (selectedDay.monthIndex < this.firstSelectedDate.monthIndex && selectedDay.monthIndex < this.secondSelectedDate.monthIndex) {
                  this.firstSelectedDate.active = false;
                  this.firstSelectedDate = selectedDay;
                  selectedDay.active = true;
                  this.firstSelectedDate.active = true;
               }
            }
         }
      },
      getCurrentMonth() {
         const currentMonthIndex = this.currentDate.getMonth();
         const currentYear = this.currentDate.getFullYear();
         const currentMonth = this.months[currentMonthIndex];
         return `${currentMonth} ${currentYear}`;
      },
      getNextMonth() {
         this.nextDate = new Date(this.currentDate);
         this.nextDate.setMonth(this.nextDate.getMonth() + 1);

         const nextMonthIndex = this.nextDate.getMonth();
         const nextYear = this.nextDate.getFullYear();
         const nextMonth = this.months[nextMonthIndex];

         return `${nextMonth} ${nextYear}`;
      },
      deactivateAllBetween() {
         this.days.forEach((day) => {
            day.between = false;
            day.active = false;
         });

         this.nextMonthDays.forEach((day) => {
            day.between = false;
            day.active = false;
         });
      },

      activateDaysInRange(startDate, endDate) {
         if (startDate && endDate) {
            if (startDate.month !== endDate.month) {
               const startMonthDays = startDate.month === this.days[15].month ? this.days : this.nextMonthDays;
               const endMonthDays = endDate.month === this.days[15].month ? this.days : this.nextMonthDays;

               startMonthDays.forEach((day) => {
                  if (day.month === startDate.month && day.number > startDate.number && day.number != "") {
                     day.active = true;
                     day.between = true;
                  }
               });

               endMonthDays.forEach((day) => {
                  if (day.month === endDate.month && day.number < endDate.number && day.number != "") {
                     day.active = true;
                     day.between = true;
                  }
               });
            } else {
               const smallerDate = startDate.number < endDate.number ? startDate : endDate;
               const greaterDate = startDate.number < endDate.number ? endDate : startDate;
               if (startDate.month == this.days[15].month && endDate.month == this.days[15].month) {
                  for (let i = 0; i < this.days.length; i++) {
                     const day = this.days[i];

                     if (
                        day.number > smallerDate.number &&
                        day.number < greaterDate.number &&
                        day.month === startDate.month &&
                        day.year === startDate.year
                     ) {
                        day.active = true;
                        day.between = true;
                     } else {
                        day.active = false;
                        day.between = false;
                     }
                  }
               } else if (startDate.month == this.nextMonthDays[15].month && endDate.month == this.nextMonthDays[15].month) {
                  for (let i = 0; i < this.nextMonthDays.length; i++) {
                     const day = this.nextMonthDays[i];

                     if (
                        day.number > smallerDate.number &&
                        day.number < greaterDate.number &&
                        day.month === startDate.month &&
                        day.year === startDate.year
                     ) {
                        day.active = true;
                        day.between = true;
                     } else {
                        day.active = false;
                        day.between = false;
                     }
                  }
               }
            }
            this.firstSelectedDate.active = true;
            this.secondSelectedDate.active = true;
         }
      },
      deactivateDaysBetween(startDate, endDate) {
         if (startDate && endDate) {
            if (startDate.month !== endDate.month) {
               const startMonthDays = startDate.month === this.days[15].month ? this.days : this.nextMonthDays;
               const endMonthDays = endDate.month === this.days[15].month ? this.days : this.nextMonthDays;

               startMonthDays.forEach((day, index) => {
                  if (day.month === startDate.month && index > startMonthDays.indexOf(startDate) && index < startMonthDays.indexOf(endDate)) {
                     day.active = false;
                     day.between = false;
                  }
               });

               endMonthDays.forEach((day, index) => {
                  if (day.month === endDate.month && index > endMonthDays.indexOf(startDate) && index < endMonthDays.indexOf(endDate)) {
                     day.active = false;
                     day.between = false;
                  }
               });
            } else {
               if (startDate.month === this.days[15].month && endDate.month === this.days[15].month) {
                  const startIndex = this.days.indexOf(startDate);
                  const endIndex = this.days.indexOf(endDate);
                  for (let i = startIndex + 1; i < endIndex; i++) {
                     this.days[i].active = false;
                     this.days[i].between = false;
                  }
               } else if (startDate.month === this.nextMonthDays[15].month && endDate.month === this.nextMonthDays[15].month) {
                  const startIndex = this.nextMonthDays.indexOf(startDate);
                  const endIndex = this.nextMonthDays.indexOf(endDate);
                  for (let j = startIndex + 1; j < endIndex; j++) {
                     this.nextMonthDays[j].active = false;
                     this.nextMonthDays[j].between = false;
                  }
               }
            }
         }
      },
      validateRestrictionsBetweenDates(firstDay, lastDay) {
         const validateArray = [];
         const filteredArray = [];
         // iki seçim de ilk ayda ise
         if (firstDay.month == this.days[15].month && lastDay.month == this.days[15].month) {
            for (let index = 0; index < this.days.length; index++) {
               if (firstDay.number <= this.days[index].number && lastDay.number >= this.days[index].number) {
                  validateArray.push(this.days[index]);
               }
            }
            for (let index = 0; index < validateArray.length; index++) {
               if (validateArray[index].textDecoration == true) {
                  filteredArray.push(validateArray[index]);
               }
            }
            // iki seçim de ikinci ayda ise
         } else if (firstDay.month == this.nextMonthDays[15].month && lastDay.month == this.nextMonthDays[15].month) {
            for (let index = 0; index < this.nextMonthDays.length; index++) {
               if (firstDay.number <= this.nextMonthDays[index].number && lastDay.number >= this.nextMonthDays[index].number) {
                  validateArray.push(this.nextMonthDays[index]);
               }
            }
            for (let index = 0; index < validateArray.length; index++) {
               if (validateArray[index].textDecoration == true) {
                  filteredArray.push(validateArray[index]);
               }
            }
         } else {
            for (let index = 0; index < this.days.length; index++) {
               if (firstDay.number <= this.days[index].number && lastDay.number >= this.days[index].number) {
                  validateArray.push(this.days[index]);
               }
            }
            for (let index = 0; index < this.nextMonthDays.length; index++) {
               if (firstDay.number <= this.nextMonthDays[index].number && lastDay.number >= this.nextMonthDays[index].number) {
                  validateArray.push(this.nextMonthDays[index]);
               }
            }
         }
         if (filteredArray.length == 0) {
            this.checkRestrictionStatus.between = false;
            this.isSearchActive = true;
         } else {
            this.checkRestrictionStatus.between = true;
            this.isSearchActive = false;
         }
      },
   },
   watch: {
      firstSelectedDate(newValue) {
         // ilk seçilen gün kontrolü
         if (this.firstSelectedDate != null) {
            this.displaySelectedDate.firstDate = this.firstSelectedDate.number + " " + this.firstSelectedDate.month;
         } else {
            this.validatedLabel = this.$t("hotelCard_lblCalendarInfo");
            this.isInvalidRestriction = false;

            this.checkRestrictionStatus.stopType = false;
            this.checkRestrictionStatus.minStay = false;
            this.checkRestrictionStatus.release = false;
            this.checkRestrictionStatus.between = false;

            this.isSearchActive = false;
            this.displaySelectedDate.firstDate = "";
            this.deactivateAllBetween();
         }

         this.deactivateDaysBetween(newValue, this.secondSelectedDate);
         this.activateDaysInRange(newValue, this.secondSelectedDate);

         //stop type 3  için validation label fmt
         if (newValue.stopType == 3) {
            this.validatedLabel = this.restrictionsFmts.stopType;
            this.checkRestrictionStatus.stopType = true;
         } else {
            this.checkRestrictionStatus.stopType = false;
         }
         //release için kontrol
         if (newValue.month == this.TodaysDate.month && newValue.year == this.TodaysDate.year) {
            if (newValue.number - this.TodaysDate.number < newValue.releaseDayCount) {
               this.validatedLabel = this.restrictionsFmts.release;
               this.checkRestrictionStatus.release = true;
            } else {
               this.checkRestrictionStatus.release = false;
            }
         }
         this.validatedTexts();
      },
      secondSelectedDate(newValue) {
         if (this.secondSelectedDate != null) {
            this.displaySelectedDate.secondDate = " - " + this.secondSelectedDate.number + " " + this.secondSelectedDate.month;
            const startDate = new Date(this.firstSelectedDate.year, this.firstSelectedDate.monthIndex, this.firstSelectedDate.number);
            const endDate = new Date(this.secondSelectedDate.year, this.secondSelectedDate.monthIndex, this.secondSelectedDate.number);

            const timeDiff = Math.abs(endDate - startDate);
            const daysDiff = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));

            this.displaySelectedDate.nights = daysDiff === 0 ? this.$t("hotelCard_lblCalendarSelectNights") : `${daysDiff}`;
         } else {
            this.displaySelectedDate.secondDate = "";
            this.displaySelectedDate.nights = this.$t("hotelCard_lblCalendarSelectNights");
            this.isSearchActive = false;
         }
         let swap = this.firstSelectedDate;
         this.deactivateAllBetween();
         this.firstSelectedDate = swap;
         this.firstSelectedDate.active = true;
         this.activateDaysInRange(this.firstSelectedDate, newValue);

         //minstay kontrol

         if (this.firstSelectedDate.minStayCount > this.displaySelectedDate.nights) {
            if (this.firstSelectedDate.month == this.days[15].month) {
               for (let index = 0; index < this.days.length; index++) {
                  if (this.days[index].number == this.firstSelectedDate.number) {
                     this.checkRestrictionStatus.minStay = true;

                     this.days[index + this.firstSelectedDate.minStayCount].blink = true;
                     this.checkRestrictionStatus.minStay = true;
                     this.validatedLabel = this.restrictionsFmts.minStay;
                     this.isSearchActive = false;
                     setInterval(() => {
                        this.days[index + this.firstSelectedDate.minStayCount].blink = false;
                     }, 1000);

                     break;
                  }
               }
            } else if (this.firstSelectedDate.month == this.nextMonthDays[15].month) {
               for (let index = 0; index < this.nextMonthDays.length; index++) {
                  if (this.nextMonthDays[index].number == this.firstSelectedDate.number) {
                     this.checkRestrictionStatus.minStay = true;

                     this.nextMonthDays[index + this.firstSelectedDate.minStayCount].blink = true;
                     this.checkRestrictionStatus.minStay = true;
                     this.validatedLabel = this.restrictionsFmts.minStay;
                     this.isSearchActive = false;
                     setInterval(() => {
                        this.nextMonthDays[index + this.firstSelectedDate.minStayCount].blink = false;
                     }, 1000);

                     break;
                  }
               }
            }
         } else {
            this.checkRestrictionStatus.minStay = false;
         }

         // seçilen günler arasında restrictionlara takılan varsa hata kontrolü
         this.validateRestrictionsBetweenDates(this.firstSelectedDate, this.secondSelectedDate);
         this.validatedTexts();
      },
   },
   mounted() {
      this.populateDays();
      this.populateNextMonthDays();
      this.handlergetAvailableHotels();
   },
};
</script>
<style scoped lang="scss">
// background shadow eklenecek
.calendar-overlay {
   font-weight: 600;
}
.custom-button-disabled {
   width: 132px;
   height: 45px;
   background-color: #dddd;
   border: none;
   color: #999494dd;
   border-radius: 10px;
   font-weight: 500;
}
.custom-button {
   width: 132px;
   height: 45px;
   background-color: #ff9133;
   border: none;
   color: white;
   border-radius: 10px;
   font-weight: 500;
}

#disabled {
   pointer-events: none;
}
.wrapper {
   width: 700px;
   height: 580px;
   background: #fff;
   border-radius: 16px;
   box-shadow: 0 15px 40px rgba(0, 0, 0, 0.12);
   .widget-loader-wrapper {
      display: flex;
      width: auto;
      height: auto;
      border-radius: 16px;
      .g-radial-loader {
         $size: 72px;
         width: $size;
         height: $size;
         border-width: 5px;
         border-color: #333333;
         margin: 0 auto;
         margin-top: 25%;
      }
   }
}
.isClickableButton {
   background: #dddddd;
   pointer-events: none;
}

.wrapper-header {
   cursor: default;
   display: flex;
   justify-content: space-between;
   margin: 1rem;
   padding: 1rem;
   height: 5rem;
   #popup-close-btn {
      background: #eaeaea;
      border-radius: 50%;
      width: 42px;
      height: 42px;
      border: 1px solid #fff;
      position: absolute;
      right: 2rem;
      margin-top: 0.75rem;
      top: 1.25rem;
      cursor: pointer;

      &::before,
      &::after {
         opacity: 0.75;
         content: "";
         width: 2.5px;
         height: 24px;
         display: block;
         border-radius: 0.5rem;
         background-color: #363636;
         position: absolute;
         left: 0;
         right: 0;
         bottom: 0;
         top: 0;
         margin: auto;
      }

      &::before {
         transform: rotate(45deg);
      }
      &::after {
         transform: rotate(-45deg);
      }
   }
}
.wrapper-header i {
   cursor: pointer;
}
.wrapper-header span {
   color: #584f4f;
}
.spacer {
   border: 0px;
   border-bottom: 3px;
   margin-top: -20px;
   margin-bottom: 10px;
   color: #e6e6e6;
   border-style: solid;
}
.wrapper-month {
   display: flex;
   margin-right: 2.5rem;
}
#secondMonth {
   margin-right: 1rem;
}
.monthTitle {
   justify-content: center;
   padding-top: 10px;
}

.icons {
   display: flex;
   align-items: center;
   justify-content: space-between;
   width: 100%;
}

.icons span {
   height: 38px;
   width: 38px;
   margin: 0 1px;
   cursor: pointer;
   color: #878787;
   text-align: center;
   line-height: 38px;
   font-size: 1.5rem;
   user-select: none;
   border-radius: 50%;
   margin-left: 20px;
}

.icons span:last-child {
   margin-right: 20px;
}

.current-date {
   cursor: default;
   color: black;
   font-size: 1.2rem;
   font-weight: 500;
}
.current-date-off {
   cursor: default;
   color: black;
   font-size: 1.2rem;
   font-weight: 500;
   margin-left: 6rem;
}
#firstMonthLabel {
   margin-right: 11rem;
   margin-left: 1rem;
}
.calendar {
   padding-left: 15px;
   padding-top: 10px;
   width: 337px;
   height: 370px;
}

.calendar ul {
   display: flex;
   flex-wrap: wrap;
   list-style: none;
   text-align: center;
}

.calendar .days {
   margin-bottom: 20px;
}

.calendar li {
   color: #333;
   width: calc(100% / 7);
   font-size: 14px;
   font-weight: 500;
}

.calendar .weeks li {
   font-weight: 500;
   cursor: default;
}

.calendar .days li {
   font-weight: 700;
   z-index: 1;
   cursor: pointer;
   position: relative;
   margin-top: 30px;
}

.days li.inactive {
   color: #aaa;
   pointer-events: none;
   cursor: not-allowed;
}
.days li.textDecoration {
   color: #aaa;
   pointer-events: none;
   cursor: not-allowed;
   text-decoration: line-through;
}

.days li.between {
   color: black;
   z-index: 0;
}
.days li.IsToday::before {
   background: rgb(216, 214, 214);
   border-radius: 50%;
}

.days li::before {
   position: absolute;
   content: "";
   left: 50%;
   top: 50%;
   height: 44px;
   width: 44px;
   z-index: -1;
   border-radius: 20%;
   transform: translate(-50%, -50%);
}
.days li.blink {
   color: #fff;
   z-index: 1;
}
.days li.blink::before {
   background: #33b8ff;
   border-radius: 20%;
   color: #fff;
   transition: 0.5s ease-in-out;
}
.days li.active::before {
   background: #33b8ff;
   border-radius: 20%;
}
.days li.between::before {
   background: #eaf8ff;
   border-radius: 0%;
   width: 120%;
}
.days li:not(.active):hover::before {
   background: #33b8ff;
}
.wrapper-bottom .spacer {
   margin-bottom: 0.5rem;
   width: 100%;
}
.wrapper-bottom .bottom-items {
   display: flex;
   justify-content: space-between;
   padding: 1rem;
   margin: 0 2rem 0.5rem 1rem;
}
.wrapper-bottom .bottom-items .dateAlert {
   cursor: default;
   margin-top: 0.5rem;
   display: flex;
   i {
      padding-right: 1rem;
   }
}
.wrapper-bottom .bottom-items .dateAlert span {
   margin-top: -5px;
   font-size: 14px;
   height: 40px;
   display: flex;
   justify-content: center;
   align-items: center;
   margin-left: 0.5rem;
}
.validated {
   color: #2feb9c;
   font-size: 14px;
   text-align: center;
}
.not-validated {
   font-size: 14px;
   color: #e44327;
}
</style>
