<template>
   <div class="filter-section-container">
      <div class="filter-section-title-wrapper">
         <span class="filter-section-title">{{ $t("hotelFilter_lblClassTitle") }}</span>
         <span class="filter-section-toggle" @click="handlerClearAll">{{ $t("searchFiltersHotel_deselectAll") }}</span>
      </div>
      <span class="star-label">{{ $t("hotelFilter_lblFilterByStars") }}</span>
      <div class="filter-stars-container">
         <div
            class="star-wrapper"
            v-for="(star, index) in stars"
            :key="index"
            :class="star.checked ? 'active' : ''"
            @click="handlerSelectStar(index)">
            <i class="gts-icon icon-star"></i>
            <span class="star">{{ index }}</span>
         </div>
      </div>

      <span class="Definations-label">{{ $t("hotelFilter_lblFilterByDefinitions") }}</span>
      <div class="definations-wrapper">
         <button :class="{ active: isActive(2) }" @click="toggleActiveButton(2)">
            {{ $t("hotelCard_lblSpecialCategory") }}
         </button>
         <button :class="{ active: isActive(3) }" @click="toggleActiveButton(3)">
            {{ $t("hotelCard_lblBoutiqueHotel") }}
         </button>
      </div>

      <div class="selected-stars">
         <span class="selected-stars" v-html="handlerSelectedStarsText()"> </span>
         <!-- <span class="stars-selected-text">{{ $t("hotelFilter_lblSelectedClassCount") }}</span> -->
      </div>
   </div>
</template>

<script>
export default {
   name: "FilterFacilities",
   props: {
      stars: {
         type: Object,
         required: true,
      },
   },
   data() {
      return {};
   },
   methods: {
      isActive(buttonValue) {
         return this.$store.state.hotel.hotelStarCategory.includes(buttonValue);
      },
      toggleActiveButton(buttonValue) {
         if (this.$store.state.hotel.hotelStarCategory.includes(-1)) {
            this.$store.commit("hotel/setHotelStarCategoryClearAll");
         }
         if (this.$store.state.hotel.hotelStarCategory.includes(buttonValue)) {
            let index = this.$store.state.hotel.hotelStarCategory.indexOf(buttonValue);
            this.$store.commit("hotel/setHotelStarCategoryClear", index);
         } else {
            this.$store.commit("hotel/setHotelStarCategory", buttonValue);
         }
         if (this.$store.state.hotel.hotelStarCategory.length == 0) {
            this.$store.commit("hotel/setHotelStarCategory", -1);
         }
         this.$parent.$parent.$parent.filterRequestModel.starCategories = this.$store.state.hotel.hotelStarCategory;
         this.$parent.$parent.$parent.handlerFilterRequestModelGenerator();
      },
      handlerClearAll() {
         this.$store.commit("hotel/setHotelStarCategoryClearAll");
         this.$store.commit("hotel/setHotelStarCategory", -1);
         this.$parent.$parent.$parent.filterRequestModel.starCategories = this.$store.state.hotel.hotelStarCategory;
         this.$parent.$parent.$parent.handlerFilterRequestModelGenerator();
         Object.keys(this.stars).forEach((star) => {
            this.stars[star].checked = false;
            this.activeButtons[0] = -1;
         });
         this.$emit("update-stars", -1);
      },

      handlerSelectStar(i) {
         this.$emit("update-stars", parseInt(i));
      },
      handlerSelectedStarsText() {
         const self = this;
         let starFmt = this.$t("hotelFilter_lblStars");
         let andFmt = this.$t("hotelFilter_lblAnd");
         let filterFmt;
         function getCheckedStars() {
            const checkedStars = Object.keys(self.stars)
               .filter((star) => self.stars[star].checked)
               .join(", ");
            return checkedStars;
         }
         const specialCategory = this.$store.state.hotel.hotelStarCategory.includes(2)
            ? `<span style='background:#BDFFAD'>${this.$t("hotelFilter_lblSpecialCategory")}</span>`
            : "";
         const boutiqueHotel = this.$store.state.hotel.hotelStarCategory.includes(3)
            ? `<span style='background:#BDFFAD'>${this.$t("hotelFilter_lblBoutiqueHotel")}</span>`
            : "";

         const andSeperator =
            getCheckedStars().length > 0 &&
            (this.$store.state.hotel.hotelStarCategory.includes(2) || this.$store.state.hotel.hotelStarCategory.includes(3))
               ? true
               : false;

         const orSeperator =
            this.$store.state.hotel.hotelStarCategory.includes(2) && this.$store.state.hotel.hotelStarCategory.includes(3) ? true : false;

         //Hotels with
         // #VALUE1# 2,3,4
         // #VALUE2# stars
         // #VALUE3# and
         // #VALUE4# special category
         // #VALUE5# &
         // #VALUE6# boutique hotel category
         //are filtered

         filterFmt = this.$t("hotelFilter_lblFilterText")
            .replace("#VALUE1#", getCheckedStars().length > 0 ? `<span style='background:#BDFFAD'>${getCheckedStars()}</span>` : "")
            .replace("#VALUE2#", getCheckedStars().length > 0 ? starFmt : "")
            .replace("#VALUE3#", andSeperator ? andFmt : "")
            .replace("#VALUE4#", specialCategory)
            .replace("#VALUE5#", orSeperator ? "&" : "")
            .replace("#VALUE6#", boutiqueHotel);

         if (
            getCheckedStars().length > 0 ||
            this.$store.state.hotel.hotelStarCategory.includes(2) ||
            this.$store.state.hotel.hotelStarCategory.includes(3)
         ) {
            return filterFmt;
         } else {
            return null;
         }
      },
   },
};
</script>

<style lang="scss" scoped>
.filter-section-container {
   font: normal normal bold 16px/21px Roboto;
   display: flex;
   flex-direction: column;
   .filter-section-title-wrapper {
      .filter-section-title {
         font-weight: 700;
      }
   }
   .star-label {
      font-size: 12px;
      font-weight: 600;
   }
   .Definations-label {
      margin-top: 1rem;
      font-size: 12px;
      font-weight: 600;
   }
   @media (max-width: 768px) {
      .definations-wrapper {
         display: flex;
         flex-direction: column;
         button {
            margin-top: 0.5rem;
         }
      }
   }
   .definations-wrapper {
      zoom: 0.8;
      display: flex;
      justify-content: space-around;
      margin-top: 1rem;
      button {
         margin-left: 0.5rem;
         border-radius: 8px;
         height: 60px;
         width: 200px;
         color: grey;
         font-weight: 500;
         font-size: 16px;
      }
      .active {
         background-color: #ffd633;
         color: #004099;
      }
      .inactive {
         background-color: #e0e0e0;
      }
   }
}
.filter-stars-container {
   display: flex;
   width: 100%;
   justify-content: space-around;
   .star-wrapper {
      position: relative;
      cursor: pointer;

      &.active {
         i {
            filter: invert(74%) sepia(100%) saturate(805%) hue-rotate(358deg) brightness(103%) contrast(103%);
         }
      }

      i {
         $icon: 37px !important;
         width: $icon;
         height: $icon;
         filter: invert(74%) sepia(100%) saturate(0%) hue-rotate(358deg) brightness(103%) contrast(103%);
      }

      span.star {
         position: absolute;
         top: 0;
         left: 0;
         right: 0;
         bottom: -5px;
         margin: auto;
         width: fit-content;
         height: fit-content;
         color: #fff;
         font-size: var(--small);
         font-weight: 600;
      }
   }
}
.selected-stars {
   margin-top: 0.5rem;
   text-align: center;
   font-weight: 500;
   font-size: 14px;
}
</style>
