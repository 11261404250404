<template>
    <transition name="modal-fade" v-if="isModalOpen">
        <div class="gts-modal-wrapper">
            <div class="gts-modal-inner backdrop-wrapper" @click="$emit('close')">
                <div :class="`modal-content-wrapper modal-${size}`">
                    <span class="btn_modalClose" @click="$emit('close')"><i class="gts-icon icon-close"></i></span>
                    <header class="modal-content-header" v-if="title">
                        <h4>{{ title }}</h4>
                        <slot name="headerCTA"></slot>
                    </header>
                    <section class="modal-content-body">
                        <slot />
                    </section>
                    <footer class="modal-content-footer">
                        <slot name="footer"></slot>
                    </footer>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
export default {
    name: "UIModal",
    props: {
        size: { type: String },
        title: { type: String },
    },
    data() {
        return {
            isModalOpen: false,
        };
    },
    methods: {
        triggerClickOutSide() {
            const self = this;
            self.isModalOpen = false;
        },
    },
};
</script>

<style lang="scss" scoped>
html body .gts-modal-wrapper {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 999;

    .gts-modal-inner {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;

        &.backdrop-wrapper {
            background-color: rgba(0, 0, 0, 0.5);
        }

        .modal-content-wrapper {
            position: relative;
            padding: 48px;
            background-color: white;
            border-radius: 16px;
            box-shadow: 0 5px 25px rgba(0, 0, 0, 0.15);

            .btn_modalClose {
                position: absolute;
                top: 16px;
                right: 16px;
            }
            .modal-content-header {
                margin-bottom: 16px;
                padding-bottom: 16px;
                border-bottom: 1px solid #dee2e6;

                h4 {
                    font-size: 24px;
                    font-weight: 500;
                }
            }
            .modal-content-body {
            }
            .modal-content-footer {
                border-top: 1px solid #dee2e6;
                padding-top: 16px;
                margin-top: 16px;

                &::v-deep .customer-book-form-cta {
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;

                    & > button:first-child {
                        margin-right: 16px;
                    }
                }
            }

            &.modal-lg {
                width: 90%;
                max-width: 90%;
            }
            &.modal-md {
                width: 60%;
                max-width: 60%;
            }
            &.modal-sm {
                width: 30%;
                max-width: 30%;
            }
        }
    }
}
</style>
