import Vue from "vue";

const state = {
   user: {
      onBehalfOfs: {},
   },
};

const mutations = {
   updateUserInformations(state, data) {
      Vue.set(state.user, "agencyIatiId", data.agencyIatiId);
      Vue.set(state.user, "agencyId", data.agencyId);
      Vue.set(state.user, "agencyName", data.agencyName);
      Vue.set(state.user, "email", data.email);
      Vue.set(state.user, "gsaId", data.gsaId);
      Vue.set(state.user, "gsaName", data.gsaName);
      Vue.set(state.user, "id", data.id);
      Vue.set(state.user, "language", data.language);
      Vue.set(state.user, "name", data.name);
      Vue.set(state.user, "surname", data.surname);
      Vue.set(state.user, "timezoneId", data.timezoneId);
      Vue.set(state.user.onBehalfOfs, "agencyOnBehalfOfId", data.agencyOnBehalfOfId);
      Vue.set(state.user.onBehalfOfs, "gsaOnBehalfOfId", data.gsaOnBehalfOfId);
      Vue.set(state.user, "initiated", data.initiated);
      Vue.set(state.user, "originalGsaCssCode", data.originalGsaCssCode);
   },
};

const actions = {
   updateUserInformations(commit, data) {
      commit("updateUserInformations", data);
   },
};

const getters = {
   getUser: (state) => state.user,
};

export const user = {
   namespaced: true,
   state,
   mutations,
   actions,
   getters,
};
