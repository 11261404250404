const state = {
    selectedCustomer: {
        dbState: 1,
        status: 1,
        gender: null,
        name: null,
        surname: null,
        birthdate: null,
        nationality: null,
        nationalIdentity: null,
        milesPan: null,
        phone: {},
        email: null,
        passport: {}
    },
    customers: []
}

const mutations = {
    setSelectedCustomer(state, data){
        state.selectedCustomer = data;
    },
    setCustomers(state, data){
        state.customers = data
    }
}

const actions = {
    setSelectedCustomer: function(commit, data) {
        commit('setSelectedCustomer', data);
    },
    setCustomers: function(commit, data){
        commit('setCustomers', data);
    }
}

const getters = {
    getSelectedCustomer: state => state.selectedCustomer,
    getCustomers: state => state.customers
}

export const customer = {
    namespaced: true,
    state,
    mutations,
    actions,
    getters 
}