<template>
   <div id="document-viewer-container" v-if="docControls.status">
      <div id="document-viewer-wrapper">
         <!-- Doc Wrapper -->
         <div id="doc-wrapper">
            <!-- Tabs -->

            <Section class="multiple-documents-tabs-wrapper">
               <div class="document-tab-wrapper">
                  <template v-for="(tab, key, index) in Documents">
                     <div class="document-tab" :key="index" :class="activeTab == key ? 'active' : ''" @click="handlerTab(key)">
                        {{ tab.title }}
                     </div>
                  </template>
               </div>

               <div id="close-doc-wrapper" @click="handlerCloseDoc">
                  <i class="gts-icon icon-close-rounded icon-sm document-close-icon gts-animation"></i>
               </div>
            </Section>

            <!-- Document Viewer -->
            <Section id="doc-viewer-wrapper" @click="handlerClickOutside">
               <!-- HTML DATA -->
               <template v-if="!docControls.loading">
                  <template v-for="(tab, index) in Documents">
                     <div
                        class="doc-html"
                        v-html="tab.content"
                        :key="index"
                        v-if="activeTab == index"
                        :class="activeTab == index ? 'activeTab' : ''"></div>
                  </template>
               </template>
               <template v-else>
                  <!-- //Widget loader -->
                  <div class="widget-loader-wrapper">
                     <span class="g-radial-loader"> </span>
                  </div>
               </template>
            </Section>
         </div>

         <!-- Document Actions Wrapper -->
         <div id="cta-wrapper">
            <div class="pax-based-print" @click="handlerPaxBasedPrint" v-if="DocumentType == 1">
               <i class="gts-icon icon-doc-pax-based"></i>
               <div class="pax-based-print-highlighter" ref="highlighter">
                  {{ $t("documentViewer_paxBasedInfoTitle") }}
               </div>
            </div>
            <ul class="ctas">
               <template v-for="(cta, index) in ctas">
                  <li :key="index" @click="cta.action" v-if="DocumentCTAs[cta.name]">
                     <div class="cta-information-popover">{{ $t(cta.information) }}</div>

                     <i class="gts-icon" :class="['icon-' + cta.icon, cta.status ? 'cta-active' : '']"></i>
                  </li>
               </template>
            </ul>

            <!-- Pax Pased Dropdown -->
            <Section class="pax-based-print-dropdown-wrapper" v-if="paxBasedPrintDropdownStatus">
               <div class="pax-based-print-select-all-wrapper">
                  <span @click="handlerSelectAll">{{ DeselectAll ? $t("documentViewer_deselectAll") : $t("documentViewer_selectAll") }}</span>
               </div>

               <!-- Pax Based List -->
               <template v-for="(item, index) in paxBasedPrint">
                  <ul id="pax-list" :key="index" v-if="activeTab == index">
                     <li
                        v-for="(pax, paxIndex) in item"
                        :key="paxIndex"
                        @click="handlerPaxSelect(index, paxIndex)"
                        :class="pax.selected ? 'pax-is-selected' : ''">
                        <span class="pax-label"> {{ pax.label }}</span>
                        <div class="pax-is-selected-wrapper">
                           <span class="pax-is-selected" v-if="pax.selected"> </span>
                        </div>
                     </li>
                  </ul>
               </template>

               <button class="pax-based-print-generator" @click="handlerGenerateRequest">{{ $t("documentViewer_generatePDF") }}</button>
            </Section>

            <!-- Email Send Popup -->
            <Section id="send-doc-email-wrapper" v-if="docControls.emailPopupStatus">
               <div class="email-input-wrapper">
                  <input type="text" v-model="docControls.emailInput" />
                  <button class="pv-email-button" @click="handlerSendMail(docControls.emailInput)"><i class="gts-icon icon-send icon-xs"></i></button>
               </div>
               <div class="email-dropdown">
                  <UISelectBox
                     light-theme
                     small
                     :options="docControls.emails"
                     v-model="docControls.emailInput"
                     :placeholder="$t('documentViewer_phEmail')" />
               </div>

               <ul v-if="false">
                  <li v-for="(email, index) in docControls.emails" :key="index">
                     <span>{{ email.value }}</span>
                     <i class="gts-icon icon-send icon-xs"></i>
                  </li>
               </ul>
            </Section>
         </div>
      </div>
   </div>
</template>

<script>
import { generateUuid } from "@/utils.js";
import { documentService } from "@/service/index.js";
import axios from "axios";
import UISelectBox from "@/components/unitary/form2/UISelectBox.vue";

export default {
   name: "DocumentViewer",
   components: {
      UISelectBox,
   },

   data() {
      return {
         activeTab: this.$store.state.app.documentViewer.activeTab,
         ctas: [
            { name: "email", icon: "doc-email", action: this.handlerMail, information: "documentViewer_sendEmail" },
            { name: "pdf", icon: "doc-pdf", action: this.handlerPdf, information: "documentViewer_openPdf" },
            { name: "whatsapp", icon: "doc-whatsapp", action: this.handlerWhatsapp, information: "documentViewer_sendToWhatsapp" },
            { name: "print", icon: "doc-print", action: this.handlerPrint, information: "documentViewer_printDocument" },
            { name: "copy", icon: "doc-copy", action: this.handlerCopy, information: "documentViewer_copyToClipboard" },
            { name: "price", icon: "doc-price", action: this.handlerPrice, information: "documentViewer_showPrice", status: Boolean },
            {
               name: "showCancelPolicies",
               icon: "doc-policies",
               action: this.handlerShowPolicies,
               information: "documentViewer_showCancelPolicies",
               status: Boolean,
            },
            {
               name: "showOptionDate",
               icon: "doc-option",
               action: this.handlerOptionDate,
               information: "documentViewer_showOptionDate",
               status: Boolean,
            },
         ],
         paxBasedPrint: {},
         paxBasedPrintDropdownStatus: true,
         documents: {},
         docControls: {
            status: false,
            loading: true,
            emailInput: "",
            emails: null,
            emailPopupStatus: false,
         },
      };
   },
   created() {
      this.handlerGetDoc();
   },
   computed: {
      Documents() {
         return this.documents;
      },
      DocumentType() {
         return this.$store.state.app.documentViewer.request.documentType;
      },
      DocumentCTAs() {
         return {
            email: true,
            pdf: true,
            whatsapp: true,
            print: true,
            copy: true,
            price: this.DocumentType != 3 && this.DocumentType != 4 ? true : false,
            showCancelPolicies: this.DocumentType == 5 ? true : false,
            showOptionDate: this.DocumentType == 5 && this.ProductStatus == 2 ? true : false,
         };
      },
      DeselectAll() {
         return Object.keys(this.paxBasedPrint[this.activeTab]).every((item) => {
            return this.paxBasedPrint[this.activeTab][item].selected;
         });
      },
      ProductStatus() {
         return this.$store.state.app.documentViewer.activeProductStatus;
      },
   },
   methods: {
      handlerClickOutside() {
         this.docControls.emailPopupStatus = false;
         this.paxBasedPrintDropdownStatus = false;
      },
      handlerCloseDoc() {
         this.$store.state.app.documentViewer.active = false;
         this.$store.state.app.documentViewer.isLoaded = false;
      },
      handlerPaxBasedPrint() {
         this.docControls.emailPopupStatus = false;
         this.paxBasedPrintDropdownStatus = !this.paxBasedPrintDropdownStatus;
      },
      handlerPaxSelect(index, paxIndex) {
         this.paxBasedPrint[index][paxIndex].selected = !this.paxBasedPrint[index][paxIndex].selected;
      },
      handlerSelectAll() {
         if (this.DeselectAll) {
            Object.keys(this.paxBasedPrint[this.activeTab]).forEach((item) => {
               this.paxBasedPrint[this.activeTab][item].selected = false;
            });
         } else {
            Object.keys(this.paxBasedPrint[this.activeTab]).forEach((item) => {
               this.paxBasedPrint[this.activeTab][item].selected = true;
            });
         }
      },
      handlerGenerateRequest() {
         var indexOf;

         Object.keys(this.documents).forEach((item, index) => {
            if (item == this.activeTab) {
               indexOf = index;
               // console.log(item, index);
            }
         });

         const selectedArr = [];
         this.paxBasedPrint[this.activeTab].forEach((item) => {
            if (item.selected) {
               selectedArr.push(item.identifier);
            }
         });

         this.$store.commit("app/setDocumentSubIdentifier", { index: indexOf, arr: selectedArr });
         this.handlerGetDoc();
      },
      handlerGetDoc() {
         //Loader Controls

         // this.docStatus = false;
         this.docControls.loading = true;
         this.$store.state.app.documentViewer.isLoaded = false;
         this.hasPrice = this.$store.state.app.documentViewer.hasPrice;
         this.docControls.emails = null;

         if (this.$store.state.app.documentViewer.request.documentType != 1) {
            this.$store.commit("app/removeRequestFromMaster");
         }

         //Close PDF Per Pax Dropdown
         this.paxBasedPrintDropdownStatus = false;
         const query = {
            uuid: generateUuid(),
            documentType: this.$store.state.app.documentViewer.request.documentType,
            identifiers: this.$store.state.app.documentViewer.request.identifiers,
            orderId: this.$store.state.app.documentViewer.request.orderId,
            orderUuid: this.$store.state.app.documentViewer.request.orderUuid,
            language: this.$store.state.user.user.language,
            showPrice: this.$store.state.app.documentViewer.request.showPrice,
            showCancellationPolicy: this.$store.state.app.documentViewer.request.showCancellationPolicy,
            showOptionDate: this.$store.state.app.documentViewer.request.showOptionDate,
            fileType: this.$store.state.app.documentViewer.request.fileType,
         };

         query.showPrice ? (this.ctas[5].status = true) : (this.ctas[5].status = false);
         query.showCancellationPolicy ? (this.ctas[6].status = true) : (this.ctas[6].status = false);
         query.showOptionDate ? (this.ctas[7].status = true) : (this.ctas[7].status = false);
         /* PNR_DOC = 1,
            PNR_OFFER = 2,
            INVOICE = 3,
            PNR_CONFIRM = 4,
            VOUCHER_DOC = 5,
            TNR_DOC = 6
            VOUUCHER_OFFER = 7,
            VERSION_INFO = 8,
            RNR_DOC = 9
         */

         if (this.$router.history.current.path == "/flight") {
            this.$store.commit("app/setIsProgressGifActive", true);
         }

         documentService.pnrDocFrontend(query).then((response) => {
            if (response.result.success) {
               // nDocuments Response Loop
               Object.keys(response.documents).map((document) => {
                  let shortCut = response.documents[document];
                  const rowObj = {
                     name: document,
                     title: shortCut.title,
                     html: shortCut.htmlUrl,
                     content: null,
                     pdf: shortCut.pdfUrl,
                  };

                  //Get HTML Content
                  axios.get(rowObj.html).then((res) => {
                     rowObj.content = res.data;
                  });
                  this.activeTab = query.documentType == 1 || query.documentType == 4 ? this.$store.state.app.documentViewer.activeTab : rowObj.name;

                  if (document == "MASTER") this.activeTab = "MASTER";
                  this.$set(this.documents, shortCut.identifier, rowObj);
                  this.$set(this.paxBasedPrint, shortCut.identifier, shortCut.subIdentifiers);
               });

               // Set Emails

               this.docControls.emails = response.emails.map((email) => {
                  return { name: email, value: email };
               });
            } else {
               const error = {
                  type: "WARNING",
                  message: this.$i18n.t(`documentViewer_documentCannotGet`),
                  count: false,
               };
               this.$store.commit("app/setNotification", error);
            }

            this.docControls.loading = false;
            this.docControls.status = true;
            this.$store.state.app.documentViewer.isLoaded = true;

            this.$store.commit("app/setIsProgressGifActive", false);
         });
      },
      handlerSendMail(mail) {
         this.docControls.loading = true;
         this.docControls.emailPopupStatus = false;
         const self = this;
         const query = {
            uuid: generateUuid(),
            documentType: self.$store.state.app.documentViewer.request.documentType,
            identifiers: self.$store.state.app.documentViewer.request.identifiers,
            orderId: self.$store.state.app.documentViewer.request.orderId,
            orderUuid: self.$store.state.app.documentViewer.request.orderUuid,
            language: self.$store.state.user.user.language,
            showPrice: self.$store.state.app.documentViewer.request.showPrice,
            fileType: self.$store.state.app.documentViewer.request.fileType,
            email: mail,
         };

         documentService.pnrDocFrontend(query).then((response) => {
            if (response.result.success) {
               const mailSend = {
                  type: "SUCCESS",
                  message: this.$i18n.t(`documentViewer_mailSentSuccessfully`),
                  count: false,
               };
               this.$store.commit("app/setNotification", mailSend);
            } else {
               const mailSend = {
                  type: "WARNING",
                  message: this.$i18n.t(`documentViewer_couldntSendMail`),
                  count: false,
               };
               this.$store.commit("app/setNotification", mailSend);
            }

            this.docControls.loading = false;
         });
      },
      handlerMail() {
         this.paxBasedPrintDropdownStatus = false;
         this.docControls.emailPopupStatus = !this.docControls.emailPopupStatus;
      },
      handlerPdf() {
         window.open(this.Documents[this.activeTab]?.pdf, "_blank");
      },
      handlerWhatsapp() {
         window.open("https://web.whatsapp.com/send?text=" + this.Documents[this.activeTab].pdf);
      },
      handlerPrint() {
         let activeTabContent = document.querySelector(".activeTab")?.innerHTML;

         const mywindow = window.open("", "Print");
         if (this.$store.state.app.documentViewer.request.documentType == 3) {
            mywindow.document.write(
               "<html><head><style>tfoot {visibility: hidden;} #fixedFooter {display: block !important;} .page-footer-space{border:none !important;}</style></head><body>"
            );
            mywindow.document.write(activeTabContent);
            mywindow.document.write("</body></html>");
         } else {
            mywindow.document.write(activeTabContent);
         }
         mywindow.document.close();
         mywindow.onload = function () {
            mywindow.focus();
            mywindow.print();
            mywindow.close();
         };
      },
      handlerCopy() {
         navigator.clipboard.writeText(this.Documents[this.activeTab].pdf);
         const copiedToClipboard = {
            type: "SUCCESS",
            message: this.$i18n.t(`documentViewer_popupCopiedToClipboard`),
            count: false,
         };
         this.$store.commit("app/setNotification", copiedToClipboard);
      },
      handlerPrice() {
         this.$store.state.app.documentViewer.request.showPrice = !this.$store.state.app.documentViewer.request.showPrice;
         this.handlerGetDoc();
      },
      handlerShowPolicies() {
         this.$store.state.app.documentViewer.request.showCancellationPolicy = !this.$store.state.app.documentViewer.request.showCancellationPolicy;
         this.handlerGetDoc();
      },
      handlerOptionDate() {
         this.$store.state.app.documentViewer.request.showOptionDate = !this.$store.state.app.documentViewer.request.showOptionDate;
         this.handlerGetDoc();
      },
      handlerTab(index) {
         this.activeTab = index;
      },
   },

   watch: {
      "docControls.status": {
         handler: function (status) {
            const self = this;

            self.$nextTick(() => {
               if (status && self.$refs?.highlighter != undefined) {
                  setTimeout(() => {
                     self.$refs?.highlighter.classList.add("d-none");
                  }, 3000);
               }
            });
         },
      },
      "docControls.emails": {
         handler: function (emails) {
            if (emails != null && Object.keys(emails).length != 0) {
               this.docControls.emailInput = emails[0].name;
            }

            // t
         },
      },
   },
};
</script>

<style lang="scss" scoped>
#document-viewer-container {
   position: fixed;
   inset: 0 0 0 0;
   z-index: 999;
   background: #00000050;

   #document-viewer-wrapper {
      width: 80vw;
      height: 80vh;
      // border: 2px solid;
      position: absolute;
      margin: auto;
      inset: 0 0 0 0;
      display: flex;
      justify-content: space-between;

      @media (min-width: 1200px) {
         width: 60vw;
      }
      @media (min-width: 1400px) {
         width: 40vw;
      }

      .doc {
         height: 100px;
      }

      #doc-wrapper {
         width: 100%;
         // border-radius: 1rem;
         // overflow: hidden;

         section.multiple-documents-tabs-wrapper {
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 32px;
            margin-left: 1rem;
            .document-tab-wrapper {
               display: flex;
               .document-tab {
                  display: flex;
                  align-items: center;
                  cursor: pointer;
                  padding: 0.25rem 1rem;
                  border-radius: 8px;
                  border-bottom-left-radius: 0;
                  border-bottom-right-radius: 0;
                  color: #363636;
                  font-weight: 500;
                  background-color: #d9d9d9;
                  margin-right: 0.75rem;

                  &.active {
                     color: #fff;
                     background-color: var(--primary);
                  }
               }
            }
            #close-doc-wrapper {
               display: flex;
               justify-content: flex-end;
               background-color: #fff;
               cursor: pointer;
               padding: 0.05rem;
               border-radius: 100%;
               cursor: pointer;
            }
         }
         section#doc-viewer-wrapper {
            background-color: #fff;
            height: 100%;
            overflow-y: auto;
            border-radius: 1rem;
            border-top-right-radius: 0;
            // border-right: 0;

            div.doc-html {
               padding: 0.5rem 1rem;
            }

            .widget-loader-wrapper {
               display: flex;
               align-items: center;
               justify-content: center;
               padding: 2rem;
               height: 100%;
               .g-radial-loader {
                  $size: 72px;
                  width: $size;
                  height: $size;
                  border-width: 5px;
                  border-color: #333333;
               }
            }
         }
      }
      #cta-wrapper {
         $ctaWidth: 56px;
         margin-top: 32px;
         background-color: var(--primary);
         width: $ctaWidth;
         border-radius: 12px;
         border-top-left-radius: 0;
         border-bottom-left-radius: 0;
         height: fit-content;
         position: relative;

         .pax-based-print {
            display: flex;
            justify-content: center;
            padding-top: 3rem;
            padding-bottom: 3rem;
            background-color: #00000040;
            border-top-right-radius: 12px;
            cursor: pointer;
            &:hover {
               background-color: #00000060;

               i {
                  filter: invert(15%) sepia(47%) saturate(1322%) hue-rotate(101deg) brightness(102%) contrast(104%);
               }
            }

            @keyframes splash {
               0% {
                  transform: translate(-15px);
               }
               50% {
                  transform: translate(0);
               }
               100% {
                  transform: translate(0);
               }
            }

            .pax-based-print-highlighter {
               animation-timing-function: ease-in-out;
               animation-name: splash;
               animation-duration: 1s;
               animation-iteration-count: 3;
               transform: translate(0);
               position: absolute;
               right: $ctaWidth;
               background-color: var(--accent-primary);
               padding: 1rem;
               width: 300px;
               top: 2rem;
               color: #fff;
               font-size: var(--normal);
               font-weight: 500;
               &::after {
                  content: "";
                  display: block;
                  position: absolute;
                  right: -6px;
                  top: 0;
                  bottom: 0;
                  margin: auto;
                  width: 12px;
                  height: 12px;
                  background-color: var(--accent-primary);
                  transform: rotate(45deg);
               }
            }
         }

         ul.ctas {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
            // overflow: hidden;
            li {
               cursor: pointer;
               position: relative;
               display: flex;
               justify-content: center;
               width: 100%;
               border-bottom: 1px solid #295da6;
               padding-bottom: 1.75rem;
               padding-top: 1.75rem;

               &:last-child {
                  border-bottom: none !important;
                  &:hover {
                     border-bottom-right-radius: 12px;
                  }
               }

               .cta-information-popover {
                  display: none;
               }

               &:hover {
                  background-color: #00000070;

                  .cta-information-popover {
                     display: flex;
                     position: absolute;
                     background-color: var(--accent-primary);
                     right: $ctaWidth;
                     top: 0;
                     bottom: 0;
                     margin: auto;
                     width: fit-content;
                     height: fit-content;
                     white-space: nowrap;
                     border-radius: 0.125rem;
                     padding: 0.5rem 1rem;
                     color: #fff;
                     font-weight: 500;
                     font-size: var(--normal);
                     text-align: right;
                  }
               }

               i {
                  &.icon-doc-policies {
                     width: 37px !important;
                     height: 37px !important;
                  }
               }

               i.cta-active {
                  filter: invert(15%) sepia(47%) saturate(1322%) hue-rotate(101deg) brightness(102%) contrast(104%);
               }
            }
         }

         .pax-based-print-dropdown-wrapper {
            display: flex;
            flex-direction: column;
            align-items: center;
            background: #244d89;
            border-radius: 0.25rem;
            position: absolute;
            top: 2rem;
            right: $ctaWidth;
            padding-bottom: 2rem;
            padding-top: 1rem;
            width: 215px;
            // padding: 1rem;

            ul#pax-list {
               width: 100%;
               padding: 0;
               li {
                  width: 100%;
                  display: flex;
                  justify-content: space-between;
                  white-space: nowrap;
                  border-bottom: 1px solid #ffffff30;
                  padding: 0.5rem 1rem;
                  cursor: pointer;

                  span.pax-label {
                     color: #fff;
                     font-size: var(--normal);
                     font-weight: 500;
                  }

                  &.pax-is-selected {
                     span.pax-label {
                        color: var(--accent-secondary);
                     }
                  }
                  .pax-is-selected-wrapper {
                     width: 24px;
                     height: 24px;
                     margin-left: 1rem;
                     span.pax-is-selected {
                        display: block;
                        width: 24px;
                        height: 24px;
                        background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxOCIgaGVpZ2h0PSIxOCIgdmlld0JveD0iMCAwIDE4IDE4Ij4KICA8ZyBpZD0iR3JvdXBfMTEzNjYiIGRhdGEtbmFtZT0iR3JvdXAgMTEzNjYiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC03ODQgLTQ5NSkiPgogICAgPHJlY3QgaWQ9IlJlY3RhbmdsZV8xNTEyIiBkYXRhLW5hbWU9IlJlY3RhbmdsZSAxNTEyIiB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHJ4PSI5IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg3ODQgNDk1KSIgZmlsbD0iIzAwZmZlMiIgb3BhY2l0eT0iMC4xIi8+CiAgICA8cmVjdCBpZD0iUmVjdGFuZ2xlXzQyNjkiIGRhdGEtbmFtZT0iUmVjdGFuZ2xlIDQyNjkiIHdpZHRoPSIxMiIgaGVpZ2h0PSIxMiIgcng9IjYiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDc4NyA0OTgpIiBmaWxsPSIjMDBmZmUyIi8+CiAgPC9nPgo8L3N2Zz4K");
                        background-repeat: no-repeat;
                        background-size: contain;
                     }
                  }

                  &:hover {
                     background-color: #00000030;
                  }

                  &:last-child {
                     border-bottom: none !important;
                     margin-bottom: 0 !important;
                  }
               }
            }
            .pax-based-print-select-all-wrapper {
               display: flex;
               justify-content: flex-end;
               padding: 0 1rem;
               width: 100%;
               margin-top: 1rem;
               margin-bottom: 0.75rem;

               span {
                  color: var(--accent-secondary);
                  text-decoration: underline;
                  cursor: pointer;

                  &:hover {
                     color: #fff;
                  }
               }
            }
            button.pax-based-print-generator {
               background-color: var(--accent-primary);
               height: 32px !important;
               border-radius: 12rem;
               width: fit-content;
               padding-left: 2rem;
               padding-right: 2rem;
               color: #fff;
               font-weight: 600;
               margin-top: 1.5rem;
            }
         }

         #send-doc-email-wrapper {
            position: absolute;
            top: 130px;
            border: 1px solid #ccc;
            right: $ctaWidth;
            background: #244d89;
            border-radius: 0.25rem;
            padding: 0;
            min-width: 260px;

            .email-input-wrapper {
               display: flex;
               width: 100%;
               justify-content: space-around;
               padding: 0 1rem;
               margin-top: 1.5rem;
               input {
                  border: 0;
                  outline: 0;
                  height: 32px;
                  background: var(--hover);
                  font-size: var(--small);
                  border-radius: 0.25rem 0 0 0.25rem;
                  text-indent: 0.5rem;
                  color: var(--txt-ghost);
                  font-weight: 500;
                  width: 100%;
               }
               .pv-email-button {
                  padding: 0 !important;
                  background: var(--primary) !important;
                  padding: 0.25rem 0.5rem !important;
                  border-radius: 0 0.25rem 0.25rem 0;
                  &:hover {
                     background-color: var(--accent-primary) !important;
                  }
                  &:active {
                     background-color: var(--accent-primary) !important;
                     transform: scale(1.1);
                  }
                  i {
                     filter: brightness(0) invert(1);
                  }
               }
            }

            .email-dropdown {
               padding: 0 1rem;
               margin-top: 1.5rem;
               margin-bottom: 1.5rem;
            }

            li {
               display: flex;
               justify-content: space-between;
               cursor: pointer;
               padding: 0.5rem 1.5rem;

               span {
                  font-size: var(--normal);
                  font-weight: 500;
                  color: var(--accent-secondary);
               }

               &:hover {
                  background-color: #00000050;
               }

               i {
                  display: none;
               }

               &:first-child {
                  padding-top: 1rem;
               }
               &:last-child {
                  padding-bottom: 1rem;
               }
            }
         }
      }
   }
}
</style>

<style lang="scss">
#doc-viewer-wrapper {
   .b-tabs {
      nav.tabs {
         display: none;
      }
   }
}
</style>
