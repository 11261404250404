<template>
   <div class="hotel-order-detail-inner-section voucher-room-type-wrapper">
      <div class="voucher-room-type-inner">
         <div class="room-type-table-wrapper">
            <h4 class="voucher-and-room-title">{{ $t("orderDetail_lblRoomInformations") }}</h4>
            <div class="room-type-table-inner">
               <div class="room-type-table-header">
                  <span>{{ $t("orderDetail_lblRoomType") }}</span>
                  <span>{{ $t("orderDetail_lblAdultCount") }}</span>
                  <span>{{ $t("orderDetail_lblChildCount") }}</span>
                  <span>{{ $t("orderDetail_lblChildAges") }}</span>
                  <span>{{ $t("orderDetail_lblBoardType") }}</span>
               </div>
               <div class="room-type-table-content">
                  <div class="room-type-item" v-for="(roomTypeItem, index) in roomType" :key="index">
                     <span class="masked">{{ roomTypeItem.channelRoomName }}</span>
                     <span clasS="masked">{{ roomTypeItem.adultCount }}</span>
                     <span class="masked">{{ roomTypeItem.childCount !== 0 ? roomTypeItem.childCount : "-" }}</span>
                     <span class="masked">
                        <span
                           v-for="(age, index) in roomTypeItem.childAges"
                           :key="index"
                           v-html="`${age}${index + 1 != roomTypeItem.childAges.length ? ',' : ''}`"></span>
                     </span>
                     <span class="masked">{{ roomTypeItem.boardTypeName != null ? roomTypeItem.boardTypeName : "-" }}</span>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>
export default {
   name: "VoucherAndRoomInformationRoomType",
   props: {
      roomTypeSummary: { type: Array },
   },
   data() {
      return {
         roomType: [],
      };
   },
   created() {
      const self = this;
      self.roomType = this.roomTypeSummary;
   },
   watch: {
      roomTypeSummary: {
         handler: function (newRoomTypeSummary) {
            const self = this;
            self.roomType = newRoomTypeSummary;
         },
         deep: true,
      },
   },
};
</script>

<style lang="scss" scoped>
.voucher-room-type-wrapper {
   margin-top: 24px;
   .voucher-room-type-inner {
      .room-type-table-wrapper {
         .voucher-and-room-title {
            margin-bottom: 24px;
            font-weight: 600;
         }
         .room-type-table-inner {
            display: block;
            border: 1px solid #c4c4c4;
            border-radius: 10px 10px 0 10px;
            overflow: hidden;
            .room-type-table-header {
               display: grid;
               grid-template-columns: repeat(5, 1fr);
               padding: 16px 42px;
               background-color: #ededed;
               border-bottom: 1px solid #c4c4c4;

               & > span {
                  font-size: 14px;
                  color: #868788;
               }
            }
            .room-type-table-content {
               .room-type-item {
                  display: grid;
                  grid-template-columns: repeat(5, 1fr);
                  padding: 16px 42px;
                  & > span {
                     font-size: 16px;
                     font-weight: 700;
                  }
               }
            }
         }
         @media (max-width: 767.98px) {
            .room-type-table-inner {
               display: flex;
               max-height: 300px;
               .room-type-table-header {
                  display: flex;
                  flex-direction: column;
                  justify-content: space-evenly;
                  & > span {
                     margin-bottom: 8px;
                  }
               }
               .room-type-table-content {
                  .room-type-item {
                     display: flex;
                     flex-direction: column;
                     height: 100%;
                     justify-content: space-between;
                     & > span {
                        font-size: 14px;
                     }
                  }
               }
            }
         }
      }
   }
}
</style>
