<template>
   <div class="user-authorization-page-wrapper custom-scroll-user-auth">
      <div class="user-authorization-page-inner">
         <div class="user-authorization-section-content">
            <div class="user-authorization-section-content-inner">
               <div id="stickyTableContainer" class="page-section-inner">
                  <table id="tblUserAuth" class="table table-bordered table-hover caption-top">
                     <thead class="table-secondary stickyHeaderGroup">
                        <tr>
                           <th class="stickyHeaderSingleCell user-header"></th>
                           <th v-for="(user, index) in cols" :key="index" class="tableCell user-header">
                              <div v-if="user.userName">
                                 <div class="dataTable-user-wrapper">
                                    <div class="user-name-info">
                                       <span>{{ user.userName.name }} {{ user.userName.surname }}</span>
                                       <small>{{ user.userName.email }}</small>
                                    </div>
                                    <div class="user-status-badge" @click="setUserStatus(user)">
                                       <div class="user-status-info" :class="user.userStatus ? 'active' : 'passive'">
                                          <span class="user-status-text">
                                             {{ user.userStatus ? $t("userAuths_userActive") : $t("userAuths_userPassive") }}
                                          </span>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </th>
                        </tr>
                     </thead>
                     <tbody>
                        <tr v-for="(role, index1) in roles" :key="index1" :class="role.forIT ? 'forIT' : role.forAdmin ? 'forAdmin' : 'forAgent'">
                           <template v-if="role.id != 25">
                              <td class="stickyHeaderSingleCell personal-auth">{{ `${$i18n.t("userAuths_lbl" + role.code)}` }}</td>
                              <td
                                 v-for="(roleCell, index2) in roleCells.filter((roleCell) => roleCell.roleId == role.id)"
                                 :key="index2"
                                 class="tableCell"
                                 :class="index1 % 2 == 0 ? 'odd' : 'even'">
                                 <div class="roleCell" v-if="roleCell.roleId == role.id && roleCell.enabled">
                                    <UICheckBox
                                       @click.native="setUserAuth(roleCell)"
                                       :id="roleCell.userId + 'checkBoxRoleField' + roleCell.roleId"
                                       :name="roleCell.userId + 'checkBoxRoleField' + roleCell.roleId"
                                       v-model="roleCell.checked" />
                                 </div>
                              </td>
                           </template>
                        </tr>
                        <tr class="tdPersonal">
                           <td class="stickyHeaderSingleCell personal-auth">
                              {{ `${$i18n.t("userAuths_lblPersonalAuth")}` }}
                           </td>
                           <td v-for="(user, index) in cols" :key="index" class="tableCell">
                              <div v-if="!user.personel" class="roleCell">
                                 <UICheckBox
                                    @click.native="setUserType(user)"
                                    :id="user.id + 'checkBoxTypeField'"
                                    :name="user.id + 'checkBoxTypeField'"
                                    v-model="user.separateUser" />
                              </div>
                           </td>
                        </tr>
                     </tbody>
                  </table>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>
import { userService } from "@/service/index.js";
import roles from "../../../../auth.json";
import { generateUuid } from "@/utils.js";
import UICheckBox from "@/components/unitary/form2/UICheckBox.vue";
export default {
   name: "UserAuthorization",
   components: {
      UICheckBox,
   },
   data() {
      return {
         cols: [],
         roles: [],
         roleCells: [],
      };
   },
   mounted() {
      roles
         .sort((a, b) => (a.index > b.index ? 1 : a.index < b.index ? -1 : 0))
         .map((rolesItem) => {
            const roleObj = {
               id: rolesItem.id,
               code: rolesItem.code,
               forAdmin: rolesItem.forAdmin ? rolesItem.forAdmin : false,
               forIT: rolesItem.forIT ? rolesItem.forIT : false,
            };
            this.roles.push(roleObj);
         });

      userService.listUserAuths({}).then((response) => {
         if (response.result.success) {
            response.userAuths
               .sort((a, b) => (a.name > b.name ? 1 : a.name < b.name ? -1 : 0))
               .map((userItem) => {
                  const userObj = {
                     id: userItem.id,
                     userStatus: userItem.status == 1 ? true : false,
                     separateUser: userItem.type == 3 ? true : false,
                     personel: userItem.type == 1 ? true : false,
                     userName: {
                        name: userItem.name,
                        surname: userItem.surname,
                        email: userItem.email,
                     },
                     userRoles: userItem.auths,
                  };
                  this.cols.push(userObj);
               });
            for (var role of this.roles) {
               for (var user of this.cols) {
                  if (user.id) {
                     var enabled = user.separateUser && role.forAdmin ? false : true;
                     this.roleCells.push({
                        userId: user.id,
                        roleId: role.id,
                        checked: user.userRoles && user.userRoles.includes(role.id),
                        forAdmin: role.forAdmin ? role.forAdmin : false,
                        forIT: role.forIT ? role.forIT : false,
                        enabled: enabled,
                     });
                  }
               }
            }
         }
      });
   },
   created() {},
   methods: {
      setUserAuth(roleCell) {
         var userId = roleCell.userId;
         document.body.style.cursor = "wait";
         var user = this.cols.filter((user) => user.id && user.id == userId)[0];
         var auths = this.getEnabledAuths(user);

         var status = user.userStatus ? 1 : 2;
         var type = user.personel ? 1 : user.separateUser ? 3 : 2;

         let query = {
            uuid: generateUuid(),
            id: userId,
            status: status,
            type: type,
            auths: auths,
         };

         userService.setUserAuths(query).then((response) => {
            if (response.result.success) {
               const authChanged = {
                  type: "SUCCESS",
                  message: this.$i18n.t(`userAuths_authChanged`),
                  count: false,
               };
               this.$store.commit("app/setNotification", authChanged);
            } else {
               const authChanged = {
                  type: "WARNING",
                  message: this.$i18n.t(`userAuths_authError`),
                  count: false,
               };
               roleCell.checked = !roleCell.checked;
               this.$store.commit("app/setNotification", authChanged);
               // console.log('SetAuths Error:::');
            }
            document.body.style.cursor = "default";
         });
      },
      setUserStatus(user) {
         var userId = user.id;
         var type = user.personel ? 1 : user.separateUser ? 3 : 2;
         document.body.style.cursor = "wait";
         var auths = this.getEnabledAuths(user);

         var status = !user.userStatus;
         let query = {
            uuid: generateUuid(),
            id: userId,
            type: type,
            status: status ? 1 : 2,
            auths: auths,
         };
         userService.setUserAuths(query).then((response) => {
            if (response.result.success) {
               const authChanged = {
                  type: "SUCCESS",
                  message: this.$i18n.t(`userAuths_authChanged`),
                  count: false,
               };
               user.userStatus = status;
               this.$store.commit("app/setNotification", authChanged);
            } else {
               const authChanged = {
                  type: "WARNING",
                  message: this.$i18n.t(`userAuths_authError`),
                  count: false,
               };
               user.userStatus = !status;
               this.$store.commit("app/setNotification", authChanged);
               // console.log('SetAuths Error:::');
            }
            document.body.style.cursor = "default";
         });
      },
      setUserType(user) {
         var userId = user.id;
         document.body.style.cursor = "wait";
         var auths = this.getEnabledAuths(user);

         var type = user.personel ? 1 : !user.separateUser ? 2 : 3;

         let query = {
            uuid: generateUuid(),
            id: userId,
            status: user.userStatus ? 1 : 2,
            type: type,
            auths: auths,
         };
         userService.setUserAuths(query).then((response) => {
            if (response.result.success) {
               const authChanged = {
                  type: "SUCCESS",
                  message: this.$i18n.t(`userAuths_authChanged`),
                  count: false,
               };
               user.separateUser = type == 3;
               this.$store.commit("app/setNotification", authChanged);
            } else {
               const authChanged = {
                  type: "WARNING",
                  message: this.$i18n.t(`userAuths_authError`),
                  count: false,
               };
               user.separateUser = !user.separateUser;
               this.$store.commit("app/setNotification", authChanged);
               // console.log('SetAuths Error:::');
            }
            document.body.style.cursor = "default";
         });
      },
      getEnabledAuths(user) {
         var auths = [];
         this.roleCells.map((cellItem) => {
            if (cellItem.userId == user.id) {
               if (cellItem.checked) {
                  if (!user.separateUser || (user.separateUser && !cellItem.forAdmin)) {
                     auths.push(cellItem.roleId);
                     cellItem.enabled = true;
                  } else cellItem.enabled = false;
               } else {
                  if (!user.separateUser || (user.separateUser && !cellItem.forAdmin)) cellItem.enabled = true;
                  else cellItem.enabled = false;
               }
            }
         });

         return auths;
      },
   },
};
</script>

<style lang="scss" scoped>
.user-authorization-page-wrapper {
   &.custom-scroll-user-auth ::-webkit-scrollbar {
      display: flex;
      height: 8px;
      width: 8px;
      overflow: auto;
   }
   &.custom-scroll-user-auth ::-webkit-scrollbar-thumb {
      background: #c0c5cc 0% 0% no-repeat padding-box;
      border-radius: 1px;
      opacity: 1;
   }
   &.custom-scroll-user-auth ::-webkit-scrollbar-track {
      background: #f2f2f2 0% 0% no-repeat padding-box;
      border-radius: 4px;
      opacity: 1;
   }

   .user-authorization-page-inner {
      padding: 1.5rem 2.5rem;

      .user-authorization-section-header {
         margin-bottom: 32px;
         border-bottom: 1px solid #ddd;
         .user-authorization-section-header-inner {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-bottom: 24px;

            .section-title {
               h4 {
                  font-size: 24px;
                  color: #373b42;
               }
            }
            .section-cta {
            }
         }
      }
      .user-authorization-section-content {
         .user-authorization-section-content-inner {
            &::v-deep .dataTable-user-wrapper {
               display: flex;
               align-items: center;
               justify-content: flex-start;
               flex-direction: column;

               .user-name-info {
                  display: flex;
                  flex-direction: column;
                  align-items: flex-start;
                  justify-content: flex-start;
                  text-align: left;
                  white-space: nowrap;
                  align-items: center;
                  span {
                     font-size: 14px;
                     font-weight: 500;
                  }
                  small {
                     color: #5c6166;
                     font-size: 12px;
                     font-weight: 400;
                  }
               }

               .user-status-badge {
                  margin-top: 8px;
                  &:hover {
                     cursor: pointer;
                  }
                  .user-status-info {
                     .user-status-text {
                        display: flex;
                        justify-content: center;
                        font-weight: 500;
                     }
                     &.active {
                        color: #00bf63;
                        top: 264px;
                        left: 680px;
                        width: 72px;
                        height: 24px;
                        background: #e6fff3 0% 0% no-repeat padding-box;
                        border: 1px solid #00bf63;
                        border-radius: 16px;
                        opacity: 1;
                     }
                     &.passive {
                        color: #e25252;
                        top: 264px;
                        left: 848px;
                        width: 72px;
                        height: 24px;
                        background: #ffe6e6 0% 0% no-repeat padding-box;
                        border: 1px solid #ea6767;
                        border-radius: 16px;
                        opacity: 1;
                     }
                  }
               }
            }

            &::v-deep .dataTable-user-phone {
               a {
                  font-size: 14px;
                  font-weight: 500;
                  color: #395990;
               }
            }
         }
      }
   }
}
.g-checkbox-container {
   display: inline-block;
   margin: 0;
   padding: 1px;
}

#stickyTableContainer {
   position: relative;
   overflow: auto;
   widows: 100%;
   height: 63vh;
   .stickyHeaderGroup {
      position: sticky;
      top: 0;
      z-index: 11;
   }

   .forAdmin {
   }
   .forIT {
      display: none;
   }
   .forAgent {
   }

   .tdPersonal {
   }

   .stickyHeaderSingleCell {
      background-color: white !important;
      left: 0rem;
      position: sticky;
      z-index: 10;
      white-space: nowrap;
      border-width: 0 0 0px;
      &.user-header {
         border-bottom: 1px solid #dee2e6;
      }
      &.personal-auth {
         border: 1px solid #dee2e6;
         border-bottom: 0px solid #dee2e6;
         border-top: 0px solid #dee2e6;
         background: #f5f7fa 0% 0% no-repeat padding-box !important;
      }
   }

   .roleCell {
      display: flex;
      justify-content: center;
      vertical-align: middle;
      &.disabled {
         pointer-events: none;
         opacity: 0.5;
      }
   }
}

#tblUserAuth {
   border-collapse: separate;
   border-spacing: 0;

   &.personal-auth:first-child {
      border-top-left-radius: 8px;
   }

   .tableCell {
      background-color: white;
      border: 1px solid #dbdbdb;
      &.user-header:nth-child(2) {
         border-top-left-radius: 8px;
      }
      &.user-header:last-child {
         border-top-right-radius: 8px;
      }
      &.odd {
         background: #f4f8ff 0% 0% no-repeat padding-box !important;
      }
   }
}
</style>
