<template>
   <div class="dynamic-segments-container">
      <div class="dynamic-segments-header" v-if="false">{{ Route }} - {{ FlightNumber }}</div>
      <!-- {{ nameAbbrForMouse }} -->
      <AncillarySeatMapPassengerWrapper
         :currency="currency"
         :seat-list="SeatList"
         :segmentIndex="segment.index"
         :flightKey="segment.flightKey"
         :segmentId="segment.id" />

      <div class="dynamic-segments-body-wrapper">
         <!-- <button @click="handlerYanyana">ayarla</button> -->
         <div class="seat-map-generator-informations-container">
            <ul class="seat-map-generator-informations-wrapper">
               <li>
                  <span class="indicator selected"></span>
                  <span class="information">{{ $t("ancillarySeatMap_lblSelected") }}</span>
               </li>
               <li>
                  <span class="indicator available"></span>
                  <span class="information">{{ $t("ancillarySeatMap_lblAvailable") }}</span>
               </li>
               <li>
                  <span class="indicator not-available"></span>
                  <span class="information">{{ $t("ancillarySeatMap_lblNotAvailable") }}</span>
               </li>
            </ul>

            <YanYana :segmentId="segment.id" v-if="IsYanYanaVisible" />
         </div>
         <SeatMapGenerator :currency="currency" :map="segment.rows" />
      </div>
   </div>
</template>

<script>
// import { flightService } from "@/service/index.js";
import AncillarySeatMapPassengerWrapper from "@/components/structural/flight/order-detail/ancillaries/seatmap2/AncillarySeatMapPassengerWrapper.vue";
import SeatMapGenerator from "@/components/structural/flight/order-detail/ancillaries/seatmap2/SeatMapGenerator.vue";
import YanYana from "@/components/structural/flight/order-detail/ancillaries/seatmap2/YanYana.vue";

export default {
   name: "DynamicSegments",
   props: {
      step: Number,
      segment: Object,
      currency: String,
   },
   components: {
      AncillarySeatMapPassengerWrapper,
      SeatMapGenerator,
      YanYana,
   },
   created() {
      this.$store.dispatch("seatmap/selectFirstPax", this.segment);
      this.$store.commit("seatmap/isModalInitialized", true);
   },
   beforeDestroy() {
      this.handlerSeatControls();
   },

   computed: {
      IsYanYanaVisible() {
         const firstPaxObj = this.$store.state.seatmap.segments[this.segment.id].paxes;
         const firstPax = Object.keys(firstPaxObj)[0];
         const seatSelected = firstPaxObj[firstPax].seat != null;
         const hasInfant = this.$store.state.seatmap.hasInfant.status;
         const paxNumber = Object.keys(firstPaxObj).length;
         const selectedPaxId = this.$store.state.seatmap.selectedPax.paxId == firstPax;

         return seatSelected && !hasInfant && paxNumber > 1 && selectedPaxId;
      },
      SeatList() {
         return this.segment.paxes;
      },

      Route() {
         return `${this.segment.departureAirport} - ${this.segment.arrivalAirport}`;
      },

      FlightNumber() {
         return this.segment.flightNumber;
      },

      nameAbbrForMouse() {
         const nameAbbr = this.$store.state.seatmap.selectedPax.nameAbbr;
         const showAbbr = nameAbbr.length != 0 ? true : false;
         return {
            nameAbbr,
            showAbbr,
         };
      },
   },
   methods: {
      handlerSeatControls() {
         const self = this;
         // const segmentIndex = self.segment.index;
         const paxes = self.segment.paxes;

         function areAllSeatsAssigned(passengers) {
            for (let key in passengers) {
               if (passengers[key].seat === null && passengers[key].paxTypeEnum != 3) {
                  return false;
               }
            }
            return true;
         }

         if (areAllSeatsAssigned(paxes)) {
            self.$store.commit("seatmap/setStep", { index: self.step, status: true });
         } else {
            self.$store.commit("seatmap/setStep", { index: self.step, status: false });
         }
      },
      handlerYanyana() {
         this.$store.dispatch("seatmap/selectYanyana", this.segment);
      },
   },
};
</script>

<style lang="scss" scoped>
.dynamic-segments-container {
   display: flex;

   ::v-deep {
      .ancillary-seatmap-passenger-wrapper-container {
         margin-right: 2rem;
         padding-right: 1rem;
         border-right: 8px solid #f4f6fa;
      }
   }

   .dynamic-segments-body-wrapper {
      // border: 1px solid red;
      width: 100%;
      .seat-map-generator-informations-container {
         display: flex;
         align-items: center;
         width: 100%;
         border-bottom: 2px solid #eee;
         margin-bottom: 1rem;
         padding-bottom: 0.5rem;
      }
      .seat-map-generator-informations-wrapper {
         display: flex;
         width: inherit;

         li {
            display: flex;
            align-items: center;
            margin-right: 2rem;

            .indicator {
               display: flex;
               justify-content: center;
               align-items: center;
               border-radius: 4px;
               width: 20px;
               height: 20px;
               margin-right: 0.5rem;

               &.selected {
                  background: var(--primary);
               }
               &.available {
                  background: #c5faca;
               }
               &.not-available {
                  background: #ebebeb;
                  &::after {
                     content: "x";
                     color: #b5b5b5;
                  }
               }
            }
            .information {
               font-size: 0.85rem;
            }
         }
      }
   }
}
</style>
