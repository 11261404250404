<template>
   <div class="hotel-card-debugger-container" v-if="infos.length != 0">
      <div class="hotel-card-debugger-inner-container" v-for="(info, index) in infos" :key="index">
         <span>{{ info.searchUid }}</span>
         <span>{{ info.fromDate }}</span>
         <span>{{ info.toDate }}</span>
         <span>{{ info.policyType }}</span>
         <span>{{ info.value }}</span>
         <span>{{ info.currency }}</span>
         <span>{{ info.amountType }}</span>
      </div>
   </div>
</template>

<script>
export default {
   name: "RoomCardDebuggerDetaild",
   props: {
      infos: {
         type: [],
         required: true,
      },
   },
};
</script>

<style lang="scss" scoped>
.hotel-card-debugger-container {
   background-color: #30353c;
   color: #32cd32;
   font-family: monospace;
   font-weight: 500;
   font-size: 1rem;
   padding: 0.5rem;
   margin: 0.5rem;
   border-radius: 0.5rem;
   .hotel-card-debugger-inner-container {
      display: flex;
      flex-direction: column;
      width: 100%;
      overflow: auto;
   }
}
</style>
