<template>
   <div class="chat-tab-container">
      <div class="chat-tab-header-wrapper" v-if="taskId != -1">
         <div class="header-info-wrapper">
            <div class="task-detail-info-inner">
               <div class="task-detail-info-item">
                  <span class="title">ID</span>
                  <span class="content">{{ Tab.id }}</span>
               </div>
               <div class="task-detail-info-item">
                  <span class="title">{{ $t("taskDetails_subStatus") }}</span>

                  <span class="content"> {{ $options.filters.enumTaskSubStatus(Tab.subStatue).text }}</span>
               </div>
               <div class="task-detail-info-item">
                  <span class="title">{{ $t("taskDetails_type") }}</span>
                  <span class="content">{{ $options.filters.enumTaskType(Tab.type).text }}</span>
               </div>
               <div class="task-detail-info-item">
                  <span class="title">{{ $t("taskDetails_taskOpenTime") }}</span>
                  <span class="content">
                     <span>
                        {{ Tab.openedDatetime | fullDateFormat }} -
                        {{ Tab.openedDatetime | TimeFormat }}
                     </span>
                     <span class="inserted-email">
                        {{ Tab.insertedUserEmail }}
                     </span>
                  </span>
               </div>
               <div class="task-detail-info-item">
                  <span class="title">{{ $t("taskDetails_openingNote") }}</span>
                  <span class="content">{{ Tab.description }}</span>
               </div>
            </div>
         </div>
         <div class="header-input-wrapper">
            <input type="text" v-model="newMessage" @keydown.enter="handlerSendMessage" :placeholder="$t('chatTabComponent_placeholder')" />
            <button @click="handlerSendMessage"></button>
         </div>
      </div>
      <div class="chat-tab-body-wrapper">
         <template v-for="(bubble, index) in Chat">
            <div class="chat-bubble-outer-container" :key="index" :class="CurrentUserId == bubble.insertedUserId ? 'my-messages' : ''">
               <ChatBubble :bubble="bubble" @has-update="handlerBubbleHasUpdate" :jump-to="taskId == -1 ? true : false" />
            </div>
         </template>
      </div>
   </div>
</template>

<script>
import { notesService } from "@/service/index.js";
import ChatBubble from "@/components/unitary/order/ServiceCenter/ChatBubble.vue";
export default {
   name: "ChatTab",
   props: {
      orderId: Number,
      taskId: Number,
      tabInformations: Object,
      isActive: Boolean,
   },
   data() {
      return {
         newMessage: "",
         chat: [],
         interval: null,
         query: {
            taskId: this.taskId,
            orderId: this.orderId,
            dbState: 1,
            noteId: 0,
         },
      };
   },
   components: {
      ChatBubble,
   },
   mounted() {
      const self = this;
      self.handlerUpdateChat();
   },

   computed: {
      Chat() {
         const self = this;
         return self.chat.reverse();
      },
      Tab() {
         const self = this;
         return self.tabInformations;
      },

      CurrentUserId() {
         const self = this;
         return self.$store.state.user.user.id;
      },
   },
   methods: {
      handlerUpdateChat() {
         const self = this;

         notesService.listNotes(self.query).then((res) => {
            if (res.result.success) {
               self.chat = res.notes;
            }
         });
      },
      handlerBubbleHasUpdate() {
         const self = this;
         self.handlerUpdateChat();
      },
      handlerSendMessage() {
         const self = this;
         let newMessage = {
            taskId: self.taskId,
            orderId: self.orderId,
            dbState: "1",
            noteId: "0",
            note: self.newMessage,
         };

         if (self.newMessage.length) {
            self.newMessage = "";
            notesService.addNote(newMessage).then((res) => {
               if (res.result.success) {
                  self.handlerUpdateChat();
               }
            });
         }
      },
   },
   watch: {
      isActive: {
         handler: function (isactive) {
            const self = this;

            if (isactive) {
               self.interval = setInterval(self.handlerUpdateChat, 10000);
            } else {
               clearInterval(self.interval);
               self.interval = null;
            }
         },
         immediate: true,
      },
   },
};
</script>

<style lang="scss" scoped>
.chat-tab-container {
   .chat-tab-header-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      background-color: #c9d6ed;

      .header-info-wrapper {
         padding: 2rem 2.5rem;
         .task-detail-info-inner {
            display: flex;
            .task-detail-info-item {
               margin-right: 1rem;
               padding-right: 1rem;
               border-right: 1px solid #a4bbe0;

               // Large devices (desktops, 992px and up)
               @media (min-width: 992px) {
                  margin-right: 2.5rem;
                  padding-right: 2.5rem;
               }
               .title {
                  font-size: var(--small);
                  font-weight: 400;
               }
               .content {
                  display: flex;
                  flex-direction: column;
                  font-weight: 600;
                  font-size: var(--normal);
                  text-transform: capitalize;

                  .inserted-email {
                     text-transform: lowercase;
                  }
               }
            }
         }
      }

      .header-input-wrapper {
         padding: 2rem;
         background-color: #dae3f5;
         display: flex;
         flex-direction: row;

         input {
            height: 56px !important;
            border-radius: 56px !important;
            width: 100%;
            outline: 0;
            border: 0;
            text-indent: 2rem;
            color: #74777b;
            margin-right: 1rem;

            &:focus {
               border: 1px solid var(--accent-primary);
               outline: 0.25rem solid #33b8ff38;
            }
         }
         button {
            width: 56px !important;
            height: 56px !important;
            background-color: #5c82b7;
            outline: 0;
            border: 0;
            border-radius: 50%;
            flex-grow: 0;
            flex-shrink: 0;
            background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNS4wMzIiIGhlaWdodD0iMjQuOTk4IiB2aWV3Qm94PSIwIDAgMjUuMDMyIDI0Ljk5OCI+PHBhdGggZD0iTTI4LjkyNiwxNi4wNzUsNi4wMDcsNC42MTVBMS4wMzEsMS4wMzEsMCwwLDAsNC41MzksNS43ODJMNy42MjIsMTcsNC41LDI4LjE5YTEuMDYxLDEuMDYxLDAsMCwwLDEuNTEsMS4yTDI4LjkyNiwxNy45MjlhMS4wNDIsMS4wNDIsMCwwLDAsMC0xLjg1NFpNNy4xNTQsMjYuNDkybDIuMy04LjQ0OWg5LjYyNlYxNS45Nkg5LjQ1NmwtMi4zLTguNDQ5TDI2LjEyNCwxN1oiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC00LjQ2MSAtNC41MDUpIiBmaWxsPSIjZmZmIi8+PC9zdmc+);
            background-repeat: no-repeat;
            background-position: center;
            background-size: 24px;
            background-position-x: 18px;
            transition-property: all;
            transition-timing-function: ease-in-out;
            transition-duration: 0.1s;

            &:hover {
               background-color: #175899;
               background-size: 26px;
            }
         }
      }
   }
   .chat-tab-body-wrapper {
      background-color: #fff;
      padding: 1rem 2rem;
      border: 2px solid #c9d6ed;
      border-radius: 0.5rem;
      border-top-left-radius: 0;
      .chat-bubble-outer-container {
         margin: 0.5rem 0;
         display: flex;
         flex-direction: column;
         align-items: flex-start;

         &::v-deep.my-messages {
            align-items: flex-end;

            .chat-bubble-container {
               flex-direction: row-reverse;

               .bubble-container {
                  background-color: #3e6fb3;
                  color: #fff;

                  .bubble-actions,
                  .jump-to {
                     display: flex;
                     i {
                        filter: invert(1) brightness(10);
                     }
                  }
               }

               .bubble-informations {
                  margin-right: 0;
                  margin-left: 1rem;
               }
            }
         }
      }
   }
}
</style>
