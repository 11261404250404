<template>
   <div id="checkout-contact-container" class="gts-box" :class="editMode ? '' : 'with-border'">
      <span class="section-title">{{ $t("checkoutContact_contactInformations") }}</span>
      <div class="checkout-contact-wrapper" v-if="contact == null && countriesObj.length == 0 ? false : true">
         <div class="contact-input">
            <UISelectBox
               :label="$t('common_countryPhoneCode')"
               id="orderSelect"
               :options="CountryOptionList"
               v-model="Contact.phone.country"
               allow-search
               has-icons />
         </div>
         <div class="contact-input">
            <UIInput
               v-model="Contact.phone.city"
               custom-class="g-short-input"
               :placeholder="$t('common_city')"
               :label="$t('common_city')"
               v-input="{ type: 'numeric', len: [2, 4], required: true, requiredMsg: $t('common_cannotBeEmpty') }" />
         </div>
         <div class="contact-input">
            <UIInput
               v-model="Contact.phone.number"
               :placeholder="$t('common_phone')"
               :label="$t('common_phone')"
               v-input="{ type: 'numeric', len: [3, 8], required: true, requiredMsg: $t('common_cannotBeEmpty') }" />
         </div>
         <div class="contact-input">
            <UIInput
               v-model="Contact.email"
               :placeholder="$t('common_email')"
               :label="$t('common_email')"
               lowercase
               v-input="{ type: 'email', len: [3, 320], required: true, requiredMsg: $t('common_cannotBeEmpty') }" />
         </div>
      </div>

      <div class="checkout-contact-information-wrapper">
         <p>* {{ $t("checkoutContact_informationText") }}</p>
      </div>
   </div>
</template>

<script>
import UISelectBox from "@/components/unitary/form2/UISelectBox.vue";
import UIInput from "@/components/unitary/form2/UIInput.vue";
export default {
   name: "CheckoutContact",
   components: {
      UISelectBox,
      UIInput,
   },
   props: {
      editMode: {
         type: Boolean,
         default: false,
      },
   },
   data() {
      return {
         countriesObj: [],
         contact: {
            phone: {
               country: null,
               city: "",
               number: "",
            },
            email: "",
         },
      };
   },
   computed: {
      Contact() {
         const self = this;
         if (self.editMode) {
            self.contact = self.$store.state.transfer.passengerInfo;
            return self.contact;
         }
         if (Object.keys(self.$store.state.transfer.passengerInfo).length !== 0 && self.$store.state.transfer.passengerInfo.constructor === Object) {
            self.contact = self.$store.state.transfer.passengerInfo;
         }
         self.contact.phone.country = "+49";
         return self.contact;
      },
      CountryOptionList() {
         const self = this;
         return self.countriesObj;
      },
   },

   watch: {
      contact: {
         handler: function (newValue) {
            this.$emit("update-to-contact", newValue);
            if (this.editMode) {
               this.$store.commit("transfer/updatePassengerInfo", newValue);
            }
         },
         deep: true,
      },
      "$store.state.app.countryList": {
         handler: function (countries) {
            const self = this;

            countries.forEach((country) => {
               const rowObj = {
                  value: country.phoneCode,
                  name: `${country.phoneCode} (${country.nameTr})`,
                  icon: country.code,
               };
               self.countriesObj.push(rowObj);
            });
         },
         immediate: true,
      },
   },
};
</script>

<style lang="scss" scoped>
#checkout-contact-container {
   .checkout-contact-wrapper {
      display: flex;
      flex-direction: column;

      @media (min-width: 576px) {
         display: grid;
         grid-template-columns: 1fr 1.25fr 2fr 2fr;
         width: 70%;
      }
      @media (min-width: 768px) {
         width: 100%;
      }
      @media (min-width: 992px) {
         width: 80%;
      }

      .contact-input {
         margin: 0 0.5rem;
         display: flex;

         @media (min-width: 576px) {
            margin-bottom: 0;
         }

         ::v-deep {
            .g-input-container {
               width: 100%;
            }
         }

         ::v-deep .form-item {
            input {
               position: relative;
               display: flex;
               justify-content: flex-start;
               align-items: center;
               border: 1px solid #b1b2b3;
               border-radius: 0.5rem;
               box-sizing: border-box;
               background: #fff;
               height: 48px !important;
               max-height: 100% !important;
               border-width: 2px;
               outline: transparent solid 2px;
               outline-offset: 2px;
               width: 100%;
               text-indent: 1rem;
               font-size: var(--normal);

               &.g-short-input {
                  width: 80px !important;
               }
            }
         }
      }
   }
   .checkout-contact-information-wrapper {
      margin-top: 2rem;
      margin-left: 0.5rem;
      p {
         font-size: var(--small);
         color: var(--txt-black);
      }
   }
}
</style>
