<template>
   <div class="seat-map-price-view-container">
      <template v-if="isLoading">
         <div class="widget-loader-wrapper">
            <span class="g-radial-loader"> </span>
         </div>
      </template>
      <template v-else>
         <span>{{ $t("ancillarySeatMap_lblGrandTotal") }}</span>
         <span class="amount">{{ totalAmount | price(currency) }}</span>
      </template>
   </div>
</template>

<script>
import { flightService } from "@/service/index.js";
export default {
   name: "SeatMapPriceView",
   props: {
      passengers: Object,
      segmentIndex: Number,
      flightKey: String,
      currency: String,
   },
   data() {
      return {
         isLoading: false,
         totalAmount: 0,
      };
   },
   created() {
      this.handlerAncillarySelectSeat();
   },
   computed: {
      TotalPax() {
         return Object.keys(this.passengers).length;
      },
      SelectSeatQuery() {
         return this.$store.state.seatmap.selectSeatQ;
      },
      passengerSeats() {
         return Object.keys(this.passengers).reduce((acc, key) => {
            acc[key] = this.passengers[key].seat;
            return acc;
         }, {});
      },
   },
   methods: {
      handlerCommitSeatAssignments(passengers) {
         Object.values(passengers).forEach((passenger) => {
            if (passenger.seat !== null && passenger.identifier != undefined) {
               this.$store.commit("seatmap/setSegmentsPaxesSeats", {
                  flightKey: this.flightKey,
                  identifier: this.segmentIndex,
                  rowObj: {
                     [passenger.paxIndex]: String(passenger.identifier), // Ensure identifier is a string
                  },
               });
            } else {
               this.$store.commit("seatmap/deleteSegmentsPaxesSeat", {
                  flightKey: this.flightKey,
                  identifier: this.segmentIndex,
                  paxIndex: passenger.paxIndex,
               });
            }
         });

         this.handlerAncillarySelectSeat();
      },
      handlerAncillarySelectSeat() {
         this.isLoading = true;
         // Assuming this.segmentIndex is available and holds the current segment index

         // Filter the SelectSeatQuery to include only the seats for the current segmentIndex
         const filteredSeats = {
            uuid: this.SelectSeatQuery.uuid,
            searchUuid: this.SelectSeatQuery.searchUuid,
            flightsSegmentsPaxesSeats: this.SelectSeatQuery.flightsSegmentsPaxesSeats,
         };

         flightService.ancillarySelectSeats(filteredSeats).then((res) => {
            this.isLoading = false;
            if (res.result.success) {
               this.totalAmount = res.grandTotal;
            }
            this.$store.commit("seatmap/setResult", res.result);
         });
      },
   },
   watch: {
      passengerSeats: {
         handler() {
            const self = this;
            console.log(this.passengers);
            this.handlerCommitSeatAssignments(self.passengers);
         },
         deep: true,
      },
   },
};
</script>

<style lang="scss" scoped>
.seat-map-price-view-container {
   display: flex;
   flex-direction: column;
   justify-content: flex-end;
   align-items: flex-end;
   width: 100%;

   span.amount {
      font-size: var(--xxlarge);
      font-weight: 600;
      color: var(--primary);
   }
}
</style>
