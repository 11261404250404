<template>
    <div class="g-radio-button-container" :class="nativeValue == value ? 'checked' : ''" @click="handlerInput">
        <span class="g-radio-check"></span>
        <input type="radio" :name="name" :id="id" :value="nativeValue" />
        <label :for="id">{{ label }}</label>
    </div>
</template>

<script>
export default {
    name: "UIRadioButton",
    props: {
        label: String,
        id: String,
        name: String,
        value: [String, Number],
        nativeValue: [String, Number],
    },
    methods: {
        handlerInput() {
            const self = this;
            self.$emit("input", self.nativeValue);
        },
    },
};
</script>

<style lang="scss" scoped>
.g-radio-button-container {
    display: flex;
    align-items: center;
    cursor: pointer;

    label {
        cursor: pointer;
        margin-left: 0.5rem;
        font-weight: 400;
    }

    .g-radio-check {
        width: 1.4rem;
        height: 1.4rem;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 2px solid var(--gray-lighten);
        border-radius: 50%;
        position: relative;
        flex-shrink: 0;
    }

    input {
        position: absolute;
        left: 0;
        opacity: 0;
        outline: 0;
        z-index: -1;
    }

    &.checked {
        label {
            // color: var(--accent-primary);
        }
        .g-radio-check {
            border: 2px solid var(--accent-primary) !important;
            background: var(--accent-primary);
            outline: 0.25rem solid var(--accent-primary-lightest);
            // box-shadow: rgba(51, 184, 255, 0.5) 0px 1px 6px;
            &::before {
                content: "";
                position: absolute;
                left: 0;
                right: 0;
                margin: auto;
                background: var(--accent-primary);
                background: #fff;
                width: 0.52rem;
                height: 0.52rem;
                border-radius: 50%;
            }
        }
    }
}
</style>
