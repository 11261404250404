import { PRIVATE_HTTP } from "./index";

const AUTOCOMPLETE = "api/autocomplete/transfer";
const MANUAL_TNR_ADD = "api/transfersearch/manualtnradd";
const SEARCH = "api/transfersearch/search";
const SELECT = "api/transfersearch/select";
const CHECKOUT = "/api/transfersale/checkout";
//const REVERSE_INQUIRY = "api/transferreverse/transferreverseinquiry";
const REVERSE = "api/transferreverse/reverse";
const REISSUE = "api/transferoperation/reissue";

export var transferSearch = (request) => {
   const promise = PRIVATE_HTTP().post(SEARCH, request);
   return promise.then((response) => {
      return response.data;
   });
};

export var transferSelect = (request) => {
   const promise = PRIVATE_HTTP().post(SELECT, request);
   return promise.then((response) => {
      return response.data;
   });
};

export var transferManualAdd = (request) => {
   const promise = PRIVATE_HTTP().post(MANUAL_TNR_ADD, request);
   return promise.then((response) => {
      return response.data;
   });
};

export var transferCheckout = (request) => {
   const promise = PRIVATE_HTTP().post(CHECKOUT, request);
   return promise.then((response) => {
      return response.data;
   });
};

export var transferAutocomplete = (request) => {
   const promise = PRIVATE_HTTP().post(AUTOCOMPLETE, request);
   return promise.then((response) => {
      return response.data;
   });
};

// export var transferReverseInquiry = (request) => {
//    const promise = PRIVATE_HTTP().post(REVERSE_INQUIRY, request);
//    return promise.then((response) => {
//       return response.data;
//    });
// };

export var transferReverse = (request) => {
   const promise = PRIVATE_HTTP().post(REVERSE, request);
   return promise.then((response) => {
      return response.data;
   });
};

export var transferReissue = (request) => {
   const promise = PRIVATE_HTTP().post(REISSUE, request);
   return promise.then((response) => {
      return response.data;
   });
};
