<template>
   <!-- <div id="user-control-box-wrapper"> -->
   <div id="user-control-box-wrapper" @mouseover="dropdown = true" @mouseleave="dropdown = false">
      <div id="ucb-dropdown">
         <!-- <img src="@/assets/images/profilePhoto.jpg" loading="lazy" alt="user profile photo" /> -->
         <div id="ucb-profile-photo" :class="NoAgencySelected ? 'no-agency-selected' : ''" v-if="!isMobileMenu">
            {{ (User.name + " " + User.surname) | fakiePhoto }}
         </div>
         <div id="ucb-close-mobile-menu" v-else></div>

         <div class="user-informations-wrapper">
            <div class="user-informations">
               <span class="name-surname">{{ User.name }} {{ User.surname }}</span>
               <span class="agency-name">{{ User.agencyName }}</span>
               <span class="agency-iatiid">{{ User.agencyIatiId }}</span>
            </div>
            <i class="gts-icon icon-arrow icon-xs"></i>
         </div>
      </div>

      <div id="ucb-dropdown-box" v-if="dropdown && gsaList && agencyList">
         <!-- {{ agencyList }} -->
         <!-- {{ OnBehalfOfInformations }} -->
         <GsaSelection :list="gsaList" :selectedGsa="OnBehalfOfInformations.gsaOnBehalfOfId" :is-disabled="selectionIsDisabled" />
         <AgencySelection :list="agencyList" :selectedAgency="OnBehalfOfInformations.agencyOnBehalfOfId" :is-disabled="selectionIsDisabled" />
         <hr />
         <router-link to="/" class="dropdown-item">{{ $t("userControlBox_lnkDashboard") }}</router-link>
         <router-link
            v-if="roles.includes(15) || roles.includes(16) || roles.includes(17) || roles.includes(18)"
            to="/settings"
            class="dropdown-item"
            >{{ $t("userControlBox_lnkSettings") }}</router-link
         >
         <hr />
         <router-link to="/logout" class="dropdown-item">{{ $t("userControlBox_lnkLogout") }}</router-link>
      </div>
   </div>
</template>

<script>
import GsaSelection from "@/components/unitary/GsaSelection.vue";
import AgencySelection from "@/components/unitary/AgencySelection.vue";
import { agencyService } from "@/service/index.js";
export default {
   name: "UserControlBox",
   props: {
      isMobileMenu: Boolean,
   },
   components: {
      GsaSelection,
      AgencySelection,
   },

   data() {
      return {
         selectionIsDisabled: false,
         dropdown: false,
         value: null,
         placeholderName: "pick a",
         user: false,
         agencyList: null,
         gsaList: null,
         onBehalfOf: {
            gsa: null,
            agency: null,
         },
         roles: [],
      };
   },
   mounted() {
      try {
         this.roles = JSON.parse(localStorage.getItem("auths"));
      } catch {
         // console.warn("Cant parse roles");
      }
   },
   // created() {
   //     listGsas();
   //     listAgencies();
   // },
   methods: {
      listGsas() {
         const self = this;
         agencyService.listGsas({}).then((res) => {
            if (res.result.success) {
               self.gsaList = res.gsas;

               //set the current gsa
               if (self.onBehalfOf.gsa && self.onBehalfOf.gsa != -1) {
                  const selectedGsa = self.gsaList.filter((gsa) => gsa.id == self.onBehalfOf.gsa);
                  self.$store.state.user.user.gsaName = selectedGsa[0].name;
               }
            }
         });
      },
      listAgencies() {
         const self = this;
         agencyService.listAgencies({}).then((res) => {
            if (res.result.success) {
               self.agencyList = res.agencies;

               //set the current agency
               if (self.onBehalfOf.agency && self.onBehalfOf.agency != -1) {
                  const selectedAgency = self.agencyList.filter((agency) => agency.id == self.onBehalfOf.agency);
                  self.$store.state.user.user.agencyName = selectedAgency[0].name;
               }
            }
         });
      },
   },
   computed: {
      OnBehalfOfInformations() {
         return this.$store.state.user.user.onBehalfOfs;
      },
      User() {
         const self = this;
         return self.user;
      },
      NoAgencySelected() {
         const self = this;
         if (self.$store.state.user.user.onBehalfOfs.agencyOnBehalfOfId == -1) {
            return true;
         } else {
            return false;
         }
      },
   },
   watch: {
      "$route.path": {
         handler: function (path) {
            const isCheckout = path.includes("/checkout");
            const isOrderDetail = path.includes("/order/detail/");
            if (isCheckout || isOrderDetail) {
               this.selectionIsDisabled = true;
            } else {
               this.selectionIsDisabled = false;
            }
         },
         immediate: true,
      },
      // "$store.state.user.user.onBehalfOfs": {
      //     handler: function () {
      //         const self = this;
      //         self.loaded = true;
      //         self.user = self.$store.state.user.user;
      //         self.onBehalfOf.gsa = self.$store.state.user.user.onBehalfOfs.gsaOnBehalfOfId;
      //         self.onBehalfOf.agency = self.$store.state.user.user.onBehalfOfs.agencyOnBehalfOfId;
      //         if(self.agencyList == null){
      //             self.listAgencies();
      //         }
      //         if(self.gsaList == null){
      //             self.listGsas();
      //         }
      //     },
      //     immediate: false,
      //     deep: true,
      // },
      "$store.state.app.agencyList": {
         handler: function () {
            const self = this;
            self.onBehalfOf.gsa = self.$store.state.user.user.onBehalfOfs.gsaOnBehalfOfId;
            self.onBehalfOf.agency = self.$store.state.user.user.onBehalfOfs.agencyOnBehalfOfId;
            self.user = self.$store.state.user.user;
            self.agencyList = self.$store.state.app.agencyList;
            if (self.onBehalfOf.agency && self.onBehalfOf.agency != -1) {
               const selectedAgency = self.agencyList.filter((agency) => agency.id == self.onBehalfOf.agency);
               self.$store.state.user.user.agencyName = selectedAgency[0] ? selectedAgency[0].name : "";
            }
         },
         immediate: true,
         deep: true,
      },
      "$store.state.app.gsaList": {
         handler: function () {
            const self = this;
            self.onBehalfOf.gsa = self.$store.state.user.user.onBehalfOfs.gsaOnBehalfOfId;
            self.onBehalfOf.agency = self.$store.state.user.user.onBehalfOfs.agencyOnBehalfOfId;
            self.user = self.$store.state.user.user;
            self.gsaList = self.$store.state.app.gsaList;
            if (self.onBehalfOf.gsa && self.onBehalfOf.gsa != -1) {
               const selectedGsa = self.gsaList.filter((gsa) => gsa.id == self.onBehalfOf.gsa);
               self.$store.state.user.user.gsaName = selectedGsa[0] ? selectedGsa[0].name : "";
            }
         },
         immediate: true,
         deep: true,
      },
   },
};
</script>

<style lang="scss" scoped>
#user-control-box-wrapper {
   display: flex;
   flex-direction: column;
   position: relative;
   height: 100%;
   cursor: pointer;
   margin-left: 1rem;
   border-left: 1px solid #ccc;
   padding-left: 1rem;
   @media (min-width: 576px) {
      margin-left: 0;
      border: none;
      padding-left: 0;
   }

   #ucb-dropdown {
      display: flex;
      align-items: center;
      height: 100%;
      img {
         width: 56px !important;
         height: 56px !important;
         border-radius: 50%;
         margin-right: 0.75rem;
      }
      #ucb-profile-photo {
         width: 56px !important;
         height: 56px !important;
         border-radius: 50%;
         background: var(--accent-primary);
         display: flex;
         align-items: center;
         justify-content: center;
         color: #fff;
         font-size: var(--large);
         font-weight: 500;
         border-radius: 50%;
         margin-right: 0;

         @media (min-width: 992px) {
            margin-right: 0.75rem;
         }

         &.no-agency-selected {
            background: var(--accent-gray);
            color: var(--txt-black);
         }
      }
      #ucb-close-mobile-menu {
         width: 56px !important;
         height: 56px !important;
         border-radius: 50%;
         border: 1px solid #ccc;
         // background-color: #363636;
         background-color: #eee;
         position: relative;

         &:before,
         &:after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            margin: auto;
            display: block;
            width: 24px;
            height: 2px;
            background-color: #fff;
            background-color: #363636;
         }
         &:before {
            transform: rotate(45deg);
         }
         &:after {
            transform: rotate(-45deg);
         }
      }

      .user-informations-wrapper {
         display: flex;
         align-items: center;
         display: none;
         @media (min-width: 992px) {
            display: flex;
         }
         .user-informations {
            display: flex;
            flex-direction: column;
            white-space: nowrap;
            span {
               &.name-surname {
                  font-size: var(--normal);
                  font-weight: 500;
               }
               &.agency-name {
                  color: var(--ghost-text-color);
                  font-size: var(--small);
               }
               &.agency-iatiid {
                  color: var(--ghost-text-color);
                  font-size: var(--small);
               }
            }
         }
         i {
            transform: rotate(90deg);
            margin-left: 1.5rem;
         }
      }
   }
   @keyframes dropdown {
      from {
         opacity: 0;
         top: 110%;
      }
      to {
         opacity: 1;
         top: 100%;
      }
   }
   #ucb-dropdown-box {
      display: none;
      animation-name: dropdown;
      animation-timing-function: ease-in-out;
      animation-duration: 0.15s;
      position: absolute;
      right: 0;
      padding: 32px 16px;
      border-radius: 8px;
      background-clip: padding-box;
      background-color: #fff;
      top: 100%;
      width: 280px;
      box-shadow: rgba(0, 0, 0, 0.2) 0px 10px 30px 0px;

      @media (min-width: 992px) {
         display: block;
      }
      &:before {
         content: "";
         position: absolute;
         top: -6px;
         right: 1.75rem;
         width: 0;
         height: 0;
         -webkit-transform: translateX(-50%);
         -ms-transform: translateX(-50%);
         transform: translateX(-50%);
         border-right: 6px solid transparent;
         border-bottom: 6px solid #fff;
         border-left: 6px solid transparent;
      }
      .multiselect-container {
         padding: 0.5rem 1rem;
      }
      hr {
         display: block;
         height: 1px;
         border: 0;
         background: #e7e7e9;
         opacity: 1;
      }
      a {
         // text-transform: capitalize;
         font-size: 1rem;
         color: #363a40;
         width: 100%;
         padding: 0.5rem 1rem;

         &:hover {
            background-color: rgba(13, 12, 34, 0.05);
         }
      }
   }
}
</style>
