<template>
   <div class="campaign-container" :class="[activetab == 'list' ? 'list-view' : '', properties.content?.length == 0 ? 'shorten' : '']">
      <!-- <div class="campaign-container" :class="activetab == 'list' ? 'list-view' : ''"> -->
      <div class="campaign-header-wrapper">
         <span>{{ $t(properties?.name) }}</span>
      </div>
      <template v-if="properties.pending">
         <div class="widget-loader-wrapper">
            <span class="g-radial-loader"> </span>
         </div>
      </template>
      <template v-else-if="properties.content.length == 0">
         <span class="no-content">{{ $t("dashboardCampaigns_noContent") }}</span>
      </template>
      <template v-else>
         <b-tabs v-model="activetab">
            <b-tab-item value="list">
               <section class="carousel-wrapper">
                  <div class="swiper" ref="campaigns">
                     <div class="swiper-wrapper">
                        <div class="swiper-slide" v-for="(item, index) in properties.content" :key="index" @click="handlerShowDetail(item)">
                           <span class="campaign-title"> {{ item.name }}</span>
                           <div class="campaign-text" v-html="item.briefHtml"></div>
                           <span class="campaign-show-details-button">{{ $t("dashboardPage_lblShowDetails") }}</span>
                           <div class="campaign-remaining-days-container" :class="item.validDayCount < 4 && item.validDayCount > 0 ? '' : 'hide'">
                              <i class="gts-icon icon-dashboard-campaings-countdown"></i>
                              <span class="campaign-remaining-days-text">{{ handlerValidDayCount(item.validDayCount) }}</span>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div class="carousel-pagination-wrapper">
                     <div class="carousel-pagination-inner-wrapper">
                        <div class="pagination-prev-wrapper" @click="handlerSlidePrev">
                           <i class="gts-icon icon-arrow-right"></i>
                        </div>
                        <div class="pagination-next-wrapper" @click="handlerSlideNext">
                           <i class="gts-icon icon-arrow-right"></i>
                        </div>
                     </div>
                  </div>
               </section>
            </b-tab-item>
            <b-tab-item value="detail">
               <Section class="campaign-detail-wrapper">
                  <div class="campaign-show-list" @click="activetab = 'list'">
                     <i class="gts-icon icon-arrow-full icon-sm"></i>
                     <span> {{ $t("dashboardPage_lblCampaignList") }}</span>
                  </div>
                  <img class="campaign-detail-thumbnail" :src="`${$constants.cdn}/announcements/${details.id}.jpg`" />
                  <h2 class="detail-title">
                     {{ details.name }}
                  </h2>
                  <div class="campaign-detail" v-html="details.detailHtml"></div>
               </Section>
            </b-tab-item>
         </b-tabs>
      </template>
   </div>
</template>

<script>
import Swiper from "swiper";

import "swiper/swiper-bundle.css";
import "swiper/swiper.min.css";

export default {
   name: "Campaign",
   props: {
      properties: Object,
   },
   beforeDestroy() {
      this.campaignsTimerClear();
      this.myInterval = null;
      console.log(this.myInterval);
   },
   created() {
      setInterval(this.campaignsTimer, 8000);
   },
   data() {
      return {
         activetab: "list",
         details: Object,
         myInterval: null,
         slider: null,
      };
   },
   methods: {
      handlerShowDetail(item) {
         this.activetab = "detail";
         this.details = item;
      },
      campaignsTimer() {
         if (this.myInterval != null) {
            if (this.slider.activeIndex + 1 === this.properties.content.length) {
               this.slider.slideTo(0);
            } else {
               this.slider.slideNext();
            }
         }
      },
      campaignsTimerClear() {
         clearInterval(this.myInterval);
         this.myInterval = setInterval(this.campaignsTimer, 8000);
      },
      handlerValidDayCount(val) {
         const self = this;
         return self.$t("dashboardPage_lblValidDayCount").replace("#VALUE#", val);
      },
      handlerSlidePrev() {
         this.slider.slidePrev();
         this.campaignsTimerClear();
      },
      handlerSlideNext() {
         console.log("handlerSlideNext");
         if (this.slider.activeIndex + 1 === this.properties.content.length) {
            this.slider.slideTo(0);
         } else {
            this.slider.slideNext();
         }

         this.campaignsTimerClear();
      },
   },
   watch: {
      "properties.pending": {
         handler: function (status) {
            if (!status) {
               this.activetab = "list";
               this.$nextTick(() => {
                  this.slider = new Swiper(this.$refs.campaigns, {
                     scrollbar: false,
                     slidesPerView: 1,
                     speed: 200,
                     autoplay: {
                        delay: 0,
                        disableOnInteraction: false,
                     },
                     loop: true,
                     spaceBetween: 0,
                     slidesOffsetAfter: 0,
                     slidesOffsetBefore: 0,
                  });
               });
            }
         },
      },
      deep: true,
      immediate: true,
   },
};
</script>

<style lang="scss" scoped>
@keyframes attention {
   0% {
      background-color: #2e67b8;
   }
   50% {
      background-color: #2e67b8;
   }
   100% {
      background-color: var(--accent-primary);
      // background-color: #1c4e96;
   }
}

.campaign-container {
   background-color: #2e67b8;
   border-radius: 1rem;
   padding: 1.5rem;

   margin-bottom: 1.5rem;
   animation-name: attention;
   animation-fill-mode: backwards;
   animation-iteration-count: 4;
   animation-timing-function: ease-out;
   animation-duration: 0.35s;
   animation-delay: 1s;

   .no-content {
      color: #fff;
      font-size: var(--large);
   }

   .widget-loader-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 6rem;
      .g-radial-loader {
         $size: 42px;
         width: $size;
         height: $size;
         border-width: 4px;
         border-color: #ffffff;
      }
   }

   &.list-view {
      min-height: 354px;
   }
   &.shorten {
      height: auto;
   }
   .campaign-header-wrapper {
      border-bottom: 1px solid #fff;
      padding-bottom: 0.5rem;
      margin-bottom: 0.5rem;
      span {
         color: #0dffd0;
         font-size: var(--large);
         font-weight: 600;
      }
   }
   .carousel-wrapper {
      position: relative;
      height: 100%;
      .swiper-slide {
         border-radius: 1rem;
         // background-color: #363636;
         padding: 0.75rem;
         box-sizing: border-box;
         cursor: pointer;
         &:hover {
            background-color: #ffffff20;
         }
      }

      .campaign-title {
         display: block;

         font-size: 2rem;
         color: #fff;
         font-weight: 600;
         line-height: 2.35rem;
      }
      .campaign-text {
         padding-top: 0.5rem;
         color: #fff !important;
         line-height: 1.25rem;
         p > strong {
            color: #ffffff !important;
         }
      }
      .campaign-show-details-button {
         display: block;
         color: #0dffd0;
         font-weight: 600;
         text-decoration: underline;
         margin-top: 0.5rem;
      }
      .campaign-remaining-days-container {
         margin-top: 1rem;
         display: inline-flex;
         padding: 0.25rem 0.5rem;
         padding-right: 1rem;
         align-items: center;
         font-weight: 600;
         background-color: #0dffd0;
         border-radius: 0.5rem;
         i {
            $dim: 22px;
            width: $dim !important;
            height: $dim !important;
         }
         span {
            margin-left: 0.5rem;
         }
         &.hide {
            display: none;
         }
      }

      .carousel-pagination-wrapper {
         display: flex;
         justify-content: flex-end;
         position: absolute;
         width: fit-content;
         z-index: 100;
         bottom: 8px;
         right: 0;
         .carousel-pagination-inner-wrapper {
            background: #2e67b8;
            // background: black;
            display: flex;

            .pagination-prev-wrapper {
               border-right: none !important;
               i {
                  transform: rotate(-180deg);
               }
            }
            .pagination-prev-wrapper,
            .pagination-next-wrapper {
               cursor: pointer;
               display: flex;
               align-items: center;
               justify-content: center;
               $dim: 38px;
               width: $dim;
               height: $dim;
               border: 2px solid #fff;

               i {
                  filter: brightness(10);
                  $dim: 25px !important;
                  width: $dim;
                  height: $dim;
                  display: block;
               }

               &:hover {
                  background-color: #ffffff20;
               }
            }
         }
      }
   }
   section.campaign-detail-wrapper {
      color: #ffffff;
      padding: 0 0.5rem;
      img.campaign-detail-thumbnail {
         border-radius: 0.5rem;
      }
      h2.detail-title {
         font-size: var(--large);
         font-weight: 600;
         margin-top: 0.5rem;
      }
      .campaign-show-list {
         display: flex;
         margin-bottom: 1rem;

         i {
            transform: rotate(-180deg);
            filter: brightness(10);
         }

         span {
            margin-left: 0.5rem;
            font-weight: 500;
         }
         &:hover {
            cursor: pointer;
         }
      }
   }
}
</style>

<style lang="scss">
.campaign-container {
   position: relative;
   display: flex;
   flex-direction: column;
   .b-tabs {
      // border: 1px solid red;
      height: 100%;
      position: relative;
      // position: absolute;
      // right: 0;
      // left: 0;
      // bottom: 0;
      // // background: red;
      .tabs {
         display: none;
      }
      .tab-content {
         padding: 0 !important;
         height: 100%;

         .tab-item {
            height: 100%;
         }
      }
   }
}

.campaign-text {
   padding-top: 0.5rem;
   color: #fff !important;
   line-height: 1.25rem;
   p > strong {
      color: #ffffff !important;
   }
}

section.campaign-detail-wrapper {
   .campaign-detail {
      a {
         color: var(--primary);
         font-weight: 500;
      }
      p {
         text-indent: 0rem !important;
      }
      strong {
         color: #ffffff;
      }
   }
}
</style>
