import { PRIVATE_HTTP } from "./index";
import router from "@/router/index.js";

const ADD_NOTE_ENDPOINT = "api/note/addnote";
const SET_NOTE_ENDPOINT = "api/note/setnote";
const LIST_NOTES_ENDPOINT = "api/note/listnotes";

//TODO:tasks altına taşınmalı
const LIST_TASKS_ENDPOINT = "api/task/listtasks";

export var setNote = (requestModel) => {
    const promise = PRIVATE_HTTP().post(SET_NOTE_ENDPOINT, requestModel);
    const resData = promise
        .then((res) => res.data)
        .catch((error) => {
            if (error.response.status === 401) {
                if (router.currentRoute.path != "/login") {
                    router.push("/login");
                }
            } 
            // else 
            // {
            //     console.log(error);
            // }
        });

    return resData;
};
export var addNote = (requestModel) => {
    const promise = PRIVATE_HTTP().post(ADD_NOTE_ENDPOINT, requestModel);
    const resData = promise
        .then((res) => res.data)
        .catch((error) => {
            if (error.response.status === 401) {
                if (router.currentRoute.path != "/login") {
                    router.push("/login");
                }
            } 
            // else 
            // {
            //     console.log(error);
            // }
        });

    return resData;
};
export var listNotes = (requestModel) => {
    const promise = PRIVATE_HTTP().post(LIST_NOTES_ENDPOINT, requestModel);
    const resData = promise
        .then((res) => res.data)
        .catch((error) => {
            if (error.response.status === 401) {
                if (router.currentRoute.path != "/login") {
                    router.push("/login");
                }
            } 
            // else 
            // {
            //     console.log(error);
            // }
        });

    return resData;
};

export var listTasks = (requestModel) => {
    const promise = PRIVATE_HTTP().post(LIST_TASKS_ENDPOINT, requestModel);
    const responseData = promise
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            if (error.response.status === 401) {
                if (router.currentRoute.path != "/login") {
                    router.push("/login");
                }
            } 
            // else 
            // {
            //     console.log(error);
            // }
        });

    return responseData;
};
