<template>
   <div class="customer-list-container">
      <div class="customer-add-new-action-wrapper">
         <UIButton className="default_outline" :text="$t('customerManagement_btnAddNewCustomer')" @click.native="handlerAddNewCustomer" />
         <!-- <UIButton className="default_outline" text="Add New" @click.native="handlerAddNewCustomer" /> -->
      </div>
      <!-- Sticky Header -->
      <section class="sticky-header">
         <div class="fihrist-user-control">
            <!-- Fihrist Letters -->
            <ul id="fihrist-container">
               <li
                  class="fihrist-letter"
                  v-for="(letter, index) in fihrist"
                  :key="index"
                  @click="handlerFihristFilter(letter)"
                  :class="fihristFilter == letter ? 'active-fihrist' : ''">
                  {{ letter }}
               </li>
            </ul>
            <UIInput v-model="search" :placeholder="$t('common_search')" allow-clear rounded />
         </div>

         <!-- Table Header Row -->
         <div class="customer-header-row" :class="hiddenColumns ? 'in-modal' : ''">
            <div class="customer-col"><span>#</span></div>
            <div class="customer-col">
               <span>{{ $t("customerManagement_thNameSurname") }}</span>
            </div>
            <div class="customer-col" v-if="!hiddenColumns">
               <span>{{ $t("customerManagement_thEmail") }}</span>
            </div>
            <div class="customer-col">
               <span>{{ $t("customerManagement_thBirthdate") }}</span>
            </div>
            <div class="customer-col" v-if="!hiddenColumns">
               <span>{{ $t("customerManagement_thPhone") }}</span>
            </div>
            <div class="customer-col" v-if="!hiddenColumns">
               <span>{{ $t("customerManagement_thNationality") }}</span>
            </div>
            <div class="customer-col" v-if="!hiddenColumns">
               <span>{{ $t("customerManagement_thNationalIdentity") }}</span>
            </div>
            <div class="customer-col" v-if="!hiddenColumns">
               <span>{{ $t("customerManagement_thPassportNumber") }}</span>
            </div>
            <div class="customer-col"><span></span></div>
         </div>
      </section>

      <!-- Table Body Row -->
      <section class="customer-body-row-container">
         <div class="customer-body-row-loader" v-if="loading">
            <span class="g-radial-loader"></span>
         </div>
         <template v-else>
            <div
               class="customer-body-row"
               v-for="(customer, index) in handlerCustomerList"
               :key="index"
               :id="customer.anchor"
               :class="hiddenColumns ? 'in-modal' : ''">
               <div class="customer-col" @click="handlerSelectCustomer(customer.index)">
                  <span class="customer-fakie-photo" :class="customer.gender == 1 ? 'male' : 'female'">
                     {{ (customer.name + " " + customer.surname) | fakiePhoto }}
                  </span>
               </div>

               <div class="customer-col">
                  <span class="customer-name"> {{ customer.name }}</span>
                  <span class="customer-surname"> {{ customer.surname }} </span>
               </div>

               <div class="customer-col" v-if="!hiddenColumns">
                  <span class="customer-email">
                     {{ customer.email }}
                  </span>
               </div>
               <div class="customer-col">
                  <span class="customer-birthdate">
                     {{ customer.birthdate | fullDateFormat }}
                  </span>
               </div>

               <div class="customer-col" v-if="!hiddenColumns">
                  <span>
                     {{
                        customer.phone.country + customer.phone.city + customer.phone.number == 0
                           ? ""
                           : customer.phone.country + customer.phone.city + customer.phone.number
                     }}
                  </span>
               </div>
               <div class="customer-col" v-if="!hiddenColumns">
                  {{ customer.nationality }}
               </div>
               <div class="customer-col" v-if="!hiddenColumns">
                  {{ customer.nationalIdentity }}
               </div>
               <div class="customer-col" v-if="!hiddenColumns">
                  {{ customer.passport.number }}
               </div>

               <div class="customer-col">
                  <section class="action-buttons">
                     <button class="edit-customer" @click="handlerEditCustomer(customer.index)">
                        <UITooltip
                           class="gts-animation-scale"
                           :tooltip-title="$t('customerManagement_editCustomer')"
                           tooltip-icon="gts-icon icon-edit-note icon-sm"
                           tooltip-position="bottom"
                           no-padding />
                     </button>
                     <button class="delete-customer" @click="handlerDeleteCustomer(customer.index)">
                        <UITooltip
                           class="gts-animation-scale"
                           :tooltip-title="$t('customerManagement_deleteCustomer')"
                           tooltip-icon="gts-icon icon-delete-note icon-sm"
                           tooltip-position="bottom"
                           no-padding />
                     </button>
                  </section>
               </div>
            </div>
         </template>
      </section>
   </div>
</template>

<script>
import Popup from "@/gts-popup.js";
import { customerService } from "@/service/index.js";
import UIInput from "@/components/unitary/form2/UIInput.vue";
import UIButton from "@/components/unitary/Button.vue";
import UITooltip from "@/components/utils/GtsToolTips.vue";
export default {
   name: "CustomerList",
   props: {
      customer: String,
      selectedCustomers: [Object, Array],
      hiddenColumns: Boolean,
   },
   components: {
      UIButton,
      UITooltip,
      UIInput,
   },
   data() {
      return {
         fihrist: {},
         customerList: [],
         listCustomerRequestModel: {
            status: 1,
         },
         fihristFilter: "",
         loading: true,
         search: "",
      };
   },
   created() {
      const self = this;
      var anchorPoint;
      customerService.listCustomers(self.listCustomerRequestModel).then((response) => {
         if (response.result.success) {
            response.customers.map((customer, index) => {
               if (!Object.prototype.hasOwnProperty.call(self.fihrist, customer.name[0])) {
                  self.fihrist[customer.name[0]] = customer.name[0];
                  anchorPoint = customer.name[0];
               } else {
                  anchorPoint = false;
               }
               customer["anchor"] = anchorPoint;
               customer["index"] = index;
               self.customerList.push(customer);
            });
            self.loading = false;
         }
      });
   },

   computed: {
      Search() {
         const self = this;
         return self.customerList.filter((row) =>
            JSON.stringify(row.name + row.surname)
               .toLowerCase()
               .includes(self.search.toLowerCase())
         );
      },

      handlerCustomerList() {
         const self = this;
         let fihristLength = self.Search.filter((customer) => customer.name.charAt(0) == self.fihristFilter).length;
         if (self.fihristFilter.length == 0 || fihristLength == 0) {
            self.fihristFilter = "";
            return self.Search;
         } else {
            return self.Search.filter((customer) => customer.name.charAt(0) == self.fihristFilter);
         }
      },
   },
   methods: {
      handlerAddNewCustomer() {
         const newCustomer = {
            agencyId: this.$store.state.user.user.agencyId,
            agencyName: this.$store.state.user.user.agencyName,
            status: 1,
            gender: 2,
            birthdate: null,
            name: null,
            surname: null,
            email: null,
            phone: {
               iso: null,
               country: "+49",
               city: null,
               number: null,
            },
            nationality: "DE",
            nationalIdentity: null,
            passport: {
               serial: null,
               number: null,
               country: null,
               expiry: null,
            },
            milesPan: null,
            hesCode: null,
            id: 0,
            dbState: 1,
            insertedUserId: null,
            insertedUserEmail: null,
            insertedDatetime: null,
            updatedUserId: null,
            updatedUserEmail: null,
            updatedDatetime: null,
         };
         this.$emit("customer-selection", newCustomer);
      },
      handlerSelectCustomer(index) {
         this.$emit("set-customer", this.customerList[index]);
      },
      handlerEditCustomer(index) {
         if (this.selectedCustomer == index) {
            this.selectedCustomer = null;
         } else {
            this.selectedCustomer = index;
         }

         this.$emit("customer-selection", this.customerList[index]);
      },
      handlerDeleteCustomer(index) {
         const self = this;
         let query = self.customerList[index];
         query.status = 2;

         customerService.setCustomer(query).then((response) => {
            if (response.result.success) {
               Popup.success(self.$i18n.t("common_success"), self.$i18n.t("customerManagement_popupCustomerDeleteSubtitle"));
               self.$nextTick(() => {
                  document.body.querySelector("#popup-ok").addEventListener("click", function () {
                     customerService.listCustomers(self.listCustomerRequestModel).then((response) => {
                        if (response.result.success) {
                           self.customerList = [];
                           self.fihrist = {};
                           var anchorPoint;
                           response.customers.map((customer, index) => {
                              if (!Object.prototype.hasOwnProperty.call(self.fihrist, customer.name[0])) {
                                 self.fihrist[customer.name[0]] = customer.name[0];
                                 anchorPoint = customer.name[0];
                              } else {
                                 anchorPoint = false;
                              }
                              customer["anchor"] = anchorPoint;
                              customer["index"] = index;
                              self.customerList.push(customer);
                           });

                           // self.customerList = response.customers;
                        }
                     });
                  });
               });
            }
         });
      },
      handlerFihristFilter(letter) {
         const self = this;
         if (letter == self.fihristFilter) {
            self.fihristFilter = "";
         } else {
            self.fihristFilter = letter;
         }
      },
   },
   watch: {},
};
</script>

<style lang="scss" scoped>
.customer-list-container {
   .customer-body-row-container {
      max-height: 500px;
      overflow: scroll;
      overflow-x: hidden;

      .customer-body-row {
         .customer-col {
            display: flex;
            align-items: center;
            span.customer-fakie-photo {
               display: flex;
               width: 38px;
               height: 38px;
               border-radius: 50%;
               align-items: center;
               justify-content: center;
               font-size: var(--normal);
               font-weight: 400;
               color: #fff;

               &.male {
                  //background-color: #067ff9;
                  border: 1.5px solid #067ff9;
                  color: #067ff9;
               }
               &.female {
                  // background-color: #ec51fb;
                  border: 1.5px solid #ec51fb;
                  color: #ec51fb;
               }
            }

            .action-buttons {
               display: flex;
               button {
                  background-color: transparent !important;

                  &:hover {
                     position: relative;
                     z-index: 999;
                  }
               }
            }
         }
      }
      .customer-body-row-loader {
         display: flex;
         justify-content: center;
         padding: 1rem;
         .g-radial-loader {
            border-color: var(--accent-primary);
         }
      }
   }

   .customer-header-row,
   .customer-body-row {
      border-radius: 24rem;
      padding: 0.5rem 0.5rem;
      display: flex;
      display: grid;
      grid-template-columns: 0.5fr repeat(7, 2fr) 100px;
      grid-column-gap: 1rem;

      .customer-col {
         // border: 1px solid #eee;
         // width: 100%;
         .customer-name {
            padding-right: 0.25rem;
            text-transform: lowercase;
            font-size: var(--normal);
            &::first-letter {
               text-transform: uppercase;
            }
         }

         .customer-birthdate {
            font-size: var(--small);
         }
      }

      &:hover {
         background-color: #f2f2f2;
         cursor: pointer;
      }

      &.added {
         background-color: #ececec;
         // cursor: not-allowed;
      }

      &.in-modal {
         grid-template-columns: 0.5fr repeat(2, 2fr) 100px;
      }
   }

   .customer-header-row {
      border: 1px solid #ccc;
      font-weight: 500;
      background: #fff;
      overflow-y: scroll;
   }
   .customer-body-row {
      border-radius: 0.125rem !important;
      border-bottom: 1px dotted #ddd;
   }

   .sticky-header {
      position: relative;
      background-color: #fff;
      top: 0;
      padding-bottom: 0.05rem;

      .fihrist-user-control {
         margin-top: 1rem;
         display: flex;
         #fihrist-container {
            display: flex;
            flex-direction: row;
            background-color: #fff;
            margin-bottom: 1rem;
            width: 100%;
            justify-content: center;
            flex-wrap: wrap;

            .fihrist-letter {
               text-transform: capitalize;
               cursor: pointer;
               color: var(--primary);
               font-size: var(--large);
               opacity: 0.8;
               margin: 0 0.35rem;
               transform: scale(1);

               transition-property: transform;
               transition-timing-function: ease-in-out;
               transition-duration: 0.1s;

               &:hover {
                  font-weight: 600;
                  opacity: 1;
                  transform: scale(1.5);
               }

               &.active-fihrist {
                  font-weight: 600;
                  opacity: 1;
                  transform: scale(1.25);
                  border-bottom: 2px solid var(--primary);
               }
            }
         }
      }
   }

   .customer-add-new-action-wrapper {
      display: flex;
      justify-content: flex-end;
   }
}
</style>

<style lang="scss">
#customer-list-wrapper .b-tabs {
   .tabs {
      display: none !important;
   }
}
</style>
