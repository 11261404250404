<template>
   <div id="transfer-travellers-wrapper" v-click-outside="handlerOutClick">
      <label>{{ inputLabel }}</label>

      <div class="travellers-inner-wrapper">
         <i class="gts-icon icon-pax-input-transfer icon-sm"></i>
         <input type="text" tabindex="5" @focus="handlerFocus" @keydown="handlerEnter" />
         <div class="travellers-infos" @click="dropdown = !dropdown">
            <span>
               {{ $t("common_adult") }}: <strong>{{ viewDatas.adult }}</strong>
            </span>
            <span v-if="viewDatas.child > 0">
               {{ $t("common_child") }}:<strong>{{ viewDatas.child }}</strong>
            </span>
            <span v-if="viewDatas.infant > 0">
               {{ $t("common_infant") }}:<strong>{{ viewDatas.infant }}</strong>
            </span>
         </div>

         <div class="gts-dropdown" v-if="dropdown">
            <ul class="travellers-dropdown-inner">
               <li class="traveller-line">
                  <div class="traveller-col">
                     <span>{{ $t("common_adult") }}</span>
                     <small>12</small>
                  </div>
                  <div class="numerator-container">
                     <button @click="handlerDecrease('adult')" :disabled="viewDatas.adult <= 1">-</button>
                     <span>{{ viewDatas.adult }}</span>
                     <button @click="handlerIncrease('adult')" :disabled="viewDatas.adult >= 45 || totalTravellers >= 45">+</button>
                  </div>
               </li>

               <li class="traveller-line">
                  <div class="traveller-col">
                     <span>{{ $t("common_child") }}</span>
                     <small>2-12</small>
                  </div>

                  <div class="numerator-container">
                     <button @click="handlerDecrease('child')" :disabled="viewDatas.child <= 0">-</button>
                     <span>{{ viewDatas.child }}</span>
                     <button @click="handlerIncrease('child')" :disabled="viewDatas.adult == 0 || totalTravellers >= 45">+</button>
                  </div>
               </li>

               <li class="traveller-line">
                  <div class="traveller-col">
                     <span>{{ $t("common_infant") }}</span>
                     <small>0-2</small>
                  </div>
                  <div class="numerator-container">
                     <button @click="handlerDecrease('infant')" :disabled="viewDatas.infant <= 0">-</button>
                     <span>{{ viewDatas.infant }}</span>
                     <button @click="handlerIncrease('infant')" :disabled="viewDatas.adult == 0 || totalTravellers >= 45">+</button>
                  </div>
               </li>
            </ul>
            <div class="button-container" @click="handlerClose">
               <UIButton className="default" :text="$t('common_okbtn')" />
            </div>
         </div>
      </div>
   </div>
</template>

<script>
import UIButton from "@/components/unitary/Button.vue";
export default {
   name: "TransferTravellers",
   components: {
      UIButton,
   },
   props: {
      inputLabel: String,
   },
   data() {
      return {
         dropdown: false,
         travellers: {
            adult: 1,
            child: 0,
            infant: 0,
         },
      };
   },
   computed: {
      viewDatas() {
         return this.travellers;
      },
      totalTravellers() {
         return this.travellers.adult + this.travellers.child + this.travellers.infant;
      },
   },
   methods: {
      handlerEmitTravellers() {
         const self = this;

         let travellers = {};

         if (self.travellers.adult > 0) {
            travellers.adult = self.travellers.adult;
         }
         if (self.travellers.child > 0) {
            travellers.child = self.travellers.child;
         }
         if (self.travellers.infant > 0) {
            travellers.infant = self.travellers.infant;
         }

         this.$emit("update-travellers", travellers);
      },
      handlerClose() {
         this.handlerEmitTravellers();
         this.dropdown = false;
      },
      handlerIncrease(type) {
         this.travellers[type]++;
         this.handlerEmitTravellers();
         //this.$emit("update-adult", { type: type, value: true });
      },
      handlerDecrease(type) {
         this.travellers[type]--;
         this.handlerEmitTravellers();
         //this.$emit("update-adult", { type: type, value: false });
      },
      handlerFocus() {
         const self = this;
         self.dropdown = true;
      },
      handlerEnter(key) {
         const self = this;
         if (key.keyCode == 13) {
            self.dropdown = false;
         }
      },
      handlerOutClick() {
         this.dropdown = false;
         this.handlerEmitTravellers();
      },
   },
};
</script>

<style lang="scss" scoped>
#transfer-travellers-wrapper {
   display: flex;
   flex-direction: column;
   height: 100%;
   position: relative;
   label {
      margin-bottom: 0.5rem;
      font-weight: 500;
   }
   .travellers-inner-wrapper {
      display: flex;
      flex-direction: row !important;
      align-items: center;
      justify-content: space-between;
      border: 1px solid #ccc;
      background: #f8f8f8;
      border-radius: 0.5rem;
      height: 100%;
      min-height: 48px;

      input {
         position: absolute;
         left: 0;
         right: 0;
         opacity: 0;
         cursor: pointer;
      }
      .travellers-infos {
         width: 100%;
         @keyframes animSpan {
            from {
               opacity: 0;
               top: 110%;
            }
            to {
               opacity: 1;
               top: 100%;
            }
         }
         span {
            animation-name: animSpan;
            animation-timing-function: ease-in-out;
            animation-duration: 0.15s;
            margin-right: 0.125rem;
            &:after {
               content: ",";
            }

            &:last-child {
               &:after {
                  content: "";
               }
            }
         }
      }
      .gts-dropdown {
         position: absolute;
         left: 0;
         right: 0;
         padding: 0;
         border-radius: 8px;
         background-clip: padding-box;
         background-color: #fff;
         top: 100%;
         box-shadow: rgba(0, 0, 0, 0.2) 0px 10px 30px 0px;
         width: fit-content;
         overflow: hidden;
         z-index: 100;
         top: 80px;
         min-width: 100%;
         min-height: 100px;

         .travellers-dropdown-inner {
            padding: 1rem;
            li.traveller-line {
               display: flex;
               justify-content: space-between;
               padding: 0.5rem 0;

               .traveller-col {
                  display: flex;
                  flex-direction: column;
                  span {
                     font-weight: 600;
                  }
               }
               .numerator-container {
                  display: flex;

                  span {
                     margin: 0 1rem;
                     font-weight: 500;
                     font-size: 1.25rem;
                  }
                  button {
                     outline: 0;
                     border: 0;
                     border-radius: 50%;
                     width: 28px;
                     height: 28px;
                     line-height: 28px;
                     font-size: 1.25rem;
                     background-color: #fff;
                     color: var(--txt-black);
                     display: flex;
                     align-items: center;
                     justify-content: center;
                     box-shadow: rgba(0, 0, 0, 0.18) 0px 2px 4px;
                     transition-property: all;
                     transition-timing-function: ease-in;
                     transition-duration: 0.05s;

                     &:hover {
                        background-color: var(--accent-primary);
                        color: #fff;
                     }

                     &:disabled {
                        background-color: #eaeaea;
                        opacity: 0.5;

                        &:hover {
                           background-color: var(--primary-bg-color);
                           color: var(--txt-black);
                        }
                     }
                  }
               }
            }
         }
         .button-container {
            padding: 0 1rem;
            padding-bottom: 0.5rem;
            display: flex;
            justify-content: flex-end;
         }
      }

      i.gts-icon {
         margin-left: 0.75rem;
         margin-right: 0.75rem;
      }
   }
}
</style>
