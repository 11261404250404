<template>
   <div id="reset-password-container">
      <section id="left-container" class="is-hidden-mobile"></section>
      <section id="right-container">
         <div class="close-terms-of-use" v-if="termsofuse.active" @click="termsofuse.active = false" key="2"></div>
         <Privacy v-if="termsofuse.active && termsofuse.identifier == 2" key="3" />
         <CookiePolicy v-if="termsofuse.active && termsofuse.identifier == 3" key="4" />
         <div id="login-form-wrapper" v-if="!termsofuse.active" key="1">
            <div class="form-wrapper">
               <br />
               <br />

               <div class="greeting-wrapper">
                  <img :src="`${this.$constants.cdn}/logo/${$constants.brand.cssCode}-logo.svg`" alt="" class="is-hidden-tablet" />
                  <span class="greeting-title"> {{ $t("resetPassword_passwordResetTitle") }}</span>
                  <span class="greeting-subtitle"> {{ $t("resetPassword_passwordResetMessage") }}</span>
               </div>

               <!-- INPUT PASSWORD -->
               <div class="form-row">
                  <label for="password">{{ $t("resetPassword_userPassword") }}</label>
                  <div class="input-container">
                     <input :type="showpassword ? 'text' : 'password'" id="input_loginUserPassword" v-model="password" />
                     <i
                        id="toggle-password"
                        class="gts-icon"
                        :class="showpassword ? 'icon-eye-closed' : 'icon-eye'"
                        @click="showpassword = !showpassword"></i>
                  </div>
               </div>
               <!-- INPUT CONFIRM PASSWORD -->
               <div class="form-row" v-if="isPasswordValid">
                  <label for="password">{{ $t("resetPassword_userPasswordConfirm") }}</label>
                  <div class="input-container">
                     <input :type="showpassword ? 'text' : 'password'" id="input_loginUserPassword" v-model="passwordConfirm" />
                     <i
                        id="toggle-password"
                        class="gts-icon"
                        :class="showpassword ? 'icon-eye-closed' : 'icon-eye'"
                        @click="showpassword = !showpassword"></i>
                  </div>
               </div>

               <Transition name="slide" mode="out-in">
                  <div v-if="passwordValidation.errors.length > 0" class="hints-container">
                     <div v-for="error in rules" :key="error.name" class="error-message-container">
                        <i class="gts-icon icon-sm" :class="error.isValid ? 'icon-done' : 'icon-undone'"></i>
                        <span class="error-message" :class="error.isValid ? 'valid' : 'invalid'">{{ handlerLang(error.message) }}</span>
                     </div>
                  </div>
               </Transition>

               <div class="form-row">
                  <button
                     id="login"
                     className="default"
                     :disabled="!isPasswordValid"
                     :class="{ isPasswordValid: !isPasswordValid }"
                     ref="loginbtn"
                     @click="handlerResetPassword">
                     {{ $t("resetPassword_resetPassword") }}
                  </button>
               </div>
            </div>
            <!-- KVKK -->
            <section class="kvkk">
               <p>
                  {{ $t("SignIn_legalText") }}
                  <!-- <a>{{ $t("SignIn_legalTextTermsOfService") }} </a> {{ $t("SignIn_legalText2") }} TERMS OF SERVİCE'İN KALDIRILMASINA KARAR VERİLDİ -->
                  <a @click="handlerTermsOfUse(2)">{{ $t("SignIn_legalTextPrivacyPolicy") }} </a>{{ $t("SignIn_legalText3") }}
                  <a @click="handlerTermsOfUse(3)">{{ $t("SignIn_legalTextCookieUse") }}</a>
               </p>
            </section>
         </div>
         <!-- Language & Copyright Informations -->
         <div id="footer-menu">
            <div class="language-container">
               <span
                  class="lang"
                  :class="lang == selectedLang ? 'active' : ''"
                  v-for="(lang, index) in languages"
                  :key="index"
                  :data-lang="lang"
                  @click="setLanguage($event)"
                  >{{ $t(`languageFullName_${lang}`) }}
               </span>
            </div>

            <div class="div">
               <span class="copyright-text"> &copy; Reisetech {{ copyright }}</span>

               <span class="ml-3">v.{{ appVersion }}</span>
            </div>
         </div>
      </section>
   </div>
</template>

<script>
const min = 8;
import Popup from "@/gts-popup.js";
import router from "@/router/index.js";
import { userService } from "@/service/index.js";
import moment from "moment";
import i18n from "@/fmt/i18n.js";
import Privacy from "@/pages/user/PrivacyPolicy.vue";
import CookiePolicy from "@/pages/user/Cookie.vue";
export default {
   name: "ResetPassword",
   components: {
      Privacy,
      CookiePolicy,
   },
   data() {
      return {
         showpassword: false,
         password: null,
         passwordConfirm: null,
         selectedLang: null,
         languages: Object.keys(i18n.messages),
         copyright: moment().format("YYYY"),
         appVersion: process.env.PACKAGE_VERSION,
         termsofuse: {
            active: false,
            identifier: null,
         },
         rules: [
            {
               name: "upperCase",
               message: "resetPassword_upperCase",
               isValid: false,
               regex: /[A-Z]+/,
            },
            {
               name: "lowerCase",
               message: "resetPassword_lowerCase",
               isValid: false,
               regex: /[a-z]+/,
            },
            {
               name: "specialChar",
               message: "resetPassword_specialChar",
               isValid: false,
               regex: /[^A-Za-z0-9]/,
            },
            {
               name: "numberRequired",
               message: "resetPassword_numberRequired",
               isValid: false,
               regex: /[0-9]+/,
            },
            {
               name: "minChar",
               message: "resetPassword_minChar",
               isValid: false,
               regex: eval("/.{" + min + ",}/"),
            },
         ],
         checkPassword: "",
         isPasswordValid: false,
      };
   },

   methods: {
      handlerLang(text) {
         if (text == "resetPassword_minChar") {
            return this.$t("resetPassword_minChar").replace("#VALUE#", min);
         } else {
            return this.$t(text);
         }
      },
      handlerValidPassword(val) {
         this.isPasswordValid = val;
      },
      handlerResetPassword() {
         if (this.isPasswordValid) {
            if (this.password === this.passwordConfirm) {
               let query = {
                  token: this.$route.query.token,
                  newPassword: this.password,
               };
               userService.changePassword(query).then((res) => {
                  if (res.result.success) {
                     Popup.success(this.$i18n.t("popup_successTitle"), this.$i18n.t("resetPassword_passwordResetSuccess")).confirmed((res) => {
                        if (res.success) {
                           this.$store.commit("app/setIsProgressGifActive", true);
                           setTimeout(() => {
                              this.$store.commit("app/setIsProgressGifActive", false);
                              router.push(`/login`);
                           }, 1000);
                        }
                     });
                  } else {
                     Popup.warning(this.$i18n.t("popup_warningTitle"), this.$i18n.t("resetPassword_passwordResetFailed"));
                  }
               });
            } else {
               Popup.info(this.$i18n.t("popup_informationTitle"), this.$i18n.t("resetPassword_passwordsAreDifferent"));
            }
         }
      },
      setLanguage(element) {
         const self = this;
         const elementLang = element.target.getAttribute("data-lang");
         this.$store.state.language = elementLang;
         this.$i18n.locale = elementLang;
         self.selectedLang = this.$store.state.language;
      },
      handlerTermsOfUse(index) {
         const self = this;
         self.termsofuse.active = true;
         self.termsofuse.identifier = index;
      },
   },
   computed: {
      passwordValidation() {
         let errors = [];
         for (let condition of this.rules) {
            if (!this.password || this.password == "") {
               condition.isValid = false;
               errors.push(condition.message);
            } else {
               if (!condition.regex.test(this.password)) {
                  condition.isValid = false;
                  errors.push(condition.message);
               } else {
                  condition.isValid = true;
               }
            }
         }
         if (errors.length === 0) {
            this.handlerValidPassword(true);
            return { valid: true, errors };
         } else {
            this.handlerValidPassword(false);
            return { valid: false, errors };
         }
      },
   },
};
</script>

<style lang="scss" scoped>
#reset-password-container {
   width: 100%;
   height: 100%;
   background-color: #fff;
   display: grid;
   grid-template-columns: 1fr;

   @media (min-width: 576px) {
      grid-template-columns: repeat(2, 1fr);
   }

   #brand-logo {
      margin-bottom: 2rem;
      width: 110px;
      position: absolute;
      right: 2.5rem;
   }

   section#left-container,
   section#right-container {
      position: relative;
   }

   section#left-container {
      background-color: #2e67b8;
      background-size: cover;
      background-repeat: no-repeat;
      background-position-x: -300px;
      background-image: url("~@/assets/images/slide/01.jpg");
   }

   section#right-container {
      padding: 3rem 1.5rem;
      display: flex;
      align-items: center;

      @media (min-width: 576px) {
         padding: 5rem 3rem;
      }

      @media (min-width: 576px) {
         padding: 5rem 3rem;
      }
      #login-form-wrapper {
         // border: 1px solid #ccc;
         padding: 0rem;
         width: 100%;
         @media (min-width: 576px) {
            padding: 2rem;
         }
         .greeting-wrapper {
            display: flex;
            flex-direction: column;
            margin-bottom: 3rem;
            img {
               width: 280px;
               margin-bottom: 2rem;
            }

            span {
               &.greeting-title {
                  font-size: 3rem;
                  font-weight: 600;
               }
            }
         }
         .form-wrapper {
            // border: 1px solid #eee;
            width: 100%;
            @media (min-width: 576px) {
               width: 60%;
            }
            .hints-container {
               display: grid;
               grid-template-columns: 1fr 1fr;
               .error-message-container {
                  display: flex;
                  padding-top: 8px;
                  .error-message {
                     display: flex;
                     padding-left: 8px;
                     align-items: center;
                     font-weight: 500 !important;
                     &.valid {
                        color: #00d341 !important;
                     }
                     &.invalid {
                        color: #95a6ba !important;
                     }
                  }
               }
            }
            .form-row {
               display: flex;
               flex-direction: column;
               margin-bottom: 1.5rem;
               .isPasswordValid {
                  background-color: #eff0f2 !important;
                  color: #c7c7c8 !important;
                  font-weight: 600 !important;
               }
               label {
                  font-size: 1rem;
                  margin-bottom: 0.5rem;
                  font-weight: 400;
                  color: #363636;
               }

               .input-container {
                  position: relative;
                  input {
                     outline: 0;
                     border: 0;
                     height: 50px;
                     text-indent: 0.75rem;
                     border: 1px solid #ccc;
                     background: #fff;
                     border-radius: 0.75rem;
                     width: 100%;
                     font-size: 1.125rem;

                     &:focus {
                        border: 1.5px solid var(--accent-primary);
                        background-color: #fff;
                     }
                     &[type="password"] {
                        font: large Verdana, sans-serif;
                        letter-spacing: 2px;
                        -webkit-text-security: disc;
                        font-size: 1.75rem;
                     }
                     &.input-success {
                        border: 1.5px solid hsl(150, 75%, 65%);
                        background-color: #f4fdf8;
                        color: #43d18a;
                     }
                     &.input-error {
                        border: 1.5px solid hsl(350, 85%, 65%);
                        background-color: #fff7f4;
                        color: #ec4921;
                     }
                  }

                  #toggle-password {
                     position: absolute;
                     top: 0;
                     bottom: 0;
                     right: 1rem;
                     margin: auto;
                     cursor: pointer;
                     opacity: 0.8;
                  }
                  i {
                     position: absolute;
                     top: 0;
                     bottom: 0;
                     margin: auto;
                     right: 1rem;
                  }
               }
               .reset-password {
                  text-align: right;
                  font-weight: 500;
                  color: var(--accent-primary);
                  font-size: 1rem;
                  cursor: pointer;
               }

               #login {
                  background-color: var(--primary);
                  border-radius: 0.75rem;
                  color: #fff;
                  font-size: 1.125rem;
                  font-weight: 700;
                  height: 50px;
                  margin-top: 2rem;

                  &:hover {
                     background-color: var(--accent-primary);
                  }
               }

               .authentication-inner {
                  display: flex;
                  align-content: center;
                  justify-content: flex-start;
                  & > .authentication-inner-item {
                     position: relative;
                     &:last-child {
                        display: inline-flex;
                        align-items: center;
                     }
                     & > i.gts-icons {
                        width: 40px;
                        height: 40px;
                        display: block;
                        margin-top: 24px;
                        margin-left: 20px;
                        background-repeat: no-repeat;
                        background-size: contain;
                     }

                     & > .token-tooltip-wrapper {
                        position: absolute;
                        top: -12px;
                        left: 75px;
                        width: 280px;
                        max-width: 280px;
                        background-color: white;
                        border: 1px solid #ccc;
                        border-radius: 12px;
                        padding: 20px 16px;

                        & > .token-tooltip-trigger {
                           position: absolute;
                           top: -12px;
                           right: -12px;
                           display: inline-flex;
                           align-items: center;
                           justify-content: center;
                           width: 30px;
                           height: 30px;
                           background-color: white;
                           border: 1px solid #ccc;
                           border-radius: 50%;
                        }

                        & > .token-tooltip-inner {
                           & > .token-tooltip-row {
                              &.token-tooltip-content {
                                 margin: 16px 0;
                                 font-size: 14px;
                              }

                              &.token-tooltip-cta-group {
                                 display: flex;
                                 flex-direction: row;
                                 justify-content: space-between;
                                 align-items: flex-start;
                              }

                              .token-tooltip-cta-button {
                                 border: 1px solid #ccc;
                                 padding: 0.25rem 0.4rem;
                                 border-radius: 12px;
                                 display: inline-flex;
                                 align-items: center;
                                 font-size: 12px;
                                 font-weight: 500;
                                 color: black;

                                 &:hover {
                                    transform: scale(1.1);
                                    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
                                 }

                                 & > svg {
                                    width: 20px;
                                    margin-right: 8px;
                                 }
                              }
                           }
                        }
                     }
                  }
               }
            }
         }
         .kvkk {
            border-top: 1px solid #eee;
            padding-top: 1.75rem;
            margin-top: 1rem;
            font-size: var(--normal);
            text-align: left;
            position: relative;
            min-height: 65px;
            width: 100%;
            line-height: 1.5rem;

            a {
               font-weight: 500;
               color: var(--accent-primary);
            }

            &::before {
               position: absolute;
               content: "";
               width: 10px;
               height: 10px;
               border: 1px solid #eee;
               top: -5px;
               border-radius: 50%;
               left: 0;
               right: 0;
               margin: auto;
               background-color: #fff;
            }
         }
      }
      #footer-menu {
         position: absolute;
         bottom: 0;
         left: 0;
         right: 0;
         display: flex;
         justify-content: space-between;
         padding: 1rem;
         background: #fbfbfb;
         border-top: 1px solid #eee;
         width: 100%;

         .language-container {
            span {
               margin: 0 0.5rem;
               font-weight: 400;
               cursor: pointer;

               &.active {
                  font-weight: 600;
               }
            }
         }
      }

      ::v-deep {
         .terms-of-use-container {
            position: relative;
            padding: 0 3rem;
            .terms-of-use-title {
               position: absolute;
               top: 1rem;
               font-size: 2rem;
               font-weight: 600;
               z-index: 99;
               height: 100px;
               background: linear-gradient(180deg, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 0) 100%);
               right: 3rem;
               left: 3rem;
            }
            .terms-of-use {
               height: 70vh;
               overflow: hidden;
               position: relative;

               div.inner-overflow {
                  padding-right: 1rem;
                  overflow-y: scroll;
                  height: 100%;
               }
               & b {
                  font-weight: 600;
               }
               & p {
                  margin: 0.25rem 0;
               }

               & ol {
                  margin: 1rem 0;
                  padding-left: 2rem;
                  li {
                     list-style-type: decimal;
                  }
               }

               & a {
                  color: var(--primary);
                  text-decoration: underline;
               }

               &::before,
               &::after {
                  content: "";
                  position: absolute;
                  left: 0;
                  right: 0;
                  height: 80px;
                  background: rgb(255, 255, 255);

                  // background: red !important;
                  // opacity: 0.5;
               }
               &:before {
                  top: 0;
                  background: linear-gradient(180deg, rgba(255, 255, 255, 1) 15%, rgba(255, 255, 255, 0) 100%);
               }
               &::after {
                  bottom: 0;
                  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 15%, rgba(255, 255, 255, 1) 100%);
               }
            }
         }
      }

      .close-terms-of-use {
         position: absolute;
         top: 2.5rem;
         right: 3.5rem;
         cursor: pointer;
         &::before,
         &::after {
            content: "";
            width: 3.5px;
            height: 38px;
            display: block;
            border-radius: 0.5rem;
            background-color: #363636;
            position: absolute;
         }

         &::before {
            transform: rotate(45deg);
         }
         &::after {
            transform: rotate(-45deg);
         }
      }
   }
}
</style>
