<template>
   <div :class="`gts-alert gts-alert_${status} ${rounded ? '' : 'no-round'}`">
      <slot />
   </div>
</template>

<script>
export default {
   name: "UIAlert",
   props: {
      status: { type: String },
      rounded: Boolean,
   },
};
</script>

<style lang="scss" scoped>
.gts-alert {
   display: flex;
   align-items: center;
   justify-content: flex-start;
   background-color: transparent;
   padding: 12px 16px;
   border: 1px solid;
   border-radius: 8px;
   margin-bottom: 1rem;

   p {
      padding: 0;
      margin: 0;
   }

   &_default {
      background-color: #cacbcc;
      border-color: #cacbcc;
      color: black;
   }
   &_default__light {
      background-color: var(--c-primary);
      box-shadow: 0px 2px 6px #0000001a;
      border: none;
      border-radius: 12px;
      color: var(--txt-black);
   }
   &_success {
      background-color: green;
      border-color: green;
      color: white;
   }
   &_info {
      background-color: #eaf6fd;
      border-color: #40bcff;
      color: #40bcff;
   }
   &_alert {
      background-color: #fdf6d3;
      border-color: #e6c72e;
      color: black;
   }
   &_warning {
      background-color: #f3edf5;
      border: 1px solid;
      border-color: #78677d;
   }
   &_orange {
      background-color: #f3edf5;
      border: 1px solid;
      border-color: #78677d;
   }
   &_info_green {
      background-color: #e9fcf2;
      border: 1px solid #7dd7aa !important;
      color: var(--txt-black) !important;
   }
   &_info_blue {
      background-color: #e0f5ff;
      border: 1px solid #9acae2 !important;
      color: var(--txt-black) !important;
   }
   &_info_yellow {
      background-color: #fffde0;
      border: 1px solid #dfd974 !important;
      color: var(--txt-black) !important;
   }
   &_info_red {
      background-color: #fde3e3;
      border: 1px solid #f8aaa7 !important;
      color: var(--txt-black) !important;
   }
   &_info_red1 {
      background-color: #ff6b6b;
      border: 1px solid #ea443d !important;
      color: #ffffff !important;
   }
   &_info_orange {
      background-color: #ff913307;
      border: 1px solid #ff9133 !important;
      color: var(--txt-black) !important;
   }
   &_info_orange_2 {
      background-color: #ff913307;
      border: 1px solid #ff9133 !important;
      color: var(--secondary) !important;
   }
}
</style>
