<template>
   <div class="flight-card-details-container">
      <span class="flight-card-details-title"> {{ details.route }}</span>

      <div class="fcd-segments-wrapper">
         <div class="fcd-segment" v-for="(segment, index) in details.segments" :key="index">
            <!-- AIRLINE INFORMATIONS -->
            <div class="segment-informations">
               <div class="airline-information-wrapper">
                  <img class="airline-logo" :src="handlerAirlineImg(segment.marketingAirlineCode)" alt="" @error="handlerUnknownImg($event)" />

                  <div class="airline-informations">
                     <div class="marketing-airline">
                        <span class="airline">{{ $t(`airlineEnum_${segment.marketingAirlineCode}`) }}</span>
                        <span class="flight-number">{{ segment.flightNumber }}</span>
                     </div>

                     <div class="operating-airline">
                        <span class="operated-by"> {{ $t("flightcardcomponent_operatedBy") }}: </span>
                        <span class="airline">{{ $t(`airlineEnum_${segment.operatingAirlineCode}`) }}</span>
                     </div>
                  </div>
               </div>

               <div class="baggage-information">
                  <i class="gts-icon icon-sm icon-baggage"></i>
                  <span class="baggage-info">{{ segment.baggageInfo }}</span>
               </div>
            </div>

            <!-- SEGMENT ROUTE -->
            <div class="segment-routes">
               <div class="from">
                  <span class="airport">{{ handlerAirportCode(segment.departureAirportCode) }}</span>
                  <span class="time">{{ segment.departureDateTime | FormatterTime }}</span>
                  <span class="fulldate">{{ segment.departureDateTime | FormatterDate }}</span>
                  <span class="city">Antalya</span>
               </div>
               <div class="seperator">
                  <span class="travel-time"> {{ segment.travelTime | duration }}</span>
               </div>
               <div class="to">
                  <!-- <span class="airport">{{ details.airports[segment.arrivalAirportCode].name }}</span> -->
                  <span class="airport">{{ handlerAirportCode(segment.arrivalAirportCode) }}</span>
                  <span class="time">{{ segment.arrivalDateTime | FormatterTime }}</span>
                  <span class="fulldate">{{ segment.arrivalDateTime | FormatterDate }}</span>
                  <span class="city">İstanbul</span>
               </div>
            </div>

            <!-- EXTRA INFORMATIONS -->
            <div class="extra-informations">
               <div class="extra-information">
                  <span class="info-title">Cabin Type</span>
                  <span class="info-content"> {{ segment.cabinType | EFltCabinType }}</span>
               </div>
               <div class="extra-information">
                  <span class="info-title">Brand Name</span>
                  <span class="info-content"> {{ segment.brandName }}</span>
               </div>
               <div class="extra-information">
                  <span class="info-title">Aircraft</span>
                  <span class="info-content"> {{ segment.aircraft }}</span>
               </div>
               <div class="extra-information">
                  <span class="info-title">Left Seat</span>
                  <span class="info-content"> {{ segment.leftSeat }}</span>
               </div>
               <div class="extra-information">
                  <span class="info-title">Brand Code</span>
                  <span class="info-content"> {{ segment.brandCode }}</span>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>
export default {
   name: "FlightCardDetails",
   props: {
      details: {
         type: Object,
         required: true,
      },
   },
   methods: {
      handlerAirlineImg(airlineLogo) {
         return `${this.$constants.cdn}/airline_34x34/${airlineLogo}.svg`;
      },
      handlerUnknownImg(ev) {
         ev.target.src = `${this.$constants.cdn}/airline_34x34/00.svg`;
      },
      handlerAirportCode(airportCode) {
         return this.details.airports[airportCode]?.name;
      },
   },
};
</script>

<style lang="scss" scoped>
.flight-card-details-container {
   padding: 0.75rem 0.75rem;
   border-top: 1px solid #ccc;
   cursor: default;

   @media (min-width: 576px) {
      padding: 0.75rem 1.5rem;
   }

   .flight-card-details-title {
      font-size: var(--large);
      font-weight: 500;
      display: none;
   }

   .fcd-segments-wrapper {
      .fcd-segment {
         background: #f2f2f2;
         border: 1px solid #ccc;
         margin: 0.75rem 0;
         padding: 1rem 0.5rem;
         border-radius: 1rem;

         &:first-child {
            margin-top: 0 !important;
         }

         .segment-informations {
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-bottom: 1px dashed #ccc;
            padding: 0.5rem 1rem;
            margin-bottom: 0.5rem;

            .airline-information-wrapper {
               display: flex;
               align-items: center;
               img {
                  $size: 36px;
                  width: $size;
                  height: $size;
                  margin-right: 0.75rem;
               }
               .airline-informations {
                  .marketing-airline,
                  .operating-airline {
                     span {
                        font-size: var(--small);
                     }
                     span.airline,
                     span.flight-number {
                        font-weight: 500;
                     }
                     span.flight-number {
                        margin-left: 0.5rem;
                     }
                  }
                  .operating-airline {
                     color: #626262;
                     font-size: var(--xsmall) !important;
                  }
               }
            }
            .baggage-information {
               display: flex;
               text-align: right;

               i {
                  filter: brightness(0);
               }

               span.baggage-info {
                  font-weight: 500;
               }
            }
         }
         .segment-routes {
            display: flex;
            .seperator {
               display: flex;
               align-items: center;
               justify-content: center;
               width: 100%;
               position: relative;
               margin: 0 1rem;

               &:before {
                  content: "";
                  position: absolute;
                  left: 0;
                  right: 0;
                  top: 0;
                  bottom: 0;
                  height: 1px;
                  margin: auto;
                  border-bottom: 1px solid var(--gray);
               }

               span.travel-time {
                  background: #f2f2f2;
                  padding: 0 0.5rem;
                  position: relative;
                  z-index: 1;
                  font-weight: 500;
               }
            }
            .from,
            .to {
               display: flex;
               flex-direction: column;
               width: fit-content;
               padding: 0 0.5rem;
               span.airport {
                  font-weight: 400;
                  white-space: nowrap;
               }
               span.time {
                  font-weight: 600;
               }
               span.fulldate {
                  font-size: var(--small);
                  color: var(--txt-ghost);
               }
               span.city {
                  display: none;
                  font-size: var(--small);
                  color: var(--txt-ghost);
               }
            }

            .to {
               span {
                  text-align: right;
               }
            }
         }

         .extra-informations {
            display: flex;
            justify-content: center;
            margin-top: 1rem;
            width: 100%;
            flex-wrap: wrap;
            div.extra-information {
               background-color: #fff;
               border: 1px solid #ccc;
               padding: 0.125rem 0.5rem;
               margin: 0 0.25rem;

               border-radius: 0.5rem;
               @media (min-width: 576px) {
                  display: flex;
                  flex-direction: column;
                  background-color: #fff;
                  border: 1px solid #ccc;
                  padding: 0.125rem 1rem;
                  border-radius: 0.5rem;
                  margin: 0 0.5rem;
                  margin-bottom: 0.5rem;
               }

               span.info-title {
                  font-size: var(--xsmall);
                  font-weight: 500;
                  color: var(--txt-ghost);
                  display: none;
               }
               span.info-content {
                  font-size: var(--small);
                  font-weight: 500;
               }
            }
         }
      }

      .for-dev {
         margin-top: 1rem;
         background: darkgray;
      }
   }
}
</style>
