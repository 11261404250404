<template>
   <div id="rent-a-car-basket-container">
      <!-- Section Basket Header -->

      <!-- {{ Basket.pickUpDropOff.pickUpLocationName }} -->

      <section class="rent-a-car-basket-header-wrapper">
         <div class="car-informations-wrapper">
            <span class="car-name">{{ Basket.vehicle.markModel }}</span>
            <span class="or-similar">{{ $t("rentacar_orSmiliar") }}</span>
            <!-- <span class="car-features">Petrol / Automatic</span> -->
            <!-- <span class="car-features">{{ Basket.vehicleUuid }}</span> -->
            <span class="car-features">
               {{ $options.filters.ERacGearType(Basket.vehicle.gearType) }} / {{ $options.filters.ERacFuelType(Basket.vehicle.fuelType) }}
            </span>
         </div>
         <div class="clear-basket" @click="handlerClearBasket">
            <i class="gts-icon icon-hotel-empty-basket icon-sm"></i>
         </div>
      </section>

      <section class="rent-a-car-basket-img-wrapper d-md-none">
         <img :src="Basket.vehicle.imageUrl" alt="" srcset="" />
      </section>

      <!-- Reservation Dates -->
      <section class="rent-a-car-basket-informations-wrapper">
         <span class="section-title">{{ $t("rentacar_ReservationDate") }}</span>

         <!-- PickUpLocation informations -->
         <div class="location-wrapper">
            <div class="direction-icon">
               <i class="gts-icon icon-arrow-full icon-sm"></i>
            </div>
            <div class="direction-information-wrapper">
               <span class="location-name">{{ Basket.pickUpDropOff.pickUpLocationName }}</span>
               <span class="location-date">
                  {{ Basket.pickUpDropOff.pickUpDateTime | fullDateTextFormat }} - {{ Basket.pickUpDropOff.pickUpDateTime | TimeFormat }}
               </span>
            </div>
         </div>

         <!-- DropOffLocation informations -->
         <div class="location-wrapper">
            <div class="direction-icon dropOff">
               <i class="gts-icon icon-arrow-full icon-sm"></i>
            </div>
            <div class="direction-information-wrapper">
               <span class="location-name">{{ Basket.pickUpDropOff.dropOffLocationName }}</span>
               <span class="location-date">
                  {{ Basket.pickUpDropOff.dropOffDateTime | fullDateTextFormat }} - {{ Basket.pickUpDropOff.dropOffDateTime | TimeFormat }}
               </span>
            </div>
         </div>

         <div class="total-price-wrapper d-none d-md-block">
            <span>{{ Basket.vehicleTotal | price(currency) }}</span>
         </div>
      </section>

      <!-- Ancillaries -->
      <!-- {{ HasAncillary }} -->
      <section class="rent-a-car-basket-ancillaries-wrapper" v-if="HasAncillary">
         <span class="section-title">{{ $t("rentacar_Ancillaries") }}</span>

         <div class="ancillaries-wrapper">
            <div class="ancillary" v-for="(ancillary, index) in Basket.ancillaries" :key="index">
               <span class="ancillary-name">{{ ancillary.ancillaryType | ERacAncillaryType }}</span>
               <span class="ancillary-price">{{ ancillary.totalPrice | price(currency) }}</span>
            </div>
            <!-- {{ Basket.ancillaries }} -->
         </div>

         <!-- <div class="total-price-wrapper">
            <span>{{ Basket.vehicleTotal | price }}</span>
         </div> -->
      </section>

      <div class="action-button-wrapper d-none d-md-block">
         <button @click="handlerCheckout">
            <div class="loader-arrow-wrapper">
               <i class="gts-icon icon-arrow-full icon-sm"></i>
            </div>

            <p class="total-container">
               <span class="total-text">{{ $t("basketComponentFlight_total") }}</span>
               <span class="total-price"> {{ Basket.grandTotal | price(currency) }}</span>
               <!-- <span class="total-price"> {{ (Basket.grandTotal + Basket.vehicleTotal + Basket.ancillaryTotal) | price }}</span> -->
            </p>
         </button>

         <div class="send-offer" v-if="false">
            {{ $t("basketComponentFlight_sendOffer") }}
         </div>
      </div>

      <div class="action-button-mobile-wrapper d-md-none">
         <p class="total-container">
            <span class="total-text">{{ $t("basketComponentFlight_total") }} : </span>
            <span class="total-price"> {{ Basket.grandTotal | price(currency) }}</span>
            <!-- <span class="total-price"> {{ (Basket.grandTotal + Basket.vehicleTotal + Basket.ancillaryTotal) | price }}</span> -->
         </p>
      </div>

      <!-- CTA Btns -->
      <div class="rentacarbasket-cta-mobile d-flex flex-row justify-content-end mt-5">
         <div class="rentacarbasket-cta-mobil-close d-lg-none me-3">
            <UIButton className="default_outline" :text="$t('basketComponentFlight_closeBasket')" @click.native="handlerCloseBasket" />
         </div>
         <div class="rentacarbasket-cta-mobil-checkout d-lg-none me-3">
            <UIButton className="default" :text="$t('basketComponentFlight_goToCheckout')" @click.native="handlerCheckout" />
         </div>
      </div>

      <!-- <button @click="handlerCheckout">Checkoooo</button> -->
   </div>
</template>

<script>
import UIButton from "@/components/unitary/Button.vue";
import { rentacarService } from "@/service/index.js";
import { generateUuid } from "@/utils.js";
export default {
   name: "RentACarBasket",
   props: {
      basket: Object,
      currency: String,
   },
   components: {
      UIButton,
   },
   data() {
      return {};
   },
   computed: {
      Basket() {
         return this.basket;
      },
      HasAncillary() {
         return Object.keys(this.basket.ancillaries).length > 0 ? true : false;
      },
   },
   methods: {
      handlerCloseBasket() {
         this.$parent.$parent.isBasketActive = false;
      },
      handlerCheckout() {
         const query = {
            searchUuid: this.$parent.$parent.selection.searchUuid,
            uuid: generateUuid(),
         };

         window.open(`/rentacar/checkout/${query.searchUuid}`);
      },
      handlerClearBasket() {
         this.$store.commit("app/setIsProgressGifActive", true);
         const query = {
            searchUuid: this.basket.searchUuid,
            vehicleUuid: null,
            ancillaries: null,
         };
         rentacarService.rentacarSelect(query).then((response) => {
            if (response.result.success) {
               this.$parent.$parent.selection = null;
               this.$store.commit("app/setIsProgressGifActive", false);
            }
         });
      },
   },
};
</script>

<style lang="scss" scoped>
#rent-a-car-basket-container {
   border-radius: 1rem;
   background-color: var(--primary);
   min-height: 300px;
   padding: 2rem 1rem;
   position: sticky;
   top: 1.5rem;
   section {
      span.section-title {
         color: var(--accent-secondary);
         font-weight: 500;
         font-size: var(--large);
      }
      &.rent-a-car-basket-header-wrapper {
         display: flex;
         justify-content: space-between;
         border-bottom: 1px dashed #fff;
         padding-bottom: 1rem;

         .clear-basket {
            display: flex;

            height: fit-content;
            padding: 0.25rem;
            cursor: pointer;
         }
         .car-informations-wrapper {
            display: flex;
            flex-direction: column;

            span {
               color: #fff;
               &.car-name {
                  font-size: var(--large);
                  font-weight: 600;
               }
               &.car-feature {
                  font-size: var(--small);
               }
               &.or-similar {
                  font-weight: 600;
                  font-size: var(--small);
                  text-decoration: underline;
               }
            }
         }
      }

      &.rent-a-car-basket-informations-wrapper {
         margin-top: 1rem;

         .location-wrapper {
            display: flex;
            margin-bottom: 1rem;
            .direction-icon {
               width: 32px;
               height: 40px;
               flex-shrink: 0;
               background-color: #2b4a80;
               border-radius: 0.25rem;
               display: flex;
               align-items: center;
               justify-content: center;

               i {
                  filter: brightness(0) saturate(100%) invert(99%) sepia(65%) saturate(7240%) hue-rotate(90deg) brightness(105%) contrast(101%);
               }

               &.dropOff {
                  i {
                     transform: rotate(180deg);
                  }
               }
            }
            .direction-information-wrapper {
               display: flex;
               flex-direction: column;
               padding-left: 0.5rem;
               span {
                  color: #fff;
                  font-size: var(--small);
               }
            }
         }
         .total-price-wrapper {
            display: flex;
            justify-content: flex-end;
            span {
               color: var(--accent-secondary);
               font-weight: 600;
               font-size: var(--xlarge);
            }
         }
      }

      &.rent-a-car-basket-ancillaries-wrapper {
         margin-top: 1rem;
         .ancillaries-wrapper {
            .ancillary {
               display: flex;
               justify-content: space-between;
               align-items: center;
               margin: 0.35rem 0;
               padding-bottom: 0.25rem;
               border-bottom: 1px dotted #ffffff40;
               span {
                  &.ancillary-name {
                     color: #fff;
                  }
                  &.ancillary-price {
                     color: var(--accent-secondary);
                     font-size: var(--large);
                     font-weight: 600;
                  }
               }
            }
         }
      }
   }

   .action-button-wrapper {
      width: 100%;
      margin: 1rem 0;

      button {
         padding: 0.5rem 1.5rem;
         border-radius: 12rem;
         width: 100%;
         margin: 1rem 0;
         box-shadow: rgba(99, 99, 99, 0.5) 0px 2px 8px 0px;
         display: flex;
         align-items: center;
         justify-content: center;
         border: 2px solid #1b4c92;
         transition: all 0.2s ease-in-out;
         background-color: #fff;

         i {
            position: relative;
            // left: -0.5rem;
            margin-right: 0.5rem;
            transition: all 0.2s ease-in-out;
            filter: invert(19%) sepia(28%) saturate(5280%) hue-rotate(204deg) brightness(70%) contrast(85%);
            flex-shrink: 0;
            transform: scale(1);
            display: none;
            @media (min-width: 1700px) {
               display: block !important;
            }
         }

         p.total-container {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            position: relative;
            top: 0;
            transition-property: top;
            transition-timing-function: ease-in-out;
            transition-duration: 0.2s;
            .total-text {
               color: #1b4c92;
               font-size: var(--xsmall);
               font-weight: 600;
               transition: opacity, 0.2s, ease-in-out;
               opacity: 1;
            }
            .total-price {
               font-size: 1.25rem;
               font-weight: 800;
               color: #1b4c92;
            }
         }

         .loader-arrow-wrapper {
            margin-right: 0.5rem;
            width: 100%;
            display: flex;
            justify-content: center;
            // background-color: gray;
            .g-radial-loader {
               border-color: var(--primary);
               border-width: 3px;
               width: 28px !important;
               height: 28px !important;
            }
         }

         &.disabled-action {
         }
      }
      .send-offer {
         color: #fff;
         text-align: center;
         width: 100%;
         display: block;
         text-decoration: underline;
         font-size: var(--small);
         cursor: pointer;

         &:hover {
            color: var(--accent-secondary);
         }
      }

      &:hover {
         button {
            background-color: #184990;
            border: 2px solid #184990;

            i {
               left: -0.1rem;
               filter: invert(100%) sepia(100%) saturate(10) hue-rotate(160deg) brightness(100%) contrast(200%);
            }

            &.disabled-action {
               cursor: not-allowed;
               background-color: #fff;
               p.total-container {
                  top: 0px !important;
                  .total-text {
                     color: #1b4c92;
                     font-size: var(--xsmall);
                     font-weight: 600;

                     opacity: 2;
                  }
                  .total-price {
                     color: #1b4c92;
                  }
               }
            }
         }
         p.total-container {
            top: -10px;
            .total-text {
               color: #1b4c92;
               font-size: var(--xsmall);
               font-weight: 600;

               opacity: 0;
            }
            .total-price {
               color: var(--accent-secondary);
            }
         }
      }
   }
}
</style>

<style lang="scss" scoped>
@media (max-width: 991.98px) {
   #rent-a-car-basket-container {
      position: fixed;
      z-index: 101;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      height: 100%;
      border-radius: 0;
      background-color: #fff;

      section {
         span.section-title {
            color: #363636;
            font-size: var(--xlarge);
            font-weight: 500;
         }
      }

      .rent-a-car-basket-header-wrapper {
         .car-informations-wrapper {
            .car-name {
               color: #363636;
               font-size: var(--xlarge) !important;
            }
            .car-features {
               color: #363636;
            }
         }
         .clear-basket {
            i {
               transform: scale(1.5);
               filter: brightness(0.25);
            }
         }
      }
      .rent-a-car-basket-informations-wrapper {
         span {
            color: #363636 !important;
            font-size: var(--normal) !important;
         }
         .direction-icon {
            background-color: #ccc !important;

            i {
               filter: brightness(0) !important;
            }
         }
      }
      .rent-a-car-basket-ancillaries-wrapper {
         .ancillary {
            span {
               color: #363636 !important;
               font-size: var(--large) !important;

               &.ancillary-price {
                  font-size: var(--xlarge) !important;
               }
            }
         }
      }

      .action-button-mobile-wrapper {
         margin-top: 1rem;
         display: flex;
         justify-content: flex-end;
         .total-container {
            display: flex;
            align-items: center;

            .total-text {
               font-size: var(--normal);
            }

            .total-price {
               margin-left: 0.5rem;
               color: var(--primary);
               font-weight: 600;
               font-size: var(--xlarge);
            }
         }
      }
   }
}
</style>
