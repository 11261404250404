<template>
    <div class="gts-room-slider-wrapper">
        <div class="gts-room-slider-inner">
            <div class="gts-gallery-wrapper">
                <!-- <div class="gallery-cta-button left-arrow" @click="handlerLeftArrow">
                    <i class="gts-icon icon-arrow-left" />
                </div> -->
                <div class="gallery-list">
                    <template v-for="(image, index) in images">
                        <div class="gallery-list-item" v-if="currentIndex == index" :key="index" v-cloak>
                            <img :src="image.thumbnail" loading="lazy" alt="room gallery image" :data-index="index" @error="$event.target.src = defaultImageThumbnail, $event.target.srcset = defaultImageThumbnail">
                        </div>
                    </template>
                </div>
                <!-- <div class="gallery-cta-button right-arrow" @click="handlerRightArrow">
                    <i class="gts-icon icon-arrow-right" />
                </div> -->
            </div>

            <!-- TODO: GTS Room Slider'da tek foto gösterimi istenmişti. Bu yüzden dot ve arrowlar commentlendi. -->
            
            <!-- <div class="gallery-dot-wrapper">
                <span class="gallery-dot" :class="index -1 === currentIndex ? 'active': ''" v-for="index in images.length" :key="index" @click="handlerSelectImage(index)" :data-index="index"></span>
            </div> -->
        </div>
    </div>    
</template>

<script>
import defaultImageThumbnail from "@/assets/images/noimg.svg";

export default {
    name: 'GtsRoomSlider',
    props:{
        sliderImages: { Array }
    },
    data(){
        return{
            images: [],
            currentIndex: 0,
            defaultImageThumbnail: defaultImageThumbnail
        }
    },
    created(){
        var self = this;
        self.images = this.sliderImages;
    },
    methods:{
        handlerSelectImage(index){
            var self = this;
            self.currentIndex = index;
        },
        handlerLeftArrow(){
            var self = this;
            if(self.currentIndex <= 0){
                self.currentIndex = self.images.length -1;
            }else{
                self.currentIndex--;
            }
        },
        handlerRightArrow(){
            var self = this;
            
            if (self.currentIndex == self.images.length -1) {
                self.currentIndex = 0;
            }else{
                self.currentIndex++;
            }
        }
    }
}
</script>

<style lang="scss" scoped>
    .gts-room-slider-wrapper{
        .gts-room-slider-inner{
            .gts-gallery-wrapper{
                display: flex;
                align-items: center;
                position: relative;
                border: 1px solid #808080;
                border-radius: 8px;

                .gallery-cta-button{
                    position: absolute;
                    width: 40px;
                    height: 40px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 50% 50%;
                    background-color: rgba($color: #ffffff, $alpha: 0.9);

                    &.left-arrow{ left: 16px; }
                    &.right-arrow{ right: 16px; }

                    & > i{}
                }
                .gallery-list{
                    width: 100%;
                    .gallery-list-item{
                        width: 100%;
                        height: 350px;
                        max-height: 350px;
                        border-radius: 8px;
                        img{
                            width: 100%;
                            height: 100%;
                            border-radius: 8px;
                            object-fit: cover;
                        }
                    }
                }
            }
            .gallery-dot-wrapper{
                display: flex;
                align-items: flex-start;
                justify-content: center;
                width: 100%;
                margin-top: 16px;

                .gallery-dot{
                    display: block;
                    width: 8px;
                    height: 8px;
                    margin-right: 8px;
                    background-color: #BFBFBF;
                    border-radius: 50% 50%;

                    &:last-child{
                        margin-right: 0;
                    }

                    &.active{
                        background-color: #737373;
                    }
                }
            }
        }
    }
</style>