<template>
   <div id="airline-matrix-container" v-if="isMatrixActive">
      <div class="airline-matrix-header">
         <h4>{{ $t("searchComponentFlight_filterAirlines") }}</h4>
         <div class="airline-matrix-toggle-all">
            <UITooltip
               :tooltip-title="$t('flightMatrix_showHideAll')"
               :no-padding="true"
               tooltip-icon="gts-icon icon-star icon-sm"
               tooltip-position="bottom"
               @click.native="handlerAirlineSuperiorAll">
            </UITooltip>
         </div>
      </div>

      <div class="airlines-wrapper">
         <button class="slide-prev swiper-navigation" @click="handlerPrevSlide">
            <i class="gts-icon icon-arrow-left"></i>
         </button>
         <swiper :slides-per-view="slidesPerView" :space-between="spaceBetweenSlides" @swiper="initializeSwiper" observer>
            <template v-for="(airline, airlineIndex) in airlines">
               <swiper-slide :key="airlineIndex">
                  <div class="airline-wrapper" :class="airline.checked ? 'airline-selected' : ''">
                     <div class="airline-logo-wrapper">
                        <img
                           class="airline-logo"
                           :src="`${$constants.cdn}/airline_34x34/${airline.airlineName}.svg`"
                           alt=""
                           @error="handlerUnknownImg($event)"
                           @click="handlerAirlineSelect(airline)" />
                     </div>
                     <span class="airline-name">{{ $t(`airlineEnum_${airline.airlineName.toUpperCase()}`) }}</span>
                     <span
                        v-if="false"
                        class="airline-count"
                        :style="{
                           borderColor: $options.filters.airlineColor(airline.airlineName),
                           color: $options.filters.airlineColor(airline.airlineName),
                           backgroundColor: $options.filters.airlineColor(airline.airlineName) + '5',
                        }">
                        {{ airline.count }}
                     </span>

                     <!-- <span class="flight-color masked-background-dark" :style="{ 'background-color': $options.filters.airlineColor(handlerLegColor) }"></span> -->

                     <button class="airline-superior" @click="handlerAirlineSuperior(airline)"></button>
                  </div>
               </swiper-slide>
            </template>
         </swiper>
         <button class="slide-next swiper-navigation" @click="handlerNextSlide">
            <i class="gts-icon icon-arrow-right"></i>
         </button>
      </div>
   </div>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper-vue2";
import UITooltip from "@/components/utils/GtsToolTips.vue";
import "swiper/swiper-bundle.css";
import "swiper/swiper.min.css";

export default {
   name: "AirlineMatrix",
   components: {
      Swiper,
      SwiperSlide,
      UITooltip,
   },

   data() {
      return {
         airlines: {},

         slider: null,
         slidesPerView: 8,
         spaceBetweenSlides: 2,
         isMatrixActive: false,
      };
   },
   created() {
      const storeInnerWidth = this.$store.state.app.clientInformations.innerWidth;
      if (storeInnerWidth <= 550) {
         this.slidesPerView = 2;
         this.spaceBetweenSlides = 2;
      } else if (storeInnerWidth >= 768 && storeInnerWidth <= 1400) {
         this.slidesPerView = 5;
         this.spaceBetweenSlides = 10;
      }
   },

   methods: {
      initializeSwiper(swiper) {
         this.slider = swiper;
      },
      handlerUnknownImg(ev) {
         ev.target.src = `${this.$constants.cdn}/airline_34x34/00.svg`;
      },
      handlerPrevSlide() {
         this.slider.slidePrev();
      },
      handlerNextSlide() {
         this.slider.slideNext();
      },
      handlerAirlineSelect(airline) {
         this.$store.dispatch("flight/selectAirline", airline.airlineName);
      },
      handlerAirlineSuperior(superiorAirline) {
         this.$store.dispatch("flight/superiorAirline", superiorAirline.airlineName);
      },
      handlerAirlineSuperiorAll() {
         this.$store.dispatch("flight/superiorAllAirline");
      },
   },

   watch: {
      "$store.state.flight.filters": {
         handler: function (filters) {
            const isFiltersEmpty = Object.keys(filters).length > 0;
            if (isFiltersEmpty) {
               this.isMatrixActive = true;
               Object.keys(filters).forEach((key) => {
                  const airlines = Object.keys(filters[key].filters.airlines);
                  airlines.map((airline) => {
                     const airlineObj = {
                        airlineName: airline,
                        checked: filters[key].filters.airlines[airline].checked,
                        count: filters[key].filters.airlines[airline].count,
                     };
                     this.$set(this.airlines, airline, airlineObj);
                  });
               });
            } else {
               this.isMatrixActive = false;
            }
         },
         immediate: true,
         deep: true,
      },
   },
};
</script>

<style lang="scss" scoped>
#airline-matrix-container {
   // padding: 0.5rem 0rem;
   margin-top: 1rem;
   margin-bottom: 1.5rem;
   .airline-matrix-header {
      display: inline-flex;
      background-color: #2f67b8;
      align-items: center;
      justify-content: center;
      padding: 0.25rem 0.75rem;
      border-radius: 0.5rem;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;

      h4 {
         color: #fff;
         font-weight: 500;
         margin-right: 0.5rem;
      }

      i {
         filter: brightness(10);
      }

      .airline-matrix-toggle-all {
         display: flex;
         align-items: center;
         justify-content: center;
         cursor: pointer;

         .gts-tooltip {
            ::v-deep {
               i.gts-icon {
                  transform: scale(1);
                  transition-property: transform;
                  transition-timing-function: ease-in;
                  transition-duration: 0.05s;
                  filter: brightness(0) invert(10) !important;

                  &:hover {
                     transform: scale(1.3);
                     filter: invert(50%) sepia(100%) saturate(256%) hue-rotate(160deg) brightness(100%) contrast(200%) !important;
                  }
               }
            }
         }
      }
   }
   .airlines-wrapper {
      background: #fff;
      display: flex;
      padding: 0.75rem 0.25rem;
      border-radius: 0.5rem;
      border-top-left-radius: 0;

      .swiper-navigation {
         background: transparent !important;
      }
      .swiper-container {
         overflow: hidden;
         width: 100%;

         .swiper-wrapper {
            display: flex !important;
            justify-content: center !important;
            .swiper-slide {
               display: flex;
               align-items: center;
               justify-content: center;
               .airline-wrapper {
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  align-items: center;
                  width: 120px;
                  height: 120px;
                  background: #f5f9ff;
                  border: 1px solid #eee;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  border-radius: 0.5rem;

                  &.airline-selected {
                     border: 2.5px solid var(--accent-secondary);
                     img.airline-logo {
                        opacity: 1 !important;
                        filter: grayscale(0) !important;
                     }
                  }

                  .airline-logo-wrapper {
                     width: 62px;
                     height: 62px;
                     display: flex;
                     align-items: center;
                     justify-content: center;
                     cursor: pointer;

                     img.airline-logo {
                        transition-property: all;
                        transition-timing-function: ease-in-out;
                        transition-duration: 0.15s;
                        $logo: 52px;
                        width: $logo;
                        height: $logo;
                        opacity: 0.3;
                        filter: grayscale(1);
                     }
                  }
                  span.airline-name {
                     font-weight: 500;
                     max-width: 100px;
                     text-align: center;
                     font-size: var(--xsmall);
                     margin-top: 0.5rem;
                     // min-height: 40px;
                     opacity: 0.7;
                  }
                  span.airline-count {
                     font-weight: 500;
                     font-size: var(--xsmall);
                     border-width: 1px;
                     border-style: solid;
                     padding: 0rem 1rem;
                     border-radius: 24rem;
                     background-color: #fff;
                  }
                  .airline-superior {
                     height: 26px !important;
                     background-color: #517bb3;
                     background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMiIgaGVpZ2h0PSIxMi4wMDIiIHZpZXdCb3g9IjAgMCAxMiAxMi4wMDIiPjxwYXRoIGQ9Ik0xNC45NjgsNy4zYS42LjYsMCwwLDAtLjUyMS0uNDA2bC0zLjQyLS4yNzJMOS41NDcsMy4zNTFhLjYuNiwwLDAsMC0xLjA5MywwTDYuOTczLDYuNjI4LDMuNTUzLDYuOWEuNi42LDAsMCwwLS4zNzEsMS4wMjhsMi41MjgsMi40NjQtLjg5NCwzLjg3MWEuNi42LDAsMCwwLC45MTguNjM0TDksMTIuNzE5LDEyLjI2NywxNC45YS42LjYsMCwwLDAsLjkxLS42NjRsLTEuMS0zLjg0TDE0LjgsNy45NDRhLjYuNiwwLDAsMCwuMTY3LS42MzlaIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMyAtMi45OTYpIiBmaWxsPSIjZmZmIi8+PC9zdmc+);
                     background-repeat: no-repeat;
                     background-position: center;
                     border-radius: 50px;
                     background-size: 12px;
                     width: 50px;
                     margin: auto;
                     position: absolute;
                     bottom: 0;
                     left: 0;
                     right: 0;
                     opacity: 0;
                     transition-property: all;
                     transition-duration: 0.15s;
                     transition-timing-function: ease-in-out;

                     &:hover {
                        background-color: var(--accent-primary);
                     }
                  }

                  &:hover {
                     .airline-superior {
                        opacity: 1;
                        bottom: 1rem;
                     }
                     .airline-name {
                        opacity: 0;
                     }
                     .airline-logo-wrapper {
                        img.airline-logo {
                           transform: scale(1.25);
                        }
                     }
                  }
               }
            }
         }
      }
   }
}
</style>
