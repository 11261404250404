<template>
   <div class="passenger-information-summary-wrapper hotel-order-detail-inner-section">
      <div class="passenger-information-summary-inner">
         <div class="passenger-information-table-wrapper">
            <div class="passenger-information-table-inner">
               <div class="passenger-information-table-header">
                  <div class="table-header-fixed-item">
                     <span>{{ $t("orderDetail_lblPassengerInfoRoomType") }}</span>
                  </div>
                  <div class="table-header-list">
                     <span>{{ $t("orderDetail_lblGuestType") }}</span>
                     <span>{{ $t("orderDetail_lblGender") }}</span>
                     <span>{{ $t("orderDetail_lblName") }}</span>
                     <span>{{ $t("orderDetail_lblBirthdate") }}</span>
                     <span>{{ $t("orderDetail_lblNationality") }}</span>
                     <!-- <span></span> -->
                  </div>
               </div>
               <div class="passenger-information-table-content">
                  <div class="passenger-information-room-item" v-for="(roomItem, index) in passengers" :key="index">
                     <div class="table-content-fixed-item">
                        <span>{{ roomItem.channelRoomName }}</span>
                     </div>
                     <div class="table-content-list">
                        <ul class="passenger-information-list default-list">
                           <li class="passenger-information-list-item" v-for="(guestItem, index) in roomItem.guests" :key="index">
                              <span>{{ guestItem.type | EHtlGuestType }}</span>
                              <span>{{ guestItem.gender | EComGender }}</span>
                              <span>{{ guestItem.name }} {{ guestItem.surname }}</span>
                              <span v-if="guestItem.type == 2">{{ guestItem.birthdate | fullDateFormat }}</span>
                              <span v-else>{{ "" }}</span>
                              <span>{{ guestItem.nationality }}</span>
                              <!-- <span><i class="gts-icon icon-arrow-rounded-down"></i></span> -->
                           </li>
                        </ul>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>
export default {
   name: "PassengerInformationSummary",
   props: {
      passengerList: { type: Array },
   },
   data() {
      return {
         passengers: [],
      };
   },
   created() {
      const self = this;
      self.passengers = this.passengerList;
   },
   watch: {
      passengerList: {
         handler: function (newPassengerList) {
            const self = this;
            self.passengers = newPassengerList;
         },
         deep: true,
      },
   },
};
</script>

<style lang="scss" scoped>
.passenger-information-summary-wrapper {
   .passenger-information-summary-inner {
      .passenger-information-table-wrapper {
         .passenger-information-table-inner {
            display: block;
            border: 1px solid #c4c4c4;
            border-radius: 10px;
            overflow: hidden;
            .passenger-information-table-header {
               display: flex;
               justify-content: flex-start;
               align-items: center;
               padding: 16px 42px;
               background-color: #ededed;
               border-bottom: 1px solid #c4c4c4;

               span {
                  font-size: 14px;
                  color: #868788;
               }
               .table-header-fixed-item {
                  width: 15%;
                  max-width: 15%;
                  margin-right: 24px;
               }
               .table-header-list {
                  display: grid;
                  grid-template-columns: repeat(5, 1fr);
                  width: 85%;
                  padding: 0 32px;
               }
            }
            .passenger-information-table-content {
               .passenger-information-room-item {
                  display: flex;
                  justify-content: flex-start;
                  align-items: stretch;
                  padding: 16px 42px;
                  background-color: white;
                  border-bottom: 1px solid #c4c4c4;

                  &:last-child {
                     border-bottom: none;
                  }
                  .table-content-fixed-item {
                     width: 15%;
                     max-width: 15%;
                     margin-right: 24px;

                     & > span {
                        display: inline-flex;
                        justify-content: flex-start;
                        align-items: center;
                        width: 100%;
                        height: 100%;
                        font-weight: 700;
                     }
                  }
                  .table-content-list {
                     width: 85%;

                     .passenger-information-list {
                        .passenger-information-list-item {
                           display: grid;
                           grid-template-columns: repeat(5, 1fr);
                           align-items: center;
                           // border: 1px solid;
                           border-radius: 20px;
                           margin-bottom: 8px;
                           padding: 10px 32px;
                           font-weight: 500;

                           &:last-child {
                              margin-bottom: 0;
                           }

                           // &:nth-child(odd) {
                           //    background-color: #f5f5f5;
                           //    border-color: #f5f5f5;
                           // }
                           // &:nth-child(even) {
                           //    background-color: white;
                           //    border-color: #e2e2e2;
                           // }

                           & > span:last-child {
                              display: inline-flex;
                              justify-content: flex-start;
                           }
                        }
                     }
                  }
               }
            }
         }
         @media (max-width: 767.98px) {
            .passenger-information-table-inner {
               display: flex;

               .passenger-information-table-header {
                  display: flex;
                  flex-direction: column;
                  padding: 16px 0px;
                  .table-header-fixed-item {
                     margin-right: 0px;
                  }
                  .table-header-list {
                     display: flex;
                     height: 100%;
                     flex-direction: column;
                     width: auto;
                     justify-content: center;
                     & > span {
                        text-align: center !important;
                        margin-bottom: 8px;
                     }
                  }
               }
               .passenger-information-table-content {
                  .passenger-information-room-item {
                     flex-direction: column;
                     .table-content-fixed-item {
                        margin-bottom: 24px;
                        border-bottom: 1px solid #bfbfbf;
                        border-right: 0;
                        width: auto;
                        max-width: -webkit-fill-available;

                        &:last-child {
                           width: 85%;
                           max-width: 85%;
                           margin-top: 16px;
                        }
                     }
                     .passenger-information-list.default-list {
                        .passenger-information-list-item {
                           display: flex;
                           flex-direction: column;
                        }
                     }
                  }
               }
            }
         }
      }
   }
}
</style>
