export const ETrfTnrStatus = {
   "-1": "ALL",
   0: "UNKNOWN",
   1: "NEW",
   2: "RESERVED",
   3: "ISSUED",
   4: "CANCELED",
   5: "VOIDED",
   6: "REFUNDED",
   7: "REISSUED",
   8: "ERROR",
   9: "OPEN",
   10: "PENDING",
};

export const ETrfLegStatus = {
   "-1": "ALL",
   0: "UNKNOWN",
   1: "NEW",
   2: "RESERVED",
   3: "ISSUED",
   4: "CANCELED",
   5: "VOIDED",
   6: "REFUNDED",
   7: "REISSUED",
   8: "ERROR",
   9: "OPEN",
   10: "PENDING",
};

export const ETrfOperationStatus = {
   "-1": "ALL",
   0: "UNKNOWN",
   1: "OPEN",
   2: "LOCKED",
   3: "PLANNED",
   4: "COMPLETED",
   5: "NOSHOW",
};

export const ETrfCommissionType = {
   "-1": "ALL",
   0: "UNKNOWN",
   1: "GSA",
   2: "AGENCY",
   3: "PROVIDER",
};

export const ETrfCommissionAmountType = {
   "-1": "ALL",
   0: "UNKNOWN",
   1: "RATIO",
   2: "AMOUNT",
};

export const ETrfVehicleType = {
   "-1": "ALL",
   0: "UNKNOWN",
   1: "CAR",
   2: "MINIVAN",
   3: "VAN",
   4: "MINIBUS",
   5: "MIDIBUS",
   6: "OTOBUS",
   7: "MINIVAN_VIP",
};

export const ETrfTransferType = {
   "-1": "ALL",
   0: "UNKNOWN",
   1: "INDIVIDUAL",
   2: "GROUP",
};
export const ETrfProductType = {
   "-1": "ALL",
   0: "UNKNOWN",
   1: "TRANSFER",
   2: "ANCILLARY",
};

export const ETrfTravelerType = {
   "-1": "ALL",
   0: "UNKNOWN",
   1: "ADULT",
   2: "CHILD",
   3: "INFANT",
};

export const ETrfSaleStatus = {
   "-1": "ALL",
   0: "UNKNOWN",
   1: "OPEN",
   2: "CLOSE",
};

export const ETrfAncillaryType = {
   "-1": "ALL",
   0: "UNKNOWN",
   1: "BABYSEAT",
   2: "WHEELCHAIR",
};

export const ETrfRouteOriginType = {
   "-1": "ALL",
   0: "UNKNOWN",
   1: "FROM",
   2: "TO",
   3: "BOTH",
};

export const EReportTransferDateType = {
   "-1": "ALL",
   0: "UNKNOWN",
   1: "SALE",
   2: "TRANSFER",
};

export const ETrfFacilityType = {
   "-1": "ALL",
   0: "UNKNOWN",
   1: "VOIDABLE_BEFORE_24H",
   2: "VOIDABLE_BEFORE_36H",
};
