<template>
   <div class="brands-wrapper">
      <!-- Swiper Slides -->

      <swiper
         :slides-per-view="slidesPerView"
         :observer="true"
         :centeredSlides="centeredSlides"
         :space-between="spaceBetweenSlides"
         @slideChange="onSlideChange"
         ref="brands">
         <swiper-slide v-for="(brand, key, brandIndex) in brands" :key="brandIndex">
            <div
               class="slide-inner-container"
               :class="[brand.selected ? 'active-brand' : '', activeSlide == brandIndex ? 'on-spot' : '']"
               @click.self="handlerSelectBrand(brand.identifier, flightIdentifier)">
               <div @click="handlerSelectBrand(brand.identifier, flightIdentifier)">
                  <div class="slide-inner-container-row">
                     <span class="brand-name masked" v-html="brand.brandName"></span>
                  </div>
                  <div class="slide-inner-container-row">
                     <span class="brand-total masked">{{ brand.total | price(brand.currency) }}</span>
                  </div>
               </div>

               <div class="slide-inner-container-row" v-if="brand.baggageInfo != '-1KG'">
                  <div class="baggage-info masked">
                     <div class="baggage-infos">
                        <template v-if="brand.brandCode == 'SUPER_ECOLIGHT'">
                           <i
                              class="gts-icon icon-brand-hand-wallet masked-hidden icon-xl"
                              style="width: 62px !important; height: 46px !important"></i>
                        </template>
                        <template v-else-if="brand.brandCode == 'LIGHT_PDBG'">
                           <i
                              class="gts-icon icon-brand-cabin-baggage masked-hidden icon-md"
                              style="width: 54px !important; height: 54px !important"></i>
                        </template>
                        <template v-else>
                           <i class="gts-icon icon-brand-baggage masked-hidden icon-md"></i>
                        </template>

                        <span>{{ brand.baggageInfo }}</span>
                     </div>
                     <div class="show-details" @click="handlerShowDetails">
                        <i class="gts-icon icon-arrow-down icon-xs"></i>
                     </div>
                  </div>
               </div>
               <div class="slide-inner-container-row" v-else>
                  <div class="baggage-info masked">
                     <span class="baggage-unknown">{{ $t("flightBrands_lblBaggageInfoUnknown") }}</span>
                  </div>
               </div>

               <div class="brand-detail" v-if="details || brand.baggageInfo == '-1KG'">
                  <ul class="brand-detail-list">
                     <li class="list-item" v-for="(info, infoIndex) in brand.ssrs" :key="infoIndex">
                        <i :class="info.state == 1 ? 'included' : 'not-included'"></i>
                        <span>{{ info.info }}</span>
                     </li>
                  </ul>
               </div>
            </div>
         </swiper-slide>
      </swiper>

      <!-- Bullets -->
      <div class="slider-navigation">
         <ul>
            <li
               v-for="(brand, key, bulletIndex) in brands"
               :key="bulletIndex"
               :ref="brand.selected ? 'active' : ''"
               :class="[brand.selected ? 'active' : '', activeBullet == bulletIndex ? 'onspot' : '']"
               :data-selected="bulletIndex"
               @click="handleBullet(bulletIndex)"
               class="masked-background-dark"></li>
         </ul>
      </div>

      <!-- Navigation -->
      <div class="navigation-buttons-container masked-hidden">
         <button class="slider-prev" @click="handlerPrevSlide" v-if="showArrows.prev">
            <i class="gts-icon icon-arrow-left"></i>
         </button>
         <button class="slider-next" @click="handlerNextSlide" v-if="showArrows.next">
            <i class="gts-icon icon-arrow-right"></i>
         </button>
      </div>

      <SsrSelection :ssrs="ssrs" :flight-identifer="flightIdentifier" :selected-brand="SelectedBrandIs" v-if="ShowSsr" />
   </div>
</template>

<script>
import { Navigation, Pagination } from "swiper";

import { SwiperCore, Swiper, SwiperSlide } from "swiper-vue2";

// Import Swiper styles
import "swiper/swiper-bundle.css";

SwiperCore.use([Navigation, Pagination]);
import { flightService } from "@/service/index.js";
import SsrSelection from "@/components/structural/flight/checkout/brands/SsrSelection.vue";
export default {
   name: "FlightBrands",
   props: {
      brands: Object,
      legIndex: String,
      flightIdentifier: String,
      pricePerLeg: Boolean,
      identifierList: Array,
      data: Object,
      ssrs: Object,
   },
   components: {
      Swiper,
      SwiperSlide,
      SsrSelection,
   },
   data() {
      return {
         showSsr: true,
         slider: null,
         activeSlide: null,
         activeBullet: null,
         showArrows: {
            prev: true,
            next: true,
         },
         centeredSlides: false,
         slidesPerView: 3,
         spaceBetweenSlides: 10,
         details: false,
      };
   },

   created() {
      const storeInnerWidth = this.$store.state.app.clientInformations.innerWidth;
      if (storeInnerWidth <= 550) {
         this.slidesPerView = 1;
         this.spaceBetweenSlides = 1;
      } else if (storeInnerWidth <= 992) {
         this.slidesPerView = 3;
         this.spaceBetweenSlides = 5;
      }
   },
   computed: {
      ShowSsr() {
         return typeof this.ssrs === "object" && this.ssrs !== null && Object.keys(this.ssrs).length > 0 && this.showSsr;
      },
      SelectedBrandIs() {
         const brandItems = this.brands;

         for (const item in brandItems) {
            if (brandItems[item].selected) {
               return brandItems[item].brandName;
            }
         }
         return null;
      },
   },

   methods: {
      onSwiper(swiper) {
         swiper;
         // const self = this;
         // self.slider = swiper;
         // self.activeSlide = swiper.activeIndex;
      },

      onSlideChange(swiper) {
         const self = this;
         self.activeBullet = swiper.activeIndex;
         self.activeSlide = swiper.activeIndex;
      },
      handleBullet(index) {
         const self = this;
         self.slider.slideTo(index);
         self.activeBullet = index;
      },
      handlerNextSlide() {
         const self = this;

         self.slider.slideNext();
      },
      handlerPrevSlide() {
         const self = this;

         self.slider.slidePrev();
      },

      handlerSelectBrand(flightID, brandId) {
         const self = this;
         self.showSsr = false;
         self.$store.commit("app/setIsProgressGifActive", true);
         self.$store.state.app.progress.message = this.$i18n.t("flightCheckout_brandSelection");

         if (!this.pricePerLeg) {
            var selectedBrandIndex;
            const arrBrands = {};

            //Find Selected Brand Index
            Object.keys(this.data).forEach((leg) => {
               arrBrands[this.data[leg].flightIdentifier] = arrBrands[this.data[leg].flightIdentifier];
               Object.keys(this.data[leg].brands).forEach((brand, brandIndex) => {
                  if (brand == flightID) {
                     selectedBrandIndex = brandIndex;
                  }
               });
            });

            //Generate ArrBrands with identifiers
            Object.keys(this.data).forEach((leg) => {
               Object.keys(this.data[leg].brands).forEach((brand, brandIndex) => {
                  if (brandIndex == selectedBrandIndex) {
                     arrBrands[this.data[leg].flightIdentifier] = brand;
                  }
               });
            });

            self.$store.commit("flight/updateCheckoutBrandsPackage", arrBrands);
         } else {
            self.$store.commit("flight/updateCheckoutBrands", {
               flightIdentifier: flightID,
               identifier: brandId,
            });
         }

         let query = self.$store.state.flight.checkout.request;

         flightService.checkout(query).then((response) => {
            self.$store.commit("flight/updateCheckoutResponse", response.checkoutResponse);
            self.$store.commit("flight/updateCheckoutRequest", response.checkoutRequest);
            self.$store.commit("flight/updateCheckoutUuid", response.checkoutUuid);
            // self.$store.commit("flight/updateIsRealData", true);
            self.showSsr = true;
            self.$store.commit("app/setIsProgressGifActive", false);
            self.$store.state.app.progress.message = "";
         });
      },
      handlerShowDetails() {
         const self = this;
         self.details = !self.details;
      },
   },
   watch: {
      brands: {
         handler: function (brands) {
            brands;

            // this.slider = new Swiper(this.$refs.brands.$el.swiper);
            this.slider = this.$refs.brands.$el.swiper;

            var selectedBrandIndex;

            Object.keys(this.brands).forEach((brand, index) => {
               if (this.brands[brand].selected) {
                  selectedBrandIndex = index;
               }
            });
            this.slider.activeIndex = selectedBrandIndex - 1;

            if (Object.keys(this.brands).length > 3) {
               this.centeredSlides = false;

               this.showArrows = {
                  prev: true,
                  next: true,
               };
            } else if (Object.keys(this.brands).length == 3) {
               this.centeredSlides = false;

               this.showArrows = {
                  prev: false,
                  next: false,
               };
            } else {
               this.centeredSlides = true;
               this.showArrows = {
                  prev: false,
                  next: false,
               };
            }
         },
      },
   },
};
</script>

<style lang="scss" scoped>
.brands-wrapper {
   padding: 1.5rem 2.5rem;
   position: relative;
   overflow: hidden;

   .swiper-container {
      overflow: hidden;
      padding: 2rem 1rem;
      width: 300px;

      @media (min-width: 768px) {
         width: 750px;
      }

      .swiper-wrapper {
         align-items: center;
      }
   }
   .swiper-slide {
      width: 200px;

      @media (min-width: 992px) {
         width: 250px;
      }

      .slide-inner-container {
         display: flex;
         flex-direction: column;
         justify-content: center;
         align-items: center;
         padding: 2rem 1.5rem;
         border: 1px solid #ccc;
         background-color: #f6f9fd;
         border-radius: 16px;
         margin: 0 1rem;
         cursor: pointer;
         min-height: 140px !important;

         &.on-spot {
            border-width: 2px;
            border-style: solid;
            border-color: #cccccc !important;
            background-color: #ffffff;
            transform: scale(1.05);
         }
         &.active-brand {
            border: 3px solid #33b8ff !important;
            color: #33b8ff;
            cursor: not-allowed;
            background-color: #e7f7ff;
         }

         .slide-inner-container-row {
            display: flex;

            span {
               text-align: center;
               width: 100%;

               &.brand-name {
                  text-transform: uppercase;
                  font-weight: 600;
                  font-size: var(--large);
               }
               &.brand-total {
                  font-size: var(--xxxlarge);
                  font-weight: 900;
               }
               &.baggage-info {
                  font-size: var(--xlarge);
                  font-weight: 500;
                  display: flex;
                  flex-direction: row;
               }
            }

            .baggage-info {
               display: flex;
               align-items: center;

               .baggage-infos {
                  display: flex;
                  align-items: center;
               }

               .baggage-unknown {
                  background-color: #ff4c4c;
                  color: #fff;
                  padding: 0.125rem 0.5rem;
                  border-radius: 0.25rem;
                  font-size: 0.8rem;
               }

               .show-details {
                  background: #d2dae8;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  width: 32px;
                  height: 32px;
                  border-radius: 50%;
                  margin-left: 0.5rem;
                  cursor: pointer;

                  &:hover {
                     background: #ccc;
                  }
               }
            }
         }

         .brand-detail {
            border-top: 1px solid #ccc;
            padding-top: 0.5rem;
            margin-top: 1rem;
            width: 100%;
            overflow: hidden;

            .brand-detail-list {
               .list-item {
                  background-repeat: no-repeat;
                  border-bottom: 1px dashed #e1e1e1;
                  padding-bottom: 0.35rem;
                  display: flex;
                  font-size: var(--small);
                  i {
                     content: "*";
                     text-indent: -999em;
                     background-size: contain;
                     background-repeat: no-repeat;
                     width: 18px;
                     height: 18px;
                     display: inline-block;
                     border-radius: 50%;
                     flex-shrink: 0;
                     margin-right: 0.5rem;
                     margin-top: 0.2rem;

                     &.included {
                        background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxOCIgaGVpZ2h0PSIxOCIgdmlld0JveD0iMCAwIDE4IDE4Ig0KPjxnIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0zNTEgLTc1OSkiPjxjaXJjbGUgY3g9IjkiIGN5PSI5IiByPSI5IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgzNTEgNzU5KSIgZmlsbD0idHJhbnNwYXJlbnQiLz48cGF0aCBkPSJNOSwyYTcsNywwLDEsMCw3LDdBNy4wMDgsNy4wMDgsMCwwLDAsOSwyTTksMEE5LDksMCwxLDEsMCw5LDksOSwwLDAsMSw5LDBaIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgzNTEgNzU5KSIgZmlsbD0iIzFlZTIzMCIvPjxwYXRoIGQ9Ik0tMTA5MjQuMzMyLDI0MWExLDEsMCwwLDEtLjQxLS4wODhzMCwwLDAsMGgwYTEsMSwwLDAsMS0uMjQ4LS4xNjFsLS4wMDktLjAwNy0yLjY2Ni0yLjRhMSwxLDAsMCwxLS4zMzEtLjc0NSwxLDEsMCwwLDEsLjI1Ny0uNjY4LDEsMSwwLDAsMSwxLjQxMi0uMDc1bDEuOTEsMS43MTgsMy42NjYtNC4yM2ExLDEsMCwwLDEsMS40MTEtLjEsMSwxLDAsMCwxLC4zNDUuNzU2LDEsMSwwLDAsMS0uMjQ0LjY1NGwtNC4zMzEsNS0uMDA3LjAwN3MwLDAsMCwwYTEsMSwwLDAsMS0uMjI0LjE4OSwxLDEsMCwwLDEtLjQ3LjE0OGgtLjA1N1oiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDExMjgzLjUgNTMwLjUwMSkiIGZpbGw9IiMxZWUyMzAiLz48L2c+PC9zdmc+);
                     }
                     &.not-included {
                        background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxOCIgaGVpZ2h0PSIxOCIgdmlld0JveD0iMCAwIDE4IDE4Ij48ZyB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMzY2IC04ODMuNSkiPjxwYXRoIGQ9Ik05LDJhNyw3LDAsMSwwLDcsN0E3LjAwOCw3LjAwOCwwLDAsMCw5LDJNOSwwQTksOSwwLDEsMSwwLDksOSw5LDAsMCwxLDksMFoiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDM2NiA4ODMuNSkiIGZpbGw9IiNmNTQzMTciLz48cGF0aCBkPSJNLTExMjg2LjU0MSwxMjJhLjk2Ljk2LDAsMCwxLC42NzkuMjgxbDIuMiwyLjIsMi4yLTIuMmEuOTU4Ljk1OCwwLDAsMSwxLjM1NSwwLC45NTUuOTU1LDAsMCwxLC4yODEuNjc4Ljk1NS45NTUsMCwwLDEtLjI4MS42NzhsLTIuMiwyLjIsMi4yLDIuMmEuOTU1Ljk1NSwwLDAsMSwuMjgxLjY3OC45NTUuOTU1LDAsMCwxLS4yODEuNjc4Ljk1OC45NTgsMCwwLDEtMS4zNTUsMGwtMi4yLTIuMi0yLjIsMi4yYS45Ni45NiwwLDAsMS0xLjM1NywwLC45NTUuOTU1LDAsMCwxLS4yODEtLjY3OC45NTUuOTU1LDAsMCwxLC4yODEtLjY3OGwyLjItMi4yLTIuMi0yLjJhLjk1NS45NTUsMCwwLDEtLjI4MS0uNjc4Ljk1NS45NTUsMCwwLDEsLjI4MS0uNjc4QS45NTguOTU4LDAsMCwxLTExMjg2LjU0MSwxMjJaIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgxMTY1OC43NTYgNzY2LjUpIiBmaWxsPSIjZjU0MzE3Ii8+PC9nPjwvc3ZnPg==);
                     }
                  }
               }
            }
         }
      }
   }
   .slider-navigation {
      display: flex;
      justify-content: center;
      margin-top: 1rem;
      ul {
         border: 1px solid #ccc;
         display: flex;
         padding: 0.5rem;
         border-radius: 12rem;
         background-color: var(--pastel-primary-lightest);
         li {
            width: 20px;
            height: 20px;
            display: inline-block;
            border-radius: 50%;
            background-color: #b1cbeb;
            opacity: 0.5;
            margin: 0 0.25rem;
            cursor: pointer;
            box-sizing: border-box;
            border-width: 2px;
            border-style: solid;
            border-color: transparent;

            &.onspot {
               opacity: 1;
               background-color: #fff;
               border-width: 2px;
               border-style: solid;
               border-color: #cccccc !important;
            }
            &.active {
               border-color: #33b8ff !important;
               background-color: #b4e0f6 !important;
               color: #33b8ff;
               opacity: 1;

               &.onspot {
                  background-color: #33b8ff !important ;
               }
            }
         }
      }
   }
   .slider-prev,
   .slider-next {
      background: none !important;
      position: absolute;
      top: 140px;

      margin: auto;
      z-index: 99;
   }

   .slider-prev {
      left: 0;
   }
   .slider-next {
      right: 0;
   }
}
</style>
