<template>
   <div class="terms-of-use-container">
      <span class="terms-of-use-title">Privacy Policy</span>
      <div class="terms-of-use">
         <div id="privacy-container" class="inner-overflow">
            <span style="height: 100px; display: block"></span>
            <p><b>Data Protection and Obligation Disclaimer</b></p>
            <p><b>A. Data Protection Statement as per DSGVO</b></p>
            <p><b>I. Names and addresses of officers</b></p>
            <p>
               The officers as per Data Protection Main Legislation and national Data Protection Laws of member states and other legislations of data
               protection lawas, are indicated below:
            </p>
            <p>{{ $constants.brand.title }}</p>
            <p>{{ $constants.brand.manager }}</p>

            <p>{{ handlerReplacer($constants.brand.address, "¶") }}</p>
            <p>Tel.: {{ $constants.brand.phone | phone }}</p>
            <p>Fax: {{ $constants.brand?.fax | phone }}</p>
            <p>E-Mail: {{ $constants.brand.email }}</p>
            <p>
               Web Sayfası:&nbsp;<a :href="$constants.brand.webSite"> {{ $constants.brand.webSite }}</a>
            </p>
            <p><b>II. Name and address of data protection officer</b></p>
            <p>Data protection officer:</p>
            <p>{{ $constants.brand.manager }}</p>
            <p>{{ handlerReplacer($constants.brand.address, "¶") }}</p>
            <p>Tel.: {{ $constants.brand.phone | phone }}</p>
            <p>Fax: {{ $constants.brand?.fax | phone }}</p>
            <p>E-Mail: {{ $constants.brand.email }}</p>
            <p>
               Web Sayfası:&nbsp;<a :href="$constants.brand.webSite"> {{ $constants.brand.webSite }}</a>
            </p>
            <p><b>III. General data processing information</b></p>
            <p><b>1. The scope of procession personal data</b></p>

            <p>
               Personal data of our users is processed only when that is required for preparing a functional webpage and for our contents and
               services. Personal data of our users is processed only by approval of our users. However, when taking approval is not possible
               previously due to actual reasons and when it is permitted to process data due to legal legislation, there is an exception about this
               matter.
            </p>
            <p><b>2. Legal base for processing personal data</b></p>
            <p>
               In case that approval is taken from related person for processing personal data, Paragraph 1lit.a of Article 6 of EU-General Data
               Protection Legislation (DSGVO) is accepted as legal base.<span class="Apple-converted-space">&nbsp;</span>
            </p>
            <p>
               For concluding a contract where related person is a party of a contract, Paragraph 1lit.b of Article 6 of EU-General Data Protection
               Legislation (DSGVO) is accepted as legal basis for processing required personal data. For applying measures before a contract, this
               legal basis is also valid for these processes.
            </p>
            <p>
               When it is required to process personal data for performing a legal obligation subjected to our company, Paragraph 1lit.c of Article 6
               of EU-General Data Protection Legislation (DSGVO) is accepted as legal base.
            </p>
            <p>
               When it is required to use personal data for interests those have vital importance for related person or another real person, Paragraph
               1lit.d of Article 6 of EU-General Data Protection Legislation (DSGVO) is accepted as legal base.
            </p>
            <p>
               When it is required to process personal data for protecting justified legal interests of our company or a third person and in case that
               interests of related person, his basic rights and freedoms are more important than the first indicated interest, Paragraph 1lit.f of
               Article 6 of EU-General Data Protection Legislation (DSGVO) is accepted as legal basis.
            </p>
            <p><b>3. Deleting data and retention period</b></p>
            <p>
               When the purpose of data retention is no longer available, personal data of related person is deleted or blocked. Data retention can be
               continued only when it is provided in union legislations, laws or other legislations establihed by European law-makers or national
               law-makers. When data retention period that is indicated in the standards, is over, data is blocked or deleted, however, this cannot be
               applied when it is required to continue to data retention for performing a contract or signing a contract.
            </p>
            <p><b>IV. Preparing web page and logfile</b></p>
            <p>
               <b>4. Definitions and scope of data processing<span class="Apple-converted-space">&nbsp;</span></b>
            </p>
            <p>
               When it is entered into our internet page, our system automatically takes data and information from computer system entering into our
               page.
            </p>
            <p>Below indicated data is taken:</p>
            <ol>
               <li>Browser type and used version information</li>
               <li>User’s operating system</li>
               <li>User’s Internet Service Provider<span class="Apple-converted-space">&nbsp;</span></li>
               <li>User’s IP-Address</li>
               <li>Entering date and hour</li>
               <li>Webpage ensuring that user’s system reaches to our internet page.</li>
               <li>Webpages called over our webpage by user’s system.</li>
            </ol>
            <p>
               Data is also recorded on Logfile of our system in the same way. This data cannot be recorded together with other personal data of a
               user.
            </p>
            <p><b>5. Legal base of data processing</b></p>
            <p>
               Legal base to temporarirly record data and Logfile, is Paragraph 1lit.f of Article 6 of EU-General Data Protection Legislation (DSGVO)
               .
            </p>
            <p><b>6. The purpose of data processing</b></p>
            <p>
               It is required to record IP-Address temporarily by the system for ensuring that web page is reached by user’s computer. For this
               purpose, IP-Address is recorded during a session.
            </p>
            <p>
               Registration process that is made to Logfile, is required to ensure functionality of the webpage. Except that, this data is required
               for imporving our webpage and for ensuring security of our information systems. Assessing data for marketing purpose does not take
               place in this sense.<span class="Apple-converted-space">&nbsp;</span>
            </p>
            <p>
               In line with these purposes, we have justified interest for data processing as per Paragraph 1lit.f of Article 6 of EU-General Data
               Protection Legislation (DSGVO).
            </p>
            <p><b>7. Data retention period</b></p>
            <p>
               Data is deleted when it is not needed for realizing the case requiring to collect data. In case of collecting data for preparing a
               wbpage, that data is deleted when the session is closed.
            </p>
            <p>
               In case of retention of data in Logfiles, that data is deleted at most seven days later. Retention periods extending that are possible.
               In such a case, IP-Addresses are deleted or they are alienated in a way not to restrict determination related customer.
            </p>
            <p><b>8. The option of objection and disposing</b></p>
            <p>
               It is obligatory to take date for preparing a wbpage and to record that data in Logfile and to operate internet page. It is not
               possible for a user to object that.
            </p>
            <p><b>V. Using cookies</b></p>
            <p><b>A) The definition and the scope of data processing.</b></p>
            <p>
               Our webpage uses cookies. These cookies are text filed recorded on computer system of a user by internet browser. In case that a user
               calls a webpage, a cookie is recorded on operating system of that user. That cookie consists of a characteristics squence (string)
               ensuring net identification of a borwser when that webpage is called again.
            </p>
            <p>
               We use cookies to make our webpage a user-friendly one. Some elements of our internet pages are required to be identified also afre
               changing called browser or page.
            </p>
            <p>Below indicated data is recorded and transferred in cookies:</p>
            <ol>
               <li>Language settings</li>
               <li>THe products at shopping cart</li>
               <li>Log-in information</li>
            </ol>
            <p>We use cookies analyzing internet surfing habit of a user.</p>
            <p>By doing so, below indicated data can also ve transfered:</p>
            <ol>
               <li>A term entered for a search</li>
               <li>Frequency of calling a page</li>
               <li>Using webpage functions</li>
            </ol>
            <p>
               Data of a user collected in this way is identified with nicknames with technical measures (pseudonymised). Fort his reason, it would
               not be possible to associate date with a user making call. Data is not retained together with other data of a user.
            </p>
            <p>
               In case that our wbepage is called, the users are informed rergarding analysis purposed use of cookies with infobanner and they are
               informed about this data protection statement. In this sense, they are also informed how to prevent this with browser settings.
            </p>
            <p><b>B) Legal base of data processing</b></p>
            <p>Legal base of data processing with cookiies is Paragraph 1lit.f of Article 6 of EU-General Data Protection Legislation (DSGVO).</p>
            <p>
               In case of approval from a user is given, legal base of personal data processing iwth using cookies for analysis puporse, is Paragraph
               1lit.a of Article 6 of EU-General Data Protection Legislation (DSGVO).
            </p>
            <p><b>C) The purpose of data processing</b></p>
            <p>
               The purpose of using required cookies is technically to ease webpage us efor the users. Some functions of our internet page cannot be
               used without cookies. For this purpose, it is required to recognize a web browser after changing page.
            </p>
            <p>Use of cookies is required for following applications:</p>
            <ol>
               <li>Shopping cart</li>
               <li>Transferring/recording language settings</li>
               <li>Recording searching terms</li>
            </ol>
            <p>User data taken with required cookies technically, is not used for preparing user profiles.</p>
            <p>
               Analysis cookies are used for improving quality of our webpage and contents. Thanks to analysis cookies, we learn how the webpage is
               used and thus, we can improve our offer continuosly.<span class="Apple-converted-space">&nbsp;</span>
            </p>
            <p>
               For these purposes, we have justified interest about processing personal data as per Paragraph 1lit.f of Article 6 of EU-General Data
               Protection Legislation (DSGVO).
            </p>
            <p><b>E) Retention period, objection and disposal option</b></p>
            <p>
               Cookies are recorded on user’s computer and transferred to our page. Fort his areason, you have full control about using cookies as a
               user. When your internet browser settings are changes, you can deactivate cookies transfer or you can limit it. Previously recorded
               cookies can always be deleted. It can also be made automatically. In case that cookies are deactivated for our webpage, it will not be
               possible to use all functions of our wbpage with full scope.<span class="Apple-converted-space">&nbsp;</span>
            </p>
            <p>Transferring flash cookies will be possible with changing browser settings as well as changing FLash Player settings.</p>
            <p>
               <b>IV. Registration<span class="Apple-converted-space">&nbsp;</span></b>
            </p>
            <p><b>1. The definition and scope of data processing</b></p>
            <p>
               On our website, we enable our users to register by entering their personal data. The data is entered in input mask and transferred to
               us and saved. No data is passed on to third parties. The following data is collected as part of a registration process:
            </p>
            <p><b>Name, Address, Telephone Number, E-mail</b></p>
            <p>The following data is taken into a memory in registratin</p>
            <ol>
               <li>User’s IP Address<span class="Apple-converted-space">&nbsp;</span></li>
               <li>Registration date and hour</li>
            </ol>
            <p>Within the scope of registrationi user’s approval is taken to process that data.</p>
            <p><b>2. Legal basis for data processing</b></p>
            <p>
               In case of having approval of a user, legal basis of data processing is Paragraph 1lit.a of Article 6 of EU-General Data Protection
               Legislation (DSGVO).
            </p>
            <p>
               If perfoming a contract where a user is a party, requires registration or applying measures for a contract required registration,
               additional legal basis for data processing is Paragraph 1lit.b of Article 6 of EU-General Data Protection Legislation (DSGVO).
            </p>
            <p><b>3. The purpose of data processing</b></p>
            <p>
               Registration of a user is required for making some specific contents and services available on our webpage. Registration is and
               interest for a business relation. Registered company introduces itself with registration transaction.
            </p>
            <p><b>4.Retention period</b></p>
            <p>Data is deleted when it is not needed for collection purpose.</p>
            <p>In case of deleting or changing registration from our internet page, data taken in registration is also deleted.</p>
            <p><b>5. Objection and disposal option</b></p>
            <p>You always have right to delete registration as a user. You can always change your recorded data.</p>
            <p><b>VII. E-Mail Communication</b></p>
            <p><b>1. The definition and scope of data processing</b></p>
            <p>
               It is possible communicate with e-mail adres provided for use. In such case, personal data of users transferred by e-mail, is recorded.
            </p>
            <p>In this sense, data transfer to third persons is not perfomred. Data is used to perform communication.</p>
            <p><b>2. Legal basis of data procession</b></p>
            <p>
               Legal basis of data processing transferred by an e-mail, is Paragraph 1lit.f of Article 6 of EU-General Data Protection Legislation
               (DSGVO). In case that e-mail communication is occurred by signing a contract, additional legal basis for data processing is Paragraph
               1lit.b of Article 6 of EU-General Data Protection Legislation (DSGVO).
            </p>
            <p><b>3. The purpose of data procesing</b></p>
            <p>In case of a communication with e-mail, justified interest is at stake for data processing.</p>
            <p><b>4. Retention period</b></p>
            <p>
               Data is deleted when it is not needed for collection purpose. Personal data transferred with e-mail, is deleted when conversation with
               a user is over. Communication is considered as it is over when it is understood that related subject is resolved.
            </p>
            <p>Personal data obtained additional during transfer, is deleted at most seven days later.</p>
            <p><b>5. Objection and disposal option</b></p>
            <p>
               User can always withdraw his/her approval for personal data processing. In case that a user contacts with us over e-mail, he/she can
               always object about recording personal data. In such a case, it is no longer possible to continue communication.
            </p>

            <p>
               Requests can be made to withdraw approval and data retention to&nbsp;<a :href="'mailto:' + $constants.brand.email">{{
                  $constants.brand.email
               }}</a
               >&nbsp;address over e-mail.
            </p>
            <p>All personal data recorded regarding a communication, is deleted.</p>
            <p><b>VII. Web analysis over Matomo (previously PIWIK)</b></p>
            <p><b>1. The scope of personal data processing</b></p>
            <p>
               We use Open-Source-Software-Tool Matomo (previously PIWIK) on our webpage for analyzing internet surfing. This software places a cookie
               on user’s computer (see bove about cookies). In case that our webpace is called, the following data is recorded:
            </p>
            <ol>
               <li>Two bites of IP-Address of the system of a user making call</li>
               <li>Called webpage</li>
               <li>Webpage ensuring to reach webpage called by a user (Referrer)</li>
               <li>Sub-pages called by a called weppage</li>
               <li>Staying period on a webpage</li>
               <li>Calling frequency of a webpage</li>
            </ol>
            <p>
               The software is operated only on our webpage’s server. Personal data of a user is recorded only that place. Data is not given to third
               persons.
            </p>
            <p>
               The software has been adjusted to record IP-Address with shortages; it means that 2 bytes of IP-Address is masked (Exp.
               192.168.xxx.xxx). Thanks to this, abbreviated IP-Address cannot be assosicated with a computer making call.
            </p>
            <p>
               A visit to be made by you to our webpage is recorded with Matoma Web Analysis. Click to (https://matomo.org/docs/privacy/) for not
               recording your visit.
            </p>
            <p><b>2. Legal basis for your personal data processing</b></p>
            <p>Legal basis for personal data processing is Paragraph 1lit.f of Article 6 of EU-General Data Protection Legislation (DSGVO).</p>
            <p><b>3. The purpose of data processing</b></p>
            <p>
               Personal data processing is made for ensuring examination of internet surfing behaviour of a user. Thanks to data obtained from
               performed assessments, we can collect information about using our webpage components individually. This is to ensure continuous
               improvement of our webpage and using easiness of our webpage. As per Paragraph 1lit.f of Article 6 of EU-General Data Protection
               Legislation (DSGVO), our justified interest about data processing takes place within this aimed purposes. With anonymization of
               IP-Addresses, interest of a user about data protection is sufficiently taken into consideration.
            </p>
            <p>
               <b>4.<span class="Apple-converted-space">&nbsp;&nbsp;</span>Retention period</b>
            </p>
            <p>
               Data is immediately deleted in case that it is no longer needed for our registration purposes. Data is deleted seven days later. See
               for detailed information:
            </p>
            <p>(https://matomo.org/docs/privacy/),</p>
            <p><b>5. Objection and disposal option</b></p>
            <p>
               Cookies are recorded on user’s computer and transferred to our page. For this areason, you have full control about using cookies as a
               user. When your internet browser settings are changes, you can deactivate cookies transfer or you can limit it. Previously recorded
               cookies can always be deleted. It can also be made automatically. In case that cookies are deactivated for our webpage, it will not be
               possible to use all functions of our webpage with full scope.<span class="Apple-converted-space">&nbsp;</span>
            </p>
            <p>You can reach information regarding special settings about Matomo Software:</p>
            <p><a href="https://matomo.org/docs/privacy/">https://matomo.org/docs/privacy/</a>.</p>
            <p><b>IX. The rights of related person</b></p>
            <p>
               In case that yoru personal data is processed, you are considered as a related person as per DSGVO legislations and you will have
               following rights against the officer:
            </p>
            <p><b>1. Right to demand information</b></p>
            <p>You can demand information from the Officer regarding whether your personal data is processed.</p>
            <p>In case of such a data processing, you can demand information from the Officer about following subjects:</p>
            <ol>
               <li>The purposes requiring personal data processing;</li>
               <li>The categories of processed personal data;</li>
               <li>The categories of a receiver or receivers those your personal data is prosented or to be prosented;</li>
               <li>
                  The period planned regarding retention of your personal data and crtieria related with retention period in case that tangible
                  information is not possible about this subject;
               </li>
               <li>
                  The right related with correcting or deleting your personal data, the right to limit data processing by the Officer or the right to
                  object against that processing;
               </li>
               <li>Availablity of the right to object against auditing institution;</li>
               <li>All available information related with the origin of data in case that personal data is received from the related person</li>
               <li>
                  As per Paragraph 1 and 4 of Article 22 of DSGVO Legislation, availability to take automated decision including Profiling and – at
                  least in such cases- important information about involved logic and access area and effects of such data procssing on the related
                  person.<span class="Apple-converted-space">&nbsp;</span>
               </li>
            </ol>
            <p>
               You have right to demand information regarding whether your personal data is transferred to a third country or an international
               organziation. In this sense, you have right to demand information about that transfer as per Article 46 of DSGVO Legislation about
               suitable guarantees.
            </p>
            <p><b>2. Right to correct</b></p>
            <p>In case that your personal data processed is wrong or defective, you have right to demand correcting that data and/or completing.</p>
            <p><b>3. Right to limit data processing</b></p>
            <p>Under the following conditions, you are right to demand limiting personal data processing:</p>
            <ol>
               <li>
                  In case that you object for accuracy of your personal data as much as the period that the Officer is allowed to control accuracy of
                  personal data;
               </li>
               <li>
                  In case that<span class="Apple-converted-space">&nbsp;&nbsp;</span>data processing is made illegaly and you object deleting your
                  personal data and you demand to limit using your personal data instead;
               </li>
               <li>
                  In case that the Officer does not need personal data for processing purpose but you need it for using, practicing or defending your
                  legal rights;
               </li>
               <li>
                  In case that you object against data processing as per Paragraph 1 of Article 21 of DSGVO Legislation and that it could not be
                  determined whether your reasons are more justified than the ones of the Officer;
               </li>
            </ol>
            <p>
               In case that your personal data is limited, -by considering its retention-, your personal data can be processed only with your approval
               or for using, practicing or defending legal rights or for protecting rights of another natural and legal person or for protecting
               public interests of the country or the Union.
            </p>
            <p>If data processing is limited as per above given conditions, you will be informed by the Officer before removing limitation.</p>
            <p><b>4. Right to delete</b></p>
            <p><b>A) Deleting obligation</b></p>
            <p>
               You demand from the Officer to delete your related personal data and the Officer is obliged to delete it immediately in case of
               occurrance of one of the following reasons.
            </p>
            <ol>
               <li>When your personal data is not needed for processing anymore for colletion purpose or for processing in other ways.</li>
               <li>
                  When there is no other legal basis for data processing and when you withdraw your approval for data processing as per Paragraph
                  1lit.a of Article 6 or Paragraph 2lit.a of Article 9 of DSGVO Legiaslation.
               </li>
               <li>
                  When you objects data processing as per Paragraph 1 of Article 22 of DSGVO Legislation and when there is no justified reasons
                  prioritized for data processing or when you object for data processing as per Paragraph 2 of Article 21 of DSGVO Legislation.
               </li>
               <li>When your personal data is processed illegally.</li>
               <li>
                  When your personal data is deleted, and it is required for performing a legal obligation as per Union Law or Member State Law which
                  the Officer is subjected.
               </li>
               <li>
                  WHen your personal data is collected related with the services offered to information socity as per Paragraph 1 of Article 8 of
                  DSGVO Legislation.
               </li>
            </ol>
            <p><b>B) Providing information to third parties</b></p>
            <p>
               In case that the Officer opens related personal data to public and is responsible about deleting data as per Paragraph 1 of Article 7
               of DSGVO Legislation and by considering available technology and practicing expenses, the Officers processing personal data takes all
               required measures (including technical ones) with the purpose of informing about all links or copies about personal data or
               replications of them that you request to delete.
            </p>
            <p><b>C) Exceptions</b></p>
            <p>Right to delete cannot be a matter in case of availability of the following conditions;</p>
            <ol>
               <li>When data processing is required for freedom of stating view and for using information right,</li>
               <li>
                  When it is required to make data processing for performing legal obligations considered necessary for data processing as per Union
                  Lw or Member State Law or when data processing is required for performing a tas about using public authority transferred to the
                  Officer or for performing a tas where there is a public interest,
               </li>
               <li>
                  Due to public interest in public health field as per Paragraph 3 of Article 9 and Paragraph 2lit.h and i of Article 9 of DSGVO
                  Legislation,
               </li>
               <li>
                  In case that it is required to make data processing for scientific and historical researches or statistical purposes or archive
                  purposes where there is public interest as per Paragraph 1 or Article 89 of DSGVO Legislation or
               </li>
               <li>In case that it is required for using, practicing or defending legal rights.</li>
            </ol>
            <p><b>5. Right of being informed</b></p>
            <p>
               If you demand right from the Officer about correcting, deleting or limiting data processing, the related Officer is obliged to inform
               all receivers of related personal data and to inform regarding data correction or deletion or limiting data processing ; however, in
               case that providing information is impossible or it is highly burdensome, this condition is not valid.
            </p>
            <p>The Offier is obliged to inform you about these receivers.</p>
            <p><b>6. Right of data transmissibility</b></p>
            <p>
               You have right to receive youyr personal data that you give to the Officer in a format that is smooth, common and readable by a
               machine. You have right to transfer your data to another Officer without being prevented by the Officer which this data is presented.
               For doing this;
            </p>
            <ul>
               <li>
                  Data processin should be based on an approval as per Paragraph 2lit.a of Article 9 of DSGVO Legislation or Paragraph 1lit.a of
                  Article 6 of DSGVO LEgislation and should be based on a contract as per Paragraph 1lit.b of Article 6 of DSGVO Legislation.
               </li>
            </ul>
            <ul>
               <li>Data processing should be made by an automated method.</li>
            </ul>
            <p>
               When it is technicall possible, by usingthis right, you also have right to take your personal date directly from one Officer and
               transfer to another Offier. It is required that freedom and rights of another persons should not be affected negatively due to this
               process.
            </p>
            <p>
               For atask performed by using public authority that is transferred to an Officer or which is a matter of public interest, data transfer
               right is not available for required personal data processing.
            </p>
            <p><b>7. Right of opposition</b></p>
            <p>
               You always have right of opposition for personal data processing related with you realized as per Paragraph 1lit.e or f of Article 6 of
               DSGVO Legislation due to reasons sourced by your special condition; however, it is also valid for Profiling about this legislation.
            </p>
            <p>
               As long as the Officer does not state his/her own interst and protective reasons for data processing (more important one in terms of
               rights and freedoms) or as long as data processing is not required for using , practicing or defendnig legal rights, the Officer does
               not process your personal data anymore.
            </p>
            <p>
               If your personal data is directly used for advertisement purposes, you can always make opposition about your personal data procsssing
               to stop thins type of advertisements; this condition is valid also for Prifiling if it is directly related with advertisements.
            </p>
            <p>
               If you object to data processing in relation to the use for direct advertising purpose, this personal data can no longer be used for
               these purposes.
            </p>
            <p>
               In the context of using the services of information society – without taking 2002/58 / EC Directive into account the – you have the
               possibility to exercise your right of objection by an automated method using technical specifications.<span
                  class="Apple-converted-space"
                  >&nbsp;</span
               >
            </p>
            <p><b>8. Withdrawing approval statement related with data protection law</b></p>
            <p>
               You always have the right to withdraw your approval statement regarding data protection law. With withdrawal of approval, legal
               validity of data transactions made until the revocation of approval is not affected by this.
            </p>
            <p><b>9. Taking decision automated in exceptions, including Profiling</b></p>
            <p>
               You have the right not to be subjected to a decision solely based on automated data processing – including Profiling – which would have
               legal effects on you or similarly affect you adversely. This condition is not valid in the following cases:
            </p>
            <p>1. If the decision is necessary for the conclusion or performance of a contract between you or the Officer,</p>
            <p>
               2.If the decision has been adopted due to the legal legislation of the Union or Member States to which the Officer is subjected and
               these legal legislations contain necessary measures to protect the rights and freedoms of the related person and the rightful
               interests, or
            </p>
            <p>3. If the decision was made with your final approval.</p>
            <p>
               Actually these decisions cannot be based on special categories of personal data as per Paragraph 1 of Article 9 of DSGVO Legislation as
               long as appropriate measures are not taken for protecting your justified intersts and rights and freedoms and as long as Paragraph
               2lit.a or g of DSGVO Legislation is not valid.
            </p>
            <p>
               Pursuant to the cases specified in (1) and (3), the Officer takes the necessary measures to protect your rights and freedoms and your
               justified interests, and amongst these rights, a person on the Officer part has right to intervene, to reveal his opinion and to
               opposite to the decision.
            </p>
            <p><b>10. The right to object to auditing institution</b></p>
            <p>
               In case that you consdier that your personal data processing violates DSGVO Legislation, without harming any right to apply to judicial
               legal opposition ways or ways regarding other administrative<span class="Apple-converted-space">&nbsp;&nbsp;</span>law, you have right
               to opposition to an auditing institution; you have right especially to make opposition in your country, where your work place is
               located or where mentioned violation happens.
            </p>
            <p>
               The auditing body to which an appeal is made, informs the appellant about the situation and the result and about legal remedies in
               accordance with Article 78 of DSGVO Regulation.
            </p>
            <span style="height: 100px; display: block"></span>
         </div>
      </div>
   </div>
</template>

<script>
export default {
   name: "PrivacyPolicy",
   methods: {
      handlerReplacer(str, char) {
         return str.replace(char, " ");
      },
   },
};
</script>

<style lang="scss" scoped></style>
