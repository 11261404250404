<template>
   <div class="transfer-checkout-basket-wrapper gts-box with-border">
      <div class="transfer-checkout-basket-inner">
         <div class="transfer-checkout-basket-header-wrapper">
            <div class="transfer-checkout-basket-header">
               <div class="transfer-checkout-basket-title">
                  {{ $t("checkoutBasket_titleBasket") }}
               </div>
               <div class="transfer-checkout-basket-show-icon">
                  <!-- <i id="toggle-password" class="gts-icon icon-eye"></i> -->
               </div>
            </div>
         </div>
         <div v-if="!Object.keys(transfers).length" class="data-mask">
            <div class="masked shadow-basket"></div>
         </div>
         <div class="transfer-checkout-basket-transfers">
            <div class="transfer-checkout-route" v-for="(routeTransfers, routeIndex) in transfers" :key="routeIndex">
               <div v-for="(transferItem, transferIndex) in routeTransfers" :key="transferIndex">
                  <div class="transfer-checkout-basket-card">
                     <div class="transfer-checkout-basket-transfer-info">
                        <div class="pickupDate">
                           {{ getPickupDate(transferItem) }} <span v-if="transferItem.flightNumber">{{ "(" + transferItem.flightNumber + ")" }}</span>
                        </div>
                        {{ transferItem.vehicle.type | ETrfVehicleType }}<br />
                        {{ transferItem.vehicle.info }} {{ $t("transfer_phOrSimilar") }}
                     </div>
                     <div class="transfer-checkout-basket-transfer-price">
                        <br />
                        {{ transferItem.price.totalFare | price(transferItem.price.currency) }}
                     </div>
                  </div>
               </div>
            </div>
         </div>
         <div class="checkout-basket-total-price">
            <div class="total-price-title">
               <span>{{ $t("transferCheckout_titleGrandTotal") }}</span>
            </div>
            <div class="total-price-info">
               <div class="div data-mask" v-if="!Object.keys(transfers).length">
                  <div class="masked"></div>
               </div>
               <span class="total-price">{{ totalPrice.grandTotal | price(totalPrice.currency) }}</span>
            </div>
         </div>
      </div>
      <!-- <div class="transfer-checkout-basket-outer">
         <div class="transfer-checkout-footer-icon">
            <i class="gts-icon icon-info-circle icon-sm"></i>
         </div>
         <div class="transfer-checkout-footer-info">As a result of this process 2 TNR(S) will be created</div>
      </div> -->
   </div>
</template>

<script>
import moment from "moment";

export default {
   name: "TransferCheckoutBasket",
   props: {
      transfers: Object,
      totalPrice: Object,
   },
   methods: {
      getPickupDate(transfer) {
         var date = moment(transfer.pickupDateTime);
         return date.format("DD.") + this.$i18n.t("common_" + date.format("MMM").toLowerCase()) + date.format(".YYYY - HH:mm");
      },
   },
};
</script>

<style lang="scss" scoped>
.transfer-checkout-basket-wrapper {
   padding: 0 !important;
   overflow: hidden;
   position: sticky;
   top: 1.5rem;

   .transfer-checkout-basket-inner {
      .transfer-checkout-basket-header-wrapper {
         margin-top: 0.5rem;
         padding: 1rem;
         .transfer-checkout-basket-header {
            display: flex;
            justify-content: space-between;
            border-bottom: 1px solid #ccc;
            .transfer-checkout-basket-title {
               font-size: 1.2rem;
               font-weight: 500;
            }
         }
      }
      .shadow-basket {
         border-radius: 0;
         height: 100px;
      }
      .transfer-checkout-basket-transfers {
         padding: 1rem;
         .transfer-checkout-route {
            margin-bottom: 1rem;
            .transfer-checkout-basket-card {
               display: flex;
               border: 1px solid #ccc;
               background-color: #cccccc50;
               border-radius: 1rem;
               padding: 0.5rem;
               .transfer-checkout-basket-transfer-info {
                  margin-right: 0.5rem;
                  font-size: 0.9rem;
                  width: 70%;
                  .pickupDate {
                     font-weight: 600;
                  }
               }
               .transfer-checkout-basket-transfer-price {
                  width: 30%;
                  font-size: 1.1rem;
                  font-weight: 600;
               }
            }
         }
      }
      .checkout-basket-total-price {
         color: #3c67b2;
         text-align: end;
         padding: 1rem;

         .total-price-title {
         }

         .total-price-info {
            font-size: 1.7rem;
            font-weight: 800;
         }
      }
   }

   .transfer-checkout-basket-outer {
      background-color: #3d7ecc;
      color: white;
      padding: 1rem;
      display: flex;
      .transfer-checkout-footer-icon {
         margin-right: 1rem;
      }
   }
}
</style>
