<template>
   <div class="room-info-wrapper room-info-item">
      <div class="room-info-inner">
         <div class="room-card-wrapper">
            <div class="room-card-inner">
               <span class="room-card-badge"></span>
               <div class="room-card-section room-informations">
                  <div class="room-information-item">
                     <span>{{ $t("hotelCheckout_lblRoomName") }}</span>
                     <span class="room-name">{{ room.roomName }}</span>
                  </div>
                  <div class="room-information-item">
                     <span>{{ $t("hotelCheckout_lblBoardType") }}</span>
                     <span class="room-board-type">{{ room.board }}</span>
                  </div>
               </div>
               <div class="room-card-section room-date">
                  <div class="room-date-content">
                     <div class="room-date-item checkin-date">
                        <div class="room-date-icon">
                           <i class="gts-icon icon-calendar-checkin"></i>
                        </div>
                        <div class="room-date-content">
                           <span>{{ $t("hotelCheckout_lblCheckin") }}</span>
                           <span class="date">{{ checkinDate | fullDateFormat }}</span>
                        </div>
                     </div>
                     <div class="room-date-item accordion-date" :class="isAccordionDateActive ? 'active' : ''">
                        <ul class="daily-date-list">
                           <li class="daily-date-list-item" v-for="(dailyDate, index) in room.dailyPrices" :key="index">
                              {{ dailyDate.date | fullDateFormat }} -
                              <strong>{{ dailyDate.price.total | price(dailyDate.price.currency) }}</strong>
                           </li>
                        </ul>
                     </div>
                     <div class="room-date-item checkout-date">
                        <div class="room-date-icon">
                           <i class="gts-icon icon-calendar-checkout"></i>
                        </div>
                        <div class="room-date-content">
                           <span>{{ $t("hotelCheckout_lblCheckout") }}</span>
                           <span class="date">{{ checkoutDate | fullDateFormat }}</span>
                        </div>
                     </div>
                  </div>
                  <div class="room-date-accordion-icon">
                     <i class="gts-icon icon-arrow-rounded-down" @click="handlerAccordionDate" v-if="!isAccordionDateActive"></i>
                     <i class="gts-icon icon-arrow-rounded-up" @click="handlerAccordionDate" v-else></i>
                  </div>
               </div>
               <div class="room-card-section room-price">
                  <div class="room-price-info">
                     <span>{{ $t("hotelCheckout_lblRoomTotal") }}</span>
                     <span class="price">{{ room.price.total | price(room.price.currency) }}</span>
                  </div>
               </div>
            </div>
            <div class="room-card-footer">
               <div class="room-card-footer-inner">
                  <i class="gts-icon icon-information"></i>
                  <p>{{ handlerRoomRestrictionInfo(room) }}</p>
               </div>
            </div>
         </div>
         <div
            class="passenger-informations-header-wrapper"
            v-if="searchedRoom[0].guests.length == paxDatatest?.guests?.length && searchRoomGuestCount == cookieRoomGuestCount">
            <div class="autofill-wrapper" v-if="ShowAutoFill">
               <span class="autofill-highlighter"> {{ $t("flight_fillPaxRowsTooltipHighlighter") }}</span>
               <button id="fill-form" @click="handlerShowModal">
                  {{ $t("flight_fillPaxRowsTooltip") }}
               </button>
               <button id="clear-form" @click="handlerClearForm" v-if="false">
                  <i class="gts-icon icon-hotel-empty-basket icon-sm"></i>
               </button>
            </div>
         </div>
         <UIModal v-show="showModal" :noBorder="true" :hasSlot="true" @close-modal="showModal = false">
            <div class="pax-data-form">
               <div class="transfer-manuel-request-info-wrapper col-12">
                  <div class="pax-data-form__title">
                     <i class="gts-icon icon-popup-cursor"></i>
                     <h3 class="txt-black">{{ $t("flight_fillPaxRowsTtitle") }}</h3>
                  </div>
                  <div class="pax-data-form__content">
                     <p>{{ $t("flight_fillPaxRowsContent") }}</p>
                     <div class="pax-data-form__info-wrapper">
                        <div class="pax-data-form__infos --side-bg"></div>
                        <div class="pax-data-form__pax-info" v-for="(paxInfo, index) in paxDatatest.guests" :key="paxInfo + index">
                           <span class="label-title">{{ $t("flight_paxInfo") }}</span>
                           <div class="sub-content">
                              <span class="label-title">{{ $t("flight_paxNameSurname") }}</span>
                              <span> {{ paxInfo.name }} - {{ paxInfo.surname }} </span>
                           </div>
                           <div class="sub-content">
                              <span class="label-title">{{ $t("flight_paxBirthDate") }}</span>
                              <span>
                                 {{ paxInfo.birthdate | FormatterDate }}
                              </span>
                           </div>
                           <div class="sub-content">
                              <span class="label-title">Cinsiyet</span>
                              <span>
                                 {{ paxInfo.gender == 1 ? "ERKEK" : "KADIN" }}
                              </span>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div class="pax-data-form__cta">
                     <UIButton
                        rounded
                        className="accent-primary_outline"
                        :text="$t('flight_fillPaxRowsCancelBtn')"
                        @click.native="showModal = false" />
                     <UIButton rounded className="accent-primary" :text="$t('flight_fillPaxRows')" @click.native="fillPaxInfo" />
                  </div>
               </div>
            </div>
         </UIModal>
         <div class="room-guest-wrapper col-12">
            <RoomGuest :roomIndex="roomIndex" :customerNationality="customerNationality" :paxes="room.guests" :contactModel="contactModel" />
         </div>
         <AddonCard
            @startupGetAddons="startupGetAddons"
            @handleUpdateAddons="handleUpdateAddons"
            v-for="(addOns, index) in room.addOns"
            :key="index"
            :addOn="addOns"
            :guest="room.guests"
            :roomIdentifier="room.roomIdentifier" />
      </div>
   </div>
</template>

<script>
import RoomGuest from "@/components/structural/hotel/checkout/RoomGuest.vue";
import { setCookie, getCookie } from "@/utils.js";
import UIModal from "@/components/unitary/form2/UIModal.vue";
import UIButton from "@/components/unitary/Button.vue";
import AddonCard from "@/components/utils/AddonCard.vue";

export default {
   name: "RoomInfoCard",
   components: {
      RoomGuest,
      UIModal,
      UIButton,
      AddonCard,
   },
   props: {
      searchedRoom: { Array },
      roomIndex: { type: Number },
      contactModel: { type: Object },
      customerNationality: { type: String },
      room: { Object },
      lastBookingDate: { type: String },
      checkinDate: { type: String },
      checkoutDate: { type: String },
   },
   data() {
      return {
         isAccordionDateActive: false,
         showModal: false,
         selectedAddOns: {},
         paxDatatest: { Object },
         paxDataArray: [],
         cookieAdultCount: null,
         cookieChildCount: null,
         cookieRoomGuestCount: null,
         searchAdultCount: null,
         searchChildCount: null,
         searchRoomGuestCount: null,
      };
   },
   computed: {
      ShowAutoFill() {
         if (getCookie("rooms") !== null) {
            if (this.paxDataArray.length === 1 && this.searchedRoom.length === 1) {
               return true;
            } else {
               return false;
            }
         } else {
            return false;
         }
      },
   },
   created() {
      this.getCookieData();
   },
   methods: {
      startupGetAddons(addons) {
         this.selectedAddOns = addons;
         this.$emit("startupGetAddons", this.selectedAddOns);
      },
      handleUpdateAddons(addons) {
         this.selectedAddOns = addons;
         this.$emit("handleUpdateAddons", this.selectedAddOns);
      },
      fillPaxInfo() {
         this.room.guests = this.paxDatatest.guests;
         this.showModal = false;
      },
      getCookieData() {
         const dat = JSON.parse(getCookie("rooms"));

         dat?.forEach((room) => {
            room.guests.forEach((guest) => {
               if (guest.type === 1) {
                  // If type is 1, multiply by 100 and add to adultCount
                  this.cookieAdultCount += guest.type * 100;
               } else if (guest.type === 2) {
                  // If type is 2, multiply by 1 and add to childCount
                  this.cookieChildCount += guest.type * 1;
               }
            });
         });

         this.cookieRoomGuestCount = this.cookieAdultCount + this.cookieChildCount;

         this.searchedRoom[0].guests.forEach((guest) => {
            if (guest.type === 1) {
               // If type is 1, multiply by 100 and add to adultCount
               this.searchAdultCount += guest.type * 100;
            } else if (guest.type === 2) {
               // If type is 2, multiply by 1 and add to childCount
               this.searchChildCount += guest.type * 1;
            }
         });

         this.searchRoomGuestCount = this.searchAdultCount + this.searchChildCount;

         dat?.forEach((paxData) => {
            this.paxDatatest = paxData;
            this.paxDataArray.push(paxData);
         });
      },
      handlerClearForm() {
         setCookie("rooms", "");
         this.paxDatatest = {};
         this.$store.state.hotel.cookieData.rooms = null;
      },
      handlerShowModal() {
         setTimeout(() => {
            document.querySelector(" .autofill-highlighter").classList.add("d-none");
         });
         this.getCookieData();
         this.showModal = true;
      },
      handlerAccordionDate() {
         var self = this;
         self.isAccordionDateActive = !self.isAccordionDateActive;
      },
      handlerRoomRestrictionInfo(room) {
         var fmt = this.$i18n.t(`hotelCheckoutPage_roomRestrictionInfo`);

         var adultCount = 0;
         var childCount = 0;

         room.guests.forEach((data) => {
            if (data.type == 1) adultCount++;
            else if (data.type == 2) childCount++;
         });

         if (adultCount > 0) {
            fmt = fmt.replace("{adultCount}", adultCount);
            fmt = fmt.replace("{adultFmt}", " " + this.$i18n.t(`common_adult`)).replace("{adultPluralFmt}", this.$i18n.t(`common_adultPluralExt`));
         }

         if (childCount > 0) {
            fmt = fmt.replace("{and}", " " + this.$i18n.t(`common_and`));
            fmt = fmt.replace("{childCount}", " " + childCount);
            fmt = fmt
               .replace("{childFmt}", " " + this.$i18n.t(`common_child`).toLowerCase())
               .replace("{childPluralFmt}", this.$i18n.t(`common_childPluralExt`));
         } else {
            fmt = fmt.replace("{and}", "");
            fmt = fmt.replace("{childCount}", "");
            fmt = fmt.replace("{childFmt}", "").replace("{childPluralFmt}", "");
         }

         return fmt;
      },
   },
   watch: {
      ShowAutoFill: {
         handler: function (status) {
            if (status) {
               setTimeout(() => {
                  document.querySelector(".autofill-highlighter")?.classList.add("d-none");
               }, 8000);
            }
         },
         immediate: true,
      },
   },
};
</script>

<style lang="scss" scoped>
.room-info-wrapper {
   .room-info-inner {
      .room-card-wrapper {
         border: 1px solid #cccccc;
         border-radius: 8px;
         margin-bottom: 1.5rem;
         overflow: hidden;

         .room-card-inner {
            width: 100%;
            display: grid;
            grid-template-columns: 1fr 1.7fr 1fr;
            width: 100%;
            padding: 16px 32px;
            border-bottom: 1px solid #cccccc;
            position: relative;

            .room-card-badge {
               position: absolute;
               $distance: 1rem;
               left: 0;
               top: $distance;
               bottom: $distance;
               margin: auto;
               width: 5px;
               background: #e0e7f0;
               border-radius: 0.5rem;
               border-top-left-radius: 0;
               border-bottom-left-radius: 0;
            }

            .room-card-section {
               &.room-informations {
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  // border-right: 1px dotted #cccccc;

                  .room-information-item {
                     display: flex;
                     flex-direction: column;
                     margin-bottom: 16px;

                     &:last-child {
                        margin-bottom: 0;
                     }

                     .room-name,
                     .room-board-type {
                        font-size: 16px;
                        font-weight: 700;
                        color: #363940;
                     }
                     .room-name {
                     }
                     .room-board-type {
                     }
                  }
               }
               &.room-date {
                  display: flex;
                  height: 100%;
                  padding: 0 2rem;
                  border-right: 1px solid #cccccc;
                  border-left: 1px solid #cccccc;
                  // padding-right: 2rem;

                  .room-date-item {
                     display: flex;
                     align-items: center;
                     margin-bottom: 16px;

                     &:last-child {
                        margin-bottom: 0;
                     }
                     .room-date-icon {
                        margin-right: 16px;
                     }
                     .room-date-content {
                        display: flex;
                        flex-direction: column;
                        width: 100%;
                        .date {
                           font-size: 16px;
                           font-weight: 700;
                           color: #363940;
                        }
                     }
                     &.checkin-date {
                     }
                     &.checkout-date {
                     }
                     &.accordion-date {
                        width: 100%;
                        max-height: 0;
                        overflow: hidden;
                        transition: max-height 0.5s ease-out;

                        &.active {
                           max-height: 500px;
                           transition: max-height 0.5s ease-in;
                        }

                        & > ul.daily-date-list {
                           padding: 8px 16px;
                           margin: 0;
                           background-color: #f5f5f5;
                           border: 1px solid #e6e6e6;
                           border-radius: 8px;
                           list-style: none;

                           & > li.daily-date-list-item {
                              margin-bottom: 8px;
                              font-size: 14px;

                              &:last-child {
                                 margin-bottom: 0;
                              }
                           }
                        }
                     }
                  }
                  .room-date-content {
                     width: 90%;
                     height: 100%;
                  }
                  .room-date-accordion-icon {
                     display: flex;
                     align-items: center;
                     justify-content: center;
                  }
               }
               &.room-price {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  .room-price-info {
                     display: flex;
                     flex-direction: column;
                     justify-content: flex-start;
                     width: max-content;
                     .price {
                        font-size: 20px;
                        font-weight: 700;
                        color: #363940;
                     }
                  }
               }
               @media (max-width: 767.98px) {
                  &.room-informations {
                     flex-direction: row;
                  }
                  &.room-date {
                     padding: 0 1rem;
                  }
                  &.room-price {
                     justify-content: flex-end;
                  }
               }
            }
         }
         @media (max-width: 767.98px) {
            .room-card-inner {
               padding: 16px 16px;
               display: block;
            }
         }
         .room-card-footer {
            background-color: #f8f8f8;
            .room-card-footer-inner {
               display: flex;
               align-items: center;
               justify-content: flex-start;
               padding: 8px 32px;
               i {
                  color: #33b8fb;
                  margin-right: 12px;
               }
               p {
                  margin: 0;
                  font-size: var(--small);
                  font-style: italic;
               }
            }
         }
      }
      .room-guest-wrapper {
         padding: 0;
         padding-top: 1.5rem;
         margin-bottom: 2rem;
         position: relative;
      }
      // .room-alert-wrapper {
      //    ::v-deep i {
      //       // width: 40px !important;
      //       margin-right: 8px;
      //       filter: invert(79%) sepia(93%) saturate(1003%) hue-rotate(338deg) brightness(100%) contrast(81%);
      //    }
      //    ::v-deep .room-alert-inner {
      //       p {
      //          color: #363940;
      //          font-size: 14px;
      //          font-weight: 700;

      //          & > span {
      //             margin-right: 8px;
      //             font-size: 20px;
      //             font-weight: 900;
      //          }
      //       }
      //    }
      // }
      .passenger-informations-header-wrapper {
         display: flex;
         justify-content: flex-end;
         align-items: center;
         margin-bottom: 1.5rem;

         .section-title {
            margin-bottom: 0 !important;
         }
         .autofill-wrapper {
            display: flex;
            justify-content: flex-end;
            // background-color: red;
            position: relative;

            @keyframes tooltip-top {
               0% {
                  transform: translateY(-10px);
               }
               100% {
                  transform: translateY(0);
               }
            }

            .autofill-highlighter {
               animation-timing-function: ease-in-out;
               animation-duration: 1s;
               animation-iteration-count: infinite;
               animation-name: tooltip-top;
               position: absolute;
               background-color: var(--accent-primary);
               bottom: 110%;
               left: 0;
               right: 0;
               margin: auto;
               width: fit-content;
               padding: 0.5rem 1.125rem;
               border-radius: 0.25rem;
               color: #fff;
               font-weight: 500;
               z-index: 999;

               &::after {
                  content: "";
                  position: absolute;
                  bottom: -5px;
                  left: 0;
                  right: 0;
                  margin: auto;
                  width: 10px;
                  height: 10px;
                  transform: rotate(45deg);
                  background-color: red;
                  background-color: var(--accent-primary);
               }
            }

            button {
               border-radius: 0.5rem;

               &#fill-form {
                  background: #0ce2a3;
                  padding: 0.5rem 1rem;
                  color: #ffffff;
                  font-weight: 600;
                  margin-right: 0.5rem;

                  &:hover {
                     background: #2a3a4d;
                  }
               }
               &#clear-form {
                  background-color: transparent !important;
                  border: 2px solid #cecece;
                  padding-left: 0.5rem;
                  padding-right: 0.5rem;

                  &:hover {
                     background-color: #2a3a4d !important;
                     border: 2px solid #2a3a4d;

                     i {
                        filter: brightness(10);
                        opacity: 1;
                     }
                  }

                  i {
                     filter: brightness(0);
                     opacity: 0.6;
                     width: 18px !important;
                  }
               }
            }
         }
      }
      .pax-data-form {
         padding: 1rem;
         width: 100%;
         &__title {
            text-align: center;
            color: #1e2023;
            font-size: var(--xlarge);
            letter-spacing: 0px;
            i {
               margin: auto;
               width: 114px !important;
               height: 79.06px !important;
            }
         }
         &__content {
            text-align: center;
            font-size: var(--small);
            color: #6d6e6f;
            letter-spacing: 0.22px;
            margin-top: 0.5rem;
         }
         &__info-wrapper {
            background: #f7f7f7 0% 0% no-repeat padding-box;
            border: 1px solid #ebebeb;
            border-radius: 16px;
            padding: 20px;
            position: relative;
            margin-top: 0.5rem;
            &::before {
               content: "";
               position: absolute;
               top: 0;
               left: 0;
               width: 16px; /* adjust as needed */
               height: 100%;
               background: #ebebeb 0% 0% no-repeat padding-box;
               border-radius: 8px 0px 0px 8px;
            }
            &.--side-bg {
               width: 16px;
               height: 100%;
               padding: 0;
            }
         }
         &__cta {
            margin-top: 1rem;
            display: flex;
            justify-content: space-evenly;
            .gts-btn {
               height: 40px;
            }
         }
         &__pax-info {
            display: flex;
            flex-direction: column;
            padding-left: 24px;
            margin-top: 20px;
            align-items: flex-start;
            border-bottom: 1px dashed #6d6e6f;
            padding-bottom: 18px;
            .label-title {
               font-weight: bold;
               font-size: var(--normal);
               color: #363636;
            }
            .sub-content {
               margin-top: 8px;
            }
            .text {
               color: #363636;
               font-size: var(--small);
               letter-spacing: 0;
            }
            &:last-child {
               border-bottom: none;
               padding-bottom: 0;
            }
         }
      }
   }
}
</style>
