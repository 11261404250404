<template>
   <div id="rent-a-car-ancillaries-container">
      <ul class="ancillaries-tabs-wrapper">
         <li v-for="(ancillary, index) in ancillariesArr" :key="index" :class="index % 2 == 0 ? 'even' : 'odd'">
            <div class="ancillary-tab-wrapper">
               <div class="ancillary-icon">
                  <i class="gts-icon icon-lg" :class="'icon-rentacar-' + ancillary.icon"></i>
               </div>
               <div class="ancillary-informations">
                  <span class="ancillary-name"> {{ ancillary.name }}</span>
                  <span class="ancillary-price">
                     {{ ancillary.pricePerDay | price }}
                     <template v-if="ShowPerDay(ancillary.type)"> / {{ $t("rentacar_lblPerDay") }} </template>
                  </span>
               </div>
               <div class="ancillary-counts" v-if="ancillary.type == 1">
                  <span class="ancillary-count-text">{{ ancillary.count }}</span>
               </div>
            </div>
         </li>
      </ul>
   </div>
</template>

<script>
import { ancillaryConversionOrderDetail } from "@/helper/rentacar.js";
export default {
   name: "RentACarOrderDetailAncillaries",
   props: {
      ancillaries: Array,
      type: {
         type: String,
         default: "Search",
      },
   },
   data() {
      return {
         ancillariesArr: [],
         request: {},
      };
   },

   methods: {
      ShowPerDay(type) {
         return type == 21 || type == 22 || type == 23 ? false : true;
      },
      IsAugmentable(type) {
         return type == 1 ? true : false;
      },
   },
   watch: {
      ancillaries: {
         handler: function () {
            const self = this;
            this.ancillariesArr = ancillaryConversionOrderDetail(self, this.ancillaries);
         },
         immediate: true,
         deep: true,
      },
   },
};
</script>

<style lang="scss" scoped>
#rent-a-car-ancillaries-container {
   ul.ancillaries-tabs-wrapper {
      display: flex;
      flex-wrap: wrap;
      li {
         flex-basis: 50%;
         padding-top: 1rem;
         padding-bottom: 1rem;

         &.even {
            padding-right: 1rem;
         }

         &.odd {
            padding-left: 1rem;
         }

         .ancillary-tab-wrapper {
            background: #ffffff 0% 0% no-repeat padding-box;
            border: 2px solid #cccccc;
            border-radius: 8px;
            opacity: 1;
            padding: 0.25rem 1rem;
            height: 72px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .ancillary-icon {
               border-right: 2px solid #ccc;
               padding-right: 1rem;
               margin-right: 1rem;
            }
            .ancillary-informations {
               display: flex;
               flex-direction: column;
               width: 100%;

               span {
                  &.ancillary-name {
                  }
                  &.ancillary-price {
                     font-weight: 500;
                  }
               }
            }
            .ancillary-counts {
               .ancillary-count-text {
                  font-size: var(--large);
                  font-weight: 500;
               }
            }
         }
      }
   }
}
</style>
