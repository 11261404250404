<template>
   <div id="rent-a-car-filters-container" class="gts-box">
      <!-- Vehicle Type -->
      <section class="filter-block vehicle-type-wrapper">
         <div class="filter-title-wrapper">
            <span class="filter-title">{{ $t("rentacar_filtersVehicleType") }}</span>
         </div>
         <ul>
            <li v-for="(type, key, index) in filters.vehicleType" :key="index">
               <UICheckBox :id="key" :name="key" v-model="type.checked" :label="key" />
            </li>
         </ul>
      </section>

      <!-- Fuel Type -->
      <section class="filter-block vehicle-type-wrapper">
         <div class="filter-title-wrapper">
            <span class="filter-title">{{ $t("rentacar_filtersFuelType") }}</span>
         </div>
         <ul>
            <li v-for="(type, key, index) in filters.fuelType" :key="index">
               <UICheckBox :id="key" :name="key" v-model="type.checked" :label="key" />
            </li>
         </ul>
      </section>

      <!-- Transmission Type -->
      <section class="filter-block vehicle-type-wrapper">
         <div class="filter-title-wrapper">
            <span class="filter-title">{{ $t("rentacar_filtersTransmissionType") }}</span>
         </div>
         <ul>
            <li v-for="(type, key, index) in filters.transmissionType" :key="index">
               <UICheckBox :id="key" :name="key" v-model="type.checked" :label="key" />
            </li>
         </ul>
      </section>
           <section class="filter-block vehicle-type-wrapper">
         <div class="filter-title-wrapper">
            <span class="filter-title">{{ $t("rentacar_passengerCapacity") }}</span>
         </div>
         <ul>
            <li v-for="(type, key, index) in filters.passengerCapacity" :key="index">
               <UICheckBox :id="key" :name="key" v-model="type.checked" :label="key" />
            </li>
         </ul>
      </section>
   </div>
</template>

<script>
import UICheckBox from "@/components/unitary/form2/UICheckBox.vue";
export default {
   name: "RentACarFilters",
   components: {
      UICheckBox,
   },
   props: {
      data: Object,
   },
   data() {
      return {
         filters: {
            vehicleType: {},
            fuelType: {},
            transmissionType: {},
            passengerCapacity: {},
         },
      };
   },
   created() {
      // console.log("created filters");
      if (this.data) {
         this.handlerGenerateFilters();
      }
   },
   methods: {
      handlerGenerateFilters() {
         Object.keys(this.data).forEach((car) => {
            // $options.filters.ERacFuelType(car.fuelType)

            // Vehicle Type Filters
            const bodyType = this.$options.filters.ERacBodyType(this.data[car].bodyType);
            this.$set(this.filters.vehicleType, bodyType, { checked: true });

            //Fuel Type Filters
            const fuelType = this.$options.filters.ERacFuelType(this.data[car].fuelType);
            this.$set(this.filters.fuelType, fuelType, { checked: true });

            //Transmission Type Filters
            const transmissionType = this.$options.filters.ERacGearType(this.data[car].gearType);
            this.$set(this.filters.transmissionType, transmissionType, { checked: true });

             //Passenger Capacity Filters
            const passengerCapacity = this.$options.filters.ERacPassengerCapacity(this.data[car].passengerCapacity);
            this.$set(this.filters.passengerCapacity, passengerCapacity, { checked: true });
           
         });
         this.$emit("filters", this.filters);
      },
   },
};
</script>

<style lang="scss" scoped>
#rent-a-car-filters-container {
   section.filter-block {
      margin-bottom: 2rem;
      .filter-title-wrapper {
         border-bottom: 1px solid #ccc;
         .filter-title {
            font-weight: 500;
         }
      }

      ul {
         li {
            margin: 0.75rem 0;
         }
      }
   }
}
</style>
