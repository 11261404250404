<template>
   <div class="gts-autocomplete-wrapper">
      <div class="gts-autocomplete-inner">
         <h1 for="input_searchLocation">{{ $t("transferSearch_lblFromDestination") }}</h1>
         <div class="search-autocomplete-input" @click="handlerActiveInput('from')">
            <input
               type="text"
               name="input_fromSearchAutocomplete"
               :placeholder="$t('transferAutocomplete_phFromDestination')"
               autocomplete="off"
               actype="fromLocation"
               class="v-required"
               v-model="fromLocation.search.name"
               @keydown="handlerKeydownEvents"
               @input="handlerChangeSearchInput" />
            <i v-if="fromLocation.clearStatus" class="gts-icon icon-close-rounded gts-animation icon-xs" @click="clearSelected('from')" />
            <div class="g-form-validation">{{ $t("common_cannotBeEmpty") }}</div>
         </div>
         <div class="search-autocomplete-list" v-if="fromLocation.isOpen" :class="toLocation.isOpen ? 'to-location' : ''">
            <div class="search-autocomplete-list-inner">
               <div class="search-autocomplete-list-section city-section cityResponse" v-if="fromLocation.results.cityResponse.length != 0">
                  <div class="search-autocomplete-list-section-icon">
                     <i class="gts-icon icon-location-point-solid" />
                  </div>
                  <div class="search-autocomplete-list-section-content">
                     <div class="search-autocomplete-list-section-content-title">
                        <span>{{ $t("transferAutocomplete_lblCity") }}</span>
                     </div>
                     <div class="search-autocomplete-list-section-content-list">
                        <div
                           class="search-autocomplete-list-section-content-list-item"
                           v-for="cityResponseItem in fromLocation.results.cityResponse"
                           :key="cityResponseItem.destinationId"
                           :class="activeDestinationId == cityResponseItem.destinationId ? 'highlighted' : ''"
                           @click="selectFromSearchResult(cityResponseItem)">
                           <span>{{ cityResponseItem.name }}</span>
                        </div>
                     </div>
                  </div>
               </div>
               <div
                  class="search-autocomplete-list-section district-section districtResponse"
                  v-if="fromLocation.results.districtResponse.length != 0">
                  <div class="search-autocomplete-list-section-icon">
                     <i class="gts-icon icon-location-point-ac" />
                  </div>
                  <div class="search-autocomplete-list-section-content">
                     <div class="search-autocomplete-list-section-content-title">
                        <span>{{ $t("transferAutocomplete_lblDistrict") }}</span>
                     </div>
                     <div class="search-autocomplete-list-section-content-list">
                        <div
                           class="search-autocomplete-list-section-content-list-item"
                           v-for="districtResponseItem in fromLocation.results.districtResponse"
                           :key="districtResponseItem.destinationId"
                           :class="activeDestinationId == districtResponseItem.destinationId ? 'highlighted' : ''"
                           @click="selectFromSearchResult(districtResponseItem)">
                           <span>{{ districtResponseItem.name }}</span>
                        </div>
                     </div>
                  </div>
               </div>
               <div class="search-autocomplete-list-section transfer-section hotelResponse" v-if="fromLocation.results.hotelResponse.length != 0">
                  <div class="search-autocomplete-list-section-icon">
                     <i class="gts-icon icon-hotel-point-ac" />
                  </div>
                  <div class="search-autocomplete-list-section-content">
                     <div class="search-autocomplete-list-section-content-title">
                        <span>{{ $t("transferAutocomplete_lblHotel") }}</span>
                     </div>
                     <div class="search-autocomplete-list-section-content-list">
                        <div
                           class="search-autocomplete-list-section-content-list-item"
                           v-for="hotelResponseItem in fromLocation.results.hotelResponse"
                           :key="hotelResponseItem.destinationId"
                           :class="activeDestinationId == hotelResponseItem.destinationId ? 'highlighted' : ''"
                           @click="selectFromSearchResult(hotelResponseItem)">
                           <span>{{ hotelResponseItem.name }}</span>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
      <!-- <div class="input-swap-container gts-animation">
            <i class="gts-icon icon-swap icon-sm" @click="handlerSwapDestinations()"></i>
        </div> -->
      <div class="gts-autocomplete-inner">
         <h1 for="input_searchLocation">{{ $t("transferSearch_lblToDestination") }}</h1>
         <div class="search-autocomplete-input" @click="handlerActiveInput('to')">
            <input
               type="text"
               name="input_fromSearchAutocomplete"
               :placeholder="$t('transferAutocomplete_phToDestination')"
               autocomplete="off"
               class="v-required"
               actype="toLocation"
               v-model="toLocation.search.name"
               @keydown="handlerKeydownEvents"
               @input="handlerChangeSearchInput" />
            <i v-if="toLocation.clearStatus" class="gts-icon icon-close-rounded gts-animation icon-xs" @click="clearSelected('to')" />
            <div class="g-form-validation">{{ $t("common_cannotBeEmpty") }}</div>
         </div>
         <div class="search-autocomplete-list" v-if="toLocation.isOpen" :class="toLocation.isOpen ? 'to-location' : ''">
            <div class="search-autocomplete-list-inner">
               <div class="search-autocomplete-list-section city-section cityResponse" v-if="toLocation.results.cityResponse.length != 0">
                  <div class="search-autocomplete-list-section-icon">
                     <i class="gts-icon icon-location-point-solid" />
                  </div>
                  <div class="search-autocomplete-list-section-content">
                     <div class="search-autocomplete-list-section-content-title">
                        <span>{{ $t("transferAutocomplete_lblCity") }}</span>
                     </div>
                     <div class="search-autocomplete-list-section-content-list">
                        <div
                           class="search-autocomplete-list-section-content-list-item"
                           v-for="cityResponseItem in toLocation.results.cityResponse"
                           :key="cityResponseItem.destinationId"
                           :class="activeDestinationId == cityResponseItem.destinationId ? 'highlighted' : ''"
                           @click="selectToSearchResult(cityResponseItem)">
                           <span>{{ cityResponseItem.name }}</span>
                        </div>
                     </div>
                  </div>
               </div>
               <div class="search-autocomplete-list-section district-section districtResponse" v-if="toLocation.results.districtResponse.length != 0">
                  <div class="search-autocomplete-list-section-icon">
                     <i class="gts-icon icon-location-point-ac" />
                  </div>
                  <div class="search-autocomplete-list-section-content">
                     <div class="search-autocomplete-list-section-content-title">
                        <span>{{ $t("transferAutocomplete_lblDistrict") }}</span>
                     </div>
                     <div class="search-autocomplete-list-section-content-list">
                        <div
                           class="search-autocomplete-list-section-content-list-item"
                           v-for="districtResponseItem in toLocation.results.districtResponse"
                           :key="districtResponseItem.destinationId"
                           :class="activeDestinationId == districtResponseItem.destinationId ? 'highlighted' : ''"
                           @click="selectToSearchResult(districtResponseItem)">
                           <span>{{ districtResponseItem.name }}</span>
                        </div>
                     </div>
                  </div>
               </div>
               <div class="search-autocomplete-list-section transfer-section hotelResponse" v-if="toLocation.results.hotelResponse.length != 0">
                  <div class="search-autocomplete-list-section-icon">
                     <i class="gts-icon icon-hotel-point-ac" />
                  </div>
                  <div class="search-autocomplete-list-section-content">
                     <div class="search-autocomplete-list-section-content-title">
                        <span>{{ $t("transferAutocomplete_lblHotel") }}</span>
                     </div>
                     <div class="search-autocomplete-list-section-content-list">
                        <div
                           class="search-autocomplete-list-section-content-list-item"
                           v-for="hotelResponseItem in toLocation.results.hotelResponse"
                           :key="hotelResponseItem.destinationId"
                           :class="activeDestinationId == hotelResponseItem.destinationId ? 'highlighted' : ''"
                           @click="selectToSearchResult(hotelResponseItem)">
                           <span>{{ hotelResponseItem.name }}</span>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>
import { transferService } from "@/service/index.js";
import debounce from "lodash.debounce";
export default {
   name: "SearchAutocomplete",
   props: {
      isFormValid: Boolean,
   },
   data() {
      return {
         activeDestinationId: null,
         fromLocation: {
            search: {
               id: 0,
               name: "",
               type: 0,
            },
            isOpen: false,
            results: {
               cityResponse: [],
               districtResponse: [],
               hotelResponse: [],
            },
            clearStatus: false,
            arrowNavigation: {
               navIndex: -1,
               navRoot: 0,
            },
         },
         toLocation: {
            search: {
               id: 0,
               name: "",
               type: 0,
            },
            isOpen: false,
            results: {
               cityResponse: [],
               districtResponse: [],
               hotelResponse: [],
            },
            arrowNavigation: {
               navIndex: -1,
               navRoot: 0,
            },
            clearStatus: false,
         },
      };
   },
   methods: {
      handlerChangeSearchInput: debounce(function (e) {
         var self = this;
         var acType = self.fromLocation;
         var searchAutocompleteReqModel = {};
         switch (e.target.getAttribute("actype")) {
            case "fromLocation":
               acType = self.fromLocation;
               searchAutocompleteReqModel = {
                  fromLocationKeyword: self.fromLocation.search.id > 0 ? null : self.fromLocation.search.name,
                  toLocationKeyword: null,
                  fromType: self.fromLocation.search.type,
                  toType: self.toLocation.search.type,
                  fromLocationId: self.fromLocation.search.id,
                  toLocationId: self.toLocation.search.id,
               };
               self.toLocation.isOpen = false;
               self.clearResult("from");
               break;
            case "toLocation":
               acType = self.toLocation;
               searchAutocompleteReqModel = {
                  fromLocationKeyword: null,
                  toLocationKeyword: self.toLocation.search.id > 0 ? null : self.toLocation.search.name,
                  fromType: self.fromLocation.search.type,
                  toType: self.toLocation.search.type,
                  fromLocationId: self.fromLocation.search.id,
                  toLocationId: self.toLocation.search.id,
               };
               self.fromLocation.isOpen = false;
               self.clearResult("to");
               break;
         }
         if (e.target.value == "") {
            if (acType == self.fromLocation) {
               const rowObj = {
                  fromLocationId: 0,
                  fromDestinationId: 0,
                  fromType: 0,
                  fromLocType: 0,
                  fromName: "",
               };
               this.$emit("update-from", rowObj);
            } else {
               const rowObj = {
                  toLocationId: 0,
                  toDestinationId: 0,
                  toType: 0,
                  toLocType: 0,
                  toName: "",
               };
               this.$emit("update-to", rowObj);
            }
            acType.isOpen = false;
         } else {
            acType.isOpen = true;
         }

         if (e.target.value.length >= 2) {
            transferService.transferAutocomplete(searchAutocompleteReqModel).then((response) => {
               if (response.result.success) {
                  acType.results.districtResponse = response.destinations.filter((destinationItem) => destinationItem.destinationType === 1);
                  acType.results.hotelResponse = response.destinations.filter((destinationItem) => destinationItem.destinationType === 2);
               }
            });
         }
      }, 500),
      handlerActiveInput(inputType) {
         var self = this;
         switch (inputType) {
            case "from":
               // self.fromLocation.isOpen = true;
               self.toLocation.isOpen = false;
               break;
            case "to":
               // self.toLocation.isOpen = true;
               self.fromLocation.isOpen = false;
               break;
         }
      },
      handlerKeydownEvents(e) {
         const liElHeight = document.querySelector(".search-autocomplete-list-section-content-list-item")?.offsetHeight;
         const activeInputField = e.target.getAttribute("actype");
         const { arrowNavigation, results } = this[activeInputField];
         var activePropName = arrowNavigation.navRoot === 0 ? "districtResponse" : "hotelResponse";
         var inActivePropName = arrowNavigation.navRoot === 0 ? "hotelResponse" : "districtResponse";

         if (e.keyCode === 40 || e.keyCode === 39) {
            if (results[activePropName].length === 0) {
               arrowNavigation.navRoot = 1;
               activePropName = "hotelResponse";
               inActivePropName = "districtResponse";
            }

            if (results[activePropName].length - 1 === arrowNavigation.navIndex) {
               arrowNavigation.navIndex = 0;
               arrowNavigation.navRoot = 1;
               document.querySelector(`.${inActivePropName}`).scrollTop = liElHeight * arrowNavigation.navIndex;
               document.querySelector(`.${activePropName}`).scrollTop = liElHeight * results[activePropName].length;
               document.querySelector(`.${activePropName}`).scrollTop = 0;
            } else {
               arrowNavigation.navIndex++;
               document.querySelector(`.${activePropName}`).scrollTop = liElHeight * arrowNavigation.navIndex;
            }

            this.activeDestinationId =
               arrowNavigation.navRoot == 1
                  ? results["hotelResponse"][arrowNavigation.navIndex].destinationId
                  : results["districtResponse"][arrowNavigation.navIndex].destinationId;
         }

         if (e.keyCode === 38 || e.keyCode === 37) {
            if (arrowNavigation.navRoot === 0 && arrowNavigation.navIndex === 0) {
               arrowNavigation.navIndex = results[activePropName].length - 1;
            }

            if (arrowNavigation.navIndex === 0) {
               arrowNavigation.navIndex = results[inActivePropName].length - 1;
               arrowNavigation.navRoot = 0;
               document.querySelector(`.${inActivePropName}`).scrollTop = liElHeight * arrowNavigation.navIndex;
            } else {
               arrowNavigation.navIndex--;
               document.querySelector(`.${activePropName}`).scrollTop = liElHeight * arrowNavigation.navIndex;
            }

            this.activeDestinationId =
               arrowNavigation.navRoot == 1
                  ? results["hotelResponse"][arrowNavigation.navIndex].destinationId
                  : results["districtResponse"][arrowNavigation.navIndex].destinationId;
         }

         if (e.keyCode === 13) {
            if (activeInputField === "fromLocation") {
               this.selectFromSearchResult(results[activePropName][arrowNavigation.navIndex]);
            } else {
               this.selectToSearchResult(results[activePropName][arrowNavigation.navIndex]);
            }
         }
      },
      handlerSwapDestinations() {
         const self = this;
         let tempFrom = self.fromLocation.search;
         let tempTo = self.toLocation.search;
         self.fromLocation.search = tempTo;
         self.toLocation.search = tempFrom;
         tempFrom = "";
         tempTo = "";
      },
      selectFromSearchResult(result) {
         var self = this;
         self.fromLocation.clearStatus = true;
         self.fromLocation.search.name = result.name;
         self.fromLocation.search.id = result.destinationId;
         self.fromLocation.search.type = result.destinationType;
         self.fromLocation.isOpen = false;
         const from = {
            locationId: result.locationId,
            destinationId: result.destinationId,
            destinationType: result.destinationType,
            locationType: result.locationType,
            destinationName: result.name,
         };
         this.$emit("update-from", from);
         self.selectSearchResult(result.routeId);
      },
      selectToSearchResult(result) {
         var self = this;
         self.toLocation.clearStatus = true;
         self.toLocation.search.name = result.name;
         self.toLocation.search.id = result.destinationId;
         self.toLocation.search.type = result.destinationType;
         self.toLocation.isOpen = false;
         const to = {
            locationId: result.locationId,
            destinationId: result.destinationId,
            destinationType: result.destinationType,
            locationType: result.locationType,
            destinationName: result.name,
         };
         this.$emit("update-to", to);
         self.selectSearchResult(result.routeId);
      },
      selectSearchResult(routeId) {
         this.$emit("update-route", routeId);
      },
      clearSelected(location) {
         const self = this;

         switch (location) {
            case "from": {
               self.fromLocation.clearStatus = false;
               self.fromLocation.search = { id: 0, name: "", type: 0 };
               const from = { fromLocationId: 0, fromDestinationId: 0, fromType: 0, fromLocType: 0, fromName: "" };
               this.$emit("update-from", from);
               this.$emit("update-route", 0);
               break;
            }
            case "to": {
               self.toLocation.clearStatus = false;
               self.toLocation.search = { id: 0, name: "", type: 0 };
               const to = { toLocationId: 0, toDestinationId: 0, toType: 0, toLocType: 0, toName: "" };
               this.$emit("update-to", to);
               this.$emit("update-route", 0);
               break;
            }
         }
      },
      clearResult(location) {
         const self = this;
         switch (location) {
            case "from": {
               self.fromLocation.results = { cityResponse: [], districtResponse: [], hotelResponse: [] };
               break;
            }
            case "to": {
               self.toLocation.results = { cityResponse: [], districtResponse: [], hotelResponse: [] };
               break;
            }
         }
      },
   },
};
</script>

<style lang="scss" scoped>
.gts-autocomplete-wrapper {
   display: flex;

   @media (max-width: 576px) {
      flex-direction: column;
   }
   h1,
   label {
      font-weight: 500;
      margin-bottom: 0.5rem;
   }
   .input-swap-container {
      display: none;
      cursor: pointer !important;
      margin-top: 2.3rem;
      margin-right: 1rem;

      @media (min-width: 576px) {
         display: block;
      }
   }
   .gts-autocomplete-inner {
      width: 100%;

      .search-autocomplete-input {
         position: relative;
         display: flex;
         align-items: center;

         & > input {
            width: 100%;
            height: 48px;
            padding-right: 1.8rem;
            // text-indent: 1rem;
            color: var(--txt-black);
            background: #f8f8f8;
            text-transform: capitalize;
            border: 1px solid #ccc;
            padding-left: 1.125rem;
            border-radius: 0.5rem;
         }

         & > i {
            position: absolute;
            right: 10px;
            cursor: pointer;
         }
      }
      .search-autocomplete-list {
         position: absolute;
         left: 0;
         width: max-content;
         max-width: 507px;
         margin-top: 10px;
         background-color: var(--c-primary);
         box-shadow: 3px 3px 10px #0000001a;
         border: 1px solid #ececec;
         border-radius: 8px;
         z-index: 2;

         &.to-location {
            @media (max-width: 576px) {
               left: 0;
            }
            left: 330px;
         }

         .search-autocomplete-list-inner {
            display: flex;
            flex-direction: column;

            .search-autocomplete-list-section {
               display: flex;
               justify-content: flex-start;
               align-items: flex-start;
               max-height: 250px;
               padding: 20px;
               border-left: 5px solid transparent;
               border-bottom: 1px solid #e4e4e4;
               overflow-y: auto;

               &:last-child {
                  border-bottom: none;
               }

               &.city-section {
                  border-left-color: #61bff9;
                  border-top-left-radius: 4px;
                  i {
                     color: #61bff9;
                  }
               }
               &.district-section {
                  border-left-color: #ffb44f;

                  i {
                     color: #ffb44f;
                  }

                  &:hover {
                     background-color: #fff6eb;
                  }
               }
               &.transfer-section {
                  border-left-color: #ff7e66;
                  border-bottom-left-radius: 4px;

                  i {
                     color: #ff7e66;
                  }

                  &:hover {
                     background-color: #ffeeeb;
                  }
               }

               &-icon {
                  margin-right: 24px;
                  // & > i {
                  // }
               }
               &-content {
                  width: 100%;
                  &-title {
                     margin-bottom: 12px;
                     & > span {
                        color: var(--txt-black);
                        font-weight: 700;
                     }
                  }
                  &-list {
                     &-item {
                        margin-bottom: 8px;
                        color: var(--txt-black);
                        cursor: pointer;

                        &:last-child {
                           margin-bottom: 0;
                        }
                        & > span {
                           font-size: 14px;
                        }
                        &:hover,
                        &.highlighted {
                           color: #fff;
                           background-color: #4da6ff !important;
                        }
                     }
                  }
               }
            }
         }
      }

      &:first-child {
         .search-autocomplete-input {
            &:after {
               content: "";
               position: absolute;
               border-right: 1px solid #ccc;
               right: 0;
               top: 0.5rem;
               bottom: 0.5rem;
            }
         }
         input {
            border-right: none !important;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;

            @media (max-width: 576px) {
               border-right: 1px solid #e4e4e4 !important;
            }
         }
      }
      &:last-child {
         input {
            border-left: none !important;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            @media (max-width: 576px) {
               border-left: 1px solid #e4e4e4 !important;
            }
         }

         @media (max-width: 576px) {
            margin-top: 1rem;
         }
      }
   }
}
</style>
