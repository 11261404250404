<template>
   <div id="flight-informations-wrapper" class="gts-box with-border">
      <span class="section-title">{{ $t("flightInformationsComponent_flightInformation") }}</span>

      <!-- loop for legs -->
      <div class="leg-container" v-for="(leg, index) in data" :key="index">
         <!-- loop for segments -->

         <FlightLeg :leg="leg" />

         <div class="flight-brands-pointer-wrapper" :class="IsPriceDifference ? '' : 'has-price-difference'">
            <template v-if="PricePerLeg">
               <FlightBrands
                  :brands="leg.brands"
                  :ssrs="leg.extraSsrs"
                  :leg-index="index"
                  :flight-identifier="leg.flightIdentifier"
                  :price-per-leg="PricePerLeg"
                  :data="data"
                  :identifier-list="flightIdentifiers" />
            </template>
            <template v-else-if="!PricePerLeg && Object.keys(data).length == Number(index)">
               <FlightBrands
                  :brands="leg.brands"
                  :ssrs="leg.extraSsrs"
                  :leg-index="index"
                  :flight-identifier="leg.flightIdentifier"
                  :price-per-leg="PricePerLeg"
                  :data="data"
                  :identifier-list="flightIdentifiers" />
            </template>
         </div>
      </div>
   </div>
</template>

<script>
import FlightLeg from "@/components/structural/flight/common/FlightLeg.vue";
import FlightBrands from "./brands/FlightBrands.vue";

export default {
   name: "FlightInformations",
   components: {
      FlightLeg,
      FlightBrands,
   },
   data() {
      return {
         legColor: {},
         legColorActive: false,
         data: {},
         flightIdentifiers: [],
      };
   },

   computed: {
      PricePerLeg() {
         return this.$store.state.flight.checkout.response.pricePerLeg;
      },
      IsPriceDifference() {
         return !this.$store.state.flight.checkout.isPriceDifference;
      },
   },
   methods: {
      handleAirlineColor(value) {
         const self = this;
         this.$set(self.legColor, value.legIndex, value);
         self.legColorActive = true;
      },
   },
   watch: {
      "$store.state.flight.checkout.response.legs": {
         handler: function () {
            const self = this;
            self.data = self.$store.state.flight.checkout.response.legs;
         },
         immediate: true,
      },
   },
};
</script>

<style lang="scss" scoped>
#flight-informations-wrapper {
   margin-top: 1rem !important;
   .leg-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0;
      background-image: linear-gradient(to right, #b6bbc1 33%, rgba(255, 255, 255, 0) 0%);
      background-position: bottom;
      background-size: 17px 2px;
      background-repeat: repeat-x;
      background-position: bottom;
      margin-bottom: 2rem;
      padding-bottom: 2rem;

      &:last-child {
         background-image: none !important;
      }

      .has-price-difference {
         pointer-events: none;
      }
   }
}
</style>
