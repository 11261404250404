<template>
   <div id="flight-informations-wrapper" :class="data == false ? 'data-mask' : ''">
      <span class="section-title">{{ $t("orderDetailPage_flightInformation") }}</span>

      <div class="flight-container" v-for="(flight, index) in data" :key="index">
         <section class="flight-info-board">
            <h2>{{ index + 1 + ". " + $t("flightOrderDetail_txtFlight") }}</h2>
            <span
               class="flight-color masked-background-dark"
               :style="{ 'background-color': $options.filters.airlineColor(flight.segments[0].marketingAirline) }"></span>
         </section>
         <div class="segment-wrapper">
            <div class="segment-container" v-for="(segment, segindex) in flight.segments" :key="segindex">
               <section class="segment-informations masked-background">
                  <span class="segment-color masked-background-dark" style="background-color: rgb(230, 41, 43)"></span>
                  <div class="segment-informations-inner-wrapper">
                     <div class="departure">
                        <span class="airport-name-abbr masked">{{ segment.departureAirportCode }}</span>
                        <span class="airport-name-full masked">{{ segment.departureAirportName }}</span>
                        <span class="flight-date masked">
                           {{ segment.departureDateTime | fullDateFormat }}
                           {{ segment.departureDateTime | TimeFormat }}
                        </span>
                        <span class="flight-date masked">
                           {{ segment.departureDateTime | onlyDayFullName }}
                        </span>
                     </div>
                     <div class="flight-info">
                        <img
                           :src="`${$constants.cdn}/airline_34x34/${segment.marketingAirline}.svg`"
                           @error="$event.target.src = `${$constants.cdn}/airline_34x34/00.svg`"
                           alt=""
                           class="masked-img" />
                        <span class="flight-code masked">{{ segment.flightNumber }}</span>

                        <span class="flight-operated-airline masked" v-if="segment.marketingAirlineName != segment.operatingAirlineName">
                           {{ $t("flightCard_operatedBy") }} {{ segment.operatingAirlineName }}
                        </span>
                     </div>
                     <div class="arrival">
                        <span class="airport-name-abbr masked">{{ segment.arrivalAirportCode }}</span>
                        <span class="airport-name-full masked">{{ segment.arrivalAirportName }}</span>
                        <span class="flight-date masked">
                           {{ segment.arrivalDateTime | fullDateFormat }}
                           {{ segment.arrivalDateTime | TimeFormat }}
                        </span>
                        <span class="flight-date masked">
                           {{ segment.arrivalDateTime | onlyDayFullName }}
                        </span>
                     </div>
                  </div>

                  <div class="segment-extra-informations-inner-wrapper">
                     <div class="info-box">
                        <span class="info-title">{{ $t("flight_flightClass") }}</span>
                        <span class="info-content masked">{{ segment.flightClass }}</span>
                     </div>
                     <div class="info-box">
                        <span class="info-title">{{ $t("flight_operatingAirline") }}</span>
                        <span class="info-content masked">{{ segment.operatingAirlineName }}</span>
                     </div>
                  </div>
               </section>
            </div>
         </div>
      </div>
   </div>
</template>

<script>
export default {
   name: "FlightInformations",
   props: {
      data: [Array, Object],
   },
};
</script>

<style lang="scss" scoped>
#flight-informations-wrapper {
   .flight-container {
      display: flex;
      flex-direction: column;
      margin-bottom: 1.5rem;

      @media (min-width: 576px) {
         flex-direction: row;
      }
      .flight-info-board {
         border-radius: 10px;
         display: flex;
         position: relative;
         overflow: hidden;
         flex-grow: 2;
         min-width: 45px;
         border: 1px solid #cccccc;

         h2 {
            writing-mode: horizontal-tb;
            text-orientation: unset;
            transform: rotate(0);
            text-align: center;
            font-size: var(--large);
            font-weight: 600;
            margin-left: 0.75rem !important;
            margin-right: 0.75rem !important;
            padding: 0.5rem 0;
            @media (min-width: 576px) {
               padding: 0;
               writing-mode: tb;
               transform: rotate(180deg);
            }
         }
         .flight-color {
            width: 24px;
            height: 24px;
            border-radius: 50%;
            display: inline-block;
            position: absolute;
            right: 0.5rem;
            top: 0;
            bottom: 0;
            margin: auto;

            @media (min-width: 576px) {
               width: 4px;
               height: 70%;
               border-radius: 10px;
               border-bottom-right-radius: 0;
               border-top-right-radius: 0;
            }
         }
      }
   }
   .segment-wrapper {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin-top: 1rem;

      @media (min-width: 576px) {
         margin-top: 0;
      }
      .segment-container {
         display: flex;
         flex-direction: column;
         margin-bottom: 1rem;

         .segment-informations {
            width: auto;
            display: flex;
            flex-direction: column;
            background: #f3f3f3;
            border-radius: 12px;
            border: 1px solid #cccccc;
            position: relative;
            padding: 1rem 0;
            @media (min-width: 576px) {
               flex-direction: row;
               margin-left: 1rem;
            }

            .segment-informations-inner-wrapper {
               display: flex;
               flex-direction: row;
               width: 100%;
               justify-content: space-around;
               border-right: 1px solid #ccc;

               @media (min-width: 576px) {
                  width: 60%;
               }

               .departure,
               .arrival {
                  display: flex;
                  flex-direction: column;

                  @media (min-width: 576px) {
                     width: 20%;
                  }
                  @media (min-width: 768px) {
                     width: 30%;
                  }
                  @media (min-width: 1200px) {
                     width: 30%;
                  }

                  span {
                     @media (min-width: 1200px) {
                        white-space: nowrap;
                     }

                     &.airport-name-abbr {
                        font-size: var(--large);
                        font-weight: 600;
                     }
                     &.airport-name-full {
                        font-size: var(--small);
                     }
                     &.flight-date {
                        font-size: var(--small);
                        opacity: 0.6;
                     }
                  }
               }

               .flight-info {
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  width: 20%;

                  img {
                     width: 50px;
                     height: 50px;
                  }

                  .flight-code {
                     font-size: var(--small);
                     font-weight: 600;
                     margin-top: 0.5rem;
                  }
                  span.flight-operated-airline {
                     font-weight: 400;
                     font-size: var(--xsmall);
                     color: #ff4c4c;
                     text-align: center;
                     max-width: 100px;
                  }
                  .airline-name {
                     font-size: var(--small);
                     opacity: 0.6;
                  }
               }
            }
            .segment-extra-informations-inner-wrapper {
               display: flex;
               flex-direction: row;
               width: 100%;
               justify-content: space-around;
               align-items: center;
               border-top: 1px solid #ccc;
               margin-top: 1rem;
               padding-top: 1rem;

               @media (min-width: 576px) {
                  width: 40%;
                  border-top: none;
                  margin-top: 0;
                  padding-top: 0;
               }

               .info-box {
                  display: flex;
                  flex-direction: column;
                  .info-title {
                     font-size: var(--small);
                     opacity: 0.8;
                     white-space: nowrap;
                  }
                  .info-content {
                     font-size: var(--large);
                     font-weight: 600;
                  }

                  &:last-child {
                     width: 60%;
                  }
               }
            }

            .segment-color {
               position: absolute;
               width: 5px;
               right: 0;
               top: 10%;
               bottom: 10%;
               border-top-left-radius: 12px;
               border-bottom-left-radius: 12px;
               background-color: transparent !important;
            }
         }

         &:last-child {
            margin-bottom: 0rem;
         }
      }
   }
}
</style>
