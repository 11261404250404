<template>
   <div id="order-detail-notes-container">
      <div class="order-detail-note-container">
         <div class="order-detail-section-title">
            <span class="section-title">{{ $t("transferOrderDetail_lblTransferAgencyNote") }} </span>
         </div>

         <section class="order-detail-note">
            <p>{{ agencyNote }}</p>
         </section>
      </div>
   </div>
</template>

<script>
export default {
   name: "OrderDetailNotes",
   components: {},
   props: {
      agencyNote: {
         type: String,
         default: "",
      },
   },
   data() {
      return {
         note: this.agencyNote,
      };
   },
   watch: {
      note: {
         handler: function (val) {
            this.$store.commit("transfer/updateReissueAgencyNote", val);
         },
      },
   },
};
</script>

<style lang="scss" scoped>
.order-detail-section-title {
   margin-bottom: 1rem;
   & > h4 {
      margin-bottom: 10px;
   }
   & > p {
      font-size: 14px;
      color: #1e2023;
   }
}

#order-detail-notes-container {
   background-color: #fef49c;
   border: 1px solid #9f9f9f;
   border-radius: 1rem;
   margin: 2rem;
   overflow: hidden;

   margin: 0 1rem;
   .order-detail-note-container {
      margin-bottom: 1rem;

      .order-detail-section-title {
         background-color: #feea3d;
         padding: 0.5rem 1.5rem;
         display: flex;
         justify-content: space-between;
         align-items: center;

         span {
            margin-bottom: 0;
         }
      }

      section.order-detail-note {
         padding: 0 1.5rem;
         width: 100%;
         display: flex;
      }
   }
}
</style>
