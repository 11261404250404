<template>
   <div class="widget-table-pagination-container" v-if="ShowPagination">
      <!-- for campaigns -->
      <div class="pagination-prev-page campaign" @click="handlerPageNumber(activePage - 1)" v-if="campaign">
         <i class="gts-icons icon-arrow-left"></i>
      </div>

      <!-- for announcements -->
      <div class="pagination-prev-page" @click="handlerPageNumber(activePage - 1)" v-else>
         {{ $t("gtsDataTableComponent_prevLabel") }}
      </div>

      <template v-if="announcement">
         <span>
            <!-- {{ pageNum }} -->
            <UISelectBox v-model="pageNum" :options="list" upper />
         </span>
      </template>

      <template v-else>
         <div class="pagination-wrapper" v-if="!campaign">
            <!-- Less than 8 -->
            <template v-if="pages.length < Limiter">
               <template v-for="(page, index) in pages">
                  <div
                     class="pagination-page"
                     :key="index"
                     :class="index + 1 == activePage ? 'active-page' : ''"
                     @click="handlerPageNumber(index + 1)">
                     {{ page }}
                  </div>
               </template>
            </template>

            <!-- More Than 8 -->
            <template v-else>
               <!-- Page 1 & 2 -->
               <template v-for="(page, index) in pages">
                  <div
                     class="pagination-page"
                     :key="index"
                     v-if="index < 2"
                     :class="index + 1 == activePage ? 'active-page' : ''"
                     @click="handlerPageNumber(index + 1)">
                     {{ page }}
                  </div>
               </template>
               <div class="pagination-seperator" v-if="activePage > 3">..</div>
               <div class="pagination-page active-page" v-if="activePage > 2 && activePage < pages.length - 1">{{ activePage }}</div>
               <div class="pagination-seperator" v-if="activePage < pages.length - 2">..</div>

               <template v-for="(page, index) in pages">
                  <div
                     class="pagination-page"
                     :key="index"
                     v-if="index > pages.length - 3"
                     :class="index + 1 == activePage ? 'active-page' : ''"
                     @click="handlerPageNumber(index + 1)">
                     {{ page }}
                  </div>
               </template>
            </template>
         </div>
      </template>

      <!-- for campaigns -->
      <div class="pagination-next-page campaign" @click="handlerPageNumber(activePage + 1)" v-if="campaign">
         <i class="gts-icons icon-arrow-right"></i>
      </div>

      <!-- for announcements -->
      <div class="pagination-next-page" @click="handlerPageNumber(activePage + 1)" v-else>
         {{ $t("gtsDataTableComponent_nextLabel") }}
      </div>
   </div>
</template>

<script>
import UISelectBox from "@/components/unitary/form2/UISelectBox.vue";
export default {
   name: "Pagination",
   components: {
      UISelectBox,
   },
   props: {
      announcement: Boolean,
      pages: Array,
      activePage: Number,
      showAlways: Boolean,
      activetab: String,
      campaign: {
         type: Boolean,
         default: false,
      },
   },
   data() {
      return {
         pageNum: 1,
         list: [],
      };
   },
   mounted() {
      if (this.campaign) {
         this.intervalId = setInterval(() => this.handlerPageNumber(this.activePage + 1), 8000);
      }

      this.pages.forEach((page) => {
         this.list.push({
            name: page,
            value: page,
         });
      });
   },
   beforeDestroy() {
      clearInterval(this.intervalId);
   },
   computed: {
      Limiter() {
         return this.$constants.isMobile ? 5 : 8;
      },
      ShowPagination() {
         if (this.showAlways) {
            return true;
         } else {
            return this.pages.length > 1 ? true : false;
         }
      },
   },
   methods: {
      handlerPageNumber(index) {
         if (index != 0 && index != this.pages.length + 1) {
            this.$emit("update-page", index);
            if (this.announcement) {
               this.pageNum = index;
            }
         } else if (this.campaign) {
            this.$emit("update-page", 1);
         }
      },
   },
   watch: {
      pageNum: {
         handler: function (num) {
            this.handlerPageNumber(num);
         },
      },
      activetab: {
         handler: function (activetab) {
            if (this.campaign) {
               if (activetab == "list") {
                  this.intervalId = setInterval(() => this.handlerPageNumber(this.activePage + 1), 8000);
               } else {
                  clearInterval(this.intervalId);
               }
            }
         },
         immediate: true,
      },
   },
};
</script>

<style lang="scss">
.widget-table-pagination-container {
   width: 100%;
   display: flex;
   justify-content: flex-end;
   align-items: center;
   margin-top: 1.5rem;
   margin-bottom: 2rem;

   .pagination-wrapper {
      display: flex;
      .pagination-page {
         width: 32px;
         height: 32px;
         border: 2px solid #cccccc;
         border-radius: 8px;
         margin: 0 0.25rem;
         display: flex;
         justify-content: center;
         align-items: center;
         cursor: pointer;

         &:hover {
            background-color: #eee;
         }

         &.active-page {
            background-color: #363636;
            color: #fff;
            font-weight: 600;
         }
      }
      .pagination-seperator {
         width: fit-content;
         height: 32px;
         font-size: 1.5rem;
         color: #363636;
      }
   }
   .pagination-prev-page,
   .pagination-next-page {
      height: 32px;
      line-height: 16px;
      font-weight: 500;
      cursor: pointer;
      border-radius: 0.5rem;
      padding: 0.5rem;
      border: 1px solid #ccc;
      //       background-color: #eee;

      i {
         width: 11px;
         height: 20px;
         background-size: contain;
         filter: brightness(10.5);
         background-repeat: no-repeat;
      }

      &:hover {
         background-color: #363636;
         color: #fff;
      }
   }
   .pagination-prev-page {
      margin-right: 0.75rem;
   }
   .pagination-next-page {
      margin-left: 0.75rem;
   }
   .campaign {
      height: 40px !important;
      width: 40px !important;
      margin-right: 0 !important;
      margin-left: 0 !important;
      border-radius: 0 !important;
      padding: 0 !important;
      display: flex;
      justify-content: center;
      align-items: center;
      &:hover {
         background-color: #4781d1;
      }
   }
}
</style>
