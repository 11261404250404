<template>
   <div class="confetti-container"></div>
</template>

<script type="module"></script>
<script>
import gsap from "gsap";
import Vue from "vue";
import VueConfetti from "vue-confetti";
// import SuccessSound from "@/assets/success.mp3";

Vue.use(VueConfetti);
export default {
   name: "Confetti",
   mounted() {
      this.$nextTick(() => {
         var txtEl = document.createElement("div");
         txtEl.classList.add("confetti-text-wrapper");
         txtEl.innerHTML = this.$i18n.t("targetAchievement_lblConfetti");
         document.body.prepend(txtEl);
         this.start();
         const tl = gsap.timeline();
         tl.fromTo(
            this.$refs.successText,
            {
               opacity: 0,
               scale: 0,
            },

            {
               duration: 0.5,
               opacity: 1,
               scale: 1,

               ease: "power3.out",
            }
         );
      });
   },
   methods: {
      start() {
         this.$confetti.start({
            particles: [
               {
                  type: "rect",
               },
               { type: "heart" },
            ],
            particlesPerFrame: 1.5,
            defaultDropRate: 15,
            defaultSize: 15,
         });
      },
   },
};
</script>

<style lang="scss">
@keyframes placeholderConfetti {
   0% {
      background-color: #39ca86;
   }

   20% {
      background-color: #bece4d;
   }

   40% {
      background-color: #fecd32;
   }

   60% {
      background-color: #f8af40;
   }

   80% {
      background-color: #f3944d;
   }

   100% {
      background-color: var(--accent-primary);
   }
}
.confetti-text-wrapper {
   background-color: #fff;
   border-radius: 1rem;
   left: 0;
   right: 0;
   top: 0;
   bottom: 0;
   position: fixed;
   margin: auto;
   width: fit-content;
   height: fit-content;
   padding: 0.5rem 3rem;
   color: #fff;
   font-size: 3rem;
   font-weight: 600;
   z-index: 9999999 !important;
   animation: placeholderConfetti 0.75s infinite;
}
</style>
