<template>
   <div id="flight-information-popup-wrapper" v-if="Informations && status">
      <div id="flight-information-popup-container">
         <div class="img-container"><img src="@/assets/images/globeLightMode.gif" alt="" /></div>
         <p class="popup-text">{{ $t("SearchInformationPopupFlight_text") }}</p>
         <section class="flight-information-inner-container">
            <div id="flight-information-legs-wrapper">
               <div class="flight-information-legs" v-for="(leg, index) in Informations.legs" :key="index">
                  <div class="flight-information-leg-row">
                     <!-- DEPARTURE DATE -->
                     <div class="flight-information-leg-col">
                        <div class="flight-date departure-date">
                           <span class="flight-date-dd">{{ leg.departureDate | FormatterDD }} </span>
                           <section class="flight-date-container">
                              <span class="flight-date-mmmm">{{ leg.departureDate | FormatterMMM }} </span>
                              <span class="flight-date-yyyy">{{ leg.departureDate | FormatterYYYY }} </span>
                           </section>
                        </div>
                     </div>

                     <!-- FROM DESTINATION -->
                     <div class="flight-information-leg-col">
                        <span
                           class="from-destination"
                           v-for="(from, findex) in leg.fromDestinations"
                           :key="from + findex"
                           v-html="from.search('!') != -1 ? `${from.replace('!', '')}<small>*</small>` : from">
                        </span>
                     </div>

                     <!-- FLIGHT ICON -->
                     <div class="flight-information-leg-col">
                        <i class="gts-icon icon-flight-icon"></i>
                     </div>

                     <!-- TO DESTINATION -->
                     <div class="flight-information-leg-col">
                        <span
                           class="to-destination"
                           v-for="(to, tindex) in leg.toDestinations"
                           :key="to + tindex"
                           v-html="to.search('!') != -1 ? `${to.replace('!', '')}<small>*</small>` : to">
                        </span>
                     </div>

                     <!-- ARRIVAL DATE -->
                     <div class="flight-information-leg-col" v-if="leg.returnDate == null ? false : true">
                        <div class="flight-date return-date">
                           <span class="flight-date-dd">{{ leg.returnDate | FormatterDD }} </span>
                           <section class="flight-date-container">
                              <span class="flight-date-mmmm">{{ leg.returnDate | FormatterMMM }} </span>
                              <span class="flight-date-yyyy">{{ leg.returnDate | FormatterYYYY }} </span>
                           </section>
                        </div>
                     </div>
                  </div>
               </div>
            </div>

            <div class="flight-information-footer">
               <span class="deep-note">* {{ $t("SearchInformationPopupFlight_allAirports") }}</span>
               <div class="pax-informations-container">
                  <span class="pax-info" v-for="(value, propertyName, index) in Informations.paxes" :key="index">
                     {{ value + " " + $t(`common_` + propertyName) }}
                  </span>
               </div>
            </div>

            <!-- <div class="flight-information-legs">
          <div class="flight-information-leg-row flight-information-footer">
            <div class="flight-information-leg-col">
              <span class="deep-note">* All Airports.</span>
            </div>
            <div class="flight-information-leg-col"></div>
            <div class="flight-information-leg-col">
              <div class="pax-informations-container">
                <span class="pax-info" v-for="(value, propertyName, index) in Informations.paxes" :key="index">
                  {{ $t(`common_` + propertyName) }}
                </span>
              </div>
            </div>
          </div>
        </div> -->
         </section>
      </div>
   </div>
</template>

<script>
export default {
   name: "FlightInformationPopup",

   data() {
      return {
         informationpopup: null,
         status: true,
      };
   },
   computed: {
      Informations() {
         return this.informationpopup;
      },
   },
   watch: {
      "$store.state.flight.search.request": {
         handler: function (informations) {
            this.informationpopup = informations;
            // this.informationpopup = {
            //    uuid: "66ac64a0-2a36-43de-81c0-30f95b3bb3c6",
            //    cabinTypes: [1, 2],
            //    paxes: {
            //       adult: 1,
            //    },
            //    airlines: [],
            //    agencyCommissionExtra: 0,
            //    transfers: [1, 2],
            //    legs: {
            //       1: {
            //          fromDestinations: ["AYT"],
            //          toDestinations: ["DUS!"],
            //          index: 1,
            //          departureDate: "2023-01-07",
            //          returnDate: "2023-01-14",
            //       },
            //    },
            //    searchUuid: "1ceec098-abff-4769-8781-6f6a9a10ace0",
            // };
            this.status = true;
         },
      },
   },
};
</script>

<style lang="scss" scoped>
#flight-information-popup-wrapper {
   position: fixed;
   left: 0;
   right: 0;
   top: 0;
   bottom: 0;
   background-color: #00000050;
   z-index: 9999;

   @media (min-width: 576px) {
   }

   #flight-information-popup-container {
      width: fit-content;
      padding: 2rem 1rem;
      height: fit-content;
      background: #fff;
      border-radius: 12px;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      margin: auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 90vw;
      @media (min-width: 576px) {
         width: 500px;
         padding: 2rem;
      }
      .popup-text {
         font-weight: 600;
         text-align: center;
      }
      .img-container {
         width: 100px;
         height: 100px;
         margin-bottom: 1.5rem;
      }
      .flight-information-inner-container {
         width: 100%;
         padding: 0 0.5rem;

         @media (min-width: 992px) {
            padding: 0 1rem;
         }

         .flight-information-legs {
            display: flex;
            flex-direction: column;
            padding: 0.5rem 0;

            .flight-information-leg-row {
               display: flex;
               flex-direction: row;
               justify-content: space-between;
               .flight-information-leg-col {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  // background: lightblue;
                  // border: 1px solid;

                  .from-destination,
                  .to-destination {
                     padding: 0 0.25rem;
                     color: var(--primary-dark-color);
                     // color: #363636;
                     font-size: 1.25rem;
                     font-weight: 800;

                     @media (min-width: 576px) {
                        font-size: 1.5rem;
                     }

                     &:after {
                        content: ",";
                     }

                     &:last-child {
                        &:after {
                           content: "" !important;
                        }
                     }
                  }
                  .flight-date {
                     display: flex;
                     align-items: center;
                     color: #455369;
                     // color: var(--primary-dark-color);
                     // opacity: 0.7;
                     .flight-date-dd {
                        font-size: 1.85rem;
                        font-weight: 600;
                        padding-right: 0.25rem;
                     }
                     .flight-date-container {
                        display: flex;
                        flex-direction: column;
                        line-height: 0.85rem;

                        span {
                           font-size: 0.75rem;
                           font-weight: 600;
                        }
                     }

                     &.departure-date {
                        border-right: 1px solid #eee;
                        padding-right: 1rem;
                        margin-right: 1rem;
                     }
                     &.return-date {
                        border-left: 1px solid #eee;
                        padding-left: 1rem;
                        margin-left: 1rem;
                     }
                  }

                  i {
                     margin: 0 1rem;
                  }
               }

               &.date-time-row {
                  position: relative;
                  margin-top: 1rem;
               }
            }

            &.leg-row {
               border-bottom: 1px dashed #eee;

               &:last-child {
                  border: none !important;
               }
            }
         }

         .flight-information-footer {
            border-top: 1px dashed #ccc;
            padding-top: 0.5rem;
            margin-top: 1rem;
            display: flex;
            justify-content: space-between;

            span {
               font-size: 0.95rem;
               font-weight: 500;
            }

            .pax-info {
               &:after {
                  content: ",";
               }

               &:last-child {
                  &:after {
                     content: "" !important;
                  }
               }
            }
         }
      }
   }
}
</style>
<style lang="scss">
.from-destination,
.to-destination {
   display: flex;

   small {
      font-size: 0.85rem;
      font-weight: 500;
   }
}
</style>
