<template>
   <div class="ancillary-reissue-input-location-container">
      <template v-if="inputStatus">
         <div class="ancillary-reissue-input-wrapper">
            <input type="text" @input="handleSearchInput" v-model="autoCompleteInputString" ref="autocompleteInput" :placeholder="placeholder" />
            <i class="gts-icon icon-close-rounded icon-xs" @click="handlerClearInput"></i>
         </div>
      </template>
      <template v-else>
         <div class="input-placeholder-wrapper" @click="handlerActivateInput">
            <div class="ancillary-reissue-input-icon-wrapper"></div>
            <div class="ancillary-reissue-input-viewer-wrapper">
               <label for=""> {{ label }}</label>
               <span>{{ autoCompleteInputString.trimmer(25) }} </span>
            </div>
         </div>
      </template>

      <div class="ancillary-reissue-location-dropdown-wrapper" v-if="showDropdown">
         <ul>
            <template v-for="(airport, airportIndex) in airportList">
               <li :key="airportIndex" @click="handlerSelectAirport(airport)">
                  <span class="airport-name">
                     {{ airport.name }}
                  </span>
               </li>
            </template>
         </ul>
      </div>
   </div>
</template>

<script>
import { flightService } from "@/service/index.js";
import debounce from "lodash.debounce";
export default {
   name: "AncillaryReissueInputLocation",
   props: {
      label: String,
      autocompleteInput: String,
      placeholder: String,
   },
   data() {
      return {
         inputStatus: false,
         autoCompleteInputString: this.autocompleteInput,
         showDropdown: false,
         airportList: [],
      };
   },
   methods: {
      handlerClearInput() {
         this.autoCompleteInputString = "";
      },
      handlerSelectAirport(airport) {
         this.$emit("selected-airport", airport.code);
         this.autoCompleteInputString = `${airport.name} (${airport.code})`;
         this.inputStatus = false;
         this.showDropdown = false;
      },
      handlerActivateInput() {
         // this.autoCompleteInput = "";
         this.inputStatus = !this.inputStatus;

         this.$nextTick(() => {
            this.showDropdown = this.airportList.length != 0 ? true : false;
            this.$refs.autocompleteInput.focus();
         });
      },
      handleSearchInput: debounce(function (value) {
         var searchAutocompleteReqModel = {
            keyword: value.target.value.clearSpaces(),
         };
         flightService.flightAutocomplete(searchAutocompleteReqModel).then((res) => {
            this.showDropdown = true;
            this.airportList = res.airports;
         });
      }, 300),
   },
   watch: {
      inputStatus: {
         handler: function (val) {
            if (val == false && this.autoCompleteInputString.length == 0) {
               this.autoCompleteInputString = this.autocompleteInput;
            }
         },
      },
   },
};
</script>

<style lang="scss" scoped>
.ancillary-reissue-input-location-container {
   display: flex;
   flex-direction: row;
   align-items: center;
   background-color: #fff;
   border: 1px solid #e6e7eb;
   border-radius: 0.5rem;
   position: relative;
   height: 56px;
   padding: 0.5rem 1rem;
   width: 100%;
   // margin-right: 1rem;

   .input-placeholder-wrapper {
      display: flex;
      align-items: center;
      cursor: pointer;
      width: 100%;
      .ancillary-reissue-input-icon-wrapper {
         width: 21px;
         height: 28px;
         background-image: url("data:image/svg+xml;base64,PHN2ZyBpZD0ibG9jYXRpb24iIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjIxLjY0OSIgaGVpZ2h0PSIyNy41NTMiIHZpZXdCb3g9IjAgMCAyMS42NDkgMjcuNTUzIj4KICA8cGF0aCBpZD0iUGF0aF84MDc0IiBkYXRhLW5hbWU9IlBhdGggODA3NCIgZD0iTTE3LjE1MywxOC41NTVhNC43NzgsNC43NzgsMCwxLDEsNC43NzgtNC43NzhBNC43NzgsNC43NzgsMCwwLDEsMTcuMTUzLDE4LjU1NVoiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC02LjQ0MyAtMy4zMDkpIiBmaWxsPSIjMzYzNjM2Ii8+CiAgPHBhdGggaWQ9IlBhdGhfODA3NSIgZGF0YS1uYW1lPSJQYXRoIDgwNzUiIGQ9Ik0xNi40NDksMjkuOGwtOC4zLTkuNzkxcS0uMTczLS4yMi0uMzQyLS40NDNhMTAuNzE1LDEwLjcxNSwwLDAsMS0yLjE4MS02LjQ5NSwxMC44MjQsMTAuODI0LDAsMCwxLDIxLjY0OSwwLDEwLjcxMSwxMC43MTEsMCwwLDEtMi4xOCw2LjQ5MnYwcy0uMjk1LjM4Ny0uMzM5LjQ0Wk05LjM3NiwxOC4zODNzLjIzLjMuMjgzLjM2OGw2Ljc5MSw4LjAxLDYuOC04LjAyYy4wNDMtLjA1NC4yNzQtLjM2LjI3NS0uMzZhOC43NTksOC43NTksMCwwLDAsMS43ODItNS4zMDYsOC44NTYsOC44NTYsMCwwLDAtMTcuNzEzLDAsOC43NjMsOC43NjMsMCwwLDAsMS43ODQsNS4zMDlaIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtNS42MjUgLTIuMjUpIiBmaWxsPSIjMzYzNjM2Ii8+Cjwvc3ZnPgo=");
         background-repeat: no-repeat;
         background-size: contain;
      }
      .ancillary-reissue-input-viewer-wrapper {
         display: flex;
         flex-direction: column;
         margin-left: 0.75rem;
         label {
            font-size: 0.85rem;
            opacity: 0.5;
         }
         span {
            font-size: 1rem;
            font-weight: 500;
         }
      }
   }
   .ancillary-reissue-input-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      input {
         background-color: transparent;
         outline: 0;
         // border: 1px solid #ccc;
         border: 0;
         width: 100%;
         text-transform: uppercase;
         font-size: 16px !important;
         font-weight: 700;
         font-size: 1.5rem;

         &::placeholder {
            font-weight: 700;
            font-size: 1.5rem;
            text-transform: capitalize;
         }
      }
   }

   .ancillary-reissue-location-dropdown-wrapper {
      background: #fff;
      position: absolute;
      // padding: 1rem;
      border: 1px solid #ccc;
      top: 100%;
      left: 0;
      right: 0;

      ul {
         padding: 0.5rem 0;
         li {
            cursor: pointer;
            padding: 0.5rem 1rem;
            span.airport-name {
               font-size: 1.25rem;
            }

            &:hover {
               background-color: var(--accent-primary);
               span.airport-name {
                  color: #fff;
               }
            }
         }
      }
   }
}
</style>
