<template>
   <div class="transfer-basket-container">
      <div class="transfer-basket-inner">
         <!-- Basket Header -->
         <div class="transfer-basket-header-wrapper">
            <span class="transfer-basket-title">
               {{ $t("transferBasket_lblTitle") }}
            </span>
            <i class="gts-icon icon-hotel-empty-basket icon-sm" @click="clearBasket"></i>
         </div>

         <!-- Basket Mobile View -->
         <Section class="transfer-basket-mobile-wrapper d-lg-none">
            <Div class="location-names-mobile-wrapper" v-for="(item, index) in basket.transfers" :key="index">
               <div class="location-name-item-wrapper">
                  <div class="location-index">{{ $t("transferSearch_lblFromDestination") }}</div>
                  <span class="location-full-name">
                     {{ item.from.destinationName }}
                  </span>
               </div>
               <div class="location-name-item-wrapper">
                  <div class="location-index">{{ $t("transferSearch_lblToDestination") }}</div>
                  <span class="location-full-name">
                     {{ item.to.destinationName }}
                  </span>
               </div>
               <div class="location-price-wrapper">
                  <span>{{ item.price.totalFare | price }}</span>
               </div>
            </Div>
            <div class="transfer-basket-mobile-total-wrapper">
               <span>{{ $t("common_lblGrandTotal") }} :</span>
               <span class="txt-black txt-xlarge total-price">{{ basket.totalPrice | price(basket.totalPriceCurrency) }}</span>
            </div>

            <!-- CTA Btns -->
            <div class="transfer-basket-cta-mobile d-flex flex-row justify-content-end mt-5">
               <div class="hotel-basket-cta-mobil-close d-lg-none me-3">
                  <UIButton className="default_outline" :text="$t('basketComponentFlight_closeBasket')" @click.native="handlerCloseBasket" />
               </div>
               <div class="transfer-basket-cta-mobil d-lg-none">
                  <UIButton
                     v-if="!isRoundTrip || (isRoundTrip && basket.transfers.length == 2)"
                     className="default"
                     :text="$t('basketComponentFlight_goToCheckout')"
                     @click.native="handlerGoToCheckout(basketResponse.uuid)" />
               </div>
            </div>
         </Section>

         <!-- Basket Leg Loop -->
         <div class="transfer-basket-content-wrapper d-none d-lg-flex">
            <div class="basket-list">
               <div class="basket-list-item" v-for="(item, index) in basket.transfers" :key="index">
                  <div class="list-item-infographic">
                     <!-- Location A -->
                     <div class="location-container">
                        <i class="gts-icon icon-transfer-basket-location"></i>
                        <div class="location-name" v-if="index == 0">A</div>
                        <div class="location-name" v-else>B</div>
                     </div>

                     <div class="location-car-icon-wrapper">
                        <i class="gts-icon icon-transfer-car"></i>
                     </div>

                     <!-- Location B -->
                     <div class="location-container">
                        <i class="gts-icon icon-transfer-basket-location"></i>
                        <div class="location-name" v-if="index == 0">B</div>
                        <div class="location-name" v-else>A</div>
                     </div>
                  </div>

                  <!-- Leg Price Info -->
                  <div class="list-item-info">
                     <div class="vehicle-info-wrapper">
                        <span class="vehicle-info"> {{ item.vehicle.info }} {{ $t("transfer_phOrSimilar") }} </span>
                     </div>

                     <div class="price-info-wrapper">
                        <div class="price-info">{{ item.price.totalFare | price(item.price.currency) }}</div>
                     </div>
                  </div>
               </div>
            </div>
         </div>

         <!-- Action Button -->
         <router-link
            v-if="!isRoundTrip || (isRoundTrip && basket.transfers.length == 2)"
            :to="{ name: 'TransferCheckout', params: { uuid: basketResponse.uuid } }"
            class="d-none d-lg-flex"
            target="_blank">
            <div class="action-button-wrapper">
               <button>
                  <i class="gts-icon icon-arrow-full icon-sm"></i>

                  <p class="total-container">
                     <span class="total-text">{{ $t("basketComponentFlight_total") }}</span>
                     <span class="total-price"> {{ basket.totalPrice | price(basket.totalPriceCurrency) }}</span>
                  </p>
               </button>
            </div>
         </router-link>

         <!-- Basket Locations' Name -->
         <div class="location-names-wrapper d-none d-lg-block">
            <div class="location-name-item-wrapper">
               <div class="location-index">{{ "A" }}</div>
               <span class="location-full-name">
                  {{ basket.transfers[0].from.destinationName }}
               </span>
            </div>
            <div class="location-name-item-wrapper">
               <div class="location-index">{{ "B" }}</div>
               <span class="location-full-name">
                  {{ basket.transfers[0].to.destinationName }}
               </span>
            </div>
         </div>
      </div>
   </div>
</template>

<script>
// import { transferService } from "@/service/index.js";
import UIButton from "@/components/unitary/Button.vue";
export default {
   name: "TransferBasketComponent",
   components: {
      UIButton,
   },
   data() {
      return {
         selectQuery: {
            searchUuid: this.$store.state.transfer.searchUuid,
            offerId: this.$store.state.transfer.basket.offerId,
         },
         basketResponse: this.$store.state.transfer.basketResponse,
      };
   },
   props: {
      isRoundTrip: Boolean,
   },
   computed: {
      basket() {
         return this.$store.state.transfer.basketResponse;
      },
   },
   methods: {
      handlerGoToCheckout(searchuuid) {
         const url = this.$router.resolve({ name: "TransferCheckout", params: { uuid: searchuuid } });
         window.open(url.href, "_blank");
      },
      handlerCloseBasket() {
         this.$parent.$parent.isBasketActive = false;
         // console.log("handlerCloseBasket", a);
      },
      clearBasket() {
         document.querySelectorAll(`[data-vehicle-id]`).forEach((card) => {
            card.classList.remove("d-none");
         });

         this.$store.commit("transfer/updateBasket", {
            departureOfferId: 0,
            returnOfferId: 0,
         });
      },

      getRouteName(from, to) {
         return from.split(",")[0] + " → " + to.split(",")[0];
      },
   },
};
</script>

<style lang="scss" scoped>
.transfer-basket-container {
   margin-top: 2px;

   .transfer-basket-inner {
      background-color: #3c67b2;
      padding: 1rem;
      border-radius: 1rem;

      .transfer-basket-header-wrapper {
         display: flex;
         justify-content: space-between;
         align-items: center;
         padding-bottom: 0.5rem;
         border-bottom: 1px dashed #fff;
         margin-bottom: 1.5rem;

         .transfer-basket-title {
            font-size: var(--xlarge);
            color: #fff;
            font-weight: 500;
         }
         i {
            cursor: pointer;

            &:hover {
               transform: scale(1.1);
            }
         }
      }

      .transfer-basket-content-wrapper {
         .basket-list {
            .basket-list-item {
               margin-bottom: 1.5rem;
               $basketMargin: 21px;

               .list-item-infographic {
                  display: flex;
                  justify-content: space-between;
                  border: 2px solid #2b4a80;
                  border-top: none;
                  padding-bottom: 0.25rem;
                  margin-left: $basketMargin;
                  margin-right: $basketMargin;

                  .location-container {
                     position: relative;

                     i {
                        width: 42px !important;
                        height: 42px !important;
                     }
                     .location-name {
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        left: 0;
                        right: 0;
                        width: 12px;
                        height: fit-content;
                        margin: auto;
                        color: var(--accent-secondary);
                        font-weight: 500;
                        font-size: var(--large);
                     }

                     &:first-child {
                        left: -$basketMargin;
                     }
                     &:last-child {
                        right: -$basketMargin;
                     }
                  }
                  .location-car-icon-wrapper {
                     display: flex;
                     align-items: flex-end;
                     i {
                        width: 46px !important;
                     }
                  }
               }

               .list-item-info {
                  display: flex;
                  margin-top: 0.5rem;
                  .vehicle-info-wrapper {
                     .vehicle-info {
                        color: #fff;
                        font-size: var(--small);
                        padding-right: 1rem;
                     }
                  }
                  .price-info-wrapper {
                     .price-info {
                        background-color: #2b4a80;
                        color: #fff;
                        border-radius: 24rem;
                        padding: 0.25rem 0.5rem;
                        display: flex;
                        align-items: center;
                        font-weight: 500;
                     }
                  }
               }
            }
         }
      }

      .action-button-wrapper {
         width: 100%;
         margin: 1rem 0;
         margin-bottom: 2rem;
         cursor: pointer;

         button {
            padding: 0.5rem 1.5rem;
            border-radius: 12rem;
            width: 100%;
            margin: 1rem 0;
            box-shadow: rgba(99, 99, 99, 0.5) 0px 2px 8px 0px;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 2px solid #1b4c92;
            transition: all 0.2s ease-in-out;
            background-color: #fff;

            i {
               position: relative;
               left: -0.5rem;
               margin-right: 0.5rem;
               transition: all 0.2s ease-in-out;
               filter: invert(19%) sepia(28%) saturate(5280%) hue-rotate(204deg) brightness(70%) contrast(85%);
            }

            p.total-container {
               display: flex;
               flex-direction: column;
               align-items: flex-start;
               position: relative;
               top: 0;
               transition-property: top;
               transition-timing-function: ease-in-out;
               transition-duration: 0.2s;
               .total-text {
                  color: #1b4c92;
                  font-size: var(--xsmall);
                  font-weight: 600;
                  transition: opacity, 0.2s, ease-in-out;
                  opacity: 1;
               }
               .total-price {
                  font-size: 1.25rem;
                  font-weight: 800;
                  color: #1b4c92;
               }
            }
         }
         .send-offer {
            color: #fff;
            text-align: center;
            width: 100%;
            display: block;
            text-decoration: underline;
            font-size: var(--small);
            cursor: pointer;

            &:hover {
               color: var(--accent-secondary);
            }
         }

         &:hover {
            button {
               background-color: #184990;
               border: 2px solid #184990;

               i {
                  left: -0.1rem;
                  filter: invert(100%) sepia(100%) saturate(10) hue-rotate(160deg) brightness(100%) contrast(200%);
               }
            }
            p.total-container {
               top: -10px;
               .total-text {
                  color: #1b4c92;
                  font-size: var(--xsmall);
                  font-weight: 600;

                  opacity: 0;
               }
               .total-price {
                  color: var(--accent-secondary);
               }
            }
         }
      }

      .location-names-wrapper {
         background-color: #345999;
         border: 2px dashed #274273;
         border-radius: 1rem;
         padding: 1rem;
         .location-name-item-wrapper {
            display: flex;
            flex-direction: column;
            margin-bottom: 1rem;
            .location-index {
               background: #233b66;
               display: flex;
               width: 32px;
               height: 32px;
               border-radius: 50%;
               flex-shrink: 0;
               justify-content: center;
               align-items: center;
               color: #fff;
               font-size: var(--large);
               font-weight: 500;
               margin-right: 0.5rem;
            }
            .location-full-name {
               color: #fff;
            }
         }
      }
   }
}
</style>
<style lang="scss" scoped>
@media (max-width: 991.98px) {
   .transfer-basket-container {
      position: fixed;
      z-index: 101;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      height: 100%;
      border-radius: 0;
      background-color: #fff;

      .transfer-basket-inner {
         background: transparent !important;

         .transfer-basket-header-wrapper {
            .transfer-basket-title {
               color: #363636 !important;
            }
            i {
               transform: scale(1.5);
               filter: brightness(0.25);
            }
         }

         section.transfer-basket-mobile-wrapper {
            .location-names-mobile-wrapper {
               background: #f2f2f2;
               border-radius: 0.5rem;
               margin: 1rem 0;
               padding: 1rem;

               .location-name-item-wrapper {
                  margin-bottom: 0.75rem;
                  .location-index {
                     font-size: var(--small);
                     color: var(--txt-black) !important;
                  }

                  .location-full-name {
                     font-size: var(--large) !important;
                     color: var(--txt-black) !important;
                     font-weight: 500;
                  }
               }
               .location-price-wrapper {
                  display: flex;
                  justify-content: flex-end;
                  span {
                     font-size: var(--large) !important;
                     color: var(--txt-black);
                     font-weight: 800;
                  }
               }
            }
            .transfer-basket-mobile-total-wrapper {
               display: flex;
               justify-content: flex-end;
               align-items: center;
               .total-price {
                  margin-left: 0.5rem;
                  color: var(--primary);
                  font-weight: 600;
               }
            }
         }
      }
   }
}
</style>
