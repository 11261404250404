<template>
   <div id="rent-a-car-sidebar-container">
      <!-- Car Image And Name Informations -->
      <section id="sidebar-info-wrapper">
         <img :src="infos.image" alt="" srcset="" />
         <div class="txt-wrapper">
            <span class="car-name">{{ infos.name }} </span>
            <span class="or-similar">{{ $t("rentacar_orSmiliar") }}</span>
         </div>
      </section>

      <!-- PickUpDropOff Informations -->
      <section id="sidebar-pickupdropoff-info-wrapper">
         <!-- PickUpLocation -->
         <div class="location-wrapper">
            <span class="location-label">{{ $t("rentacarCheckout_lblPickUpLocation") }}</span>
            <span class="location-name">{{ infos.pickUp.name }}</span>
            <span class="location-date">{{ infos.pickUp.date | fullDateTextFormat }} - {{ infos.pickUp.date | TimeFormat }}</span>
         </div>
         <!-- DropoffLocation -->
         <div class="location-wrapper">
            <span class="location-label">{{ $t("rentacarCheckout_lblDropOffLocation") }}</span>
            <span class="location-name">{{ infos.dropOff.name }}</span>
            <span class="location-date">{{ infos.dropOff.date | fullDateTextFormat }} - {{ infos.dropOff.date | TimeFormat }}</span>
         </div>
      </section>

      <!-- Ancillaries  -->
      <section v-if="infos.ancillaryTotal > 0" id="sidebar-ancillaries-info-wrapper">
         <span class="section-title">{{ $t("rentacarCheckout_lblAncillaries") }}</span>

         <ul class="sidebar-ancillaries-list-wrapper">
            <template v-for="(ancillary, index) in infos.ancillaries">
               <li :key="index" v-if="ancillary.ancillaryCount > 0">
                  <i class="gts-icon" :class="'icon-rentacar-' + $options.filters.ERacAncillaryTypeIcon(ancillary.ancillaryType)"></i>
                  <div class="ancillaries-info-wrapper">
                     <span class="ancillary-type">{{ $options.filters.ERacAncillaryType(ancillary.ancillaryType) }}</span>
                     <span class="ancillary-price">{{ ancillary.pricePerDay | price(currency) }}</span>
                  </div>
               </li>
            </template>
         </ul>
      </section>

      <!-- Price Summary -->
      <section id="sidebar-price-info-wrapper">
         <span class="section-title">{{ $t("rentacarCheckout_lblPriceSummary") }}</span>
         <!-- Vehicle Total -->
         <div class="price-wrapper">
            <span class="price-label">{{ $t("rentacarCheckout_lblVehicleTotal") }}</span>
            <span class="price"> {{ infos.vehicleTotal | price(currency) }}</span>
         </div>
         <!-- Ancillaries Total -->
         <div v-if="infos.ancillaryTotal > 0" class="price-wrapper">
            <span class="price-label">{{ $t("rentacarCheckout_lblAncillaryTotal") }}</span>
            <span class="price"> {{ infos.ancillaryTotal | price(currency) }}</span>
         </div>
         <!-- Grand Total -->
         <div class="price-wrapper grand-total">
            <span class="price-label">{{ $t("rentacarCheckout_lblGrandTotal") }}</span>
            <span class="price"> {{ infos.grandTotal | price(currency) }}</span>
         </div>
      </section>
   </div>
</template>

<script>
export default {
   name: "RentACarSideBar",
   props: {
      currency: String,
      infos: Object,
   },
};
</script>

<style lang="scss" scoped>
#rent-a-car-sidebar-container {
   background: var(--c-primary);
   padding: 18px 16px;
   margin: 1.5rem 0rem;
   border-radius: 0.5rem;
   position: sticky;
   top: 1rem;

   section {
      // margin: 0.5rem 0;
      margin-bottom: 1.5rem;
      padding-bottom: 1rem;
      border-bottom: 1px solid #656565;

      &:first-child,
      &:last-child {
         border-bottom: none;
      }
      &#sidebar-info-wrapper {
         display: flex;
         flex-direction: column;
         justify-content: center;

         .txt-wrapper {
            text-align: center;
            .car-name {
               font-weight: 600;
               font-size: var(--xlarge);
               text-align: center;
               margin-top: 1rem;
            }
            .or-similar {
               font-weight: 600;
               font-size: var(--small);
               text-decoration: underline;
            }
         }

         img {
            // filter: grayscale(100%);
            transform: scale(1.125);
         }
      }

      &#sidebar-pickupdropoff-info-wrapper {
         .location-wrapper {
            display: flex;
            flex-direction: column;
            margin-bottom: 1rem;

            .location-label {
               font-weight: 600;
            }
            .location-date {
               font-size: var(--small);
            }
         }
      }

      &#sidebar-ancillaries-info-wrapper {
         ul.sidebar-ancillaries-list-wrapper {
            li {
               background-color: #ebedf3;
               border: 1px solid #eee;
               margin-bottom: 0.5rem;
               display: flex;
               align-items: center;
               border-radius: 0.5rem;
               padding: 0.5rem 1rem;
               .ancillaries-info-wrapper {
                  display: flex;
                  flex-direction: column;
                  margin-left: 1rem;
                  span {
                     &.ancillary-type {
                     }
                     &.ancillary-price {
                        font-weight: 600;
                     }
                  }
               }
            }
         }
      }
      &#sidebar-price-info-wrapper {
         .price-wrapper {
            opacity: 0.6;
            display: flex;
            justify-content: space-between;

            span.price {
               font-size: var(--large);
               font-weight: 500;
            }

            &.grand-total {
               opacity: 1;
               font-weight: 600;
               font-size: var(--large);

               .price {
                  font-weight: 800;
               }
            }
         }
      }
   }
}
</style>
<style lang="scss" scoped>
#rent-a-car-sidebar-container2 {
   background: var(--c-primary);
   padding: 18px 16px;
   margin: 1.5rem 0rem;
   border-radius: 0.5rem;
   .sidebar-header-wrapper {
      display: flex;
      border-bottom: 1px solid #eee;
      padding-bottom: 0.5rem;

      span {
         &.car-name {
            font-weight: 500;
         }
      }
   }
   .sidebar-body-wrapper {
      margin-top: 1rem;
      display: flex;
      justify-content: space-between;

      span.grand-total {
         font-weight: 600;
         font-size: var(--large);
      }
   }
}
</style>
