<template>
   <div class="credit-card-container">
      <div class="credit-card-wrapper">
         <div class="cc-form-container">
            <UIInput
               :label="$t('creditCardComponent_holderLabel')"
               type="text"
               id="input_creditCardFullName"
               v-model="creditcard.holder"
               v-input="{ type: 'alphabetic', required: true, len: [2, 25], requiredMsg: $t('common_cannotBeEmpty') }" />

            <UIInput
               :label="$t('creditCardComponent_panLabel')"
               type="text"
               id="input_creditCardPan"
               v-model="creditcard.pan"
               v-input="{ type: 'numeric', required: true, pattern: 'xxxx xxxx xxxx xxxx', requiredMsg: $t('common_cannotBeEmpty') }" />
            <div class="cc-input-group">
               <div class="cc-date-group">
                  <UIInput
                     :label="$t('creditCardComponent_monthLabel')"
                     type="text"
                     id="input_creditCardMonth"
                     v-model="creditcard.month"
                     v-input="{ type: 'numeric', required: true, pattern: 'xx', requiredMsg: $t('common_cannotBeEmpty') }" />
                  <UIInput
                     :label="$t('creditCardComponent_yearLabel')"
                     type="text"
                     id="input_creditCardYear"
                     v-model="creditcard.year"
                     v-input="{ type: 'numeric', required: true, pattern: 'xx', requiredMsg: $t('common_cannotBeEmpty') }" />
               </div>
               <UIInput
                  :label="$t('creditCardComponent_cvvLabel')"
                  type="text"
                  id="input_creditCardCVV"
                  ref="cvv"
                  v-model="creditcard.cvv"
                  v-input="{ type: 'numeric', required: true, pattern: 'xxx', requiredMsg: $t('common_cannotBeEmpty') }" />
            </div>
         </div>

         <div class="cc-mockup-container">
            <div class="cc-mockup-wrapper" :class="flipBack ? 'show-back' : ''">
               <div class="cc-card-front">
                  <i class="gts-icon icon-cc-visa icon-xl" v-if="isVisa"></i>
                  <i class="gts-icon icon-cc-mastercard icon-xl" v-else></i>
                  <div class="cc-informations-container">
                     <div class="cc-informations-row">
                        <span class="cc-pan">{{ creditcard.pan ? handlerCreditCardPan() : "XXXX XXXX XXXX XXXX" }}</span>
                     </div>

                     <div class="cc-informations-row">
                        <span class="cc-name"> {{ creditcard.holder ? creditcard.holder : "________ _______" }} </span>
                        <span class="cc-expiry"> {{ creditcard.month ? creditcard.month : "__ " }}/{{ creditcard.year ? creditcard.year : " __" }} </span>
                     </div>
                  </div>
               </div>
               <div class="cc-card-back">
                  <span class="cc-card-back-stripe">
                     <i> {{ creditcard.cvv }}</i>
                  </span>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>
import UIInput from "@/components/unitary/form2/UIInput.vue";

export default {
   name: "PaymentCreditcard",

   components: {
      UIInput,
   },
   data() {
      return {
         isVisa: true,
         creditcard: {
            holder: "",
            pan: "",
            month: "",
            year: "",
            cvv: "",
         },
         flipBack: false,
      };
   },
   mounted() {
      const self = this;
      const el = this.$refs.cvv.$el.querySelector("input");
      el.addEventListener("focus", function () {
         self.flipBack = true;
      });
      el.addEventListener("blur", function () {
         self.flipBack = false;
      });
   },
   methods: {
      GetCardType(number) {
         // visa
         var re = new RegExp("^4");
         if (number.match(re) != null) return "Visa";

         // Mastercard
         // Updated for Mastercard 2017 BINs expansion
         if (/^(5[1-5][0-9]{14}|2(22[1-9][0-9]{12}|2[3-9][0-9]{13}|[3-6][0-9]{14}|7[0-1][0-9]{13}|720[0-9]{12}))$/.test(number)) return "Mastercard";

         // AMEX
         re = new RegExp("^3[47]");
         if (number.match(re) != null) return "AMEX";

         // Discover
         re = new RegExp("^(6011|622(12[6-9]|1[3-9][0-9]|[2-8][0-9]{2}|9[0-1][0-9]|92[0-5]|64[4-9])|65)");
         if (number.match(re) != null) return "Discover";

         // Diners
         re = new RegExp("^36");
         if (number.match(re) != null) return "Diners";

         // Diners - Carte Blanche
         re = new RegExp("^30[0-5]");
         if (number.match(re) != null) return "Diners - Carte Blanche";

         // JCB
         re = new RegExp("^35(2[89]|[3-8][0-9])");
         if (number.match(re) != null) return "JCB";

         // Visa Electron
         re = new RegExp("^(4026|417500|4508|4844|491(3|7))");
         if (number.match(re) != null) return "Visa Electron";

         return "";
      },
      handlerCreditCardPan() {
         return this.creditcard.pan.toString().replace(/\d{4}(?=.)/g, "$& ");
      },
      validate() {
         if (this.creditcard == null) return "CREDITCARD";
         if (this.creditcard.holder == null || this.creditcard.holder.length < 3) return "HOLDER";
         if (this.creditcard.pan == null || this.creditcard.pan.length < 16) return "PAN";
         if (this.creditcard.month == null || this.creditcard.month.length != 2) return "MONTH";
         if (this.creditcard.year == null || this.creditcard.year.length != 2) return "YEAR";
         if (this.creditcard.cvv == null || this.creditcard.cvv.length != 3) return "CVV";
         return null;
      },
      fetchEntry() {
         let entry = this.creditcard;
         entry.pan = entry.pan.replaceAll(" ", "");
         // alert(JSON.stringify(entry));
         return entry;
      },
   },
   watch: {
      //TODO:WILL_BE_REMOVED, CARD_DATA_NEVER_BE_STORED_IN_RAM, USE:fetchEntry
      creditcard: {
         handler: function (val) {
            this.isVisa = this.GetCardType(val.pan) == "Visa" ? true : false;
            const self = this;
            const creditcard = {
               holder: self.creditcard.holder,
               pan: self.creditcard.pan.clearAllSpaces(),
               month: self.creditcard.month,
               year: self.creditcard.year,
               cvv: self.creditcard.cvv,
            };

            this.$store.commit("finance/updateCreditCard", creditcard);
         },
         deep: true,
      },
   },
};
</script>
<style lang="scss" scoped>
.credit-card-container {
   width: 100%;
   ::v-deep {
      input {
         text-indent: 0.5rem !important;
      }
   }
   .credit-card-wrapper {
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      // display: grid;
      // grid-template-columns: 1.5fr 3fr;
      width: 100%;

      .cc-mockup-container {
         display: none;
         background-color: transparent;
         height: 220px;
         width: 360px;
         perspective: 1000px;
         flex-shrink: 0;

         @media (min-width: 576px) {
            display: flex;
         }

         .cc-mockup-wrapper {
            position: relative;
            width: 100%;
            height: 100%;
            transition: transform 0.6s;
            transform-style: preserve-3d;

            @media (min-width: 576px) {
            }

            .cc-card-front,
            .cc-card-back {
               position: absolute;
               border-radius: 1rem;
               padding: 1rem;
               padding-bottom: 2rem;
               width: 100%;
               height: 100%;
               -webkit-backface-visibility: hidden;
               backface-visibility: hidden;
               display: flex;
               flex-direction: column;
               justify-content: space-between;
            }

            .cc-card-front {
               background-color: var(--accent-primary);

               .cc-informations-container {
                  width: fit-content;
                  min-width: 216px;
                  .cc-informations-row {
                     display: flex;
                     justify-content: space-between;

                     span {
                        color: #fff;
                        &.cc-pan {
                           font-family: monospace;
                           width: 100%;
                           font-size: var(--large);
                           font-weight: 600;
                        }
                     }
                  }
               }
            }

            .cc-card-back {
               background-color: #dedede;
               display: flex;
               flex-direction: column;
               align-items: flex-end;
               justify-content: flex-end;
               transform: rotateY(180deg);

               .cc-card-back-stripe {
                  width: 100%;
                  height: 40px;
                  display: flex;
                  justify-content: flex-end;
                  align-items: center;
                  border-radius: 0.5rem;
                  background: #ececec;

                  i {
                     margin-right: 1rem;
                  }
               }
            }

            &.show-back {
               transform: rotateY(180deg);
            }
         }
      }

      .cc-form-container {
         margin-right: 1.5rem;

         .g-input-container {
            margin-top: 0.5rem;
         }
         .cc-input-group {
            display: grid;
            grid-template-columns: 0.5fr 1fr;
            grid-column-gap: 1rem;

            .cc-date-group {
               display: grid;
               grid-template-columns: 1fr 1fr;
               grid-column-gap: 1rem;
            }
         }
      }
   }
}
</style>
<style lang="scss" scoped>
.credit-card-wrapper {
   .credit-card-inner {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      .credit-card-section {
         width: 47.5%;
         height: 250px;
         max-height: 250px;
         min-height: 250px;
         .credit-card-section-inner {
            height: 100%;

            .form-item {
               width: 100%;
               margin-bottom: 16px;
            }
            .credit-card-input-group {
               display: flex;
               justify-content: space-between;

               .date-group {
                  display: inline-flex;

                  & > .form-item {
                     width: 30%;
                     margin-right: 16px;

                     &:last-child {
                        margin-right: 0;
                     }
                  }
               }

               & > .form-item {
                  width: 40%;
               }
            }
            .credit-card-preview-wrapper {
               position: relative;
               width: 100%;
               height: 100%;
               background-color: #2974c7;
               background: linear-gradient(300deg, var(--primary) 0%, var(--accent-primary) 100%);
               border-radius: 18px;
               transition: transform 0.6s;
               transform-style: preserve-3d;
               box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);

               &.cvv-mode {
                  transform: rotateY(180deg);
               }

               // &::after {
               //     content: url("../../../assets/images/account_bg.svg");
               //     display: block;
               //     position: absolute;
               //     right: 0;
               //     bottom: -8px;
               //     opacity: 0.5;
               // }
               .credit-card-preview-inner-front,
               .credit-card-preview-inner-back {
                  position: absolute;
                  width: 100%;
                  height: 100%;
                  -webkit-backface-visibility: hidden;
                  backface-visibility: hidden;
               }
               .credit-card-preview-inner-back {
                  background: var(--accent-primary);
                  transform: rotateY(180deg);
               }
               .credit-card-preview-inner {
                  padding: 20px;
                  .credit-card-preview-item {
                     &:first-child {
                        margin-bottom: 24px;
                     }
                     &:nth-child(2) {
                        margin-bottom: 42px;
                     }
                     img {
                        width: 90px;
                     }
                     span.pan-text,
                     span.holder-text,
                     span.date-text {
                        font-size: 18px;
                        font-weight: 600;
                        color: #9dddfe;
                     }
                     span.holder-text {
                        text-transform: uppercase;
                     }

                     span.pan-text {
                        font-weight: 500;
                        font-size: 18px;
                        color: #9ddcff;
                        font-family: monospace;
                     }
                  }
               }
            }
         }
      }
   }
}
</style>
