<template>
   <section class="widget-body-container">
      <template v-if="properties.pending">
         <div class="widget-loader-wrapper">
            <span class="g-radial-loader"> </span>
         </div>

         <!-- loading... -->
      </template>

      <template v-else>
         <component :is="WidgetType" :properties="properties"></component>
      </template>
   </section>
</template>

<script>
import MultiTable from "@/components/widgets/MultiTable.vue";
import Giros from "@/components/widgets/Giros.vue";
import Announcement from "@/components/widgets/Announcement.vue";
import Campaign from "@/components/widgets/Campaign.vue";

export default {
   name: "Body",
   props: {
      properties: Object,
   },
   components: {
      MultiTable,
      Giros,
      Announcement,
      Campaign,
   },
   data() {
      return {};
   },
   mounted() {},
   computed: {
      WidgetType() {
         switch (this.properties?.type) {
            case 1:
               return "MultiTable";
            case 2:
               return "MultiTable";
            case 3:
               return "Giros";
            case "announcement":
               return "Announcement";
            case "campaign":
               return "Campaign";
            default:
               return "MultiTable";
         }
      },
   },
   methods: {},
};
</script>

<style lang="scss" scoped>
.widget-body-container {
   width: 100%;
   padding: 1rem 1rem;
   transition-property: all;
   transition-timing-function: ease-in-out;
   transition-duration: 0.15s;

   @media (min-width: 992px) {
      padding: 1rem 1.5rem;
   }
}
</style>
<style lang="scss">
.widget-loader-wrapper {
   display: flex;
   align-items: center;
   justify-content: center;
   padding: 2rem;
   .g-radial-loader {
      $size: 42px;
      width: $size;
      height: $size;
      border-width: 4px;
      border-color: #363636;
   }
}
</style>
