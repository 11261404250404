<template>
   <section class="checkout-section hotel-descriptions-section gts-box with-border">
      <div class="checkout-section-inner">
         <div class="checkout-section-title">
            <span class="section-title">{{ $t("hotelCheckout_lblHotelDescriptionsTitle") }} </span>

            <p>{{ $t("hotelCheckout_lblHotelDescriptions") }}</p>
         </div>
         <div class="checkout-hotel-descriptions-wrapper">
            <div class="checkout-hotel-descriptions-inner">
               <UIAlert status="info_orange_2">
                  <div class="description-alert-icon">
                     <i class="gts-icons icon-inline-alert"></i>
                  </div>
                  <div class="description-alert-content">
                     <p>{{ $t("hotelCheckout_lblHotelDescriptionsAlert") }}</p>
                  </div>
               </UIAlert>
            </div>
            <div class="checkout-hotel-descriptions-inner">
               <UITextarea :labelText="$t('common_agencyNote')" id="textarea_checkoutAgencyNote" v-model="hotelDescription.agencyNote" />
            </div>
            <template v-if="isExpedia">
               <div class="checkout-hotel-descriptions-inner">
                  <span class="section-title">{{ $t("hotelCheckout_lblHotelProviderInstructionsTitle") }} </span>
                  <p>{{ $t("hotelCheckout_lblHotelProviderInstructionsDescriptions") }}</p>
                  <div class="instuctions-wrapper" :class="showDetails ? '--showDetails' : ''">
                     <div class="instructions" :class="showDetails ? '--showDetails' : ''">{{ providerInstructions }}</div>
                     <div class="instructions-toggler" @click="showDetails = !showDetails" :class="showDetails ? 'details-active' : ''">
                        <i class="gts-icon icon-arrow-down icon-sm"></i>
                     </div>
                  </div>
               </div>
               <div class="checkout-hotel-descriptions-inner" v-if="providerViewInfo.priceViewType == 2">
                  <div class="terms-wrapper">
                     <UICheckBox :id="'terms'" v-model="checked" v-required />
                     <div class="terms-text">
                        {{ $t("hotelCheckout_lblHotelTerms") }}
                        <span @click="handlerClickedTerm('agent')" class="txt-black pointer">{{ $t("hotelCheckout_lblHotelAgentTerms") }}</span>
                        {{ $t("hotelCheckout_lblHotelAnd") }}
                        <span @click="handlerClickedTerm('supplier')" class="txt-black pointer">{{ $t("hotelCheckout_lblHotelSupplierTerms") }}</span>
                     </div>
                  </div>
               </div>
            </template>
         </div>
      </div>
      <div v-if="isExpedia" class="col-12">
         <UIModal
            v-show="showModal"
            :modalContent="htmlContent"
            :modalTitle="modalTitle"
            :hasHtmlContent="true"
            @clicked-term="handlerClickedTerm"
            @close-modal="showModal = false" />
      </div>
   </section>
</template>

<script>
import UITextarea from "@/components/unitary/form/Textarea.vue";
import UIAlert from "@/components/unitary/Alert.vue";
import UICheckBox from "@/components/unitary/form2/UICheckBox.vue";
import UIModal from "@/components/unitary/form2/UIModal.vue";
import { getExpediaTerms, getExpediaSupplierTerms } from "@/service/hotel.api";

export default {
   name: "HotelDescriptions",
   components: {
      UITextarea,
      UIAlert,
      UICheckBox,
      UIModal,
   },
   props: {
      providerInstructions: {
         type: String,
         default: "",
      },
      isExpedia: {
         type: Boolean,
      },
      providerViewInfo: { type: Object },
   },
   data() {
      return {
         showDetails: false,
         showModal: false,
         checked: true,
         htmlContent: null,
         modalTitle: null,
         activeLanguage: null,
         hotelDescription: {
            agencyNote: "",
         },
      };
   },
   mounted() {
      switch (this.$store.state.user.user.language) {
         case 1:
            this.activeLanguage = "tr";
            break;

         case 2:
            this.activeLanguage = "en";
            break;

         case 3:
            this.activeLanguage = "de";
            break;

         default:
            this.activeLanguage = "en";
            break;
      }
   },
   methods: {
      handlerClickedTerm(type) {
         this.showModal = true;
         type == "agent"
            ? getExpediaTerms(this.activeLanguage).then((res) => {
                 this.htmlContent = res;
                 this.modalTitle = this.$t("hotelCheckout_lblHotelAgentTerms");
              })
            : getExpediaSupplierTerms(this.activeLanguage).then((res) => {
                 this.htmlContent = res;
                 this.modalTitle = this.$t("hotelCheckout_lblHotelSupplierTerms");
              });
      },
   },
   watch: {
      hotelDescription: {
         handler: function (newValue) {
            this.$emit("update-to-hotel-description", newValue);
         },
         deep: true,
      },
      "$i18n.locale"(newValue) {
         const self = this;
         self.activeLanguage = newValue;
      },
   },
};
</script>

<style lang="scss" scoped>
.checkout-section-title {
   margin-bottom: 1.5rem;
   & > h4 {
      margin-bottom: 10px;
   }
   & > p {
      font-size: 14px;
      color: #1e2023;
   }
}
.checkout-hotel-descriptions-wrapper {
   .checkout-hotel-descriptions-inner {
      padding-bottom: 40px;
      margin-bottom: 32px;
      border-bottom: 1px dotted #cccccc;

      .instuctions-wrapper {
         white-space: break-spaces;
         box-shadow: 0px 0px 0px #7070703c;
         border: 2px solid #3c67b2;
         border-radius: 8px;
         max-height: 80px;
         display: flex;
         overflow: hidden;
         padding: 0px 12px 12px 12px;
         margin-top: 16px;

         &.--showDetails {
            max-height: 100%;
            overflow: auto;
         }
         .instructions-toggler {
            background-color: #eaeaea;
            padding: 0.5rem 0.5rem;
            border-radius: 8px;
            display: flex;
            align-items: center;
            height: fit-content;
            margin-left: 1rem;
            margin-top: 1rem;
            cursor: pointer;
            align-self: center;

            i {
               transform: rotate(0deg);
               transition-property: transform;
               transition-timing-function: ease-in-out;
               transition-duration: 0.25s;
               width: 40px !important;
               height: 38px !important;
            }

            &.details-active {
               i {
                  transform: rotate(-180deg);
               }
            }

            &:hover {
               background-color: #d8d8d8;
            }
         }
      }

      .terms-wrapper {
         box-shadow: 0px 0px 0px #7070703c;
         border: 2px solid #3c67b2;
         border-radius: 8px;
         padding: 19px 21px;
         margin-top: 16px;
         display: flex;

         .terms-text {
            margin-left: 12px;
            .pointer {
               cursor: pointer;
            }
         }
      }

      &:last-child {
         padding-bottom: 0;
         margin-bottom: 0;
         border-bottom: none;
      }
      ::v-deep .gts-alert_info_orange {
         border-style: dotted !important;

         i {
            display: block;
            width: 32px;
            height: 32px;
            margin-right: 18px;
            background-repeat: no-repeat;
            background-size: contain;
            filter: brightness(0) saturate(100%) invert(71%) sepia(61%) saturate(3072%) hue-rotate(335deg) brightness(104%) contrast(104%);
         }
      }
      & .form-item {
         width: 100%;

         & > ::v-deep label {
            font-size: 16px;
            font-weight: 700;
            color: #363940;
         }
      }
   }
}
</style>
