<template>
   <div class="guest-list-item" v-click-outside="handlerFillCustomer">
      <section class="customer-management-wrapper" v-if="showFihrist">
         <CustomerManagement ref="customerListModal" @set-customer="handlerSetCustomer" show-close />
      </section>
      <div class="guest-form-wrapper" :id="'guestItem-' + guest.guestItemIndex">
         <div class="pax-row-header">{{ guest.guestItemIndex+1 }}. {{ $t("guestItem_txtGuest") }} {{ guest.type | EFltPaxType }}</div>
         <div class="form-row">
            <div class="form-item">
               <UISelectBox :options="genders" v-model="guest.gender" :selectedOpt="guest.guestItemIndex % 2 ? 1 : 2" light-theme />
            </div>
            <div class="form-item">
               <UIInput
                  type="text"
                  id="input_guestName"
                  :placeholder="$t('common_name')"
                  v-model="guest.name"
                  ac-tag="name"
                  :customers="customerList"
                  @set-customer="handlerSetCustomer"
                  autocomplete
                  v-input="{ type: 'alphabetic', len: [2, 30], required: true, requiredMsg: $t('common_cannotBeEmpty') }" />
            </div>
            <div class="form-item">
               <UIInput
                  type="text"
                  id="input_guestSurName"
                  :placeholder="$t('common_surname')"
                  v-model="guest.surname"
                  ac-tag="surname"
                  :customers="customerList"
                  @set-customer="handlerSetCustomer"
                  v-input="{ type: 'alphabetic', len: [2, 30], required: true, requiredMsg: $t('common_cannotBeEmpty') }" />
            </div>
            <div class="form-item" v-show="this.guest.type == 2">
               <UIDatePicker v-model="guest.birthdate" :min="guest.minDate" :max="guest.maxDate" />
            </div>
         </div>
      </div>
      <div class="guest-cta-group">
         <UIButton className="default" icon="user-search" modalTrigger="true" @click.native="triggerCustomerListModal" />
      </div>
   </div>
</template>

<script>
import moment from "moment";
import UIInput from "@/components/unitary/form2/UIInput.vue";
import UIButton from "@/components/unitary/Button.vue";
import UIDatePicker from "@/components/unitary/form2/UIDatePicker2.vue";
import { customerService } from "@/service/index.js";
import CustomerManagement from "@/components/structural/flight/common/CustomerManagement.vue";
import UISelectBox from "@/components/unitary/form2/UISelectBox.vue";

export default {
   name: "RoomGuestItem",
   components: {
      UIInput,
      UIButton,
      UISelectBox,
      UIDatePicker,
      CustomerManagement,
   },
   props: {
      roomIndex: { type: Number },
      contactModel: { type: Object },
      customerNationality: { String },
      guestItemIndex: { String },
      pax: { Object },
   },
   data() {
      return {
         genders : this.$GtsDropdownData.EComGender(),
         showFihrist: false,
         guest: {
            dbState: 1,
            status: 1,
            birthdate:
               this.pax.type == 1
                  ? this.$constants.customerBirthdate.format(this.$constants.dateFormat)
                  : moment(this.pax.minDate).format(this.$constants.dateFormat),
            minDate:
               this.pax.type == 1
                  ? this.$constants.customerBirthdate.format(this.$constants.dateFormat)
                  : moment(this.pax.minDate).format(this.$constants.dateFormat),
            maxDate: this.pax.type == 1 ? moment().format(this.$constants.dateFormat) : moment(this.pax.maxDate).format(this.$constants.dateFormat),
            gender: null,
            name: null,
            surname: null,
            type: this.pax.type,
            index: this.pax.index,
            guestItemIndex: this.guestItemIndex,
         },
         customerList: [],
         listCustomerRequestModel: {
            status: 1,
         },
      };
   },
   created() {
      const self = this;
      customerService.listCustomers({ status: 1 }).then((res) => {
         self.customerList = res.customers;
      });
   },
   computed: {
      guestElementId() {
         return `guestItem-${this.guestItemIndex}`;
      },
      genderElementId() {
         return `genderElement-${this.guestItemIndex}`;
      },
   },
   methods: {
      triggerCustomerListModal() {
         const self = this;
         self.showFihrist = true;
      },
      selectedCustomer(customer) {
         const self = this;

         let selectedCustomer = self.tableOptions.tableRows.filter((customerItem) => customerItem.id == customer)[0];

         self.guest.birthdate = moment(selectedCustomer.birthdate, "DD.MM.YYYY").format(this.$constants.dateFormat);
         self.guest.gender = selectedCustomer.hiddenGenderValue;
         //self.$children[0].$children[0].$attrs.value = self.guest.gender;
         self.guest.name = selectedCustomer.name;
         self.guest.surname = selectedCustomer.surname;
         self.handlerShowModal();
         if (this.roomIndex == 0 && this.guestItemIndex == 0) {
            this.contactModel.email = selectedCustomer.email;
            this.contactModel.phone.country =
               selectedCustomer.phoneModel.country == null || selectedCustomer.phoneModel.country == "" ? "+49" : selectedCustomer.phoneModel.country;
            this.contactModel.phone.city = selectedCustomer.phoneModel.city;
            this.contactModel.phone.number = selectedCustomer.phoneModel.number;
         }
      },
      handlerFillCustomer() {
         const self = this;
         if (self.guest.name != null || self.guest.surname != null || self.guest.birthdate != null) {
            self.guest.gender;
            self.guest.name;
            self.guest.surname;
            self.guest.birthdate;
         }
      },
      handlerSetCustomer(customer) {
         const self = this;
         self.guest.name = customer.name;
         self.guest.surname = customer.surname;
         self.guest.gender = customer.gender;
         self.guest.birthdate = self.guest.type == 1 ? self.guest.birthdate : moment(customer.birthdate).format(this.$constants.dateFormat);
      },
      handlerUpdateGender(selectedGender) {
         var self = this;
         self.guest.gender = selectedGender;
      },
      setInitialGenderValue(newGender) {
         const self = this;
         self.guest.gender = newGender;
      },
   },
   watch: {
      'pax': {
    handler: function(newVal) {
            this.guest.name = newVal.name
            this.guest.gender = newVal.gender
            this.guest.surname = newVal.surname
    },
    deep: true // Bu, pax içindeki derin değişiklikleri de izlemek için gerekebilir
  },
      guest: {
         handler: function (newValue) {
            var self = this;

            //Tech-debt: Fixed v-upper validation directive
            if (newValue.name != null) {
               self.guest.name = newValue.name.toUpperCase();
            }
            if (newValue.surname != null) {
               self.guest.surname = newValue.surname.toUpperCase();
            }

            self.guest.gender = Number(newValue.gender);
            return this.$emit("update-to-room-guest-info", { ...newValue });
            // this.$store.commit('hotel/setCheckoutGuestInfo', self.guest);
         },
         deep: true,
      },
      "$store.state.customer.customers"(newCustomerList) {
         const self = this;
         self.customerList = newCustomerList;
      },
      customerList: {
         handler: function (newList) {
            var self = this;
            const newArray = [];
            newList.map((customerItem) => {
               const rowObj = {
                  id: customerItem.id,
                  gender: customerItem.gender === 1 ? this.$t("common_male") : this.$t("common_female"),
                  name: customerItem.name,
                  surname: customerItem.surname,
                  birthdate: moment(customerItem.birthdate).format("DD.MM.YYYY"),
                  email: customerItem.email,
                  phone: `${
                     customerItem.phone.number != null
                        ? customerItem.phone.country + "" + customerItem.phone.city + "" + customerItem.phone.number
                        : ""
                  }`,
                  phoneModel: {
                     country: customerItem.phone.country,
                     city: customerItem.phone.city,
                     number: customerItem.phone.number,
                  },
                  nationality: customerItem.nationality,
                  nationalIdentity: customerItem.nationalIdentity,
                  passport: `${customerItem.passport.number != null ? customerItem.passport.serial + "-" + customerItem.passport.number : ""}`,
                  milesPan: customerItem.milesPan,
                  hesCode: customerItem.hesCode,
                  hiddenGenderValue: customerItem.gender,
               };
               newArray.push(rowObj);
            });
            self.tableOptions = newArray;
         },
         deep: true,
      },
   },
};
</script>

<style lang="scss" scoped>
.guest-list-item {
   display: flex;
   align-items: center;
   justify-content: space-between;
   width: 100%;
   background-image: linear-gradient(to right, #e1e1e1 50%, rgba(255, 255, 255, 0) 50%);
   background-position: bottom;
   background-size: 10px 2px;
   background-repeat: repeat-x;
   padding-bottom: 16px;
   margin-bottom: 3.5rem;

   &:last-child {
      padding-bottom: 0;
      margin-bottom: 0;
      background-image: none;
   }
   .guest-form-wrapper {
      display: flex;
      flex-direction: column;
      display: flex;
      flex-direction: column;
      position: relative;
      .pax-row-header {
         position: absolute;
         top: -2.5rem;
         left: 0;
         background: #fff;
         border: 1px solid #ccc;
         border-radius: 16px;
         padding: 0.25rem 1rem;
         font-size: var(--small);
         font-weight: 600;
      }
      & .form-row {
         width: 100%;
         display: flex;
         flex-direction: row;
         align-items: flex-start;

         .form-item {
            min-width: 150px;
            max-width: 150px;
            margin-right: 16px;

            &:last-child {
               margin-right: 0;
            }
         }
      }
      @media (max-width: 767.98px) {
         & .form-row {
            flex-direction: column;
            .form-item {
               min-width: -webkit-fill-available;
               max-width: -webkit-fill-available;
               width: -webkit-fill-available;
            }
         }
      }
   }
   @media (max-width: 767.98px) {
      .guest-form-wrapper {
         flex-grow: 1;
      }
   }
   .guest-cta-group {
      width: 10%;
      display: flex;
      justify-content: flex-end;

      & > button {
         width: auto;
         background: none;
         padding: 0;
         font-size: 24px;
         margin-right: 24px;
         cursor: pointer;

         &:hover {
            background: none;
         }

         &:last-child {
            margin-right: 0;
         }
      }
   }

   ::v-deep .gts-modal-wrapper .cta-group-column {
      & > div {
         & > button {
            &:first-child {
               margin-right: 8px;

               & i {
                  filter: brightness(0) invert(1);
               }
            }
            &:last-child {
               & i {
                  filter: invert(100%) sepia(0%) saturate(7500%) hue-rotate(244deg) brightness(106%) contrast(99%);
               }
            }
         }
      }
   }
   .customer-management-wrapper {
      position: absolute;
      z-index: 99;
      width: 100%;
      transform: translateY(44%);
      border: 1px solid #ccc;
      border-radius: 1rem;
      right: 0;
      box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;

      @media (min-width: 992px) {
         width: 800px;
      }
   }
}
.customer-book-form-cta {
   display: flex;
   justify-content: flex-end;
}
</style>
