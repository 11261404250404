<template>
    <form class="form-wrapper" :id="id">
        <div class="form-inner">
            <div class="form-title" v-if="formTitle">
                <h4>{{ formTitle }}</h4>
            </div>
            <div class="form-inner-wrapper">
                <slot></slot>
            </div>
        </div>
    </form>
</template>

<script>
export default {
    name: "UIForm",
    props: {
        id: { String },
        formTitle: { String },
    },
};
</script>

<style lang="scss" scoped>
.form-wrapper {
    width: 100%;
    .form-inner {
        .form.title {
            h4 {
            }
        }
        .form-inner-wrapper {
            display: flex;
            flex-direction: column;
            .form-row {
                width: 100%;
                display: flex;
                flex-direction: row;
                align-items: flex-start;

                .form-item {
                    label {
                        margin-bottom: 8px;
                    }
                }
            }
        }
    }
}
</style>
