<template>
   <div class="form-item">
      <label :for="id" v-if="labelText">{{ labelText }}</label>
      <input
         :type="type"
         name="gts-input"
         :id="id"
         :placeholder="placeHolder"
         @input="$emit('input', $event.target.value)"
         :maxlength="maxlength"
         :value="value"
         :disabled="disabled"
         :autocomplete="autocomplete"
         :class="customClass" />
   </div>
</template>

<script>
export default {
   name: "UIInput",
   props: {
      type: { String },
      placeHolder: { String },
      id: { String },
      labelText: { String },
      maxlength: { String },
      value: null,
      disabled: { Boolean },
      autocomplete: { String },
      customClass: String,
   },
};
</script>

<style lang="scss" scoped>
.form-item {
   width: max-content;
   height: max-content;

   & > label {
      margin-bottom: 8px;
   }

   & > input {
      width: 100%;
      height: 32px;
      max-height: 32px;
      padding: 8px 0;
      border: 1px solid #b1b2b3;
      border-radius: 16px;
      font-size: 12px;
      color: #363940;
      text-indent: 16px;
      outline: none;
      transition: 0.15s ease-in-out;

      &::placeholder {
         color: #6d6e6f;
      }

      &:focus,
      &:active {
         border-color: #33b8ff;
         box-shadow: 0 3px 5px 0 rgba(51, 184, 255, 0.5);
      }
   }
}
</style>
