<template>
   <div class="reissue-selection-container">
      <!-- {{ requestModel }} - {{ AllowSearch }} -->
      <!-- Flights Loop -->
      <template v-for="(flight, flightIndex) in flights">
         <div class="flight-selection-wrapper" :key="flightIndex">
            <section class="flight-selection-informations-wrapper">
               <!-- Flight Informations -->
               <div class="flight-selection-information-section-wrapper">
                  <!-- Flight Index -->
                  <div class="flight-index">{{ flight.flightIndex }}. {{ $t("ancillariesServicesModal_lblFlight") }}</div>
                  <!-- Airline Img -->
                  <img
                     class="airline-logo"
                     :src="`${$constants.cdn}/airline_34x34/${flight.marketingAirlineCode}.svg`"
                     alt=""
                     width="36"
                     height="36" />

                  <!-- Flight Route -->
                  <div class="flight-route-wrapper">
                     <span class="departure-airport">{{ flight.departureAirportConstant }}</span>
                     <span class="flight-icon"></span>
                     <span class="arrival-airport">{{ flight.arrivalAirportConstant }}</span>
                  </div>
                  <!-- Departure Date -->
                  <div class="departure-datetime-wrapper">
                     <span class="datetime-label"> {{ $t("ancillariesServicesModal_lblDepartureDate") }}</span>
                     <span class="datetime-info">{{ flight.departureDateConstant | FormatterDD_MM_YYYY_HH_mm }}</span>
                  </div>
                  <div class="arrival-datetime-wrapper">
                     <span class="datetime-label">{{ $t("ancillariesServicesModal_lblArrivalDate") }}</span>
                     <span class="datetime-info">{{ flight.arrivalDate | FormatterDD_MM_YYYY_HH_mm }}</span>
                  </div>
               </div>
               <!-- {{ flight.flightIndex }} -- {{ flight.showInputs }} -->

               <!-- Toggler -->
               <div class="flight-selection-toggler-wrapper">
                  <div class="toggle-wrapper">
                     <b-switch
                        v-model="flight.showInputs"
                        :disabled="flight.disabled"
                        @input="handlerUnputController(flight.flightIndex, flight.showInputs)">
                     </b-switch>
                  </div>
               </div>
            </section>
            <!-- {{ AllowSearch }} - {{ MinDateCtrl }} -->
            <AncillaryReissueInputsWrapper
               v-if="flight.showInputs"
               :flight="flight"
               @leg-request-model="handlerLegRequestModel"
               :min-date-ctrl="MinDateCtrl" />
         </div>
      </template>

      <div class="user-directives-wrapper" v-show="uiHelper">
         <!-- <p>{{ $t("ancillariesServicesModal_infoTxtPlsSelectFlights") }}</p> -->
         <p v-html="handlerFMT()"></p>
         <!-- <p>Please select the flights, via <i class="toggle-icon"></i>button, you want to reissue.</p> -->
      </div>
   </div>
</template>

<script>
import AncillaryReissueInputsWrapper from "@/components/structural/flight/order-detail/ancillaries/ancillary-reissue/AncillaryReissueInputsWrapper.vue";
import moment from "moment";
export default {
   name: "AncillaryReissueSelection",
   components: {
      AncillaryReissueInputsWrapper,
   },
   props: {
      flightsObj: Array,
      availableType: Number,
   },
   data() {
      return {
         uiHelper: true,
         flights: this.flightsObj,
         requestModel: {
            flightIds: [],
            legs: {},
         },
      };
   },

   computed: {
      MinDateCtrl() {
         const isRT = this.flights.length == 2 ? true : false;
         const isEveryInputsEnabled = this.flights.every((item) => {
            return item.showInputs;
         });
         const isSecondFlightEnabled = isRT && Object.keys(this.requestModel.legs)[0] == 2 ? true : false;
         // return {
         //    isRoundTrip: isRT,
         //    isEveryInputsEnabled: isEveryInputsEnabled,
         //    isSecondFlightEnabled: isSecondFlightEnabled,
         //    result: isRT && !isEveryInputsEnabled && isSecondFlightEnabled ? this.flights[0].departureDate : "",
         // };

         return isRT && !isEveryInputsEnabled && isSecondFlightEnabled ? this.flights[0].departureDate : false;
      },
      AllowSearch() {
         const someShowInputsEnabled = this.flights.some((item) => {
            return item.showInputs;
         });

         const isFormsValid = this.flights.every((item) => {
            return item.departureDate != null;
         });

         return someShowInputsEnabled && isFormsValid;
      },
      ReissueSelectionController() {
         return this.requestModel;
      },
   },
   methods: {
      handlerFMT() {
         this.$i18n.t(`common_adult`);
         return this.$i18n.t(`ancillariesServicesModal_infoTxtPlsSelectFlights`).replace("{ICON}", '<i class="toggle-icon"></i>');
      },
      handlerLegRequestModel(model) {
         //update flight arr
         const modelIndex = model.flightIndex - 1;
         this.flights[modelIndex].departureDate = model.departureDate;

         //set new request model
         this.$set(this.requestModel.legs, model.flightIndex, {
            index: model.flightIndex,
            fromDestination: model.fromDestination,
            toDestination: model.toDestination,
            departureDate: model.departureDate,
         });

         this.handlerRT_Controller(modelIndex);
      },
      handlerRT_Controller(trigger) {
         const isRTEnabled = this.requestModel.flightIds.length == 2;

         if (isRTEnabled) {
            const flight_A = this.requestModel.legs[1].departureDate;
            const flight_B = this.requestModel.legs[2].departureDate;

            if (flight_A > flight_B && trigger == 0) {
               const addDay = moment(flight_A, "YYYY-MM-DD").add(2, "days").format("YYYY-MM-DD");
               this.flights[1].departureDate = null;
               this.requestModel.legs[2].departureDate = addDay;
            } else if (flight_B < flight_A && trigger == 1) {
               const subtractDay = moment(flight_B, "YYYY-MM-DD").subtract(2, "days").format("YYYY-MM-DD");
               this.flights[0].departureDate = null;
               this.requestModel.legs[1].departureDate = subtractDay;
            }
         }
      },
      handlerUnputController(identifier, status) {
         this.uiHelper = false;

         switch (this.availableType) {
            case 0:
               // console.log("case 0");
               break;
            case 2:
               this.$nextTick(() => {
                  this.flights.forEach((flight) => {
                     flight.showInputs = false;
                  });
                  this.flights[identifier - 1].showInputs = status;
               });
               break;
            case 3:
               this.$nextTick(() => {
                  this.flights.forEach((flight) => {
                     flight.showInputs = status;
                  });
               });
               break;
         }
         // {
         // UNKNOWN = 0,
         // NOT_AVAILABLE = 1,
         // ONLY_OW = 2,
         // ONLY_RT = 3,
         // ONLY_ML = 4,
         // BOTH_RT_OW = 5,
         // BOTH_ML_OW = 6
         // }
      },
      handlerFlightIdsGenerator() {
         this.requestModel.flightIds = [];
         this.flights.forEach((flight) => {
            if (flight.showInputs) {
               this.requestModel.flightIds.push(flight.flightId);
            } else {
               this.$delete(this.requestModel.legs, flight.flightIndex);
            }
         });
      },
   },

   watch: {
      flights: {
         handler: function () {
            this.handlerFlightIdsGenerator();
         },
         deep: true,
      },
      AllowSearch: {
         handler: function (status) {
            this.handlerFlightIdsGenerator();
            this.$emit("allow-search", status);
         },
         immediate: true,
      },
   },
};
</script>

<style lang="scss" scoped>
.reissue-selection-container {
   min-height: 400px;
   .flight-selection-wrapper {
      background-color: hsl(220, 37%, 96%);
      border-radius: 0.5rem;
      margin-bottom: 1rem;

      @media (max-width: 576px) {
         display: flex;
         flex-direction: column;
      }
      // border: 1px solid #f2f2f2;

      .flight-selection-informations-wrapper {
         display: flex;
         justify-content: space-between;
         align-items: center;
         width: 100%;
         padding: 1.25rem 1rem;

         .flight-selection-information-section-wrapper {
            //     border: 1px solid #ccc;
            display: flex;
            // justify-content: space-between;
            align-items: center;
            width: 100%;
            font-size: 18px;
            font-weight: 500;
            color: #363940;

            .airline-logo,
            .flight-index,
            .flight-route-wrapper {
               margin-right: 2.25rem;
            }

            .flight-index {
               border-right: 1px solid #ccc;
               padding: 0.5rem;
               padding-right: 2rem;
            }

            .flight-route-wrapper {
               display: flex;
               align-items: center;
               margin-right: 2.5rem;

               span {
                  display: flex;
                  margin: 0 0.5rem;
                  min-width: 40px;
                  &.flight-icon {
                     display: flex;
                     $iconSize: 24px !important;
                     width: $iconSize;
                     height: $iconSize;
                     min-width: $iconSize;
                     background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNS42NzciIGhlaWdodD0iMjUuNjc3IiB2aWV3Qm94PSIwIDAgMjUuNjc3IDI1LjY3NyI+CiAgPHBhdGggaWQ9ImZsaWdodCIgZD0iTTE1LjksMTUuODYsMTQuMzY4LDcuNDA2YTEuMzIsMS4zMiwwLDAsMSwuMzI2LTEuMTIyYzEuMDU1LTEuMTYyLDEuOTkxLTIuMjc1LDMuMDU0LTMuNDE3QTEuODc0LDEuODc0LDAsMCwwLDE3LjYxMS42MTFhMS44OCwxLjg4LDAsMCwwLTIuNDIyLS4zYy0xLjE2MSwxLjA4LTIuMjkyLDIuMDI5LTMuNDc0LDMuMWExLjMyMywxLjMyMywwLDAsMS0xLC4zNDNsLTguMzI2LTEuNEwuMjksMy44ODMsOC4wNDcsNy40MzksNC44NDEsMTEuMDg2LDEuNDMsMTAuOTUzLDAsMTIuMzgzbDQuMywxLjU3MSwxLjU3MSw0LjMsMS40My0xLjQzTDcsMTMuMjQ0LDEwLjYwNSwxMGwzLjc2NCw3Ljk2NVoiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDEyLjkwNiAwKSByb3RhdGUoNDUpIiBmaWxsPSIjMzYzNjM2Ii8+Cjwvc3ZnPgo=");
                     background-size: cover;
                     margin: 0 0.75rem;
                  }
               }
            }
            .departure-datetime-wrapper,
            .arrival-datetime-wrapper {
               display: flex;
               flex-direction: column;
               line-height: 1.25rem;
               position: relative;
               top: -7px;

               .datetime-label {
                  font-size: 0.7rem;
                  font-weight: 400;
                  opacity: 0.75;
               }
               .datetime-info {
                  font-size: 16px;
                  // font-family: "Roboto Mono";
               }
            }

            .departure-datetime-wrapper {
               margin-right: 2rem;
            }
         }
      }
      .flight-selection-inputs-wrapper {
         display: flex;
         justify-content: space-between;
         border-top: 2px dashed #dedfe1;
         padding: 1rem;
         @media (max-width: 576px) {
            flex-direction: column;
         }
      }
   }
   .user-directives-wrapper {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      p {
         margin-top: 1rem;
         color: #363636;
         display: flex;
         align-items: center;
         font-size: 0.9rem;

         i.toggle-icon {
            display: block;
            background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyOCIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDI4IDE2Ij4KICA8ZyBpZD0iR3JvdXBfMTE4NjgiIGRhdGEtbmFtZT0iR3JvdXAgMTE4NjgiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0xMDY0IC00OTIpIj4KICAgIDxwYXRoIGlkPSJSZWN0YW5nbGVfNTA5NCIgZGF0YS1uYW1lPSJSZWN0YW5nbGUgNTA5NCIgZD0iTTgsMUE3LDcsMCwwLDAsOCwxNUgyMEE3LDcsMCwwLDAsMjAsMUg4TTgsMEgyMGE4LDgsMCwwLDEsMCwxNkg4QTgsOCwwLDAsMSw4LDBaIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgxMDY0IDQ5MikiIGZpbGw9IiMzNjM2MzYiLz4KICAgIDxjaXJjbGUgaWQ9IkVsbGlwc2VfMTMxNyIgZGF0YS1uYW1lPSJFbGxpcHNlIDEzMTciIGN4PSI2IiBjeT0iNiIgcj0iNiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMTA2NyA0OTQpIiBmaWxsPSIjMzYzNjM2Ii8+CiAgPC9nPgo8L3N2Zz4K");
            width: 28px;
            height: 16px;
            margin: 0 0.5rem;
         }
      }
   }
}
</style>
<style lang="scss">
i.toggle-icon {
   display: block;
   background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyOCIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDI4IDE2Ij4KICA8ZyBpZD0iR3JvdXBfMTE4NjgiIGRhdGEtbmFtZT0iR3JvdXAgMTE4NjgiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0xMDY0IC00OTIpIj4KICAgIDxwYXRoIGlkPSJSZWN0YW5nbGVfNTA5NCIgZGF0YS1uYW1lPSJSZWN0YW5nbGUgNTA5NCIgZD0iTTgsMUE3LDcsMCwwLDAsOCwxNUgyMEE3LDcsMCwwLDAsMjAsMUg4TTgsMEgyMGE4LDgsMCwwLDEsMCwxNkg4QTgsOCwwLDAsMSw4LDBaIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgxMDY0IDQ5MikiIGZpbGw9IiMzNjM2MzYiLz4KICAgIDxjaXJjbGUgaWQ9IkVsbGlwc2VfMTMxNyIgZGF0YS1uYW1lPSJFbGxpcHNlIDEzMTciIGN4PSI2IiBjeT0iNiIgcj0iNiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMTA2NyA0OTQpIiBmaWxsPSIjMzYzNjM2Ii8+CiAgPC9nPgo8L3N2Zz4K");
   width: 28px;
   height: 16px;
   margin: 0 0.5rem;
}
.flight-selection-toggler-wrapper {
   .switch input[type="checkbox"]:checked + .check,
   .switch input[type="checkbox"]:active + .check,
   .switch input[type="checkbox"] + .check,
   .switch input[type="checkbox"]:focus + .check {
      transform-origin: top;
      transform: scale(1.25);
   }
   .switch input[type="checkbox"]:checked + .check {
      background: #0acc8b !important;
   }

   .switch input[type="checkbox"]:active + .check,
   .switch input[type="checkbox"]:focus + .check {
      box-shadow: none !important;
   }
}
</style>
