<template>
   <div id="mobile-flight-basket" v-if="basketResponse">
      <div id="mobile-flight-basket-container">
         <section class="mf-basket-header">
            <h1 @click="handlerCloseBasket">
               {{ $t("basketComponentFlight_basket") }}

               <template v-if="offersCount == 1">
                  <template v-for="(offer, offerIndex) in basket.offers">
                     <div class="single-display-text" :key="offerIndex">
                        <span v-if="offer.tripType == 1">{{ $t("basketComponentFlight_single") }}</span>
                        <span v-else>{{ $t("basketComponentFlight_package") }}</span>
                     </div>
                  </template>
               </template>
            </h1>
            <div class="basket-type-switch" v-if="offersCount == 2" @click="handlerSwitchBasketType">
               <span :class="step == 0 ? 'active-switch' : ''">{{ $t("basketComponentFlight_single") }} </span>
               <span :class="step == 1 ? 'active-switch' : ''">{{ $t("basketComponentFlight_package") }} </span>
            </div>
         </section>

         <div class="mobile-flight-basket-content-container">
            <template v-for="(offer, offerKey, offerIndex) in basketResponse.offers">
               <div class="mfb-ticket" :key="offerIndex" v-if="offerIndex == step">
                  <div class="mobile-flight-basket-offer-legs" v-for="(leg, legIndex) in offer.legs" :key="legIndex">
                     <div class="mf-leg-informations-header">
                        <span class="txt-black txt-large"> {{ leg.route }}</span>
                        <span class="txt-mono"> {{ leg.date | fullDateFormat }}</span>
                     </div>

                     <div class="mobile-flight-basket-offer-segments" v-for="(segment, segmentIndex) in leg.segments" :key="segmentIndex">
                        <div class="mf-segment-column">
                           <img
                              :src="`${$constants.cdn}/airline_34x34/${segment.operatingAirlineCode}.svg`"
                              @error="$event.target.src = `${$constants.cdn}/airline_34x34/00.svg`"
                              class="airline-logo" />
                        </div>
                        <div class="mf-segment-column d-flex flex-row">
                           <div class="mf-segment-column-infographic d-flex flex-row justify-content-between w-100">
                              <div class="d-flex flex-column">
                                 <span class="txt-black"> {{ segment.originAirportCode }}</span>
                                 <span class="txt-mono">{{ segment.departureDateTime | FormatterTime }}</span>
                              </div>
                              <div class="d-flex flex-column">
                                 <i class="gts-icon icon-flight-icon icon-sm"></i>
                              </div>
                              <div class="d-flex flex-column">
                                 <span class="txt-black"> {{ segment.destinationAirportCode }}</span>
                                 <span class="txt-mono"> {{ segment.arrivalDateTime | FormatterTime }}</span>
                              </div>
                           </div>
                        </div>
                        <div class="mf-segment-column">
                           <span class="w-100 d-block txt-right txt-small txt-mono"> {{ segment.flightNumber }}</span>
                           <span class="w-100 d-block txt-right txt-small txt-mono"> {{ segment.baggageInfo }} - {{ segment.flightClass }}</span>
                        </div>

                        <!-- {{ leg.price }} -->
                     </div>

                     <div class="mf-leg-informations-footer">
                        <span class="txt-large txt-black">{{ leg.price | price(leg.currency) }}</span>
                     </div>
                  </div>

                  <div class="basket-price-container">
                     <div class="basket-price-row">
                        <span>{{ $t("common_lblGrandTotal") }} </span>
                        <span class="txt-black txt-xlarge grand-total">{{ offer.price | price(offer.currency) }}</span>
                     </div>
                  </div>

                  <div class="mobile-flight-basket-action d-flex flex-row justify-content-end mt-4">
                     <UIButton className="default_outline" :text="$t('basketComponentFlight_closeBasket')" @click.native="handlerCloseBasket" class="mr-2" />
                     <template v-if="basketResponse.checkoutEnabled">
                        <UIButton className="default" :text="$t('basketComponentFlight_goToCheckout')" @click.native="handlerCheckout(offer.searchUuid)" />
                     </template>
                  </div>
               </div>
            </template>
         </div>
      </div>
   </div>
</template>

<script>
import UIButton from "@/components/unitary/Button.vue";
export default {
   name: "MobileFlightBasket",
   components: {
      UIButton,
   },
   props: {
      basket: Object,
   },

   data() {
      return {
         basketResponse: null,
         allowCheckoutButton: false,
         offersCount: null,
         step: 0,
      };
   },
   created() {},

   methods: {
      handlerCloseBasket() {
         this.$parent.basket.status = false;
      },
      handlerShowBasket() {
         this.$parent.basket.status = true;
      },
      handlerCheckout(searchuuid) {
         window.open(`/flight/checkout/${searchuuid}`);
      },
      handlerSwitchBasketType() {
         const self = this;

         if (self.step == 0) {
            self.step = 1;
         } else {
            self.step = 0;
         }
      },
   },
   watch: {
      basket: {
         handler: function (basket) {
            this.basketResponse = null;
            if (basket) {
               this.basketResponse = basket;
               this.offersCount = Object.keys(basket?.offers).length;
               if (this.offersCount == 2) {
                  this.step = 1;
               } else {
                  this.step = 0;
               }
            }
         },

         deep: true,
      },
      "$store.state.flight.basket": {
         handler: function (basket) {
            const basketCount = Object.keys(basket).length;
            this.$parent.basket.count = basketCount;
         },
         deep: true,
      },
   },
};
</script>

<style lang="scss" scoped>
$border: 1px solid #ccc;
$sectionGap: 1rem 0;
#mobile-flight-basket {
   position: fixed;
   overflow-y: auto;
   top: 0;
   bottom: 0;
   left: 0;
   right: 0;
   background: #fff;
   box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
   padding: 1rem 1rem;
   z-index: 100;

   @media (min-width: 768px) {
      width: 60vw;
      background: #fff;
      box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
      top: 0;
      bottom: 0;
      right: 0;
      left: auto;
      border: 1px solid #ccc;
      padding: 2rem 1.5rem;
   }

   #mobile-flight-basket-container {
      .mf-basket-header {
         display: flex;
         align-items: center;
         justify-content: space-between;
         margin-bottom: 1rem;

         h1 {
            font-size: var(--xxlarge);
            font-weight: 500;
            display: flex;

            .single-display-text {
               padding-left: 0.5rem;
               color: var(--accent-primary);
            }
         }

         .basket-type-switch {
            padding: 0.25rem;
            border-radius: 12rem;
            display: inline-flex;
            margin-left: 0.25rem;
            border: 1px solid #ccc;
            cursor: pointer;
            span {
               color: var(--primary) !important;
               font-size: var(--large);
               font-weight: 600;
               padding: 0.15rem 0.5rem;
               border-radius: 12rem;
               display: flex;
               align-items: center;

               &.active-switch {
                  background-color: var(--primary);
                  color: #fff !important;
               }
            }
         }
      }

      .mobile-flight-basket-content-container {
         .mobile-flight-basket-offer-legs {
            background: #f2f2f2;
            // border: 1px solid #ccc;
            border-radius: 0.5rem;
            margin: 1rem 0;
            padding: 1rem;
            &:first-child {
               margin-top: 0 !important;
            }
            h2 {
               font-size: var(--xlarge);
               font-weight: 600;
            }
            .mf-leg-informations-header {
               display: flex;
               align-items: center;
               justify-content: space-between;
               margin-bottom: 0.25rem;
               span {
                  margin-right: 0.5rem;
               }
            }
            .mf-leg-informations-footer {
               display: flex;
               justify-content: flex-end;
               margin-top: 0.5rem;

               span {
               }
            }
            .mobile-flight-basket-offer-segments {
               display: flex;
               // border: 1px solid #ccc;
               padding: 0.5rem 0;
               display: grid;
               grid-template-columns: 1fr 4fr 2fr;
               grid-column-gap: 0.5rem;
               border-bottom: 1px dashed #bcbcbc;

               &:last-child {
                  border-bottom: none !important;
               }

               .mf-segment-column {
                  border-right: 1px dashed #bcbcbc;
                  padding: 0 0.5rem;

                  .mf-segment-column-infographic {
                     width: 100%;
                     margin-right: 0.5rem;
                  }

                  &:first-child {
                     padding-left: 0 !important;
                  }

                  &:last-child {
                     border-right: none !important;
                  }

                  .airline-logo {
                     width: 38px;
                  }
               }
            }
         }
         .basket-price-container {
            padding-right: 1rem;
            .basket-price-row {
               display: flex;
               justify-content: flex-end;
               align-items: center;

               span {
                  margin-left: 0.5rem;

                  &.grand-total {
                     color: var(--primary);
                  }
               }
            }
         }
      }
   }
}
</style>

<style lang="scss">
.mobile-flight-basket-content-container {
   nav.tabs {
      display: none;
   }
}
</style>
