// import store from "@/store/index.js";
import ghostLanguage from "@/fmt/languages/ghostLanguage.js";
import airlineList from "@/helper/generated-files/airlineList";

let getGlobalFmtList = {
   tr: ghostLanguage.tr,
   en: ghostLanguage.en,
   de: ghostLanguage.de,
};

const airlinesFmt = () => {
   const airlines = airlineList.airlines;
   airlines.forEach((airline) => {
      getGlobalFmtList.tr[`airlineEnum_${airline.iataCode}`] = airline.nameTr;
      getGlobalFmtList.en[`airlineEnum_${airline.iataCode}`] = airline.nameEn;
      getGlobalFmtList.de[`airlineEnum_${airline.iataCode}`] = airline.nameDe;
   });
};
airlinesFmt();
export { getGlobalFmtList };
