<template>
   <div id="loading-page-container">
      <img src="@/assets/images/globeLightMode.gif" alt="" />
   </div>
</template>

<script>
export default {
   name: "Loading",
};
</script>

<style lang="scss" scoped>
#loading-page-container {
   background-color: #fff;
   margin: 0;
   position: fixed;
   inset: 0 0 0 0;
   img {
      width: 160px;
      height: 160px;
      position: absolute;
      inset: 0 0 0 0;
      margin: auto;
   }
}
</style>
