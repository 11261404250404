<template>
    <div id="style-guide-container">
        <section class="component-container">
            <div class="component-container-row">
                <div class="component-container-column">
                    <UIInput v-model="uiForms.input.value" placeholder="Type here..." allow-clear label="Label" />
                    <CodeViewer :data="uiForms.input"> </CodeViewer>
                </div>
                <div class="component-container-column">
                    <UISelectBox v-model="uiForms.select.value" :options="uiForms.select.options" label="Gender" allow-search light-theme />
                    <CodeViewer :data="uiForms.select"> </CodeViewer>
                </div>
            </div>
            <div class="component-container-row">
                <div class="component-container-column">
                    <UIDatePicker2
                        v-model="uiForms.datepicker.value"
                        :min="uiForms.datepicker.minDate"
                        :max="uiForms.datepicker.maxDate"
                        allow-clear
                        placeholder="Birthdate"
                        custom-header
                    />
                    <CodeViewer :data="uiForms.datepicker"> </CodeViewer>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import UIInput from "@/components/unitary/form2/UIInput.vue";
import UIDatePicker2 from "@/components/unitary/form2/UIDatePicker2.vue";

import UISelectBox from "@/components/unitary/form2/UISelectBox.vue";
import CodeViewer from "@/dev/guideComponents/CodeViewer.vue";
export default {
    name: "StyleGuide",
    components: {
        UIInput,
        UISelectBox,
        UIDatePicker2,
        CodeViewer,
    },
    data() {
        return {
            uiForms: {
                select: {
                    value: 1,
                    options: [
                        { value: 1, name: "Male" },
                        { value: 2, name: "Female" },
                    ],
                    html: ` <UISelectBox v-model="uiForms.select.value" :options="uiForms.select.options" label="Gender" allow-search/>`,
                    description: [
                        {
                            name: "label",
                            type: "String",
                            val: "",
                            default: "",
                        },
                        {
                            name: "options",
                            type: "Array",
                            val: "",
                            default: "[]",
                        },
                        {
                            name: "allow-search",
                            type: "Boolean",
                            val: "",
                            default: "false",
                        },

                        {
                            name: "light-theme",
                            type: "Boolean",
                            val: "",
                            default: "false",
                        },
                    ],
                    import: {
                        name: "UISelectBox",
                        from: "@/components/unitary/form2/UISelectBox.vue",
                    },
                },
                input: {
                    value: "Tyler, the creator",
                    html: ` <UIInput v-model="uiForms.input.value" placeholder="Type here..." allow-clear label="Label" />`,
                    description: [
                        {
                            name: "placeholder",
                            type: "String",
                            val: "",
                            default: "",
                        },
                        {
                            name: "label",
                            type: "String",
                            val: "",
                            default: "",
                        },
                        {
                            name: "allow-clear",
                            type: "Boolean",
                            val: "",
                            default: "false",
                        },
                        {
                            name: "lowercase",
                            type: "Boolean",
                            val: "",
                            default: "false",
                        },
                        {
                            name: "disabled",
                            type: "Boolean",
                            val: "",
                            default: "false",
                        },
                    ],
                    import: {
                        name: "UIInput",
                        from: "@/components/unitary/form2/UIInput.vue",
                    },
                },
                datepicker: {
                    value: "2022-11-16",
                    minDate: "2022-11-15",
                    maxDate: "2023-01-23",

                    html: `       <UIDatePicker2 v-model="uiForms.datepicker.value" allow-clear placeholder="Birthdate" />`,
                    description: [
                        {
                            name: "placeholder",
                            type: "String",
                            val: "",
                            default: "",
                        },
                        {
                            name: "label",
                            type: "String",
                            val: "",
                            default: "",
                        },
                        {
                            name: "allow-clear",
                            type: "Boolean",
                            val: "",
                            default: "false",
                        },
                        {
                            name: "min",
                            type: "String",
                            val: "",
                            default: "false",
                        },
                        {
                            name: "min",
                            type: "String",
                            val: "",
                            default: "false",
                        },
                        {
                            name: "custom-header",
                            type: "Boolean",
                            val: "",
                            default: "false",
                        },
                    ],
                    import: {
                        name: "UIDatePicker2",
                        from: "@/components/unitary/form2/UIDatePicker2.vue",
                    },
                },
            },
        };
    },
};
</script>

<style lang="scss" scoped>
#style-guide-container {
    background: #eee !important;
    margin: auto;
    width: 100%;
    padding-top: 1rem;
    padding: 1rem;
    display: flex;
    justify-content: center;

    section.component-container {
        width: 80%;
        display: flex;
        flex-direction: column;

        .component-container-row {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-column-gap: 5rem;
            margin-bottom: 1rem;
            .component-container-column {
                padding: 2rem;
                border-radius: 0.5rem;
                background-color: #fff;
            }
        }
    }
}
</style>
