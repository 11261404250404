import moment from "moment";

class TokenService {
    setJwtInfo(jwt, jwtDuration, jwtRefreshToken) {
        localStorage.setItem('jwt', jwt);
        localStorage.setItem('jwtRefreshToken', jwtRefreshToken);

        const expiryDate = moment().add(jwtDuration, "minute").format("YYYY-MM-DDTHH:mm:ss.SSSSZ");
        
        localStorage.setItem('jwtDurationExpiry', expiryDate);
    }

    delJwtInfo() {
        localStorage.removeItem('jwt');
        localStorage.removeItem('jwtRefreshToken');
        localStorage.removeItem('jwtDurationExpiry');
    }

    isJwtExpired() {
        if (this.isJwtDeleted())
            return true;

        const tokenExpiryDate = localStorage.getItem('jwtDurationExpiry');
        const tokenExpiryDateMoment = moment(tokenExpiryDate).format("YYYY-MM-DDTHH:mm:ss.SSSSZ");
        const now = moment().format("YYYY-MM-DDTHH:mm:ss.SSSSZ");

        const isExp = moment(now).diff(moment(tokenExpiryDateMoment)) >= 0;

        return isExp;
    }

    isJwtDeleted() {
        const jwt = localStorage.getItem('jwt');
        const tokenExpiryDate = localStorage.getItem('jwtDurationExpiry');
        const jwtRefreshToken = localStorage.getItem('jwtRefreshToken');        

        return !jwt || !tokenExpiryDate || !jwtRefreshToken;
    }

    getJwtToken() {
        return localStorage.getItem('jwt');
    }
    
    getRefreshToken() {
        return localStorage.getItem('jwtRefreshToken');
    }

    getLang() {
        return localStorage.getItem('lang');
    }

    // updateLocaleAccessToken(token) {
    //     let user = JSON.parse(localStorage.getItem('user'));
    //     user.accessToken = token;
    //     localStorage.setItem('user', JSON.stringify(user));
    // }

    // removeUser() {
    //     localStorage.removeItem('user')
    // }
}

export default new TokenService();