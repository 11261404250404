<template>
   <div class="digit-input-wrapper">
      <div class="digit-input-inner">
         <input
            type="text"
            id="input_totpCode"
            v-model="digitCode"
            :maxlength="digitMaxLength"
            autocomplete="off"
            ref="hiddenDigitTextInput"
            v-digit />
         <ul class="digit-input-list">
            <li
               v-for="index in digitMaxLength"
               :key="index"
               @click="triggerDigitInput"
               class="digit-input-list-item"
               ref="digitInput"
               :class="isError ? 'is-error' : ''"></li>
         </ul>
      </div>
   </div>
</template>

<script>
export default {
   name: "DigitInput",
   props: {
      digitLength: { String },
      isError: Boolean,
      clearAllDigits: { type: Boolean, default: false },
   },
   data() {
      return {
         digitMaxLength: 6,
         digitCode: "",
         digitCodeArray: [],
      };
   },
   methods: {
      triggerDigitInput() {
         const digitInputElements = this.$refs.digitInput;
         const hiddenDigitTextInput = this.$refs.hiddenDigitTextInput;

         hiddenDigitTextInput.focus();
         if (!digitInputElements[0].classList.contains("is-focused")) {
            digitInputElements[0].classList.add("is-focused", "marker");
         }
      },
   },
   directives: {
      digit: {
         bind: function (el, binding, vnode) {
            el.triggerInputEvent = function (e) {
               const digitInputElements = vnode.context.$refs.digitInput;
               vnode.context.digitCodeArray = [...e.target.value];
               vnode.context.digitCode = e.target.value;

               if (vnode.context.digitCodeArray.length == 0) {
                  digitInputElements.forEach((digitInputElement) => {
                     digitInputElement.classList.add("is-error");
                  });
               }

               if (e.inputType == "deleteContentBackward") {
                  digitInputElements[vnode.context.digitCodeArray.length].classList.remove("is-focused");
                  digitInputElements[vnode.context.digitCodeArray.length].classList.add("marker");
                  digitInputElements[vnode.context.digitCodeArray.length].innerHTML = "";
                  if (vnode.context.digitCodeArray.length < vnode.context.digitMaxLength) {
                     digitInputElements[vnode.context.digitCodeArray.length + 1]?.classList.remove("marker");
                  }
               } else {
                  vnode.context.digitCodeArray.map((digitCodeItem, i) => {
                     digitInputElements[i].innerHTML = digitCodeItem;
                     digitInputElements[i].classList.remove("marker");
                     digitInputElements[i].classList.add("is-focused");

                     if (vnode.context.digitCodeArray.length != vnode.context.digitMaxLength) {
                        digitInputElements[vnode.context.digitCodeArray.length].classList.add("marker");
                     }

                     if (digitInputElements[i].classList.contains("is-error")) {
                        digitInputElements[i].classList.remove("is-error");
                     }
                  });
               }
               vnode.context.$parent.digitLength = vnode.context.digitCodeArray.length;
            };

            el.addEventListener("input", el.triggerInputEvent);
         },
      },
   },
   watch: {
      digitCode: {
         handler: function (newDigitCode) {
            this.$emit("update-digit-code", newDigitCode);
         },
         deep: true,
      },

      digitLength: {
         handler: function (newDigitLength) {
            const self = this;

            if (newDigitLength) {
               self.digitMaxLength = Number(newDigitLength);
            } else {
               self.digitMaxLength = 6;
            }
         },
         deep: true,
      },

      clearAllDigits: {
         handler: function () {
            const self = this;

            if (self.clearAllDigits) {
               for (var i = 0; i < self.digitMaxLength; i++) {
                  document.getElementsByClassName("digit-input-list-item")[i].classList.remove("is-focused");
                  document.getElementsByClassName("digit-input-list-item")[i].classList.add("is-error");
                  document.getElementsByClassName("digit-input-list-item")[i].innerHTML = "";
               }
               this.digitCode = "";
               this.digitCodeArray = [];

               self.$parent.clearAllDigits = false;
            }
         },
         deep: true,
      },
   },
};
</script>

<style lang="scss" scoped>
.digit-input-wrapper {
   .digit-input-inner {
      position: relative;

      #input_totpCode {
         height: 0;
         opacity: 0;
         position: absolute;
      }
      .digit-input-list {
         display: flex;
         align-items: center;
         padding: 0;
         margin: 0;
         .digit-input-list-item {
            position: relative;
            display: inline-block;
            border-radius: 8px;
            margin: 0 0.3rem;
            height: 50px;
            width: 40px;
            background-color: #fff;
            border: 1px solid #ccc;
            font-size: 20px;
            line-height: 45px;
            text-align: center;
            cursor: text;

            &.is-error {
               position: relative;
               border-color: #fb9595;
               background-color: #ffe1e1d9;
            }
            &.is-focused {
               position: relative;
               border-color: aqua;
            }

            &.marker {
               @keyframes digitInputFocused {
                  0% {
                     opacity: 0;
                  }
                  50% {
                     opacity: 1;
                  }
                  90% {
                     opacity: 1;
                  }
               }

               &::before {
                  content: "|";
                  position: absolute;
                  left: 0.5rem;
                  font-size: 1.3rem;
                  animation-name: digitInputFocused;
                  animation-duration: 0.8s;
                  animation-iteration-count: infinite;
                  color: #000;
               }
            }
         }
      }
   }
}
</style>
