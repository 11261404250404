import { logger } from "./logger.js";

class ErrorManager {
   handleVueError(err, vm, info) {
      console.log(vm.$vnode.tag, "component-tag");
      logger.error(err.name, err.message, err.stack, info, vm.$vnode.tag);

      return false;
   }

   handleVueWarn(msg, vm, trace) {
      logger.warn(msg, trace);

      return false;
   }

   handleWindowError(message, source, lineno, colno, error) {
      logger.error(message, source, lineno, colno, error);

      return false;
   }

   handleWindowUnhandledRejection(event) {
      //handle error here
      //event.promise contains the promise object
      //event.reason contains the reason for the rejection
      logger.error(event.reason.message, event.reason.stack);

      return false;
   }

   handleErrorStatus(errorStatus) {
      if (
         (!errorStatus.type && errorStatus.title && errorStatus.subTitle) ||
         (errorStatus.type && errorStatus.type == "warning" && errorStatus.subTitle != "Axios handle exception")
      )
         logger.warn(errorStatus);

      return false;
   }
}

const errorManager = new ErrorManager();

export { errorManager };
