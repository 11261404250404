<template>
   <div id="price-informations-wrapper" class="gts-box with-border">
      <span class="section-title">{{ $t("flightPriceInformationsComponent_priceInformations") }}</span>

      <div class="price-informations-table">
         <!-- Row Header -->
         <section class="gts-row gts-row-header d-none d-lg-grid">
            <span>{{ $t("common_passenger") }}</span>
            <span>{{ $t("common_price") }}</span>
            <span>{{ $t("common_pritax") }}</span>
            <span>{{ $t("common_serviceFee") }}</span>
            <span>{{ $t("common_agencyCommission") }}</span>
            <span>{{ $t("common_grandTotal") }}</span>
         </section>

         <!-- Row Loop -->
         <section
            class="gts-row gts-row-body masked-background masked-borderless d-none d-lg-grid"
            v-for="(pax, key, index) in paxes"
            :key="index + key">
            <div class="passenger-cell-container">
               <span class="passenger-cell masked masked-shorten">{{ pax.count }} {{ $t(`common_${key.toLowerCase()}`) }} </span>
            </div>
            <div class="passenger-cell-container">
               <span class="price-cell masked masked-shorten">{{ pax.amount.fare | price(pax.amount.currency) }}</span>
            </div>
            <div class="passenger-cell-container">
               <span class="pri-tax-cell masked masked-shorten">{{ (pax.amount.taxE + pax.amount.taxI) | price(pax.amount.currency) }}</span>
            </div>
            <div class="passenger-cell-container">
               <span class="service-fee-cell masked masked-shorten">{{
                  (pax.amount.otherMarkups - pax.amount.marksBreakdown.ac - pax.amount.marksBreakdown.ace) | price(pax.amount.currency)
               }}</span>
            </div>
            <div class="passenger-cell-container">
               <span class="agency-com-cell masked masked-shorten">{{ pax.amount.marksBreakdown.ac | price(pax.amount.currency) }}</span>
            </div>
            <div class="passenger-cell-container">
               <span class="grand-total-cell masked masked-shorten">{{
                  (pax.amount.grandTotal - pax.amount.marksBreakdown.ace) | price(pax.amount.currency)
               }}</span>
            </div>
         </section>

         <!-- Mobile Price Information Row Per Pax -->
         <div class="price-informations-mobile d-lg-none" v-for="(pax, key, index) in paxes" :key="index">
            <span class="pax-information-header">{{ pax.count }} {{ $t(`common_${key.toLowerCase()}`) }}</span>
            <ul class="pax-price-informations-row">
               <li>
                  <span>{{ $t("common_price") }}</span> <span>{{ pax.amount.fare | price(pax.amount.currency) }}</span>
               </li>
               <li>
                  <span>{{ $t("common_tax") }}</span> <span> {{ pax.amount.taxE | price(pax.amount.currency) }}</span>
               </li>
               <li>
                  <span>{{ $t("common_pritax") }}</span> <span>{{ pax.amount.taxI | price(pax.amount.currency) }}</span>
               </li>

               <li>
                  <span> {{ $t("common_serviceFee") }}</span>
                  <span> {{ pax.amount.fareMarkups | price(pax.amount.currency) }}</span>
               </li>
               <li>
                  <span>{{ $t("common_agencyCommission") }}</span> <span>{{ pax.amount.otherMarkups | price(pax.amount.currency) }}</span>
               </li>
            </ul>
            <div class="pax-grandtotal">{{ $t("common_grandTotal") }} : {{ pax.amount.grandTotal | price(pax.amount.currency) }}</div>
         </div>

         <!-- Common Price Informations -->
         <PriceWidget :totalPriceInfo="priceInformations" @commission-update="handlerAddCommission" checkoutMode label="flightCheckout" />

         <!--TODO: We need to implement masked and masked related classes to price widget for skeleton loader - 07.04.23 Deniz -->
         <!-- <div v-if="false" id="price-informations-summary-container">
            <div id="price-informations-summary-inner-wrapper">
               <div class="price-informations-summary-inner-row">
                  <span class="pis-label txt-right">{{ $t("common_totalPrice") }}:</span>
                  <span class="pis-value txt-right masked masked-shorten">{{ total.total | price }}</span>
               </div>
               <div class="price-informations-summary-inner-row">
                  <span class="pis-label txt-right">{{ $t("common_agencyExtraCommission") }} :</span>
                  <span class="pis-value txt-right masked masked-shorten">
                     <input
                        type="text"
                        class="agency-comm masked-disabled"
                        :data-max="total.total"
                        v-model="AgencyCommission"
                        v-price
                        ref="agencycommission" />
                     <div class="add-comm-button">
                        <UITooltip
                           class="gts-animation-scale"
                           @click.native="handlerAddCommission(total.grandTotal)"
                           :tooltip-title="$t('AgencyExtraCommission_checkOutInformation')"
                           tooltip-icon="gts-icon icon-add-leg"
                           tooltip-position="bottom"
                           no-padding />
                     </div>
                  </span>
               </div>
               <div class="price-informations-summary-inner-row">
                  <span class="pis-label txt-right">{{ $t("common_generalGrandTotal") }} :</span>
                  <span class="pis-value pis-grand-total txt-right masked masked-shorten">{{ total.grandTotal | price }}</span>
               </div>
            </div>
         </div> -->
      </div>
   </div>
</template>

<script>
import { flightService } from "@/service/index.js";
import PriceWidget from "@/components/unitary/PriceWidget.vue";
export default {
   name: "PriceInformations",
   components: {
      PriceWidget,
   },
   data() {
      return {
         paxes: null,
         total: null,
         maxTotal: 0,
      };
   },
   computed: {
      priceInformations() {
         return {
            issueTotal: this.total.total,
            grandTotal: this.total.grandTotal,
         };
      },
   },
   methods: {
      setAgencyCommission(agencyCommission) {
         this.$store.state.flight.checkout.request.agencyCommissionExtra = agencyCommission;
      },
      handlerAddCommission(commissionObj) {
         // We need to check if commission type is select cuz we dont use comissionSelectBox in flight checkout
         if (commissionObj.type != "button") return;

         let { agencyCommissionExtra } = commissionObj.agencyCommission;
         this.setAgencyCommission(agencyCommissionExtra);
         if (agencyCommissionExtra == "") agencyCommissionExtra = 0;

         if (this.priceInformations.issueTotal > agencyCommissionExtra) {
            //set progress gif
            this.$store.commit("app/setIsProgressGifActive", true);
            this.$store.state.app.progress.message = this.$i18n.t("flightCheckout_agencyExtraCCommission");

            // set new uuid for request
            this.$store.commit("flight/updateAgencyCommission");

            let query = this.$store.state.flight.checkout.request;

            query.agencyCommissionExtra = agencyCommissionExtra;
            flightService.checkout(query).then((response) => {
               this.$store.commit("flight/updateCheckoutResponse", response.checkoutResponse);
               this.$store.commit("flight/updateCheckoutRequest", response.checkoutRequest);
               this.$store.commit("flight/updateCheckoutUuid", response.checkoutUuid);
               this.$store.commit("app/setIsProgressGifActive", false);
            });
         }
      },
   },
   watch: {
      "$store.state.flight.checkout.response.paxes": {
         handler: function () {
            const self = this;
            self.paxes = self.$store.state.flight.checkout.response.paxes;
            self.total = self.$store.state.flight.checkout.response.total;
            self.maxTotal = parseInt(self.$store.state.flight.checkout.response.total.total);
         },
         immediate: true,
      },
   },
};
</script>

<style lang="scss" scoped>
#price-informations-wrapper {
   .price-informations-table {
      display: grid;

      section.gts-row {
         display: grid;
         grid-template-columns: 25% repeat(5, 15%);
         padding: 1.25rem 2rem;
         margin-bottom: 1rem;

         span {
            font-size: var(--large);
            display: flex;
            justify-content: flex-end;

            &:first-child {
               justify-content: flex-start !important;
            }

            &.grand-total-cell {
               font-weight: 900 !important;
            }
         }

         &.gts-row-body {
            border-radius: 24rem;
            &:nth-child(even) {
               background-color: #f5f7fa;
            }
            &:nth-child(odd) {
               border: 1px solid #dee2e6;
            }

            .passenger-cell-container {
               display: flex;
               justify-content: flex-end;
               padding: 0;
               span {
                  font-weight: 500;
                  font-size: var(--large);
                  &:first-child {
                     font-weight: 400;
                     text-transform: capitalize;
                  }
               }

               &:first-child {
                  justify-content: flex-start;
               }
            }
         }

         &.gts-row-header {
            border-bottom: 1px solid #ccc;
            padding: 0 2rem;

            span {
               font-size: var(--small);
               display: flex;
               text-align: right;
               align-items: center;
               justify-content: flex-end;
               font-weight: 600;
               padding: 0.5rem;
            }
         }

         &.gts-row-footer {
            background-color: transparent !important;
            display: grid;
            grid-template-columns: 50% 50%;
            padding: 0 2rem !important;
            margin: 0 !important;
            margin-bottom: 1rem !important;

            @media (min-width: 576px) {
               grid-template-columns: 85% 15%;
            }

            .cell-container {
               display: flex;
               justify-content: flex-end;
               span {
                  padding: 0 !important;
                  &.label-cell {
                     align-items: center;
                     justify-content: flex-end !important;
                     font-size: var(--normal);
                     font-weight: 500;
                     text-align: right;
                  }
                  &.value-cell {
                     font-size: var(--large);
                     font-weight: 900 !important;
                     position: relative;

                     .agency-comm {
                        border: 1px solid #ccc;
                        outline: none;
                        border-radius: 12px;
                        text-align: end;
                        padding: 0.2rem;
                        padding-right: 0.5rem;
                        max-width: 100px;
                        margin-bottom: 0.5rem;
                        margin-right: 0.25rem;
                     }

                     .add-comm-button {
                        cursor: pointer;
                     }
                  }
               }
            }
         }
      }

      .price-informations-mobile {
         display: flex;
         flex-direction: column;
         border: 1px solid #cccccc;
         padding: 1rem;
         border-radius: 16px;
         background-color: #f5f5f5;
         margin-bottom: 1.5rem;

         @media (min-width: 768px) and (max-width: 991.98px) {
            font-size: 18px;
         }
         span.pax-information-header {
            font-size: var(--large);
            font-weight: 500;
         }
         ul.pax-price-informations-row {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            height: auto;
            border-top: 1px solid #ccc;
            border-bottom: 1px solid #ccc;
            padding-bottom: 0.8rem;
            margin: 0.8rem 0;

            @media (min-width: 768px) {
               display: flex;
               flex-wrap: wrap;
            }
            li {
               flex-basis: 20%;
               width: 100%;
               display: flex;
               flex-direction: column;
               text-align: center;
               padding: 0.5rem 0;
               height: auto;
               align-items: center;
               margin: 0;

               @media (min-width: 768px) {
                  width: 20%;
               }

               span {
                  &:first-child {
                     height: auto;
                     display: flex;
                     flex-direction: column;
                     justify-content: center;
                     align-items: center;
                     font-size: var(--large);
                     font-weight: 500;
                     width: 80px;

                     @media (min-width: 768px) {
                        font-size: var(--normal);
                        height: 34px;
                        margin-bottom: 0.5rem;
                     }
                  }
                  &:last-child {
                     font-weight: 800;
                     font-size: var(--large);
                     @media (min-width: 768px) {
                        font-weight: 600;
                        font-size: var(--normal);
                     }
                  }
               }
            }
         }

         .pax-grandtotal {
            width: 100%;
            text-align: right;
            padding: 0 0.75rem;
            font-weight: 600;
         }
      }

      #price-informations-summary-container {
         width: 100%;
         //  background: #eee;
         padding-right: 2rem;
         display: flex;
         justify-content: flex-end;

         #price-informations-summary-inner-wrapper {
            display: flex;
            flex-direction: column;
            min-width: 260px;

            .price-informations-summary-inner-row {
               display: grid;
               width: 100%;
               grid-template-columns: 1fr 1fr;
               border: 1px;
               margin: 0.25rem 0;
               align-items: center;

               .pis-label {
                  font-size: var(--large);
                  font-weight: 500;

                  @media (min-width: 992px) {
                     font-size: var(--normal);
                  }
               }
               .pis-value {
                  font-size: var(--xlarge);
                  font-weight: 600;
                  display: flex;
                  justify-content: flex-end;

                  @media (min-width: 992px) {
                     font-size: var(--large);
                  }

                  &.pis-grand-total {
                     color: var(--accent-primary);
                     font-weight: 800;
                  }
               }

               .agency-comm {
                  border: 1px solid #ccc;
                  outline: none;
                  border-radius: 0.5rem;
                  height: 32px;
                  text-align: end;
                  padding: 0.2rem;
                  padding-right: 0.5rem;
                  max-width: 100px;
                  margin-bottom: 0.5rem;
                  margin-right: 0.55rem;
                  font-size: var(--large);
               }

               .add-comm-button {
                  cursor: pointer;
               }
            }
         }
      }
   }
}
</style>
