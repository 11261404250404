<template>
   <div class="transfer-datepicker-container g-input-container" v-click-outside="handlerClickOutside">
      <label>{{ label }}</label>
      <div class="transfer-datepicker-input-wrapper">
         <!-- From Date -->
         <div class="date-wrapper" @click="handlerShowDatePicker('from')" :class="{ activedate: datepickers.from.status }">
            <span class="day">{{ DisplayFromDate.day }}</span>
            <div class="month-year-wrapper">
               <span class="month">{{ DisplayFromDate.month }}</span>
               <span class="year">{{ DisplayFromDate.year }}</span>
            </div>
            <!-- <div class="to-date-clear-button-wrapper">
               <i class="gts-icon icon-close-rounded icon-xs" @click="datepickers.from.value = today"></i>
            </div> -->
         </div>

         <!-- Return Date -->
         <div class="date-wrapper" @click="handlerShowDatePicker('to')" :class="{ activedate: datepickers.to.status }">
            <template v-if="DisplayToDate.year.length == 4">
               <span class="day">{{ DisplayToDate.day }}</span>
               <div class="month-year-wrapper">
                  <span class="month">{{ DisplayToDate.month }}</span>
                  <span class="year">{{ DisplayToDate.year }}</span>
               </div>
            </template>
            <template v-else>
               <div class="add-return-date">+</div>
            </template>
         </div>
         <div class="to-date-clear-button-wrapper gts-animation me-0 d-xxl-flex" v-if="DisplayToDate.year.length == 4">
            <i class="gts-icon icon-close-rounded icon-xs" @click="handlerClearFromDate"></i>
         </div>
      </div>
      <!-- Datepicker   -->
      <div class="g-datepicker-wrapper" :class="identifier">
         <!-- <b-datepicker inline v-model="datepickers.vModel" @input="handlerDatePickerChange"> </b-datepicker> -->
         <UIDatepicker2 v-model="datepickers.model" inline ref="datePicker" :min="minDate" :max="maxDate" custom-header />
      </div>
   </div>
</template>

<script>
import moment from "moment";
import i18n from "@/fmt/i18n.js";
import UIDatepicker2 from "@/components/unitary/form2/UIDatePicker2.vue";
export default {
   name: "TransferDatePicker",
   props: {
      label: String,
   },
   components: {
      UIDatepicker2,
   },
   data() {
      return {
         identifier: "",
         minDate: moment().add(1, "days").format(this.$constants.dateFormat),
         maxDate: moment().add(1, "years").format(this.$constants.dateFormat),
         datepickers: {
            model: moment().add(1, "days").format(this.$constants.dateFormat),

            from: {
               status: false,
               value: moment().add(1, "days").format(this.$constants.dateFormat),
            },
            to: {
               status: false,
               value: "",
            },
            monthArr: [],
         },
      };
   },
   computed: {
      DisplayFromDate() {
         const value = this.datepickers.from.value;

         return {
            day: moment(value, this.$constants.dateFormat).lang(i18n.locale).format("DD"),
            month: moment(value, this.$constants.dateFormat).lang(i18n.locale).format("MMMM"),
            year: moment(value, this.$constants.dateFormat).lang(i18n.locale).format("YYYY"),
         };
      },
      DisplayToDate() {
         const value = this.datepickers.to.value;

         if (moment(value).isValid()) {
            return {
               day: moment(value, this.$constants.dateFormat).lang(i18n.locale).format("DD"),
               month: moment(value, this.$constants.dateFormat).lang(i18n.locale).format("MMMM"),
               year: moment(value, this.$constants.dateFormat).lang(i18n.locale).format("YYYY"),
            };
         } else {
            return {
               day: "",
               month: "",
               year: "",
            };
         }
      },
   },

   methods: {
      handlerClearFromDate() {
         this.datepickers.to.value = "";
         this.$emit("date-update", {
            from: this.datepickers.from.value,
            to: null,
         });
         this.maxDate = moment().add(1, "years").format(this.$constants.dateFormat);
      },
      handlerClickOutside() {
         this.$refs.datePicker.datepicker.status = false;
         this.datepickers.from.status = false;
         this.datepickers.to.status = false;
      },
      handlerShowDatePicker(identifier) {
         this.$refs.datePicker.datepicker.status = true;
         this.identifier = identifier;
         this.datepickers[identifier].status = true;
         if (identifier == "from" && this.datepickers.to.value.length != 0) {
            this.minDate = moment().add(1, "days").format(this.$constants.dateFormat);
            this.maxDate = this.datepickers.to.value;
            this.datepickers.model = this.datepickers.from.value;
         }
         if (identifier == "to" && this.datepickers.from.value.length != 0) {
            this.minDate = this.datepickers.from.value;
            this.maxDate = moment().add(1, "years").format(this.$constants.dateFormat);
            this.datepickers.to.value.length != 0 ? (this.datepickers.model = this.datepickers.to.value) : null;
         }
         this.datepickers[identifier === "from" ? "to" : "from"].status = false;
      },
      handlerDatePickerChange() {
         if (this.datepickers.from.status) {
            this.datepickers.from.value = this.datepickers.model;
         }
         if (this.datepickers.to.status) {
            this.datepickers.to.value = this.datepickers.model;
         }

         this.$emit("date-update", {
            from: this.datepickers.from.value,
            to: this.datepickers.to.value.length != 0 ? this.datepickers.to.value : null,
         });
      },
   },
   watch: {
      "datepickers.model": {
         handler: function () {
            this.handlerDatePickerChange();
         },
      },
      "datepickers.to.status": {
         handler: function (status) {
            if (status && this.datepickers.to.value.length == 0) {
               this.datepickers.to.value = this.datepickers.model;
            }
            this.$emit("date-update", {
               from: this.datepickers.from.value,
               to: this.datepickers.to.value,
            });
         },
         deep: true,
      },
   },
};
</script>

<style lang="scss" scoped>
.transfer-datepicker-container {
   .transfer-datepicker-input-wrapper {
      width: 100%;
      height: 48px;
      color: var(--txt-black);
      background: #f8f8f8;
      text-transform: capitalize;
      border: 1px solid #ccc;
      border-radius: 0.5rem;
      display: flex;
      justify-content: space-around;
      position: relative;
      overflow: hidden;

      &:after {
         content: "";
         position: absolute;
         // border-right: 1px solid #ccc;
         top: 0.5rem;
         margin: auto;
         display: flex;
         bottom: 0.5rem;
      }

      .date-wrapper {
         cursor: pointer;
         display: flex;
         align-items: center;
         width: 100%;
         justify-content: center;
         margin-top: 0.25rem;
         margin-bottom: 0.25rem;

         &:hover {
            span {
               color: var(--accent-primary);
            }
         }

         span {
            &.day {
               font-size: var(--xxlarge);
               font-weight: 600;
            }
         }

         .month-year-wrapper {
            line-height: 14px;
            display: flex;
            flex-direction: column;
            padding-left: 0.25rem;
            margin-right: 0.75rem;
            span {
               font-size: var(--xsmall);
               font-weight: 500;
            }
         }

         &:first-child {
            margin-left: 0.5rem;
            margin-right: 0.25rem;
            border-right: 1px solid #ccc;
         }

         &:last-child {
            margin-left: 0.15rem;
            margin-right: 0.65rem;
         }

         .add-return-date {
            cursor: pointer;
            padding: 0.125rem 0.75rem;
            background: #efefef;
            border: 1px dashed #999;
            border-radius: 0.5rem;
            color: #999;
            font-size: 2rem;
            font-weight: 300;
            line-height: 28px;
            text-align: center;
         }

         &.activedate {
            span {
               color: var(--accent-primary);
            }
         }
      }
      .to-date-clear-button-wrapper {
         position: absolute;
         right: 3px;
         padding: 0.125rem;
         cursor: pointer;
         top: 12px;
         @media (max-width: 992px) {
            right: 1rem;
         }
      }
   }
   .g-datepicker-wrapper {
      &.to {
         margin-left: 120px;
         @media (max-width: 576px) {
            margin-left: 0;
         }
      }
      &.from {
         margin-left: -187px;

         @media (max-width: 992px) {
            margin-left: 0;
         }
      }
   }
}
</style>
