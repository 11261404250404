<template>
   <div class="add-on-horizontal-view-c">
      <span class="add-on-horizontal-view-title"> {{ $t("hotelCard_lblhasMandatoryAddonTitle") }}</span>
      <div class="add-on-horizontal-view-item-wrapper" v-for="(item, itemIndex) in addOns" :key="itemIndex">
         <div class="add-on-horizontal-view-item-section-left">
            <div class="add-on-horizontal-view-image-gallery">
               <template v-for="(img, imgIndex) in item.images">
                  <div class="add-on-image-gallery-wrapper" :key="imgIndex" v-if="imgIndex == item.activeImg">
                     <img :src="img" />
                     <i class="g-radial-loader"></i>
                  </div>
               </template>

               <template v-if="item.images.length > 1">
                  <button class="btn-prev" @click="handlerImageGallery(-1, item)">
                     <i class="gts-icon icon-arrow icon-xs"></i>
                  </button>
                  <button class="btn-next" @click="handlerImageGallery(1, item)">
                     <i class="gts-icon icon-arrow icon-xs"></i>
                  </button>
               </template>
            </div>
         </div>
         <div class="add-on-horizontal-view-item-section-middle">
            <div class="add-on-horizontal-view-item-informations-wrapper">
               <span class="add-on-horizontal-view-item-date">{{ item.date }}</span>
               <span class="add-on-horizontal-view-item-title">{{ item.title }}</span>
               <div class="add-on-horizontal-view-item-content" v-html="item.content"></div>
            </div>
            <div class="add-on-horizontal-view-item-info-box-outer-wrapper">
               <template v-for="(item, itemIndex) in handlerInformationText(item.disabledChildAges)">
                  <div class="add-on-horizontal-view-item-info-box-wrapper" :key="itemIndex">
                     <i class="info-icon"></i>
                     <span>{{ item.msg }}</span>
                  </div>
               </template>
            </div>
         </div>
         <div class="add-on-horizontal-view-item-section-right"></div>
      </div>
   </div>
</template>

<script>
export default {
   name: "AddonHorizontalView",
   props: {
      addonInformations: Object,
   },
   data() {
      return {
         addOns: [],
      };
   },
   created() {
      function convertNewlinesToListItems(description) {
         // Split the description by newline characters
         const lines = description.split("\n");

         // Wrap each line in <li> tags
         const listItems = lines.map((line) => `<li>${line}</li>`).join("");

         // Wrap the list items in a <ul> tag
         return `<ul>${listItems}</ul>`;
      }
      this.addOns = this.addonInformations.addOns.map((addon) => {
         return {
            title: addon.title,
            activeImg: 0,
            images: [addon?.image1Url, addon?.image2Url].filter((url) => url !== null),
            content: convertNewlinesToListItems(addon.description),
            date: this.$options.filters.FormatterDate(addon.effectiveDate),
            price: this.$options.filters.price(addon.price, addon.currency),
            disabledChildAges: addon.disabledChildAges,
         };
      });
   },
   methods: {
      handlerImageGallery(val, img) {
         if (img.images.length === 0) {
            return;
         }
         img.activeImg += val;
         if (img.activeImg >= img.images.length) {
            img.activeImg = 0;
         } else if (img.activeImg < 0) {
            img.activeImg = img.images.length - 1;
         }
      },

      handlerInformationText(obj) {
         return obj.map((item) => {
            const ageRange = `${item.fromAge}-${item.toAge}`;
            let msg = "hotelRoomDetail_lblAddOnInfo";

            if (item.disabled) {
               msg += "Disabled";
            }
            if (item.free) {
               msg += "Free";
            }

            return {
               msg: this.$t(msg).replace("#AGE_RANGE#", ageRange),
            };
         });
      },
   },
};
</script>

<style lang="scss" scoped>
.add-on-horizontal-view-c {
   margin-top: 2rem;
   background-color: #f6f8fb;
   border: 1px solid #e6e6e6;
   border-radius: 0.75rem;
   padding: 0.5rem;

   .add-on-horizontal-view-title {
      font-weight: 600;
      font-size: 1.25rem;
      margin-bottom: 0.5rem;
      display: flex;
   }

   .add-on-horizontal-view-item-wrapper {
      display: flex;
      justify-content: space-between;
      background: #fff;
      border-radius: 0.75rem;
      border: 1px solid #eee;
      margin-bottom: 0.5rem;
      padding: 0.5rem;

      .add-on-horizontal-view-item-section-left {
         flex-shrink: 0;
         position: relative;

         .add-on-horizontal-view-image-gallery {
            height: 100%;
            .add-on-image-gallery-wrapper {
               overflow: hidden;
               position: relative;
               display: flex;
               align-items: center;
               justify-content: center;
               border-radius: 0.5rem;
               background-color: #f2f2f2;
               width: 250px;
               height: 100%;
               border-radius: 0.5rem;

               img {
                  position: absolute;
                  z-index: 1;
               }
               i {
                  position: absolute;
               }
            }
         }

         button {
            position: relative;
            background-color: #fff;
            position: absolute;
            top: 0;
            bottom: 0;
            height: 50px;
            margin: auto 0.5rem;
            border-radius: 0.5rem;
            border: 1px solid #eee;
            z-index: 2;

            &.btn-prev {
               i {
                  transform: rotate(180deg);
               }
            }

            &.btn-next {
               right: 0;
            }

            &:hover {
               transform: scale(1.15);
            }
         }
      }
      .add-on-horizontal-view-item-section-middle {
         width: 100%;
         display: flex;
         flex-direction: column;
         justify-content: space-between;
         margin-left: 1rem;

         .add-on-horizontal-view-item-informations-wrapper {
            display: flex;
            flex-direction: column;
            margin-bottom: 1.5rem;
            padding-top: 0.5rem;
            span {
               width: fit-content;
               display: flex;
               &.add-on-horizontal-view-item-date {
                  background-color: #feddff;
                  border-radius: 0.125rem;
                  font-weight: 600;
                  color: #000;
                  font-size: 1.125rem;
                  padding: 0 0.25rem;
                  margin-bottom: 0.125rem;
               }
               &.add-on-horizontal-view-item-title {
                  font-weight: 600;
                  font-size: 1.25rem;
               }
               &.add-on-horizontal-view-item-content {
               }
            }
         }
      }
      .add-on-horizontal-view-item-section-right {
      }
   }
}
</style>

<style lang="scss">
.add-on-horizontal-view-item-info-box-wrapper {
   // border: 1px solid;
   border-radius: 24rem;
   background-color: #f4f4f4;
   display: flex;
   margin-bottom: 0.25rem;
   padding: 0.35rem;
   border: 1px solid #eee;

   i.info-icon {
      background-size: contain;
      background-image: url("data:image/svg+xml;base64,PHN2ZyBpZD0iaW5mby1jaXJjbGUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjE1Ljc3NyIgaGVpZ2h0PSIxNS43NzciIHZpZXdCb3g9IjAgMCAxNS43NzcgMTUuNzc3Ij4KICA8cGF0aCBpZD0iUGF0aF84MzgwIiBkYXRhLW5hbWU9IlBhdGggODM4MCIgZD0iTTcuODg5LDE0Ljc5MWE2LjksNi45LDAsMSwwLTYuOS02LjlBNi45LDYuOSwwLDAsMCw3Ljg4OSwxNC43OTFabTAsLjk4NkE3Ljg4OSw3Ljg4OSwwLDEsMCwwLDcuODg5LDcuODg5LDcuODg5LDAsMCwwLDcuODg5LDE1Ljc3N1oiIGZpbGw9IiMzNjM2MzYiIGZpbGwtcnVsZT0iZXZlbm9kZCIvPgogIDxwYXRoIGlkPSJQYXRoXzgzODEiIGRhdGEtbmFtZT0iUGF0aCA4MzgxIiBkPSJNMTcuMDIyLDE0LjgyM2wtMi4yNTguMjgzLS4wODEuMzc1LjQ0NC4wODJjLjI5LjA2OS4zNDcuMTc0LjI4NC40NjJsLS43MjcsMy40MmMtLjE5MS44ODQuMSwxLjMuOCwxLjNhMi4wNDIsMi4wNDIsMCwwLDAsMS40NDQtLjU4OWwuMDg3LS40MWExLjA5MiwxLjA5MiwwLDAsMS0uNjc3LjI0MmMtLjI3MSwwLS4zNy0uMTktLjMtLjUyNVoiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC04LjIxNyAtOC4zMjcpIiBmaWxsPSIjMzYzNjM2Ii8+CiAgPHBhdGggaWQ9IlBhdGhfODM4MiIgZGF0YS1uYW1lPSJQYXRoIDgzODIiIGQ9Ik0xNy43MjIsOC44NjFhLjk4Ni45ODYsMCwxLDEtLjk4Ni0uOTg2QS45ODYuOTg2LDAsMCwxLDE3LjcyMiw4Ljg2MVoiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC04Ljg0OCAtNC40MjQpIiBmaWxsPSIjMzYzNjM2Ii8+Cjwvc3ZnPgo=");
      background-repeat: no-repeat;
      width: 22px;
      height: 22px;
      display: inline-block;
      margin-right: 0.25rem;
   }
}
.add-on-horizontal-view-item-content {
   overflow-y: scroll;
   height: 200px;
   ul {
      padding-left: 1rem;
      li {
         list-style-type: disc;
      }
   }
}
</style>
