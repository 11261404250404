<template>
   <div class="profile-settings-wrapper">
      <div class="profile-settings-inner">
         <div class="profile-settings-section-content">
            <div class="profile-settings-section-content-inner">
               <div class="content-section-wrapper">
                  <div class="reset-password content-section-item">
                     <div class="reset-password-badge-wrapper" @click="resetPassword">
                        <div class="reset-password-badge-icon">
                           <svg xmlns="http://www.w3.org/2000/svg" width="85" height="119" viewBox="0 0 22.5 31.5">
                              <path
                                 id="locked"
                                 d="M27,15.75H24.75V9a6.75,6.75,0,1,0-13.5,0v6.75H9A2.25,2.25,0,0,0,6.75,18V31.5A2.25,2.25,0,0,0,9,33.75H27a2.25,2.25,0,0,0,2.25-2.25V18A2.25,2.25,0,0,0,27,15.75ZM13.5,9a4.5,4.5,0,1,1,9,0v6.75h-9ZM27,31.5H9V18H27Z"
                                 transform="translate(-6.75 -2.25)" />
                           </svg>
                        </div>
                        <div class="reset-password-badge">
                           <h4>{{ $t("profileSettings_lblPasswordChangeTitle") }}</h4>
                           <p>
                              {{ $t("profileSettings_lblPasswordChangeContent") }}
                           </p>
                        </div>
                     </div>
                  </div>

                  <!-- Security Content -->
                  <div class="security-content content-section-item" v-if="false">
                     <div class="content-section-item-inner">
                        <div class="section-description content-section-inner-item">
                           <h4>{{ $t("profileSettings_lblPrivacyAndSecurity") }}</h4>
                           <p>{{ $t("profileSettings_lblPrivacyAndSecurityDescription") }}</p>
                        </div>
                        <div class="section-form content-section-inner-item">
                           <span class="create-new-password-cta" @click="resetPassword">
                              {{ $t("profileSettings_lblResetPassword") }}
                           </span>
                           <!-- <UIButton className="warning" :text="$t('profileSettings_lblResetPassword')" @click.native="resetPassword" /> -->
                        </div>
                     </div>
                  </div>

                  <div class="user-information-content content-section-item">
                     <div class="content-section-item-inner">
                        <div class="section-description content-section-inner-item">
                           <h4>{{ $t("profileSettings_lblUserInfo") }}</h4>
                           <p>{{ $t("profileSettings_lblUserDescription") }}</p>
                        </div>
                        <div class="section-form content-section-inner-item">
                           <UIForm class="user-information-wrapper section-form-wrapper">
                              <div class="form-row">
                                 <div class="form-item">
                                    <label class="sub-item-title"
                                       >{{ $t("profileSettings_lblUserName") }} {{ $t("profileSettings_lblUserSurname") }}</label
                                    >
                                    <span>{{ profile.userName }} {{ profile.userSurname }}</span>
                                 </div>
                                 <div class="form-item">
                                    <label class="sub-item-title">{{ $t("profileSettings_lblUserEmail") }}</label>
                                    <span>{{ profile.userEmail }}</span>
                                 </div>
                              </div>
                              <div class="form-row">
                                 <div class="form-item">
                                    <label class="sub-item-title">{{ $t("profileSettings_lblUserPhone") }}</label>
                                    <span>{{ fullUserPhone }}</span>
                                 </div>
                                 <div class="form-item">
                                    <label class="sub-item-title">{{ $t("profileSettings_lblUserTimezone") }}</label>
                                    <span>{{ profile.userTimezoneName }}</span>
                                 </div>
                                 <div class="form-item">
                                    <label class="sub-item-title">{{ $t("profileSettings_lblUserLanguage") }}</label>
                                    <span>{{ profile.userLanguage }}</span>
                                 </div>
                              </div>
                           </UIForm>
                        </div>
                     </div>
                  </div>
                  <div class="agency-information-content content-section-item">
                     <div class="content-section-item-inner">
                        <div class="section-description content-section-inner-item">
                           <h4>{{ $t("profileSettings_lblAgencyInfo") }}</h4>
                           <p>{{ $t("profileSettings_lblAgencyDescription") }}</p>
                        </div>
                        <div class="section-form content-section-inner-item">
                           <UIForm class="user-information-wrapper section-form-wrapper">
                              <div class="form-row">
                                 <div class="form-item">
                                    <label class="sub-item-title">{{ $t("profileSettings_lblAgencyId") }}</label>
                                    <span>{{ profile.agencyId }}</span>
                                 </div>
                                 <div class="form-item">
                                    <label class="sub-item-title">{{ $t("profileSettings_lblGsa") }}</label>
                                    <span>{{ profile.gsaName }}</span>
                                 </div>
                                 <div class="form-item">
                                    <label class="sub-item-title">{{ $t("profileSettings_lblAgencyOfficialName") }}</label>
                                    <span>{{ profile.agencyOfficialTitle }}</span>
                                 </div>
                              </div>
                              <div class="form-row">
                                 <div class="form-item">
                                    <label class="sub-item-title">{{ $t("profileSettings_lblAgencyName") }}</label>
                                    <span>{{ profile.agencyName }}</span>
                                 </div>
                                 <div class="form-item">
                                    <label class="sub-item-title">{{ $t("profileSettings_lblAgencyEmail") }}</label>
                                    <span>{{ profile.agencyEmail }}</span>
                                 </div>
                              </div>
                           </UIForm>
                        </div>
                     </div>
                  </div>
                  <div class="location-information-content content-section-item">
                     <div class="content-section-item-inner">
                        <div class="section-description content-section-inner-item">
                           <h4>{{ $t("profileSettings_lblRegionLanguagePreferences") }}</h4>
                           <p>{{ $t("profileSettings_lblRegionLanguagePreferencesDescription") }}</p>
                        </div>
                        <div class="section-form content-section-inner-item">
                           <UIForm class="user-information-wrapper section-form-wrapper">
                              <div class="form-row">
                                 <div class="form-item">
                                    <label class="sub-item-title">{{ $t("profileSettings_lblAgencyCountryPhoneCode") }}</label>
                                    <span>{{ agencyPhone?.country }}</span>
                                 </div>
                                 <div class="form-item">
                                    <label class="sub-item-title">{{ $t("profileSettings_lblAgencyPhone") }}</label>
                                    <span>{{ fullAgencyPhone }}</span>
                                 </div>
                                 <div class="form-item">
                                    <label class="sub-item-title">{{ $t("profileSettings_lblAgencyTimezone") }}</label>
                                    <span>{{ profile.agencyTimezoneName }}</span>
                                 </div>
                              </div>
                              <div class="form-row">
                                 <div class="form-item">
                                    <label class="sub-item-title">{{ $t("profileSettings_lblAgencyAddress") }}</label>
                                    <span>{{ fullAgencyAddress }}</span>
                                 </div>
                              </div>
                           </UIForm>
                        </div>
                     </div>
                  </div>
               </div>
               <!-- <div class="content-cta-wrapper">
                  <UIButton className="default" :text="$t('profileSettings_lblUpdate')" />
               </div> -->
            </div>
         </div>
      </div>
   </div>
</template>

<script>
import { userService } from "@/service/index.js";
import UIForm from "@/components/unitary/form/Form.vue";

export default {
   name: "ProfileSettings",
   components: {
      UIForm,
   },
   data() {
      return {
         profile: {},
         value: "Europe/Istanbul",
         options: ["Europe/Istanbul", "Europe/Istanbul", "Europe/Istanbul", "Europe/Istanbul"],
      };
   },
   created() {
      const self = this;
      userService.getProfile({}).then((response) => {
         if (response.result.success) {
            self.profile = response.profile;

            const userSettingsObj = {
               userFullName: `${self.profile.userName} ${self.profile.userSurname}`,
               userEmail: self.profile.userEmail,
               userId: self.profile.userId,
            };

            this.$emit("set-user-settings-obj", userSettingsObj);
         }
      });
   },
   methods: {
      resetPassword() {
         const userEmail = this.profile.userEmail;
         const filter = new RegExp(
            /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
         );

         if (filter.test(userEmail)) {
            userService.resetPassword({ email: userEmail, sendEmail: false }).then((response) => {
               if (response.result.success) {
                  window.open(`/ResetPassword?token=${response.token}`);
               }
            });
         }
      },
   },
   computed: {
      agencyPhone() {
         const self = this;
         return self.profile.agencyPhone;
      },
      fullAgencyAddress() {
         const self = this;
         return `${self.profile.agencyAddress1} - ${self.profile.agencyAddress2}`;
      },
      fullAgencyPhone() {
         const self = this;
         return self.profile.agencyPhone ? `${self.profile.agencyPhone.city}${self.profile.agencyPhone.number}` : "";
      },
      fullUserPhone() {
         const self = this;
         return self.profile.userPhone ? `${self.profile.userPhone?.country} ${self.profile.userPhone.city} ${self.profile.userPhone.number}` : "";
      },
      userProfileNameText() {
         const self = this;
         if (Object.entries(self.profile).length != 0) {
            const userName = self.profile.userName;
            const userSurname = self.profile.userSurname;
            const userProfileNameText = userName.charAt(0) + userSurname.charAt(0);

            return userProfileNameText;
         } else {
            return "";
         }
      },
   },
};
</script>

<style lang="scss" scoped>
.profile-settings-wrapper {
   .profile-settings-inner {
      padding: 1.5rem 2.5rem;

      .profile-settings-section-header {
         margin-bottom: 32px;
         .profile-settings-section-header-inner {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-bottom: 24px;

            .section-title {
               h4 {
                  font-size: 24px;
                  color: #373b42;
               }
            }
            .section-cta {
            }
         }
      }
      .profile-settings-section-content {
         .profile-settings-section-content-inner {
            .content-section-wrapper {
               margin-bottom: 48px;
               .content-section-item {
                  padding: 24px;
                  background: #f3f3f3;
                  border-radius: 24px;
                  margin-top: 24px;
                  border: 1px solid #d5d2d2;

                  &:first-child {
                     padding-top: 0;
                  }

                  .content-section-item-inner {
                     align-items: flex-start;
                     .content-section-inner-item {
                        &.section-description {
                           width: 25%;
                           max-width: 25%;
                           margin-bottom: 16px;

                           h4 {
                              margin-bottom: 8px;
                              font-size: 16px;
                              font-weight: 700;
                              color: #373b42;
                           }
                           p {
                              font-size: 12px;
                              width: 220px;
                              max-width: 220px;
                           }
                        }
                        &.section-form {
                           max-width: 100%;
                           border-top: 1px solid #dbdbdb;
                           width: 97%;

                           .section-form-wrapper {
                              margin-top: 16px;
                              .form-row {
                                 width: 100%;
                                 margin-bottom: 24px;
                                 gap: 80px;
                                 display: grid;
                                 grid-template-columns: 1fr 1fr 1fr;
                                 .form-item {
                                    display: flex;
                                    flex-direction: column;
                                    .sub-item-title {
                                       font-weight: 500;
                                       font-size: var(--small);
                                    }
                                    span {
                                       font-size: var(--small);
                                    }
                                 }
                              }

                              @media (max-width: 768px) {
                                 .form-row {
                                    gap: 24px;
                                    grid-template-columns: 1fr;
                                 }
                              }

                              @media (max-width: 992px) {
                                 .form-row {
                                    gap: 24px;
                                    grid-template-columns: 1fr 1fr 1fr;
                                 }
                              }
                           }
                        }
                     }
                  }

                  &.profile-image-content {
                     .profile-image-inner {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        .profile-image-item {
                           &.profile-thumbnail-image {
                              span {
                                 width: 127px;
                                 height: 127px;
                                 display: flex;
                                 align-items: center;
                                 justify-content: center;
                                 background-color: #6e93c6;
                                 border-radius: 50%;
                                 font-size: 48px;
                                 color: white;
                              }
                           }
                           &.profile-thumbnail-image-upload {
                              width: 70%;
                              height: 100%;
                              min-height: 160px;
                              display: flex;
                              align-items: center;
                              justify-content: center;
                              background-color: #f2f5f8;
                              border: 2px dashed #b1b2b3;
                              border-radius: 8px;
                              .profile-thumbnail-image-upload-inner {
                                 display: flex;
                                 flex-direction: column;
                                 align-items: center;
                                 justify-content: center;
                                 text-align: center;

                                 span {
                                    font-size: 20px;
                                    font-weight: 500;
                                 }

                                 button {
                                    margin-top: 8px;
                                    background-color: white;

                                    &:hover {
                                       color: #266cbf;
                                    }
                                 }
                              }
                           }
                        }
                     }
                  }
                  &.location-information-content {
                     .section-form-wrapper {
                        .form-row:last-child {
                           .form-item {
                              width: 100% !important;
                           }
                        }
                     }
                  }

                  &.security-content {
                     .create-new-password-cta {
                        padding: 0.75rem 1.125rem;
                        background: #e7eef4;
                        border-radius: 0.25rem;
                        color: #909eaa;
                        font-weight: 500;
                        display: inline-block;
                        cursor: pointer;

                        &:hover {
                           background-color: var(--accent-primary);
                           color: #fff;
                        }
                     }
                  }
                  &.reset-password {
                     background: none;
                     padding: 0px;
                     border-radius: 0px;
                     border: 0px;
                     .reset-password-badge-wrapper {
                        background-color: #e7e7e7;
                        background-color: var(--accent-primary);
                        background-color: #ffd04c;
                        background-color: #ffd664;
                        border: 2px solid #f0bd2e;
                        padding: 0.5rem 2rem;
                        color: #363636;
                        border-radius: 0.75rem;
                        display: flex;
                        align-items: center;
                        cursor: pointer;
                        .reset-password-badge-icon {
                           svg {
                              width: 40px;
                           }
                        }
                        .reset-password-badge {
                           padding-left: 2rem;
                           h4 {
                              font-size: 1.125rem;
                              font-weight: 600;
                              margin-bottom: 0rem;
                           }
                           p {
                              font-size: 0.85rem;
                           }
                        }

                        &:hover {
                           background-color: #ccc;
                           background-color: #ffd04c;
                           color: #363636;
                        }
                     }
                  }
               }
            }
            .content-cta-wrapper {
               width: 100%;
               display: flex;
               align-items: center;
               justify-content: center;

               & > button {
                  margin-right: 32px;

                  &:last-child {
                     margin-right: 0;
                  }
               }
            }
         }
      }
   }
}
</style>
