<template>
   <div id="flight-paxes-wrapper" class="g-input-container" v-click-outside="handlerOutClick">
      <label>{{ inputLabel }}</label>

      <div class="paxes-inner-wrapper">
         <input type="text" tabindex="5" @focus="handlerFocus" @keydown="handlerEnter" />
         <div class="paxes-infos" @click="dropdown = !dropdown">
            <span>
               {{ $t("common_adult") }}: <strong>{{ viewDatas.adult }}</strong>
            </span>
            <span v-if="viewDatas.child > 0">
               {{ $t("common_child") }}:<strong>{{ viewDatas.child }}</strong>
            </span>
            <span v-if="viewDatas.infant > 0">
               {{ $t("common_infant") }}:<strong>{{ viewDatas.infant }}</strong>
            </span>
         </div>
         <i class="gts-icon icon-paxes icon-sm" @click="dropdown = !dropdown"></i>
         <div class="gts-dropdown" v-if="dropdown">
            <ul class="paxes-dropdown-inner">
               <li class="pax-line">
                  <div class="pax-col">
                     <span>{{ $t("common_adult") }}</span>
                     <small>12</small>
                  </div>
                  <div class="numerator-container">
                     <div class="numarator-btn" @click="viewDatas.adult <= 1 ? '' : handlerDecrease('adult')">-</div>
                     <span>{{ viewDatas.adult }}</span>
                     <div class="numarator-btn" @click="viewDatas.adult >= 7 || totalPaxes >= 7 ? '' : handlerIncrease('adult')">+</div>
                  </div>
               </li>
               <li class="pax-line">
                  <div class="pax-col">
                     <span>{{ $t("common_child") }}</span>
                     <small>2-12</small>
                  </div>

                  <div class="numerator-container">
                     <div class="numarator-btn" @click="viewDatas.child <= 0 ? '' : handlerDecrease('child')">-</div>
                     <span>{{ viewDatas.child }}</span>
                     <div class="numarator-btn" @click="viewDatas.adult >= 7 || totalPaxes >= 7 ? '' : handlerIncrease('child')">+</div>
                  </div>
               </li>

               <li class="pax-line">
                  <div class="pax-col">
                     <span>{{ $t("common_infant") }}</span>
                     <small>0-2</small>
                  </div>
                  <div class="numerator-container">
                     <div class="numarator-btn" @click="viewDatas.infant <= 0 ? '' : handlerDecrease('infant')">-</div>
                     <span>{{ viewDatas.infant }}</span>
                     <div class="numarator-btn" @click="viewDatas.infant >= viewDatas.adult || totalPaxes >= 7 ? '' : handlerIncrease('infant')">
                        +
                     </div>
                  </div>
               </li>
            </ul>
         </div>
      </div>
   </div>
</template>

<script>
export default {
   name: "FlightPaxes",
   components: {},
   props: {
      inputLabel: String,
   },
   data() {
      return {
         dropdown: false,
         paxes: {
            adult: 1,
            child: 0,
            infant: 0,
         },
      };
   },
   computed: {
      viewDatas() {
         return this.paxes;
      },
      totalPaxes() {
         return this.paxes.adult + this.paxes.child + this.paxes.infant;
      },
   },
   methods: {
      handlerEmitPaxes() {
         const self = this;

         let paxes = {};

         if (self.paxes.adult > 0) {
            paxes.adult = self.paxes.adult;
         }
         if (self.paxes.child > 0) {
            paxes.child = self.paxes.child;
         }
         if (self.paxes.infant > 0) {
            paxes.infant = self.paxes.infant;
         }

         this.$emit("update-paxes", paxes);
      },
      handlerClose() {
         this.handlerEmitPaxes();
         this.dropdown = false;
      },
      handlerIncrease(type) {
         this.paxes[type]++;
         this.handlerEmitPaxes();
         //this.$emit("update-adult", { type: type, value: true });
      },
      handlerDecrease(type) {
         this.paxes[type]--;
         this.handlerEmitPaxes();
         //this.$emit("update-adult", { type: type, value: false });
      },
      handlerFocus() {
         const self = this;
         self.dropdown = true;
      },
      handlerEnter(key) {
         const self = this;
         if (key.keyCode == 13) {
            self.dropdown = false;
         }
      },
      handlerOutClick() {
         this.dropdown = false;
         this.handlerEmitPaxes();
      },
   },
};
</script>

<style lang="scss" scoped>
#flight-paxes-wrapper {
   .paxes-inner-wrapper {
      display: flex;
      flex-direction: row !important;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;

      i {
         cursor: pointer;
      }
      input {
         position: absolute;
         left: 0;
         right: 0;
         opacity: 0;
         cursor: pointer;
         pointer-events: none;
      }
      .paxes-infos {
         width: 100%;
         @keyframes animSpan {
            from {
               opacity: 0;
               top: 110%;
            }
            to {
               opacity: 1;
               top: 100%;
            }
         }
         span {
            animation-name: animSpan;
            animation-timing-function: ease-in-out;
            animation-duration: 0.15s;
            margin-right: 0.125rem;
            &:after {
               content: ",";
            }

            &:last-child {
               &:after {
                  content: "";
               }
            }
         }
      }
      .gts-dropdown {
         position: absolute;
         left: 0;
         right: 0;
         padding: 0;
         border-radius: 8px;
         background-clip: padding-box;
         background-color: #fff;
         top: 100%;
         box-shadow: rgba(0, 0, 0, 0.2) 0px 10px 30px 0px;
         width: 180px;
         overflow: hidden;
         z-index: 100;
         top: 50px;
         min-width: 100%;
         min-height: 100px;

         @media (min-width: 992px) {
            width: fit-content;
         }

         .paxes-dropdown-inner {
            padding: 1rem;
            li.pax-line {
               display: flex;
               justify-content: space-between;
               padding: 0.5rem 0;

               .pax-col {
                  display: flex;
                  flex-direction: column;
                  span {
                     font-weight: 600;
                  }
               }
               .numerator-container {
                  display: flex;
                  align-items: center;

                  span {
                     margin: 0 1rem;
                     font-weight: 500;
                     font-size: 1.25rem;
                  }
                  .numarator-btn {
                     outline: 0;
                     border: 0;
                     border-radius: 50%;
                     width: 28px;
                     height: 28px;
                     line-height: 28px;
                     font-size: 1.25rem;
                     background-color: #fff;
                     color: var(--txt-black);
                     display: flex;
                     align-items: center;
                     justify-content: center;
                     box-shadow: rgba(0, 0, 0, 0.18) 0px 2px 4px;
                     transition-property: all;
                     transition-timing-function: ease-in;
                     transition-duration: 0.05s;
                     cursor: pointer;

                     &:hover {
                        background-color: var(--accent-primary);
                        color: #fff;
                     }

                     &:disabled {
                        background-color: #eaeaea;
                        opacity: 0.5;

                        &:hover {
                           background-color: var(--primary-bg-color);
                           color: var(--txt-black);
                        }
                     }
                  }
               }
            }
         }
         .button-container {
            padding: 0 1rem;
            padding-bottom: 0.5rem;
            display: flex;
            justify-content: flex-end;
         }
      }
   }
}
</style>
