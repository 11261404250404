<template>
    <div id="notes-container">
        <UIAlert status="info_blue" :rounded="false">
            <slot>
                <span>
                    {{ $t("notesComponent_infoAlert_text") }}
                </span>
            </slot>
        </UIAlert>

        <!-- ADD NEW NOTE -->
        <div id="add-new-note-wrapper">
            <textarea name="" id="add-new-note" rows="3" v-model="newNote" :placeholder="$t('taskNotes_placeholder')"></textarea>
            <UIButton className="default" :text="$t('common_save')" @click.native="handlerCreateNote" />
        </div>

        <!-- NOTE WRAPPER -->
        <div class="note-container-wrapper">
            <div class="note-container" ref="note" v-for="(note, index) in Notes" :key="index">
                <div class="note-header">
                    <p class="note-inserted-time">
                        <span class="title-fmt">{{ $t("notesComp_notTime") }}</span>
                        <span class="date-time">{{ note.insertedDatetime | fullDateTextFormat }} - {{ note.insertedDatetime | TimeFormat }}</span>
                    </p>
                    <div class="note-actions">
                        <button :disabled="index == updatedNote" class="gts-animation" @click="updatedNote = index">
                            <i class="gts-icon icon-edit-note icon-sm"></i>
                        </button>
                        <button class="gts-animation" @click="handlerDeleteNote(index, note)">
                            <i class="gts-icon icon-delete-note icon-sm"></i>
                        </button>
                    </div>
                </div>
                <div class="note-body" :class="{ 'is-deleting-now': index == deletedNote }">
                    <p v-if="index != updatedNote">{{ note.note }}</p>

                    <Transition name="slide" mode="out-in">
                        <div class="note-update-input" v-if="index == updatedNote">
                            <textarea name="" rows="4" :value="note.note" ref="textarea"></textarea>
                            <div class="note-update-actions">
                                <!-- <UIButton className="default" text="update"  @click.native="handlerUpdateCustomer"/> -->
                                <UIButton className="default_outline" :text="$t('common_cancel')" @click.native="handlerCancelNote" />
                                <UIButton className="default" :text="$t('common_save')" @click.native="handlerSetNote(note)" />
                            </div>
                        </div>
                    </Transition>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { notesService } from "@/service/index.js";
import UIAlert from "@/components/unitary/Alert.vue";
import UIButton from "@/components/unitary/Button.vue";
export default {
    name: "Notes",
    props: {
        orderId: [String, Number],
    },
    components: {
        UIAlert,
        UIButton,
    },
    data() {
        return {
            newNote: "",
            notes: null,
            updatedNote: null,
            deletedNote: null,
            filters: {
                taskId: 0,
                orderId: this.orderId,
                noteId: -1,
            },
        };
    },
    computed: {
        Notes() {
            const self = this;
            return self.notes;
        },
    },
    methods: {
        handlerUpdateNotes() {
            const self = this;
            const listNotesQuery = {
                taskId: 0,
                orderId: self.orderId,
                noteId: -1,
            };
            notesService.listNotes(listNotesQuery).then((response) => {
                self.notes = response.notes.reverse();
                self.deletedNote = null;
                self.updatedNote = null;
            });
        },
        handlerCreateNote() {
            const self = this;
            let query = {
                taskId: 0,
                orderId: self.orderId,
                dbState: 1,
                noteId: 0,
                note: self.newNote,
            };

            notesService.addNote(query).then((response) => {
                if (response.result.success) {
                    self.handlerUpdateNotes();
                    self.newNote = null;
                }
            });
        },
        handlerUpdateNote(index) {
            const self = this;
            self.updatedNote = index;
        },
        handlerDeleteNote(index, note) {
            const self = this;
            self.deletedNote = index;
            let query = {
                orderId: self.orderId,
                noteId: note.id,
                dbState: 2,
                note: note.note,
                id: note.id,
                taskId: note.taskId,
            };
            notesService.setNote(query).then((response) => {
                if (response.result.success) {
                    self.handlerUpdateNotes();
                }
            });
        },
        handlerSetNote(note) {
            let newValue = this.$refs.textarea[0].value;

            const self = this;
            let query = {
                id: note.id,
                taskId: note.taskId,
                orderId: this.orderId,
                dbState: 1,
                noteId: note.id,
                note: newValue,
            };

            notesService.setNote(query).then((response) => {
                if (response.result.success) {
                    self.handlerUpdateNotes();
                    this.updatedNote = null;
                }
            });
        },
        handlerCancelNote() {
            const self = this;
            self.updatedNote = null;
        },
    },
    watch: {
        orderId: {
            handler: function () {
                const self = this;
                self.handlerUpdateNotes();
            },
        },
    },
};
</script>

<style lang="scss" scoped>
#notes-container {
    padding: 3rem 2rem;
    background: #fff;
    border-radius: 0.5rem;
    border-top-left-radius: 0;
    #add-new-note-wrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        margin-top: 2rem;

        textarea {
            width: 100%;
            border-radius: 12px;
            border: 1px solid #ccc;
            padding: 1.5rem 1rem;
            color: var(--ghost-text-color);

            &::first-letter {
                text-transform: capitalize;
            }
            &:focus {
                border: 1px solid var(--accent-primary);
                outline: 0.25rem solid #33b8ff38;
            }
        }
        button {
            margin-top: 0.5rem;
        }
    }
    .note-container-wrapper {
        display: grid;
        grid-template-columns: auto auto;
        .note-container {
            position: relative;
            background-color: #fef49c;
            border: 1px solid #7b7b7b;
            border-radius: 6px;
            margin: 2rem;
            overflow: hidden;

            .note-header {
                background-color: #feea3d;
                padding: 0.5rem 1.5rem;
                display: flex;
                justify-content: space-between;
                align-items: center;

                .note-inserted-time {
                    .title-fmt {
                        font-weight: 600;
                        margin-right: 0.5rem;
                    }
                    .date-time {
                        font-weight: 400;
                    }
                }

                .note-actions {
                    margin: 0.5rem;

                    display: flex;
                    button {
                        background: none !important;
                        width: 38px;
                        height: 38px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: 50%;
                        margin: 0 0.25rem;

                        &:hover {
                            background-color: #00000020 !important;
                            filter: none !important;
                        }

                        &:disabled {
                            opacity: 0;
                        }
                    }
                }
            }
            .note-body {
                padding: 1rem 1.5rem;
                p {
                }
                .note-update-input {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-end;
                    textarea {
                        width: 100%;
                        outline: 0;
                        border: 1px solid #ccc;
                        border-radius: 16px;
                        white-space: pre-wrap;
                        padding: 1rem;
                        color: #666;
                    }
                    .note-update-actions {
                        display: flex;
                        margin-top: 1rem;
                        button {
                            margin-left: 0.5rem;
                        }
                    }
                }

                &.is-deleting-now {
                    &:after {
                        content: "*";
                        text-indent: -999em;

                        position: absolute;
                        top: 0;
                        bottom: 0;
                        left: 0;
                        right: 0;
                        background-color: white;
                        opacity: 0.7;
                    }
                }
            }
        }
    }
}
</style>
