<template>
   <div id="customer-list-wrapper">
      <div id="customer-list-inner-wrapper">
         <div id="customer-list-header">
            <h1 v-if="activeTab == 'fihrist'">{{ $t("customerList_customerList") }}</h1>
            <h1 v-else>
               <button class="gts-animation" @click="activeTab = 'fihrist'">
                  <i class="gts-icon icon-arrow-full"></i>
               </button>

               <!-- {{ $t("customerList_addCustomer") }} -->
            </h1>

            <button id="customer-list-close" v-if="showClose" @click="handlerCloseCustomerManagement">
               <i class="gts-icon icon-close-rounded"></i>
            </button>
         </div>
         <b-tabs v-model="activeTab" :destroy-on-hide="true">
            <!-- CUSTOMER FIHRIST -->
            <b-tab-item label="fihrist" value="fihrist">
               <CustomerList @customer-selection="handlerCustomerSelection" @set-customer="handlerSetCustomer" :hidden-columns="showClose" />
            </b-tab-item>
            <!-- SET CUSTOMER -->
            <b-tab-item label="set" value="set">
               <CustomerSet :selectedCustomer="selectedCustomer" @okey="activeTab = 'fihrist'" ref="customerSet" />
            </b-tab-item>
         </b-tabs>
      </div>
   </div>
</template>

<script>
import CustomerSet from "@/components/structural/flight/common/CustomerSet.vue";
import CustomerList from "@/components/structural/flight/common/CustomerList.vue";
export default {
   name: "CustomerManagement",
   components: {
      CustomerSet,
      CustomerList,
   },
   props: {
      showClose: Boolean,
   },
   data() {
      return {
         activeTab: "fihrist",
         selectedCustomer: "",
         isActive: false,
      };
   },

   methods: {
      handlerCustomerSelection(customer) {
         this.selectedCustomer = customer;
         this.activeTab = "set";
      },
      handlerCloseCustomerManagement() {
         this.$parent.showFihrist = false;
      },
      handlerSetCustomer(customer) {
         this.$emit("set-customer", customer);
         this.$parent.showFihrist = false;
      },
   },
};
</script>

<style lang="scss" scoped>
#customer-list-wrapper {
   width: 100%;
   background-color: #fff;
   padding: 1.5rem 1rem;
   border-radius: 1rem;

   #customer-list-inner-wrapper {
      position: relative;
      #customer-list-header {
         display: flex;
         justify-content: space-between;
         align-items: center;
         margin-bottom: 1rem;
         h1 {
            font-size: var(--xxlarge);
            font-weight: 500;
            display: flex;
            align-items: center;
            justify-content: center;
            button {
               background: transparent !important;
               i {
                  transform: rotate(180deg);
               }
            }
         }
         #customer-list-close {
            position: absolute;
            right: 0;
            top: 0;
            background-color: transparent !important;
         }
      }
   }
}
</style>

<style lang="scss">
#customer-list-wrapper .b-tabs {
   .tabs {
      display: none !important;
   }
}
</style>
