<template>
   <div id="rent-a-car-description-container" class="gts-box with-border">
      <span class="section-title">{{ $t("rentacar_lblRentACarDescriptionsTitle") }} </span>

      <ul class="rent-a-car-info-list">
         <li>{{ $t("rentacar_lblRentACarDescriptionsInfoOne") }}</li>
         <li>{{ $t("rentacar_lblRentACarDescriptionsInfoTwo") }}</li>
         <li>{{ $t("rentacar_lblRentACarDescriptionsInfoThree") }}</li>
         <li>{{ $t("rentacar_lblRentACarDescriptionsInfoFour") }}</li>
         <li>{{ DescriptionFive(driverRequirements) }}</li>
      </ul>

      <UIAlert status="info_orange_2">
         <div class="description-alert-icon">
            <i class="gts-icons icon-inline-alert"></i>
         </div>
         <div class="description-alert-content">
            <p>{{ handlerLastVoidDateTimeFMT(lastVoidDateTime) }}</p>
         </div>
      </UIAlert>

      <div class="rent-a-car-agency-note-wrapper">
         <label for="rentacaragencynote">{{ $t("common_agencyNote") }}</label>
         <textarea id="rentacaragencynote" v-model="agencyNote"></textarea>
      </div>
   </div>
</template>

<script>
// import UITextarea from "@/components/unitary/form/Textarea.vue";
import UIAlert from "@/components/unitary/Alert.vue";
export default {
   name: "RentACarDescriptions",
   props: {
      lastVoidDateTime: String,
      driverRequirements: Object,
   },
   components: {
      UIAlert,
      //       UITextarea,
   },
   data() {
      return {
         agencyNote: "",
      };
   },
   methods: {
      DescriptionFive(val) {
         // let a = this.$t("rentacar_lblRentACarDescriptionsInfoFives");
         // console.log("DescriptionFive", a);
         const self = this;
         return self.$t("rentacar_lblRentACarDescriptionsInfoFives").replace("#VALUE1#", val.minDriverAge).replace("#VALUE2#", val.minLicenseAge);
      },
      handlerLastVoidDateTimeFMT(val) {
         const willReturn = this.$t(`rentacarCheckout_lblLastVoidDateTime`).replace("#VALUE#", val);

         return willReturn;
      },
   },
   watch: {
      agencyNote: {
         handler: function (note) {
            this.$emit("agency-note", note);
         },
      },
   },
};
</script>

<style lang="scss" scoped>
#rent-a-car-description-container {
   .rent-a-car-info-list {
      padding-left: 1.5rem;
      margin: 1rem 0;
      margin-bottom: 2rem;
      li {
         list-style-type: disc;
      }
   }
   .rent-a-car-agency-note-wrapper {
      margin-top: 2rem;
      display: flex;
      flex-direction: column;

      label {
         margin-bottom: 0.5rem;
      }
      textarea {
         border: 1px solid #ccc;
         border-radius: 0.5rem;
         height: 132px;
         padding: 1rem;
         color: #363636;
         //  text-transform: capitalize;
      }
      //       background: red;
   }
}
</style>
