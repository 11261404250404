<template>
   <div id="package-offer-container">
      <div class="package-offer-header">
         <h4>Paket Teklifi</h4>
      </div>

      <div class="package-offer-inner-wrapper">
         <div
            id="package-offer-wrapper"
            :class="mode ? 'column-offer' : 'row-offer'"
            :style="{ 'justify-content': PackageOfferCards.length > 4 ? 'space-around' : 'start' }">
            <!-- Package Offer Card -->
            <template v-for="(card, index) in PackageOfferCards">
               <div class="package-offer-card-wrapper gts-card" :key="index">
                  <!-- Hotel Offer Wrapper -->
                  <Section class="poc-hotel-offer-wrapper">
                     <div class="poc-hotel-image-wrapper">
                        <div class="img-container" :style="{ 'background-image': 'url(' + card.hotel.imageUrl + ')' }"></div>
                        <!-- <img :src="card.hotel.imageUrl" alt="" class="src" /> -->
                     </div>
                     <div class="poc-hotel-info-wrapper">
                        <div class="hotel-info-container">
                           <span class="hotel-name">{{ card.hotel.title }}</span>

                           <div class="hotel-stars">
                              <i
                                 v-for="(item, index) in 5"
                                 :key="index"
                                 class="gts-icon icon-star icon-xs"
                                 :class="parseInt(index) < card.hotel.stars ? 'active' : ''"></i>
                           </div>
                        </div>
                        <div class="hotel-date-container">
                           <div class="hotel-date-wrapper check-in-date">
                              <span class="txt-small">Check-in Tarihi</span>
                              <span class="txt-black txt-large">{{ card.hotel.checkinDate | fullDateShortMonth }}</span>
                           </div>
                           <div class="hotel-date-wrapper check-out-date">
                              <span class="txt-small">Check-out Tarihi</span>
                              <span class="txt-black txt-large">{{ card.hotel.checkoutDate | fullDateShortMonth }}</span>
                           </div>
                        </div>
                     </div>
                  </Section>

                  <!-- Flight Offer Wrapper -->
                  <Section class="poc-flight-offer-wrapper">
                     <template v-for="(flight, index) in card.flights">
                        <div class="flight-offer" :key="index">
                           <div class="route-and-marketing-airline">
                              <img :src="`${$constants.cdn}/airline_34x34/${flight.marketingAirlineCode}.svg`" width="32" height="32" />
                              <span class="txt-bold">{{ flight.route }}</span>
                           </div>
                           <span class="time-information">
                              {{ flight.departureDatetime | TimeFormat }}
                           </span>
                        </div>
                     </template>
                  </Section>

                  <!-- CardInfos Wrapper -->
                  <Section class="poc-card-informations-wrapper">
                     <span>Uçuş + Konaklama Toplam</span>
                     <span class="originalAmount">{{ card.amount.originalAmount | price }}</span>
                     <span class="discountPercentage">%{{ card.amount.discountRatio }} indirim</span>
                     <span class="discountedAmount">{{ card.amount.discountedAmount | price }}</span>
                     <!-- CTA Wrapper -->
                     <Section class="poc-card-action-wrapper">
                        <UIButton className="default" text="Sepete Ekle" />
                        <!-- <UIButton className="alert" text="Sepete Ekle" /> -->
                     </Section>
                  </Section>
               </div>
            </template>
         </div>
      </div>
   </div>
</template>

<script>
/*
      .package-offer-card-wrapper default height setted
*/

import UIButton from "@/components/unitary/Button.vue";

export default {
   name: "PackageCompTestVue",
   props: {
      packageOffers: Array,
   },
   components: {
      UIButton,
   },
   data() {
      return {
         mode: true,
         cards: [],
      };
   },

   computed: {
      PackageOfferCards() {
         return this.packageOffers;
      },
   },
   methods: {},
};
</script>

<style lang="scss" scoped>
#package-offer-container {
   .package-offer-header {
      background-color: var(--primary);
      display: inline-flex;
      align-items: center;
      justify-content: center;
      padding: 0.25rem 0.75rem;
      border-radius: 0.5rem;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      h4 {
         color: #fff;
         font-weight: 500;
         margin-right: 0.5rem;
      }
   }
   .package-offer-inner-wrapper {
      background-color: #fff;
      padding: 1rem;
      margin-bottom: 2rem;
      #package-offer-wrapper {
         display: flex;
         gap: 1rem;
         justify-content: space-around;

         &.column-offer {
            flex-direction: row;
         }

         &.row-offer {
            flex-direction: column;

            .package-offer-card-wrapper {
               flex-direction: row;
               width: 100%;

               section.poc-hotel-offer-wrapper {
                  width: 100%;
                  .poc-hotel-image-wrapper {
                     width: 100%;
                  }
                  .poc-hotel-info-wrapper {
                     width: 100%;
                  }
               }
               section.poc-flight-offer-wrapper {
                  border-left: 1px solid #ccc;
                  padding: 1rem;
                  .flight-offer {
                     width: 240px;
                  }
               }
            }
         }

         .package-offer-card-wrapper {
            display: flex;
            flex-direction: column;
            height: fit-content;
            width: 250px;
            overflow: hidden;
            background-color: #f5f5f9;
            /* box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 12px; */
            border-radius: 1rem;
            padding: 1rem;
            height: 100%;
            border: 1px solid #ccc;

            // Hotel Offer Wrapper
            section.poc-hotel-offer-wrapper {
               display: flex;
               position: relative;
               margin-bottom: 1rem;
               .poc-hotel-image-wrapper {
                  width: 80px;
                  height: initial;
                  overflow: hidden;
                  border-radius: 0.5rem;
                  margin-right: 0.75rem;
                  .img-container {
                     height: 100%;
                     background-position: center center;
                     background-repeat: no-repeat;
                     background-size: cover;
                  }
               }
               .poc-hotel-info-wrapper {
                  // background-color: #eee;
                  height: 154px;
                  display: flex;
                  flex-direction: column;
                  justify-content: space-between;
                  span.hotel-name {
                  }
               }
               .hotel-stars {
                  display: flex;
                  margin-bottom: 0.5rem;

                  i {
                     opacity: 0.3;
                     &.active {
                        opacity: 1;
                        filter: invert(74%) sepia(100%) saturate(805%) hue-rotate(358deg) brightness(103%) contrast(103%);
                     }
                  }
               }
               .hotel-date-container {
                  .hotel-wrapper-dummy {
                  }
                  .hotel-date-wrapper {
                     display: flex;
                     flex-direction: column;
                     line-height: 20px;
                     margin-bottom: 0.25rem;

                     &.check-in-date {
                        span {
                           color: #1dc973;
                        }
                     }
                     &.check-out-date {
                        span {
                           color: #e24323;
                        }
                     }
                  }
               }

               &::before,
               &::after {
                  content: "";
                  width: 12px;
                  height: 12px;
                  position: absolute;
                  bottom: -20px;
                  background-color: #e0e7f0;
                  border-radius: 50%;
               }

               &::before {
                  left: calc(-1rem + -6px);
               }
               &::after {
                  right: calc(-1rem + -6px);
               }
            }

            //Flight Offer Wrapper
            section.poc-flight-offer-wrapper {
               margin-top: 1rem;
               .flight-offer {
                  background-color: #ebebeb;
                  border-radius: 24rem;
                  padding: 0.25rem;
                  padding-right: 1rem;
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  margin-bottom: 0.5rem;

                  .route-and-marketing-airline {
                     display: flex;
                     align-items: center;
                     font-size: var(--normal);
                     img {
                        border-radius: 50%;
                     }
                     span {
                        margin-left: 0.5rem;
                     }
                  }
                  span.time-information {
                     font-size: var(--normal);
                     font-weight: 500;
                  }
               }
            }

            //Card Informations Wrapper
            section.poc-card-informations-wrapper {
               display: flex;
               flex-direction: column;
               justify-content: flex-end;
               margin-top: 1rem;

               span {
                  text-align: right;

                  &.originalAmount {
                     color: var(--primary);
                     font-weight: 500;
                     text-decoration: line-through;
                  }

                  &.discountPercentage {
                     color: #ff8900;
                     font-weight: 600;
                  }

                  &.discountedAmount {
                     color: #05ce91;
                     font-size: var(--xlarge);
                     font-weight: 600;
                  }
               }
            }

            //Action Wrapper
            section.poc-card-action-wrapper {
               display: flex;
               margin-top: 1rem;
               justify-content: center;
               width: 100%;
            }
            section.ctrl {
            }
         }
      }
   }
}
</style>
