<template>
   <div class="cancellation-policies-wrapper" :class="className">
      <div class="cancellation-policies-inner">
         <div class="cancellation-policies-inner-section --mobile-hidden">
            <i class="gts-icons icon-inline-alert"></i>
         </div>
         <div class="cancellation-policies-inner-section">
            <div class="inner-section-header">
               <p>
                  <span v-if="cancellationDate == null || cancellationDate == ''">{{ $t("cancellationPolicy_lblNoCancellationPolicy") }}</span>
                  <span v-if="cancellationDate != null && cancellationDate != ''">{{ $t("cancellationPolicy_lblFreeCancellationDate") }}</span>
                  <span v-if="cancellationDate != null && cancellationDate != ''"
                     >{{ cancellationDate | fullDateFormat }} - {{ cancellationDate | TimeFormat }}</span
                  >
               </p>
            </div>
            <div class="inner-section-content">
               <div class="content-item" v-if="cancellationDate == null || cancellationDate == ''">
                  <p>{{ $t("cancellationPolicy_lblNoLastBookingDateDescription") }}</p>
               </div>
               <div class="content-item" v-for="(policyItem, index) in policyList" :key="index">
                  <p v-if="policyItem.amountType === 1 && policyItem.policyType === 1" :key="index">
                     {{
                        $t("enum_cancellationPolicies_ratio", {
                           0: $options.filters.cancellationPolicyItemDate(policyItem.fromDate),
                           1: $options.filters.cancellationPolicyItemDate(policyItem.toDate),
                           2: policyItem.value,
                           3: "%",
                        })
                     }}
                  </p>
                  <p v-if="policyItem.amountType === 1 && policyItem.policyType !== 1" :key="index">
                     {{
                        $t("enum_cancellationPolicies_ratio_no_show", {
                           0: $options.filters.cancellationPolicyItemDate(policyItem.fromDate),
                           1: policyItem.value,
                           2: "%",
                        })
                     }}
                  </p>
                  <p v-if="policyItem.amountType === 2 && policyItem.policyType === 1" :key="index">
                     {{
                        $t("enum_cancellationPolicies_fixed", {
                           0: $options.filters.cancellationPolicyItemDate(policyItem.fromDate),
                           1: $options.filters.cancellationPolicyItemDate(policyItem.toDate),
                           2: policyItem.value,
                           3: policyItem.currency,
                        })
                     }}
                  </p>
                  <p v-if="policyItem.amountType === 2 && policyItem.policyType !== 1" :key="index">
                     {{
                        $t("enum_cancellationPolicies_fixed_no_show", {
                           0: $options.filters.cancellationPolicyItemDate(policyItem.fromDate),
                           1: policyItem.value,
                           2: policyItem.currency,
                        })
                     }}
                  </p>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>
export default {
   name: "CancellationPolicies",
   props: {
      className: { type: String },
      cancellationDate: { type: String },
      policies: { type: Array },
   },
   data() {
      return {
         policyList: [],
      };
   },
   created() {
      const self = this;
      self.policyList = this.policies;
      self.currencyMap();
   },
   methods: {
      currencyMap() {
         const self = this;
         self.policyList.forEach((policy) => {
            var currency = policy.currency;
            switch (policy.currency) {
               case "EUR":
                  currency = "€";
                  break;
               case "USD":
                  currency = "$";
                  break;
               case "TRY":
                  currency = "₺";
                  break;
               default:
                  currency = "€";
                  break;
            }
            policy.currency = currency;
         });
      },
   },
   watch: {
      policies: {
         handler: function (newPolicies) {
            const self = this;
            self.policyList = newPolicies;
            self.currencyMap();
         },
         deep: true,
      },
   },
};
</script>

<style lang="scss" scoped>
.cancellation-policies-wrapper {
   .cancellation-policies-inner {
      display: flex;
      align-items: flex-start;
      border: 1px dotted #e6955c;
      border-radius: 10px;
      overflow: hidden;
      .cancellation-policies-inner-section {
         &:first-child {
            padding: 24px;

            & > i {
               filter: invert(62%) sepia(71%) saturate(410%) hue-rotate(335deg) brightness(95%) contrast(90%);
            }
         }
         &:last-child {
            width: 100%;
         }
         i {
            display: block;
            width: 81px;
            height: 81px;
            background-repeat: no-repeat;
            background-size: contain;
         }
         .inner-section-header {
            padding: 10px 16px;
            margin-bottom: 10px;
            background-color: #fff9f2;
            border-bottom: 1px solid #e6955c;
            font-size: 14px;
            font-weight: 700;
            color: #e6955c;

            & > p > span:first-child {
               margin-right: 8px;
            }
         }
         .inner-section-content {
            padding: 0 16px;
            .content-item {
               margin-bottom: 8px;
               font-size: var(--small);

               &:last-child {
                  margin-bottom: 0;
               }
            }
         }
         @media (max-width: 767.98px) {
            &.--mobile-hidden {
               display: none;
            }
         }
      }
   }
}
</style>
