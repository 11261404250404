<template>
   <div class="package-iframe-c">
      <template v-if="iframeInformations.status">
         <iframe :src="IframeUrl" title="Schimetterling Login" @load="hidePreloader"> </iframe>
      </template>
   </div>
</template>

<script>
export default {
   name: "PackageIframe",
   props: {
      iframeInformations: Object,
   },
   mounted() {
      this.$nextTick(() => {
         const input = document.querySelector("iframe").contentDocument.querySelector("input");
         input.addEventListener("focus", (event) => {
            event.preventDefault();
            input.scrollIntoView({ block: "nearest", behavior: "smooth" });
         });
      });
   },

   computed: {
      IframeUrl() {
         let baseUrl = "https://proxy.schmetterling-argus.de/webstart/v1/gts/";

         switch (this.iframeInformations.application) {
            case 1:
               return `${baseUrl}xena/${this.iframeInformations.agencyCode}_${this.iframeInformations.userId}/${this.iframeInformations.hash}?lang=${this.iframeInformations.lang}`;
            case 2:
               return `${baseUrl}xena/${this.iframeInformations.agencyCode}_${this.iframeInformations.userId}/${this.iframeInformations.hash}?lang=${this.iframeInformations.lang}`;
            default:
               return false;
         }

         // return `${baseUrl}/${this.iframeInformations.agencyCode}/${this.iframeInformations.hash}`;
      },
   },
   methods: {
      hidePreloader() {
         setTimeout(() => {
            this.$store.commit("app/setIsProgressGifActive", false);
         }, 2000);
      },
   },
};
</script>

<style lang="scss">
iframe {
   overflow: hidden;
}
</style>

<style lang="scss" scoped>
.package-iframe-c {
   width: 100%;
   height: 100%;
   // border: 1px solid;

   iframe {
      width: 100%;
      height: 100%;
   }
}
</style>
