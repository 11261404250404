import store from "./store";
import i18n from "@/fmt/i18n.js";

var errorStatus = {
   type: "",
   title: "",
   subTitle: "",
   isPopupActive: false,
   htmlContent: "",
   extra: {},
};

let DELAY_TIME = 3000;
const STORE_PATH = "app/setErrorStatus";

class Popup {
   success(title, subTitle, content, delay) {
      errorStatus.type = "success";
      errorStatus.title = title;
      errorStatus.subTitle = subTitle;
      errorStatus.isPopupActive = true;
      errorStatus.htmlContent = content;
      errorStatus.isConfirmed = function () {
         return new Promise((res) => {
            setTimeout(() => {
               let a = document.querySelector("#popup-ok");
               a.addEventListener("click", function () {
                  res({ success: true });
               });
            }, 1000);
         });
      };

      store.commit(STORE_PATH, errorStatus);

      if (delay != undefined || delay != null) {
         DELAY_TIME = delay;
      }

      setTimeout(function () {
         errorStatus.isPopupActive = false;
         store.commit(STORE_PATH, errorStatus);
      }, DELAY_TIME);

      return errorStatus.isConfirmed();
   }

   warning(title, subTitle, content, delay) {
      errorStatus.type = "warning";
      errorStatus.title = title;
      errorStatus.subTitle = subTitle;
      errorStatus.isPopupActive = true;
      errorStatus.htmlContent = content;

      store.commit(STORE_PATH, errorStatus);

      if (delay != undefined || delay != null) {
         DELAY_TIME = delay;
      }

      setTimeout(function () {
         errorStatus.isPopupActive = false;
         store.commit(STORE_PATH, errorStatus);
      }, DELAY_TIME);

      return errorStatus;
   }

   info(title, subTitle, content) {
      errorStatus.type = "info";
      errorStatus.title = title;
      errorStatus.subTitle = subTitle;
      errorStatus.htmlContent = content;
      errorStatus.isPopupActive = true;

      store.commit(STORE_PATH, errorStatus);

      // setTimeout(function () {
      //   errorStatus.isPopupActive = false;
      //   store.commit(STORE_PATH, errorStatus);
      // }, DELAY_TIME);

      return errorStatus;
   }
   confirm(title, subTitle, content, customCancel) {
      errorStatus.type = "confirm";
      errorStatus.title = title;
      errorStatus.subTitle = subTitle;
      errorStatus.htmlContent = content;
      errorStatus.customCancel = customCancel;
      errorStatus.isPopupActive = true;
      store.commit(STORE_PATH, errorStatus);

      errorStatus.isConfirmed = function () {
         return new Promise((res) => {
            setTimeout(() => {
               let a = document.querySelector("#popup-ok");
               a.addEventListener("click", function () {
                  res({ success: true });
               });
            }, 100);
         });
      };

      return errorStatus.isConfirmed();
   }

   // New
   priceChange(title, subTitle, oldPrice, newPrice, priceDiff, rezBtn, checkBtn, content) {
      errorStatus.type = "priceChange";
      errorStatus.title = title;
      errorStatus.subTitle = subTitle;
      errorStatus.htmlContent = content;
      errorStatus.isPopupActive = true;
      errorStatus.extra = {
         oldPrice,
         newPrice,
         priceDiff,
         rezBtn,
         checkBtn,
      };
      store.commit(STORE_PATH, errorStatus);

      errorStatus.isConfirmed = function () {
         return new Promise((res) => {
            setTimeout(() => {
               let a = document.querySelector("#go2checkout");
               let b = document.querySelector("#reserveit");
               if (a) {
                  a.addEventListener("click", function () {
                     res({ success: true });
                  });
               }

               if (b) {
                  b.addEventListener("click", function () {
                     res({ success: true });
                  });
               }
            }, 100);
         });
      };

      return errorStatus.isConfirmed();
   }
   custom(title, subTitle, content) {
      errorStatus.type = "custom";
      errorStatus.subTitle = subTitle;
      errorStatus.title = title;
      errorStatus.htmlContent = content;
      errorStatus.isPopupActive = true;

      store.commit(STORE_PATH, errorStatus);

      return errorStatus;
   }

   result(result, okBtnCb) {
      errorStatus.type = result.success ? "success" : "warning";
      errorStatus.title = result.success ? i18n.t("popup_successTitle") : i18n.t("popup_warningTitle");
      errorStatus.title = i18n.t(`Popup_${result.success ? "SUCCESS" : "ERROR"}`);
      errorStatus.subTitle = result.subCode.length
         ? i18n.t(`RESPONSE_${result.app}_${result.service}_${result.code}_${result.subCode}`)
         : i18n.t(`RESPONSE_${result.app}_${result.service}_${result.code}`);
      errorStatus.isPopupActive = true;
      errorStatus.htmlContent = "";
      errorStatus.confirmButtonCb = okBtnCb ? okBtnCb : null;
      // let title = `RESPONSE_${response.data.result.app}_${response.data.result.service}_${response.data.result.code}_${response.data.result.subCode}`;

      store.commit(STORE_PATH, errorStatus);

      return new Promise((resolve) => {
         setTimeout(() => {
            errorStatus.isPopupActive = false;
            store.commit(STORE_PATH, errorStatus);
            resolve("timeout");
         }, 5000);
      });
   }
}

Promise.prototype.confirmed = Promise.prototype.then;

export default new Popup();
