<template>
   <div id="version-page-wrapper">
      <Aside />
      <Main>
         <Navbar />

         <div id="version-page-inner-container" class="container gts-box">
            <!-- <button class="gts-btn btn-primary">Satın Al</button>
                <button class="gts-btn btn-primary-outlined">İptal Et</button>

                <button class="gts-btn btn-danger">Cancel</button>
                <button class="gts-btn btn-danger-outlined">İptal Et</button>

                <button class="gts-btn btn-secondary">Hotel</button>
                <button class="gts-btn btn-secondary-outlined">İptal Et</button> -->
            <div class="form-row"></div>

            <span class="page-title">Version Bilgisi</span>
            <hr />
            <div class="version-table">
               <!-- Table Header -->
               <div class="version-row version-table-header">
                  <span class="table-title">Version</span>
                  <span class="table-datetime">Datetime</span>
                  <span class="table-content">Information</span>
               </div>

               <!-- Table Loop -->
               <div class="version-row" v-for="(item, index) in data" :key="index">
                  <span class="table-title">{{ item.version }}</span>
                  <span class="table-datetime">{{ item.date }}<br />{{ item.time }}</span>
                  <span class="table-content" v-html="item.content"></span>
               </div>
            </div>
         </div>
      </Main>
   </div>
</template>

<script>
import moment from "moment";
import Aside from "@/components/unitary/Aside.vue";
import Navbar from "@/components/unitary/Navbar.vue";
import Main from "@/components/unitary/Main.vue";

import version from "../version.json";

export default {
   name: "VersionPage",
   components: {
      Aside,
      Navbar,
      Main,
   },
   data() {
      return {
         data: null,
         inputDate: null,
         datecustom: null,
         months: [
            { name: this.$i18n.t(`common_january`), value: 0 },
            { name: this.$i18n.t(`common_february`), value: 1 },
            { name: "March", value: 2 },
            { name: "April", value: 3 },
            { name: "May", value: 4 },
            { name: "June", value: 5 },
            { name: "July", value: 6 },
            { name: "August", value: 7 },
            { name: "September", value: 8 },
            { name: "October", value: 9 },
            { name: "November", value: 10 },
            { name: "December", value: 11 },
         ],
      };
   },
   created() {
      const self = this;
      self.data = version;
      moment;

      const getGlobalFmtList = {
         tr: {},
         en: {},
         de: {},
      };

      const dateTR = {
         days: ["Paz", "Pts", "Sal", "Çar", "Per", "Cum", "Cts"],
         month: ["Ocak", "Şubat", "Mart", "Nisan", "Mayıs", "Haziran", "Temmuz", "Ağustos", "Eylül", "Ekim", "Kasım", "Aralık"],
      };

      const dateEN = {
         days: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
         month: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
      };
      const dateDE = {
         days: ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"],
         month: ["Januar", "Februar", "März", "April", "Mai", "Juni", "Juli", "August", "September", "Oktober", "November", "Dezember"],
      };

      dateEN.month.forEach((month, index) => {
         getGlobalFmtList.en[`common_${month.toLowerCase()}`] = dateEN.month[index];

         getGlobalFmtList.tr[`common_${month.toLowerCase()}`] = dateTR.month[index];

         getGlobalFmtList.de[`common_${month.toLowerCase()}`] = dateDE.month[index];
      });
   },

   methods: {
      selectMonth(option) {
         if (option) {
            this.focusedDate = new Date(this.focusedDate);
            this.focusedDate.setMonth(option.value);
         }
      },
   },
};
</script>

<style lang="scss" scoped>
#version-page-wrapper {
   #version-page-inner-container {
      .page-title {
         font-size: 1.75rem;
         font-weight: 700;
      }

      .version-table {
         .version-row {
            display: grid;
            grid-template-columns: 1fr 1fr 8fr;
            padding: 1rem 1.5rem;
            border-radius: 0.5rem;

            &.version-table-header {
               background-color: transparent !important;
               span {
                  color: var(--primary);
                  font-weight: 600;
               }
            }

            &:nth-child(even) {
               background-color: #efefef;
            }
            &:nth-child(odd) {
               background-color: #ffffff;
            }

            span.table-title,
            span.table-datetime {
               font-weight: 500;
            }
         }
      }
   }
}
</style>
