<template>
   <div
      class="reissue-search-leg-bodycard-container"
      :class="[isEven ? 'with-bg' : '', hasSelection ? 'selected-view' : '']"
      @click="handlerSelectFlight"
      v-if="ShowCard">
      <AncillaryReissueSearchLegBodyCardMain :flight="flight" :hasSelection="hasSelection" />
      <AncillaryReissueSearchLegBodyCardDetails :details="Details" v-if="hasSelection && allowDetails" />
   </div>
</template>

<script>
import AncillaryReissueSearchLegBodyCardMain from "@/components/structural/flight/order-detail/ancillaries/ancillary-reissue/AncillaryReissueSearchLegBodyCardMain.vue";
import AncillaryReissueSearchLegBodyCardDetails from "@/components/structural/flight/order-detail/ancillaries/ancillary-reissue/AncillaryReissueSearchLegBodyCardDetails.vue";
export default {
   name: "AncillaryReissueSearchLegBodyCard",
   props: {
      flight: Object,
      airports: Object,
      isEven: Boolean,
      hasSelection: Boolean,
      allowDetails: Boolean,
   },
   components: {
      AncillaryReissueSearchLegBodyCardMain,
      AncillaryReissueSearchLegBodyCardDetails,
   },
   computed: {
      Details() {
         return {
            segments: this.flight.segments,
            airports: this.airports,
         };
      },
      isSelected() {
         return this.flight.selected;
      },
      ShowCard() {
         if (this.hasSelection == false) {
            return true;
         } else {
            return this.hasSelection && this.flight.selected;
         }
      },
   },
   methods: {
      handlerSelectFlight() {
         this.$parent.$parent.$emit("selected-flight", {
            identifier: this.flight.identifier,
            legIndex: this.$parent.legIndex,
         });
      },
      handlerAirlineImg(airlineLogo) {
         return `${this.$constants.cdn}/airline_34x34/${airlineLogo}.svg`;
      },
      handlerUnknownImg(ev) {
         ev.target.src = `${this.$constants.cdn}/airline_34x34/00.svg`;
      },
   },
};
</script>

<style lang="scss" scoped>
.reissue-search-leg-bodycard-container {
   display: flex;
   flex-direction: column;
   border-radius: 24rem;
   margin: 0.5rem 0;
   padding: 0.5rem;

   &.selected-view {
      border-radius: 0;
      margin: 0;
      background-color: #f5f5f9;
      padding: 0.75rem 0.5rem;
   }

   &:hover {
      cursor: pointer;
      background-color: #e6f1fc !important;
   }
}

.reissue-search-leg-bodycard-container.with-bg {
   background-color: #f4f6fa;
}
</style>
