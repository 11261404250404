<template>
   <div id="traveler-informations-wrapper" class="gts-box with-border masked">
      <div class="traveler-informations-header-wrapper">
         <span class="section-title">{{ $t("transferTravelerInformationsComponent_travelerInformations") }}</span>
         <div id="autofill-wrapper" v-if="ShowAutoFill">
            <span class="autofill-highlighter"> {{ $t("flight_fillPaxRowsTooltipHighlighter") }}</span>
            <button id="fill-form" @click="handlerShowModal">
               {{ $t("flight_fillPaxRowsTooltip") }}
            </button>
            <button id="clear-form" @click="handlerClearForm">
               <i class="gts-icon icon-hotel-empty-basket icon-sm"></i>
            </button>
         </div>
      </div>
      <template v-for="(traveler, index) in travelers">
         <TravelerInfo :pickupDateTime="pickupDateTime" :traveler="traveler" :key="index" />
      </template>
      <UIModal v-show="showModal" :noBorder="true" :hasSlot="true" @close-modal="showModal = false">
         <div class="traveler-data-form">
            <div class="transfer-manuel-request-info-wrapper col-12">
               <div class="traveler-data-form__title">
                  <i class="gts-icon icon-popup-cursor"></i>
                  <h3 class="txt-black">{{ $t("transfer_fillTravelers") }}</h3>
               </div>
               <div class="traveler-data-form__content">
                  <p>{{ $t("transfer_fillTravelersContent") }}</p>
                  <div class="traveler-data-form__info-wrapper">
                     <div class="traveler-data-form__infos --side-bg"></div>
                     <div
                        class="traveler-data-form__traveler-info"
                        v-for="(travelerInfo, index) in $store.state.transfer.cookieData.travelers"
                        :key="travelerInfo + index">
                        <div class="sub-content">
                           <span class="label-title">{{ $t("transfer_travelerNameSurname") }}</span>
                           <span> {{ travelerInfo.name }} - {{ travelerInfo.surname }} </span>
                        </div>
                        <div class="sub-content">
                           <span class="label-title">{{ $t("transfer_travelerBirthDate") }}</span>
                           <span>
                              {{ travelerInfo.birthdate | FormatterDate }}
                           </span>
                        </div>
                        <div class="sub-content">
                           <span class="label-title">{{ $t("transfer_travelerType") }}</span>
                           <span> {{ travelerInfo.type | ETrfTravelerType }} - {{ travelerInfo.gender | EComGender }} </span>
                        </div>
                     </div>
                  </div>
               </div>
               <div class="traveler-data-form__cta">
                  <UIButton
                     rounded
                     className="accent-primary_outline"
                     :text="$t('transfer_fillTravelerRowsCancelBtn')"
                     @click.native="showModal = false" />
                  <UIButton rounded className="accent-primary" :text="$t('transfer_fillTravelerRows')" @click.native="fillTravelerRows" />
               </div>
            </div>
         </div>
      </UIModal>
   </div>
</template>

<script>
import TravelerInfo from "@/components/structural/transfer/checkout/TravelerInfo.vue";
import UIModal from "@/components/unitary/form2/UIModal.vue";
import UIButton from "@/components/unitary/Button.vue";

import { setCookie } from "@/utils.js";
import moment from "moment";
moment;
export default {
   name: "TravelerInformations",
   components: {
      TravelerInfo,
      UIModal,
      UIButton,
   },
   props: {
      travelers: Array,
      pickupDateTime: Date,
   },
   data: () => ({
      showModal: false,
   }),
   computed: {
      ShowAutoFill() {
         return this.$store.state.transfer.cookieData.travelers;
      },
   },
   methods: {
      fillTravelerRows() {
         const storedCookie = this.$store.state.transfer.cookieData.travelers;
         this.travelers = storedCookie;
         this.$emit("set-travelers", storedCookie);
         this.showModal = false;
         this.handlerClearForm();
      },
      handlerShowModal() {
         this.showModal = true;
      },
      handlerClearForm() {
         setCookie("travelerData", "");
         setCookie("travelerCount", "");
         this.$store.state.transfer.cookieData.travelers = null;
      },
   },
   watch: {
      ShowAutoFill: {
         handler: function (status) {
            if (status) {
               setTimeout(() => {
                  document.querySelector(".autofill-highlighter").classList.add("d-none");
               }, 8000);
            }
         },
         immediate: true,
      },
   },
};
</script>

<style lang="scss" scoped>
#traveler-informations-wrapper {
   position: relative;

   .traveler-informations-header-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 1.5rem;

      .section-title {
         margin-bottom: 0 !important;
      }
      #autofill-wrapper {
         display: flex;
         justify-content: flex-end;
         // background-color: red;
         position: relative;

         @keyframes tooltip-top {
            0% {
               transform: translateY(-10px);
            }
            100% {
               transform: translateY(0);
            }
         }

         .autofill-highlighter {
            animation-timing-function: ease-in-out;
            animation-duration: 1s;
            animation-iteration-count: infinite;
            animation-name: tooltip-top;
            position: absolute;
            background-color: var(--accent-primary);
            bottom: 110%;
            left: 0;
            right: 0;
            margin: auto;
            width: fit-content;
            padding: 0.5rem 1.125rem;
            border-radius: 0.25rem;
            color: #fff;
            font-weight: 500;
            z-index: 999;

            &::after {
               content: "";
               position: absolute;
               bottom: -5px;
               left: 0;
               right: 0;
               margin: auto;
               width: 10px;
               height: 10px;
               transform: rotate(45deg);
               background-color: red;
               background-color: var(--accent-primary);
            }
         }

         button {
            border-radius: 0.5rem;

            &#fill-form {
               background: #0ce2a3;
               padding: 0.5rem 1rem;
               color: #ffffff;
               font-weight: 600;
               margin-right: 0.5rem;

               &:hover {
                  background: #2a3a4d;
               }
            }
            &#clear-form {
               background-color: transparent !important;
               border: 2px solid #cecece;
               padding-left: 0.5rem;
               padding-right: 0.5rem;

               &:hover {
                  background-color: #2a3a4d !important;
                  border: 2px solid #2a3a4d;

                  i {
                     filter: brightness(10);
                     opacity: 1;
                  }
               }

               i {
                  filter: brightness(0);
                  opacity: 0.6;
                  width: 18px !important;
               }
            }
         }
      }
   }

   .traveler-wrapper {
      background: #f8f8f8;
      border: 1px solid #ccc;
      position: relative;
      min-height: 50px;
      border-radius: 24px;
      padding: 1rem 2rem;
      margin: 2rem 0rem;
      padding-top: 1.75rem;

      .traveler-travelertype {
         position: absolute;
         top: -1rem;
         left: 1rem;
         background: #fff;
         border: 1px solid #ccc;
         border-radius: 16px;
         padding: 0.25rem 1rem;
         font-size: var(--small);
         font-weight: 600;
      }
   }
   .traveler-data-form {
      padding: 1rem;
      width: 100%;
      &__title {
         text-align: center;
         color: #1e2023;
         font-size: var(--xlarge);
         letter-spacing: 0px;
         i {
            margin: auto;
            width: 114px !important;
            height: 79.06px !important;
         }
      }
      &__content {
         text-align: center;
         font-size: var(--small);
         color: #6d6e6f;
         letter-spacing: 0.22px;
         margin-top: 0.5rem;
      }
      &__info-wrapper {
         background: #f7f7f7 0% 0% no-repeat padding-box;
         border: 1px solid #ebebeb;
         border-radius: 16px;
         padding: 20px;
         position: relative;
         margin-top: 0.5rem;
         &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 16px; /* adjust as needed */
            height: 100%;
            background: #ebebeb 0% 0% no-repeat padding-box;
            border-radius: 8px 0px 0px 8px;
         }
         &.--side-bg {
            width: 16px;
            height: 100%;
            padding: 0;
         }
      }
      &__cta {
         margin-top: 1rem;
         display: flex;
         justify-content: space-evenly;
         .gts-btn {
            height: 40px;
         }
      }
      &__traveler-info {
         display: flex;
         flex-direction: column;
         padding-left: 24px;
         margin-top: 20px;
         align-items: flex-start;
         border-bottom: 1px dashed #6d6e6f;
         padding-bottom: 18px;
         .label-title {
            font-weight: bold;
            font-size: var(--normal);
            color: #363636;
         }
         .sub-content {
            margin-top: 8px;
         }
         .text {
            color: #363636;
            font-size: var(--small);
            letter-spacing: 0;
         }
         &:last-child {
            border-bottom: none;
            padding-bottom: 0;
         }
      }
   }
}
</style>
