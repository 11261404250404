<template>
   <div id="notification-page-container">
      <Aside />
      <Main>
         <Navbar />

         <div class="container is-max-desktop">
            <div class="columns">
               <div class="column is-4" v-if="false">
                  <div class="np-settings-wrapper container gts-box">
                     <h2>{{ $t("NotificationComponent_notificationSettings") }}</h2>

                     <div class="np-allow-notification-container" v-if="true">
                        <span>{{ $t("NotificationComponent_notificationStatus") }}</span>
                        <span>{{
                           notificationAllowed ? $t("NotificationComponent_notificationAllowed") : $t("NotificationComponent_notificationNotAllowed")
                        }}</span>
                     </div>
                     <UIButton
                        className="default"
                        :text="$t('NotificationComponent_btnAllowNotification')"
                        @click.native="allowNotification"
                        v-if="!notificationAllowed"></UIButton>
                  </div>
               </div>
               <div class="column is-12">
                  <div id="np-wrapper" class="container gts-box">
                     <NotificationContent :is-page="true" />
                  </div>
               </div>
            </div>
         </div>
      </Main>
   </div>
</template>

<script>
import Aside from "@/components/unitary/Aside.vue";
import Navbar from "@/components/unitary/Navbar.vue";
import Main from "@/components/unitary/Main.vue";
import NotificationContent from "@/components/unitary/NotificationContent.vue";
import UIButton from "@/components/unitary/Button.vue";
export default {
   name: "Notification",
   components: {
      Aside,
      Navbar,
      Main,
      NotificationContent,
      UIButton,
   },
   data() {
      return {
         notificationAllowed: Notification.permission === "granted",
      };
   },
   created() {
      // const self = this;
      // Notification.requestPermission().then((result) => {
      //     if (result == "granted") {
      //         self.notificationAllowed = true;
      //     }
      // });
   },
   methods: {
      allowNotification() {
         Notification.requestPermission().then((result) => {
            if (result == "granted") {
               self.notificationAllowed = true;
            } else {
               self.notificationAllowed = false;
            }
         });
      },
   },
};
</script>

<style lang="scss" scoped>
#notification-page-container {
   #np-wrapper {
   }

   .np-settings-wrapper {
      h2 {
         font-weight: 500;
         font-size: var(--large);
         padding-bottom: 1.25rem;
         margin-bottom: 0.75rem;
      }

      .np-allow-notification-container {
         display: flex;
         justify-content: space-between;
      }
   }
}
</style>
