<template>
   <div class="order-detail-view-wrapper order-detail-transfer-view">
      <div class="order-detail-view-inner">
         <TransferAndLegInformation
            :orderId="orderId"
            :pageLoad="pageLoad"
            :tnrId="TnrId"
            :tnr="tnrDetail.tnr"
            :travelers="tnrDetail.travelers"
            :legs="tnrDetail.legs"
            :legPrices="tnrDetail.legPrices" />
         <TravelerInformations :travelers="tnrDetail.travelers" />
         <div v-if="tnrDetail.tnr.canReissue && EditMode">
            <CheckoutContact :editMode="EditMode" />
         </div>
         <div v-if="tnrDetail.tnr.agencyNote">
            <OrderDetailNotes :agencyNote="tnrDetail.tnr.agencyNote" />
         </div>
         <div v-if="tnrDetail.tnr.canReissue && EditMode" class="reissue-btn-wrapper">
            <Button @click.native="postReissueRequest" :text="$t('common_save')" className="default" />
         </div>
         <PaymentPurchaseWidget entityType="transfer" :entityId="TnrId" :paymentChoiceDetail="PaymentChoiceDetail" />
      </div>
   </div>
</template>

<script>
import TransferAndLegInformation from "@/components/structural/transfer/order-detail/TransferAndLegInformation.vue";
import TravelerInformations from "@/components/structural/transfer/order-detail/TravelerInformations.vue";
import PaymentPurchaseWidget from "@/components/structural/payment/PaymentPurchaseWidget.vue";
import CheckoutContact from "@/components/structural/transfer/checkout/CheckoutContact.vue";
import { transferService } from "@/service/index.js";
import Button from "@/components/unitary/Button.vue";
import Popup from "@/gts-popup.js";
import OrderDetailNotes from "@/components/structural/transfer/order-detail/OrderDetailNotes.vue";

export default {
   name: "OrderDetailTransferView",
   components: {
      TransferAndLegInformation,
      TravelerInformations,
      PaymentPurchaseWidget,
      CheckoutContact,
      OrderDetailNotes,
      Button,
   },
   props: {
      tnrDetail: Object,
      orderId: Number,
      pageLoad: Boolean,
   },
   computed: {
      EditMode() {
         return this.$store.state.transfer.editMode;
      },
      TnrId() {
         return this.tnrDetail ? this.tnrDetail.tnr.tnrId : 0;
      },
      PaymentChoiceDetail() {
         return this.tnrDetail ? this.tnrDetail.paymentChoiceDetail : null;
      },
      TotalPrice() {
         if (!this.tnrDetail) return "";
         if (this.tnrDetail.legPrices.length > 0) {
            var total = 0;
            var currency = "EUR";
            for (var legPrice of this.tnrDetail.legPrices) {
               currency = legPrice.currency;
               total += legPrice.totalAmount;
            }
            return total + " " + this.$options.filters.currency(currency);
         } else return "";
      },
   },
   created() {
      this.$store.commit("transfer/setCanReissue", this.tnrDetail.tnr.canReissue);
   },
   methods: {
      postReissueRequest() {
         this.$validate().then((res) => {
            if (res.success) {
               this.$store.commit("transfer/updateReissueAgencyNote", this.tnrDetail.tnr.agencyNote);
               let request = this.$store.state.transfer.reissue ? this.$store.state.transfer.reissue : {};
               transferService.transferReissue(request).then((response) => {
                  if (!response.result.success) {
                     Popup.result(response.result);
                     return;
                  }
                  if (response.result.success) {
                     this.$store.commit("transfer/updateEditMode", false);
                     Popup.success(this.$i18n.t("common_success"), this.$i18n.t("transferReissue_popupReiusseSuccessSubtitle"));
                     return;
                  }
               });
            } else {
               const autoScroll = document.querySelector("#validation-error-auto-scroller");
               if (autoScroll) {
                  document.querySelector("#validation-error-auto-scroller").scrollIntoView();
               }
            }
         });
      },
   },
   watch: {
      tnrDetail: {
         handler: function (val) {
            this.$store.commit("transfer/setCanReissue", val.tnr.canReissue);
         },
      },
   },
};
</script>

<style lang="scss" scoped>
.order-detail-view-wrapper {
   .order-detail-view-inner {
      .reissue-btn-wrapper {
         display: flex;
         justify-content: center;
      }
      .transfer-order-detail-section {
         margin-bottom: 42px;

         &:last-child {
            margin-bottom: 0;
         }
      }
   }
}
</style>
