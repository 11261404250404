<template>
   <div class="transfer-page-wrapper">
      <Aside />
      <Main>
         <Navbar />
         <div id="transfer-search-wrapper">
            <div class="row gx-3 gx-md-2 gx-lg-3 gx-xxl-3">
               <div class="col-12">
                  <Search :activeTab="5" isPackage :iframeInformations="iframeInformations" />

                  <!-- <Search :activeTab="5" @search-response="handlerSearchResponse" isPackage /> -->

                  <div class="cooperation-wrapper">
                     <span>Cooperation with </span>
                     <img :src="logo" alt="Cooperation with Schimetterling" class="schimetterling-logo" />
                  </div>
               </div>
            </div>
         </div>
      </Main>
   </div>
</template>

<script>
import { userService, TokenService } from "@/service/index.js";
import { generateUuid } from "@/utils.js";

import Aside from "@/components/unitary/Aside.vue";
import Navbar from "@/components/unitary/Navbar.vue";
import Main from "@/components/unitary/Main.vue";

import Search from "@/components/unitary/SearchTab.vue";
import SchimetterlingLogo from "@/assets/images/schmetterling.png";

export default {
   name: "TransferSearch",
   components: {
      Aside,
      Navbar,
      Main,
      Search,
   },
   data() {
      return {
         logo: SchimetterlingLogo,
         iframeInformations: {
            agencyCode: null,
            application: null,
            userId: null,
            hash: null,
            status: false,
            lang: null,
         },

         agtUserGetSsoTokenReqModel: {
            jwt: TokenService.getJwtToken(),
            uuid: generateUuid(),
            timeout: "00:30:00",
         },
         agtUserGetSsoUserInfoReqModel: {
            ssoToken: null,
            uuid: generateUuid(),
            timeout: "00:30:00",
         },
      };
   },
   created() {
      this.$store.commit("app/setIsProgressGifActive", true);
      userService
         .getUserToken(this.agtUserGetSsoTokenReqModel)
         .then((response) => {
            this.iframeInformations.application = 2;
            this.iframeInformations.agencyCode = response.agencyCode;
            this.iframeInformations.userId = response.userId;
            this.iframeInformations.hash = response.ssoToken;
            this.iframeInformations.lang = this.$i18n.locale;
         })
         .finally(() => {
            this.iframeInformations.status = true;
         });
   },
};
</script>

<style lang="scss" scoped>
.transfer-page-wrapper {
   .transfer-page-inner {
      margin: auto;
      margin-top: 3rem;
   }
   span {
      //.
      // margin: 1rem 0;
      // margin-top: 0 !important;
      // margin-bottom: 1.75rem;
      // display: flex;
      // justify-content: flex-end;
      // font-size: var(--small);
      // font-weight: 600;
      // opacity: 0.65;
   }

   .cooperation-wrapper {
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
      margin-bottom: 0.5rem;

      span {
         font-weight: 500;
         color: #053971;
         padding-right: 0.35rem;
         font-size: var(--large);
      }
      .schimetterling-logo {
         width: 150px;
         padding-bottom: 4px;
      }
   }
}
</style>
