<template>
   <div class="transfer-vehicle-card-container">
      <!-- Vehicle Type -->
      <div class="vehicle-type-wrapper" :class="'vehicle-type-1'">
         <span class="vehicle-type">...</span>
      </div>

      <!-- Route Informations -->
      <div class="route-informations-wrapper">
         <div class="route-information-body-wrapper">
            <!-- A Location Wrapper -->
            <div class="location-wrapper">
               <!-- Location Icon -->
               <div class="location-icon-wrapper">
                  <i class="gts-icon icon-transfer-basket-location"></i>
                  <span class="location-mark-name"> A </span>
               </div>

               <div class="location-informations">
                  <span class="location-name masked"></span>
                  <div class="location-fullname-wrapper">
                     <span class="location-fullname masked"> </span>
                  </div>
               </div>
            </div>

            <!-- Transfer Card Image -->
            <div class="transfer-card-image-wrapper masked"></div>

            <!-- B Location Wrapper -->
            <div class="location-wrapper txt-right">
               <div class="location-informations">
                  <span class="location-name masked"></span>
                  <div class="location-fullname-wrapper">
                     <span class="location-fullname masked"> </span>
                  </div>
               </div>

               <!-- Location Icon -->
               <div class="location-icon-wrapper">
                  <i class="gts-icon icon-transfer-basket-location"></i>
                  <span class="location-mark-name"> B </span>
               </div>
            </div>
         </div>
         <div class="route-informations-footer-wrapper">
            <div class="transfer-facilities-wrapper">
               <div class="icon-wrapper">
                  <i class="gts-icon icon-transfer-seat"></i>
                  <span class="masked"></span>
               </div>
               <div class="icon-wrapper">
                  <i class="gts-icon icon-transfer-baggage"></i>
                  <span class="masked"></span>
               </div>
            </div>
            <div class="time-picker masked"></div>
         </div>
      </div>
   </div>
</template>

<script>
export default {
   name: "TransferCheckoutCardSkeleton",
   components: {},
};
</script>

<style lang="scss" scoped>
.transfer-vehicle-card-container {
   display: flex;

   .vehicle-type-wrapper {
      display: flex;
      align-items: center;
      border: 1px solid #ccc;
      border-radius: 1rem;
      min-width: 45px;
      margin-right: 1rem;
      position: relative;

      .vehicle-type {
         text-align: center;
         -ms-writing-mode: tb;
         writing-mode: tb;
         transform: rotate(180deg);
         margin-left: 0.5rem;
         margin-right: 0.5rem;
      }

      &::after {
         content: "";
         position: absolute;
         right: 0;
         width: 6px;

         top: 3rem;
         bottom: 3rem;
         border-top-left-radius: 0.5rem;
         border-bottom-left-radius: 0.5rem;
      }

      &.vehicle-type-1 {
         &::after {
            background: #4dffc4;
         }
      }
      &.vehicle-type-2 {
         &::after {
            background: #363636;
         }
      }
   }

   .route-informations-wrapper {
      width: 100%;
      border: 1px solid #d3d3d3;
      background-color: #fafafa;
      border-radius: 1rem;
      padding-bottom: 0;
      position: relative;

      .route-information-body-wrapper {
         display: flex;
         width: 100%;
         justify-content: space-between;
         padding: 0 1.5rem;
         padding-top: 2rem;
         .location-wrapper {
            display: flex;
            margin-bottom: 1.25rem;
            .location-icon-wrapper {
               position: relative;
               margin-right: 0.5rem;
               line-height: 2.5rem;

               .location-mark-name {
                  position: absolute;
                  top: 0;
                  left: 0;
                  right: 0;
                  bottom: 0;
                  margin: auto;
                  color: #fff;
                  font-weight: 600;
                  font-size: 1.5rem;
                  width: fit-content;
               }
            }
            .location-informations {
               display: flex;
               flex-direction: column;
               width: 156px;
            }

            &.txt-right {
               text-align: right;
               .location-icon-wrapper {
                  margin-left: 0.5rem;
               }
            }
         }

         .transfer-card-image-wrapper {
            width: 300px;
            height: 75px;
            z-index: 1;
            position: absolute;
            left: 0;
            right: 0;
            top: 1rem;
            margin: auto;
         }
      }

      .route-informations-footer-wrapper {
         display: flex;
         justify-content: space-between;
         background-color: #f2f2f2;
         padding: 0.25rem 1rem;
         border-bottom-right-radius: 1rem;
         border-bottom-left-radius: 1rem;
         // position: relative;
         // top: -30px;

         .transfer-facilities-wrapper {
            display: flex;

            .icon-wrapper {
               display: flex;
               align-items: center;
               margin-right: 1rem;
            }
         }
      }
   }
}
</style>
