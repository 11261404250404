<template>
   <div class="widget-announcement-container">
      <template v-if="properties.content.length == 0">
         <span class="no-content">{{ $t("dashboardAnnouncements_noContent") }}</span>
      </template>
      <template v-else>
         <b-tabs v-model="activetab">
            <b-tab-item value="list">
               <Section class="announcement-list-wrapper">
                  <template v-for="(item, index) in Announcements">
                     <div class="announcement" :key="index" @click="handlerShowDetail(item)">
                        <div class="announcement-thumbnail">
                           <img class="announcement-thumbnail" :src="`${$constants.cdn}/announcements/${item.id}.jpg`" />
                        </div>
                        <div class="announcement-brief-information">
                           <span class="announcement-title">{{ item.name }}</span>
                           <span class="announcement-text" v-html="item.briefHtml"></span>
                           <span class="announcement-read-more-text">{{ $t("dashboardPage_lblReadMore") }}</span>
                        </div>
                     </div>
                  </template>

                  <Pagination
                     announcement
                     :pages="pagination.pages"
                     v-if="!properties.pending"
                     :active-page="pagination.activepage"
                     @update-page="handlerUpdatePage"
                     show-always />
               </Section>
            </b-tab-item>
            <b-tab-item value="detail">
               <Section class="announcement-detail-wrapper">
                  <div class="announcement-show-list" @click="activetab = 'list'">
                     <i class="gts-icon icon-arrow-full icon-sm"></i>
                     <span> {{ $t("dashboardPage_lblAnnouncementList") }}</span>
                  </div>
                  <img class="announcement-detail-thumbnail" :src="`${$constants.cdn}/announcements/${details.id}.jpg`" />
                  <h2 class="detail-title">
                     {{ details.name }}
                  </h2>
                  <div class="announcement-detail" v-html="details.detailHtml"></div>
               </Section>
            </b-tab-item>
         </b-tabs>
      </template>
   </div>
</template>

<script>
import Pagination from "@/components/widgets/base/Pagination.vue";
export default {
   name: "Announcement",
   props: {
      properties: [Object, Array],
   },
   components: {
      Pagination,
   },
   data() {
      return {
         activetab: "list",
         details: "",
         pagination: {
            enabled: true,
            perPage: 3,
            pages: [],
            options: [],
            activepage: 1,
         },
      };
   },
   computed: {
      Announcements() {
         this.Pagination;
         let sliceFrom = this.pagination.activepage - 1;
         let sliceTo = this.pagination.activepage;
         let perPage = this.pagination.perPage;
         return this.properties.content.slice(perPage * sliceFrom, perPage * sliceTo);
      },
      Pagination() {
         const self = this;
         const count = self.properties.content?.length;
         const totalPage = Math.ceil(count / self.pagination.perPage);
         if (totalPage == 0) {
            self.pagination.enabled = false;
         } else {
            self.pagination.pages.length = 0;
            for (let i = 1; i <= totalPage; i++) {
               self.pagination.pages.push(i);
               const rowObj = {
                  name: i,
                  value: i,
               };
               self.pagination.options.push(rowObj);
            }
         }

         return self.pagination;
      },
   },
   methods: {
      handlerShowDetail(item) {
         this.activetab = "detail";
         this.details = item;
      },

      handlerUpdatePage(index) {
         this.pagination.activepage = index;
      },
   },
};
</script>

<style lang="scss" scoped>
.no-content {
   font-size: var(--large);
}
.widget-announcement-container {
   section.announcement-list-wrapper {
      .announcement {
         padding: 0.75rem;
         border-bottom: 1px solid #ccc;
         border-radius: 0;
         display: flex;
         margin-bottom: 0.5rem;
         cursor: pointer;

         .announcement-thumbnail {
            flex-shrink: 0;
            $size: 120px;
            width: $size;
            height: $size;
            display: none;
            img {
               border-radius: 0.5rem;
            }

            @media (min-width: 1600px) {
               display: flex;
            }
         }
         .announcement-brief-information {
            margin-left: 0.75rem;
            padding-top: 0.5rem;
            width: 100%;
            .announcement-title {
               font-size: var(--large);
               font-weight: 600;
            }
            .announcement-text {
               font-size: var(--small);
               width: fit-content;
               display: flex;
            }
            .announcement-read-more-text {
               font-size: var(--small);
               display: flex;
               justify-content: flex-end;
               margin-top: 20px;
               text-decoration: underline;
               font-weight: 600;
               position: relative;
            }
         }

         &:hover {
            background-color: #eee;
            //     color: #fff;
            border-radius: 1rem;
         }
      }
   }

   section.announcement-detail-wrapper {
      padding: 0 0.5rem;
      img.announcement-detail-thumbnail {
         border-radius: 0.5rem;
      }
      h2.detail-title {
         font-size: var(--large);
         font-weight: 600;
         margin-top: 0.5rem;
      }
      .announcement-show-list {
         display: flex;
         margin-bottom: 1rem;

         i {
            transform: rotate(-180deg);
         }

         span {
            margin-left: 0.5rem;
            font-weight: 500;
         }
         &:hover {
            cursor: pointer;
         }
      }
   }
}
</style>

<style lang="scss">
.widget-announcement-container {
   nav.tabs {
      display: none;
   }
}
section.announcement-detail-wrapper {
   .announcement-detail {
      a {
         color: var(--primary);
         font-weight: 500;
      }
      p {
         text-indent: 0rem !important;
      }
   }
}
</style>
