<template>
    <div id="notifications-container">
        <TransitionGroup name="notify" mode="out-in">
            <template v-for="(notification, index) in notificationList">
                <div class="notification" :class="'notification-' + $options.filters.notification(notification.type)" :key="index">
                    <!-- NOTIFICATION TYPE WRAPPER -->
                    <div class="notification-type-wrapper">
                        <i class="gts-icon icon-lg" :class="'icon-status-' + $options.filters.notification(notification.type)"></i>
                    </div>

                    <!-- NOTIFICATION MESSAGE WRAPPER  -->
                    <div class="notification-message-wrapper">
                        <span class="notification-date">{{ notification.date | TimeFormat }}</span>
                        <p>{{ notification.message }}</p>
                    </div>

                    <!-- NOTIFICATION ACTION WRAPPER -->
                    <div class="notification-action-wrapper">
                        <button class="notification-close" @click="handlerRemoveNotification(index)">
                            <i class="gts-icon icon-error"></i>
                        </button>
                        <button class="notification-link" v-if="notification.type != 'SUCCESS' && notification.type != 'WARNING'">
                            <i class="gts-icon icon-eye"></i>
                        </button>
                    </div>
                </div>
            </template>
        </TransitionGroup>
    </div>
</template>

<script>
export default {
    name: "UINotification",
    data() {
        return {
            notificationList: [],
        };
    },

    computed: {
        Notifications() {
            const self = this;
            return self.$store.state.app.notification.list;
        },
    },
    methods: {
        handlerRemoveNotification(identifier) {
            this.notificationList.splice(identifier, 1);
        },
    },
    watch: {
        "$store.state.app.notification.item": {
            handler: function (notification) {
                const self = this;

                self.notificationList.push(notification);

                setTimeout(() => {
                    self.handlerRemoveNotification(0);
                }, 4000);
            },
        },
    },
};
</script>

<style lang="scss" scoped>
.notify-leave-active,
.notify-enter-active {
    position: absolute;
    transition: 1s;
    transition-duration: 0.5s;
    transition-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
}
.notify-enter {
    transform: translateX(100%);
}
.notify-leave-to {
    opacity: 0;
    transform: translateX(100%);
}

#notifications-container {
    position: fixed;
    z-index: 9999;
    width: 500px;
    top: 6rem;
    right: 2rem;

    .notification {
        display: flex;
        background: #fff;
        border-radius: 16px;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
        padding: 0;
        padding-left: 1rem;
        padding-right: 1rem;
        margin-bottom: 0.75rem;
        height: 112px;

        .notification-type-wrapper,
        .notification-message-wrapper,
        .notification-action-wrapper {
            padding-top: 0.75rem;
            padding-bottom: 0.75rem;
        }

        .notification-type-wrapper {
            display: flex;
            align-items: center;
            padding-left: 0.5rem;
            padding-right: 0.5rem;
        }

        .notification-message-wrapper {
            margin: 0 0.5rem;
            padding: 1.5rem 1.25rem;
            border-left: 1px solid;
            border-right: 1px solid;
            border-color: #eee;

            width: 315px;
            span {
                font-size: var(--small);
                color: var(--txt-ghost);
                font-weight: 600;
            }
            p {
                color: #666666;
            }
        }

        .notification-action-wrapper {
            display: flex;
            flex-direction: column;
            padding: 0 !important;
            width: 70px;

            button {
                background-color: transparent !important;
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                border-bottom: 1px solid #eee;

                i {
                    filter: brightness(0);
                    opacity: 0.7;
                }
                &.notification-close {
                    i {
                        width: 15px !important;
                    }
                }
                &.notification-link {
                    i {
                        width: 50px !important;
                    }
                }

                &:last-child {
                    border-bottom: none !important;
                }
            }
        }
    }
}
</style>
