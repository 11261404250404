<template>
   <div class="gsa-selection" v-click-outside="handlerClickOutside">
      <span>{{ $t("onBehalfOfComponent_lblGsa") }}</span>
      <!-- <span>#{{ SelectedGsa }}</span> -->
      <br />
      <div class="gsa-select-box" @click="dropdown = !dropdown" :class="AllowSelection ? '' : 'not-allowed'">
         <div class="gsa-label-container">
            <span>{{ SelectedGsa }}</span>
         </div>
         <i class="gts-icon icon-arrow-down icon-xs" v-if="AllowSelection"></i>
      </div>
      <div class="gsa-dropdown-container" v-if="dropdown && AllowSelection && !isDisabled">
         <div class="gsa-input-container">
            <input type="text" v-if="AllowSearch" v-model="search" :placeholder="$t('onBehalfOfComponent_phGsa')" />
         </div>

         <ul class="gsa-dropdown-list-wrapper">
            <template v-if="this.$constants.brand.id == 1">
               <li @click="handlerReturnToGts" v-if="Agencies.length == 1">GTS</li>
            </template>

            <li
               v-for="(agency, index) in Agencies"
               :key="index"
               @click="handlerSelect(index)"
               :class="selection == agency.name ? 'selected-agency' : ''">
               {{ agency.name }}
            </li>
         </ul>
      </div>
   </div>
</template>

<script>
// import { agencyService } from "@/service/index.js";
import { globalService, TokenService } from "@/service/index.js";
import { generateUuid } from "@/utils.js";
export default {
   name: "GsaSelection",
   props: {
      list: Array,
      selectedGsa: Number,
      isDisabled: Boolean,
   },
   data() {
      return {
         dropdown: false,
         selection: null,
         search: "",
      };
   },
   created() {
      const self = this;
      self.selection = self.$store.state.user.user.gsaName;
   },

   computed: {
      SelectedGsa() {
         const selectedGsa = this.list.find((gsa) => {
            return gsa.id == this.selectedGsa;
         });
         return selectedGsa?.name;
      },
      AllowSearch() {
         return false;
      },
      Agencies() {
         const self = this;
         return self.list;
      },
      Selected() {
         const self = this;
         if (self.selection != null) {
            return self.selection;
         } else {
            return "Pls Select";
         }
      },
      AllowSelection() {
         const self = this;
         if (
            self.$store.state.user.user.onBehalfOfs.agencyOnBehalfOfId == -1 ||
            self.$store.state.user.user.onBehalfOfs.gsaOnBehalfOfId == -1 ||
            self.$store.state.user.user.gsaId == -1
         ) {
            return true;
         } else {
            return false;
         }
      },
   },
   methods: {
      handlerSelect(index) {
         const self = this;
         self.$store.commit("app/setIsProgressGifActive", true);

         let query = {
            onBehalfOfAgencyId: self.$store.state.user.user.agencyId,
            onBehalfOfGsaId: self.Agencies[index].id,
            uuid: generateUuid(),
            language: self.$store.state.user.user.language,
         };

         globalService.setWorkingParams(query).then((res) => {
            if (res.result.success && res.result.code == "SUCCESS") {
               //Update JWT Token
               TokenService.setJwtInfo(res.jwt, res.jwtDuration, res.jwtRefreshToken);

               self.selection = self.Agencies[index].name;
               self.dropdown = false;
               window.location.reload();
            }
         });
      },
      handlerReturnToGts() {
         const self = this;
         self.$store.commit("app/setIsProgressGifActive", true);
         let query = {
            onBehalfOfAgencyId: -1,
            onBehalfOfGsaId: 1,
            uuid: generateUuid(),
            language: self.$store.state.user.user.language,
         };
         globalService.setWorkingParams(query).then((res) => {
            if (res.result.success && res.result.code == "SUCCESS") {
               //Update JWT Token
               TokenService.setJwtInfo(res.jwt, res.jwtDuration, res.jwtRefreshToken);

               self.dropdown = false;
               self.selection = "";
               window.location.reload();
            }
         });
      },
      handlerClickOutside() {
         const self = this;
         self.dropdown = false;
      },
   },
};
</script>

<style lang="scss" scoped>
.gsa-selection {
   position: relative;
   margin-bottom: 0.5rem;
   .gsa-select-box {
      border: 1px solid #ccc;
      background: #f8f8f8;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding: 0 0.75rem;
      box-sizing: border-box;
      border-radius: 0.5rem;
      position: relative;
      min-height: 50px;
      cursor: pointer;

      .gsa-label-container {
         span {
            font-size: var(--small);
            color: var(--txt-black);
            font-weight: 500;
         }
      }

      &.not-allowed {
         background: #f4f4f4;
         .gsa-label-container {
            span {
               color: var(--txt-ghost);
            }
         }
      }
   }
   .gsa-dropdown-container {
      border: 1px solid #ccc;
      background: #fff;
      left: 0;
      right: 0;
      padding: 0.5rem 0rem;
      position: absolute;
      z-index: 5;
      border-radius: 0px 0px 12px 12px;
      top: calc(100% - 5px);

      .gsa-input-container {
         padding: 0 1rem;
         input {
            width: 100%;
            border: 1px solid #ccc;
            background: #fff;
            border-radius: 0.25rem;
            min-height: 36px;
            text-indent: 0.5rem;
         }
      }
      ul.gsa-dropdown-list-wrapper {
         li {
            border-bottom: 1px solid #eee;
            padding: 0.25rem 1rem;
            font-size: var(--small);
            white-space: nowrap;
            width: 100%;
            cursor: pointer;
            &:hover {
               background-color: var(--hover);
            }

            &.selected-agency {
               font-weight: 600;
               background-color: var(--hover);
            }
         }
      }
   }
}
</style>
