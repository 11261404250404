import axios from "axios"; // axios kütüphanesini içe aktarır
import https from "https"; // https kütüphanesini içe aktarır
import TokenService from "./token.api"; // TokenService modülünü içe aktarır
import router from "@/router/index.js"; // Vue router modülünü içe aktarır
import { logger } from "@/helper/logger.js"; // logger yardımcı modülünü içe aktarır
import store from "@/store/index.js"; // Vuex store modülünü içe aktarır
import { generateUuid } from "@/utils"; // UUID oluşturma fonksiyonunu içe aktarır
// import * as userService from "./user.api"; // userService modülünü içe aktarır
import { refreshToken, REFRESH_TOKEN_ENDPOINT } from "./refresh.token";

const PRIVATE_HTTP = (timeout) => {
   const privateHttp = axios.create({
      baseURL: process.env.VUE_APP_API_URL,
      timeout: timeout || process.env.VUE_AXIOS_TIMEOUT,
      headers: {
         Authorization: `Bearer ${TokenService.getJwtToken()}`,
         "Reisetech-Version": process.env.PACKAGE_VERSION,
      },
      httpsAgent: new https.Agent({
         rejectUnauthorized: false,
      }),
   });

   privateHttp.interceptors.request.use(async (config) => {
      if (config.url === REFRESH_TOKEN_ENDPOINT) {
         return config;
      }

      if (TokenService.isJwtExpired()) {
         await refreshToken();
         config.headers.Authorization = `Bearer ${TokenService.getJwtToken()}`;
      }

      config.headers["Access-Control-Allow-Origin"] = "*";
      config.headers["GTS-UUID"] = config.data.uuid || generateUuid();

      return config;
   });

   privateHttp.interceptors.response.use(
      (response) => response,
      (error) => {
         if (error.response && error.response.status === 401) {
            if (error.config && error.config.url !== REFRESH_TOKEN_ENDPOINT) {
               logger.error("Unexpected Unauthorized Error", error);
            }

            TokenService.delJwtInfo();

            const currentPath = window.location.pathname;
            if (currentPath !== "/login") {
               router.push({ path: "/login", query: { returnUrl: currentPath } });
            }

            return new Promise(() => false);
         }

         logger.error("Axios handle exception", error);
         store.commit("app/setIsProgressGifActive", false);

         const errorData = {
            uuid: "",
            result: {
               success: false,
               app: "AGENT_SPA",
               service: "AXIOS",
               code: "EXCEPTION_AXIOS",
               subCode: error.message || "",
               message: error.stack || "",
            },
         };

         error.data = errorData;

         return Promise.reject(error);
      }
   );

   return privateHttp;
};

export default PRIVATE_HTTP;
