<template>
   <div id="customer-set-container">
      <!-- {{ selectedCustomer }} -->

      <div class="customer-set-form-row">
         <UIInput type="text" id="input_customerAgencyName" :label="$t('customerSet_lblAgencyName')" disabled v-model="customer.agencyName" />
         <UISelectBox
            id="select_customerGender"
            :label="$t('customerSet_lblGender')"
            :options="GenderList"
            v-model="customer.gender"
            is-gender
            light-theme />
         <UIInput
            type="text"
            id="input_customerName"
            :label="$t('customerSet_lblName')"
            v-model="customer.name"
            v-input="{ type: 'alphabetic', len: [2, 30], required: true, requiredMsg: $t('common_cannotBeEmpty'), group: 'customer' }" />
         <UIInput
            type="text"
            id="input_customerSurname"
            :label="$t('customerSet_lblSurname')"
            v-model="customer.surname"
            v-input="{ type: 'alphabetic', len: [2, 30], required: true, requiredMsg: $t('common_cannotBeEmpty'), group: 'customer' }" />

         <UIDatePicker
            :placeholder="$t('common_birthday')"
            :label="$t('customerSet_lblBirthdate')"
            v-model="customer.birthdate"
            :min="minDate"
            :max="maxDate"
            v-input="{ type: 'date', required: true, requiredMsg: $t('common_cannotBeEmpty'), group: 'customer' }" />

         <button class="customer-set-action-toggle" :class="showDetails ? 'details-active' : ''" @click="showDetails = !showDetails">
            <i class="gts-icon icon-arrow-down icon-xs"></i>
         </button>
      </div>

      <div class="customer-set-form-row">
         <UISelectBox
            id="select_customerGender"
            :label="$t('customerSet_lblCountryCode')"
            :options="PhoneCountry"
            v-model="customer.phone.country"
            has-icons
            light-theme />
         <UIInput
            type="text"
            id="input_customerCityCode"
            :label="$t('customerSet_lblCityCode')"
            v-model="customer.phone.city"
            v-input="{ type: 'numeric', len: [0, 4], required: false, requiredMsg: $t('common_cannotBeEmpty') }" />
         <UIInput
            type="text"
            id="input_customerPhoneNumber"
            :label="$t('customerSet_lblPhoneNumber')"
            v-model="customer.phone.number"
            v-input="{ type: 'numeric', len: [0, 8], required: false, requiredMsg: $t('common_cannotBeEmpty') }" />
         <UIInput
            type="text"
            id="input_customerEmail"
            :label="$t('customerSet_lblEmail')"
            v-model="customer.email"
            lowercase
            v-input="{ type: 'email', len: [3, 320], required: false, requiredMsg: $t('common_cannotBeEmpty') }" />
      </div>

      <template v-if="showDetails">
         <div class="customer-set-form-row">
            <UISelectBox
               id="select_customerGender"
               :label="$t('customerSet_lblNationality')"
               :options="CountryList"
               v-model="customer.nationality"
               has-icons
               light-theme />
            <KeepAlive>
               <div v-if="customer.nationality == 'TR'">
                  <UIInput
                     type="text"
                     id="input_customerNationalIdentity"
                     :label="$t('customerSet_lblNationalIdentity')"
                     v-model="customer.nationalIdentity"
                     v-input="{ type: 'tckn', len: [11, 11], required: true, requiredMsg: $t('common_cannotBeEmpty') }" />
               </div>
            </KeepAlive>
            <KeepAlive>
               <div v-if="customer.nationality != 'TR'">
                  <UIInput
                     type="text"
                     id="input_customerNationalIdentity"
                     :label="$t('customerSet_lblNationalIdentity')"
                     v-model="customer.nationalIdentity"
                     disabled
                     v-input="{ type: 'numeric', len: [0, 20], required: false, requiredMsg: $t('common_cannotBeEmpty') }" />
               </div>
            </KeepAlive>
            <UIInput
               type="text"
               id="input_customerMilesCard"
               :label="$t('customerSet_lblMilesCard')"
               v-model="customer.milesPan"
               v-input="{ type: 'alphanumeric', len: [0, 11], required: false, requiredMsg: $t('common_cannotBeEmpty') }" />
            <!-- <UIInput type="text" id="input_customerHesCode" :label="$t('customerSet_lblHesCode')" v-model="customer.hesCode" v-required v-upper /> -->
         </div>

         <div class="customer-set-form-row">
            <UIInput
               type="text"
               id="input_customerSerialNumber"
               :label="$t('customerSet_lblSerialNumber')"
               v-model="customer.passport.serial"
               v-input="{ type: 'alphanumeric', len: [0, 3], required: false, requiredMsg: $t('common_cannotBeEmpty') }" />
            <UIInput
               type="text"
               id="input_customerPassportNumber"
               :label="$t('customerSet_lblPassportNumber')"
               v-model="customer.passport.number"
               v-input="{ type: 'alphanumeric', len: [0, 9], required: false, requiredMsg: $t('common_cannotBeEmpty') }" />
            <UIDatePicker :label="$t('customerSet_lblDateofExpiry')" v-model="customer.passport.expiry" :min="expiryDate.min" :max="expiryDate.max" />
         </div>
      </template>

      <div class="customer-set-action-row">
         <UIButton className="default_outline" :text="$t('customerSet_btnClose')" @click.native="handlerCancelAction" />
         <UIButton className="default" :text="$t('customerSet_btnAdd')" @click.native="handlerSetCustomer()" />
      </div>
   </div>
</template>

<script>
import Popup from "@/gts-popup.js";
import UIInput from "@/components/unitary/form2/UIInput.vue";
import UISelectBox from "@/components/unitary/form2/UISelectBox.vue";
import UIDatePicker from "@/components/unitary/form2/UIDatePicker2.vue";
import UIButton from "@/components/unitary/Button.vue";
import moment from "moment";
import { customerService } from "@/service/index.js";
export default {
   name: "CustomerSet",
   props: {
      selectedCustomer: [String, Number, Object],
   },
   components: {
      UIInput,
      UISelectBox,
      UIDatePicker,
      UIButton,
   },
   data() {
      return {
         customer: {
            phone: {
               country: null,
            },
         },
         showDetails: false,
         minDate: moment().subtract(100, "years").format(this.$constants.dateFormat),
         maxDate: moment().format(this.$constants.dateFormat),
         expiryDate: {
            min: moment().format(this.$constants.dateFormat),
            max: moment().add(10, "years").format(this.$constants.dateFormat),
         },
      };
   },

   computed: {
      CountryList() {
         const self = this;
         //mapping countrylist for select array [tr]
         let activeLanguage = self.$store.state.user.user.language;

         let selectorStr = "";
         switch (activeLanguage) {
            case 1:
               selectorStr = "Tr";
               break;

            case 2:
               selectorStr = "En";
               break;

            case 3:
               selectorStr = "De";
               break;

            default:
               selectorStr = "En";
               break;
         }

         return self.$store.state.app.countryList.map((data) => {
            var obj = {
               name: data[`name${selectorStr}`],
               value: data.code.toUpperCase(),
               icon: data.code,
            };
            return obj;
         });
      },
      PhoneCountry() {
         const self = this;
         //mapping countrylist for select array [tr]
         let activeLanguage = self.$store.state.user.user.language;

         let selectorStr = "";
         selectorStr;
         switch (activeLanguage) {
            case 1:
               selectorStr = "Tr";
               break;

            case 2:
               selectorStr = "En";
               break;

            case 3:
               selectorStr = "De";
               break;

            default:
               selectorStr = "En";
               break;
         }

         return self.$store.state.app.countryList.map((country) => {
            var obj = {
               value: country?.phoneCode,
               name: `${country?.phoneCode} (${country?.nameTr})`,
               icon: country?.code,
            };
            return obj;
         });
      },

      GenderList() {
         const self = this;
         return [
            { name: self.$i18n.t("common_male"), value: 1 },
            { name: self.$i18n.t("common_female"), value: 2 },
         ];
      },
   },
   methods: {
      handlerCancelAction() {
         this.handlerEmitOkay();
      },

      handlerSetCustomer() {
         this.$validate("customer").then((res) => {
            const self = this;
            if (res.success) {
               customerService.setCustomer(this.customer).then(() => {
                  Popup.success(self.$i18n.t("common_success"), self.$i18n.t("customerManagement_popupCustomerEditSubtitle"));
                  //test
                  self.handlerEmitOkay();
               });
            }
         });
      },

      handlerEmitOkay() {
         this.$emit("okey");
      },
   },
   watch: {
      selectedCustomer: {
         handler: function (obj) {
            if (obj.id != 0) {
               customerService.getCustomer({ id: obj.id }).then((res) => {
                  this.customer = {
                     ...res.customer,
                     birthdate: moment(res.customer.birthdate).format(this.$constants.dateFormat),
                  };
               });
            } else {
               this.customer = obj;
            }
         },

         immediate: true,
      },
   },
};
</script>

<style lang="scss" scoped>
#customer-set-container {
   .customer-set-form-row {
      display: grid !important;
      grid-template-columns: repeat(3, 2fr);
      column-gap: 1rem;
      row-gap: 1rem;

      @media (min-width: 992px) {
         display: grid !important;
         grid-template-columns: repeat(5, 2fr) 42px;
         column-gap: 1rem;
         margin-bottom: 1rem;
      }

      .customer-set-action-toggle {
         background: #ccc;
         border-radius: 50%;
         width: 38px;
         height: 38px;
         margin-top: 37px;
         display: flex;
         align-items: center;
         justify-content: center;
         transform: rotate(0deg);
         transition-property: transform;
         transition-duration: 0.3s;
         transition-timing-function: ease-in;

         &.details-active {
            transform: rotate(180deg);
         }
      }

      ::v-deep .g-datepicker-wrapper {
         width: auto;
      }
   }
   .customer-set-action-row {
      display: flex;
      justify-content: flex-end;
      border-top: 1px solid #eee;
      padding-top: 1rem;
      padding-right: calc(1rem + 42px);

      button {
         margin-right: 1rem;

         &:last-child {
            margin-right: 0;
         }
      }
   }
}
</style>
