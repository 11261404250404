<template>
   <div id="smarttable-view-container">
      <UISelectBox id="report-type" :options="viewModes" v-model="dataViewMode" lightTheme />
   </div>
</template>

<script>
import UISelectBox from "@/components/unitary/form2/UISelectBox.vue";
export default {
   name: "SmartTableViewMode",
   props: {
      viewModes: Array,
      viewMode: String,
   },
   components: {
      UISelectBox,
   },
   data() {
      return {
         dataViewMode: this.viewMode,
      };
   },
   watch: {
      dataViewMode: {
         handler: function () {
            this.$emit("update-mode", this.dataViewMode);
         },
         immediate: true,
      },
   },
};
</script>

<style lang="scss" scoped>
#smarttable-view-container {
   position: relative;
   input {
      width: 200px;
      border-radius: 24rem;
      outline: 0;
      border: 2px solid #ccc;
      height: 40px;
      text-indent: 1rem;
      color: #363636;
      text-transform: uppercase;
      font-size: var(--small);
      background-color: #f6f6f6;

      &:focus {
         border-color: var(--accent-primary-lighten);
         background-color: #98dcff14;
         font-weight: 500;
      }

      &::-webkit-input-placeholder {
         text-transform: capitalize;
      }
   }
   .clear-search {
      cursor: pointer;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0.75rem;
      height: fit-content;
      margin: auto;
      width: 18px !important;
      height: 18px !important;

      &.disabled {
         opacity: 0;
      }
   }
}
</style>
