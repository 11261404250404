<template>
   <div id="login-page-container" class="container-fluid px-0">
      <div class="login-page-inner-wrapper col-12 h-100">
         <div class="login-page-inner-row row g-0 h-100">
            <!-- Image, slider, ads etc. -->

            <section class="login-page-left col-lg-6 d-none d-lg-block" :class="$constants.brand.cssCode"></section>

            <!-- Login Form & Helpers -->
            <section class="login-page-right col-12 col-lg-6">
               <!-- Terms of use -->
               <div class="close-terms-of-use" v-if="termsofuse.active" @click="termsofuse.active = false" key="2"></div>
               <Privacy v-if="termsofuse.active && termsofuse.identifier == 2" key="3" />
               <CookiePolicy v-if="termsofuse.active && termsofuse.identifier == 3" key="4" />

               <div id="login-form-container" v-if="!termsofuse.active" key="1">
                  <!-- Greeting Text -->
                  <div class="greeting-wrapper">
                     <img :src="`${this.$constants.cdn}/logo/${$constants.brand.cssCode}-logo.svg`" alt="" />
                  </div>
                  <!-- Login Form -->
                  <div class="form-wrapper">
                     <div class="form-row">
                        <label for="email">{{ $t("SignIn_userEmail") }}</label>
                        <div class="input-container">
                           <input
                              type="email"
                              id="input_loginUserEmail"
                              ref="email"
                              v-model="requestModel.email"
                              @input="handlerType"
                              :class="[
                                 isConfirmedEmail && isValidEmail ? 'input-success' : '',
                                 isInteracted && (!isConfirmedEmail || !isValidEmail) ? 'input-error' : '',
                              ]" />
                           <i v-if="isSpinnerActive" class="g-radial-loader"></i>
                           <i
                              v-else
                              class="gts-icon icon-sm"
                              :class="[
                                 isConfirmedEmail && isValidEmail ? 'icon-success' : '',
                                 isInteracted && (!isConfirmedEmail || !isValidEmail) ? 'icon-error' : '',
                              ]"></i>
                        </div>
                     </div>
                     <div class="form-row no-bottom-margin">
                        <label for="password">{{ $t("SignIn_userPassword") }}</label>
                        <div class="input-container">
                           <input
                              :type="showpassword ? 'text' : 'password'"
                              id="input_loginUserPassword"
                              v-model="requestModel.password"
                              @keyup.enter="login" />
                           <i
                              id="toggle-password"
                              class="gts-icon"
                              :class="showpassword ? 'icon-eye-closed' : 'icon-eye'"
                              @click="showpassword = !showpassword"></i>
                        </div>
                     </div>
                     <div class="form-row">
                        <div class="reset-password-wrapper">
                           <button class="reset-password" @click="resetPassword">
                              {{ $t("SignIn_forgotPassword") }}
                           </button>
                        </div>
                     </div>

                     <div class="form-row" v-if="usingTotpStatus">
                        <Transition name="fade">
                           <template>
                              <div class="authentication-inner">
                                 <div class="authentication-inner-item">
                                    <label class="digit-label">Token</label>
                                    <UIDigitInput
                                       :is-error="digitInputError"
                                       :clearAllDigits="clearAllDigits"
                                       @keyup.enter.native="login"
                                       @update-digit-code="
                                          (newDigitCode) => {
                                             this.requestModel.totp = newDigitCode;
                                          }
                                       " />
                                 </div>
                                 <div class="authentication-inner-item">
                                    <i
                                       class="gts-icons icon-information icon-md"
                                       @click="
                                          () => {
                                             this.isTokenTooltipActive = !this.isTokenTooltipActive;
                                          }
                                       "></i>
                                    <div class="token-tooltip-wrapper" v-if="isTokenTooltipActive">
                                       <div class="token-tooltip-inner">
                                          <div class="token-tooltip-row">
                                             <h6><strong>Token Tanımlama İçin</strong></h6>
                                          </div>
                                          <div class="token-tooltip-row token-tooltip-content">
                                             <p>
                                                Akıllı telefonunuza Google Authenticator uygulamasını indiriniz.<br />Uygulamanın telefonunuza
                                                kurulması aşamasında ekrandaki karekodu okutunuz.
                                             </p>
                                          </div>
                                          <div class="token-tooltip-row token-tooltip-cta-group">
                                             <a
                                                href="https://apps.apple.com/tr/app/google-authenticator/id388497605"
                                                target="_blank"
                                                class="token-tooltip-cta-button">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="26.824" height="32" viewBox="0 0 26.824 32">
                                                   <path
                                                      id="apple"
                                                      d="M19.013,3.481a7.829,7.829,0,0,0,.58-2.633c0-.134-.044-.223-.044-.357A2.307,2.307,0,0,0,19.46,0a7.407,7.407,0,0,0-4.775,2.9,8.68,8.68,0,0,0-1.517,4.954A13.048,13.048,0,0,0,15.131,7.5a5.894,5.894,0,0,0,2.1-1.339,8.251,8.251,0,0,0,1.785-2.678ZM16.469,7.944a10.2,10.2,0,0,1-2.767.625,14.326,14.326,0,0,1-2.633-.536,12.234,12.234,0,0,0-3.437-.625,6.73,6.73,0,0,0-5.49,2.812A11.463,11.463,0,0,0,0,17.406a19.513,19.513,0,0,0,2.812,9.685C4.731,30.393,6.694,32,8.614,32a8.2,8.2,0,0,0,2.544-.625,7.626,7.626,0,0,1,2.723-.625,8.754,8.754,0,0,1,2.9.58,8.74,8.74,0,0,0,2.723.58c1.652,0,3.3-1.25,4.954-3.7a23.2,23.2,0,0,0,1.428-2.455c.357-.759.669-1.562.937-2.321a5.774,5.774,0,0,1-3.079-2.455,7.677,7.677,0,0,1-1.294-4.285,6.944,6.944,0,0,1,1.205-3.973,17.228,17.228,0,0,1,2.231-2.277A12.272,12.272,0,0,0,24.9,9.326c-.357-.312-.669-.58-.982-.8a7.556,7.556,0,0,0-4.15-1.25,11.98,11.98,0,0,0-3.3.669Z" /></svg
                                                >APP STORE</a
                                             >
                                             <a
                                                href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en&gl=US"
                                                target="_blank"
                                                class="token-tooltip-cta-button">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="29.25" height="31.5" viewBox="0 0 29.25 31.5">
                                                   <g id="logo-google-playstore-outline" transform="translate(-3.375 -2.25)">
                                                      <path
                                                         id="Path_9272"
                                                         data-name="Path 9272"
                                                         d="M3.375,4.183V31.816a.3.3,0,0,0,.518.216L18.281,18,3.893,3.967a.3.3,0,0,0-.518.216Z" />
                                                      <path
                                                         id="Path_9273"
                                                         data-name="Path 9273"
                                                         d="M24.314,12.234,6.273,2.3l-.011-.006a.307.307,0,0,0-.352.5L20.052,16.307Z" />
                                                      <path
                                                         id="Path_9274"
                                                         data-name="Path 9274"
                                                         d="M5.912,33.215a.307.307,0,0,0,.352.5l.011-.006,18.039-9.939-4.262-4.075Z" />
                                                      <path
                                                         id="Path_9275"
                                                         data-name="Path 9275"
                                                         d="M31.6,16.242l-5.038-2.775L21.822,18l4.737,4.53L31.6,19.758a2.018,2.018,0,0,0,0-3.516Z" />
                                                   </g>
                                                </svg>

                                                PLAY STORE</a
                                             >
                                          </div>
                                       </div>
                                       <div
                                          class="token-tooltip-trigger"
                                          @click="
                                             () => {
                                                this.isTokenTooltipActive = !this.isTokenTooltipActive;
                                             }
                                          ">
                                          x
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </template>
                        </Transition>
                     </div>
                     <div class="form-row">
                        <button
                           id="login"
                           className="default"
                           @click="login"
                           :disabled="!isConfirmedEmail || requestModel.password.length < 1 || (usingTotpStatus && requestModel.totp.length != 6)"
                           ref="loginbtn">
                           <span>{{ $t("SignIn_signInBtn") }}</span>
                        </button>
                     </div>
                  </div>
                  <!-- KVKK -->
                  <section class="kvkk">
                     <p>
                        {{ $t("SignIn_legalText") }}
                        <!-- <a>{{ $t("SignIn_legalTextTermsOfService") }} </a> {{ $t("SignIn_legalText2") }} TERMS OF SERVİCE'İN KALDIRILMASINA KARAR VERİLDİ -->
                        <a @click="handlerTermsOfUse(2)">{{ $t("SignIn_legalTextPrivacyPolicy") }} </a>{{ $t("SignIn_legalText3") }}
                        <a @click="handlerTermsOfUse(3)">{{ $t("SignIn_legalTextCookieUse") }}</a>
                     </p>
                  </section>
               </div>

               <!-- Language & Copyright Informations -->
               <div id="footer-menu">
                  <div class="language-container">
                     <span
                        class="lang"
                        :class="lang == selectedLang ? 'active' : ''"
                        v-for="(lang, index) in languages"
                        :key="index"
                        :data-lang="lang"
                        @click="setLanguage($event)"
                        >{{ $t(`languageFullName_${lang}`) }}
                     </span>
                  </div>

                  <div class="div">
                     <span class="copyright-text"> &copy; Reisetech {{ copyright }}</span>

                     <span class="ml-3">v.{{ appVersion }}</span>
                  </div>
               </div>
            </section>
         </div>
      </div>
   </div>
</template>
<script>
import { userService, TokenService } from "@/service/index.js";
import { versionChecker } from "@/helper/versionChecker.js";
import { getDeviceInfo } from "@/helper/common.js";
import UIDigitInput from "@/components/unitary/DigitInput";
import Popup from "@/gts-popup.js";
import i18n from "@/fmt/i18n.js";
import Privacy from "@/pages/user/PrivacyPolicy.vue";
import CookiePolicy from "@/pages/user/Cookie.vue";
import moment from "moment";
import validatorHelpers from "@/directives/validator-helpers";
import { setCookie, getCookie } from "@/utils.js";

export default {
   name: "LoginPage",
   components: {
      Privacy,
      CookiePolicy,
      UIDigitInput,
   },
   data() {
      return {
         termsofuse: {
            active: false,
            identifier: null,
         },
         requestModel: {
            email: "",
            password: "",
            totp: "",
         },
         userLoginRequestModel: {
            email: "null",
            password: "null",
         },
         isValidEmail: null,
         isInteracted: false,
         isSpinnerActive: false,
         isConfirmedEmail: null,
         selectedLang: null,
         showpassword: false,
         digitInputError: false,
         clearAllDigits: false,
         digitLength: null,
         usingTotpStatus: false, //otp input'u default kapalı
         isTokenTooltipActive: false,
         copyright: moment().format("YYYY"),
         languages: Object.keys(i18n.messages),
         appVersion: process.env.PACKAGE_VERSION,
         OtpCookieData: {
            email: "",
            isOtpEnabled: false,
            isTodaysFirstLogin: true,
            lastLoginDate: moment().format("YYYY-MM-DD"),
            cssCode: "gtsfly",
         },
         isShowTotp: true,
      };
   },
   computed: {
      CookiePopup() {
         const self = this;
         self;
         return self.$store.state.app.cookie;
      },
   },
   mounted() {
      const self = this;
      document.querySelector("#app-wrapper").style.marginLeft = "0";

      self.selectedLang = TokenService.getLang();
      function getCookie(cname) {
         let name = cname + "=";
         let ca = document.cookie.split(";");
         for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) == " ") {
               c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
               return c.substring(name.length, c.length);
            }
         }
         return "";
      }

      if (getCookie("allowCookies").length == 0) {
         document.cookie = "allowCookies=false; path=/; secure";
         self.$store.state.app.cookie = false;
      }
      if (getCookie("allowCookies") == "true") {
         self.$store.state.app.cookie = true;
      } else {
         self.$store.state.app.cookie = false;
      }
   },

   methods: {
      handlerValidateEmail(email) {
         this.isValidEmail = validatorHelpers.emailValidation(email);
         return this.isValidEmail;
      },
      handlerType() {
         clearTimeout(this.timeout);
         this.timeout = setTimeout(() => {
            this.isSpinnerActive = true;
            this.debouncingEmail();
         }, 1000);
      },
      updateOtpData(res) {
         this.OtpCookieData.isOtpEnabled = res.usingTotp;
      },
      saveOtpCookie(cssCode) {
         if (getCookie("OtpControl") == false) {
            setCookie("OtpControl", JSON.stringify(this.OtpCookieData), 7);
         }
         let getUserCookieData = JSON.parse(getCookie("OtpControl"));
         this.OtpCookieData.cssCode = cssCode;
         this.OtpCookieData.email = this.requestModel.email;
         this.OtpCookieData.lastLoginDate = moment().format("YYYY-MM-DD");

         if (
            !getUserCookieData ||
            getUserCookieData.email != this.requestModel.email ||
            getUserCookieData.lastLoginDate != this.OtpCookieData.lastLoginDate
         ) {
            this.OtpCookieData.isTodaysFirstLogin = true;
         } else {
            this.OtpCookieData.isTodaysFirstLogin = false;
         }
         if (this.isShowTotp == false) {
            this.OtpCookieData.isTodaysFirstLogin = false;
            this.OtpCookieData.isOtpEnabled = false;
         }

         setCookie("OtpControl", JSON.stringify(this.OtpCookieData), 7);
      },
      login() {
         const self = this;
         userService
            .userStartup({
               email: self.requestModel.email,
            })
            .then((response) => {
               if (!response.usingTotp) {
                  this.updateOtpData(response);
                  self.handlerLoginAction();
               } else {
                  this.updateOtpData(response);
                  self.usingTotpStatus = response.usingTotp;
                  self.handlerLoginAction();
               }
            });
      },
      handlerLoginAction() {
         const self = this;
         self.$store.commit("app/setIsProgressGifActive", true);
         this.userLoginRequestModel.email = self.requestModel.email;
         this.userLoginRequestModel.password = btoa(self.requestModel.password);
         this.userLoginRequestModel.totp = self.requestModel.totp;
         switch (this.$i18n.locale) {
            case "tr":
               this.userLoginRequestModel.language = 1;
               break;
            case "en":
               this.userLoginRequestModel.language = 2;
               break;
            case "de":
               this.userLoginRequestModel.language = 3;
               break;
            default:
               this.userLoginRequestModel.language = 1;
               break;
         }
         this.userLoginRequestModel.deviceInfo = getDeviceInfo();
         this.userLoginRequestModel.spaVersion = process.env.PACKAGE_VERSION;

         userService.userLogin(this.userLoginRequestModel).then((response) => {
            if (response.result.subCode == "TOTP_RESET" && response.totpQrCodeUrl != "") {
               setTimeout(() => {
                  this.isSpinnerActive = true;
                  this.isConfirmedEmail = false;
                  this.isInteracted = false;
                  this.debouncingEmail();
               }, 50000);
               setTimeout(() => {
                  document.body.querySelector("#popup-close-btn").addEventListener("click", () => {
                     this.isSpinnerActive = true;
                     this.isConfirmedEmail = false;
                     this.isInteracted = false;
                     this.debouncingEmail();
                  });
               }, 200);
            }
            if (response.result.success) {
               TokenService.setJwtInfo(response.jwt, response.jwtDuration, response.jwtRefreshToken);
               const userInfos = {
                  agencyIatiId: response.originalAgencyIatiId,
                  agencyId: response.originalAgencyId,
                  agencyName: response.originalAgencyName,
                  email: response.userEmail,
                  gsaId: response.originalGsaId,
                  gsaName: response.originalGsaName,
                  id: response.userId,
                  language: response.userLanguage,
                  name: response.userName,
                  surname: response.userSurname,
                  timezoneId: response.userTimezoneId,
                  agencyOnBehalfOfId: response.onBehalfOfAgencyId,
                  gsaOnBehalfOfId: response.onBehalfOfGsaId,
                  initiated: false,
               };

               this.$store.commit("app/setSpaLastVersion", response.spaVersion);
               versionChecker.checkAndUpdateStorageVersion(process.env.PACKAGE_VERSION);

               this.$store.commit("user/updateUserInformations", userInfos);
               localStorage.setItem("auths", JSON.stringify(response.agentAuths));
               this.$store.commit("app/setIsProgressGifActive", false);
               if (this.$route && this.$route.query && this.$route.query.returnUrl && this.$route.query.returnUrl !== "/login") {
                  const returnUrl = this.$route.query.returnUrl;
                  if (this.$route.name.toLowerCase() !== returnUrl.toLowerCase()) {
                     this.$router.push(returnUrl).catch(() => {});
                  }
               } else {
                  if (this.$route.name !== "Dashboard") {
                     this.$router.push({ name: "Dashboard" }).catch(() => {});
                  }
               }
               self.clearAllDigits = false;
            } else {
               self.clearAllDigits = true;
               this.$store.commit("app/setIsProgressGifActive", false);
            }
            this.saveOtpCookie(response.info.onBehalfOfGsa.cssCode);
         });
      },
      resetPassword() {
         const userEmail = this.requestModel.email;
         const filter = new RegExp(
            /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
         );

         if (filter.test(userEmail)) {
            this.$store.commit("app/setIsProgressGifActive", true);
            userService.resetPassword({ email: userEmail, sendEmail: true }).then((response) => {
               if (response.result.success) {
                  this.$store.commit("app/setIsProgressGifActive", false);
               } else {
                  this.$store.commit("app/setIsProgressGifActive", false);
               }
            });
         } else {
            Popup.warning(i18n.t("Popup_ERROR"), i18n.t("resetPassword_emailEmpty"));
         }
      },
      setLanguage(element) {
         const self = this;
         const elementLang = element.target.getAttribute("data-lang");
         this.$store.state.language = elementLang;
         this.$i18n.locale = elementLang;
         self.selectedLang = this.$store.state.language;
      },
      handlerTermsOfUse(index) {
         const self = this;
         self.termsofuse.active = true;
         self.termsofuse.identifier = index;
      },

      debouncingEmail() {
         const self = this;

         if (self.handlerValidateEmail(self.requestModel.email)) {
            const userStartupRequestModel = {
               email: self.requestModel.email,
            };

            setTimeout(() => {
               userService
                  .userStartup(userStartupRequestModel)
                  .then((response) => {
                     this.isSpinnerActive = false;
                     if (!response) {
                        this.isConfirmedEmail = false;
                        this.isInteracted = true;
                        this.usingTotpStatus = false;
                        this.isShowTotp = true;
                        return;
                     }
                     if (response.result.success && response.usingTotp != null) {
                        this.isInteracted = true;
                        self.isConfirmedEmail = true;
                        self.usingTotpStatus = response.usingTotp;
                        this.isShowTotp = response.showTotpGuide;
                     }
                  })
                  .catch((err) => {
                     console.log("err", err);
                  });
            }, 1500);
         } else {
            this.isInteracted = true;
            this.isSpinnerActive = false;
            this.isConfirmedEmail = false;
            this.isShowTotp = true;
            this.usingTotpStatus = false;
         }
      },
   },
   watch: {
      "$store.state.app.cookie": {
         handler: function (value) {
            const self = this;
            if (value) {
               self.cookie = true;
            } else {
               self.cookie = false;
            }
         },
         immediate: true,
      },
   },
};
</script>
<style lang="scss" scoped>
#login-page-container {
   height: 100%;
   overflow: hidden;
   .login-page-left,
   .login-page-right {
      height: 100%;
   }
   .login-page-left {
      background-color: #2e67b8;
      background-size: cover;
      background-repeat: no-repeat;

      &.gtsfly {
         // background-position-x: -300px;
         background-image: url("~@/assets/images/slide/gtslogin_white.jpg");
      }
      &.mefly {
         background-position-x: -350px;
         background-image: url("~@/assets/images/slide/02.jpg");
      }
   }
   .login-page-right {
      position: relative;
      background: #fff;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0rem 2rem;
      padding-top: 5rem;

      @media (min-width: 768px) {
         justify-content: center;
         padding-top: 0;
      }
      @media (min-width: 992px) {
         align-items: flex-start;
         padding-left: 6rem;
      }
      #login-form-container {
         @media (min-width: 1200px) {
            max-width: 500px;
         }
         .greeting-wrapper {
            display: flex;
            flex-direction: column;
            margin-bottom: 2rem;

            @media (min-width: 576px) {
               width: 280px;
               margin-bottom: 3rem;
            }

            img {
               width: 200px;

               @media (min-width: 576px) {
                  width: 280px;
                  margin-bottom: 2rem;
               }
            }

            span {
               display: none;

               @media (min-width: 576px) {
                  display: block;
               }

               &.greeting-title {
                  font-size: 3rem;
                  font-weight: 600;
               }
            }
         }
         .form-wrapper {
            // border: 1px solid #eee;
            width: 100%;
            @media (min-width: 576px) {
               width: 100%;
            }
            .form-row {
               display: flex;
               flex-direction: column;
               margin-bottom: 1.5rem;

               &.disabled {
                  cursor: default;
               }

               &.no-bottom-margin {
                  margin-bottom: 0.5rem;
               }
               label {
                  font-size: 1rem;
                  margin-bottom: 0.5rem;
                  font-weight: 500;
                  color: #363636;
               }

               .input-container {
                  position: relative;
                  input {
                     outline: 0;
                     border: 0;
                     height: 50px;
                     text-indent: 0.75rem;
                     border: 1px solid #ccc;
                     background: #fff;
                     border-radius: 0.75rem;
                     width: 100%;
                     font-size: 1.125rem;

                     &:focus {
                        border: 1.5px solid var(--accent-primary);
                        background-color: #fff;
                     }
                     &[type="password"] {
                        font: large Verdana, sans-serif;
                        letter-spacing: 2px;
                        -webkit-text-security: disc;
                        font-size: 1.75rem;
                     }
                     &.input-success {
                        border: 1.5px solid hsl(150, 75%, 65%);
                        background-color: #f4fdf8;
                        color: #43d18a;
                     }
                     &.input-error {
                        border: 1.5px solid hsl(350, 85%, 65%);
                        background-color: #fff7f4;
                        color: #ec4921;
                     }
                  }

                  .g-radial-loader {
                     border-color: var(--gray-lighten);
                  }

                  #toggle-password {
                     position: absolute;
                     top: 0;
                     bottom: 0;
                     right: 1rem;
                     margin: auto;
                     cursor: pointer;
                     opacity: 0.8;
                  }
                  i {
                     position: absolute;
                     top: 0;
                     bottom: 0;
                     margin: auto;
                     right: 1rem;
                  }
               }
               .reset-password-wrapper {
                  display: flex;
                  justify-content: flex-end;
                  .reset-password {
                     background-color: transparent;
                     font-weight: 500;
                     color: var(--accent-primary);
                     font-size: 1rem;
                     cursor: pointer;
                     width: fit-content;
                  }
               }

               #login {
                  background-color: var(--primary);
                  border-radius: 0.75rem;
                  color: #fff;
                  font-size: 1.125rem;
                  font-weight: 700;
                  height: 50px;
                  margin-top: 0;

                  &:disabled {
                     background-color: var(--gray-lightest);
                     &:hover {
                        background-color: var(--gray-lighten);
                     }
                  }

                  @media (min-width: 576px) {
                     margin-top: 2rem;
                  }

                  &:hover {
                     background-color: var(--accent-primary);
                  }
               }

               .authentication-inner {
                  display: flex;
                  align-content: center;
                  justify-content: flex-start;
                  & > .authentication-inner-item {
                     position: relative;
                     &:last-child {
                        display: inline-flex;
                        align-items: center;
                     }
                     & > i.gts-icons {
                        width: 40px;
                        height: 40px;
                        display: block;
                        margin-top: 24px;
                        margin-left: 20px;
                        background-repeat: no-repeat;
                        background-size: contain;
                        &:hover {
                           cursor: pointer;
                        }
                     }

                     & > .token-tooltip-wrapper {
                        position: absolute;
                        top: -12px;
                        left: 75px;
                        width: 280px;
                        max-width: 280px;
                        background-color: white;
                        border: 1px solid #ccc;
                        border-radius: 12px;
                        padding: 20px 16px;

                        & > .token-tooltip-trigger {
                           position: absolute;
                           top: -12px;
                           right: -12px;
                           display: inline-flex;
                           align-items: center;
                           justify-content: center;
                           width: 30px;
                           height: 30px;
                           background-color: white;
                           border: 1px solid #ccc;
                           border-radius: 50%;
                           cursor: pointer;
                        }

                        & > .token-tooltip-inner {
                           & > .token-tooltip-row {
                              &.token-tooltip-content {
                                 margin: 16px 0;
                                 font-size: 14px;
                              }

                              &.token-tooltip-cta-group {
                                 display: flex;
                                 flex-direction: row;
                                 justify-content: space-between;
                                 align-items: flex-start;
                              }

                              .token-tooltip-cta-button {
                                 border: 1px solid #ccc;
                                 padding: 0.25rem 0.4rem;
                                 border-radius: 12px;
                                 display: inline-flex;
                                 align-items: center;
                                 font-size: 12px;
                                 font-weight: 500;
                                 color: black;

                                 &:hover {
                                    transform: scale(1.1);
                                    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
                                 }

                                 & > svg {
                                    width: 20px;
                                    margin-right: 8px;
                                 }
                              }
                           }
                        }
                     }
                  }
               }
            }
         }
         .kvkk {
            border-top: 1px solid #eee;
            padding-top: 1.75rem;
            margin-top: 1rem;
            font-size: var(--normal);
            text-align: center;
            position: relative;
            min-height: 65px;
            width: 100%;
            line-height: 1.5rem;

            a {
               font-weight: 500;
               color: var(--accent-primary);
            }

            &::before {
               position: absolute;
               content: "";
               width: 10px;
               height: 10px;
               border: 1px solid #eee;
               top: -5px;
               border-radius: 50%;
               left: 0;
               right: 0;
               margin: auto;
               background-color: #fff;
            }
         }
      }
      #footer-menu {
         position: absolute;
         bottom: 0;
         left: 0;
         right: 0;
         display: flex;
         justify-content: space-between;
         padding: 1rem;
         background: #fbfbfb;
         border-top: 1px solid #eee;
         width: 100%;

         .language-container {
            span {
               margin: 0 0.5rem;
               font-weight: 400;
               cursor: pointer;

               &.active {
                  font-weight: 600;
               }
            }
         }
      }
      ::v-deep {
         .terms-of-use-container {
            position: relative;
            padding: 0 3rem;
            .terms-of-use-title {
               position: absolute;
               top: 1rem;
               font-size: 2rem;
               font-weight: 600;
               z-index: 99;
               height: 100px;
               background: linear-gradient(180deg, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 0) 100%);
               right: 3rem;
               left: 3rem;
            }
            .terms-of-use {
               height: 70vh;
               overflow: hidden;
               position: relative;

               div.inner-overflow {
                  padding-right: 1rem;
                  overflow-y: scroll;
                  height: 100%;
               }
               & b {
                  font-weight: 600;
               }
               & p {
                  margin: 0.25rem 0;
               }

               & ol {
                  margin: 1rem 0;
                  padding-left: 2rem;
                  li {
                     list-style-type: decimal;
                  }
               }

               & a {
                  color: var(--primary);
                  text-decoration: underline;
               }

               &::before,
               &::after {
                  content: "";
                  position: absolute;
                  left: 0;
                  right: 0;
                  height: 80px;
                  background: rgb(255, 255, 255);

                  // background: red !important;
                  // opacity: 0.5;
               }
               &:before {
                  top: 0;
                  background: linear-gradient(180deg, rgba(255, 255, 255, 1) 15%, rgba(255, 255, 255, 0) 100%);
               }
               &::after {
                  bottom: 0;
                  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 15%, rgba(255, 255, 255, 1) 100%);
               }
            }
         }
      }

      .close-terms-of-use {
         position: absolute;
         top: 2.5rem;
         right: 3.5rem;
         cursor: pointer;
         &::before,
         &::after {
            content: "";
            width: 3.5px;
            height: 38px;
            display: block;
            border-radius: 0.5rem;
            background-color: #363636;
            position: absolute;
         }

         &::before {
            transform: rotate(45deg);
         }
         &::after {
            transform: rotate(-45deg);
         }
      }
   }
}
</style>
