<template>
   <div class="container is-widescreen">
      <div class="order-detail-view-wrapper order-detail-hotel-view row">
         <div class="order-detail-view-inner col-12">
            <VoucherAndRoomInformation
               :voucherInformation="voucherDetail.voucher"
               :cancellationPoliciesInformation="voucherDetail.cancelPolicies"
               :roomsInformation="voucherDetail.rooms"
               :partialPaymentPrices="voucherDetail?.installments" />
            <PassengerInformation :passengerInformation="voucherDetail.rooms" />
            <PriceInformation
               :addons="voucherDetail.addOns"
               :pricesInformation="voucherDetail.rooms"
               :countryCode="CountryCode"
               :totalPriceInformation="TotalPriceInformation" />
            <PaymentPurchaseWidget
               entityType="hotel"
               :entityId="VoucherId"
               :paymentChoiceDetail="PaymentChoiceDetail"
               :partialPaymentOrderDetailModel="partialPaymentModel"
               :partialPaymentModel="partialPaymentPaymentModel"
               :partialPaymentEnabled="isPartialPaymentEnabled"
               :isChecked="isPartialPaymentEnabled" />
         </div>
      </div>
   </div>
</template>

<script>
import VoucherAndRoomInformation from "@/components/structural/hotel/order-detail/VoucherAndRoomInformation.vue";
import PriceInformation from "@/components/structural/hotel/order-detail/PriceInformation.vue";
import PassengerInformation from "@/components/structural/hotel/order-detail/PassengerInformation.vue";
import PaymentPurchaseWidget from "@/components/structural/payment/PaymentPurchaseWidget.vue";

export default {
   name: "OrderDetailHotelView",
   components: {
      VoucherAndRoomInformation,
      PriceInformation,
      PassengerInformation,
      PaymentPurchaseWidget,
   },
   props: {
      voucherDetail: Object,
   },
   computed: {
      isPartialPaymentEnabled() {
         if (this.voucherDetail.voucher.isPartialPaid && this.voucherDetail.voucher.status == 2) {
            return true;
         } else {
            return false;
         }
      },
      partialPaymentPaymentModel() {
         const paymentModel = {
            firstPaymentDate: this.voucherDetail.installments[0]?.dueDate,
            secondPaymentDate: this.voucherDetail.installments[1]?.dueDate,
            firstPaymentPrice: this.voucherDetail.installments[0]?.amount,
            secondPaymentPrice: this.voucherDetail.installments[1]?.amount,
            currency: this.voucherDetail.installments[0]?.currency,
         };
         return paymentModel;
      },
      partialPaymentModel() {
         const paymentModel = {
            voucherDetail: this.voucherDetail.voucher,
            cancelattionPolicy: this.voucherDetail.cancelPolicies,
         };
         return paymentModel;
      },
      CountryCode() {
         return this.voucherDetail ? this.voucherDetail.voucher.countryCode : "";
      },
      VoucherId() {
         return this.voucherDetail ? this.voucherDetail.voucher.id : 0;
      },
      PaymentChoiceDetail() {
         return this.voucherDetail ? this.voucherDetail.paymentChoiceDetail : null;
      },
      TotalPriceInformation() {
         if (!this.voucherDetail) return "";
         return {
            total: this.voucherDetail.grandTotal,
            currency: this.voucherDetail.currency,
            priceViewType: this.voucherDetail.priceViewType,
            providerTotal: this.voucherDetail.providerTotal,
            tax: this.voucherDetail.tax,
            cityTax: this.voucherDetail.cityTax,
            baseRate: this.voucherDetail.baseRate,
            agencyCommission: this.voucherDetail.agencyCommission,
            saleTotal: this.voucherDetail.issueTotal,
            reverseTotal: this.voucherDetail.reverseTotal,
            addOnTotal: this.voucherDetail.voucher.addOnTotal,
         };
      },
   },
};
</script>

<style lang="scss" scoped>
.order-detail-view-wrapper {
   .order-detail-view-inner {
      .hotel-order-detail-section {
         margin-bottom: 42px;

         &:last-child {
            margin-bottom: 0;
         }
      }
   }
}
</style>
