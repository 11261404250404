<template>
   <div class="widget-c">
      <div class="icon-container">
         <i :class="icon == '' ? '' : icon"></i>
      </div>
      <div class="widget-wrapper">
         <div :class="title ? 'widget-title' : 'widget-nontitle'">
            <span v-if="title !== ''">{{ title }}</span>
            <span class="non-title" v-if="htmlContent" v-html="htmlContent"></span>
            <span class="non-title" v-if="text !== '' && !htmlContent"> {{ text }}</span>
         </div>
         <div class="widget-event">
            <span v-if="!ishtmlContent">{{ contentBody }}</span>
            <span v-else v-html="contentBody"></span>
         </div>
      </div>
   </div>
</template>

<script>
export default {
   name: "GtsInfoWidgetWBorder",
   props: {
      title: { type: String, default: "" },
      icon: { type: String, default: "" },
      text: { type: String, default: "" },
      ishtmlContent: { type: Boolean, default: false },
      contentBody: { type: String, default: "" },
      htmlContent: { type: String, default: "" }, // Eklenen prop
   },
};
</script>

<style lang="scss" scoped>
$icon-size-m: 40px !important;
#rounded-tick {
   width: $icon-size-m;
   height: $icon-size-m;
}
.widget-c {
   display: flex;
   border: 1px solid #29cb96;
   background: linear-gradient(90deg, #e0f9e6cb 0%, #f5fcf5 100%);
   margin-bottom: 1rem;
   border-radius: 0.75rem;
   overflow: hidden;
   i {
      width: 60px !important;
      height: 60px !important;
   }
   .widget-wrapper {
      width: 100%;
      height: auto;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      .widget-event {
         padding: 1rem;
         background: white;
      }
      .widget-title {
         display: flex;
         padding-left: 1rem;
         padding-top: 0.75rem;
         font-weight: 500;
         border-bottom: 1px solid #29cb96;
         width: 100%;
         span {
            font-size: 16px;
            color: #2f3130;
         }
         .non-title {
            font-weight: 500;
            font-size: 15px;
            padding-left: 1rem;
            font-weight: bold;
            font-size: 20px;
            margin-top: -3px;
         }
      }
      .widget-nontitle {
         display: flex;
         padding: 1.25rem;
         .non-title {
            font-weight: 500;
            font-size: 15px;
         }
      }
   }

   .icon-container {
      border-right: 1px solid #29cb96;
      padding: 1rem;
      padding-left: 1.5rem;
      background: white;
   }
}
</style>
