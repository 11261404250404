<template>
   <div class="reissue-search-leg-container">
      <AncillaryReissueSearchLegHeader :header="LegHeader" :is-selected="leg.isSelected" />
      <AncillaryReissueSearchLegBody
         :flights="Flights"
         :leg-index="leg.index"
         :is-selected="leg.isSelected"
         :airports="airports"
         v-if="LegHeader.allow"
         :allow-details="allowDetails" />
   </div>
</template>

<script>
import AncillaryReissueSearchLegHeader from "@/components/structural/flight/order-detail/ancillaries/ancillary-reissue/AncillaryReissueSearchLegHeader.vue";
import AncillaryReissueSearchLegBody from "@/components/structural/flight/order-detail/ancillaries/ancillary-reissue/AncillaryReissueSearchLegBody.vue";
export default {
   name: "AncillaryReissueSearchLeg",
   props: {
      leg: Object,
      legControl: Object,
      allowDetails: Boolean,
      airports: Object,
   },
   components: {
      AncillaryReissueSearchLegHeader,
      AncillaryReissueSearchLegBody,
   },
   computed: {
      LegHeader() {
         let isDepartureFlight = this.leg.index == 1;
         let isReturnFlight;
         if (Object.keys(this.legControl).length == 2) {
            isReturnFlight = this.leg.index == 2 && this.legControl.departureLeg;
         } else {
            isReturnFlight = this.leg.index == 2;
         }

         return {
            index: this.leg.index,
            title: this.leg.index == 1 ? "Departure Flight" : "Return Flight",
            date: this.leg.date,
            allow: this.leg.index == 1 ? isDepartureFlight : isReturnFlight,

            count: Object.keys(this.leg.flights).length,
         };
      },
      Flights() {
         return this.leg.flights;
      },
   },
   data() {
      return {};
   },
   methods: {},
};
</script>

<style lang="scss" scoped>
.reissue-search-leg-container {
   width: 100%;
   margin-bottom: 1.25rem;
}
</style>
