<template>
    <div class="service-page-wrapper">
        <Aside />
        <Main>
            <Navbar />
            <div class="service-page-inner container">
                <p>service</p>
            </div>
        </Main>
    </div>
</template>

<script>
import Aside from '@/components/unitary/Aside.vue';
import Navbar from '@/components/unitary/Navbar.vue';
import Main from '@/components/unitary/Main.vue';

export default {
    name: 'ServicePage',
    components:{
        Aside,
        Navbar,
        Main
    }
}
</script>