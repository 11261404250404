<template>
   <div class="aside-container" v-show="asideStatus" v-if="!this.$store.state.direction.isRoutedDirection">
      <!-- Space For Fixed Position -->
      <div class="aside-whitespace-wrapper"></div>
      <div id="aside-wrapper">
         <!-- Aside Menu Items -->
         <div class="aside-nav-list">
            <div class="aside-nav-list-item">
               <router-link to="/">
                  <UITooltip :tooltip-title="$t('aside_dashBoard')" tooltip-icon="gts-icon icon-aside-dashboard"></UITooltip>
               </router-link>
            </div>
            <div
               v-if="roles.includes(1) || roles.includes(2) || roles.includes(3) || roles.includes(22)"
               class="aside-nav-list-item"
               @mouseenter="handlerSubMenuEnter($event)"
               @mouseleave="handlerSubMenuLeave($event)">
               <router-link v-if="roles.includes(1)" to="/flight">
                  <UITooltip :tooltip-title="$t('aside_flightSearch')" tooltip-icon="gts-icon icon-aside-search" :hide="true"></UITooltip>
               </router-link>
               <router-link v-else-if="roles.includes(2)" to="/hotel">
                  <UITooltip :tooltip-title="$t('aside_hotelSearch')" tooltip-icon="gts-icon icon-aside-search" :hide="true"></UITooltip>
               </router-link>
               <router-link v-else-if="roles.includes(3)" to="/transfer">
                  <UITooltip :tooltip-title="$t('aside_transferSearch')" tooltip-icon="gts-icon icon-aside-search" :hide="true"></UITooltip>
               </router-link>
               <router-link v-else-if="roles.includes(22)" to="/rentacar">
                  <UITooltip :tooltip-title="$t('aside_rentACarSearch')" tooltip-icon="gts-icon icon-aside-search" :hide="true"></UITooltip>
               </router-link>

               <div class="aside-circular-menu" v-if="false">
                  <div v-if="roles.includes(1)" class="aside-circular-menu-item flight">
                     <span>U</span>
                  </div>
                  <div v-if="roles.includes(2)" class="aside-circular-menu-item hotel">
                     <span>H</span>
                  </div>
                  <div v-if="roles.includes(3)" class="aside-circular-menu-item transfer">
                     <span>T</span>
                  </div>
                  <div v-if="roles.includes(2)" class="aside-circular-menu-item rentACar">
                     <span>R</span>
                  </div>
               </div>
               <div class="aside-nav-list-sub-menu">
                  <ul>
                     <li v-if="roles.includes(1)">
                        <router-link to="/flight">
                           <span>{{ $t("aside_flightSearch") }}</span>
                        </router-link>
                     </li>
                     <li v-if="roles.includes(2)">
                        <router-link to="/hotel">
                           <span>{{ $t("aside_hotelSearch") }}</span>
                        </router-link>
                     </li>
                     <li v-if="roles.includes(3)">
                        <router-link to="/transfer">
                           <span>{{ $t("aside_transferSearch") }}</span>
                        </router-link>
                     </li>
                     <li v-if="roles.includes(22)">
                        <router-link to="/rentacar">
                           <span>{{ $t("aside_rentACarSearch") }}</span>
                        </router-link>
                     </li>
                     <li v-if="roles.includes(25)">
                        <router-link to="/packages">
                           <span>{{ $t("common_searchPackages") }}</span>
                        </router-link>
                     </li>
                  </ul>
               </div>
            </div>

            <div v-if="roles.includes(7)" class="aside-nav-list-item">
               <router-link to="/sale/order">
                  <UITooltip :tooltip-title="$t('aside_orderSearch')" tooltip-icon="gts-icon icon-aside-orderlist"></UITooltip>
               </router-link>
            </div>
            <div v-if="roles.includes(9)" class="aside-nav-list-item">
               <router-link to="/task/taskList">
                  <UITooltip :tooltip-title="$t('aside_serviceCenter')" tooltip-icon="gts-icon icon-aside-tasklist"></UITooltip>
               </router-link>
            </div>

            <div
               v-if="roles.includes(10) || roles.includes(11) || roles.includes(21)"
               class="aside-nav-list-item"
               @mouseenter="handlerSubMenuEnter($event)"
               @mouseleave="handlerSubMenuLeave($event)">
               <router-link v-if="roles.includes(10)" to="/report/flightSalesReport">
                  <UITooltip :tooltip-title="$t('aside_reports')" tooltip-icon="gts-icon icon-aside-salereport" :hide="true"></UITooltip>
               </router-link>
               <router-link v-else-if="roles.includes(11)" to="/report/hotelSalesReport">
                  <UITooltip :tooltip-title="$t('aside_reports')" tooltip-icon="gts-icon icon-aside-salereport" :hide="true"></UITooltip>
               </router-link>
               <router-link v-else-if="roles.includes(21)" to="/report/transferSalesReport">
                  <UITooltip :tooltip-title="$t('aside_reports')" tooltip-icon="gts-icon icon-aside-salereport" :hide="true"></UITooltip>
               </router-link>
               <router-link v-else-if="roles.includes(23)" to="/report/rentACarSalesReport">
                  <UITooltip :tooltip-title="$t('aside_reports')" tooltip-icon="gts-icon icon-aside-salereport" :hide="true"></UITooltip>
               </router-link>
               <router-link v-else-if="roles.includes(26)" to="/report/schmetterlingSalesReport">
                  <UITooltip :tooltip-title="$t('aside_reports')" tooltip-icon="gts-icon icon-aside-salereport" :hide="true"></UITooltip>
               </router-link>

               <div class="aside-nav-list-sub-menu">
                  <ul>
                     <li v-if="roles.includes(10)">
                        <router-link to="/report/flightSalesReport">
                           <span>{{ $t("aside_flightSalesReport") }}</span>
                        </router-link>
                     </li>
                     <li v-if="roles.includes(11)">
                        <router-link to="/report/hotelSalesReport">
                           <span>{{ $t("aside_hotelSalesReport") }}</span>
                        </router-link>
                     </li>
                     <li v-if="roles.includes(21)">
                        <router-link to="/report/transferSalesReport">
                           <span>{{ $t("aside_transferSalesReport") }}</span>
                        </router-link>
                     </li>
                     <li v-if="roles.includes(23)">
                        <router-link to="/report/rentACarSalesReport">
                           <span>{{ $t("aside_rentACarSalesReport") }}</span>
                        </router-link>
                     </li>
                     <li v-if="roles.includes(26)">
                        <router-link to="/report/schmetterlingSalesReport">
                           <span>{{ $t("aside_schmetterlingSalesReport") }}</span>
                        </router-link>
                     </li>
                  </ul>
               </div>
            </div>

            <!-- <div class="aside-nav-list-item" @mouseenter="submenu = true" @mouseleave="submenu = false" :class="submenu ? 'has-sub' : ''"> -->
            <div
               v-if="roles.includes(12) || roles.includes(13) || roles.includes(14)"
               class="aside-nav-list-item"
               @mouseenter="handlerSubMenuEnter($event)"
               @mouseleave="handlerSubMenuLeave($event)">
               <router-link v-if="roles.includes(12)" to="/finance/invoiceList">
                  <UITooltip :tooltip-title="$t('aside_finance')" tooltip-icon="gts-icon icon-aside-invoice" :hide="true"></UITooltip>
               </router-link>
               <router-link v-else-if="roles.includes(13)" to="/finance/accTransactionList">
                  <UITooltip :tooltip-title="$t('aside_finance')" tooltip-icon="gts-icon icon-aside-invoice" :hide="true"></UITooltip>
               </router-link>
               <router-link v-else-if="roles.includes(14)" to="/finance/balanceUpdate">
                  <UITooltip :tooltip-title="$t('aside_finance')" tooltip-icon="gts-icon icon-aside-invoice" :hide="true"></UITooltip>
               </router-link>

               <div class="aside-nav-list-sub-menu">
                  <ul>
                     <li v-if="roles.includes(12)">
                        <router-link to="/finance/invoiceList">
                           <span>{{ $t("aside_invoiceList") }}</span>
                        </router-link>
                     </li>
                     <li v-if="roles.includes(13)">
                        <router-link to="/finance/accTransactionList">
                           <span>{{ $t("aside_accTransactionList") }}</span>
                        </router-link>
                     </li>
                     <li v-if="roles.includes(14)">
                        <router-link to="/finance/balanceUpdate">
                           <span>{{ $t("aside_balanceUpdate") }}</span>
                        </router-link>
                     </li>
                  </ul>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>
import UITooltip from "@/components/utils/GtsToolTips.vue";
export default {
   name: "Aside",
   components: {
      UITooltip,
   },
   data() {
      return {
         asideStatus: true,
         roles: [],
      };
   },
   mounted() {
      this.roles = JSON.parse(localStorage.getItem("auths"));
      window.addEventListener("mousemove", (mouse) => {
         if (mouse.x <= 5 && !this.asideStatus) {
            this.asideStatus = true;
         }
      });
      window.addEventListener("resize", () => {
         this.handlerArrangeGrid();
      });
   },
   methods: {
      handlerCloseAsideMenu() {
         this.asideStatus = false;
      },
      handlerSubMenuEnter(ev) {
         ev.target.classList.add("has-sub");
      },
      handlerSubMenuLeave(ev) {
         ev.target.classList.remove("has-sub");
      },
      handlerArrangeGrid() {
         const screenInnerWidth = window.innerWidth;
         if (this.asideStatus && screenInnerWidth >= 1200) {
            document.querySelector("#app-wrapper").style.marginLeft = "64px";
         } else {
            document.querySelector("#app-wrapper").style.marginLeft = "0";
         }
      },
   },
   watch: {
      asideStatus: {
         handler: function () {
            this.handlerArrangeGrid();
         },
         immediate: true,
      },
   },
};
</script>

<style lang="scss" scoped>
.aside-container {
   display: none;

   @media (min-width: 1200px) {
      display: block;
   }

   .aside-whitespace-wrapper {
      width: 64px;
   }
   #aside-wrapper {
      background-color: var(--c-side-bar);
      width: 64px !important;
      position: fixed;
      top: 0px;
      bottom: 0px;
      margin: auto;
      left: 0;
      height: fit-content;
      border-radius: 0px 32px 32px 0px;
      bottom: 0;
      z-index: 999;

      .aside-toggler-container {
         height: 80px;
         display: flex;
         align-items: center;
         justify-content: center;
         .aside-toggler {
            background-color: #00000030;
            width: 38px;
            height: 38px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            margin-top: 0.5rem;
            flex-shrink: 0;
            cursor: pointer;
            i {
               transform: rotate(-180deg);
               filter: brightness(10);
               margin-right: 0.2rem; //optical allignment
            }
         }
      }

      .aside-nav-list {
         width: 100%;
         margin-top: 48px;
         .aside-nav-list-item {
            display: flex;
            justify-content: center;
            position: relative;
            margin: 0.25rem 0;

            &.has-sub {
               background: var(--c-aside-submenu);

               .aside-nav-list-sub-menu {
                  display: block;
               }
            }

            a {
               width: 100%;
               height: auto;
               position: relative;

               display: flex;
               justify-content: center;

               .gts-tooltip {
                  ::v-deep .gts-icon {
                     filter: brightness(0) invert(1);
                     width: 34px !important;
                     height: 34px !important;
                     transform: scale(1);
                     transition-property: transform;
                     transition-timing-function: ease-in;
                     transition-duration: 0.05s;

                     &:hover {
                        transform: scale(1.1);
                        filter: invert(50%) sepia(100%) saturate(256%) hue-rotate(160deg) brightness(100%) contrast(200%);
                     }
                  }
               }
            }
            &:last-child {
               margin-bottom: 48px;
            }
            .aside-nav-list-sub-menu {
               position: absolute;
               top: 0;
               left: 100%;
               background-color: var(--c-aside-submenu);
               border-radius: 0 0.5rem 0.5rem 0;
               display: none;
               ul {
                  padding: 0.5rem 0rem;

                  li {
                     position: relative;
                     a {
                        display: flex;
                        justify-content: flex-start;
                        span {
                           position: relative;
                           display: flex;
                           white-space: nowrap;
                           color: #fff;
                           font-size: var(--normal);
                           font-weight: 500;
                           padding: 0.5rem 2rem;
                           padding-right: 3rem;
                           z-index: 2;
                        }
                     }

                     &:before {
                        content: "";
                        position: absolute;
                        bottom: 0;
                        height: 0px;
                        width: 100%;
                        z-index: 0;
                        background-color: #00000020;
                        opacity: 0;
                        transition-property: all;
                        transition-timing-function: ease-in-out;
                        transition-duration: 0.12s;
                     }

                     &:hover {
                        cursor: pointer;
                        &:before {
                           content: "";
                           position: absolute;
                           bottom: 0;
                           opacity: 1;
                           height: 100%;
                        }
                     }
                  }
               }
            }

            .aside-circular-menu {
               position: absolute;
               left: 100%;
               background-color: lightcyan;
               width: fit-content;
               top: -32px;

               .aside-circular-menu-item {
                  position: relative;
                  left: 0;
                  width: 48px;
                  height: 48px;
                  background-color: var(--primary);
                  border-radius: 50%;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  transform: scale(1);

                  &:hover {
                     transform: scale(1.15);
                  }

                  span {
                     color: #fff;
                     font-weight: 500;
                     font-size: var(--large);
                  }
               }
            }
         }
      }
   }
}
</style>
