<template>
   <div v-if="isVisible" class="totp-modal" ref="modal">
      <div class="modal-content">
         <div class="modal-header">
            <span class="modal-title">{{ tokenText }}</span>
         </div>
         <div class="modal-body">
            <div class="code-inputs">
               <input
                  v-model="code1"
                  maxlength="1"
                  class="code-input"
                  ref="code1"
                  @input="moveFocus(1, 'next')"
                  @keydown.backspace="moveFocus(1, 'prev')"
                  @keyup.enter="handleEnter" />
               <input
                  v-model="code2"
                  maxlength="1"
                  class="code-input"
                  ref="code2"
                  @input="moveFocus(2, 'next')"
                  @keydown.backspace="moveFocus(2, 'prev')"
                  @keyup.enter="handleEnter" />
               <input
                  v-model="code3"
                  maxlength="1"
                  class="code-input"
                  ref="code3"
                  @input="moveFocus(3, 'next')"
                  @keydown.backspace="moveFocus(3, 'prev')"
                  @keyup.enter="handleEnter" />
               <input
                  v-model="code4"
                  maxlength="1"
                  class="code-input"
                  ref="code4"
                  @input="moveFocus(4, 'next')"
                  @keydown.backspace="moveFocus(4, 'prev')"
                  @keyup.enter="handleEnter" />
               <input
                  v-model="code5"
                  maxlength="1"
                  class="code-input"
                  ref="code5"
                  @input="moveFocus(5, 'next')"
                  @keydown.backspace="moveFocus(5, 'prev')"
                  @keyup.enter="handleEnter" />
               <input
                  v-model="code6"
                  maxlength="1"
                  class="code-input"
                  ref="code6"
                  @input="moveFocus(6, 'next')"
                  @keydown.backspace="moveFocus(6, 'prev')"
                  @keyup.enter="handleEnter" />
            </div>
         </div>
         <div class="modal-footer">
            <UIButton className="default" :text="verifyToken" @click.native="verifyTotp" />
         </div>
      </div>
   </div>
</template>

<script>
import UIButton from "../unitary/Button.vue";
export default {
   props: {
      tokenText: {
         type: String,
         required: true,
      },
      verifyToken: {
         type: String,
         required: true,
      },
   },
   components: {
      UIButton,
   },
   data() {
      return {
         isVisible: false,
         code1: "",
         code2: "",
         code3: "",
         code4: "",
         code5: "",
         code6: "",
         translatedTitle: "",
         currentLanguage: "",
      };
   },
   mounted() {
      this.currentLanguage = this.$i18n.locale;
   },
   methods: {
      show() {
         this.isVisible = true;
      },
      hide() {
         this.isVisible = false;
      },
      moveFocus(currentIndex, direction) {
         if (direction === "next" && this[`code${currentIndex}`].length === 1) {
            const nextRef = `code${currentIndex + 1}`;
            if (this.$refs[nextRef]) {
               this.$refs[nextRef].focus();
            }
         } else if (direction === "prev" && !this[`code${currentIndex}`]) {
            const prevRef = `code${currentIndex - 1}`;
            if (this.$refs[prevRef]) {
               this.$refs[prevRef].focus();
            }
         }
      },
      handleEnter() {
         if (this.code1 && this.code2 && this.code3 && this.code4 && this.code5 && this.code6) {
            this.verifyTotp();
         }
      },
      verifyTotp() {
         const totpCode = this.code1 + this.code2 + this.code3 + this.code4 + this.code5 + this.code6;
         if (totpCode.length === 6) {
            this.$emit("verified", totpCode);
            this.hide();
         }
      },
      cancel() {
         this.$emit("cancelled");
         this.hide();
      },
   },
};
</script>

<style lang="scss">
$primary-color: #007bff;
$danger-color: #f44336;
$background-color: #ffffff;
$modal-header-color: #333;
$modal-footer-bg: #f5f5f5;
$overlay-color: rgba(0, 0, 0, 0.5);
$border-color: #ddd;
.totp-modal {
   position: fixed;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   display: flex;
   justify-content: center;
   align-items: center;
   background: $overlay-color;
   z-index: 1000;
   backdrop-filter: blur(20px);
   .modal-content {
      background: $background-color;
      border-radius: 12px;
      width: 100%;
      max-width: 450px;
      box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
      overflow: hidden;
      .modal-header {
         display: flex;
         justify-content: space-between;
         align-items: center;
         padding: 20px;
         background-color: $modal-footer-bg;
         border-bottom: 1px solid $border-color;
         .modal-title {
            font-size: 1.5rem;
            font-weight: bold;
            color: $modal-header-color;
         }
      }
      .modal-body {
         padding: 20px;
         text-align: left;
         color: $modal-header-color;
         p {
            margin-bottom: 15px;
            font-size: 1rem;
         }
         .code-inputs {
            display: flex;
            justify-content: space-between;
            margin-top: 15px;
            .code-input {
               width: 50px;
               height: 50px;
               text-align: center;
               font-size: 1.5rem;
               border: 2px solid $border-color;
               border-radius: 8px;
               box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
            }
         }
      }
      .modal-footer {
         display: flex;
         justify-content: flex-end;
         align-items: center;
         background: $modal-footer-bg;
         padding: 15px 20px;
         .verify-button {
            background-color: $primary-color;
            color: white;
            padding: 10px 20px;
            border: none;
            border-radius: 5px;
            font-size: 1rem;
            cursor: pointer;
            transition: background-color 0.3s ease;
            &:hover {
               background-color: darken($primary-color, 10%);
            }
         }
      }
   }
}
</style>
