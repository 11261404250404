<template>
   <div class="filter-section-container">
      <div class="filter-section-title-wrapper">
         <span class="filter-section-title">{{ $t("hotelFilter_lblFacilityTitle") }}</span>
         <span class="filter-section-toggle" @click="handlerClearAll">{{ $t("searchFiltersHotel_deselectAll") }}</span>
      </div>
      <template>
         <template v-for="(facility, index) in facilitiesArr">
            <div class="filter-item" :key="index" v-if="index <= maxLength">
               <UICheckBox v-model="facility.checked" :id="facility.id" :label="setLabelText(facility)" hotel @input="handlerCheckClick" />
               <span class="filter-count">{{ facility.count }}</span>
            </div>
         </template>
      </template>

      <button class="show-more-filter" @click="handlerExpandFilter">
         {{ maxLength == 20 ? $t("hotelFilter_btnShowLess") : $t("hotelFilter_btnShowMore") }}
      </button>

      <span class="show-all-facilities" @click="handlerTriggerModal" v-if="maxLength > 3">{{ $t("hotelFilter_btnShowAll") }}</span>
   </div>
</template>

<script>
import UICheckBox from "@/components/unitary/form2/UICheckBox.vue";

export default {
   name: "FilterFacilities",
   props: {
      facilities: {
         type: Object,
         required: true,
      },
   },
   components: {
      UICheckBox,
   },
   data() {
      return {
         maxLength: 3,
         facilitiesArr: [],
         activeLang: null,
      };
   },
   methods: {
      handlerCheckClick() {
         this.$emit("click", true);
      },
      handlerClearAll() {
         Object.keys(this.facilities).forEach((facility) => {
            this.facilities[facility].checked = false;
         });
         this.handlerCheckClick();
      },
      handlerTriggerModal() {
         this.$parent.$parent.$parent._data.facilitiesModalStatus = true;
      },
      handlerExpandFilter() {
         if (this.maxLength == 3) {
            this.maxLength = 20;
         } else {
            this.maxLength = 3;
         }
      },
      setLabelText(facility) {
         return this.activeLang == "en" ? facility.nameEn : this.activeLang == "tr" ? facility.nameTr : facility.nameDe;
      },
      updateFacilityCounts(tempFacilitiesArr) {
         if (!tempFacilitiesArr) return;

         const secondArrayMap = {};
         for (const obj of tempFacilitiesArr) {
            secondArrayMap[obj.id] = obj;
         }

         // Iterate over the first array objects and replace the count property
         for (const obj of this.facilitiesArr) {
            const matchingObject = secondArrayMap[obj.id];
            if (matchingObject) {
               obj.count = matchingObject.count;
            }
         }
      },
   },
   watch: {
      facilities: {
         handler: function (facilities) {
            Object.keys(facilities).forEach((facility) => {
               this.facilitiesArr.push(facilities[facility]);
            });
            this.facilitiesArr.sort((a, b) => a.priority - b.priority || a.uiIndex - b.uiIndex);
         },
         immediate: true,
      },
      "$store.state.hotel.isFilterActive": {
         handler: function () {
            const self = this;
            let searchRes = self.$store.state.hotel.searchResult;
            let activeFacilityFilters = [];
            let hotelFacilityIds = [];
            // to get active facilities
            Object.values(self.$store.state.hotel.hotelFilter.facilities).map((facility) =>
               facility.checked ? activeFacilityFilters.push(facility.id) : null
            );
            // filtering hotels by active facilities and getting all facilities of filtered hotels
            searchRes
               .filter((hotel) => {
                  return activeFacilityFilters.every((facility) => {
                     return hotel.allFacilities.includes(facility);
                  });
               })
               .map((hotel) => hotel.allFacilities.forEach((facilityId) => hotelFacilityIds.push(facilityId)));
            // counting facility counts of each hotel
            const result = hotelFacilityIds.reduce((acc, curr) => {
               if (!acc[curr]) {
                  acc[curr] = { count: 1 };
               } else {
                  acc[curr].count++;
               }
               return acc;
            }, {});

            let deepCopiedFacilitiesArr = window.structuredClone(self.facilitiesArr);

            deepCopiedFacilitiesArr.map((facility) => {
               if (Object.prototype.hasOwnProperty.call(result, facility.id)) {
                  facility.count = result[facility.id].count;
               } else {
                  facility.count = 0;
               }
               return facility;
            });

            self.updateFacilityCounts(deepCopiedFacilitiesArr);
         },
      },
      "$i18n.locale": {
         handler: function (locale) {
            this.activeLang = locale;
         },
         immediate: true,
      },
   },
};
</script>
<style lang="scss" scoped>
.facilities-modal {
   position: fixed;
   top: 0;
   bottom: 0;
   right: 0;
   left: 0;
   z-index: 9999;
   width: 100%;
   height: 100%;
   // background: #00000050;
   .facilities-modal-container {
      position: absolute;
      z-index: 9999999;
      background: green;
      border-radius: 0.5rem;
      border: 1px solid #ccc;
      width: 800px;
      height: 600px;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
   }
}
.show-more-filter {
   border: 1px solid #ccc;
   font-size: var(--small);
   background-color: #eee !important;
   border-radius: 0.25rem;
   display: flex;
   align-items: center;
   display: block;
   width: 100%;
   padding: 0.25rem 0;
   font-weight: 500;
   cursor: pointer;
   margin-top: 0.5rem;
}
.show-all-facilities {
   color: var(--primary);
   font-size: var(--small);
   font-weight: 500;
   width: 100%;
   text-decoration: underline;
   padding-top: 0.5rem;
   text-align: center;
   display: block;
   cursor: pointer;
}
</style>
