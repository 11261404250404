import moment from "moment";
// import i18n from "@/fmt/i18n.js";

export const flightChips = function (chips) {
   const arr = [];
   chips.forEach((chip) => {
      if (chip.type === 2 && !chip.code.includes("!")) {
         chip.code = chip.code + "!";
      }
      arr.push(chip.code);
   });
   return arr;
};

export const isValidDate = function (date) {
   const regx = /^[0-9-.]*$/;
   const maxLength = 10;
   let dateVal = date;

   if (date && date.includes("T")) {
      dateVal = date.split("T")[0];
   }

   if (regx.test(dateVal) && dateVal.length == maxLength) {
      return true;
   } else {
      return false;
   }
};

export const collapseLeg = function (obj, objLength) {
   Object.keys(obj).forEach((item) => {
      if (objLength > 2) {
         document.querySelector(`.flight-leg-${item}`).classList.remove("flight-leg-sticky");
      } else {
         document.querySelector(`.flight-leg-${item}`).classList.add("flight-leg-sticky");
      }
   });
};

export const baggageInformations = function (baggage) {
   if (baggage == "0KG" || baggage == "0PC" || baggage == "-1KG") {
      return { status: false, info: baggage };
   } else {
      return { status: true, info: baggage };
   }
};

export const flightDuration = (t) => {
   const dEnum = "d ",
      hEnum = "h ",
      mEnum = "m ",
      d = moment.duration(t).days(),
      h = moment.duration(t).hours(),
      m = moment.duration(t).minutes();
   var txt = "";

   if (d == 0 && h == 0 && m == 0) {
      txt = false;
   } else {
      if (d > 0) {
         txt = d + dEnum;
      }
      if (h > 0) {
         txt += h + hEnum;
      }
      if (m > 0) {
         txt += m + mEnum;
      }
   }

   return txt;
};

export const segmentGenerator = function (segments, highlightedDestinations) {
   const from = 0;
   const to = segments.length;
   var obj = {};

   const chipHighlighter = function (identifier) {
      return Object.keys(highlightedDestinations).some((airport) => {
         if (identifier == "from") {
            return highlightedDestinations[airport] == segments[from].departureAirportCode;
         } else {
            return highlightedDestinations[airport] == segments[to - 1].arrivalAirportCode;
         }
      });
   };

   obj[from] = {
      airport: segments[from].departureAirportCode,
      time: moment(segments[from].departureDateTime).format("HH:mm"),
      timeFilter: segments[from].departureDateTime,
      flight: segments[from].flightNumber,
      travelTime: segments[from].travelTime,
      highlighted: chipHighlighter("from"),
   };
   obj[to] = {
      airport: segments[to - 1].arrivalAirportCode,
      time: moment(segments[to - 1].arrivalDateTime).format("HH:mm"),
      timeFilter: segments[to - 1].arrivalDateTime,
      travelTime: segments[to - 1].travelTime,
      highlighted: chipHighlighter("to"),
   };

   segments.forEach((segment, i) => {
      if (i != 0 && i != segments.length) {
         obj[i] = {
            airport: segment.departureAirportCode,
            // time: moment(segments[i].departureDateTime).format("HH:mm"),
            time: moment(segments[i - 1].arrivalDateTime).format("HH:mm"),
            time2: moment(segment.departureDateTime).format("HH:mm"),
            flight: segment.flightNumber,
            travelTime: segment.travelTime,
         };
         // is city transfer?
         if (segments[i - 1].arrivalAirportCode != segments[i].departureAirportCode) {
            obj[i].airport2 = segments[i - 1].arrivalAirportCode;
            obj[i].hasCityTransfer = true;
         }
      }
   });

   return obj;
};

export const format24hours = function (val) {
   if (val != 24) {
      return moment(val, "hh").format("HH:00");
   } else {
      return "24:00";
   }
};
export const convertToHour = function (val) {
   val = moment(val, "HH:mm").format("HH");
   return parseInt(val);
};
