<template>
   <div class="g-input-container" v-click-outside="handlerClickOutside">
      <label>{{ label }}</label>
      <!-- INPUT TYPE TEXT -->
      <div class="g-input-text-wrapper" @click="handlerShowDatepicker">
         <input type="text" :value="Display" @input="handlerInputChange($event)" v-date />

         <!-- Input Actions -->
         <div class="g-form-input-actions-wrapper">
            <div class="clear-day-button gts-animation me-0 d-none d-xxl-flex" v-if="ShowClear" @click="handlerClearInput">
               <i class="gts-icon icon-close-rounded icon-xs"></i>
            </div>
            <div class="g-form-input-datepicker-handler">
               <div class="prev-day-button gts-animation" @click="handlerPrevDay">
                  <i class="gts-icon icon-arrow-prev icon-xs"></i>
               </div>
               <div class="next-day-button gts-animation" @click="handlerNextDay">
                  <i class="gts-icon icon-arrow icon-xs"></i>
               </div>
            </div>
         </div>
         <div class="g-form-validation">{{ $t("common_cannotBeEmpty") }}</div>
      </div>

      <!-- DATEPICKER  -->
      <div class="g-datepicker-wrapper" v-if="datepicker.status">
         <b-datepicker
            inline
            :day-names="WeekDays"
            v-model="datepicker.value"
            :focused-date="date"
            :min-date="datepicker.min"
            :max-date="datepicker.max"
            :nearby-month-days="false"
            :first-day-of-week="1"
            :events="Events"
            @input="handlerDatePickerChange">
            <template #header>
               <b-field>
                  <div class="datepicker-gts-header">
                     <div class="button-prev" @click="handlerPrevMonth" :class="datepicker.navigations.prev ? '' : 'invisible'">
                        <i class="gts-icon icon-arrow icon-sm gts-animation"></i>
                     </div>

                     <span class="month-name">{{ DatepickerHeader }}</span>
                     <div class="button-next" @click="handlerNextMonth" :class="datepicker.navigations.next ? '' : 'invisible'">
                        <i class="gts-icon icon-arrow icon-sm gts-animation"></i>
                     </div>
                  </div>
               </b-field>
            </template>
         </b-datepicker>
      </div>
   </div>
</template>

<script>
import moment from "moment";
import { isValidDate } from "@/helper/flight.js";
const displayFormat = "DD.MM.YYYY";

export default {
   name: "FlightDatePicker",
   props: {
      label: String,
      value: [String, Object],
      allowClear: Boolean,
      siblingDate: String,
      events: Array,
      isMultileg: Boolean,
      return: Boolean,
   },
   data() {
      return {
         date: moment()._d,
         datepicker: {
            value: null,
            min: moment().subtract(1, "days")._d,
            max: moment().add(1, "year")._d,
            status: false,
            navigations: {
               next: true,
               prev: true,
            },
            monthArr: [],
            header: "",
         },
      };
   },

   computed: {
      WeekDays() {
         return moment().locale(this.$i18n.locale)._locale._weekdaysMin;
      },
      Display() {
         if (isValidDate(this.value)) {
            return moment(this.value, this.$constants.dateFormat).format(displayFormat);
         } else {
            return "";
         }
      },
      DatepickerHeader() {
         const datePickerYear = moment(this.date).format(" YYYY");
         const datePickerMonth = this.datepicker.monthArr[parseInt(moment(this.date).format("MM")) - 1].name;

         return datePickerMonth + datePickerYear;
      },
      ShowClear() {
         if (this.allowClear && this.value != null) {
            return true;
         } else {
            return false;
         }
      },
      Events: function () {
         if (this.isMultileg) {
            return this.events;
         } else {
            if (this.return) {
               return [
                  {
                     date: moment(this.siblingDate, this.$constants.dateFormat)._d,
                     type: "is-departure",
                  },
               ];
            } else {
               return [
                  {
                     date: moment(this.siblingDate, this.$constants.dateFormat)._d,
                     type: "is-departure",
                  },
               ];
            }
         }
      },
   },

   methods: {
      handlerShowDatepicker() {
         this.datepicker.status = true;
      },
      handlerInputChange(ev) {
         if (isValidDate(ev.target.value)) {
            const dateStr = moment(ev.target.value, displayFormat).format(this.$constants.dateFormat);
            this.$emit("input", dateStr);
         }
      },
      handlerClearInput() {
         this.$emit("input", null);
      },
      handlerDatePickerChange() {
         const selectedDate = moment(this.datepicker.value).format(this.$constants.dateFormat);
         this.$emit("input", selectedDate);
         this.datepicker.status = false;
      },
      handlerIsOutOfRange() {
         const smallerThanMin = this.datepicker.value < this.datepicker.min;
         const biggerThanMax = this.datepicker.value > this.datepicker.max;

         if (smallerThanMin || biggerThanMax) {
            this.datepicker.value = this.date = moment(this.datepicker.min, this.$constants.dateFormat)._d;
            this.$emit("input", moment(this.datepicker.value).format(this.$constants.dateFormat));
         }
      },
      handlerPrevMonth() {
         this.date = new Date(this.date);
         this.date.setDate(1);
         this.date.setMonth(this.date.getMonth() - 1);
      },
      handlerNextMonth() {
         this.date = new Date(this.date);
         this.date.setDate(1);
         this.date.setMonth(this.date.getMonth() + 1);
      },
      handlerClickOutside() {
         this.datepicker.status = false;
      },
      handlerPrevDay() {
         const prevDay = moment(this.value, this.$constants.dateFormat).subtract(1, "day").format(this.$constants.dateFormat);
         this.$emit("input", prevDay);
      },
      handlerNextDay() {
         const nextDay = moment(this.value, this.$constants.dateFormat).add(1, "day").format(this.$constants.dateFormat);
         this.$emit("input", nextDay);
      },
   },
   watch: {
      value: {
         handler: function (val) {
            if (isValidDate(val)) {
               this.datepicker.value = this.date = moment(val, this.$constants.dateFormat)._d;
               this.handlerIsOutOfRange();
            }
         },
      },
      date: {
         handler: function () {
            const minDate = moment(this.datepicker.min).format("MM.YYYY");
            const maxDate = moment(this.datepicker.max).format("MM.YYYY");
            const currentDate = moment(this.date).format("MM.YYYY");
            if (maxDate == currentDate) {
               this.datepicker.navigations.next = false;
            } else {
               this.datepicker.navigations.next = true;
            }
            if (minDate == currentDate) {
               this.datepicker.navigations.prev = false;
            } else {
               this.datepicker.navigations.prev = true;
            }
         },
         immediate: true,
      },

      "datepicker.status": {
         handler: function (status) {
            const isSiblingValid = isValidDate(this.siblingDate);

            if (status && isSiblingValid && this.value == null) {
               this.datepicker.value = moment(this.siblingDate, this.$constants.dateFormat)._d;
               this.date = this.datepicker.value;
            }
         },
      },
      "$i18n.locale": {
         handler: function () {
            this.datepicker.monthArr = this.$GtsDropdownData.dateMonthArray();
         },
         immediate: true,
      },
   },
};
</script>

<style lang="scss" scoped>
.g-input-container {
   position: relative;

   .g-input-text-wrapper {
      position: relative;
      input {
         position: relative;
         display: flex;
         justify-content: flex-start;
         align-items: center;
         border: 1px solid #ccc;
         border-radius: 0.5rem;
         box-sizing: border-box;
         background-color: #f8f8f8;
         height: 48px !important;
         max-height: 100% !important;
         outline: 2px solid transparent;
         width: 100%;
         text-indent: 1rem;
         font-size: var(--normal);
         color: var(--txt-black);
         font-weight: 500;
         pointer-events: none;

         @media (min-width: 992px) {
            pointer-events: auto;
         }

         &:focus {
            border: 1px solid var(--accent-primary) !important;
            outline: 0.25rem solid var(--accent-primary-lightest);
         }
      }

      .g-form-input-actions-wrapper {
         position: absolute;
         padding: 1rem;
         top: 0;
         right: 0;
         bottom: 0;
         margin: auto;
         display: flex;

         .clear-day-button {
            background: none !important;
            margin-right: 0.5rem;
         }

         .g-form-input-datepicker-handler {
            background: none !important;
            display: flex;
            .prev-day-button,
            .next-day-button {
               display: flex;
               padding: 0 0.35rem;
               cursor: pointer;
            }
         }
      }

      &.out-of-range {
         input {
            background: #fcf1f1;
            border: 2px solid #f07c7c;
         }
         i {
            filter: invert(77%) sepia(21%) saturate(7219%) hue-rotate(312deg) brightness(103%) contrast(89%);
         }
      }

      &.in-range {
         input {
            background: #fff;
            border: 2px solid #b1b2b3;
         }
      }
   }
   .g-datepicker-wrapper {
      position: absolute;
      min-width: 312px;
      z-index: 99;
      top: 100%;
      border-radius: 0.5rem;
      overflow: hidden;
      border: 1px solid #eee;
      box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
   }
}
</style>
<style lang="scss">
.datepicker-gts-header {
   display: flex;
   justify-content: space-between;
   align-items: center;
   padding: 0.75rem 1rem;
   margin: 0.125rem 0;

   .month-name {
      background: #3d7ecc;
      color: #fff;
      font-weight: 600;
      font-size: var(--large);
      width: 100%;
      border-radius: 24rem;
      margin: 0 1.5rem;
      text-align: center;
      padding: 0.25rem 0;
   }
   .button-prev,
   .button-next {
      border-radius: 50%;
      outline: 0;
      border: 0;
      width: 26px !important;
      height: 26px !important;
      // background: #ccc;
      display: flex;
      flex-shrink: 0;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      i {
         flex-shrink: 0;
      }
   }
   .button-prev {
      transform: rotate(-180deg);
   }
}
.g-datepicker-wrapper {
   width: 100%;
   // width: 100%;
   // min-width: fit-content;
   // max-width: 90vw;

   @media (min-width: 992px) {
      width: fit-content;
   }

   .dropdown-menu {
      width: 100%;
      @media (min-width: 992px) {
         width: fit-content;
      }
   }
}
</style>
