import store from "@/store/index.js";

export var paxMapper = (paxes, EFltPaxType) => {
   const mapper = {};
   paxes.map((pax) => {
      mapper[pax.id] = {
         paxId: pax.id,
         paxIndex: pax.index,
         paxName: `${pax.name} ${pax.surname}`,
         paxType: EFltPaxType(pax.paxType),
         paxTypeEnum: pax.paxType,
         price: null,
         seat: null,
         cabinType: null,
         selected: false,
         initialSeat: false,
      };
   });
   return mapper;
};

export var paxIdsMapper = (paxes) => {
   const mapper = [];
   paxes.map((pax) => {
      if (pax.parentIndex == 0) {
         mapper.push(pax.id);
      } else {
         store.commit("seatmap/setHasInfant", pax);
      }
   });
   return mapper;
};

export var segmentIdForPaxes = (paxes, segmentId) => {
   return Object.keys(paxes).reduce((acc, key) => {
      acc[key] = {
         ...paxes[key],
         segmentId: segmentId,
         status: true,
      };
      return acc;
   }, {});
};

export var segments = (flights) => {
   const mapper = [];

   Object.keys(flights).forEach((flightKey) => {
      const segments = flights[flightKey].segments;

      Object.keys(segments).forEach((key) => {
         const segment = segments[key];
         mapper.push({
            name: segment.departureAirport + " - " + segment.arrivalAirport,
            index: segment.id,
            status: false,
            departureTime: segment.departureDateTime,
         });
      });
   });

   return mapper;
};

export const seatMapGenerator = (rows, segmentId, EFltSeatLayout) => {
   const seatLayout = EFltSeatLayout.replace("SL_", "");
   const flightType = seatLayout.split("_").map(Number);

   // Mapping for status from modelFromApi to myModel
   const statusMap = {
      0: "unavailable",
      1: "available",
      2: "occupied",
   };

   // Generate seat pattern from A to Z
   const generateSeatPattern = () => {
      const seatPattern = [];
      for (let i = 65; i <= 90; i++) {
         seatPattern.push(String.fromCharCode(i));
      }
      return seatPattern;
   };

   const seatPattern = generateSeatPattern();

   // Function to map seats from modelFromApi to myModel
   const mapSeats = (apiSeats, rowNumber, totalRow) => {
      const totalSeats = flightType.reduce((acc, val) => acc + val, 0);

      return seatPattern.slice(0, totalSeats).map((column) => {
         const seat = apiSeats.find((s) => s.column === column);

         if (seat) {
            return {
               // name: seat.identifier.split("-")[1],
               name: rowNumber + seat.column,
               identifier: seat.identifier,
               column: seat.column,
               type: seat.applicableTypes,
               status: statusMap[seat.status],
               price: seat.price,
               features: [], // Populate this based on your logic
               exitSeat: seat.description == "Exit seat" ? true : false,
               segmentId: segmentId,
               tooltipLocation: totalRow - rowNumber < 3 ? "up" : "down",
            };
         } else {
            return {
               name: "EMPTY",
               column: column,
               type: "BUS",
               status: "available",
               price: null,
               features: [],
               exitSeat: false,
               segmentId: segmentId,
            };
         }
      });
   };

   // Function to convert modelFromApi to myModel
   const convertModel = (apiModels) => {
      return apiModels.map((apiModel) => {
         const seats = mapSeats(apiModel.seats, apiModel.number, apiModels.length);

         // Splitting seats into groups based on flightType
         const seatGroups = [];
         let start = 0;
         for (let groupSize of flightType) {
            seatGroups.push({
               seats: seats.slice(start, start + groupSize),
            });
            start += groupSize;
         }

         function areDescriptionsSame(seats) {
            if (seats.length === 0) return true;
            const firstDescription = seats[0].description;
            for (let i = 1; i < seats.length; i++) {
               if (seats[i].description !== firstDescription) {
                  return false;
               }
            }
            return firstDescription;
         }

         return {
            r: areDescriptionsSame(apiModel.seats),
            rowNumber: apiModel.number,
            class: "Business",
            isExitRow: areDescriptionsSame(apiModel.seats),
            seatGroups: seatGroups,
         };
      });
   };

   return convertModel(rows);
};
