<template>
   <div id="theme-toggler-container" @click="handlerToggleTheme">
      <UITooltip
         :tooltip-title="$t('navbarComponent_switchToDarkMode')"
         tooltip-icon="gts-icon icon-dark-mode icon-sm gts-animation"
         tooltip-position="bottom"
         v-if="true">
      </UITooltip>
      <UITooltip
         :tooltip-title="$t('navbarCompopFlnent_switchToLightMode')"
         tooltip-icon="gts-icon icon-light-mode icon-sm gts-animation"
         tooltip-position="bottom"
         v-else>
      </UITooltip>
   </div>
</template>

<script>
import UITooltip from "@/components/utils/GtsToolTips.vue";
export default {
   name: "ThemeToggler",
   components: {
      UITooltip,
   },
   created() {
      const activeTheme = localStorage.getItem("user-theme");
      if (activeTheme == null) {
         localStorage.setItem("user-theme", "light-theme");
      }
      document.documentElement.className = localStorage.getItem("user-theme");
   },
   methods: {
      handlerToggleTheme() {
         const activeTheme = localStorage.getItem("user-theme");
         if (activeTheme === "light-theme") {
            this.setTheme("dark-theme");
         } else {
            this.setTheme("light-theme");
         }
      },
      setTheme(theme) {
         localStorage.setItem("user-theme", theme);
         document.documentElement.className = theme;
      },
   },
};
</script>

<style></style>
