export default {
   reservations: [
      {
         entityType: null,
         orderUuid: null,
         paxFullName: null,
         provider: null,
         entity: null,
         route: null,
         entityStatus: null,
         optionDateTime: null,
      },
      {
         entityType: null,
         orderUuid: null,
         paxFullName: null,
         provider: null,
         entity: null,
         route: null,
         entityStatus: null,
         optionDateTime: null,
      },
      {
         entityType: null,
         orderUuid: null,
         paxFullName: null,
         provider: null,
         entity: null,
         route: null,
         entityStatus: null,
         optionDateTime: null,
      },
      {
         entityType: null,
         orderUuid: null,
         paxFullName: null,
         provider: null,
         entity: null,
         route: null,
         entityStatus: null,
         optionDateTime: null,
      },
      {
         entityType: null,
         orderUuid: null,
         paxFullName: null,
         provider: null,
         entity: null,
         route: null,
         entityStatus: null,
         optionDateTime: null,
      },
      {
         entityType: null,
         orderUuid: null,
         paxFullName: null,
         provider: null,
         entity: null,
         route: null,
         entityStatus: null,
         optionDateTime: null,
      },
      {
         entityType: null,
         orderUuid: null,
         paxFullName: null,
         provider: null,
         entity: null,
         route: null,
         entityStatus: null,
         optionDateTime: null,
      },
   ],
   upcomings: [
      {
         entityType: null,
         orderUuid: null,
         paxFullName: null,
         provider: null,
         providerType: null,
         entity: null,
         route: null,
         entityStatus: null,
         upcomingDateTime: null,
         onlineCheckInUrl: null,
         vehicleType: null,
         markModel: null,
      },
      {
         entityType: null,
         orderUuid: null,
         paxFullName: null,
         provider: null,
         providerType: null,
         entity: null,
         route: null,
         entityStatus: null,
         upcomingDateTime: null,
         onlineCheckInUrl: null,
         vehicleType: null,
         markModel: null,
      },
      {
         entityType: null,
         orderUuid: null,
         paxFullName: null,
         provider: null,
         providerType: null,
         entity: null,
         route: null,
         entityStatus: null,
         upcomingDateTime: null,
         onlineCheckInUrl: null,
         vehicleType: null,
         markModel: null,
      },
      {
         entityType: null,
         orderUuid: null,
         paxFullName: null,
         provider: null,
         providerType: null,
         entity: null,
         route: null,
         entityStatus: null,
         upcomingDateTime: null,
         onlineCheckInUrl: null,
         vehicleType: null,
         markModel: null,
      },
      {
         entityType: null,
         orderUuid: null,
         paxFullName: null,
         provider: null,
         providerType: null,
         entity: null,
         route: null,
         entityStatus: null,
         upcomingDateTime: null,
         onlineCheckInUrl: null,
         vehicleType: null,
         markModel: null,
      },
      {
         entityType: null,
         orderUuid: null,
         paxFullName: null,
         provider: null,
         providerType: null,
         entity: null,
         route: null,
         entityStatus: null,
         upcomingDateTime: null,
         onlineCheckInUrl: null,
         vehicleType: null,
         markModel: null,
      },
      {
         entityType: null,
         orderUuid: null,
         paxFullName: null,
         provider: null,
         providerType: null,
         entity: null,
         route: null,
         entityStatus: null,
         upcomingDateTime: null,
         onlineCheckInUrl: null,
         vehicleType: null,
         markModel: null,
      },
      {
         entityType: null,
         orderUuid: null,
         paxFullName: null,
         provider: null,
         providerType: null,
         entity: null,
         route: null,
         entityStatus: null,
         upcomingDateTime: null,
         onlineCheckInUrl: null,
         vehicleType: null,
         markModel: null,
      },
      {
         entityType: null,
         orderUuid: null,
         paxFullName: null,
         provider: null,
         providerType: null,
         entity: null,
         route: null,
         entityStatus: null,
         upcomingDateTime: null,
         onlineCheckInUrl: null,
         vehicleType: null,
         markModel: null,
      },
   ],
   reportGiros: [
      {
         entityType: null,
         code: null,
         gross: null,
         reservedEntityCount: null,
         reservedPaxCount: null,
         issuedEntityCount: null,
         issuedPaxCount: null,
         issuedRoomCount: null,
      },
      {
         entityType: null,
         code: null,
         gross: null,
         reservedEntityCount: null,
         reservedPaxCount: null,
         issuedEntityCount: null,
         issuedPaxCount: null,
         issuedRoomCount: null,
      },
      {
         entityType: null,
         code: null,
         gross: null,
         reservedEntityCount: null,
         reservedPaxCount: null,
         issuedEntityCount: null,
         issuedPaxCount: null,
         issuedRoomCount: null,
      },
      {
         entityType: null,
         code: null,
         gross: null,
         reservedEntityCount: null,
         reservedPaxCount: null,
         issuedEntityCount: null,
         issuedPaxCount: null,
         issuedRoomCount: null,
      },
   ],
   uuid: null,
   result: {
      success: null,
      app: null,
      service: null,
      code: null,
      subCode: null,
      message: null,
   },
   id: null,
   ids: null,
};
