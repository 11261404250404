<template>
   <div id="passenger-informations-wrapper" class="gts-box with-border">
      <div id="validation-error-auto-scroller"></div>

      <div class="passenger-informations-header-wrapper">
         <span class="section-title">{{ $t("flightPassengerInformationsComponent_passengerInformations") }}</span>

         <div id="autofill-wrapper" v-if="ShowAutoFill">
            <span class="autofill-highlighter"> {{ $t("flight_fillPaxRowsTooltipHighlighter") }}</span>
            <button id="fill-form" @click="handlerShowModal">
               {{ $t("flight_fillPaxRowsTooltip") }}
            </button>
            <button id="clear-form" @click="handlerClearForm">
               <i class="gts-icon icon-hotel-empty-basket icon-sm"></i>
            </button>
         </div>
      </div>

      <template v-for="(pax, index) in paxes">
         <PaxRow :model="pax" :key="index" @active-row="handlerSetActiveRow" />
      </template>

      <UIModal v-show="showModal" :noBorder="true" :hasSlot="true" @close-modal="showModal = false">
         <div class="pax-data-form">
            <div class="transfer-manuel-request-info-wrapper col-12">
               <div class="pax-data-form__title">
                  <i class="gts-icon icon-popup-cursor"></i>
                  <h3 class="txt-black">{{ $t("flight_fillPaxRowsTtitle") }}</h3>
               </div>
               <div class="pax-data-form__content">
                  <p>{{ $t("flight_fillPaxRowsContent") }}</p>
                  <div class="pax-data-form__info-wrapper">
                     <div class="pax-data-form__infos --side-bg"></div>
                     <div
                        class="pax-data-form__pax-info"
                        v-for="(paxInfo, index) in $store.state.flight.cookieData.passengers"
                        :key="paxInfo + index">
                        <span class="label-title">{{ $t("flight_paxInfo") }}</span>
                        <div class="sub-content">
                           <span class="label-title">{{ $t("flight_paxNameSurname") }}</span>
                           <span> {{ paxInfo.name }} - {{ paxInfo.surname }} </span>
                        </div>
                        <div class="sub-content">
                           <span class="label-title">{{ $t("flight_paxBirthDate") }}</span>
                           <span>
                              {{ paxInfo.birthdate | FormatterDate }}
                           </span>
                        </div>
                        <div class="sub-content">
                           <span class="label-title">{{ $t("flight_paxType") }}</span>
                           <span>
                              {{ paxInfo.paxType | EFltPaxType }}
                           </span>
                        </div>
                     </div>
                  </div>
               </div>
               <div class="pax-data-form__cta">
                  <UIButton rounded className="accent-primary_outline" :text="$t('flight_fillPaxRowsCancelBtn')" @click.native="showModal = false" />
                  <UIButton rounded className="accent-primary" :text="$t('flight_fillPaxRows')" @click.native="fillPaxRows" />
               </div>
            </div>
         </div>
      </UIModal>
   </div>
</template>

<script>
import PaxRow from "@/components/structural/flight/checkout/passenger/PaxRow.vue";
import UIModal from "@/components/unitary/form2/UIModal.vue";
import UIButton from "@/components/unitary/Button.vue";
import moment from "moment";
import { setCookie } from "@/utils.js";
import Popup from "@/gts-popup.js";
moment;
export default {
   name: "PassengerInformations",
   components: {
      PaxRow,
      UIModal,
      UIButton,
   },
   data() {
      return {
         paxes: [],
         showModal: false,
         activePaxRowIndex: false,
      };
   },
   computed: {
      ShowAutoFill() {
         return this.$store.state.flight.cookieData.passengers;
      },
   },
   methods: {
      handlerSetActiveRow(model) {
         // console.log("handlerSetActiveRow");
         this.activePaxRowIndex = model.paxIndex - 1;
      },
      fillPaxRows() {
         this.$store.commit("flight/setCheckoutPassengersFromCookie", this.$store.state.flight.cookieData.passengers);
         this.$store.commit("flight/setCheckoutPassenger", this.$store.state.flight.cookieData.passengers[0]);
         this.showModal = false;
      },
      handlerShowModal() {
         this.showModal = true;
      },
      handlerClearForm() {
         setCookie("paxData", "");
         this.$store.state.flight.cookieData.passengers = null;
      },
   },
   watch: {
      "$store.state.flight.checkout.request.passengers": {
         handler: function (passengers) {
            const self = this;
            if (passengers != undefined || passengers != null) {
               self.paxes.length = 0;

               const birthDateControl = function (birthdate, paxType) {
                  paxType;

                  if (birthdate) {
                     return moment(birthdate).format(self.$constants.dateFormat);
                  } else {
                     return null;
                  }
               };

               const passportControl = (obj) => {
                  if (obj == null) {
                     return {
                        country: null,
                        expiry: null,
                        number: null,
                        serial: null,
                     };
                  } else {
                     return {
                        country: obj.country,
                        expiry: obj.expiry != null ? moment(obj.expiry).format("YYYY-MM-DD") : null,
                        number: obj.number,
                        serial: obj.serial,
                     };
                  }
               };

               passengers.forEach((passenger, index) => {
                  const rowObj = {
                     paxIndex: index + 1,
                     paxType: passenger.paxType,
                     gender: passenger.gender == 0 ? 1 : passenger.gender,
                     birthdate: birthDateControl(passenger.birthdate, passenger.paxType),
                     name: passenger.name,
                     surname: passenger.surname,
                     nationality: "DE", // default nationality selection
                     nationalIdentity: passenger.nationalIdentity,
                     passport: passportControl(passenger.passport),
                     hesCode: passenger.hesCode,
                     loyaltyCards: passenger.loyaltyCards,
                     email: passenger.email ? passenger.email : null,
                     phone: passenger.phone ? passenger.phone : null,
                  };

                  this.paxes.push(rowObj);
               });
            }
         },
         immediate: true,
         deep: true,
      },
      ShowAutoFill: {
         handler: function (status) {
            if (status) {
               setTimeout(() => {
                  document.querySelector(".autofill-highlighter").classList.add("d-none");
               }, 8000);
            }
         },
         immediate: true,
      },
      paxes: {
         handler: function (paxes) {
            // Helpers
            const uniquePaxIdentifier = (pax) => {
               return pax.name + pax.surname + pax.birthdate;
            };
            const hasDuplicates = (arr) => {
               return arr.length !== new Set(arr).size;
            };

            const isFormInteracted = paxes.some((pax) => {
               return pax.name != null && pax.name != "";
            });

            //Helpers End

            const paxArr = [];
            paxes.forEach((pax) => {
               if (uniquePaxIdentifier(pax) != "null") {
                  paxArr.push(uniquePaxIdentifier(pax));
               }
            });

            if (hasDuplicates(paxArr) && isFormInteracted) {
               Popup.warning(this.$i18n.t("Popup_lblEmptySearch"), this.$i18n.t("Popup_txtDuplicatedPassenger"));
               this.paxes[this.activePaxRowIndex].name = "";
               this.paxes[this.activePaxRowIndex].surname = "";
               this.paxes[this.activePaxRowIndex].birthdate = null;
            }
         },
         deep: true,
      },
   },
};
</script>

<style lang="scss" scoped>
#passenger-informations-wrapper {
   position: relative;

   .passenger-informations-header-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 1.5rem;

      .section-title {
         margin-bottom: 0 !important;
      }
      #autofill-wrapper {
         display: flex;
         justify-content: flex-end;
         // background-color: red;
         position: relative;

         @keyframes tooltip-top {
            0% {
               transform: translateY(-10px);
            }
            100% {
               transform: translateY(0);
            }
         }

         .autofill-highlighter {
            animation-timing-function: ease-in-out;
            animation-duration: 1s;
            animation-iteration-count: infinite;
            animation-name: tooltip-top;
            position: absolute;
            background-color: var(--accent-primary);
            bottom: 110%;
            left: 0;
            right: 0;
            margin: auto;
            width: fit-content;
            padding: 0.5rem 1.125rem;
            border-radius: 0.25rem;
            color: #fff;
            font-weight: 500;
            z-index: 999;

            &::after {
               content: "";
               position: absolute;
               bottom: -5px;
               left: 0;
               right: 0;
               margin: auto;
               width: 10px;
               height: 10px;
               transform: rotate(45deg);
               background-color: red;
               background-color: var(--accent-primary);
            }
         }

         button {
            border-radius: 0.5rem;

            &#fill-form {
               background: #0ce2a3;
               padding: 0.5rem 1rem;
               color: #ffffff;
               font-weight: 600;
               margin-right: 0.5rem;

               &:hover {
                  background: #2a3a4d;
               }
            }
            &#clear-form {
               background-color: transparent !important;
               border: 2px solid #cecece;
               padding-left: 0.5rem;
               padding-right: 0.5rem;

               &:hover {
                  background-color: #2a3a4d !important;
                  border: 2px solid #2a3a4d;

                  i {
                     filter: brightness(10);
                     opacity: 1;
                  }
               }

               i {
                  filter: brightness(0);
                  opacity: 0.6;
                  width: 18px !important;
               }
            }
         }
      }
   }

   .z-2 {
      z-index: 2 !important;
   }

   .pax-wrapper {
      background: #f8f8f8;
      border: 1px solid #ccc;
      position: relative;
      min-height: 50px;
      border-radius: 24px;
      padding: 1rem 2rem;
      margin: 2rem 0rem;
      padding-top: 1.75rem;

      .pax-paxtype {
         position: absolute;
         top: -1rem;
         left: 1rem;
         background: #fff;
         border: 1px solid #ccc;
         border-radius: 16px;
         padding: 0.25rem 1rem;
         font-size: var(--small);
         font-weight: 600;
      }
   }
   .pax-data-form {
      padding: 1rem;
      width: 100%;
      &__title {
         text-align: center;
         color: #1e2023;
         font-size: var(--xlarge);
         letter-spacing: 0px;
         i {
            margin: auto;
            width: 114px !important;
            height: 79.06px !important;
         }
      }
      &__content {
         text-align: center;
         font-size: var(--small);
         color: #6d6e6f;
         letter-spacing: 0.22px;
         margin-top: 0.5rem;
      }
      &__info-wrapper {
         background: #f7f7f7 0% 0% no-repeat padding-box;
         border: 1px solid #ebebeb;
         border-radius: 16px;
         padding: 20px;
         position: relative;
         margin-top: 0.5rem;
         &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 16px; /* adjust as needed */
            height: 100%;
            background: #ebebeb 0% 0% no-repeat padding-box;
            border-radius: 8px 0px 0px 8px;
         }
         &.--side-bg {
            width: 16px;
            height: 100%;
            padding: 0;
         }
      }
      &__cta {
         margin-top: 1rem;
         display: flex;
         justify-content: space-evenly;
         .gts-btn {
            height: 40px;
         }
      }
      &__pax-info {
         display: flex;
         flex-direction: column;
         padding-left: 24px;
         margin-top: 20px;
         align-items: flex-start;
         border-bottom: 1px dashed #6d6e6f;
         padding-bottom: 18px;
         .label-title {
            font-weight: bold;
            font-size: var(--normal);
            color: #363636;
         }
         .sub-content {
            margin-top: 8px;
         }
         .text {
            color: #363636;
            font-size: var(--small);
            letter-spacing: 0;
         }
         &:last-child {
            border-bottom: none;
            padding-bottom: 0;
         }
      }
   }
}
</style>
