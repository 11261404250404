<template>
    <div class="gts-document-viewer-wrapper document-backdrop">
        <div class="gts-document-viewer-inner">
            <div class="document-tabs-section">
                <ul class="document-tab-list nav nav-tabs" id="tab_documentContent" role="tablist">
                    <li class="document-tab-list-item nav-item" role="presentation">
                        <a class="nav-link active" id="pnr-tab" data-bs-toggle="tab" href="#pnr-HXUHA4" role="tab" aria-controls="#pnr-HXUHA4" aria-selected="true">HXUHA4</a>
                    </li>
                    <li class="document-tab-list-item nav-item" role="presentation">
                        <a class="nav-link" id="pnr-tab" data-bs-toggle="tab" href="#pnr-WQTK9W" role="tab" aria-controls="#pnr-WQTK9W" aria-selected="true">WQTK9W</a>
                    </li>
                </ul>
            </div>
            <div class="document-content-section">
                <span class="document-close-button"><i class="gts-icon icon-close-rounded"></i></span>
                <div class="document-tab-content-wrapper tab-content" id="tabPane_documentContent">
                    <div class="document-tab-content-item tab-pane fade show active" id="pnr-HXUHA4" role="tabpanel" aria-labelledby="pnr-tab">
                        <p>HXUHA4</p>
                    </div>
                    <div class="document-tab-content-item tab-pane fade" id="pnr-WQTK9W" role="tabpanel" aria-labelledby="pnr-tab">
                        <p>WQTK9W</p>
                    </div>
                </div>
                <div class="document-cta-wrapper">
                    <div class="document-cta-inner">
                        <div class="document-cta-row price-switch"><p>price switch</p></div>
                        <div class="document-cta-row share-group"><p>share-group</p></div>
                        <div class="document-cta-row email-share"><p>email-share</p></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'GtsDocumentViewer'
}
</script>

<style lang="scss" scoped>
    .gts-document-viewer-wrapper{
        position: fixed;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        top: 0;
        left: 0;
        z-index: 1;

        &.document-backdrop{
            background: rgba($color: #000000, $alpha: 0.7);
        }

        .gts-document-viewer-inner{
            width: 50%;
            .document-tabs-section{
                .document-tab-list{
                    padding: 0;
                    padding-left: 30px;
                    margin: 0;
                    border: none;
                    .document-tab-list-item{
                        margin-right: 8px;

                        &:last-child{ margin-right: 0; }

                        a.nav-link{
                            padding: 8px 16px;
                            background-color: #B5B5B5;
                            border: none;
                            border-radius: 8px 8px 0 0;
                            color: white;
                            font-size: 14px;
                            font-weight: 700;

                            &.active{
                                background-color: #3E6FB3;    
                                color: white;
                            }
                        }
                    }
                }
            }
            .document-content-section{
                position: relative;
                padding: 30px;
                background-color: white;
                border: 1px solid #C9C9C9;
                border-radius: 12px;

                .document-tab-content-wrapper{
                    padding: 16px;
                    border: 1px solid #C9C9C9;
                    border-radius: 12px;

                    .document-tab-content-item{}
                }
                .document-cta-wrapper{
                    position: absolute;
                    top: 0;
                    right: -85px;
                    background-color: #3E6FB3;
                    border-radius: 0 8px 8px 0;

                    .document-cta-inner{
                        padding: 16px;
                        .document-cta-row{
                            &.price-switch{}
                            &.share-group{}
                            &.email-share{}
                        }
                    }
                }
                .document-close-button{
                    position: absolute;
                    top: 0;
                    right: 0;
                }
            }
        }
    }
</style>