<template>
   <section class="transfer-order-detail-section traveler-information-wrapper">
      <div class="traveler-information-inner">
         <div class="transfer-order-detail-section-title">
            <h4>{{ $t("orderDetail_lblTravelerInfoTitle") }}</h4>
         </div>
         <div class="transfer-order-detail-section-content">
            <div class="traveler-information-summary-wrapper transfer-order-detail-inner-section">
               <div class="traveler-information-summary-inner">
                  <div class="traveler-information-table-wrapper">
                     <div class="traveler-information-table-inner">
                        <div class="traveler-information-table-header">
                           <div class="table-header-list">
                              <span class="txt-black d-none d-lg-block">#</span>
                              <span>{{ $t("orderDetail_lblName") }}</span>
                              <span>{{ $t("orderDetail_lblGuestType") }}</span>
                              <span>{{ $t("orderDetail_lblGender") }}</span>
                           </div>
                        </div>
                        <div class="traveler-information-table-content">
                           <div class="traveler-information-room-item">
                              <div class="table-content-list">
                                 <ul class="traveler-information-list default-list">
                                    <li class="traveler-information-list-item" v-for="(travelerItem, index) in travelersList" :key="index">
                                       <span class="font-bold masked d-none d-lg-block">{{ index + 1 }}</span>
                                       <span v-if="!EditMode" class="masked">{{ travelerItem.name }} {{ travelerItem.surname }}</span>
                                       <div class="reissue-inputs" v-else>
                                          <UIInput v-model="travelerItem.name" @input="handlerTravelerInfo" />
                                          <UIInput v-model="travelerItem.surname" @input="handlerTravelerInfo" />
                                       </div>
                                       <span class="masked">{{ travelerItem.type | ETrfTravelerType }}</span>
                                       <span class="masked">{{ travelerItem.gender | EComGender }}</span>
                                    </li>
                                 </ul>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </section>
</template>

<script>
import UIInput from "@/components/unitary/form2/UIInput.vue";

export default {
   name: "TravelerInformation",
   props: {
      travelers: Array,
   },
   components: { UIInput },
   computed: {
      EditMode() {
         return this.$store.state.transfer.editMode;
      },
   },
   data() {
      return {
         travelersList: this.travelers,
      };
   },
   methods: {
      handlerTravelerInfo() {
         let updatedTransfersList = this.$store.state.transfer.reissue.transfers.map((transfer) => {
            transfer.travelers = this.travelersList.map((traveler) => {
               traveler.name = traveler.name.toUpperCase();
               traveler.surname = traveler.surname.toUpperCase();
               return traveler;
            });
            return transfer;
         });
         this.$store.commit("transfer/updateReissueTransfersList", updatedTransfersList);
      },
   },
   watch: {
      travelers: {
         handler: function (val) {
            this.travelersList = val;
         },
      },
   },
};
</script>

<style lang="scss" scoped>
.traveler-information-wrapper {
   .traveler-information-inner {
      .transfer-order-detail-section-title {
         margin-bottom: 24px;
         & > h4 {
            font-weight: 700;
         }
      }
      .transfer-order-detail-section-content {
         .traveler-information-summary-wrapper {
            .traveler-information-summary-inner {
               .traveler-information-table-wrapper {
                  .traveler-information-table-inner {
                     display: block;
                     border-radius: 10px;
                     overflow: hidden;
                     .traveler-information-table-header {
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;

                        @media (min-width: 992px) {
                           padding: 16px 42px;
                        }

                        span {
                           font-size: 16px;
                           color: #363940;
                           font-weight: bold;
                        }
                        .table-header-fixed-item {
                           width: 15%;
                           max-width: 15%;
                           margin-right: 24px;
                        }
                        .table-header-list {
                           display: grid;
                           grid-template-columns: repeat(2, 1fr) 0.75fr;
                           width: 100%;

                           @media (min-width: 992px) {
                              padding: 0 32px;
                              grid-template-columns: repeat(4, 1fr);
                           }
                        }
                     }
                     .traveler-information-table-content {
                        .traveler-information-room-item {
                           display: flex;
                           justify-content: flex-start;
                           align-items: stretch;

                           background-color: white;
                           border-bottom: 1px solid #c4c4c4;

                           @media (min-width: 992px) {
                              padding: 16px 42px;
                           }

                           &:last-child {
                              border-bottom: none;
                           }
                           .table-content-fixed-item {
                              width: 15%;
                              max-width: 15%;
                              margin-right: 24px;
                              border-right: 1px solid #bfbfbf;

                              & > span {
                                 display: inline-flex;
                                 justify-content: flex-start;
                                 align-items: center;
                                 width: 100%;
                                 height: 100%;
                                 font-weight: 700;
                              }
                           }
                           .table-content-list {
                              width: 100%;

                              .traveler-information-list {
                                 .traveler-information-list-item {
                                    display: grid;
                                    grid-template-columns: repeat(2, 1fr) 0.75fr;

                                    align-items: center;
                                    border-radius: 20px;
                                    margin-bottom: 8px;
                                    padding: 0.5rem 0;
                                    border: 1px solid #dedede;

                                    @media (min-width: 992px) {
                                       grid-template-columns: repeat(4, 1fr);
                                       padding: 10px 32px;
                                    }

                                    .reissue-inputs {
                                       width: 67%;

                                       /* .g-input-container {
                                          .gts-input-wrapper {
                                             &:nth-child(1) {
                                                margin-top: 0.75rem;
                                             }
                                          }
                                       } */
                                    }

                                    span {
                                       color: #363940;
                                       &:first-child {
                                          font-weight: bold;
                                          font-size: 24px;
                                       }
                                       &:nth-child(2) {
                                          font-weight: bold;
                                       }
                                    }

                                    &:last-child {
                                       margin-bottom: 0;
                                    }

                                    &:nth-child(odd) {
                                       background-color: #f5f5f5;
                                       border-color: #f5f5f5;
                                    }
                                    &:nth-child(even) {
                                       background-color: white;
                                       border-color: #e2e2e2;
                                    }

                                    & > span:last-child {
                                       display: inline-flex;
                                       justify-content: flex-start;
                                    }
                                 }
                              }
                           }
                        }
                     }
                  }
               }
            }
         }
      }
   }
}
</style>
