<template>
   <div class="hotel-checkout-basket-wrapper">
      <div class="hotel-checkout-basket-wrapper gts-box with-border">
         <div class="hotel-checkout-basket-inner">
            <!-- {{ checkoutBasket }} -->
            <div class="hotel-checkout-basket-inner-wrapper">
               <div class="hotel-checkout-basket-title">
                  <span class="title">{{ checkoutBasket.hotelInformation.name }}</span>
                  <div class="hotel-checkout-basket-hotel-info">
                     <div class="hotel-star-list">
                        <i
                           v-for="(item, index) in checkoutBasket.hotelInformation.star"
                           :key="'activeStar-' + index"
                           class="gts-icon icon-star active"></i>
                        <i v-for="(item, index) in 5 - checkoutBasket.hotelInformation.star" :key="'star-' + index" class="gts-icon icon-star"></i>
                     </div>
                     <span>{{ checkoutBasket.hotelInformation.address }}</span>
                  </div>
               </div>
               <div class="hotel-checkout-basket-content">
                  <div class="checkout-basket-room-list">
                     <div class="basket-room-list-item" v-for="(checkoutRoomItem, index) in checkoutBasket.rooms" :key="'r-' + index">
                        <!-- ROOM ITEM NEW -->
                        <div class="room-item-inner">
                           <span class="room-count">{{ $t("hotelCheckoutBasket_txtRoom").replace("#VALUE#", convertToRoman(index + 1)) }}</span>
                           <span class="room-name">
                              {{ checkoutRoomItem.roomName }}
                           </span>

                           <div class="price-and-date-infos">
                              <span class="room-date">{{ checkoutBasket.diffDate }} {{ $t("hotelCheckoutBasket_txtNight") }}</span>
                              <span class="room-price">{{ checkoutRoomItem.price.total | price(checkoutRoomItem.price.currency) }} </span>
                           </div>
                           <div v-for="(addOn, index) in checkoutRoomItem.addOns" :key="index">
                              <div class="addon-price-and-infos" v-if="addOn.selected">
                                 <span class="addon-title">{{ addOn.title }}</span>
                                 <div class="addon-price-and-date">
                                    <span class="addon-date">{{
                                       addOn.effectiveDate.split("T")[0].split("-").reverse().join("-").replace("-", ".")
                                    }}</span>
                                    <span class="addon-price">{{ $options.filters.price(addOn.price, addOn.currency) }}</span>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div class="checkout-basket-total-price" v-if="checkoutBasket.totalAmount">
                     <div class="basket-total-price-inner">
                        <!-- <div class="total-price-title">
                           <span>ödenecek tutar</span>
                        </div> -->
                        <div class="total-price-info">
                           <span class="price-grand-total">{{ $t("hotelBasket_lblGrandTotal") }}</span>
                           <span class="discount-price" v-if="checkoutBasket.totalAmount.oldAmount != 0"
                              ><s>{{ checkoutBasket.totalAmount.oldAmount }}</s></span
                           >
                           <span class="total-price">{{ checkoutBasket.totalAmount.grandTotal | price(checkoutBasket.totalAmount.currency) }}</span>
                        </div>
                     </div>
                  </div>
               </div>

               <div class="hotel-checkout-basket-footer" v-if="boardType">
                  <span class="board-type-title">
                     {{ $t("hotelCheckoutBasket_txtBoardType") }}
                  </span>
                  <span class="board-type"> {{ boardType }}</span>
               </div>
            </div>
         </div>
      </div>
      <GtsInfoWidget v-if="isPartialWidgetVisible" :icon="'gts-icon icon-partial-payment-shadow'" :bgColor="'bg-white'" slotable>
         <template #event-content>
            <div id="widget-content">
               <span v-html="partialPaymentInfoText"></span>
               <UIToggle size="toggle-S" @toggleChange="toggleChange" :isChecked="isPartialPaymentChecked" />
            </div>
         </template>
      </GtsInfoWidget>
   </div>
</template>

<script>
import GtsInfoWidget from "@/components/utils/GtsInfoWidget.vue";
import UIToggle from "@/components/unitary/form2/UIToggle.vue";
export default {
   name: "HotelCheckoutBasket",
   components: {
      GtsInfoWidget,
      UIToggle,
   },
   computed: {
      partialPaymentInfoText() {
         var infoTextStyled =
            "<span style='font-weight:600'>" +
            this.$options.filters.price(this.partialPaymentModel.firstPaymentPrice, this.partialPaymentModel.currency) +
            "</span>";
         return this.$t("hotelCheckoutBasket_partialPaymentControl").replace("#VALUE#", infoTextStyled);
      },
      checkoutBasket() {
         return this.$store.state.hotel.basketResponse;
      },
   },
   props: {
      partialPaymentModel: { type: Object },
      isPartialPaymentChecked: Boolean,
      isPartialWidgetVisible: Boolean,
   },
   data() {
      return {
         boardType: null,
         partialPaymentControl: this.isPartialPaymentChecked,
      };
   },
   methods: {
      convertToRoman(num) {
         const roman = [
            "I",
            "II",
            "III",
            "IV",
            "V",
            "VI",
            "VII",
            "VIII",
            "IX",
            "X",
            "XI",
            "XII",
            "XIII",
            "XIV",
            "XV",
            "XVI",
            "XVII",
            "XVIII",
            "XIX",
            "XX",
         ];
         return roman[num - 1] || num;
      },
      toggleChange(val) {
         this.partialPaymentControl = val;
         this.$emit("basketPartialPaymentControl", val);
      },
   },
   watch: {
      checkoutBasket(newValue) {
         if (newValue) {
            this.boardType = newValue.rooms[0].board;
         }
      },
   },
};
</script>

<style lang="scss" scoped>
#widget-content {
   display: flex;
   flex-direction: column;
   justify-content: flex-start;
   span {
      overflow-wrap: anywhere;
      padding: 0.5rem 0 1rem 0.25rem;
      font-size: 14px;
   }
}
.hotel-checkout-basket-wrapper {
   padding: 0 !important;
   overflow: hidden;
   position: sticky;
   top: 1.5rem;
   border-radius: 1.25rem !important;

   .hotel-checkout-basket-inner {
      .hotel-checkout-basket-inner-wrapper {
         display: flex;
         flex-direction: column;
         width: 100%;
         height: 100%;

         .hotel-checkout-basket-title {
            display: inline-flex;
            align-items: flex-start;
            // justify-content: space-between;
            justify-content: flex-start;
            flex-wrap: wrap;
            width: 100%;
            padding: 16px;
            padding-top: 1.5rem;
            padding-bottom: 16px;

            & > span.title {
               margin-bottom: 0 !important;
               font-size: 1.35rem;
               font-weight: 700;
               color: #363940;
            }

            & > .hotel-checkout-basket-hotel-info {
               width: 100%;
               display: flex;
               justify-content: space-between;
               align-items: center;
               flex-wrap: wrap;
               border-bottom: 2px solid #cccccc;
               padding-bottom: 1rem;

               & > .hotel-star-list {
                  display: flex;
                  position: relative;
                  top: -0.25rem;
                  i {
                     width: 16px !important;
                     margin-right: 4px;
                     opacity: 0.3;

                     &:last-child {
                        margin-right: 0;
                     }
                  }
                  i.active {
                     opacity: 1;
                     filter: invert(74%) sepia(100%) saturate(805%) hue-rotate(358deg) brightness(103%) contrast(103%);
                  }
               }
               & > span {
                  width: 100%;
                  font-size: var(--small);
                  color: var(--txt-gray);
                  text-decoration: underline;
               }
            }
         }
         .hotel-checkout-basket-content {
            padding: 16px;

            .checkout-basket-room-list {
               .basket-room-list-item {
                  margin-bottom: 1rem;

                  &:not(:first-child) {
                     padding-top: 1rem;
                     border-top: 1px dashed #cccccc;
                  }
                  &:last-child {
                     margin-bottom: 0;
                  }

                  .room-item-inner {
                     display: flex;
                     flex-direction: column;
                     span.room-count {
                        font-size: var(--large);
                        font-weight: 600;
                     }
                     span.room-name {
                        font-size: var(--small);
                        font-weight: 500;
                     }
                     .price-and-date-infos {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        font-weight: 600;
                        span.room-date {
                           font-size: var(--small);
                        }
                        span.room-price {
                           font-size: var(--large);
                        }
                     }
                  }
                  .addon-price-and-infos {
                     display: flex;
                     flex-direction: column;
                     background: #fff2fe;
                     border-radius: 4px;
                     padding: 0.75rem;
                     margin: 0.25rem -0.25rem 0 -0.25rem;
                     // margin-top: 0.25rem;
                     // margin-left: -0.25rem;
                     // margin-right: -0.25rem;
                     span {
                        color: #ff21fd;
                        font-weight: 400;
                        font-size: 14px;
                     }
                     .addon-price-and-date {
                        display: flex;
                        justify-content: space-between;
                        // flex-direction: column;

                        .addon-price {
                           justify-content: flex-end;
                           color: #ff21fd;
                           font-weight: 600;
                           text-align: end;
                        }
                     }
                  }
               }
            }
            .checkout-basket-total-price {
               margin: 24px 0 16px 0;

               .basket-total-price-inner {
                  display: flex;
                  flex-direction: column;
                  justify-content: flex-start;
                  align-items: flex-end;
                  text-align: right;

                  .total-price-title {
                     span {
                        color: #3e6fb3;
                     }
                  }
                  .total-price-info {
                     display: flex;
                     flex-direction: column;
                     margin-top: 1rem;
                     .price-grand-total {
                        font-size: 18px;
                        color: #2e67b8;
                        font-weight: 600;
                     }
                     .discount-price {
                        font-size: 18px;
                        color: #2e67b8;
                     }
                     .total-price {
                        font-size: 24px;
                        font-weight: 700;
                        color: #2e67b8;
                     }
                  }
               }
            }
         }
         .hotel-checkout-basket-footer {
            display: flex;
            flex-direction: column;
            padding: 1rem;
            background-color: var(--primary);
            span {
               color: #fff;

               &.board-type-title {
                  color: var(--c-primary);
                  font-weight: 600;
                  font-size: var(--small);
               }
               &.board-type {
                  color: var(--c-primary);
                  font-weight: 600;
                  font-size: var(--normal);
               }
            }
         }
         .hotel-checkout-basket-cta {
            display: flex;
            align-items: center;
            padding: 16px;
            background-color: #f4f4f4;
            border-radius: 0 0 12px 12px;

            & > i {
               width: 64px !important;
               margin-right: 16px;
               font-size: 32px;
               filter: invert(27%) sepia(49%) saturate(0%) hue-rotate(231deg) brightness(92%) contrast(86%);
            }
            & > p {
               margin: 0;
               font-size: 14px;
               color: #4e4e4e;
            }
         }
      }
   }
}
</style>
