//STRING PROTOTYPES
String.prototype.trimmer = function (max) {
   return this?.length > max ? `${this.substring(0, max)}..` : this;
};
String.prototype.alphabetic = function () {
   return this.toUpperCase().clearTurkishChars().clearNonLatinChars().replace(/\s\s+/g, " ");
};
String.prototype.numeric = function () {
   return this.onlyNumbers().clearSpaces();
};
String.prototype.alphanumeric = function () {
   return this.toUpperCase().onlyAlphaNumerics().clearSpaces();
};

// STRING PROTOTYPES HELPERS
String.prototype.clearTurkishChars = function () {
   return this.replace(/Ğ/g, "G").replace(/Ü/g, "U").replace(/Ş/g, "S").replace(/İ/g, "I").replace(/Ö/g, "O").replace(/Ç/g, "C");
};
String.prototype.clearNonLatinChars = function () {
   return this.replace(/[^A-Z\s]/g, "");
};
String.prototype.emailControl = function () {
   return this.replace(/[^0-9a-z@._-]/gi, "");
};
String.prototype.clearSpaces = function () {
   return this.replace(/^\s+|\s+$/g, "");
};
String.prototype.clearAllSpaces = function () {
   return this.replace(/\s/g, "");
};
String.prototype.onlyNumbers = function () {
   return this.replace(/[^0-9\s]/g, "");
};
String.prototype.onlyAlphaNumerics = function () {
   return this.replace(/[^0-9a-z]/gi, "");
};

export default {
   patternMatch({ input, template }) {
      try {
         let j = 0;
         let plaintext = "";
         let countj = 0;
         while (j < template.length) {
            // code block to be

            if (countj > input.length - 1) {
               template = template.substring(0, j);
               break;
            }

            if (template[j] == input[j]) {
               j++;
               countj++;
               continue;
            }

            if (template[j] == "x") {
               template = template.substring(0, j) + input[countj] + template.substring(j + 1);
               plaintext = plaintext + input[countj];
               countj++;
            }
            j++;
         }

         return template;
      } catch {
         return "";
      }
   },
   emailValidation(email) {
      var regx = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/;
      return regx.test(email);
   },
   tcknValidation(value) {
      value = value.toString();
      var isEleven = /^[0-9]{11}$/.test(value);
      var totalX = 0;
      for (var i = 0; i < 10; i++) {
         totalX += Number(value.substr(i, 1));
      }
      var isRuleX = totalX % 10 == value.substr(10, 1);
      var totalY1 = 0;
      var totalY2 = 0;
      for (let i = 0; i < 10; i += 2) {
         totalY1 += Number(value.substr(i, 1));
      }
      for (let i = 1; i < 10; i += 2) {
         totalY2 += Number(value.substr(i, 1));
      }
      var isRuleY = (totalY1 * 7 - totalY2) % 10 == value.substr(9, 0);
      return isEleven && isRuleX && isRuleY;
   },
};
