<template>
   <section class="rent-a-car-order-detail-section rnr-information-wrapper">
      <div class="rnr-information-inner">
         <div class="rnr-information-header-wrapper">
            <span class="section-title">{{ $t("orderDetail_lblRentACarInfoTitle") }}</span>

            <div class="pdf-action-buttons-wrapper">
               <template v-if="rnr.status == 3">
                  <button id="document-provider-booking-docs" :class="rnrProviderLoading ? 'loading' : ''" @click="handlerViewProviderBookingDoc">
                     <span>{{ $t("orderDetail_btnRnrProviderBookingDoc") }}</span>
                     <span class="g-radial-loader" v-if="rnrProviderLoading"></span>
                     <i class="gts-icon icon-eticket" v-else></i>
                  </button>
                  <!-- <button id="document-provider-docs" :class="rnrLoading ? 'loading' : ''" @click="handlerViewProviderDoc">
                     <span>{{ $t("orderDetail_btnRnrProvider") }}</span>
                     <span class="g-radial-loader" v-if="rnrLoading"></span>
                     <i class="gts-icon icon-eticket" v-else></i>
                  </button> -->
                  <button id="document-rnr" :class="rnrLoading ? 'loading' : ''" @click="handlerDocumentViewer(9)">
                     <span>{{ $t("orderDetail_btnRnr") }}</span>
                     <span class="g-radial-loader" v-if="rnrLoading"></span>
                     <i class="gts-icon icon-eticket" v-else></i>
                  </button>
               </template>
            </div>
         </div>
         <div class="rent-a-car-order-detail-section-content">
            <RentACarInformationSummary :rnr="rnr" :total="total" />
         </div>
      </div>
   </section>
</template>

<script>
import RentACarInformationSummary from "@/components/structural/rentacar/order-detail/RentACarInformationSummary.vue";

export default {
   name: "RentACarInformation",
   components: {
      RentACarInformationSummary,
   },
   props: {
      rnrId: Number,
      orderId: Number,
      rnr: Object,
      total: Object,
      driver: Object,
      pageLoad: Boolean,
   },
   data() {
      return {
         rnrLoading: false,
         rnrProviderLoading: false,
      };
   },
   methods: {
      handlerViewProviderDoc() {
         this.rnrProviderLoading = true;
         window.open(this.rnr.providerDocuments[0].link, "_blank");
         this.rnrProviderLoading = false;
      },
      handlerViewProviderBookingDoc() {
         this.rnrProviderLoading = true;
         window.open(this.rnr.providerDocuments[1].link, "_blank");
         this.rnrProviderLoading = false;
      },
      handlerDocumentViewer(type) {
         const self = this;
         self.rnrLoading = true;
         self.$store.state.app.documentViewer.request.showPrice = false;
         self.$store.state.app.documentViewer.hasPrice = true;
         self.$store.state.app.documentViewer.request.documentType = type;
         self.$store.state.app.documentViewer.request.fileType = 2;
         self.$store.state.app.documentViewer.activeProductStatus = self.rnr.status;
         self.$store.commit("app/setDocumentViewer", true);
      },
   },
   watch: {
      "$store.state.app.documentViewer.isLoaded": {
         handler: function (status) {
            if (status) {
               this.rnrLoading = false;
            }
         },
      },
   },
};
</script>

<style lang="scss" scoped>
.rnr-information-wrapper {
   .rnr-information-inner {
      .rent-a-car-order-detail-section-title {
         margin-bottom: 24px;
         & > h4 {
            font-weight: 700;
         }
      }
   }
}
.rnr-information-header-wrapper {
   display: flex;
   justify-content: space-between;
   align-items: center;
   margin-bottom: 1.5rem;

   .section-title {
      margin-bottom: 0 !important;
   }

   .pdf-action-buttons-wrapper {
      display: flex;

      button {
         margin: 0 0.5rem;
         height: 44px;
         display: flex;
         align-items: center;
         border-radius: 24rem;
         padding-left: 1.75rem;
         padding-right: 1.125rem;
         font-size: var(--large);
         font-weight: 600;
         transform: scale(1);
         transition-property: transform;
         transition-timing-function: ease-in;
         transition-duration: 0.05s;

         i {
            margin-left: 1rem;
         }

         &:last-child {
            margin-right: 0 !important;
         }

         &#document-rnr,
         &#document-provider-docs {
            background-color: #fff;
            border: 2px solid var(--primary);
            color: var(--primary);

            &:hover {
               background-color: var(--primary);
               border: 2px solid var(--primary);
               color: #fff;

               i {
                  filter: brightness(10);
               }
            }
         }

         &#document-booking-confirmation {
            background-color: var(--success);
            color: #2d4c77;
         }

         &:hover {
            transform: scale(1.1);
         }

         &.loading {
            background-color: var(--accent-primary) !important;
            border: 2px solid var(--accent-primary) !important;
            transform: scale(1.1);
            color: #fff !important;

            .g-radial-loader {
               margin-left: 1rem;
            }
         }
      }
   }
}
</style>
