export const EComGender = {
   "-1": "ALL",
   0: "UNKNOWN",
   1: "MALE",
   2: "FEMALE",
};

export const EComLanguage = {
   "-1": "ALL",
   0: "UNKNOWN",
   1: "TURKISH",
   2: "ENGLISH",
   3: "GERMAN",
};

export const EComResult = {
   "-1": "ALL",
   0: "UNKNOWN",
   1: "SUCCESS",
   2: "FAILURE",
   3: "EXCEPTION",
   4: "CONNECTION",
   5: "TIMEOUT",
   6: "SESSION",
   7: "AUTHORIZATION",
   8: "PARAMETER",
   9: "EXISTED",
   10: "EXPIRED",
   11: "REJECTED",
   12: "RETRY",
   13: "PRICE_DIFFERENCE",
   14: "NO_EXISTS",
   15: "EMPTY_LIST",
   16: "PAYMENT_POST",
   17: "INSUFFICIENT_PAYMENT",
   18: "INSUFFICIENT_FUNDS",
   19: "RESERVED",
   20: "ISSUED",
};

export const EComStatus = {
   "-1": "ALL",
   0: "UNKNOWN",
   1: "ACTIVE",
   2: "PASSIVE",
};

export const EComApplication = {
   "-1": "ALL",
   0: "UNKNOWN",
   1: "ADMIN_WEB",
   2: "AGENT_WEB",
   3: "AGENT_IOS",
   4: "AGENT_ANDROID",
   5: "ADMIN_API",
   6: "AGENT_API",
   7: "AGENT_MAPI",
   8: "FLIGHT_API",
   9: "HOTEL_API",
   10: "CRON_API",
   11: "PAYMENT_API",
   12: "AGENT_SPA",
   13: "DOCGEN_SPA",
};
