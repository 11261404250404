<template>
   <div id="nc-wrapper" :class="isPage ? 'notification-page' : ''">
      <!-- <button @click="handlerUndo" v-if="true">geri getir</button> -->
      <div class="np-list-control-panel">
         <h6>{{ $t("NotificationComponent_notificationsHeader") }}</h6>
         <div class="np-actions">
            <button v-if="isPage" @click="handlerDeleteAll">
               <i class="gts-icon icon-error icon-xs"></i>
               <span>{{ $t("NotificationComponent_removeAll") }}</span>
            </button>
            <button @click="handlerMarkAllAsRead">
               <i class="gts-icon icon-mark-as-read icon-xs"></i>
               <span>{{ $t("NotificationComponent_markAsRead") }}</span>
            </button>
            <button v-if="!isPage" @click="handlerNotificationPage">
               <i class="gts-icon icon-arrow-full icon-xs"></i>
               <span>{{ $t("NotificationComponent_notificationPage") }}</span>
            </button>
         </div>
      </div>

      <div class="np-list-filter-wrapper">
         <!-- <span v-for="(step, index) in tabs.list" :key="index" @click="tabs.step = index" :class="tabs.step == index ? 'active' : ''">
          {{ step }} <i>{{ step.length }}</i>
        </span> -->

         <div class="np-list-filter-tabs">
            <span v-for="(tab, key, index) in Notifications" :key="index" @click="handlerTabs(index)" :class="tabs.step == index ? 'active' : ''">
               {{ $t(`NotificationComponent_${key}`) }} <i>{{ tab.count }}</i>
            </span>
         </div>

         <div class="np-information-container" v-if="false">
            {{ $t("NotificationComponent_notificationStatus") }}
         </div>
      </div>

      <div class="np-list-container">
         <b-tabs v-model="tabs.step">
            <b-tab-item v-for="(type, key, index) in Notifications" :key="index" label="Today">
               <Transition name="slide" mode="out-in" v-if="false">
                  <div class="g-loader-wrapper" v-if="updating">
                     <span class="g-radial-loader loader-accent-gray loader-large"></span>
                  </div>
               </Transition>
               <div class="no-notification-wrapper" v-if="type.isEmpty">
                  <span> {{ $t(`NotificationComponent_empty${key[0].toUpperCase() + key.slice(1)}`) }}</span>
               </div>
               <TransitionGroup name="fade" mode="out-in">
                  <template>
                     <div
                        class="np-list-wrapper"
                        v-for="(notification, index) in type.filter"
                        :key="index"
                        :class="[notification.status != 3 ? 'unread' : '', updating == notification.id ? 'deleting' : '']">
                        <i class="gts-icon icon-custom" :class="'icon-status-' + $options.filters.notificationType(notification.type)"></i>

                        <section class="np-list-content-wrapper" @click="handlerRoute(notification)">
                           <span class="np-list-message" v-html="notification.message"></span>

                           <span class="np-list-message-time">{{ notification.insertedDatetime | ago }} </span>
                        </section>
                        <div class="np-list-action-wrapper">
                           <i class="gts-icon icon-error icon-xs" @click.self="handlerDeleteNotification(notification.id)"></i>
                        </div></div
                  ></template>
               </TransitionGroup>
            </b-tab-item>
         </b-tabs>
      </div>
      <div class="np-notification-allow" v-if="false">
         <button @click="handlerAllowNotification">allow</button>
         <b-switch v-model="notificationAllowed" :leftLabel="true">
            {{ notificationAllowed }} Notifications {{ $t(`notifications_${notificationAllowed}`) }}:
         </b-switch>
      </div>
   </div>
</template>

<script>
import Popup from "@/gts-popup.js";
import { globalService } from "@/service/index.js";
import { generateUuid } from "@/utils.js";
import moment from "moment";
import { isIOS } from "@/helper/common";

export default {
   name: "NotificaitonContent",
   props: {
      isPage: Boolean,
      perPage: Number,
   },
   data() {
      return {
         list: [],
         updating: false,
         notificationQuery: {
            uuid: generateUuid(),
            application: 2,
         },
         tabs: {
            step: 0,
            perPage: this.perPage,
         },
         notificationAllowed: false,
      };
   },
   created() {
      const self = this;
      if (isIOS()) return;
      const notificationStatus = Notification.permission;
      if (notificationStatus == "granted") {
         self.notificationAllowed = true;
      } else {
         self.notificationAllowed = false;
      }
   },

   computed: {
      Notifications() {
         const self = this;
         let today = moment().second(0).hour(0).minute(0).format("DD-MM-YYYY");
         let yesterday = moment().subtract(1, "day")._d;
         let weekFirst = moment().subtract(2, "days")._d;
         let weekLast = moment().subtract(2, "days").subtract(1, "week")._d;

         yesterday = moment(yesterday).second(0).hour(0).minute(0).format("DD-MM-YYYY");
         weekFirst = moment(weekFirst).second(0).hour(0).minute(0).format("DD-MM-YYYY");
         weekLast = moment(weekLast).second(0).hour(0).minute(0).format("DD-MM-YYYY");

         const todayList = [];
         const yesterdayList = [];
         const lastWeekList = [];
         const olderList = [];

         self.list.forEach((item) => {
            let itemDate = moment(item.insertedDatetime).second(0).hour(0).minute(0).format("DD-MM-YYYY");

            //TODAY LIST

            if (itemDate == today) {
               todayList.push(item);
            }
            //YESTERDAY LIST
            else if (itemDate == yesterday) {
               yesterdayList.push(item);
            }
            //LAST WEEK LIST
            else if (itemDate <= weekFirst && itemDate > weekLast) {
               lastWeekList.push(item);
            }
            //Older
            else {
               olderList.push(item);
            }
         });

         if (self.dropdown) {
            if (todayList.length > 0) {
               self.tabs.step = 0;
            } else if (yesterdayList.length > 0) {
               self.tabs.step = 1;
            } else if (lastWeekList.length > 0) {
               self.tabs.step = 2;
            } else {
               self.tabs.step = 3;
            }
         }

         return {
            today: {
               filter: todayList.slice(0, self.tabs.perPage).reverse(),
               count: todayList.length,
               isEmpty: todayList.length == 0,
            },
            yesterday: {
               filter: yesterdayList.slice(0, self.tabs.perPage).reverse(),
               count: yesterdayList.length,
               isEmpty: yesterdayList.length == 0,
            },
            week: {
               filter: lastWeekList.slice(0, self.tabs.perPage).reverse(),
               count: lastWeekList.length,
               isEmpty: lastWeekList.length == 0,
            },
            older: {
               filter: olderList.slice(0, self.tabs.perPage).reverse(),
               count: olderList.length,
               isEmpty: olderList.length == 0,
            },
         };
      },
      NotificationCount() {
         const self = this;
         return self.$store.state.app.notification.count;
      },
   },
   methods: {
      handlerOutClick() {
         const self = this;
         self.dropdown = false;
      },
      handlerRoute(notification) {
         const self = this;

         // 1: task, 4:invoice, 5:announcement
         // status 2:unread
         switch (notification.type) {
            case 1:
               window.open(`/order/detail/${notification.parameters.ORDER_UUID}`);
               break;
            case 4:
               self.$store.state.app.documentViewer.request.documentType = 3;
               self.$store.state.app.documentViewer.request.fileType = 2;
               self.$store.state.app.documentViewer.request.showPrice = false;
               self.$store.state.app.documentViewer.activeProductStatus = 0;
               self.$store.state.app.documentViewer.hasPrice = false;
               self.$store.state.app.documentViewer.request.identifiers = [{ identifier: notification.parameters.INVOICE_UUID, subIdentifiers: [] }];

               self.$store.commit("app/setDocumentViewer", true);
               break;
         }

         globalService
            .notificationFeedback({
               notificationIds: [notification.id],
               status: 3,
            })
            .then(() => {
               globalService.notificationList(self.notificationQuery).then((res) => {
                  self.list = res.notifications;
                  self.updating = false;
               });
               // window.location.reload();
            });
      },
      handlerDeleteNotification(id) {
         const self = this;

         Popup.confirm(self.$i18n.t("common_confirmationTitle"), self.$i18n.t("NotificationComponent_deleteNotificationConfirm"));

         const confirmationAction = function () {
            let query = {
               notificationIds: [id],
               status: 4,
            };
            self.updating = id;
            globalService.notificationFeedback(query).then(() => {
               globalService.notificationList(self.notificationQuery).then((update) => {
                  self.list = update.notifications;
                  setTimeout(() => {
                     self.updating = false;
                  }, 2000);
               });
               if (self.$store.state.app.notification.count > 0) {
                  self.$store.state.app.notification.count--;
               }
            });
            self.$store.state.app.errorStatus.isPopupActive = false;
         };

         self.$nextTick(() => {
            document.body.querySelector("#popup-ok").addEventListener("click", confirmationAction);
         });

         // /////////
      },
      handlerMarkAllAsRead() {
         const self = this;

         Popup.confirm(self.$i18n.t("common_confirmationTitle"), self.$i18n.t("NotificationComponent_readAllConfirm"));
         const confirmationAction = function () {
            let query = {
               notificationIds: [],
               status: 3,
            };
            self.list
               // .filter((item) => {
               //     return item.status != 3;
               // })
               .forEach((item) => query.notificationIds.push(item.id));

            globalService.notificationFeedback(query).then((res) => {
               self.$store.state.app.notification.count = res.unreadCount;

               globalService.notificationList(self.notificationQuery).then((res) => {
                  self.list = res.notifications;
                  self.updating = false;
               });
            });
         };
         self.$nextTick(() => {
            document.body.querySelector("#popup-ok").addEventListener("click", confirmationAction);
         });
      },
      handlerNotificationPage() {
         window.open(`/notifications`);
      },
      handlerUndo() {
         const self = this;
         let query = {
            notificationIds: ["4922", "3281", "3403", "3413", "3517", "4225", "4544", "4577", "4588", "4599", "4712"],
            status: 2,
            uuid: generateUuid(),
         };
         self.list.forEach((item) => query.notificationIds.push(item.id));

         globalService.notificationFeedback(query);
      },
      handlerDeleteAll() {
         const self = this;

         Popup.confirm(self.$i18n.t("common_confirmationTitle"), self.$i18n.t("NotificationComponent_deleteAllNotificationConfirm"));

         const confirmationAction = function () {
            let query = {
               notificationIds: [],
               status: 4,
            };

            self.list.forEach((item) => query.notificationIds.push(item.id));

            globalService.notificationFeedback(query).then((re) => {
               re;
               globalService.notificationList(self.notificationQuery).then((res) => {
                  self.list = res.notifications;
                  self.updating = false;
               });
            });
         };

         self.$nextTick(() => {
            document.body.querySelector("#popup-ok").addEventListener("click", confirmationAction);
         });
      },
      handlerTabs(index) {
         const self = this;
         if (localStorage.getItem("navTab") == null) {
            localStorage.setItem("navTab", index);
         }

         self.tabs.step = index;
      },
      handlerAllowNotification() {
         Notification.requestPermission().then((result) => {
            if (result == "granted") {
               self.notificationAllowed = true;
            }
         });
      },
   },
   watch: {
      dropdown: {
         handler: function () {
            const self = this;
            globalService.notificationList(self.notificationQuery).then((res) => {
               self.list = res.notifications;

               self.updating = false;
            });
         },
         immediate: true,
      },
      notificationAllowed: {
         handler: function (status) {
            if (status) {
               Notification.requestPermission().then((result) => {
                  if (result == "granted") {
                     self.notificationAllowed = true;
                  }
               });
            }
         },
      },
      "$store.state.app.errorStatus.isOk": {
         handler: function (status) {
            if (status) {
               this.$store.state.app.errorStatus.deneme();
            }
         },
         deep: true,
      },
   },
};
</script>

<style lang="scss" scoped>
#nc-wrapper {
   .np-list-control-panel,
   .np-list-container {
      padding: 0;
   }

   .np-list-control-panel {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 0.5rem;
      padding-left: 1.5rem;
      padding-right: 1.5rem;
      margin-bottom: 0.75rem;

      .np-actions {
         display: flex;
         button {
            display: flex;
            align-items: center;
            background: transparent !important;
            padding-right: 0;
            margin: 0 0.5rem;

            i.icon-error {
               transform: scale(0.7);
            }
            i {
               filter: invert(20%) sepia(100%) saturate(600%) hue-rotate(190deg) brightness(75%) contrast(90%);
            }
            span {
               margin-left: 0.25rem;
               font-size: var(--small);
               font-weight: 500;
               color: var(--txt-black);
            }

            &:last-child {
               margin-right: 0 !important;
            }
         }
      }

      h6 {
         font-weight: 500;
         font-size: var(--large);
      }
   }

   .np-list-container {
      .g-loader-wrapper {
         display: flex;
         justify-content: center;
         span {
            margin: 1.5rem;
         }
      }
      .no-notification-wrapper {
         padding: 1rem 1.5rem;

         span {
            font-size: var(--normal);
            color: var(--txt-ghost);
            font-weight: 500;
            text-align: center;
            width: 100%;
            display: block;
         }
      }
      .np-list-wrapper {
         display: flex;
         justify-content: space-between;
         align-items: center;
         padding: 1rem 1.5rem;
         border-bottom: 1px solid #eee;
         cursor: pointer;

         i.icon-custom {
            flex-shrink: 0;
            width: 42px !important;
            height: 42px !important;
         }

         .np-list-content-wrapper {
            display: flex;
            flex-direction: column;
            width: 100%;
            margin-left: 0.75rem;
            .np-list-message {
               font-size: var(--small);
               font-weight: 600;
               line-height: 1.25rem;
            }
            .np-list-message-time {
               font-size: var(--xsmall);
               text-transform: capitalize;
            }
         }

         .np-list-action-wrapper {
            i {
               filter: brightness(0);
               opacity: 0.6;
               transform: scale(0.8);
            }
         }

         &:last-child {
            border-bottom: none !important;
         }

         &.unread {
            background-color: var(--secondary-lightest);
         }
         &:hover {
            background-color: var(--hover);
         }

         &.deleting {
            opacity: 0.7;
            background-color: #ffe1e1;
            .np-list-action-wrapper {
               visibility: hidden !important;
            }
         }
      }
   }

   .np-list-filter-wrapper {
      display: flex;
      padding: 0 1.5rem;
      border-bottom: 2px solid #eee;

      .np-list-filter-tabs {
         padding-top: 0.5rem;
         padding-bottom: 0.5rem;
         span {
            color: var(--txt-ghost);
            margin-right: 1.5rem;
            font-size: var(--small);
            font-weight: 400;
            text-transform: capitalize;
            position: relative;

            cursor: pointer;

            i {
               background-color: #eee;
               color: #a2a2a2;
               display: inline-block;
               text-align: center;
               width: 20px;
               border-radius: 4px;
               font-size: var(--xsmall);
            }

            &:after {
               content: "";
               position: absolute;
               bottom: 0;
               height: 2px;
               background: #eee;
               left: -3px;
               right: -3px;
               bottom: -13px;
            }

            &.active {
               font-weight: 500;
               color: var(--txt-black);

               i {
                  background-color: #363636;
                  color: var(--txt-negative);
               }

               &:after {
                  background: #363636;
               }
            }

            &:last-child {
               margin-right: 0;
            }
         }
      }
   }
   &:before {
      content: "";
      position: absolute;
      top: -6px;
      right: 0.5rem;
      width: 0;
      height: 0;
      transform: translateX(-50%);
      border-right: 6px solid transparent;
      border-bottom: 6px solid #fff;
      border-left: 6px solid transparent;
   }
}
.notification-page {
   .np-list-wrapper {
      border: 1px solid #eee;
      border-radius: 0.75rem;
      margin: 0.5rem 0;
   }
   &:before {
      display: none;
   }
}
</style>

<style lang="scss">
.np-list-container {
   nav.tabs {
      display: none;
   }
}
</style>
