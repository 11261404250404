<template>
   <div class="hotel-basket-wrapper">
      <div class="hotel-basket-inner">
         <div class="hotel-basket-inner-wrapper">
            <!-- Hotel Basket Title Wrapper -->
            <div class="hotel-basket-title-wrapper">
               <div class="hotel-basket-title-top">
                  <span class="hotel-basket-title"> </span>
               </div>
               <div class="hotel-basket-title-bottom">
                  <div class="hotel-stars">
                     <i
                        v-for="(item, index) in this.$store.state.hotel.basketResponse.hotelStar"
                        :key="'activeStar-' + index"
                        class="gts-icon icon-star active"></i>
                     <i
                        v-for="(item, index) in 5 - this.$store.state.hotel.basketResponse.hotelStar"
                        :key="'star' + index"
                        class="gts-icon icon-star"></i>
                  </div>
                  <span class="hotel-basket-delete" @click="clearBasket">
                     <i class="gts-icon icon-hotel-empty-basket icon-sm"></i>
                  </span>
               </div>
            </div>

            <div class="dotted-line"></div>

            <div class="hotel-basket-content">
               <div class="basket-list">
                  <div class="basket-list-item" v-for="(basketRoomItem, index) in basket.rooms" :key="index">
                     <div class="basket-room-name">
                        <span>{{ basketRoomItem.roomName }}</span>

                        <span class="hotel-basket-boardName-txt">{{ basket.boardName }}</span>
                     </div>
                     <div class="basket-room-price-info">
                        <div class="basket-room-price-info-item room-price">
                           <span>{{ $t("hotelBasket_lblRoomPrice") }}</span>
                           <span>{{ basketRoomItem.rate | price(basketRoomItem?.currency) }}</span>
                        </div>
                     </div>
                     <div class="basket-addon-wrapper" v-if="basketRoomItem.addOns.length != 0">
                        <span class="addon-section-title">
                           {{ $t("hotelCard_lblhasAddonTitle") }}
                        </span>
                        <div class="basket-addon-inner-wrapper" v-for="(addOn, addOnIndex) in basketRoomItem.addOns" :key="addOnIndex">
                           <span class="addon-title">
                              {{ addOn.title }}
                           </span>
                           <div class="addon-infos">
                              <span class="addon-date">{{ addOn.effectiveDate | FormatterDate }}</span>
                              <span class="addon-price">{{ addOn.price | price(addOn?.currency) }}</span>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <div class="hotel-basket-cta" v-if="basket.checkoutEnabled" @click="handlerCheckout(basket.basketUuid)">
               <button>
                  <!-- <i class="gts-icon icon-arrow-full icon-sm"></i> -->
                  <p class="total-container">
                     <span class="total-text">{{ $t("basketComponentFlight_total") }}</span>
                     <span class="total-price"> {{ basket.totalPrice | price(basket?.rooms[0]?.currency) }}</span>
                  </p>
               </button>

               <UIButton
                  className="light"
                  :text="`${basket.totalPrice} ${basket.totalPriceCurrency}`"
                  isRouter="true"
                  v-if="false"
                  :routerUrl="{
                     name: 'HotelCheckout',
                     params: { uuid: basket.uuid },
                  }"
                  z
                  target="_blank" />
            </div>
            <div class="send-offer" :class="$store.state.app.clientInformations.isMobile ? '--mobile' : ''" @click="handlerDocumentViewer(7)">
               {{ $t("basketComponentHotel_sendOffer") }}
            </div>
            <div class="hotel-basket-cta-mobile d-flex flex-row justify-content-end">
               <div class="hotel-basket-cta-mobil-close d-lg-none" @click="handlerCloseBasket">
                  <UIButton className="default_outline" :text="$t('basketComponentFlight_closeBasket')" />
               </div>
               <div class="hotel-basket-cta-mobil d-lg-none" v-if="basket.checkoutEnabled" @click="handlerCheckout(basket.basketUuid)">
                  <UIButton className="default" :text="$t('basketComponentFlight_goToCheckout')" />
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>
import { hotelService } from "@/service/index.js";
import UIButton from "@/components/unitary/Button.vue";
import Popup from "@/gts-popup.js";

export default {
   name: "HotelBasketComponent",
   components: {
      UIButton,
   },
   data() {
      return {
         offerLoading: false,
         selectQuery: {
            searchUuid: this.$store.state.hotel.searchUuid,
            hotelId: this.$store.state.hotel.basketResponse.hotelCode,
            rooms: this.$store.state.hotel.selectedRooms,
            recommended: this.$store.state.hotel.basketResponse.recommended,
         },
         basketResponse: "",
      };
   },
   computed: {
      basket() {
         return this.$store.state.hotel.basketResponse;
      },
   },
   methods: {
      handlerDocumentViewer(type) {
         const self = this;
         self.offerLoading = true;
         this.$store.state.app.documentViewer.request.documentType = type;
         this.$store.state.app.documentViewer.request.identifiers = [
            { identifier: self.selectQuery.searchUuid, subIdentifiers: [self.basket.basketUuid] },
         ];
         this.$store.state.app.documentViewer.request.fileType = 2;
         this.$store.state.app.documentViewer.request.showPrice = false;
         this.$store.state.app.documentViewer.hasPrice = true;
         self.$store.state.app.documentViewer.activeProductStatus = 0;
         this.$store.commit("app/setDocumentViewer", true);
      },
      handlerCloseBasket() {
         this.$parent.$parent._data.isBasketActive = false;
      },
      handlerCheckout(basketUuid) {
         window.open(`/hotel/checkout/${this.selectQuery.searchUuid}/${basketUuid}`);
      },

      clearBasket() {
         const self = this;

         self.selectQuery.rooms = {};
         this.$store.state.hotel.basketResponse.hotelCode = null;
         this.$store.state.hotel.selectedRooms = {};

         hotelService.hotelSelect(self.selectQuery).then((response) => {
            if (!response.result.success) {
               Popup.result(response.result);
               return;
            } else {
               self.basketResponse = {
                  hotelCode: 0,
                  id: response.id,
                  basketUuid: response.basketUuid,
                  rooms: response.rooms,
                  totalPrice: response.totalPrice.total,
                  totalPriceCurrency: this.$options.filters.currency(response.totalPrice.currency),
                  uuid: response.uuid,
                  checkoutEnabled: response.checkoutEnabled,
                  recommended: response.recommended,
                  channelId: Object.keys(response.rooms).length === 0 ? 0 : response.channelId,
                  boardId: Object.keys(response.rooms).length === 0 ? 0 : response.boardId,
                  hotelName: Object.keys(response.rooms).length === 0 ? 0 : response.hotelName,
                  boardName: Object.keys(response.rooms).length === 0 ? 0 : response.board,
                  hotelStar: Object.keys(response.rooms).length === 0 ? 0 : response.hotelStar,
               };
               this.$store.commit("hotel/updateBasketResponse", self.basketResponse);
            }
         });
      },
   },
};
</script>

<style lang="scss" scoped>
.hotel-basket-wrapper {
   display: flex;
   position: sticky;
   top: 1rem;
   height: fit-content;
   background-color: var(--primary);
   width: 100%;
   border-radius: 0.5rem;

   .hotel-basket-inner {
      display: flex;
      border-radius: 8px;
      width: 100%;

      .hotel-basket-inner-wrapper {
         padding: 1.5rem 1rem;
         width: 100%;

         .hotel-basket-title-wrapper {
            .hotel-basket-title-top {
               display: flex;
               justify-content: space-between;
               align-items: center;
               font-size: var(--normal);
               font-weight: 600;
               color: #fff;
            }

            .hotel-basket-title-bottom {
               display: flex;
               align-items: center;
               justify-content: space-between;
               .hotel-stars {
                  display: flex;
                  i {
                     width: 12px !important;
                     margin-right: 4px;
                     opacity: 0.3;

                     &:last-child {
                        margin-right: 0;
                     }
                  }
                  i.active {
                     opacity: 1;
                     filter: invert(74%) sepia(100%) saturate(805%) hue-rotate(358deg) brightness(103%) contrast(103%);
                  }
               }

               i {
                  cursor: pointer;
                  transform: scale(1);
                  transition-property: transform;
                  transition-timing-function: ease-in-out;
                  transition-duration: 0.1s;

                  &:hover {
                     transform: scale(1.15);
                  }
               }

               .hotel-basket-delete {
                  // cursor: pointer;
               }
            }
         }

         .dotted-line {
            background-image: linear-gradient(to right, #e0e7f0 33%, rgba(255, 255, 255, 0) 0%);
            background-position: left;
            background-size: 12px auto;
            background-repeat: repeat-x;
            display: inline-block;
            width: 100%;
            bottom: 7px;
            left: 1rem;
            right: 1rem;
            height: 2px;
         }
         .hotel-basket-content {
            .basket-list {
               margin-top: 1rem;
               .basket-list-item {
                  padding-bottom: 1rem;
                  margin-bottom: 1rem;
                  border-bottom: 1px dashed #ffffff50;
                  .basket-room-name {
                     span {
                        color: #ffffff;
                        font-size: var(--normal);
                        font-weight: 500;
                     }

                     .hotel-basket-boardName-txt {
                        display: flex;
                        color: #74fbe5;
                        font-size: var(--small);
                     }
                  }

                  .basket-room-price-info {
                     .basket-room-price-info-item {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        span {
                           &:first-child {
                              color: #fff;
                              font-size: var(--small);
                           }
                           &:last-child {
                              color: var(--accent-secondary);
                              font-size: var(--large);
                              font-weight: 800;
                           }
                        }
                     }
                  }

                  &:last-child {
                     border-bottom: none !important;
                  }
                  .basket-addon-wrapper {
                     margin-top: 1rem;
                     background-color: #00000030;
                     border-radius: 0.75rem;
                     padding: 1rem;
                     // padding-top: 0.5rem;
                     .addon-section-title {
                        display: block;
                        color: #fff;
                        font-weight: 600;
                        border-bottom: 1.5px dotted #eeeeee60;
                        padding-bottom: 0.25rem;
                        margin-bottom: 0.25rem;
                     }
                     .basket-addon-inner-wrapper {
                        .addon-title {
                           color: #fff;
                        }
                        .addon-infos {
                           display: flex;
                           flex-direction: column;
                           justify-content: space-between;
                           align-items: baseline;
                           .addon-date {
                              color: #03ffe5;
                              font-weight: 500;
                              font-size: var(--small);
                           }
                           .addon-price {
                              color: #03ffe5;
                              font-size: 1.25rem;
                              font-weight: 600;
                           }
                        }
                     }
                  }
               }
            }
         }
         .hotel-basket-cta {
            button {
               padding: 0.5rem 1.5rem;
               border-radius: 12rem;
               width: 100%;
               margin: 1rem 0;
               box-shadow: rgba(99, 99, 99, 0.5) 0px 2px 8px 0px;
               display: flex;
               align-items: center;
               justify-content: center;
               border: 2px solid #1b4c92;
               transition: all 0.2s ease-in-out;

               i {
                  position: relative;
                  left: -0.5rem;
                  margin-right: 0.5rem;
                  transition: all 0.2s ease-in-out;
                  filter: invert(19%) sepia(28%) saturate(5280%) hue-rotate(204deg) brightness(70%) contrast(85%);
               }

               p.total-container {
                  display: flex;
                  flex-direction: column;
                  align-items: flex-start;
                  position: relative;
                  top: 0;
                  transition-property: top;
                  transition-timing-function: ease-in-out;
                  transition-duration: 0.2s;
                  .total-text {
                     color: #1b4c92;
                     font-size: var(--xsmall);
                     font-weight: 600;
                     transition: opacity, 0.2s, ease-in-out;
                     opacity: 1;
                  }
                  .total-price {
                     font-size: 1.25rem;
                     font-weight: 800;
                     color: #1b4c92;
                  }
               }
            }
            &:hover {
               button {
                  background-color: #184990;
                  border: 2px solid #184990;

                  i {
                     left: -0.1rem;
                     filter: invert(100%) sepia(100%) saturate(10) hue-rotate(160deg) brightness(100%) contrast(200%);
                  }
               }
               p.total-container {
                  top: -10px;
                  .total-text {
                     color: #1b4c92;
                     font-size: var(--xsmall);
                     font-weight: 600;

                     opacity: 0;
                  }
                  .total-price {
                     color: var(--accent-secondary);
                  }
               }
            }
         }
         .send-offer {
            color: #fff;
            text-align: center;
            width: 100%;
            display: block;
            text-decoration: underline;
            font-size: var(--small);
            cursor: pointer;
            &.--mobile {
               color: var(--primary);
               font-size: var(--large);
               text-align: start;
            }

            &:hover {
               color: var(--accent-secondary);
            }
         }
      }

      .hotel-basket-inner-wrapper2 {
         width: 100%;
         .hotel-basket-title {
            display: inline-flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            border-bottom: 1px solid #5a84be;
            padding-bottom: 16px;
            margin-bottom: 24px;
            & > span {
               &.title {
                  margin-bottom: 0;
                  color: white;
                  font-size: 24px;
               }
               &.cta-button {
                  display: inline-flex;
                  align-items: center;

                  border-radius: 16px;
                  padding: 8px 16px;
                  padding-right: 0.125rem !important;
                  font-size: 12px;
                  cursor: pointer;
               }
            }
         }
         .hotel-basket-content {
            margin-bottom: 24px;
            .basket-list {
               .basket-list-item {
                  padding-bottom: 16px;
                  margin-bottom: 16px;
                  border-bottom: 1px dotted #5a84be;

                  &:last-child {
                     margin-bottom: 0;
                  }
                  .basket-room-name {
                     margin-bottom: 16px;
                     & > span {
                     }
                  }
                  .basket-room-price-info {
                     .basket-room-price-info-item {
                        display: flex;
                        justify-content: space-between;
                        align-items: flex-start;
                        margin-bottom: 8px;

                        &:last-child {
                           margin-bottom: 0;
                        }

                        & > span {
                           font-size: 12px;
                        }

                        &.room-price {
                        }
                        &.room-count {
                        }
                     }
                  }
               }
            }
         }
         .hotel-basket-cta {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            ::v-deep a {
               width: 100%;
               font-size: 24px;

               & > span {
                  width: 100%;
               }
            }
         }
      }
   }
}
</style>
<style lang="scss" scoped>
@media (max-width: 991.98px) {
   .hotel-basket-wrapper {
      position: fixed;
      z-index: 101;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      height: 100%;
      border-radius: 0;
      background-color: #fff;

      .hotel-basket-title {
         color: var(--txt-black);
         font-size: var(--xxlarge);
      }

      .hotel-stars {
         transform: scale(1.5);
         transform-origin: left top;
      }

      .hotel-basket-content {
         background: #f2f2f2;
         border-radius: 0.5rem;
         margin: 1rem 0;
         padding: 1rem;

         .basket-room-name {
            span {
               font-size: var(--xlarge) !important;
               color: var(--txt-black) !important;
               &.hotel-basket-boardName-txt {
                  font-size: var(--large) !important;
               }
            }
         }

         .basket-room-price-info {
            .basket-room-price-info-item {
               span {
                  color: var(--primary) !important;
                  &:first-child {
                     font-size: var(--large) !important;
                  }
                  &:last-child {
                     font-size: var(--xlarge) !important;
                  }
               }
            }
         }
      }

      .basket-list-item {
         border-bottom: 1px dashed #48484850 !important;
      }

      .hotel-basket-delete {
         position: absolute;
         right: 1rem;
         top: 2.5rem;
         transform: scale(1.5);

         i {
            filter: brightness(0.25);
         }
      }

      .hotel-basket-cta {
         display: none;
      }
      .hotel-basket-cta-mobil {
         display: flex;
         justify-content: flex-end;
      }
      .hotel-basket-cta-mobil-close {
         display: flex;
         justify-content: flex-end;
         margin-right: 0.5rem;
      }
   }
}
</style>
