import { PRIVATE_HTTP } from "./index";
import router from "@/router/index.js";
import Popup from "@/gts-popup.js";
import i18n from "@/fmt/i18n.js";

const SEARCH = "api/rentacar/sale/search";
const AUTOCOMPLETE = "api/rentacar/sale/autocomplete";
const ANCILLARIES = "api/rentacar/sale/searchancillaries";
const SELECT = "api/rentacar/sale/select";
const CHECKOUT = "api/rentacar/sale/checkout";
const REVERSE = "api/rentacar/reverse/reverse";
const INQUIRY = "api/rentacar/reverse/reverseinquiry";
export var rentacarSearch = (requestModel) => {
   let timeout = 45 * 1000; // 45 sn
   const promise = PRIVATE_HTTP(timeout).post(SEARCH, requestModel);
   const resData = promise
      .then((response) => {
         if (!response.data.result.success) {
            if (response.data.result.code == "REJECTED" && response.data.result.subCode == "PARAMETER") {
               let numericValueMatch = response.data.result.message.match(/_(\d+)_/);
               let numericValue = numericValueMatch ? numericValueMatch[1] : "UNKNOWN";
               let type = response.data.result.message.substring(0, 7) == "PERIOD_" ? "PERIOD_DAYS" : "PICKUP_HOURS";
               let msg = i18n.t(`RESPONSE_${response.data.result.app}.${response.data.result.service}.${response.data.result.code}.${response.data.result.subCode}.${type}`);
               Popup.warning(i18n.t("popup_warningTitle"), msg.replace("#VALUE#", numericValue));
            } else {
               let msg = i18n.t(`RESPONSE_${response.data.result.app}.${response.data.result.service}.${response.data.result.code}.${response.data.result.subCode}`);
               Popup.warning(i18n.t("popup_warningTitle"), msg);
            }
         }
         return response.data;
      })
      .catch((error) => {
         if (error.response.status === 401) {
            if (router.currentRoute.path != "/login") {
               router.push("/login");
            }
         }
      });

   return resData;
};

export var rentacarAutocomplete = (request) => {
   const promise = PRIVATE_HTTP().post(AUTOCOMPLETE, request);
   return promise.then((response) => {
      return response.data;
   });
};
export var rentacarAncillaries = (request) => {
   const promise = PRIVATE_HTTP().post(ANCILLARIES, request);
   return promise.then((response) => {
      return response.data;
   });
};
export var rentacarSelect = (request) => {
   const promise = PRIVATE_HTTP().post(SELECT, request);
   return promise.then((response) => {
      return response.data;
   });
};
export var rentacarCheckout = (request) => {
   const promise = PRIVATE_HTTP().post(CHECKOUT, request);
   return promise.then((response) => {
      return response.data;
   });
};
export var rentACarReverse = (request) => {
   const promise = PRIVATE_HTTP().post(REVERSE, request);
   return promise.then((response) => {
      return response.data;
   });
};
export var rentACarReverseInquiry = (request) => {
   const promise = PRIVATE_HTTP().post(INQUIRY, request);
   return promise.then((response) => {
      return response.data;
   });
};
