<template>
   <div class="modal-overlay" @click="$emit('close-modal')">
      <div id="modal-body" class="modal-content" :class="[modalSize !== '' ? `${modalSize}` : '']" @click.stop>
         <div class="d-flex modal-content-header-wrapper">
            <h4 class="modal-content__title">{{ modalTitle }}</h4>
            <div v-show="noCloseIcon" id="close-modal" @click="$emit('close-modal')"></div>
         </div>
         <div class="modal-content-body-wrapper" :class="noBorder ? '--noBorder' : ''">
            <div v-if="hasHtmlContent" v-html="modalContent"></div>
            <div v-else-if="!hasSlot" class="modal-content-body-wrapper__text">{{ modalContent }}</div>
            <div v-if="hasSlot">
               <slot></slot>
            </div>
            <!-- <div class="modal-content-body-wrapper__gradient" :class="isGradientInVisible ? '--hidden' : ''"></div> -->
         </div>
      </div>
   </div>
</template>

<script>
export default {
   name: "UIModal",
   props: {
      modalSize: {
         type: String,
         default: "",
      },
      modalTitle: {
         type: String,
         default: "",
      },
      modalContent: {
         type: String,
         default: "Modal Content",
      },
      hasHtmlContent: {
         type: Boolean,
         default: false,
      },
      hasSlot: {
         type: Boolean,
         default: false,
      },
      noBorder: {
         type: Boolean,
         default: false,
      },
      noCloseIcon: {
         type: Boolean,
         default: true,
      },
   },
   data() {
      return {
         lastScrollPosition: 0,
         modalBody: null,
         isGradientInVisible: false,
      };
   },
   mounted() {
      this.modalBody = document.getElementById("modal-body");
      this.lastScrollPosition = this.modalBody.pageYOffset;
      this.modalBody.addEventListener("scroll", this.onScroll);
   },
   beforeDestroy() {
      this.modalBody.removeEventListener("scroll", this.onScroll);
   },
   methods: {
      onScroll() {
         let modalBody = document.getElementById("modal-body");
         let modalBodyScrollTop = modalBody.scrollTop;
         let modalBodyScrollHeight = modalBody.scrollHeight;
         let modalBodyClientHeight = modalBody.clientHeight;
         let modalBodyScrollPosition = modalBodyScrollTop + modalBodyClientHeight;
         if (modalBodyScrollPosition >= modalBodyScrollHeight) {
            this.isGradientInVisible = true;
         }
         if (modalBodyScrollTop === 0) {
            this.isGradientInVisible = false;
         }
      },
   },
};
</script>
<style lang="scss" scoped>
@mixin transition() {
   transition-timing-function: ease-in-out;
   transition-duration: 0.125s;
   transition-property: all;
}
.modal-overlay {
   position: fixed;
   z-index: 100;
   top: 0;
   bottom: 0;
   left: 0;
   right: 0;
   display: flex;
   justify-content: center;
   box-shadow: rgba(0, 0, 0, 0.1) 0px 3px 12px;
   background-color: #0000004a;
}

.modal-content-header-wrapper {
   display: flex;
   justify-content: space-between;

   #close-modal {
      $size: 36px;
      width: $size;
      height: $size;
      background-color: #eaeaea;
      border-radius: 50%;
      position: relative;
      display: block;
      transform: scale(1);
      cursor: pointer;
      margin-top: -9px;
      margin-right: 8px;
      &:hover {
         transform: scale(1.125);
         background: #ccc;
         @include transition();
      }
      &::before,
      &::after {
         content: "";
         width: 18px;
         height: 2px;
         background: #363636;
         display: block;
         border-radius: 1rem;
         position: absolute;
         inset: 0;
         margin: auto;
      }
      &::before {
         transform: rotate(45deg);
      }
      &::after {
         transform: rotate(-45deg);
      }
   }
}

.modal-content-body-wrapper {
   border-top: 1px solid #e6e6e6;
   border-bottom: 1px solid #e6e6e6;
   margin-top: 30px;
   &__gradient {
      background: transparent -webkit-linear-gradient(90deg, #ffffff 0%, #ffffff 35%, #ffffff00 100%) 0% 0% no-repeat padding-box;
      opacity: 1;
      z-index: +1;
      height: 86px;
      position: relative;
      top: 459px;
      &.--hidden {
         display: none;
      }
   }
   &.--noBorder {
      border: none;
      margin-top: 0;
   }
}

.modal-content {
   text-align: left;
   width: 675px;
   height: 640px;
   margin: auto !important;
   padding: 32px 32px 48px 48px;
   border-radius: 16px;
   background: white;
   &__title {
      font-size: 20px;
      font-weight: bold;
   }
   &.large {
      height: 850px;
      margin-top: 1.5%;
      -ms-overflow-style: none;
      scrollbar-width: 100%;
      background: white;
   }
   &.auto {
      height: auto;
      max-height: 850px;
      margin-top: 1.5%;
      -ms-overflow-style: none;
      scrollbar-width: 100%;
      background: white;
   }
   &.heighted {
      width: 560px;
      height: 640px;

      background: #ffffff;
   }
   &.heighted::-webkit-scrollbar {
      display: none;
      scrollbar-width: 100%;
   }
   &.large::-webkit-scrollbar {
      display: none;
      scrollbar-width: 100%;
   }
   &.auto::-webkit-scrollbar {
      display: none;
      scrollbar-width: 100%;
   }
}
.close {
   cursor: pointer;
}
</style>
