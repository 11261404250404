import { logger } from "./logger.js";

export const getDeviceInfo = function () {
   var deviceInfo = {};

   try {
      var module = {
         options: [],
         header: [navigator.platform, navigator.userAgent, navigator.appVersion, navigator.vendor, window.opera],
         dataos: [
            { name: "Windows Phone", value: "Windows Phone", version: "OS" },
            { name: "Windows", value: "Win", version: "NT" },
            { name: "iPhone", value: "iPhone", version: "OS" },
            { name: "iPad", value: "iPad", version: "OS" },
            { name: "Kindle", value: "Silk", version: "Silk" },
            { name: "Android", value: "Android", version: "Android" },
            { name: "PlayBook", value: "PlayBook", version: "OS" },
            { name: "BlackBerry", value: "BlackBerry", version: "/" },
            { name: "Macintosh", value: "Mac", version: "OS X" },
            { name: "Linux", value: "Linux", version: "rv" },
            { name: "Palm", value: "Palm", version: "PalmOS" },
         ],
         databrowser: [
            { name: "Chrome", value: "Chrome", version: "Chrome" },
            { name: "Firefox", value: "Firefox", version: "Firefox" },
            { name: "Safari", value: "Safari", version: "Version" },
            { name: "Internet Explorer", value: "MSIE", version: "MSIE" },
            { name: "Opera", value: "Opera", version: "Opera" },
            { name: "BlackBerry", value: "CLDC", version: "CLDC" },
            { name: "Mozilla", value: "Mozilla", version: "Mozilla" },
         ],
         init: function () {
            var agent = this.header.join(" "),
               os = this.matchItem(agent, this.dataos),
               browser = this.matchItem(agent, this.databrowser);

            return { os: os, browser: browser };
         },
         matchItem: function (string, data) {
            var i = 0,
               j = 0,
               regex,
               regexv,
               match,
               matches,
               version;

            for (i = 0; i < data.length; i += 1) {
               regex = new RegExp(data[i].value, "i");
               match = regex.test(string);
               if (match) {
                  regexv = new RegExp(data[i].version + "[- /:;]([\\d._]+)", "i");
                  matches = string.match(regexv);
                  version = "";
                  if (matches) {
                     if (matches[1]) {
                        matches = matches[1];
                     }
                  }
                  if (matches) {
                     matches = matches.split(/[._]+/);
                     for (j = 0; j < matches.length; j += 1) {
                        if (j === 0) {
                           version += matches[j] + ".";
                        } else {
                           version += matches[j];
                        }
                     }
                  } else {
                     version = "0";
                  }
                  return {
                     name: data[i].name,
                     version: parseFloat(version),
                  };
               }
            }
            return { name: "unknown", version: 0 };
         },
      };

      var e = module.init();

      deviceInfo["osName"] = e.os.name;
      deviceInfo["osVersion"] = e.os.version;
      deviceInfo["browserName"] = e.browser.name;
      deviceInfo["browserVersion"] = e.browser.version;
      deviceInfo["userAgent"] = navigator.userAgent;
      deviceInfo["appVersion"] = navigator.appVersion;
      deviceInfo["platform"] = navigator.platform;
      deviceInfo["vendor"] = navigator.vendor;
      deviceInfo["uiHelpers"] = {
         screenWidth: window.screen.width,
         screenHeight: window.screen.height,
         browserWith: window.innerWidth,
         browserHeight: window.innerHeight,
         devicePixelRatio: window.devicePixelRatio,
      };
   } catch (error) {
      logger.error(error);
   }

   return deviceInfo;
};
/* 
*{Array} arr - Array of objects
const people = [ { name: 'John', age: 42 }, { name: 'Adam', age: 39 } ];

objectify(people, p => p.name.toLowerCase());

Result :{ john: { name: 'John', age: 42 }, adam: { name: 'Adam', age: 39 } }
Objectify array of objects -> {key: value} 
*/
export const objectify = (arr, mapKey, mapValue = (i) => i) =>
   arr.reduce((acc, item) => {
      acc[mapKey(item)] = mapValue(item);
      return acc;
   }, {});
/* 
indexOn([
  { id: 10, name: 'apple' },
  { id: 20, name: 'orange' }
], 'id');

Result : { '10': { name: 'apple' }, '20': { name: 'orange' } }
Objectify array of objects by a key
*/
export const indexOn = (arr, key) =>
   arr.reduce((obj, v) => {
      const { [key]: id, ...data } = v;
      obj[id] = data;
      return obj;
   }, {});
/* 
@parameters {Object} obj - Object to be listified
@parameters {Function} mapFn - Function to map
--------------------------------------------------------
const people = { John: { age: 42 }, Adam: { age: 39 } };

listify(people, (key, value) => ({ name: key, ...value }));

Result : [ { name: 'John', age: 42 }, { name: 'Adam', age: 39 } ]
*/
export const listify = (obj, mapFn) =>
   Object.entries(obj).reduce((acc, [key, value]) => {
      acc.push(mapFn(key, value));
      return acc;
   }, []);
/*---------------- isIOS helper ------------*/
export const isIOS = () => {
   const browserInfo = navigator.userAgent.toLowerCase();

   if (browserInfo.match("iphone") || browserInfo.match("ipad")) {
      return true;
   }
   if (["iPad Simulator", "iPhone Simulator", "iPod Simulator", "iPad", "iPhone", "iPod"].includes(navigator.platform)) {
      return true;
   }
   return false;
};

// If empty return true
export const isObjEmpty = (obj) => {
   return Object.keys(obj).length === 0;
};
