import { PRIVATE_HTTP } from "./index";
import router from "@/router/index.js";
import Popup from "@/gts-popup.js";

const LIST_GSAS_ENDPOINT = "api/agency/listgsas";
const LIST_AGENCIES_ENDPOINT = "api/agency/listagencies";
const LIST_PROVIDERS_ENDPOINT = "api/agency/listproviders";

export var listGsas = (requestModel) => {
    const promise = PRIVATE_HTTP().post(LIST_GSAS_ENDPOINT, requestModel);
    const responseData = promise
        .then((response) => response.data)
        .catch((error) => {
            if (error.response.status === 401) {
                if(router.currentRoute.path != "/login"){
                    router.push("/login");
                }
            } else {
                Popup.warning("listGsas Error", error);
            }
        });

    return responseData;
};

export var listAgencies = (requestModel) => {
    const promise = PRIVATE_HTTP().post(LIST_AGENCIES_ENDPOINT, requestModel);
    const responseData = promise
        .then((response) => response.data)
        .catch((error) => {
            if (error.response.status === 401) {
                if(router.currentRoute.path != "/login"){
                    router.push("/login");
                }
            } else {
                Popup.warning("listGsas Error", error);
            }
        });

    return responseData;
};
export var listProviders = (requestModel) => {
    const promise = PRIVATE_HTTP().post(LIST_PROVIDERS_ENDPOINT, requestModel);
    const responseData = promise
        .then((response) => response.data)
        .catch((error) => {
            if (error.response.status === 401) {
                if(router.currentRoute.path != "/login"){
                    router.push("/login");
                }
            } else {
                Popup.warning("listProviders Error", error);
            }
        });

    return responseData;
};
