<template>
   <div id="hotel-search-form-container" :class="isStickyBarActive ? 'sticky' : ''">
      <div class="identifier col-12">
         <div class="row gx-4 gy-3">
            <div class="auto-complete-wrapper col-12 col-lg-4">
               <SearchAutocomplete @update-to-destination="handlerUpdateDestination" :label="$t('hotelSearch_lblDestination')" />
            </div>
            <div class="col-6 col-lg-2">
               <GtsDateRangePicker
                  :key="refreshKeyForDate"
                  @update-to-date="handlerUpdateDate"
                  calendarType="default"
                  :label="$t('hotelSearch_lblDate')"
                  ref="hotelSearchDateRangePicker" />
            </div>
            <div class="col-6 col-lg-2">
               <SearchGuestComponent @update-to-rooms="handlerUpdateRooms" ref="searchGuest" :label="$t('hotelSearch_lblGuest')" />
            </div>
            <div class="col-12 col-lg-2">
               <UISelectBox
                  id="select_customerGender"
                  :label="$t('hotelSearch_lblNationality')"
                  :options="CountryList"
                  allow-search
                  v-model="hiddenNationalIdentity.code"
                  has-icons />
            </div>
            <div class="col-12 col-lg-2 hotel-search-btn-wrapper">
               <UIButton className="hotel" :text="$t('common_search')" @click.native="handlerSearchHotel" :isSpinnerActive="spinnerStatus" />
            </div>
         </div>
      </div>
      <UIModal v-show="showManuelRequestModal" :noBorder="true" :hasSlot="true" @close-modal="showManuelRequestModal = false">
         <div class="hotel-manuel-request-form">
            <div class="hotel-manuel-request-info-wrapper col-12">
               <div class="hotel-manuel-request-form__title">
                  <i class="gts-icon icon-popup-cursor"></i>
                  <h3 class="txt-black">{{ $t("hotel_manuelRequestModalTitle") }}</h3>
               </div>
               <div class="hotel-manuel-request-form__content">
                  <p>{{ $t("hotel_lblManuelRequest") }}</p>
                  <div class="hotel-manuel-request-form__info-wrapper">
                     <div class="hotel-manuel-request-form__infos --side-bg"></div>
                     <div class="hotel-manuel-request-form__hotel-info">
                        <span class="label-title">{{ $t("hotel_lblDestinationName") }}</span>
                        <span class="text"> {{ searchQuery.destination.name }}</span>
                     </div>
                     <div class="hotel-manuel-request-form__hotel-info">
                        <span class="label-title">{{ $t("hotel_lblCustomerNationality") }}</span>
                        <span class="text"> {{ searchQuery.customerNationality }}</span>
                     </div>
                     <div class="hotel-manuel-request-form__hotel-info">
                        <span class="label-title">{{ $t("hotel_lblDateInfo") }}</span>
                        <div class="d-flex">
                           <span class="text"> {{ searchQuery.checkInDate | FormatterDate }} </span>
                           <span class="text"> / {{ searchQuery.checkOutDate | FormatterDate }}</span>
                        </div>
                     </div>
                     <div class="hotel-manuel-request-form__hotel-info">
                        <span class="label-title">{{ $t("hotel_lblRoomInfos") }}</span>
                        <div class="d-flex" v-for="(room, index) in searchQuery.rooms" :key="'room' + index">
                           <span class="text"
                              ><i class="txt-black">{{ $t("hotel_lblRoom") }}-{{ index + 1 }}</i
                              >: {{ room.adults }} {{ $t("hotel_lblAdult") }}</span
                           >
                           <span class="text" v-if="room.children > 0">,{{ room.children }} {{ $t("hotel_lblChild") }}</span>
                        </div>
                     </div>
                  </div>
               </div>
               <div class="hotel-manuel-request-form__inputs">
                  <div>
                     <div class="hotel-manuel-request-form__inputs--line left d-none d-lg-block"></div>
                     <div class="hotel-manuel-request-form__inputs--line right d-none d-lg-block"></div>
                     <h2 class="hotel-manuel-request-form__inputs--title">{{ $t("hotel_manuelFormTitle") }}</h2>
                  </div>
                  <div class="form-wrapper">
                     <div class="inner-form-wrapper-mail">
                        <UIInput
                           :label="$t('hotelSearchForm_lblMail')"
                           lowercase
                           id="agencyEmail"
                           v-model="formData.email"
                           inputModel="email"
                           :placeholder="$store.state.user.email" />
                     </div>
                     <div class="inner-form-wrapper-phone">
                        <!-- Phone Country -->
                        <UISelectBox
                           :label="$t('hotelSearchForm_lblCode')"
                           id="orderSelect"
                           :options="PhoneCountry"
                           v-model="formData.phone.country"
                           allow-search />

                        <!-- Phone City -->
                        <UIInput
                           style="white-space: pre"
                           :label="$t('hotelSearchForm_lblArea')"
                           id="phoneCity"
                           v-model="formData.phone.city"
                           custom-class="g-short-input"
                           :custom-validation-txt="$t('manuelRequestContact_vldMin4')"
                           v-input="{ type: 'numeric', len: [2, 4], required: true, requiredMsg: $t('common_cannotBeEmpty') }" />

                        <!-- Phone Number -->
                        <UIInput
                           :label="$t('common_phone')"
                           id="phoneNumber"
                           v-model="formData.phone.number"
                           v-input="{ type: 'numeric', len: [3, 8], required: true, requiredMsg: $t('common_cannotBeEmpty') }" />
                     </div>
                  </div>
                  <!-- <div class="hotel-manuel-request-form__inputs--content form-wrapper" style="justify-content: flex-start; width: 174px">
                  </div> -->
               </div>
               <div class="hotel-manuel-request-form__cta">
                  <UIButton
                     rounded
                     className="accent-primary_outline"
                     :text="$t('hotel_manuelRequestCancel')"
                     @click.native="showManuelRequestModal = false" />
                  <UIButton rounded className="accent-primary" :text="$t('hotel_manuelRequestConfirm')" @click.native="handlerManuelRequest" />
               </div>
            </div>
         </div>
      </UIModal>
   </div>
</template>

<script>
import { hotelService } from "@/service/index.js";
import SearchAutocomplete from "@/components/structural/hotel/common/SearchAutocomplete.vue";
import SearchGuestComponent from "@/components/structural/hotel/common/SearchGuest.vue";
import GtsDateRangePicker from "@/components/utils/GtsDateRangePicker.vue";
import UIButton from "@/components/unitary/Button.vue";
import UIModal from "@/components/unitary/form2/UIModal.vue";
import UIInput from "@/components/unitary/form2/UIInput.vue";
import "vue-multiselect/dist/vue-multiselect.min.css";
import i18n from "@/fmt/i18n.js";
import Popup from "@/gts-popup.js";
import moment from "moment";

//add for ui
import UISelectBox from "@/components/unitary/form2/UISelectBox.vue";

export default {
   name: "SearchApp",
   components: {
      SearchAutocomplete,
      GtsDateRangePicker,
      SearchGuestComponent,
      UIButton,
      UIModal,
      UISelectBox,
      UIInput,
   },

   data() {
      return {
         formData: {
            email: "",
            phone: {
               country: "+49",
               city: "",
               number: "",
            },
         },
         searchQuery: {
            destination: {
               code: "",
               type: "HOTEL",
               name: "",
            },
            checkInDate: "",
            checkOutDate: "",
            rooms: "",
            currency: "",
            customerNationality: "DE",
         },
         refreshKeyForDate: 1,
         channels: {},
         searchResult: [],
         searchResultRecommendedHotels: [],
         hotelList: [],
         recommendedHotelList: [],
         searchUuid: "",
         spinnerStatus: false,
         hiddenNationalIdentity: {
            code: "DE",
            nameTr: "Almanya",
            nameEn: "Germany",
            nameDe: "Deutschland",
            phoneCode: "+49",
         },
         currentLang: "",
         showManuelRequestModal: false,
         isStickyBarActive: false,
         isErrorMessageShown: false,
         calendarShownCount: false,
      };
   },
   // mounted() {
   //    window.addEventListener("scroll", this.handleScroll);
   // },
   // destroyed() {
   //    window.removeEventListener("scroll", this.handleScroll);
   // },
   methods: {
      getCurrencySymbol(currency) {
         return this.$options.filters.currency(currency);
      },
      // handleScroll() {
      //    let isTargetElementVisible = true; // Add a variable to keep track of target element's visibility status

      //    if (window.pageYOffset == 0) {
      //       this.isStickyBarActive = false;
      //       this.$emit("sticky-bar-status", this.isStickyBarActive);
      //    }
      //    /* reference: https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API */
      //    const observer = new IntersectionObserver(
      //       (entries) => {
      //          entries.forEach((entry) => {
      //             if (entry.intersectionRatio === 0) {
      //                // Target element is not visible
      //                if (isTargetElementVisible) {
      //                   isTargetElementVisible = false; // Update visibility status
      //                   this.isStickyBarActive = true;
      //                   this.$emit("sticky-bar-status", this.isStickyBarActive);
      //                }
      //             } else if (entry.intersectionRatio > 0) {
      //                // Target element is visible
      //                if (!isTargetElementVisible) {
      //                   isTargetElementVisible = true; // Update visibility status
      //                   this.isStickyBarActive = false;
      //                   this.$emit("sticky-bar-status", this.isStickyBarActive);
      //                }
      //             }
      //          });
      //       },
      //       {
      //          threshold: 0, // Use threshold 0 to trigger when the target element is completely invisible
      //       }
      //    );

      //    const targetElement = document.querySelector(".hotel-search-count-sorting-container");
      //    observer.observe(targetElement);
      // },
      handlerUpdateDate(dateRange) {
         var self = this;
         self.searchQuery.checkInDate = moment(dateRange.startDate).format("YYYY-MM-DD");
         self.searchQuery.checkOutDate = moment(dateRange.endDate).format("YYYY-MM-DD");

         setTimeout(() => {
            if (document.querySelector(".show-calendar")) {
               self.calendarShownCount = true;
            } else {
               if (self.calendarShownCount) {
                  this.$refs.searchGuest.$el.querySelector("#dropdownMenuButton1").click();
               }
            }
         }, 200);
      },
      handlerUpdateRooms(rooms) {
         var self = this;
         self.searchQuery.rooms = rooms;
      },
      handlerUpdateDestination(destination) {
         var self = this;
         self.searchQuery.destination = destination;

         setTimeout(() => {
            if (document.querySelector(".search-autocomplete-list")) {
               return;
            } else {
               this.$refs.hotelSearchDateRangePicker.$el.querySelector(".gts-date-range-input").click();
            }
         }, 200);
      },
      handlerSearchHotel() {
         var self = this;
         self.spinnerStatus = true;
         self.$store.commit("hotel/setIsSearching", true);
         self.$store.commit("hotel/setOnUsFilterStatus", false);
         self.$store.commit("hotel/setHotelStarCategoryClearAll");
         self.$store.commit("hotel/setHotelStarCategory", -1);
         // Filtre aktif iken search atılıyor mu kontrol
         self.$store.commit("hotel/setIsSearchingCheck", true);

         const checkChildrenAgesArr = self.searchQuery.rooms.filter((item) => item.children > 0 && item.childrenAges.length != item.children);

         if (checkChildrenAgesArr.length > 0) {
            self.spinnerStatus = false;
            Popup.info(this.$i18n.t("Popup_lblChildrenAgesWarn"), this.$i18n.t("Popup_lblChildrenAgesWarnText"));
            return;
         }

         if (
            self.searchQuery == {} ||
            self.searchQuery.destination.code == "null" ||
            self.searchQuery.destination.code == "undefined" ||
            (Object.keys(self.searchQuery.destination).length === 0 && self.searchQuery.destination.constructor === Object)
         ) {
            self.spinnerStatus = false;
            if (!this.isErrorMessageShown) {
               this.isErrorMessageShown = true;
               Popup.warning(this.$i18n.t("Popup_lblEmptySearch"), this.$i18n.t("Popup_txtEmptySearch"));
            }
            return;
         } else {
            // if (this.isStickyBarActive) {
            //    this.isStickyBarActive = false;
            //    this.$emit("sticky-bar-status", this.isStickyBarActive);
            //    window.scrollTo(0, 0);
            // }

            self.hotelList = [];
            this.$store.commit("hotel/updateSearchResult", self.hotelList);

            //resetFiltersCount before search
            this.$store.dispatch("hotel/resetHotelFiltersCount");

            self.recommendedHotelList = [];
            this.$store.commit("hotel/updateSearchResultRecommendedHotel", self.recommendedHotelList);
            this.$store.commit("hotel/updateSearchResultStatus", null);

            hotelService.setSearchCheckinDate(self.searchQuery.checkInDate);
            hotelService.setSearchCheckoutDate(self.searchQuery.checkOutDate);
            hotelService.setSearchDestinationCode(self.searchQuery.destination.code);
            hotelService.setSearchDestinationType(self.searchQuery.destination.type);
            hotelService.setSearchDestinationName(self.searchQuery.destination.name);

            self.searchQuery.customerNationality = this.hiddenNationalIdentity.code;

            this.$store.commit("hotel/updateBasketResponse", {});
            this.$store.commit("hotel/updateSelectedRooms", {});

            this.$store.commit("hotel/reSetSearchQuery", self.searchQuery);

            this.$store.commit("hotel/setSearchQuery", self.searchQuery);
            hotelService
               .hotelSearch(self.searchQuery)
               .then((response) => {
                  if (!response.result.success) {
                     Popup.result(response.result);
                     return;
                  }
                  let currency = this.getCurrencySymbol(response.hotels[0].price.currency);
                  this.$store.commit("hotel/setFilterCurrency", currency);

                  this.$store.commit("hotel/generateSearchUuid", response.searchUuid);
                  var searchResultStore = this.$store.state.hotel.searchResult;
                  self.channels = response.channels;
                  if (response.agencyPhone)
                     self.formData.phone = {
                        country: response.agencyPhone.country,
                        city: response.agencyPhone.city,
                        number: response.agencyPhone.number,
                     };

                  if (searchResultStore.length == 0) {
                     self.searchResult = response.hotels;
                     self.searchResultRecommendedHotels = response.recommendedHotels;
                  } else {
                     self.searchResult = [];
                     self.searchResultRecommendedHotels = [];

                     self.hotelList = [];
                     self.recommendedHotelList = [];

                     self.searchResult = response.hotels;
                     self.searchResultRecommendedHotels = response.recommendedHotels;
                  }

                  this.$store.commit("hotel/updateMinMaxPrice", {
                     minPrice: response.minPrice,
                     maxPrice: response.maxPrice,
                  });
                  this.$store.commit("hotel/updateSearchResultStatus", response.result);
               })
               .then(() => {
                  self.searchResult.map((hotelItem) => {
                     const hotelItemObj = {
                        restrictionType: hotelItem.price.restrictionType,
                        restrictionValue: hotelItem.price.restrictionValue,
                        id: hotelItem.id,
                        name: hotelItem.name,
                        addOn: hotelItem.hasAddOn,
                        hasPhoto: hotelItem.hasPhoto,
                        thumbnailImage: hotelItem.thumbnailImage,
                        topFacilities: hotelItem.topFacilities,
                        allFacilities: hotelItem.facilities,
                        specialOffer: hotelItem.specialOffer,
                        boardTypes: hotelItem.boardTypes,
                        isPartialPaymentExist: hotelItem.isPartialPaymentExist,
                        hotelStarCategory: hotelItem.hotelStarCategory,
                        location: {
                           coordinate: {
                              lat: hotelItem.latitude,
                              lon: hotelItem.longitude,
                           },
                           address: hotelItem.address,
                        },
                        score: {
                           point: hotelItem.hotelScore,
                           star: hotelItem.star,
                        },
                        price: {
                           amount: hotelItem.price.total,
                           currency: hotelItem.price.currency,
                           totalAmount: hotelItem.price.total,
                           originalAmount: hotelItem.price.originalAmount,
                           channelId: hotelItem.price.channelId,
                           onUs: self.channels[hotelItem.price.channelId]?.onUs,
                           minStay: false,
                           stopStay: false,
                           maxStay: false,
                        },
                        closedHotel: {
                           isClosed: hotelItem.closedHotel.isClosed,
                           fromDate: hotelItem.closedHotel.fromDate,
                           toDate: hotelItem.closedHotel.toDate,
                           description: hotelItem.closedHotel.description,
                        },
                        offers: {
                           recommended: false,
                           earlyBooking: false,
                           bestPrice: false,
                        },
                        content: {
                           description: null,
                           descriptions: null,
                           location: {
                              coordinate: {
                                 lat: hotelItem.latitude,
                                 lon: hotelItem.longitude,
                              },
                           },
                        },
                     };
                     hotelItemObj.isShowStatusActive = true;
                     self.hotelList.push(hotelItemObj);
                     this.$store.commit("hotel/updateSearchResult", self.hotelList);
                  });

                  self.searchResultRecommendedHotels.map((recommendedHotelItem) => {
                     const recommendedHotelItemObj = {
                        restrictionType: recommendedHotelItem.price.restrictionType,
                        restrictionValue: recommendedHotelItem.price.restrictionValue,
                        id: recommendedHotelItem.id,
                        name: recommendedHotelItem.name,
                        addOn: recommendedHotelItem.hasAddOn,
                        hasPhoto: recommendedHotelItem.hasPhoto,
                        thumbnailImage: recommendedHotelItem.thumbnailImage,
                        topFacilities: recommendedHotelItem.topFacilities,
                        hotelStarCategory: recommendedHotelItem.hotelStarCategory,
                        isPartialPaymentExist: recommendedHotelItem.isPartialPaymentExist,
                        allFacilities: recommendedHotelItem.facilities,
                        specialOffer: recommendedHotelItem.specialOffer,
                        boardTypes: recommendedHotelItem.boardTypes,
                        location: {
                           coordinate: {
                              lat: recommendedHotelItem.latitude,
                              lon: recommendedHotelItem.longitude,
                           },
                           address: recommendedHotelItem.address,
                        },
                        score: {
                           point: recommendedHotelItem.hotelScore,
                           star: recommendedHotelItem.star,
                        },
                        price: {
                           amount: recommendedHotelItem.price.total,
                           currency: recommendedHotelItem.price.currency,
                           totalAmount: recommendedHotelItem.price.total,
                           originalAmount: recommendedHotelItem.price.originalAmount,
                           channelId: recommendedHotelItem.price.channelId,
                           onUs: self.channels[recommendedHotelItem.price.channelId]?.onUs,
                           minStay: false,
                           stopStay: false,
                           maxStay: false,
                        },
                        offers: {
                           recommended: false,
                           earlyBooking: false,
                           bestPrice: false,
                        },
                        content: {
                           description: null,
                           descriptions: null,
                           location: {
                              coordinate: {
                                 lat: recommendedHotelItem.latitude,
                                 lon: recommendedHotelItem.longitude,
                              },
                           },
                        },
                     };

                     recommendedHotelItemObj.isShowStatusActive = true;
                     self.recommendedHotelList.push(recommendedHotelItemObj);
                     this.$store.commit("hotel/updateSearchResultRecommendedHotel", self.recommendedHotelList);
                  });
                  self.spinnerStatus = false;
                  self.$store.commit("hotel/setIsSearching", false);
                  self.$store.commit("hotel/setSearchQuery", null);
                  this.funcRefreshKeys();
               })
               .finally(() => {
                  if (
                     self.recommendedHotelList.length == 0 &&
                     self.hotelList.length == 0 &&
                     Object.keys(JSON.parse(JSON.stringify(self.searchQuery.destination))).length > 0
                  ) {
                     this.$store.commit("hotel/setIsSearching", false);
                     self.showManuelRequestModal = true;
                     self.formData.email = this.$store.state.user.user.email;
                     self.spinnerStatus = false;
                     self.$store.commit("hotel/setIsSearchingCheck", true);

                     return;
                  } else {
                     self.$store.commit("hotel/setIsSearchingCheck", false);
                     self.spinnerStatus = false;
                     return;
                  }
               });
         }
      },
      handlerManuelRequest() {
         const self = this;

         let manuelRequest = {
            checkInDate: moment(self.searchQuery.checkInDate).format(self.$constants.dateFormat),
            checkOutDate: moment(self.searchQuery.checkOutDate).format(self.$constants.dateFormat),
            currency: self.searchQuery.currency,
            customerNationality: self.searchQuery.customerNationality,
            destination: self.searchQuery.destination,
            rooms: self.searchQuery.rooms,
            // uuid: self.searchQuery.uuid,
            email: self.formData.email,
            phone: self.formData.phone,
         };

         hotelService.hotelManualAdd(manuelRequest).then((response) => {
            if (response.result.success) {
               Popup.info(this.$i18n.t("Popup_lblRequestReceived"), this.$i18n.t("Popup_lblPlsWaitForContact"));
            }
            self.showManuelRequestModal = false;
         });
      },
      customLabel({ nameTr }) {
         return `${nameTr}`;
      },
      setCustomerNationalIdentity(data) {
         const self = this;
         self.searchQuery.customerNationality = data.code.toUpperCase();
      },
      funcRefreshKeys() {
         this.refreshKeyForDate++;
      },
   },
   created() {
      var self = this;
      self.searchQuery.destination.code = hotelService.getSearchDestinationCode();
      self.searchQuery.destination.type = hotelService.getSearchDestinationType();
      self.searchQuery.destination.name = hotelService.getSearchDestinationName();
      self.currentLang = i18n.locale;

      //resetFiltersCount before search
      this.$store.dispatch("hotel/resetHotelFiltersCount");
   },
   computed: {
      PhoneCountry() {
         const self = this;
         //mapping countrylist for select array [tr]
         let activeLanguage = self.$store.state.user.user.language;

         let selectorStr = "";
         selectorStr;
         switch (activeLanguage) {
            case 1:
               selectorStr = "Tr";
               break;

            case 2:
               selectorStr = "En";
               break;

            case 3:
               selectorStr = "De";
               break;

            default:
               selectorStr = "En";
               break;
         }

         return self.$store.state.app.countryList.map((country) => {
            var obj = {
               value: country.phoneCode,
               name: `${country.phoneCode}`,
               icon: country.code,
            };
            return obj;
         });
      },
      CountryList() {
         const self = this;
         //mapping countrylist for select array [tr]
         let activeLanguage = self.$store.state.user.user.language;

         let selectorStr = "";
         switch (activeLanguage) {
            case 1:
               selectorStr = "Tr";
               break;

            case 2:
               selectorStr = "En";
               break;

            case 3:
               selectorStr = "De";
               break;

            default:
               selectorStr = "En";
               break;
         }

         return self.$store.state.app.countryList.map((data) => {
            var obj = {
               name: data[`name${selectorStr}`],
               value: data.code.toUpperCase(),
               icon: data.code,
            };
            return obj;
         });
      },
   },
   watch: {
      "$i18n.locale"() {
         this.funcRefreshKeys();
      },
      "$store.state.hotel.isCalendarTriggered": {
         handler: function (status) {
            if (status) {
               this.searchQuery = this.$store.state.hotel.reSetSearchQuery;
               this.handlerSearchHotel();
               this.$store.commit("hotel/setIsCalendarTriggered", false);
               this.searchQuery.checkInDate = this.$store.state.hotel.reSetSearchQuery.checkInDate;
               this.searchQuery.checkOutDate = this.$store.state.hotel.reSetSearchQuery.checkOutDate;
               this.handlerUpdateDate();
            }
         },
      },
      "$store.state.hotel.datePickerStatus": {
         handler: function (status) {
            const self = this;

            if (status) {
               setTimeout(() => {
                  self.$refs.hotelSearchDateRangePicker.$el.querySelector(".gts-date-range-input").click();
                  self.$store.state.hotel.datePickerStatus = false;
               }, 500);
            }
         },
      },
   },
};
</script>
<style lang="scss">
#hotel-search-form-container {
   // .identifier {
   //    border: 1px solid #eee;
   //    padding: 0.5rem;
   // }
   &.sticky {
      padding: 16px;
      .identifier {
         .col-lg-2 .daterange-picker-container,
         .col-lg-2 .guest-dropdown-wrapper,
         .col-lg-2 .g-select-box-container {
            label {
               display: none !important;
            }
         }
         .auto-complete-wrapper {
            .gts-autocomplete-wrapper {
               label {
                  display: none !important;
               }
            }
         }
      }
   }
}
</style>
<style lang="scss" scoped>
#hotel-search-form-container {
   .hotel-search-btn-wrapper {
      align-items: flex-end;
      display: flex;
      margin-bottom: 0.25rem;

      button {
         width: 100%;
      }
   }
   .hotel-manuel-request-form {
      padding: 1rem;
      width: 100%;
      margin-top: -1.5rem;
      .form-wrapper {
         margin-bottom: 1.25rem;
         padding: 0;
         display: grid;
         grid-template-columns: 1fr 1fr;
         grid-column-gap: 1rem;
         margin-top: 1rem;

         @media (max-width: 576px) {
            width: 100%;
            padding: 0 !important;
            // border: 1px solid red !important;
         }

         ::v-deep {
            .g-input-container {
               .gts-input-wrapper {
                  input {
                     background: #f7f9fc;
                     text-indent: 0.5rem;
                     border: 2px solid #cacaca;

                     &#agencyEmail {
                        width: 100% !important;
                     }
                     &#phoneCity {
                        width: 54px !important;
                     }
                     &#phoneNumber {
                        width: 100% !important;
                        margin-left: 0.5rem;
                     }
                  }
               }
            }
         }

         &:last-child {
            width: 100%;

            textarea {
               width: 100%;
               height: 106px;
               background: #f7f9fc;
               border: 2px solid #cacaca;
               border-radius: 0.5rem;
               padding: 0.5rem 1rem;
            }
         }
         // padding: 0.5rem;
         // margin: 0.5rem;
         // background: green;

         &.phone {
            display: flex;
            margin-top: 16px;
            ::v-deep {
               .g-select-box-container {
                  width: 82px;
                  margin-right: 0.5rem;
                  margin-left: 2rem;
               }
               .gts-input-wrapper {
                  #agencyEmail {
                     // width: 142px;
                  }
               }
               .g-input-container {
                  &:last-child {
                     // margin-left: 0.5rem;
                  }
               }
            }
         }

         .inner-form-wrapper-phone {
            display: flex;

            ::v-deep {
               .g-select-box-container {
                  width: 82px;
                  margin-right: 0.5rem;
               }
            }
         }
      }
      &__title {
         text-align: center;
         color: #1e2023;
         font-size: var(--xlarge);
         letter-spacing: 0px;
         i {
            margin: auto;
            width: 114px !important;
            height: 79.06px !important;
         }
      }
      &__content {
         text-align: center;
         font-size: var(--small);
         color: #6d6e6f;
         letter-spacing: 0.22px;
         margin-top: 0.5rem;
      }
      &__info-wrapper {
         background: #f7f7f7 0% 0% no-repeat padding-box;
         border: 1px solid #ebebeb;
         border-radius: 16px;
         padding: 8px;
         position: relative;
         margin-top: 0.5rem;
         &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 16px; /* adjust as needed */
            height: 100%;
            background: #ebebeb 0% 0% no-repeat padding-box;
            border-radius: 8px 0px 0px 8px;
         }
         &.--side-bg {
            width: 16px;
            height: 100%;
            padding: 0;
         }
      }
      &__inputs {
         background: #f7f7f7 0% 0% no-repeat padding-box;
         border: 1px solid #ebebeb;
         border-radius: 16px;
         padding: 16px 24px 16px 24px;
         margin-top: 1rem;
         &--line {
            background-color: #636f7b;
            height: 2px;
            width: 40px;
            opacity: 0.27;
            position: relative;
            &.left {
               top: 16px;
               left: 106px;
            }
            &.right {
               top: 14px;
               left: 349px;
            }
         }
         &--title {
            font-weight: bold;
            color: #363636;
            text-align: center;
         }
         &--content {
            display: flex;
            justify-content: space-evenly;
            margin-top: 12px;
            .g-select-box-container {
               width: 26%;
            }
         }
      }
      &__cta {
         margin-top: 1rem;
         display: flex;
         justify-content: space-evenly;
         .gts-btn {
            height: 40px;
         }
      }
      &__hotel-info {
         display: flex;
         flex-direction: column;
         padding-left: 24px;
         margin-top: 20px;
         align-items: flex-start;
         .label-title {
            font-weight: bold;
            font-size: var(--normal);
            color: #363636;
         }
         .text {
            color: #363636;
            font-size: var(--small);
            letter-spacing: 0;
            text-align: -webkit-auto;
         }
      }
   }
}
</style>
<style lang="scss" scoped>
.search-component-wrapper {
   // width: 80%;
   // max-width: 80%;
   // background-color: var(--c-primary);
   // box-shadow: 0px 2px 6px #0000001a;
   // border-radius: 12px;
   // margin-bottom: 50px;
   color: var(--txt-black);

   .search-component-inner {
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      justify-content: space-between;
      // padding: 32px;

      .search-component-inner-section {
         .search-input-item {
            display: flex;
            flex-direction: column;

            label {
               margin-bottom: 8px;
               font-weight: 500;
            }
            input,
            select,
            .multiselect {
               width: auto;
               height: 50px;
               max-height: 50px;
               height: 50px;

               ::v-deep .multiselect__tags {
                  display: flex;
                  justify-content: flex-start;
                  align-items: center;
                  min-height: 50px;
                  padding: 0 16px;
                  border: 1px solid #cacbcc;
                  border-radius: 12px;
                  color: var(--txt-black);

                  & .multiselect__single {
                     margin-bottom: 0;
                  }
               }
            }
            .multiselect {
               width: 150px;
               max-width: 150px;
            }
         }

         &.search-location-section {
            width: 300px;
            max-width: 300px;
         }
         &.search-cta-section {
            display: inline-flex;
            justify-content: center;
            width: 16%;

            & > button {
               width: 100%;
               height: 43px;
               margin-bottom: 2px;
            }

            ::v-deep .lds-ring {
               width: 32px;
               height: 32px;
            }
         }
      }
      .column {
         ::v-deep label {
            font-weight: 500;
         }
      }
   }
}
</style>
