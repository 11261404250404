<template>
   <span :class="`gts-info-badge gts-info-badge__${badgeClassName} ${modifier ? modifier : ''}`">{{ badgeText }}</span>
</template>

<script>
export default {
   name: "UIInfoBadge",
   props: {
      className: { type: String },
      text: { type: String },
      modifier: { type: String },
   },
   data() {
      return {
         badgeClassName: this.className,
         badgeText: this.text,
         badgeModifier: this.modifier,
      };
   },
   watch: {
      text: function (newValue) {
         var self = this;
         self.badgeText = newValue;
      },
   },
};
</script>

<style lang="scss" scoped>
/* __ClassNameProp --modifier(if exists)*/
html body .gts-info-badge {
   padding: 0.125rem 0.5rem;
   height: 19px;
   background-color: transparent;
   border: 1px solid;
   border-radius: 3px;
   color: black;
   font-size: var(--small);
   font-weight: var(--bold);
   cursor: default;
   white-space: nowrap;

   &__default {
      background-color: #f5f6fb;
      border-color: #d9dade;
      color: #363940;
   }

   &__EHtlRefundType {
      &.--nonrefundable {
         background-color: #ff5959;
         border-radius: 0.25rem;
         font-weight: 700;
         color: #ffffff;
      }
      &.--refundable {
         background-color: #e8faf1;
         border-radius: 0.25rem;
         font-weight: 700;
         border-color: #1dc973;
         color: #1dc973;
      }
   }

   &__HtlProviderInfo {
      border-radius: 0.25rem;
      opacity: 1;
      text-align: center;
      font-weight: 700;
      display: flex;
      align-items: center;
      justify-content: center;
      line-height: 13px;
      height: 21px;
      box-sizing: content-box;
      padding: 0.125rem 0.35rem;

      &.--direct {
         background: var(--accent-primary-lightest);
         border: 1px solid #1667be;
         color: var(--primary);
      }
      &.--direct-recommended {
         background: var(--primary);
         border: 1px solid #1667be;
         color: #ffffff;
      }
      &.--provider {
         background: var(--secondary-lightest);
         border: 1px solid #ff8b00;
         color: var(--secondary);
      }
   }
   &__HotelStatus {
      border-radius: 0.25rem;
      opacity: 1;
      text-align: center;
      font-weight: 700;
      display: flex;
      align-items: center;
      justify-content: center;
      line-height: 13px;
      height: 21px;
      box-sizing: content-box;
      padding: 0.125rem 0.35rem;
      &.--closed {
         background: #f0343424;
         border-radius: 3px;
         color: #f03434;
      }
   }
}
</style>
