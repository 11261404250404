import { PRIVATE_HTTP, PUBLIC_HTTP } from "./index";
import router from "@/router/index.js";

const ANNOUNCEMENT_ENDPOINT = "api/announcement/getAnnouncement";
const LIST_ANNOUNCEMENTS = "api/announcement/listAnnouncements";

export var getAnnouncement = (requestModel) => {
   const promise = PUBLIC_HTTP.post(ANNOUNCEMENT_ENDPOINT, requestModel);
   const responseData = promise
      .then((response) => response.data)
      .catch((error) => {
         if (error.response.status === 401) {
            if (router.currentRoute.path != "/login") {
               router.push("/login");
            }
         }
         // else
         // {
         //   console.log(error);
         // }
      });

   return responseData;
};
export var listAnnouncements = (requestModel) => {
   const promise = PRIVATE_HTTP().post(LIST_ANNOUNCEMENTS, requestModel);
   const responseData = promise
      .then((response) => response.data)
      .catch((error) => {
         if (error.response.status === 401) {
            if (router.currentRoute.path != "/login") {
               router.push("/login");
            }
         }
         // else
         // {
         //   console.log(error);
         // }
      });

   return responseData;
};
