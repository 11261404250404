export const ETaskStatus = {
   "-1": "ALL",
   0: "UNKNOWN",
   1: "OPEN",
   2: "CLOSED",
};

export const ETaskSubStatus = {
   "-1": "ALL",
   0: "UNKNOWN",
   1: "NEW",
   2: "PROCESSING",
   3: "WAITING_ON_OPERATOR",
   4: "WAITING_ON_PROVIDER",
   5: "WAITING_ON_AGENCY",
   6: "CLOSED",
   7: "REPLIED_BY_PROVIDER",
   8: "REPLIED_BY_AGENCY",
   9: "REOPEN",
};

export const ETaskType = {
   "-1": "ALL",
   0: "UNKNOWN",
   1: "PNR_RESERVE",
   2: "PNR_REISSUE",
   3: "PNR_ANCILLARY",
   4: "PNR_CORRECTION",
   5: "PNR_DIVIDE",
   6: "PNR_OPEN",
   7: "PNR_ADD",
   8: "PNR_UPDATE",
   9: "PNR_UPDATE_PAX",
   21: "VOUCHER_REVERSE",
   22: "VOUCHER_REISSUE",
   23: "VOUCHER_ADD",
   24: "VOUCHER_NOTE",
   25: "VOUCHER_UPDATE",
   26: "VOUCHER_RECONFIRMATION",
   41: "TNR_ADD",
   42: "TNR_REVERSE",
   43: "TNR_REISSUE",
   61: "PAYMENT_REVERSE",
};

export const ETaskSubType = {
   "-1": "ALL",
   0: "UNKNOWN",
   1: "BASIC",
};

export const ETaskClosingType = {
   "-1": "ALL",
   0: "UNKNOWN",
   1: "COMPLETED",
   2: "CANCELED",
   3: "WONT_FIX",
   4: "DUBLICATE",
};
