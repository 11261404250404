const constants = {
   DATE_PATTERN: "YYYY-MM-DD",
   TIME_PATTERN: "HH:mm:ss",
   DATE_TIME_PATTERN: "YYYY-MM-DDTHH:mm:ss",
   CDN_URL: process.env.VUE_APP_CDN_URL,
};

constants.install = function (Vue) {
   Vue.prototype.$constants = {
      dateFormat: constants.DATE_PATTERN,
      timeFormat: constants.TIME_PATTERN,
      dateTimeFormat: constants.DATE_TIME_PATTERN,
      cdn: constants.CDN_URL,
      customerBirthdate: moment("1971-01-01", "YYYY-MM-DD"),
      isMobile: window.innerWidth <= 1200,
      showTargetAchievementIcon: Boolean,
      brand: {},
   };
};

import moment from "moment";

export default constants;

// constants.install = function (Vue) {
//     Vue.prototype.$constants = (key) => {
//         return constants[key];
//     };
// };
