<template>
   <div :class="bgColor == '' ? 'widget-c' : 'widget-c' + ' ' + bgColor">
      <i :class="icon == '' ? '' : icon"></i>
      <div class="widget-wrapper">
         <div :class="title ? 'widget-title' : slotable ? '' : 'widget-nontitle'">
            <span v-if="title !== ''">{{ title }}</span>
            <span class="non-title" v-if="htmlContent" v-html="htmlContent"></span>
            <span class="non-title" v-if="text !== '' && !htmlContent">{{ text }}</span>
         </div>
         <div class="widget-event" v-if="slotable">
            <slot name="event-content"></slot>
         </div>
         <div class="widget-icon" v-else>
            <i id="rounded-tick" :class="slotIcon"></i>
         </div>
      </div>
   </div>
</template>

<script>
export default {
   name: "InfoWidget",
   props: {
      title: { type: String, default: "" },
      icon: { type: String, default: "" },
      text: { type: String, default: "" },
      htmlContent: { type: String, default: "" },
      deactive: { type: Boolean, default: true },
      slotable: { type: Boolean, default: true },
      slotIcon: { type: String, default: "gts-icon icon-rounded-tick" },
      bgColor: { type: String, default: "" },
   },
   data() {
      return {};
   },
};
</script>

<style lang="scss" scoped>
$icon-size-m: 40px !important;
#rounded-tick {
   width: $icon-size-m;
   height: $icon-size-m;
}
.widget-c {
   display: flex;
   border: 1px solid #29cb96;
   background: linear-gradient(90deg, #e0f9e6cb 0%, #f5fcf5 100%);
   padding: 0.75rem;
   margin-bottom: 1rem;
   border-radius: 0.5rem;
   &.bg-white {
      background: #fff;
   }
   i {
      width: 60px !important;
      height: 60px !important;
   }
   .widget-wrapper {
      width: 100%;
      display: flex;
      justify-content: space-between;
      .widget-title {
         display: flex;
         flex-direction: column;
         padding-left: 0.75rem;
         padding-top: 0.25rem;
         font-weight: 500;

         span {
            font-size: 18px;
            padding: 2px;
         }
         .non-title {
            font-weight: 500;
            font-size: 15px;
         }
      }
      .widget-nontitle {
         display: flex;
         padding: 1.25rem;
         .non-title {
            font-weight: 500;
            font-size: 15px;
         }
      }
      .widget-event {
         display: flex;
         justify-content: center;
         i {
         }
      }
      .widget-icon {
         display: flex;
         justify-content: center;
         align-items: center;
         padding-right: 1rem;
      }
   }
   &.deactive {
      filter: grayscale(100%);
      border: 2px solid #f3dcdc;
      background: white !important;
   }
}
</style>
