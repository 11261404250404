<template>
   <div
      class="ssr-selection-box-container"
      :class="HasSelection ? 'ssr-selected' : ''"
      @click.self="ssr.isExpandable ? handlerShowDetails() : handlerSelectSSR()">
      <div class="ssr-main-wrapper">
         <section class="ssr-icon-wrapper">
            <i class="gts-icon icon-lg" :class="'icon-' + ssr.icon"></i>
            <span class="icon-selected" v-if="HasSelection"> </span>
         </section>
         <!-- SSR Informations -->
         <section class="ssr-informations-wrapper">
            <span class="ssr-info-title"> {{ $t(`ssrSelectionBox_lbl${ssr.ssrName.toLowerCase()}Title`) }}</span>
            <span class="ssr-info-description" v-if="ssr.isExpandable && HasSelection">{{ SelectedIdentifierDescription }} </span>
            <span class="ssr-info-description" v-else-if="ssr.isExpandable">{{ $t(`ssrSelectionBox_lbl${ssr.ssrName}Description`) }} </span>
            <span class="ssr-info-description" v-else-if="ssr.ssrName == 'CABIN_BAG'">
               {{ $t(`ssrSelectionBox_lbl${ssr.ssrName}Description`) }}
            </span>
            <span class="ssr-info-description" v-else>{{ ssr.ssrDescription }} </span>
         </section>
         <!-- SSR Add & Details -->

         <section class="ssr-action">
            <span class="ssr-price-txt" v-if="ssr.isExpandable && HasSelection">
               +{{ SelectedIdentifierKG }}, +{{ SelectedIdentifierPrice | price }}
            </span>
            <span class="ssr-price-txt" v-else-if="ssr.isExpandable">- </span>
            <span class="ssr-price-txt" v-else>+ {{ ssr.ssrPrice | price }} </span>
            <div
               class="ssr-cta-wrapper"
               :class="[ssr.isExpandable ? '' : 'ssr-add-btn', ssr.isExpandable && showDetails ? 'ssr-details-active' : '']"
               @click="ssr.isExpandable ? handlerShowDetails() : handlerSelectSSR()"></div>
         </section>
      </div>

      <!-- Details -->

      <div class="ssr-details-wrapper" v-if="!HasSelection && showDetails">
         <template v-for="(item, itemIndex) in ssr.ssrItems">
            <div class="ssr-detail-item-wrapper" :key="itemIndex" @click="handlerSelectSSR(item.itemIdentifier)">
               <span class="item-info"> {{ item.itemDisplayValue }}</span>
               <span class="item-price"> + {{ item.price | price }}</span>
            </div>
         </template>
      </div>
   </div>
</template>

<script>
export default {
   name: "SsrSelectionBox",
   props: {
      ssr: Object,
   },

   data() {
      return {
         showDetails: false,
      };
   },
   computed: {
      SsrNameControl() {
         return this.ssr.ssrName == "BAGGAGE" && this.HasSelection;
      },
      HasSelection() {
         return Object.keys(this.ssr.ssrItems).some((item) => {
            return this.ssr.ssrItems[item].selected;
         });
      },
      SelectedIdentifier() {
         const ssrItems = this.ssr.ssrItems;
         for (const item in ssrItems) {
            if (ssrItems[item].selected) {
               return ssrItems[item].item;
            }
         }
         return null;
      },
      SelectedIdentifierPrice() {
         return this.ssr.ssrItems[this.SelectedIdentifier].price;
      },
      SelectedIdentifierKG() {
         return this.ssr.ssrItems[this.SelectedIdentifier].itemDisplayValue;
      },
      SelectedIdentifierDescription() {
         return this.ssr.ssrItems[this.SelectedIdentifier].itemDisplayValue;
      },
   },
   methods: {
      handlerSelectSSR(identifier) {
         console.log("handlerSelectSSR", identifier);
         let rowObj = {
            identifier: String,
            parentIdentifier: String,
            status: this.HasSelection,
         };
         if (!this.ssr.isExpandable) {
            rowObj.identifier = Object.keys(this.ssr.ssrItems)[0];
            rowObj.parentIdentifier = this.ssr.ssrName;
         } else {
            rowObj.identifier = identifier;
            rowObj.parentIdentifier = this.ssr.ssrName;
         }

         this.$emit("ssr-selection", rowObj);
         this.showDetails = false;
      },
      handlerShowDetails() {
         console.log("handlerShowDetails");
         if (!this.HasSelection) {
            this.showDetails = !this.showDetails;
         } else {
            this.handlerSelectSSR(this.SelectedIdentifier);
         }
      },
   },
   watch: {
      SsrNameControl: {
         handler: function (status) {
            this.$emit("show-badge", {
               status: status,
            });
         },
      },
   },
};
</script>

<style lang="scss" scoped>
$transitionAnim: all 0.1s ease-in-out;
.ssr-selection-box-container {
   border: 1px solid #cccccc;
   cursor: pointer;
   border-radius: 1rem;
   padding: 1rem;
   margin-bottom: 1rem;
   transition: $transitionAnim;
   background-color: #f7f9fd;

   &:hover {
      transform: scale(1.008);
      // transform: translateX(5px);

      box-shadow: 3px 3px 10px #3e7ecc60;

      .ssr-action {
         .ssr-cta-wrapper {
            // transform: scale(1.25);
         }
      }
   }

   &.ssr-selected {
      background-color: #f0faff;
      border-width: 2px;
      border-color: #35b5ff;

      .ssr-price-txt {
         color: var(--accent-primary);
      }

      .ssr-action {
         .ssr-cta-wrapper {
            background-color: var(--primary) !important;
            background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMi43MjgiIGhlaWdodD0iMTIuNzI4IiB2aWV3Qm94PSIwIDAgMTIuNzI4IDEyLjcyOCI+CiAgPGcgaWQ9Ikdyb3VwXzEyMDAyIiBkYXRhLW5hbWU9Ikdyb3VwIDEyMDAyIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMzYuNzcgLTEzMTguNzU0KSByb3RhdGUoNDUpIj4KICAgIDxsaW5lIGlkPSJMaW5lXzExMzAiIGRhdGEtbmFtZT0iTGluZSAxMTMwIiB5Mj0iMTIiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDk3My41IDkwNi41KSByb3RhdGUoOTApIiBmaWxsPSJub25lIiBzdHJva2U9IiNmZmYiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLXdpZHRoPSIzIi8+CiAgICA8bGluZSBpZD0iTGluZV8xMjY4IiBkYXRhLW5hbWU9IkxpbmUgMTI2OCIgeTI9IjEyIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg5NjcuNSA5MDAuNSkiIGZpbGw9Im5vbmUiIHN0cm9rZT0iI2ZmZiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2Utd2lkdGg9IjMiLz4KICA8L2c+Cjwvc3ZnPgo=") !important;

            &.ssr-add-btn,
            &.ssr-detail-btn,
            &.ssr-details-active {
               background-color: var(--primary) !important;
               background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMi43MjgiIGhlaWdodD0iMTIuNzI4IiB2aWV3Qm94PSIwIDAgMTIuNzI4IDEyLjcyOCI+CiAgPGcgaWQ9Ikdyb3VwXzEyMDAyIiBkYXRhLW5hbWU9Ikdyb3VwIDEyMDAyIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMzYuNzcgLTEzMTguNzU0KSByb3RhdGUoNDUpIj4KICAgIDxsaW5lIGlkPSJMaW5lXzExMzAiIGRhdGEtbmFtZT0iTGluZSAxMTMwIiB5Mj0iMTIiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDk3My41IDkwNi41KSByb3RhdGUoOTApIiBmaWxsPSJub25lIiBzdHJva2U9IiNmZmYiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLXdpZHRoPSIzIi8+CiAgICA8bGluZSBpZD0iTGluZV8xMjY4IiBkYXRhLW5hbWU9IkxpbmUgMTI2OCIgeTI9IjEyIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg5NjcuNSA5MDAuNSkiIGZpbGw9Im5vbmUiIHN0cm9rZT0iI2ZmZiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2Utd2lkdGg9IjMiLz4KICA8L2c+Cjwvc3ZnPgo=") !important;
            }
         }
      }
   }

   .ssr-main-wrapper {
      display: flex;
      justify-content: space-between;
      section {
         flex-shrink: 1;
         &.ssr-icon-wrapper {
            margin-right: 1.5rem;
            position: relative;

            span.icon-selected {
               position: absolute;
               display: block;
               background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNiIgaGVpZ2h0PSIyNiIgdmlld0JveD0iMCAwIDI2IDI2Ij4KICA8ZyBpZD0iR3JvdXBfMTIwMjEiIGRhdGEtbmFtZT0iR3JvdXAgMTIwMjEiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0zNDQgLTEwMjEpIj4KICAgIDxjaXJjbGUgaWQ9IkVsbGlwc2VfMTM3NyIgZGF0YS1uYW1lPSJFbGxpcHNlIDEzNzciIGN4PSIxMyIgY3k9IjEzIiByPSIxMyIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMzcwIDEwMjEpIHJvdGF0ZSg5MCkiIGZpbGw9IiMxZmUyMzAiLz4KICAgIDxsaW5lIGlkPSJMaW5lXzExMzAiIGRhdGEtbmFtZT0iTGluZSAxMTMwIiB4MT0iNS4xMjkiIHkxPSIzLjM0IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgzNTAuNSAxMDM2LjE2NykiIGZpbGw9Im5vbmUiIHN0cm9rZT0iI2ZmZiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2Utd2lkdGg9IjMiLz4KICAgIDxsaW5lIGlkPSJMaW5lXzEyNjgiIGRhdGEtbmFtZT0iTGluZSAxMjY4IiB4MT0iNy40MzciIHkyPSIxMS4wMTQiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDM1NS42MjkgMTAyOC40OTMpIiBmaWxsPSJub25lIiBzdHJva2U9IiNmZmYiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLXdpZHRoPSIzIi8+CiAgPC9nPgo8L3N2Zz4K");
               width: 26px;
               height: 26px;
               right: 0;
               bottom: 0;
            }
         }
         &.ssr-informations-wrapper {
            display: flex;
            width: 100%;
            flex-direction: column;
            color: var(--primary);

            span.ssr-info-title {
               font-size: var(--xlarge);
               font-size: 1.25rem;
               font-weight: 600;
               text-transform: capitalize;
            }
            span.ssr-info-desripton {
               font-size: var(--normal);
            }
         }
         &.ssr-action {
            display: flex;
            align-items: center;
            white-space: nowrap;
            // background: red;

            span.ssr-price-txt {
               font-size: 1.25rem;
               font-weight: 500;
               margin-right: 1rem;
            }
            .ssr-cta-wrapper {
               width: 38px;
               height: 38px;
               border-radius: 24rem;
               background-position: center;
               background-repeat: no-repeat;
               transition: $transitionAnim;
               background-color: #cccccc;
               background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNy44MDciIGhlaWdodD0iMTEuNjAzIiB2aWV3Qm94PSIwIDAgMTcuODA3IDExLjYwMyI+CiAgPGcgaWQ9Ikdyb3VwXzEyMDE2IiBkYXRhLW5hbWU9Ikdyb3VwIDEyMDE2IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg5NTkuOTA0IDEwNDAuODAyKSByb3RhdGUoMTgwKSI+CiAgICA8bGluZSBpZD0iTGluZV8xMTMwIiBkYXRhLW5hbWU9IkxpbmUgMTEzMCIgeDE9IjAuNjI2IiB5Mj0iOS4zOTkiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDk1MC41NTggMTAzMS44OTgpIHJvdGF0ZSgtNDUpIiBmaWxsPSJub25lIiBzdHJva2U9IiNmZmYiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLXdpZHRoPSIzIi8+CiAgICA8bGluZSBpZD0iTGluZV8xMjY4IiBkYXRhLW5hbWU9IkxpbmUgMTI2OCIgeDI9IjAuNjI2IiB5Mj0iOS4zOTkiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDk0NC43OTYgMTAzOC41NDQpIHJvdGF0ZSgtMTM1KSIgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjZmZmIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS13aWR0aD0iMyIvPgogIDwvZz4KPC9zdmc+Cg==");
               cursor: pointer;
               position: relative;

               &:hover {
                  transform: scale(1.25);
               }

               &.ssr-add-btn {
                  background-color: var(--accent-primary);
                  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNSIgaGVpZ2h0PSIxNSIgdmlld0JveD0iMCAwIDE1IDE1Ij4KICA8ZyBpZD0iR3JvdXBfMTIwMDIiIGRhdGEtbmFtZT0iR3JvdXAgMTIwMDIiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC05NjAgLTg5OSkiPgogICAgPGxpbmUgaWQ9IkxpbmVfMTEzMCIgZGF0YS1uYW1lPSJMaW5lIDExMzAiIHkyPSIxMiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoOTczLjUgOTA2LjUpIHJvdGF0ZSg5MCkiIGZpbGw9Im5vbmUiIHN0cm9rZT0iI2ZmZiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2Utd2lkdGg9IjMiLz4KICAgIDxsaW5lIGlkPSJMaW5lXzEyNjgiIGRhdGEtbmFtZT0iTGluZSAxMjY4IiB5Mj0iMTIiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDk2Ny41IDkwMC41KSIgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjZmZmIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS13aWR0aD0iMyIvPgogIDwvZz4KPC9zdmc+Cg==");
               }
               &.ssr-details-active {
                  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNy44MDciIGhlaWdodD0iMTEuNjAzIiB2aWV3Qm94PSIwIDAgMTcuODA3IDExLjYwMyI+CiAgPGcgaWQ9Ikdyb3VwXzEyMDE2IiBkYXRhLW5hbWU9Ikdyb3VwIDEyMDE2IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtOTQyLjA5NyAtMTAyOS4xOTgpIj4KICAgIDxsaW5lIGlkPSJMaW5lXzExMzAiIGRhdGEtbmFtZT0iTGluZSAxMTMwIiB4MT0iMC42MjYiIHkyPSI5LjM5OSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoOTUwLjU1OCAxMDMxLjg5OCkgcm90YXRlKC00NSkiIGZpbGw9Im5vbmUiIHN0cm9rZT0iI2ZmZiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2Utd2lkdGg9IjMiLz4KICAgIDxsaW5lIGlkPSJMaW5lXzEyNjgiIGRhdGEtbmFtZT0iTGluZSAxMjY4IiB4Mj0iMC42MjYiIHkyPSI5LjM5OSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoOTQ0Ljc5NiAxMDM4LjU0NCkgcm90YXRlKC0xMzUpIiBmaWxsPSJub25lIiBzdHJva2U9IiNmZmYiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLXdpZHRoPSIzIi8+CiAgPC9nPgo8L3N2Zz4K");
               }
            }
         }
      }
   }

   .ssr-details-wrapper {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-column-gap: 1.25rem;
      grid-row-gap: 1.25rem;
      margin-left: 5rem;
      padding-top: 1.5rem;
      margin-top: 1rem;
      border-top: 1px dashed #ccc;
      .ssr-detail-item-wrapper {
         display: flex;
         flex-direction: column;
         justify-content: center;
         padding: 1rem;
         // width: 88px;
         // height: 80px;
         border-radius: 0.5rem;
         border: 1px solid #cccccc;
         background-color: #fff;

         &:hover {
            background-color: #eee;
            cursor: pointer;
         }

         span {
            width: 100%;
            text-align: center;

            &.item-info {
               font-weight: 600;
            }
         }
      }
   }
}
</style>
