const state = {
    balanceForm: {},
    creditCard: {},
};

const mutations = {
    updateBalanceForm(state, data) {
        state.balanceForm = data;
    },
    updateCreditCard(state, data) {
        state.creditCard = data;
    },
};
const actions = {
    updateBalanceForm: function (commit, data) {
        commit("updateBalanceForm", data);
    },
    updateCreditCard: function (commit, data) {
        commit("updateCreditCard", data);
    },
};
const getters = {
    getUpdateBalanceForm: (state) => state.balanceForm,
    getCreditCard: (state) => state.creditCard,
};

export const finance = {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
