<template>
   <table ref="print" style="display: none">
      <thead>
         <tr>
            <th v-for="(item, index) in table.headers" :key="index">
               {{ $t(item) }}
            </th>
         </tr>
      </thead>
      <tr v-for="(item, index) in table.rows" :key="index">
         <td></td>
         <td>{{ item.paxFullName }}</td>
         <td>
            {{ item.provider }}
            <span class="txt-small txt-black" v-if="item.entityType != 2">
               {{ item.route }}
            </span>
            <span class="txt-small txt-black" v-else v-for="(route, index) in Route(item)" :key="index">
               {{ route }}
            </span>
         </td>
         <td>
            {{ item.dateTime | FormatterDate }}
            {{ item.dateTime | FormatterTime }}
         </td>
         <td>
            {{ item.entity }}
         </td>
      </tr>
   </table>
</template>

<script>
export default {
   name: "PDFexport",
   props: {
      table: Object,
   },
   methods: {
      Route(item) {
         var newItem = item.route.split(" ");
         return newItem;
      },
   },
};
</script>
