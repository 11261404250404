<template>
   <div class="hotel-card-debugger-container">
      <div class="hotel-card-debugger-inner-container">
         <span>{{ infos.searchUid }}</span>
         <span>{{ infos.roomIdentifier }}</span>
         <span>{{ infos.channelManagerName }}</span>
         <span>{{ infos.channelName }}</span>
         <span>{{ infos.providerName }}</span>
         <span>{{ infos.spoIds }}</span>
         <span>{{ infos.restrictionType }}</span>
      </div>
   </div>
</template>

<script>
export default {
   name: "RoomCardDebugger",
   props: {
      infos: {
         type: Object,
         required: true,
      },
   },
};
</script>

<style lang="scss" scoped>
.hotel-card-debugger-container {
   background-color: #30353c;
   color: #32cd32;
   font-family: monospace;
   font-weight: 500;
   font-size: 1rem;
   padding: 0.5rem;
   margin: 0.5rem;
   border-radius: 0.5rem;

   .hotel-card-debugger-inner-container {
      display: flex;
      flex-direction: column;
      width: 100%;
      overflow: auto;
   }
}
</style>
