<template>
   <div class="seat-map-result-container">
      <template v-if="result.success">
         <i class="gts-icon icon-seatmap-result-success"></i>
      </template>
      <template v-else>
         <i class="gts-icon icon-seatmap-result-error"></i>
      </template>

      <p>{{ $t(`ancillarySeatMap_lblResult${result.code}`) }}</p>

      <UIButton :className="result.success ? 'success' : 'warning'" :text="UIButton" @click.native="handlerClose" />
   </div>
</template>

<script>
import UIButton from "@/components/unitary/Button.vue";
export default {
   name: "Seat",
   props: {
      result: Object,
   },
   components: {
      UIButton,
   },

   computed: {
      UIButton() {
         return this.result.success ? this.$i18n.t("ancillarySeatMap_lblSuccessBtn") : this.$i18n.t("ancillarySeatMap_lblErrorBtn");
      },
   },
   methods: {
      handlerClose() {
         this.$store.commit("seatmap/setStatus", false);
      },
   },
};
</script>

<style lang="scss" scoped>
.seat-map-result-container {
   width: 100%;
   display: flex;
   flex-direction: column;
   align-items: center;
   text-align: center;
   padding-top: 4rem;
   i {
      width: 120px !important;
      height: 120px !important;
   }
   p {
      margin-top: 1rem;
      font-size: var(--large);
      color: #363636;
      margin-bottom: 2rem;
   }
}
</style>
