<template>
   <section class="widget-header-container" v-click-outside="handlerCloseDropdown">
      <!-- <span class="widget-identifier"></span> -->
      <span class="widget-title" :style="properties?.type == 'campaign' ? 'color: #0dffd0;' : ''">
         {{ PropertiesTypeTxt(properties?.type) }}
      </span>

      <div class="widget-header-cta-wrapper">
         <!-- Drag and Drop is disabled -->
         <!-- <button
            class="drag-widget"
            @mousedown="handlerDragAndDrop(true)"
            @mouseup="handlerDragAndDrop(false)"
            :class="DraggableStatus ? 'active' : ''">
            <i class="gts-icon icon-widget-draggable icon-sm"></i>
         </button> -->
         <button class="toggle-informations" @click="privacy = !privacy" v-if="false">
            <template v-if="!privacy">
               <i class="gts-icon icon-eye"></i>
            </template>
            <template v-else>
               <i class="gts-icon icon-eye-closed"></i>
            </template>
         </button>
         <!-- Controls Start -->
         <div class="widget-controls-wrapper" v-if="properties?.type == 1 || properties?.type == 2 || properties?.type == 3">
            <button class="cta-trigger" @click="handlerDropdown">
               <i class="gts-icon icon-widget-cta icon-sm"></i>
            </button>
            <ul class="cta-wrapper" v-if="dropdown">
               <template v-for="(cta, index) in ctas">
                  <li
                     :key="index"
                     @click="handlerWidgetOptions(cta.identifier)"
                     :class="[cta.identifier == 1 ? 'add' : '', cta.identifier == 3 ? 'remove' : '']">
                     {{ $t(cta.name) }}
                  </li>
               </template>
            </ul>
         </div>
         <!-- Controls End -->
      </div>
   </section>
</template>

<script>
export default {
   name: "Header",
   props: {
      properties: Object,
   },
   data() {
      return {
         dropdown: false,
         privacy: false,
         ctas: [
            // addNew ve removeWidget geçici olarak kapatıldı
            // { name: "widgetHeaderComponent_addNew", identifier: 1 },
            { name: "widgetHeaderComponent_toggleExtras", identifier: 2 },
            // { name: "widgetHeaderComponent_removeWidget", identifier: 3 },
            // { name: "widgetHeaderComponent_highlighter", identifier: 4 },
            // { name: "widgetHeaderComponent_backtoFirst", identifier: 5 },
         ],
      };
   },
   computed: {
      DraggableStatus() {
         return this.$parent.draggable;
      },
   },
   methods: {
      PropertiesTypeTxt(type) {
         if (type == 1) {
            return this.$t("dashboard_reservations");
         } else if (type == 2) {
            return this.$t("dashboard_upcomings");
         } else if (type == 3) {
            return this.$t("dashboard_reportsGiro");
         } else {
            return this.$t("dashboardPage_announcements");
         }
      },
      handlerDropdown() {
         this.dropdown = !this.dropdown;
      },
      handlerWidgetOptions(identifier) {
         if (identifier == 1) {
            this.$parent.$parent.$parent.controller.status = true;
         }
         if (identifier == 2) {
            this.$parent.properties.extras = !this.$parent.properties.extras;
         }
         if (identifier == 3) {
            this.handlerRemoveWidget();
         }
         if (identifier == 4) {
            this.$parent.highlighter = !this.$parent.highlighter;
         }
         this.handlerCloseDropdown();
      },
      handlerCloseDropdown() {
         this.dropdown = false;
      },
      handlerDragAndDrop(status) {
         this.$emit("draggable", status);
      },
      handlerRemoveWidget() {
         this.$parent.$emit("removeWidget", this.properties?.type);
      },
   },
   watch: {
      privacy: {
         handler: function (status) {
            this.$parent.properties.privacy = status;
         },
      },
   },
};
</script>

<style lang="scss" scoped>
.widget-header-container {
   display: flex;
   justify-content: space-between;
   align-items: center;
   padding: 0 1.5rem 1rem;
   border-bottom: 1px solid #ccc;
   position: relative;

   .widget-identifier {
      position: absolute;
      width: 5px;
      height: 100%;
      background: red;
   }

   span.widget-title {
      font-weight: 500;
      font-size: var(--large);
   }
   .widget-header-cta-wrapper {
      display: flex;
      align-items: center;
      button {
         background-color: transparent;
         // border: 1px solid #eee;
         height: 40px;
      }

      .drag-widget {
         &.active {
            i {
               filter: invert(50%) sepia(100%) saturate(256%) hue-rotate(160deg) brightness(100%) contrast(200%);
            }
         }
      }

      .widget-controls-wrapper {
         position: relative;

         ul.cta-wrapper {
            display: flex;
            overflow: hidden;
            flex-direction: column;
            position: absolute;
            background-color: #fff;
            border: 1px solid #eee;
            border-radius: 1rem;
            box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
            width: fit-content;
            right: 0;
            z-index: 99;
            li {
               white-space: nowrap;
               padding: 0.5rem 1rem;
               cursor: pointer;
               border-bottom: 1px solid #eee;

               &:hover {
                  background-color: #eee;
               }

               &.add {
                  &:hover {
                     background-color: #4cf9a0;
                     color: #363636;
                  }
               }
               &.remove {
                  &:hover {
                     background-color: #f94d4c;
                     color: #fff;
                  }
               }
            }
         }
      }
   }
}
</style>
