export const widgets = {
   101: "dashboard_flightReservations",
   102: "dashboard_flightForthComings",
   103: "dashboard_flightPerformance",
   104: "dashboard_flightGiros",
   201: "dashboard_hotelReservations",
   202: "dashboard_hotelUpcomingCheckins",
   203: "dashboard_hotelPerformance",
   301: "dashboard_transferUpcoming",
   401: "dashboard_rentACarUpcoming",
};
export const widgetsResponseModelMap = {
   101: "FLIGHT_RESERVATIONS",
   102: "FLIGHT_FLIGHTS",
   103: "FLIGHT_PERFORMANCE",
   104: "FLIGHT_GIROS",
   201: "HOTEL_RESERVATIONS",
   202: "HOTEL_CHECKINS",
   203: "HOTEL_PERFORMANCE",
   301: "TRANSFER_UPCOMING",
   401: "RENTACAR_UPCOMING",
};

export const EDashMainWidgetType = {
   "-1": "ALL",
   0: "UNKNOWN",
   1: "RESERVATIONS",
   2: "UPCOMINGS",
   3: "REPORT_GIROS",
};
export const EDashMainWidget2 = {
   "-1": "ALL",
   0: "UNKNOWN",
   // RESERVATIONS
   100: "RESERVATIONS_ALL",
   101: "RESERVATIONS_FLIGHT",
   102: "RESERVATIONS_HOTEL",
   // UPCOMINGS
   200: "UPCOMINGS_ALL",
   201: "UPCOMINGS_FLIGHT",
   202: "UPCOMINGS_HOTEL",
   203: "UPCOMINGS_TRANSFER",
   204: "UPCOMINGS_RENTACAR",
   // REPORTS GIRO
   300: "REPORT_GIRO_ALL",
   301: "REPORT_GIRO_FLIGHT",
   302: "REPORT_GIRO_HOTEL",
   303: "REPORT_GIRO_TRANSFER",
   304: "REPORT_GIRO_RENTACAR",
};

export const widgets2 = {
   1: "dashboard_reservations",
   2: "dashboard_upcomings",
   3: "dashboard_reportsGiro",
};

export const widgetsResponseModelMap2 = {
   1: "RESERVATIONS",
   2: "UPCOMINGS",
   3: "REPORT_GIROS",
};
