<template>
   <button
      :class="`gts-btn gts-btn__${btnClassName} ${rounded ? 'rounded' : ''} ${modalTrigger ? 'modal-trigger-btn' : ''} ${
         notRounded ? 'not-rounded' : ''
      }`"
      v-if="!btnIsRouter"
      :disabled="isDisabled"
      @keypress.space.prevent
      @keypress.enter.prevent>
      <template v-if="!icon">
         <span class="g-radial-loader" v-if="isSpinnerActive"></span>
         <template v-else> {{ btnText }} {{ rounded }}</template>
      </template>
      <template v-else>
         <div class="lds-ring" v-if="isSpinnerActive">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
         </div>
         <template v-else>
            <span class="only-icon" :class="btnText ? 'with-text' : ''">
               <i :class="`gts-icon icon-${icon} ${modalTrigger ? 'modal-trigger-btn' : ''}`"></i>
               {{ btnText }}
            </span>
         </template>
      </template>
   </button>
   <router-link :to="btnRouterUrl" :class="`gts-btn gts-btn__${btnClassName}`" v-else>
      <template v-if="!icon">
         {{ btnText }}
      </template>
      <template v-else>
         <span class="only-icon" :class="btnText ? 'with-text' : ''">
            <i :class="`gts-icon icon-${icon} ${modalTrigger ? 'modal-trigger-btn' : ''}`"></i>
            <span class="text">{{ btnText }}</span>
         </span>
      </template>
   </router-link>
</template>

<script>
export default {
   name: "UIButton",
   props: {
      className: { String },
      text: { String },
      isRouter: { Boolean },
      routerUrl: [String, Object],
      icon: { String },
      rounded: { Boolean },
      notRounded: { Boolean },
      modalTrigger: { Boolean },
      isSpinnerActive: { Boolean },
      isDisabled: { Boolean },
   },
   data() {
      return {
         btnClassName: this.className,
         btnText: this.text,
         btnIsRouter: this.isRouter,
         btnRouterUrl: this.routerUrl,
      };
   },
   watch: {
      text: function (newValue) {
         var self = this;
         self.btnText = newValue;
      },
   },
};
</script>

<style lang="scss" scoped>
html body .gts-btn {
   display: flex;
   align-items: center;
   justify-content: center;
   // width: max-content;
   max-width: 100%;
   height: 44px !important;
   padding: 0.5rem 2.5rem;
   background-color: transparent;
   border: 2px solid transparent;
   border-radius: 24rem;
   font-size: 16px;
   color: white;
   text-align: center;
   cursor: pointer;
   text-decoration: none;
   white-space: nowrap;
   transition: 0.3s all;

   &:disabled {
      opacity: 0.3;
      cursor: not-allowed;
   }

   & > .lds-ring {
      & > div {
         width: 100%;
         height: 100%;
         margin: 0;
         border-width: 3px;
      }
   }

   & > .only-icon {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      .icon-white {
         filter: brightness(10);
      }

      &.with-text > i {
         margin-right: 8px;
      }
   }

   &__default {
      background-color: var(--primary);

      &:hover {
         background-color: var(--accent-primary);
      }

      &_outline {
         border-color: var(--primary);
         color: var(--primary);
         background-color: #fff;

         &:hover {
            border-color: transparent !important;
            background-color: var(--accent-primary);
            color: white;
         }
      }
   }
   &__partialPay {
      background-color: #0acc8b !important;
      padding: 0 1rem !important;
      font-weight: 700 !important;
      .only-icon {
         i {
            height: 40px !important;
            width: 40px !important;
         }
      }
      &:hover {
         background-color: #0acc8b;
      }
      &_outline {
         border-color: var(--primary);
         color: var(--primary);
         background-color: #fff;
         &:hover {
            border-color: transparent !important;
            background-color: var(--accent-primary);
            color: white;
         }
      }
   }
   &__otp {
      background-color: transparent;

      &:hover {
         background-color: transparent;
      }

      &_outline {
         border-color: var(--primary);
         color: var(--primary);
         background-color: transparent;

         &:hover {
            border-color: transparent !important;
            background-color: #36a3eb;
            color: transparent;
         }
      }
   }
   &__flight {
      background-color: var(--primary-flight);

      &:hover {
         background-color: var(--accent-primary);
      }

      &_outline {
         border-color: var(--primary);
         color: var(--primary);
         background-color: #fff;

         &:hover {
            border-color: transparent !important;
            background-color: var(--accent-primary);
            color: white;
         }
      }
   }

   &__hotel {
      background-color: var(--primary-hotel);

      &:hover {
         background-color: var(--accent-primary);
      }

      &_outline {
         border-color: var(--primary);
         color: var(--primary);
         background-color: #fff;

         &:hover {
            border-color: transparent !important;
            background-color: var(--accent-primary);
            color: white;
         }
      }
   }
   &__transfer-dark {
      background-color: var(--primary-transfer-darker);

      &:hover {
         background-color: var(--primary-transfer);
      }

      &_outline {
         border-color: var(--primary);
         color: var(--primary);
         background-color: #fff;

         &:hover {
            border-color: transparent !important;
            background-color: var(--primary-transfer);
            color: white;
         }
      }
   }
   &__transfer {
      background-color: var(--primary-transfer);

      &:hover {
         background-color: var(--accent-primary);
      }

      &_outline {
         border-color: var(--primary);
         color: var(--primary);
         background-color: #fff;

         &:hover {
            border-color: transparent !important;
            background-color: var(--accent-primary);
            color: white;
         }
      }
   }

   &__rentacar {
      background-color: var(--primary-rentacar);

      &:hover {
         background-color: var(--accent-primary);
      }

      &_outline {
         border-color: var(--primary);
         color: var(--primary);
         background-color: #fff;

         &:hover {
            border-color: transparent !important;
            background-color: var(--accent-primary);
            color: white;
         }
      }
   }

   &__light {
      background-color: white;
      border-color: #24619f;
      color: #24619f;

      &:hover {
         background-color: #266cbf;
         color: white;
      }

      &_outline {
         border-color: #266cbf;

         color: #266cbf;

         &:hover {
            background-color: #266cbf;
            color: white;
         }
      }
   }

   &__accent-primary {
      background-color: var(--accent-primary);
      color: white;

      &:hover {
         background-color: var(--accent-primary);
         color: white;
      }

      &_outline {
         border-color: var(--accent-primary);

         color: var(--accent-primary);

         &:hover {
            background-color: var(--accent-primary);
            color: white;
         }
      }
   }

   &__success {
      background-color: #2feb9c;

      &:hover {
         background-color: #2feb9c;
      }

      &_outline {
         border-color: #2feb9c;
         color: #2feb9c;

         &:hover {
            background-color: #2feb9c;
            color: white;
         }
      }
      &_dark_text {
         background-color: #2feb9c;
         color: #3a5a86;
      }
   }

   &__info {
      background-color: var(--accent-primary);

      &:hover {
         background-color: var(--accent-primary);
      }

      &_outline {
         border-color: var(--accent-primary);
         color: var(--accent-primary);

         &:hover {
            background-color: var(--accent-primary);
            color: white;
         }
      }
   }
   &__confirm {
      background-color: #33b7ff;

      &:hover {
         background-color: var(--accent-primary);
      }

      &_outline {
         border-color: #33b7ff;
         color: #33b7ff;

         &:hover {
            background-color: var(--accent-primary);
            color: white;
         }
      }
   }
   &__priceChange {
      background-color: #3adc8b;

      // &:hover {
      //    background-color: var(--accent-primary);
      // }

      &_outline {
         border-color: #3adc8b;
         color: #3adc8b;

         &:hover {
            background-color: #3adc8b;
            color: white;
         }
      }
   }

   &__alert {
      background-color: #ff9133;

      &:hover {
         background-color: #ff9133;
      }

      &_outline {
         border-color: #ff9133;
         color: #ff9133;

         &:hover {
            background-color: #ff9133;
            color: white;
         }
      }
   }
   &__grayscale {
      background-color: #e6e6e6;
      color: #8c8c8c;

      &:hover {
         background-color: #e6e6e6;
      }

      &_outline {
         border-color: #e6e6e6;
         color: #e6e6e6;

         &:hover {
            background-color: #ff9133;
            color: white;
         }
      }
   }

   &__warning {
      background-color: #ff4c4c;

      &:hover {
         background-color: #ff4c4c;
      }

      &_outline {
         border-color: #ff4c4c;
         color: #ff4c4c;

         &:hover {
            background-color: #ff4c4c;
            color: white;
         }
      }
   }
   &__attention {
      background-color: #ffcd33;
      color: var(--txt-black);

      &:hover {
         background-color: #ffcd33;
      }

      &_outline {
         border-color: #ffcd33;
         color: #ffcd33;
         &:hover {
            background-color: #ffcd33;
            color: white;
         }
      }
   }

   &__purple {
      background-color: #a838e0;

      &:hover {
         background-color: white;
         color: #a838e0;
         transform: scale(1.1);
         border: 1px solid #a838e0;
      }

      &_outline {
         border-color: #a838e0;
         color: #a838e0;

         &:hover {
            background-color: white;
            color: #a838e0;
            border: 1px solid #a838e0;
            transform: scale(1.1);
         }
      }
   }
   &__pink {
      background-color: #ff00ff;

      &:hover {
         background-color: white;
         color: #a838e0;
         transform: scale(1.1);
         border: 1px solid #a838e0;
      }

      &_outline {
         border-color: #ff00ff;
         color: #ff00ff;

         &:hover {
            background-color: #ff00ff;
            color: #a838e0;
            border: 1px solid #a838e0;
            transform: scale(1.1);
         }
      }
   }
   &.rounded {
      width: 40px;
      max-width: 40px;
      height: 40px;
      max-height: 40px;
      padding: 0;
      border-radius: 50% !important;
   }
   &.not-rounded {
      border-radius: 0.25rem;
   }
   &.gts-btn-animation {
      transform: scale(1);
      transition-property: transform;
      transition-timing-function: ease-in;
      transition-duration: 0.05s;

      &:hover {
         transform: scale(1.1);
      }
   }
}
</style>
