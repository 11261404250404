<template>
   <div id="smarttable-search-container">
      <input type="text" v-model="search" :placeholder="$t('common_search')" />
      <i
         class="gts-icon icon-close-rounded gts-animation icon-xs clear-search"
         @click="search = ''"
         :class="search.length == 0 ? 'disabled' : ''"></i>
   </div>
</template>

<script>
export default {
   name: "SmartTableSearch",
   data() {
      return {
         search: "",
      };
   },

   watch: {
      search: {
         handler: function (val) {
            this.$emit("searchKeyword", val);
         },
      },
   },
};
</script>

<style lang="scss" scoped>
#smarttable-search-container {
   position: relative;
   input {
      width: 200px;
      border-radius: 24rem;
      outline: 0;
      border: 2px solid #ccc;
      height: 40px;
      text-indent: 1rem;
      color: #363636;
      text-transform: uppercase;
      font-size: var(--small);
      background-color: #f6f6f6;

      &:focus {
         border-color: var(--accent-primary-lighten);
         background-color: #98dcff14;
         font-weight: 500;
      }

      &::-webkit-input-placeholder {
         text-transform: capitalize;
      }
   }
   .clear-search {
      cursor: pointer;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0.75rem;
      height: fit-content;
      margin: auto;
      width: 18px !important;
      height: 18px !important;

      &.disabled {
         opacity: 0;
      }
   }
}
</style>
