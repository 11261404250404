<template>
   <div id="transfer-informations-wrapper" class="gts-box with-border">
      <div id="validation-error-auto-scroller"></div>
      <span class="section-title">{{ $t("TransferInformations_transferInformations") }}</span>
      <template v-if="!Object.keys(transfers).length">
         <TransferCheckoutCardSkeleton />
      </template>
      <div class="transfer-checkout-transfer-item" v-for="(routeTransfers, routeIndex) in transfers" :key="`checkout-transfer-${routeIndex}`">
         <div v-for="(transferItem, transferIndex) in routeTransfers" :key="transferIndex">
            <TransferCheckoutCard :transferItem="transferItem" :routeIndex="routeIndex" />
         </div>
      </div>

      <div class="transfer-checkout-prices-wrapper">
         <div class="transfer-checkout-prices-inner-wrapper">
            <!-- Vehicle Wrapper -->
            <div class="transfer-vehicle-wrapper">
               <div class="transfer-vehicle-inner-wrapper" v-for="(routeTransfers, routeIndex) in transfers" :key="`vehicle-inner-${routeIndex}`">
                  <template v-for="(transferItem, transferIndex) in routeTransfers">
                     <div class="vehicle-label" :class="'label-' + routeIndex" :key="`vehicle-${transferIndex}`">
                        <span class="label-circle"></span>
                        <span>{{ routeIndex }}. {{ $t("TransferInformations_lblCar") }}</span>
                     </div>
                     <span :key="`fare-${transferIndex}`">{{ transferItem.price.totalFare | price(transferItem.price.currency) }}</span>
                  </template>
               </div>
            </div>
            <!-- Price Wrapper -->
            <div class="transfer-price-wrapper">
               <PriceWidget
                  @commission-update="handlerAddCommission"
                  showCommission
                  :totalPriceInfo="PricesInfo"
                  checkoutMode
                  label="transferCheckout" />
            </div>
         </div>
      </div>
   </div>
</template>

<script>
import TransferCheckoutCard from "@/components/structural/transfer/checkout/TransferCheckoutCard.vue";
import TransferCheckoutCardSkeleton from "@/components/structural/transfer/checkout/TransferCheckoutCardSkeleton.vue";
import PriceWidget from "@/components/unitary/PriceWidget.vue";

export default {
   name: "TransferInformations",
   components: {
      TransferCheckoutCard,
      TransferCheckoutCardSkeleton,
      PriceWidget,
   },
   props: {
      checkoutRequestModel: Object,
      transfers: Object,
      totalPrice: Object,
   },
   data() {
      return {
         isFirstRender: false,
         agencyCommissionExtra: 0,
         pickupTime: null,
         pnr: "",
         agencyCommission: {
            options: [],
         },
      };
   },
   computed: {
      PricesInfo() {
         return this.totalPrice;
      },
   },
   methods: {
      handlerAddCommission(agencyCommissionInfo) {
         const emitter = () => {
            switch (type) {
               case "select": {
                  this.checkoutRequestModel.totalPrice.agencyCommissionRatio = Number(agencyCommissionRatio);
                  break;
               }
               case "input": {
                  const agencyCommissionInputValue = parseInt(agencyCommissionExtra);

                  if (!agencyCommissionInputValue || isNaN(agencyCommissionInputValue)) {
                     this.checkoutRequestModel.totalPrice.agencyCommissionExtra = 0;
                     break;
                  }
                  this.checkoutRequestModel.totalPrice.agencyCommissionExtra = agencyCommissionInputValue;
                  this.checkoutRequestModel.totalPrice.agencyCommissionRatio = Number(agencyCommissionRatio);
                  break;
               }
            }
            this.checkoutRequestModel.refreshCheckout = true;
            // emit event to parent component
            this.$emit("update-to-agency-extra");
         };

         // similar to const type = agencyCommissionInfo.type; Object destructing
         const { type } = agencyCommissionInfo;
         const { agencyCommissionExtra, agencyCommissionRatio } = agencyCommissionInfo.agencyCommission;
         if (!this.isFirstRender) {
            emitter();
            this.$store.commit("app/setIsProgressGifActive", true);
            this.$store.state.app.progress.message = this.$i18n.t("transferCheckout_agencyExtraCCommission");
         }
         // on first render prevent to run this function
         // Cause of emit event we forcing commission function , to prevent this we use isFirstRender flag and set it false after first render
         if (type === "select" && this.isFirstRender) {
            this.isFirstRender = false;
         }
      },
   },
   watch: {
      "checkoutRequestModel.totalPrice.agencyCommissionExtra": {
         handler: function (agencyCommissionExtra) {
            this.agencyCommissionExtra = agencyCommissionExtra;
         },
      },
   },
};
</script>

<style lang="scss" scoped>
#transfer-informations-wrapper {
   position: relative;

   #transfer-inputs {
      display: flex;
      margin-bottom: 1rem;
      .transfer-input {
         margin: 0 0.5rem;
         display: flex;
      }
   }
   .transfer-checkout-transfer-item {
      margin-bottom: 2.5rem;
   }

   .transfer-checkout-prices-wrapper {
      display: flex;
      justify-content: flex-end;
      .transfer-checkout-prices-inner-wrapper {
         .transfer-vehicle-wrapper {
            margin-bottom: 1rem;
            display: flex;
            justify-content: flex-end;
            .transfer-vehicle-inner-wrapper {
               display: grid;
               grid-template-columns: 2fr 1fr;
               background: #f2f2f2;
               border-radius: 24rem;
               padding: 0.25rem 0.5rem;
               margin-bottom: 0.5rem;

               .vehicle-label {
                  display: flex;
                  span {
                     &:first-child {
                        background: #fff;
                        width: 22px;
                        height: 22px;
                        display: block;
                        border-radius: 50%;
                        margin-right: 0.5rem;
                     }
                     &:last-child {
                        font-weight: 500;
                     }
                  }

                  &.label-1 {
                     .label-circle {
                        border: 6px solid #4dfec4;
                     }
                  }
                  &.label-2 {
                     .label-circle {
                        border: 6px solid #363636;
                     }
                  }
               }

               > span {
                  text-align: right;
               }
            }
         }

         .transfer-price-wrapper {
            .transfer-price-row {
               display: grid;
               grid-template-columns: 2fr 1fr;
               justify-content: space-between;
               .transfer-price-col {
                  width: 100%;
                  display: flex;
                  justify-content: flex-end;
                  align-items: center;

                  span {
                     &.transfer-grand-total {
                        font-size: 1.25rem;
                        font-weight: 600;
                     }
                  }

                  #agency-commission-input {
                     border: 1px solid #ccc;
                     border-radius: 1rem;
                     width: 70px;
                     text-indent: 0.5rem;
                  }
               }
            }
         }
      }
   }

   .transfer-checkout-information-prices {
      .transfer-checkout-total-price {
         text-align: end;
         padding: 1rem;
         span {
            margin-left: 1rem;
            font-weight: 700;
         }
      }
      .transfer-checkout-agency-commission {
         text-align: end;
         padding: 1rem;
         span {
            margin-left: 1rem;
            font-weight: 700;
         }
      }
      .transfer-checkout-grand-total {
         text-align: end;
         padding: 1rem;
         span {
            margin-left: 1rem;
            font-weight: 700;
            &:last-child {
               font-size: 1.6rem;
               color: #3c67b2;
            }
         }
      }
   }
}
</style>
