<template>
   <div class="g-input-container" :class="rounded ? 'rounded' : ''" v-click-outside="handlerClickOutside">
      <label v-if="label" :for="id">{{ label }}</label>

      <div class="gts-input-wrapper">
         <input
            type="text"
            :id="id"
            @input="handlerInput($event)"
            @keydown="handlerArrowNavigation"
            :placeholder="placeholder"
            :value="Value"
            :disabled="disabled"
            :class="textRight ? '--textRight' : ''"
            autocomplete="reisetech" />

         <i class="gts-icon icon-close-rounded gts-animation icon-xs clear-btn" @click.self="handlerClearInput" v-if="ShowClear"></i>
         <div class="g-form-validation" v-if="customValidationTxt">{{ customValidationTxt }}</div>
         <div class="g-form-validation" v-if="!value || value.length == 0">{{ $t("common_cannotBeEmpty") }}</div>
      </div>

      <!-- CUSTOMER LIST DROPDOWN -->
      <ul class="g-input-autocomplete" v-if="dropdown && Customers.length > 0">
         <li
            v-for="(customer, index) in Customers"
            :key="index"
            @click="handlerSelectCustomer(customer)"
            :class="navigation.index == index ? 'highlighted' : ''">
            <span class="g-input-autocomplete-name">{{ customer.name + " " + customer.surname }}</span>
            <span class="g-input-autocomplete-birth">{{ customer.birthdate | fullDateFormat }}</span>
         </li>
      </ul>
   </div>
</template>

<script>
export default {
   name: "UIInput",
   props: {
      placeholder: String,
      value: String,
      autocomplete: Boolean,
      customers: Array,
      acTag: String,
      label: String,
      allowClear: Boolean,
      lowercase: Boolean,
      id: String,
      disabled: Boolean,
      rounded: Boolean,
      textRight: Boolean,
      customValidationTxt: String,
      updateMode: Boolean,
      inputModel: String,
      index: Number,
   },
   data() {
      return {
         dropdown: false,
         navigation: {
            active: true,
            index: 0,
         },
      };
   },

   computed: {
      Value() {
         if (typeof this.value == "string") {
            if (this.lowercase) {
               return this.value.toLowerCase();
            } else {
               return this.value.toUpperCase();
            }
         } else {
            return null;
         }
      },
      Customers() {
         if (this.customers != undefined) {
            return this.customers.filter((customer) => {
               return customer[this.acTag].includes(this.value.toUpperCase());
            });
         } else {
            return ["asd", "asd"];
         }
      },
      ShowClear() {
         if (this.allowClear && this.value != null && this.value.length > 0) {
            return true;
         } else {
            return false;
         }
      },
   },
   methods: {
      handlerInput(ev) {
         const self = this;

         if (self.autocomplete) {
            if (ev.target.value.length > 1) {
               self.dropdown = true;
            } else {
               self.dropdown = false;
            }
         }
         if (self.lowercase) {
            self.$emit("input", ev.target.value);
         } else {
            self.$emit("input", ev.target.value);
         }
         if (self.updateMode) {
            self.$emit("update-mode", {
               value: ev.target.value,
               model: self.inputModel,
               index: self.index,
            });
         }
      },
      handlerSelectCustomer(customer) {
         const self = this;
         self.$emit("set-customer", customer);
         self.dropdown = false;
      },
      handlerArrowNavigation(event) {
         const self = this;
         switch (event.keyCode) {
            case 40:
            case 39:
               if (self.navigation.index < self.Customers.length - 1) {
                  self.navigation.index++;
               }
               break;
            case 38:
            case 37:
               if (self.navigation.index > 0) {
                  self.navigation.index--;
               }
               break;
            case 13:
               self.$emit("set-customer", self.Customers[self.navigation.index]);
               self.dropdown = false;
               // self.handlerPushChip(self.arrowNavigation.navIndex);
               break;
            case 9:
               self.dropdown = false;
               break;
         }
      },
      handlerClearInput() {
         const self = this;
         self.$emit("input", "");
         self.dropdown = false;
      },
      handlerClickOutside() {
         const self = this;
         self.dropdown = false;
      },
   },
   watch: {
      value: {
         handler: function (val) {
            const self = this;
            if (val == "") {
               self.$emit("input", "");
            }
         },
      },
   },
};
</script>
<style lang="scss" scoped>
.g-input-container {
   label {
      margin-bottom: 0.5rem;
   }

   .gts-input-wrapper {
      position: relative;
      .clear-btn {
         position: absolute;
         right: 1rem;
         top: 0;
         bottom: 0;
         margin: auto;
         cursor: pointer;
      }
      input {
         position: relative;
         display: flex;
         justify-content: flex-start;
         align-items: center;
         border: 1px solid #b1b2b3;
         border-radius: 0.5rem;
         box-sizing: border-box;
         background: #fff;
         height: 48px !important;
         max-height: 100% !important;
         border-width: 2px;
         width: 100%;
         text-indent: 1rem;
         font-size: var(--normal);
         min-width: 50px !important;

         &.--textRight {
            display: inline-block;
            text-align: right;
         }

         &:focus {
            border: 1px solid var(--accent-primary);
            outline: 0.25rem solid var(--accent-primary-lightest);
         }

         &::placeholder {
            text-transform: capitalize;
         }

         &:disabled {
            background: #eee;
         }
      }
   }
   .g-input-autocomplete {
      background: #fff;
      position: absolute;
      border: 1px solid #ccc;
      border-radius: 12px;
      left: auto;
      z-index: 99;
      width: 100%;
      height: auto;
      padding: 0.75rem 0rem;
      overflow: hidden;
      width: max-content;

      li {
         padding: 0.5rem 1rem;
         font-size: var(--small);
         display: flex;
         justify-content: space-between;
         align-items: center;
         border-bottom: 1px solid #eee;
         cursor: pointer;

         .g-input-autocomplete-birth {
            margin-left: 1rem;
            font-size: var(--xsmall);
            font-weight: 600;
         }
         &:hover {
            background: var(--hover);
         }

         &.highlighted {
            background: var(--hover);
         }
      }
   }

   &.rounded {
      .gts-input-wrapper {
         input {
            border-radius: 24rem;
            height: 32px !important;
         }
      }
   }
}
</style>
