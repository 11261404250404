<template>
   <div
      class="flightcard-inner-wrapper"
      :class="[
         flight.familyPrice != null ? 'has-family-price' : '',
         flight.familyPrice == 0 ? 'not-has-family-price' : '',
         flight.isSelected ? 'is-selected' : '',
         flight.isHiddenFromUI ? 'flightcard-collapsed' : '',
      ]"
      :data-identifier="flight.identifier"
      :data-price="flight.price"
      v-if="FilterBy"
      :data-leg="flight.legIndex">
      <!-- {{ flight.transferTimeFilter }} -->
      <!-- {{ flight.familyPrice }} -- {{ flight.price }} -->
      <!-- FlightCard Header Mobile -->
      <div class="flightcard-header d-lg-none">
         <div class="flightcard-header-left">
            <img class="airline-logo" :src="handlerAirlineImg(flight.marketingAirlineCode)" alt="" @error="handlerUnknownImg($event)" />
            <div class="airline-informations">
               <div class="marketing-airline">
                  <span class="airline">{{ flight.marketingAirline }}</span>
                  <!-- <span class="flight-number">{{ flight.flightNumber }}</span> -->
               </div>
               <div class="operating-airline">
                  <span class="operated-by"> {{ $t("flightCard_operatedBy") }} : </span>
                  <span class="airline">{{ flight.operatingAirline }}</span>
               </div>
            </div>
         </div>

         <!-- Charter Badge Mobile -->
         <div class="flightcard-header-right">
            <!-- <i class="gts-icon icon-in-basket"></i> -->
            <span class="is-charter" v-if="flight.isCharter">charter</span>
         </div>
      </div>

      <div :data-flightNumber="flight.flightNumber" class="flightcard-basics-wrapper" @click="handlerSelectFlight">
         <!-- CHARTER -->
         <div class="is-charter d-none d-lg-block" v-if="flight.isCharter">charter</div>

         <!-- SELECT BUTTON -->
         <div class="flightcard-selection-wrapper">
            <div class="flightcard-selection-checkmark"></div>
         </div>

         <!-- AIRLINE INFORMATION -->
         <div class="flightcard-airline-informations d-none d-lg-flex">
            <img class="airline-logo" :src="handlerAirlineImg(flight.marketingAirlineCode)" alt="" @error="handlerUnknownImg($event)" />
            <span class="marketing-airline-name">{{ flight.marketingAirline }}</span>
            <span class="flight-channel" v-if="isChannel">{{ flight.channel }}</span>
            <!-- <span class="flight-number">{{ flight.flightNumber }}</span> -->
         </div>

         <!-- PRICE & CABINTYPE INFORMATIONS -->
         <div class="flightcard-main-informations">
            <!-- Price Section -->
            <div class="flightcard-price-wrapper">
               <!-- Display if it is onw way  -->
               <template v-if="flight.price == 0 && flight.familyPrice == 0">
                  <span class="flightcard-price-oneway"> {{ $t(`flightCardComponent_oneWay`) }}</span>
               </template>

               <template v-else>
                  <!-- Display if discount -->
                  <template v-if="flight.familyPrice != 0 && flight.familyPrice != null && flight.price != flight.familyPrice">
                     <span class="flightcard-discount"> {{ flight.price | price(currency) }} </span>
                     <span class="flightcard-price cut-price"> {{ flight.familyPrice | price(currency) }}</span>
                  </template>

                  <!-- Display if no discount -->
                  <template v-if="flight.familyPrice == 0 || flight.familyPrice == null || flight.price == flight.familyPrice">
                     <span class="flightcard-price"> {{ flight.price | price(currency) }}</span>
                  </template>
               </template>
            </div>
            <span class="flightcard-cabintype">{{ flight.cabinType | EFltCabinType }} </span>

            <div class="flightcard-baggage-seat-informations">
               <div class="baggage-wrapper">
                  <i class="gts-icon icon-xs icon-baggage"></i>
                  <span class="baggage-information" v-if="flight.baggage.status">{{ flight.baggage.info }}</span>
                  <span class="baggage-information" v-else>{{ flight.baggage.info }}</span>
               </div>
               <div class="seat-wrapper">
                  <i class="gts-icon icon-xs icon-seat"></i>
                  <span class="seat-information">{{ flight.leftSeat }}</span>
               </div>
            </div>
         </div>

         <FlightCardInfoGraphic :segments="flight.segments" :transfer-time="flight.transferTime" :connection="flight.connection" />

         <!-- SHOW/HIDE DETAILS  -->
         <div class="flightcard-details-toggle d-none d-xxl-inline-flex" :class="showDetails ? 'hide-details' : ''" data-toggle>
            <i class="gts-icon icon-arrow-down icon-sm"></i>
         </div>
      </div>

      <FlightCardDebugger :infos="Debugger" v-if="isDebugger" />

      <FlightCardDetails v-if="showDetails" :details="flight.details" />

      <!-- FlightCard Footer -->
      <div class="flightcard-footer-wrapper">
         <!-- Last Seat Attention -->
         <span class="last-seat-attention" :class="flight.leftSeat <= 9 ? 'seat-visible' : ''">
            <i class="gts-icon icon-flame icon-xs"></i>
            {{ $t("searchComponentFlight_lastSeat") }} {{ flight.leftSeat }}
         </span>

         <!-- Operating Airlines -->
         <span class="operating-airline-name d-none d-xl-block" v-if="flight.operatingAirlineCode != flight.marketingAirlineCode"
            >{{ $t("flightcardcomponent_operatedBy") }}: {{ $t(`airlineEnum_${flight.operatingAirlineCode}`) }}
         </span>

         <!--  Show Details Mobile -->
         <div class="flightcard-details-toggle d-xxl-none" :class="showDetails ? 'hide-details' : ''" data-toggle @click="showDetails = !showDetails">
            <i class="gts-icon icon-eye-closed" v-if="showDetails"></i>
            <i class="gts-icon icon-eye" v-else></i>
            <span class="toggle-detail-txt">{{ $t("flightCard_showDetails") }}</span>
         </div>

         <!-- Connections -->
         <span class="connections d-none d-xxl-block">
            {{ flight.stopCount }}
         </span>
      </div>
   </div>
</template>
<script>
import { format24hours, convertToHour } from "@/helper/flight.js";
import FlightCardInfoGraphic from "@/components/structural/flight/search/search-results/flight-card/FlightCardInfoGraphic.vue";
import FlightCardDebugger from "@/components/structural/flight/search/search-results/flight-card/FlightCardDebugger.vue";
import FlightCardDetails from "@/components/structural/flight/search/search-results/flight-card/FlightCardDetails.vue";
export default {
   name: "FlightCard",
   props: {
      flight: {
         type: Object,
         required: true,
      },
      flightIndex: Number,
      legIndex: Number,
      currency: String,
   },
   components: {
      FlightCardInfoGraphic,
      FlightCardDebugger,
      FlightCardDetails,
   },
   data() {
      return {
         showDetails: false,
         isDebugger: false,
         isChannel: false,
      };
   },
   mounted() {
      document.body.addEventListener("keydown", (event) => {
         let held = false;

         if (event.ctrlKey && event.altKey) {
            held = true;
         }

         if (held && event.keyCode == 73) {
            this.isDebugger = !this.isDebugger;
         }
         if (held && event.keyCode == 84) {
            this.isChannel = !this.isChannel;
         }
      });
   },

   computed: {
      FilterBy() {
         const storeShortCut = this.$store.state.flight.filters[this.flight.legIndex].filters;

         //car filter options helper variables
         const departureTime = this.flight.segments[0].time;
         const arrivalTime = this.flight.segments[Object.keys(this.flight.segments).length - 1].time;
         const baggageAllowance = this.flight.baggage.status ? "withbaggage" : "nobaggage";

         //Card filter options
         const connection = storeShortCut.connections[this.flight.connection].checked;
         const cabintype = storeShortCut.cabintype[this.flight.cabinType].checked;
         const airlines = storeShortCut.airlines[this.flight.marketingAirlineCode].checked;
         const dpTakeOff = format24hours(storeShortCut.hours.departure[0]) <= departureTime;
         const dpLanding = format24hours(storeShortCut.hours.departure[1]) >= departureTime;
         const rtTakeoff = format24hours(storeShortCut.hours.arrival[0]) <= arrivalTime;
         const rtLanding = format24hours(storeShortCut.hours.arrival[1]) >= arrivalTime;
         const baggage = storeShortCut.baggage[baggageAllowance].checked;

         return connection && cabintype && airlines && dpTakeOff && dpLanding && rtTakeoff && rtLanding && baggage;
      },
      Debugger() {
         return {
            channel: this.flight.channel,
            identifier: this.flight.identifier,
            debugger: this.flight.debugger,
         };
      },
   },

   methods: {
      handlerSelectFlight(ev) {
         const isNotSelection = ev.target.hasAttribute("data-toggle");
         if (isNotSelection) {
            this.showDetails = !this.showDetails;
         } else {
            this.$emit("selected-flight", { identifier: this.flight.identifier, index: this.flightIndex });
         }

         const sameDayFlight = this.$parent.$parent.$parent.$parent.$parent.sameDayFlight;
         if (sameDayFlight) {
            this.handlerSameDayControll();
         }
      },
      handlerSameDayControll() {
         const departureTime = convertToHour(this.flight.segments[0].time) - 2;
         const arrivalTime = convertToHour(this.flight.segments[Object.keys(this.flight.segments).length - 1].time) + 2;

         const otherFilter = this.legIndex == 1 ? 2 : 1;

         if (this.legIndex == 1) {
            const current = this.$store.state.flight.filters[otherFilter].filters.hours.departure[1];
            this.$store.state.flight.filters[otherFilter].filters.hours.departure = [arrivalTime, current];
         } else {
            const current = this.$store.state.flight.filters[otherFilter].filters.hours.arrival[0];
            this.$store.state.flight.filters[otherFilter].filters.hours.arrival = [current, departureTime];
         }
      },
      handlerAirlineImg(airlineLogo) {
         return `${this.$constants.cdn}/airline_34x34/${airlineLogo}.svg`;
      },
      handlerUnknownImg(ev) {
         ev.target.src = `${this.$constants.cdn}/airline_34x34/00.svg`;
      },
   },
};
</script>

<style lang="scss" scoped>
.flightcard-inner-wrapper {
   position: relative;
   // background-color: #fff;
   // background-color: #fcfcfd;
   // // background-color: #f9f9fb;
   // // background-color: #f8f8fb; //saturated
   background-color: #f5f5f9; //saturated
   border: 1px solid #ccc;
   margin-bottom: 1.125rem;
   border-radius: 1rem;
   // box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
   // box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
   // box-shadow: rgba(0, 0, 0, 0.15) 0px 3px 3px 0px;
   // box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
   cursor: pointer;
   outline: 3px solid transparent;
   transition-property: all;
   transition-timing-function: ease-in-out;
   transition-duration: 1ms;

   .flightcard-header {
      background-color: #eee;
      padding: 0.25rem 0.5rem;
      border-radius: 1rem;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
      height: 52px;
      display: flex;
      justify-content: space-between;

      .flightcard-header-left {
         display: flex;
         align-items: center;
         img {
            $size: 34px;
            width: $size;
            height: $size;
            margin-right: 0.75rem;
         }
         .airline-informations {
            .marketing-airline,
            .operating-airline {
               span {
                  font-size: var(--small);
               }
               span.airline,
               span.flight-number {
                  font-weight: 500;
               }
               span.flight-number {
                  margin-left: 0.5rem;
               }
            }
            .operating-airline {
               color: #626262;
               font-size: var(--xsmall) !important;
            }
         }
      }
      .flightcard-header-right {
         display: flex;
         align-items: center;
         .is-charter {
            margin-left: 0.5rem;
            background-color: #fff;
            border: 2px solid #ff4c4c;
            color: #ff4c4c;
            border-radius: 0.5rem !important;
            font-size: var(--xsmall);
            padding: 0.15rem 0.5rem;
            text-transform: uppercase;
            font-weight: 600;
         }

         i {
            display: none;
         }
      }
   }

   &.is-selected {
      .flightcard-basics-wrapper {
         .flightcard-main-informations {
            background-color: var(--accent-primary-lightest);
            border: 3px solid var(--accent-primary);
            box-shadow: 0px 0px 0px 4px var(--accent-primary-lightest);
            -webkit-box-shadow: 0px 0px 0px 4px var(--accent-primary-lightest);
            -moz-box-shadow: 0px 0px 0px 4px var(--accent-primary-lightest);
         }
         .flightcard-selection-wrapper {
            border: 2px solid var(--accent-primary);
            .flightcard-selection-checkmark {
               display: block !important;
               $size: 20px;
               width: $size;
               height: $size;
               background: var(--accent-primary);
               border-radius: 50%;
               opacity: 1;
            }
         }
      }
   }

   &:hover {
      background-color: #fff;

      .flightcard-details-toggle {
         background-color: #f0f0f0 !important;
         // background-color: red;
      }
      .flightcard-selection-wrapper {
         transform: scale(1.05);
      }

      ::v-deep .flight-icon-container {
         background-color: #fff !important;
         transform: scale(1.15);
      }
   }
   &.has-family-price {
      outline: 3px solid var(--accent-secondary);
      .is-charter {
         border-top-right-radius: 1rem !important;
      }

      &.not-has-family-pricek {
         outline: none !important;
      }
   }
   &.flightcard-collapsed,
   &.lazy-loaded-collapsed-leg {
      display: none;
   }
   &.details-active {
      background: #fff;
   }
}
.flightcard-inner-wrapper {
   .flightcard-basics-wrapper {
      display: flex;
      align-items: center;
      padding: 1rem;
      width: 100%;
      justify-content: space-between; //check here
      flex-direction: row-reverse;
      @media (min-width: 992px) {
         flex-direction: row;
      }
      .is-charter {
         position: absolute;
         top: 0;
         right: 0;
         background-color: #ff4c4c;
         border-radius: 5px;
         border-top-left-radius: 0;
         border-top-right-radius: 0.5rem;
         border-bottom-right-radius: 0;
         font-size: var(--xsmall);
         color: #fff;
         padding: 0.15rem 0.5rem;
         text-transform: uppercase;
         font-weight: 600;
      }

      .flightcard-selection-wrapper {
         $size: 28px;
         width: $size;
         height: $size;
         display: inline-flex;
         flex-shrink: 0;
         border: 2px solid #a2a2a2;
         border-radius: 50%;
         align-items: center;
         justify-content: center;
         background-color: #fff;
         transition-property: transform;
         transition-duration: 0.1s;
         transition-timing-function: ease-in-out;
         margin-right: 1rem;
         @media (max-width: 1800px) {
            display: none;
         }

         .flightcard-selection-checkmark {
            opacity: 0;
            transition-property: opacity;
            transition-timing-function: ease-in-out;
            transition-duration: 0.1s;
         }

         &.is-selected {
            border: 2px solid var(--accent-primary);
            .flightcard-selection-checkmark {
               display: block !important;
               $size: 20px;
               width: $size;
               height: $size;
               background: var(--accent-primary);
               border-radius: 50%;
               opacity: 1;
            }
         }
      }
      .flightcard-details-toggle {
         display: inline-flex;
         flex-shrink: 0;
         align-items: center;
         justify-content: center;
         $size: 42px;
         width: $size;
         height: $size;
         border: 1px solid #eee;
         border-radius: 50%;
         background-color: #fff;

         i {
            pointer-events: none;
            transform: rotate(0deg);
            transition-property: transform;
            transition-timing-function: ease-out;
            transition-duration: 0.25s;
         }

         &.hide-details {
            i {
               transform: rotate(180deg);
            }
         }
      }
      .flightcard-airline-informations {
         display: flex;
         flex-direction: column;
         align-items: center;
         max-width: 65px;
         img.airline-logo {
            $airlineLogoSize: 36px;
            width: $airlineLogoSize;
            height: $airlineLogoSize;
         }
         span.marketing-airline-name {
            font-size: var(--xsmall);
            margin-top: 0.25rem;
            font-weight: 500;
            text-align: center;
            width: 70px;
            word-wrap: normal;
         }
         span.flight-number {
            font-weight: 400;
            font-size: var(--xsmall);
            color: var(--txt-ghost);
         }
         span.flight-channel {
            color: #ff4c4c;
            font-size: var(--xsmall);
            font-weight: 500;
         }
      }
      .flightcard-main-informations {
         display: flex;
         flex-direction: column;
         align-items: center;
         border: 1px solid #ccc;
         border-radius: 1rem;
         padding: 0.75rem 0rem;
         background-color: #fcfcfc;
         margin-left: 0.5rem;

         @media (min-width: 992px) {
            margin-left: 1rem;
         }
         @media (min-width: 1400px) {
            margin-left: 1.5rem;
            padding: 0.75rem 0.25rem;
         }

         div.flightcard-price-wrapper {
            position: relative;

            .flightcard-discount {
               position: absolute;
               top: -1.75rem;
               font-size: 15px;
               color: var(--txt-ghost);
               text-decoration: line-through;
               background: #fafafa;
               padding: 0 0.5rem;
               // border: 1px solid #cccccc;
               border-radius: 0.5rem;
            }
            .flightcard-price {
               text-align: center;
               font-size: 1.45rem;
               font-weight: 800;
               color: var(--primary);
               margin-bottom: 1rem;
               padding: 0.125rem 0.35rem;

               &.cut-price {
                  background-color: #e9fcf2;
                  color: #1dc973 !important;
                  border-radius: 0.25rem;
               }
            }
         }
         span.flightcard-price-oneway {
            text-align: center;
            text-decoration: line-through;
            font-size: var(--small);
            font-weight: 400;
            color: var(--primary);
            margin-bottom: 0.25rem;
            display: block;
         }
         span.flightcard-cabintype {
            text-align: center;

            border-radius: 0.5rem;
            font-size: var(--small);
            font-weight: 500;
            color: var(--txt-ghost);
            width: fit-content;
         }

         .flightcard-baggage-seat-informations {
            display: flex;
            flex-direction: row;
            margin-top: 0.25rem;

            .baggage-wrapper,
            .seat-wrapper {
               display: flex;
               margin: 0 0.25rem;

               span {
                  color: var(--secondary);
                  font-size: var(--small);
                  font-weight: 500;
               }
            }
         }
      }
   }
   .flightcard-footer-wrapper {
      display: flex;
      border-top: 1px solid #ccc;
      background-color: #eee;
      padding: 0.25rem 1rem;
      border-bottom-left-radius: 1rem;
      border-bottom-right-radius: 1rem;
      justify-content: space-between;
      align-items: center;

      .last-seat-attention {
         display: flex;
         color: var(--txt-black);
         font-weight: 500;
         font-size: 0.85rem;
         align-items: center;
         border-radius: 1rem;
         visibility: hidden;

         &.seat-visible {
            color: #ff4c4c;
            visibility: visible;
         }

         i {
            margin-right: 0.25rem;
            width: 14px !important;
            height: 14px !important;
            filter: invert(38%) sepia(45%) saturate(3741%) hue-rotate(336deg) brightness(116%) contrast(90%);
         }
      }
      .flightcard-details-toggle {
         display: flex;
         align-items: center;
         i {
            width: 30px !important;
         }
         .toggle-detail-txt {
            margin-left: 0.25rem;
            font-size: var(--small);
            font-weight: 500;
            color: var(--txt-gray);
         }
      }
      span.connections {
         font-size: var(--small);
         font-weight: 500;
      }
      span.operating-airline-name {
         font-size: var(--small);
         font-weight: 500;
         color: var(--gray-lighten);
      }
   }
}
</style>
