import Vue from "vue";
import Helpers from "./validator-helpers";
import { generateUuid } from "@/utils.js";
var inputs = {};

const validation = {
   install: (Vue) => {
      const validate = function (groupCode, exclude) {
         return new Promise((res) => {
            var success;
            if (groupCode) {
               const formGroup = {};
               Object.keys(inputs).forEach((input) => {
                  if (inputs[input].group == groupCode) {
                     formGroup[input] = inputs[input];
                  }
               });
               success = Object.keys(formGroup).every((input) => {
                  return formGroup[input].valid;
               });
            } else {
               if (exclude) {
                  Object.keys(inputs).forEach((input) => {
                     if (inputs[input].expression.includes(exclude)) {
                        inputs[input].valid = true;
                     }
                  });
               }

               success = Object.keys(inputs).every((input) => {
                  return inputs[input].valid;
               });
            }

            if (!success) {
               if (groupCode) {
                  Object.keys(inputs).forEach((identifier) => {
                     if (!inputs[identifier].valid && inputs[identifier].group == groupCode) {
                        interactionStatus(identifier, true);
                        const el = inputs[identifier].el;
                        const binding = inputs[identifier].binding;
                        const vnode = inputs[identifier].vnode;
                        isValid(el, binding, vnode);
                     }
                  });
               } else {
                  Object.keys(inputs).forEach((identifier) => {
                     if (!inputs[identifier].valid) {
                        interactionStatus(identifier, true);
                        const el = inputs[identifier].el;
                        const binding = inputs[identifier].binding;
                        const vnode = inputs[identifier].vnode;
                        isValid(el, binding, vnode);
                     }
                  });
               }
            }

            res({ success, inputs });
         });
      };

      Vue.prototype.$validate = validate;
   },
};

Vue.use(validation);

export default {
   // Initialize Hook
   Initialize(el, binding, vnode) {
      el = el.querySelector("input") ?? undefined;
      el.dataset.identifier = generateUuid();

      genereateIdentifiers(el, binding, vnode, el.dataset.identifier);
      watcher(el, binding, vnode);
      isValid(el, binding, vnode);
      onBlur(el, binding, vnode);
      debouncer();
   },

   // Dispose Hook
   Dispose(el) {
      el = el.querySelector("input") ?? undefined;
      delete inputs[el.dataset.identifier];
   },
};

const genereateIdentifiers = (el, binding, vnode, identifier) => {
   inputs[identifier] = {
      expression: vnode.data.model.expression,
      valid: !binding.value.required ? true : false,
      required: binding.value.required ? true : false,
      interacted: false,
      identifier: identifier,
      group: binding.value.group ? binding.value.group : false,
      el,
      binding,
      vnode,
   };
};

const watcher = (el, binding, vnode) => {
   el.oninput = () => {
      interactionStatus(el.dataset.identifier, true);
   };

   const activateWatcher = Object.prototype.hasOwnProperty.call(inputs, el.dataset.identifier);

   if (activateWatcher) {
      const expression = vnode.data.model.expression;
      const unwatch = vnode.context.$watch(expression, {
         handler: function () {
            //
            if (inputs[el.dataset.identifier] == undefined) {
               unwatch();
            } else {
               isValid(el, binding, vnode);
            }
         },
         immediate: false,
      });
   }
};

const validateInput = (el, binding, isValid) => {
   inputs[el.dataset.identifier].valid = isValid;
   isValid ? removeInvalidBadge(el) : addInvalidBadge(el, binding);
};

const isValid = (el, binding, vnode) => {
   const validator = {
      type: typeController(el, binding, vnode),
      length: lengthController(el, binding, vnode) ?? true,
      pattern: patternController(el, binding, vnode) ?? true,
      range: rangeController(el, binding, vnode) ?? true,
   };
   validator;

   const keyValidator = Object.keys(validator).every((key) => {
      return validator[key];
   });

   validateInput(el, binding, !binding.value.required ? true : keyValidator);
};

const typeController = (el, binding, vnode) => {
   if (binding.value.type == "email") {
      el.value = el.value.clearTurkishChars().emailControl().toLowerCase();
      eval(`vnode.context.${vnode.data.model.expression} = '${el.value.clearTurkishChars().emailControl().toLowerCase()}'`);
      if (Helpers.emailValidation(el.value)) {
         return true;
      } else {
         return false;
      }
   } else if (binding.value.type == "tckn") {
      el.value = el.value.numeric();
      eval(`vnode.context.${vnode.data.model.expression} = '${el.value.numeric()}'`);
      if (Helpers.tcknValidation(el.value)) {
         return true;
      } else {
         return false;
      }
   } else if (binding.value.type == "date") {
      if (el.value.length == 10) {
         return true;
      } else if (!binding.value.required) {
         return true;
      } else {
         return false;
      }
   } else if (binding.value.type == "time") {
      const minLength = 2;
      const maxLength = 2;
      const minValue = 0;
      const maxValue = binding.value.identifier == "hours" ? 23 : 59;
      const valueNum = Number(el.value);
      if (isNaN(valueNum)) {
         el.value = 0;
         return false;
      }
      if (valueNum > maxValue) {
         el.value = String(maxValue);
         eval(`vnode.context.${vnode.data.model.expression} =  '${el.value}'`);
         return true;
      } else if (valueNum < minValue) {
         return false;
      }
      if (el.value.length < minLength) {
         return false;
      } else if (el.value.length > maxLength) {
         el.value = el.value.slice(0, maxLength);
         eval(`vnode.context.${vnode.data.model.expression} = '${el.value}'`);
         return true;
      }
      return true;
   } else if (binding.value.type == "checkbox") {
      if (el.checked) {
         return true;
      } else {
         if (binding.value.required) {
            return false;
         } else {
            return true;
         }
      }
   } else {
      var isControlExist = el?.value[binding.value.type] instanceof Function;
      if (isControlExist) {
         eval(`vnode.context.${vnode.data.model.expression} = '${el.value[binding.value.type]()}'`);
      }
      return true;
   }
};
const lengthController = (el, binding, vnode) => {
   // Is Len exist?
   if (binding.value.len) {
      const minLength = binding.value.len[0];
      const maxLength = binding.value.len[1];
      if (el.value.length < minLength) {
         return false;
      } else if (el.value.length > maxLength) {
         el.value = el.value.slice(0, maxLength);
         eval(`vnode.context.${vnode.data.model.expression} =  '${el.value}'`);
         return true;
      }
   } else {
      if (binding.value.range) {
         return true;
      } else {
         binding.value.len = [0, 60];
         return true;
      }
   }
};
const patternController = (el, binding, vnode) => {
   if (binding.value.pattern) {
      el.value = Helpers.patternMatch({
         input: el.value,
         template: binding.value.pattern,
      });
      if (binding.value.pattern.length == el.value.length) {
         eval(`vnode.context.${vnode.data.model.expression} =  '${el.value.clearAllSpaces()}'`);
         return true;
      } else {
         return false;
      }
   }
};
const rangeController = (el, binding, vnode) => {
   if (binding.value.range != undefined) {
      const minValue = binding.value.range[0];
      const maxValue = binding.value.range[1];
      const valueNum = Number(el.value);
      const valueStr = String(valueNum);

      eval(`vnode.context.${vnode.data.model.expression} =  '${valueStr}'`);

      if (valueNum > maxValue) {
         el.value = String(maxValue);
         eval(`vnode.context.${vnode.data.model.expression} =  '${el.value}'`);
         return true;
      } else if (valueNum < minValue) {
         return false;
      }
   }
};

/*
#####################
###### HELPERS ######
#####################
*/

const onBlur = (el, binding, vnode) => {
   el.onblur = debounce(() => {
      if (!binding.value.pattern && binding.value.type != "date") {
         el.value = el.value.trim();
         eval(`vnode.context.${vnode.data.model.expression} =  '${el.value}'`);
      }

      if (!inputs[el.dataset.identifier].interacted) {
         interactionStatus(el.dataset.identifier, true);
      }

      isValid(el, binding, vnode);
   }, 300);
};

const interactionStatus = (identifier, status) => {
   inputs[identifier].interacted = status;
};

const addInvalidBadge = (el, binding) => {
   if (inputs[el.dataset.identifier].interacted) {
      if (binding.value?.requiredMsg != undefined) {
         const badgeEl = el.parentElement.querySelector(".g-form-warning-badge");
         if (!badgeEl) {
            const badgeHTML = `<div class="g-form-warning-badge">${binding.value.requiredMsg}</div>`;
            el.parentElement.insertAdjacentHTML("beforeend", badgeHTML);
         }
      }
   }
};

const removeInvalidBadge = (el) => {
   const badgeEl = el.parentElement.querySelector(".g-form-warning-badge");
   if (badgeEl) {
      badgeEl.remove();
   }
};

//Debouncer
import debounce from "lodash.debounce";

const debouncer = debounce(function () {
   console.log(`debouncer`, inputs);
}, 2000);
