<template>
   <div class="g-form g-checkbox-container" :class="[value ? 'checked' : '', hotel ? 'is-hotel' : '']">
      <span class="g-checkbox" @click="handlerInput"></span>
      <input type="checkbox" :name="name" :id="id" @input="handlerInput" :checked="value" />
      <label v-if="label" :for="id">{{ label }}</label>
   </div>
</template>

<script>
export default {
   name: "UICheckBox",
   props: {
      label: [String, Number],
      id: [String, Number],
      name: [String, Number],
      value: Boolean,
      hotel: Boolean,
   },
   methods: {
      handlerInput() {
         this.$emit("input", !this.value);
      },
   },
};
</script>

<style lang="scss" scoped>
.g-checkbox-container {
   display: flex;
   align-items: flex-start;
   // margin-bottom: 1rem;
   cursor: pointer;
   label {
      margin: 0 !important;
      margin-left: 0.5rem !important;
      font-weight: 400 !important;
      cursor: pointer;
   }
   .g-checkbox {
      width: 22px !important;
      height: 22px !important;
      display: inline-block;
      border-radius: 4px;
      border-width: 2px;
      border-style: solid;
      position: relative;
      border-color: var(--accent-primary);
      flex-shrink: 0;
   }

   input {
      position: absolute;
      left: 0;
      opacity: 0;
      outline: 0;
      z-index: -1;
   }

   &.checked {
      .g-checkbox {
         outline: 0.25rem solid #ebf8ff;
         background-color: #fff;

         &:before {
            content: "";
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            margin: auto;
            width: 12px;
            height: 12px;
            background-repeat: no-repeat;
            background-position: center;
            background-size: 14px;
            background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMS42NCIgaGVpZ2h0PSIxMC42MjMiIHZpZXdCb3g9IjAgMCAxMS42NCAxMC42MjMiPjxwYXRoIGQ9Ik0yNzMuMjYxLDIyMjMuODQxYTEuMjUxLDEuMjUxLDAsMCwxLS43NjQtLjI2bC0zLjU0My0yLjczNmExLjI1LDEuMjUsMCwwLDEsMS41MjgtMS45NzlsMi41LDEuOTI5LDQuODQ4LTcuMDM2YTEuMjUsMS4yNSwwLDAsMSwyLjA1OSwxLjQxOGwtNS42LDguMTIzYTEuMjQ5LDEuMjQ5LDAsMCwxLTEuMDI5LjU0MVoiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0yNjguNDY4IC0yMjEzLjIxOCkiIGZpbGw9IiM1Y2I3ZjkiLz48L3N2Zz4=");
         }
      }

      &.is-hotel {
         .g-checkbox {
            outline: 0.25rem solid #fff0eb;

            &:before {
               background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMS42NCIgaGVpZ2h0PSIxMC42MjMiIHZpZXdCb3g9IjAgMCAxMS42NCAxMC42MjMiPjxwYXRoIGQ9Ik0yNzMuMjYxLDIyMjMuODQxYTEuMjUxLDEuMjUxLDAsMCwxLS43NjQtLjI2bC0zLjU0My0yLjczNmExLjI1LDEuMjUsMCwwLDEsMS41MjgtMS45NzlsMi41LDEuOTI5LDQuODQ4LTcuMDM2YTEuMjUsMS4yNSwwLDAsMSwyLjA1OSwxLjQxOGwtNS42LDguMTIzYTEuMjQ5LDEuMjQ5LDAsMCwxLTEuMDI5LjU0MVoiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0yNjguNDY4IC0yMjEzLjIxOCkiIGZpbGw9IiNmZjkxMzMiLz48L3N2Zz4=");
            }
         }
      }
   }

   &.is-hotel {
      .g-checkbox {
         border-color: var(--secondary);
      }
   }
}
</style>
