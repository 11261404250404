<template>
   <div id="rent-a-car-checkout-page-wrapper">
      <Aside />
      <Main>
         <Navbar />
         <!-- RentACar Grid -->
         <div class="container-lg px-md-2 px-lg-0">
            <div class="col px-md-2 px-lg-0">
               <div class="row gx-4">
                  <div class="col-12 col-lg-9">
                     <!-- {{ request }} -->
                     <!-- <button @click="handlerGenerateRequestModelBtn">handlerGenerateRequestModelBtn</button> -->

                     <!-- <br />
                     <br /> -->
                     <!-- {{ request }} -->
                     <!-- {{ request }}

                     <br /><br />
                     {{ response.ancillaries }} -->

                     <CarInformations
                        :car="CarInformations"
                        :price="PriceInformations"
                        :ancillariesObj="response.ancillaries"
                        v-if="response"
                        :currency="currency" />
                     <!-- <CarInformations :car="CarInformations" :price="PriceInformations" :ancillariesObj="response.ancillaries" v-if="response" /> -->

                     <DriverInformations
                        @driver="handlerUpdateDriver"
                        :model="DriverInfo"
                        :minDriverAge="MinDriverAge"
                        v-if="DriverInfo"
                        :address="address"
                        :validated="validated" />

                     <RentACarDescriptions
                        @agency-note="handlerAgencyNote"
                        :lastVoidDateTime="response?.lastVoidDateTime"
                        :driverRequirements="MinDriverRequirements" />

                     <PaymentPurchaseWidget
                        entityType="rentacar"
                        @click.native="handleSubmitClicked"
                        @submit-active="handleSubmitClicked"
                        :checkoutRequest="CheckoutRequest"
                        :paymentChoiceDetail="PaymentChoiceDetail"
                        :funcValidateForm="funcValidateForm" />

                     <!-- {{ PaymentChoiceDetail }} -->
                  </div>

                  <div class="col d-none d-lg-block col-lg-3">
                     <RentACarSideBar :infos="SideBar" :currency="currency" />
                  </div>
               </div>
            </div>
         </div>
      </Main>
   </div>
</template>

<script>
// import { rentacarService } from "@/service/index.js";
// import { generateUuid } from "@/utils.js";

import Aside from "@/components/unitary/Aside.vue";
import Navbar from "@/components/unitary/Navbar.vue";
import Main from "@/components/unitary/Main.vue";

// import RentACarDummy from "@/pages/rentacar/RentACarDummy.js";

import CarInformations from "@/components/structural/rentacar/checkout/CarInformations.vue";
import DriverInformations from "@/components/structural/rentacar/checkout/DriverInformations.vue";
import RentACarDescriptions from "@/components/structural/rentacar/checkout/RentACarDescriptions.vue";
import RentACarSideBar from "@/components/structural/rentacar/checkout/RentACarSideBar.vue";

import PaymentPurchaseWidget from "@/components/structural/payment/PaymentPurchaseWidget.vue";

import { rentacarService } from "@/service/index.js";
import { generateUuid } from "@/utils.js";

import { ancillaryConversion, ancillaryRequestModelGenerator } from "@/helper/rentacar.js";
import gtsPopup from "@/gts-popup";

export default {
   name: "RentaCarCheckout",
   components: {
      Aside,
      Navbar,
      Main,
      CarInformations,
      DriverInformations,
      RentACarDescriptions,
      PaymentPurchaseWidget,
      RentACarSideBar,
   },
   data() {
      return {
         response: null,
         validated: true,
         address: false,
         request: {
            searchUuid: this.$route.params.searchUuid,
            checkoutUuid: "",
            isPostBack: true,
            ancillaries: "",
            agencyCommissionRatio: "",
            agencyCommissionExtra: "",
            driverInfo: {
               name: null,
               surname: null,
               gender: 0,
               birthdate: null,
               phone: {
                  country: +49,
                  city: null,
                  number: null,
               },
               email: null,
               flightNumber: null,
               address: null,
            },
            trxType: 1,
            agencyNote: null,
         },
      };
   },
   created() {
      this.handlerCheckoutFirstRequest();
   },
   computed: {
      currency() {
         return this.response?.currency;
      },
      CheckoutRequest() {
         return this.request;
      },
      PaymentChoiceDetail() {
         return this.response?.paymentChoiceDetail;
      },
      MinDriverRequirements() {
         return {
            minDriverAge: this.response?.vehicle.minDriverAge,
            minLicenseAge: this.response?.vehicle.minLicenseAge,
         };
      },
      CarInformations() {
         return this.response;
      },
      PriceInformations() {
         return {
            currency: this.response?.currency,
            agencyCommission: this.response?.agencyCommission,
            agencyCommissionRatio: this.response?.agencyCommissionRatio,
            agencyCommissionExtra: this.response?.agencyCommissionExtra,
            grandTotal: this.response?.grandTotal,
         };
      },
      SideBar() {
         return {
            name: this.response?.vehicle.markModel,
            image: this.response?.vehicle.imageUrl,
            pickUp: {
               name: this.response?.pickUpDropOff.pickUpLocationName,
               date: this.response?.pickUpDropOff.pickUpDateTime,
            },
            dropOff: {
               name: this.response?.pickUpDropOff.dropOffLocationName,
               date: this.response?.pickUpDropOff.dropOffDateTime,
            },
            grandTotal: this.response?.grandTotal,
            ancillaryTotal: this.response?.ancillaryTotal,
            vehicleTotal: this.response?.vehicleTotal,
            ancillaries: this.response?.ancillaries,
         };
      },
      DriverInfo() {
         // console.log("DriverInfo");
         if (this.response != null && this.response.driverInfo != null && this.response.driverInfo.phone == null) {
            var driverInfo = this.response.driverInfo;
            driverInfo.phone = {
               country: "+49",
               city: "",
               number: "",
            };
            driverInfo.birthdate = null;
            return driverInfo;
         } else {
            return this.response?.driverInfo;
         }
      },
      MinDriverAge() {
         return this.response?.vehicle?.minDriverAge;
      },
   },

   methods: {
      handleSubmitClicked(e) {
         const isBtn = e.target.nodeName == "BUTTON";
         if (isBtn) {
            this.address = true;
            // console.log("handleSubmitClicked", e.target.nodeName);
         }
      },
      funcValidateForm() {
         return this.validated;
      },
      handlerAgencyNote(a) {
         this.request.agencyNote = a;
      },
      handlerCheckoutFirstRequest() {
         const self = this;
         const query = {
            searchUuid: this.$route.params.searchUuid,
            trxType: 1,
         };

         const isCheckoutUuidExist = !!this.$route.query.checkoutUuid;
         query[isCheckoutUuidExist ? "checkoutUuid" : "uuid"] = isCheckoutUuidExist ? this.$route.query.checkoutUuid : generateUuid();

         rentacarService.rentacarCheckout(query).then((response) => {
            if (response.result.success) {
               this.response = response;

               let ancillariesArr = ancillaryConversion(self, response.ancillaries);

               this.handlerRequestModelGenerator({
                  agencyCommissionExtra: response.agencyCommissionExtra,
                  agencyCommissionRatio: response.agencyCommissionRatio,
                  ancillaries: ancillaryRequestModelGenerator(self, ancillariesArr),
                  checkoutUuid: response.checkoutUuid,
                  // paymentInfo: response.paymentChoiceDetail,
               });

               if (!isCheckoutUuidExist) {
                  this.$router.push({ query: { checkoutUuid: response.checkoutUuid } });
               }
            } else {
               if (response.result.code == "EXPIRED") {
                  gtsPopup.result(response.result);
                  this.$router.push(`/rentacar`);
               } else {
                  gtsPopup.result(response.result);
               }
            }
         });
      },
      handlerRequestModelGenerator(model) {
         //checkoutuuid
         this.request.checkoutUuid = model.checkoutUuid ?? this.request.checkoutUuid;
         //ancillaries Model
         this.request.ancillaries = model.ancillaries ?? this.request.ancillaries;
         //Agency Commissions
         this.request.agencyCommissionExtra = model.agencyCommissionExtra ?? this.request.agencyCommissionExtra;
         this.request.agencyCommissionRatio = model.agencyCommissionRatio ?? this.request.agencyCommissionRatio;

         //Driver Informations

         this.request.driverInfo = model.driverInfo ?? this.request.driverInfo;
      },
      handlerUpdateDriver(driverInfo) {
         this.handlerRequestModelGenerator({ driverInfo });
      },
      handlerGenerateRequestModelBtn() {
         // console.log("handlerGenerateRequestModelBtn", this.request);
      },
      handlerThrowRequest() {
         this.$store.commit("app/setIsProgressGifActive", true);
         rentacarService.rentacarCheckout(this.request).then((response) => {
            //  this.$set(this.request)
            this.response = response;
            this.$store.commit("app/setIsProgressGifActive", false);
         });
      },
   },
   watch: {
      "request.driverInfo": {
         handler: function (driverinfo) {
            const { name, surname, birthdate, phone, email, flightNumber, address } = driverinfo;
            const { city, number } = phone;

            this.validated = [name, surname, birthdate, city, number, email, flightNumber, address].every(Boolean);
         },
         deep: true,
      },
   },
};
</script>

<style lang="scss" scoped>
// #rent-a-car-checkout-page-wrapper {
// }
</style>
