<template>
    <div class="register-page-wrapper">
        <div class="register-page-inner">
            <div class="page-section page-image">
                <template v-for="(imageItem, index) in imageUrls">
                    <img :src="imageItem" alt="register page image" :key="index" v-if="currentImage == index" />
                </template>
            </div>
            <div class="page-section page-form" id="validForm">
                <div class="register-form-inner">
                    <UIForm v-form-validation id="validation-form">
                        <UIButton text="submit" className="default_outline" />
                        <UIInput
                            type="text"
                            labelText="tckn"
                            v-validation.tckn="{ msg: this.$i18n.t('validation.tcknError') }"
                            v-must
                            v-numeric
                            style="margin-top: 40px"
                        />
                        <!-- <UIInput type="text" labelText="alphabetic upper" v-validation.alphabetic="{msg: 'alphabetic error'}" v-must v-upper style="margin-top: 16px;" /> -->
                        <UIInput
                            type="text"
                            labelText="alphabetic - banned"
                            v-validation.alphabetic="{ msg: 'alphabetic error' }"
                            v-banned-words
                            style="margin-top: 16px"
                        />
                        <UIInput type="text" labelText="email" v-validation.email="{ msg: 'email error' }" v-must style="margin-top: 16px" />
                        <UIInput type="text" labelText="length 5-10" v-validation.length="{ min: 5, max: 10 }" style="margin-top: 16px" />
                        <UIInput
                            type="text"
                            labelText="length 2-4 (alphabetic)"
                            v-validation.length="{ msg: 'length error', min: 2, max: 4 }"
                            v-validation.alphabetic="{ msg: 'alphabetic error' }"
                            style="margin-top: 16px"
                        />

                        <UIInput type="text" labelText="email" v-validation.email style="margin-top: 16px" v-must />

                        <UIInput
                            type="text"
                            labelText="mask amount"
                            v-mask.amount="{ pattern: '***.***.**', format: 'xxx.xxx.xx' }"
                            style="margin-top: 16px"
                            data-mask="asd"
                        />
                    </UIForm>
                    <UIButton text="send" className="success" @click.native="handlerFormValid" />

                    <!-- <b-field label="Select a date">
                        <b-datepicker
                            v-model="selected"
                            :locale="locale"
                            placeholder="Click to select..."
                            :icon-right="selected ? 'close-circle' : ''"
                            :min-date="minDate"
                            icon-right-clickable
                            @icon-right-click="clearDate"
                            trap-focus>
                        </b-datepicker>
                    </b-field> -->
                </div>
            </div>
            <GtsDocumentViewer v-if="isDocumentViewerOpen" />
        </div>
    </div>
</template>

<script>
import GtsDocumentViewer from "@/components/utils/GtsDocumentViewer.vue";
import moment from "moment";
import UIForm from "@/components/unitary/form/Form.vue";
import UIInput from "@/components/unitary/form/Input.vue";
import UIButton from "@/components/unitary/Button.vue";
// import { thisFormIsValid } from "@/directives/gts-validation";

export default {
    name: "RegisterPage",
    components: {
        GtsDocumentViewer,
        UIForm,
        UIInput,
        UIButton,
    },
    data() {
        return {
            selected: moment().add(3, "day")._d,
            locale: "de-DE",
            minDate: moment().add(-3, "day")._d,
            requestModel: {
                email: "cagatay.unal@gtsfly.de",
                password: "1",
            },
            imageUrls: [
                "https://dev-agent.gtsfly.de/img/loginPhotos/01.jpg",
                "https://dev-agent.gtsfly.de/img/loginPhotos/02.jpg",
                "https://dev-agent.gtsfly.de/img/loginPhotos/03.jpg",
            ],
            currentImage: 1,
            isDocumentViewerOpen: false,
        };
    },
    methods: {
        clearDate() {
            this.selected = null;
        },
        handlerFormValid() {
            // Validation.thisFormIsValid('validation-form') ? console.log('form valid') : console.log('form valid değil');
            // console.log(thisFormIsValid("validation-form"));
        },
    },
};
</script>

<style lang="scss" scoped>
html body .register-page-wrapper {
    .register-page-inner {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
        background-color: white;

        .page-section {
            width: 47.5%;
            height: 100%;
            &.page-image {
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
            &.page-form {
                display: inline-flex;
                align-items: flex-start;
                justify-content: flex-start;

                & > .register-form-inner {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    justify-content: flex-start;
                    width: 50%;
                }
            }
        }
    }
}
</style>
