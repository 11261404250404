<template>
   <div class="reissue-calculator-container">
      <!-- {{ calculator.result }} -->
      <div class="reissue-calculator-wrapper" :class="calculator.result?.success == false ? 'error' : ''">
         <div id="reissue-calculator-informations-wrapper">
            <div class="reissue-calculator-icon"></div>
            <div class="reissue-calculator-texts">
               <template v-if="calculator.result?.success != false">
                  <div class="reissue-calculator-title">{{ $t("ancillarReissue_lblCalculateTitle") }}</div>
                  <div class="reissue-calculator-description">{{ $t("ancillarReissue_lblCalculateDescription") }}</div>
               </template>
               <template v-else>
                  <div class="reissue-calculator-title">{{ $t("ancillarReissue_lblCalculatingFailedTitle") }}</div>
                  <template v-if="calculator.result.subCode == 'FLIGHT_SAME_DAY'">
                     <div class="reissue-calculator-description">
                        {{ $t(`ancillarReissue_lblCalculatingFailedDescription${calculator.result.subCode}`) }}
                     </div>
                  </template>
                  <template v-else>
                     <div class="reissue-calculator-description">
                        {{ $t(`ancillarReissue_lblCalculatingFailedDescription`) }}
                     </div>
                  </template>
               </template>
            </div>
         </div>
         <div id="reissue-calculator-button-wrapper">
            <button @click="handlerCalculateReissue" v-if="calculator.result?.success != false">
               <span v-if="calculator.calculating == false">{{ $t("ancillarReissue_btnCalculateReissue") }}</span>
               <span v-else class="g-radial-loader"></span>
            </button>

            <!-- <button @click="handlerCalculateReissue">Calculate Reissue</button> -->
         </div>
      </div>
   </div>
</template>

<script>
export default {
   name: "AncillaryReissueCalculator",
   props: {
      calculator: Object,
   },
   components: {},
   data() {
      return {};
   },
   methods: {
      handlerCalculateReissue() {
         this.$emit("calculate");
      },
   },
};
</script>

<style lang="scss" scoped>
.reissue-calculator-container {
   width: 100%;
   display: flex;
   justify-content: center;
   margin-top: 1.5rem;
   .reissue-calculator-wrapper {
      background-color: #e8fafa;
      border: 1.5px solid #12b3b3;
      border-radius: 1rem;
      width: 65%;
      height: 82px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 1rem;

      #reissue-calculator-informations-wrapper {
         display: flex;
         .reissue-calculator-icon {
            width: 78px;
            height: 48px;
            background-repeat: no-repeat;
            background-size: contain;
            background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI0OCIgaGVpZ2h0PSI0NS4xNzciIHZpZXdCb3g9IjAgMCA0OCA0NS4xNzciPgogIDxnIGlkPSJHcm91cF8xMTc2MSIgZGF0YS1uYW1lPSJHcm91cCAxMTc2MSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTUyMCAtNDg1KSI+CiAgICA8cGF0aCBpZD0iVW5pb25fNDAiIGRhdGEtbmFtZT0iVW5pb24gNDAiIGQ9Ik0wLDE5LjVBMTkuNSwxOS41LDAsMSwxLDE5LjUsMzksMTkuNSwxOS41LDAsMCwxLDAsMTkuNVpNMTIuOTk0LDQuMUExNi43NzIsMTYuNzcyLDAsMSwwLDE5LjUsMi43ODYsMTYuNjM2LDE2LjYzNiwwLDAsMCwxMi45OTQsNC4xWm00LjEyMiwyMy45ODRhMi4wNzEsMi4wNzEsMCwwLDEsMi4xOTItMi4xLDIuMDM3LDIuMDM3LDAsMCwxLDIuMTksMi4xLDEuOTkxLDEuOTkxLDAsMCwxLTIuMTksMi4wNEExLjk5MywxLjk5MywwLDAsMSwxNy4xMTcsMjguMDgzWm0uOTczLTQuOWMtLjEyMSwwLS4xODItLjA2Mi0uMjEzLS4xODQtLjA1OS0uODUzLS41NDctOC0uNTQ3LTEwLjU2VjEwLjI1YS4zMjcuMzI3LDAsMCwxLC4xNTItLjMzNiw1LjI3Niw1LjI3NiwwLDAsMSwxLjgyNy0uMzY1LDUuOTU2LDUuOTU2LDAsMCwxLDEuODU2LjI3NC4zODEuMzgxLDAsMCwxLC4xODIuMzM0djIuMTkxYzAsMi41NTYtLjUxNiw5LjczOS0uNjA4LDEwLjY1MiwwLC4wOTItLjAzMS4xODQtLjIxMy4xODRaIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg1MjAgNDg1KSIgZmlsbD0iIzEyYjNiMyIvPgogICAgPHBhdGggaWQ9IkVsbGlwc2VfMjk2IiBkYXRhLW5hbWU9IkVsbGlwc2UgMjk2IiBkPSJNMTcuMS0uNjM0QzI5LjU4NCwzLjAxOSwzMi4zNzIsOC4wODQsMzQuNTg4LDE2Ljg1N1MyNi41LDMwLjIxMywxNy4xLDM0LjM0Ny0zLjk0OSwzMi4xNjgtLjM5NCwxNi44NTcsNC42MS00LjI4NywxNy4xLS42MzRaIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg1MzMuMTA4IDQ5NC41OTEpIiBmaWxsPSIjMTJiM2IzIiBvcGFjaXR5PSIwLjE3NiIvPgogIDwvZz4KPC9zdmc+Cg==");
         }
         .reissue-calculator-texts {
            display: flex;
            flex-direction: column;
            font-size: 0.9rem;

            .reissue-calculator-title {
               font-weight: 600;
            }
         }
      }
      #reissue-calculator-button-wrapper {
         button {
            background-color: #12b3b3;
            border-radius: 24rem;
            font-weight: 600;
            color: #fff;
            padding: 0.75rem 1.25rem;
            white-space: nowrap;
            min-width: 154px;
            height: 42px;
         }
      }

      &.error {
         background-color: #fff4f4;
         border: 1.5px solid #f03434;

         .reissue-calculator-icon {
            background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI0OCIgaGVpZ2h0PSI0NS4xNzciIHZpZXdCb3g9IjAgMCA0OCA0NS4xNzciPgogIDxnIGlkPSJHcm91cF8xMTc2MSIgZGF0YS1uYW1lPSJHcm91cCAxMTc2MSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTUyMCAtNDg1KSI+CiAgICA8cGF0aCBpZD0iVW5pb25fNDAiIGRhdGEtbmFtZT0iVW5pb24gNDAiIGQ9Ik0wLDE5LjVBMTkuNSwxOS41LDAsMSwxLDE5LjUsMzksMTkuNSwxOS41LDAsMCwxLDAsMTkuNVpNMTIuOTk0LDQuMUExNi43NzIsMTYuNzcyLDAsMSwwLDE5LjUsMi43ODYsMTYuNjM2LDE2LjYzNiwwLDAsMCwxMi45OTQsNC4xWm00LjEyMiwyMy45ODRhMi4wNzEsMi4wNzEsMCwwLDEsMi4xOTItMi4xLDIuMDM3LDIuMDM3LDAsMCwxLDIuMTksMi4xLDEuOTkxLDEuOTkxLDAsMCwxLTIuMTksMi4wNEExLjk5MywxLjk5MywwLDAsMSwxNy4xMTcsMjguMDgzWm0uOTczLTQuOWMtLjEyMSwwLS4xODItLjA2Mi0uMjEzLS4xODQtLjA1OS0uODUzLS41NDctOC0uNTQ3LTEwLjU2VjEwLjI1YS4zMjcuMzI3LDAsMCwxLC4xNTItLjMzNiw1LjI3Niw1LjI3NiwwLDAsMSwxLjgyNy0uMzY1LDUuOTU2LDUuOTU2LDAsMCwxLDEuODU2LjI3NC4zODEuMzgxLDAsMCwxLC4xODIuMzM0djIuMTkxYzAsMi41NTYtLjUxNiw5LjczOS0uNjA4LDEwLjY1MiwwLC4wOTItLjAzMS4xODQtLjIxMy4xODRaIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg1MjAgNDg1KSIgZmlsbD0iI2YwMzQzNCIvPgogICAgPHBhdGggaWQ9IkVsbGlwc2VfMjk2IiBkYXRhLW5hbWU9IkVsbGlwc2UgMjk2IiBkPSJNMTcuMS0uNjM0QzI5LjU4NCwzLjAxOSwzMi4zNzIsOC4wODQsMzQuNTg4LDE2Ljg1N1MyNi41LDMwLjIxMywxNy4xLDM0LjM0Ny0zLjk0OSwzMi4xNjgtLjM5NCwxNi44NTcsNC42MS00LjI4NywxNy4xLS42MzRaIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg1MzMuMTA4IDQ5NC41OTEpIiBmaWxsPSIjZjAzNDM0IiBvcGFjaXR5PSIwLjE3NiIvPgogIDwvZz4KPC9zdmc+Cg==") !important;
         }
      }
   }
}
</style>
