<template>
   <div class="hotel-filters-container">#hotelfilterscontainer</div>
</template>

<script>
export default {
   name: "HotelFilters",
   created() {
      // console.log("HotelFilters component created");
   },
};
</script>

<style></style>
