<template>
   <div class="rent-a-car-time-picker-container gts-input-form" v-click-outside="handlerOutClick">
      <!-- Input Wrapper -> Main form -->
      <div class="gts-input-wrapper" @click="handlerDropdown">
         <i class="gts-icon icon-rent-a-car-timepicker icon-sm"></i>
         <span class="input-value">{{ value }}</span>
      </div>

      <div class="gts-input-dropdown-wrapper" v-if="dropdownStatus">
         <template v-for="(time, index) in times">
            <div class="gts-input-dropdown-item" :key="index" @click="handlerSelectTime(time)" :class="time == value ? 'highlight' : ''">
               <span> {{ time }} </span>
            </div>
         </template>
      </div>
   </div>
</template>

<script>
import moment from "moment";
export default {
   name: "RentACarTimePicker",
   props: {
      value: String,
      minTime: [Object, String],
   },
   data() {
      return {
         times: [],
         dropdownStatus: false,
      };
   },
   created() {
      this.handlerGenerateDropdown();
   },
   methods: {
      handlerGenerateDropdown() {
         this.times = [];
         if (this.minTime != null || this.minTime != undefined) {
            const minHour = moment(this.minTime, "HH:mm").format("HH");
            const minMinutes = moment(this.minTime, "HH:mm").format("mm");

            // console.log("handlerGenerateDropdown", minMinutes);
            for (let i = 0; i <= 23; i++) {
               let timeOne = `${String(i).padStart(2, "0")}:00`;
               let timeTwo = `${String(i).padStart(2, "0")}:30`;

               if (timeOne >= minHour) {
                  if (minMinutes < "00" && timeOne != minHour) {
                     this.times.push(timeOne);
                  }

                  this.times.push(timeTwo);
               }
            }
         } else {
            for (let i = 0; i <= 23; i++) {
               let timeOne = `${String(i).padStart(2, "0")}:00`;
               let timeTwo = `${String(i).padStart(2, "0")}:30`;
               this.times.push(timeOne);
               this.times.push(timeTwo);
            }
         }
      },
      handlerDropdown() {
         this.dropdownStatus = !this.dropdownStatus;
      },
      handlerSelectTime(time) {
         this.$emit("time", time);
         this.handlerDropdown();
      },
      handlerOutClick(e) {
         const datePickerControll = e.srcElement.className == "input-value" || e.srcElement.className == "gts-input-wrapper";

         if (this.value != null || datePickerControll) {
            this.dropdownStatus = false;
         }
      },
   },
};
</script>

<style lang="scss" scoped>
.rent-a-car-time-picker-container {
   min-width: 96px;
   span.input-value {
      font-size: var(--normal);
   }

   .gts-input-dropdown-wrapper {
      // display: none;
      animation-name: dropdown;
      animation-timing-function: ease-in-out;
      animation-duration: 0.15s;
      position: absolute;
      right: 0;
      // padding: 32px 16px;
      // padding: 5px;
      border-radius: 8px;
      // background-clip: padding-box;
      background-color: #fff;
      top: 100%;
      width: 100%;
      box-shadow: rgba(0, 0, 0, 0.2) 0px 10px 30px 0px;
      z-index: 99;
      height: 326px;
      overflow-y: scroll;

      .gts-input-dropdown-item {
         cursor: pointer;
         span {
            display: block;
            padding: 0.25rem 0.5rem;
         }
         &:hover {
            background-color: #e4e4e4;
         }
         &.highlight {
            background-color: #e6fff7;
            font-weight: 800;
            color: #1fcc92;
         }
      }
   }
}
</style>
