<template>
   <div class="flight-filter-leg-container gts-box gts-custom-scroll" :class="filterStatus ? 'more-whitespace' : ''">
      <!-- HEADER -->
      <!-- {{ Object.keys(filterOptions.filters.airlines).length }} -->
      <div class="flight-filter-leg-header-wrapper" @click="filterStatus = !filterStatus">
         <!-- FILTER HEADER  -->
         <div class="flight-filter-leg-title-wrapper">
            <i class="gts-icon icon-filter icon-sm"></i>
            <h5>{{ filterOptions.routes }}</h5>
         </div>
         <!-- LEG TOGGLER -->
         <div class="flight-filter-action-wrapper">
            <button class="flight-filter-leg-toggler">
               <i class="gts-icon icon-arrow-down icon-xs"></i>
            </button>
         </div>
      </div>

      <div class="flight-filter-leg-body-container" v-if="filterStatus">
         <!-- AIRLINES -->
         <div class="filter-wrapper">
            <div class="filter-title-wrapper">
               <span class="filter-title">{{ $t("searchFiltersFlight_airlines") }}</span>
               <a @click="handleSelectAll(filterOptions.filters.airlines, 'airlines')" v-if="selectAll.airlines">
                  {{ SelectAllText.selectAll }}
               </a>
               <a @click="handleClearAll(filterOptions.filters.airlines, 'airlines')" v-else>
                  {{ SelectAllText.clearAll }}
               </a>
            </div>
            <div class="checkbox-container">
               <UICheckBox
                  v-for="(type, index) in filterOptions.filters.airlines"
                  :key="index"
                  :label="enumConversion('airlines', type.airlineName)"
                  :id="type.airlineName"
                  :name="type.airlineName"
                  v-model="type.checked" />
            </div>
         </div>

         <!-- CONNECTIONS -->
         <div class="filter-wrapper">
            <div class="filter-title-wrapper">
               <span class="filter-title">{{ $t("searchFiltersFlight_connections") }}</span>
               <a @click="handleSelectAll(filterOptions.filters.connections, 'connections')" v-if="selectAll.connections">
                  {{ SelectAllText.selectAll }}
               </a>
               <a @click="handleClearAll(filterOptions.filters.connections, 'connections')" v-else>
                  {{ SelectAllText.clearAll }}
               </a>
            </div>
            <div class="checkbox-container">
               <UICheckBox
                  v-for="(type, index) in filterOptions.filters.connections"
                  :key="index"
                  :label="enumConversion('connections', type.connectionName)"
                  :id="type.name"
                  :name="type.name"
                  v-model="type.checked" />
            </div>
         </div>

         <!-- BAGGAGE ALLOWANCE -->
         <div class="filter-wrapper">
            <div class="filter-title-wrapper">
               <!-- <span class="filter-title">{{ $t("searchFiltersFlight_baggageAllowance") }}</span> -->
               <span class="filter-title">{{ $t("Baggage") }}</span>
               <a @click="handleSelectAll(filterOptions.filters.baggage, 'baggage')" v-if="selectAll.baggage">
                  {{ SelectAllText.selectAll }}
               </a>
               <a @click="handleClearAll(filterOptions.filters.baggage, 'baggage')" v-else>
                  {{ SelectAllText.clearAll }}
               </a>
            </div>
            <div class="checkbox-container">
               <UICheckBox
                  v-for="(type, index) in filterOptions.filters.baggage"
                  :key="index"
                  :label="enumConversion('baggage', type.name)"
                  :id="type.name"
                  :name="type.name"
                  v-model="type.checked" />
            </div>
         </div>

         <!-- CABIN TYPE -->
         <div class="filter-wrapper">
            <div class="filter-title-wrapper">
               <span class="filter-title">{{ $t("searchFiltersFlight_cabinTypes") }}</span>
               <a @click="handleSelectAll(filterOptions.filters.cabintype, 'cabintype')" v-if="selectAll.cabintype">
                  {{ SelectAllText.selectAll }}
               </a>
               <a @click="handleClearAll(filterOptions.filters.cabintype, 'cabintype')" v-else>
                  {{ SelectAllText.clearAll }}
               </a>
            </div>
            <div class="checkbox-container">
               <UICheckBox
                  v-for="(type, index) in filterOptions.filters.cabintype"
                  :key="index"
                  :label="enumConversion('cabintype', type.type)"
                  :id="type.type"
                  :name="type.type"
                  v-model="type.checked" />
            </div>
         </div>

         <!-- DEPARTURE -->
         <div class="filter-wrapper">
            <div class="filter-title-wrapper">
               <span class="filter-title">{{ $t("searchFiltersFlight_departureHour") }}</span>
            </div>
            <div class="hours-container">
               <div class="hour-viewers-wrapper">
                  <span>{{ handlerHourFormat(filterOptions.filters.hours.departure[0]) }}</span>
                  <span>{{ handlerHourFormat(filterOptions.filters.hours.departure[1]) }}</span>
               </div>

               <b-field>
                  <b-slider v-model="filterOptions.filters.hours.departure" :min="0" :max="24" :step="1" lazy :custom-formatter="customFormatter">
                  </b-slider>
               </b-field>
            </div>
         </div>

         <!-- ARRIVAL -->
         <div class="filter-wrapper">
            <div class="filter-title-wrapper">
               <span class="filter-title">{{ $t("searchFiltersFlight_arrivalHour") }}</span>
            </div>
            <div class="hours-container">
               <div class="hour-viewers-wrapper">
                  <span>{{ handlerHourFormat(filterOptions.filters.hours.arrival[0]) }}</span>
                  <span>{{ handlerHourFormat(filterOptions.filters.hours.arrival[1]) }}</span>
               </div>

               <b-field>
                  <b-slider v-model="filterOptions.filters.hours.arrival" :min="0" :max="24" :step="1" lazy :custom-formatter="customFormatter">
                  </b-slider>
               </b-field>
            </div>
         </div>
      </div>
   </div>
</template>

<script>
import UICheckBox from "@/components/unitary/form2/UICheckBox.vue";
import moment from "moment";
export default {
   name: "FlightFilterLeg",
   props: {
      filterOptions: Object,
   },
   components: {
      UICheckBox,
   },
   data() {
      return {
         filterStatus: false,
         selectAll: {
            connections: false,
            cabintype: false,
            airlines: false,
            baggage: false,
         },
      };
   },

   computed: {
      SelectAllText() {
         return {
            selectAll: this.$i18n.t("searchFiltersFlight_selectAll"),
            clearAll: this.$i18n.t("searchFiltersFlight_deselectAll"),
         };
      },
   },

   methods: {
      enumConversion(filterType, filterName) {
         switch (filterType) {
            case "airlines":
               return this.$i18n.t(`airlineEnum_${filterName}`);
            case "connections":
               return filterName <= 0 ? this.$i18n.t("searchFiltersFlight_noneStop") : `${filterName} ${this.$i18n.t("searchFiltersFlight_stop")}`;
            case "cabintype":
               return this.$options.filters.EFltCabinType(filterName);
            case "baggage":
               return filterName;
         }
      },
      handleClearAll(el, name) {
         Object.keys(el).forEach((flight) => {
            el[flight].checked = false;
         });
         this.selectAll[name] = !this.selectAll[name];
      },
      handleSelectAll(el, name) {
         Object.keys(el).forEach((flight) => {
            el[flight].checked = true;
         });
         this.selectAll[name] = !this.selectAll[name];
      },

      customFormatter(val) {
         if (val != 24) {
            return moment().hours(val).format("HH:00");
         } else {
            return "24:00";
         }
      },
      handlerHourFormat(val) {
         if (val != 24) {
            return moment(val, "hh").format("HH:00");
         } else {
            return "24:00";
         }
      },
   },
};
</script>

<style lang="scss" scoped>
.flight-filter-leg-container {
   padding: 1rem 0.75rem;
   transition-property: padding;
   transition-timing-function: ease-in;
   transition-duration: 0.15s;

   &.more-whitespace {
      padding: 2rem 0.75rem;
   }
   .flight-filter-leg-header-wrapper {
      display: flex;
      justify-content: space-between;
      .flight-filter-leg-title-wrapper {
         display: flex;
         align-items: center;
         width: 100px;
         i {
            flex-shrink: 0;
         }
         h5 {
            font-weight: 600;
            margin-left: 0.5rem;
         }
      }
      .flight-filter-action-wrapper {
         button.flight-filter-leg-toggler {
            $square: 32px;
            width: $square;
            height: $square;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
         }
      }
   }

   .flight-filter-leg-body-container {
      max-height: 75vh;
      overflow-y: auto;
      overflow-x: hidden;
      padding: 0 0.25rem;
      .filter-wrapper {
         border-bottom: 1px solid #dcdcdc;
         margin: 0.5rem 0;
         padding: 0.5rem 0.25rem;

         .filter-title-wrapper {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 0.75rem;
            span.filter-title {
               font-weight: 500;
               font-size: var(--small);
            }
            a {
               font-size: 0.75rem;
               font-weight: 500;
               color: var(--accent-primary);
            }
         }
         .checkbox-container {
            .g-checkbox-container {
               margin-bottom: 0.5rem;
               font-size: var(--small);
            }
         }

         .hours-container {
            .hour-viewers-wrapper {
               display: flex;
               justify-content: space-between;
               span {
                  border-radius: 0.25rem;
                  border: 1px solid #ccc;
                  padding: 0.125rem 0.25rem;
                  font-weight: 500;
                  background: #eee;
               }
            }
         }

         &:last-child {
            border-bottom: none !important;
         }
      }
   }
}
</style>

<style lang="scss">
.hours-container {
   .b-checkbox {
      display: flex;
      align-items: flex-start !important;

      input[type="checkbox"]:checked + .check {
         background-color: #f8f8f8 !important;
         background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNSIgdmlld0JveD0iMCAwIDE2IDE1Ij48ZyB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMzQ1Ljc1OCAtMTM3NykiPjxwYXRoIGQ9Ik0wLDEzLjVhMS40OTMsMS40OTMsMCwwLDEtLjgzMS0uMjUyLDEuNSwxLjUsMCwwLDEtLjQxNi0yLjA4bDgtMTJhMS41LDEuNSwwLDAsMSwyLjA4LS40MTZBMS41LDEuNSwwLDAsMSw5LjI0OC44MzJsLTgsMTJBMS41LDEuNSwwLDAsMSwwLDEzLjVaIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgzNTIuMjU4IDEzNzguNSkiIGZpbGw9IiM0YTc4YjciLz48cGF0aCBkPSJNNSw1YTEuNDkzLDEuNDkzLDAsMCwxLS44NTktLjI3MWwtNS0zLjVBMS41LDEuNSwwLDAsMS0xLjIyOS0uODYsMS41LDEuNSwwLDAsMSwuODYtMS4yMjlsNSwzLjVBMS41LDEuNSwwLDAsMSw1LDVaIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgzNDcuMjU4IDEzODcpIiBmaWxsPSIjNGE3OGI3Ii8+PC9nPjwvc3ZnPg==) !important;
         background-size: 12px !important;
         background-position: center;
         border-color: var(--primary) !important;
         box-shadow: none;
         outline: none;
         border-width: 1.5px !important;
      }
      input[type="checkbox"] + .check {
         background-color: #f8f8f8 !important;
         border: 1.5px solid #e2e2e2 !important;
         background-size: 12px !important;
         background-position: center;
         box-shadow: none;
         outline: none;
      }
      input[type="checkbox"]:focus + .check {
         box-shadow: none !important;
         outline: none;
      }

      .control-label {
         font-size: 0.85rem;
      }
   }

   .hours-container {
      display: flex;
      justify-content: space-between;
      margin: 0.5rem 0rem;

      span {
         background-color: #dde5ee;
         padding: 0.25rem 0.75rem;
         border-radius: 24rem;
         font-size: 0.85rem;
         font-weight: 500;
      }
   }
   .b-slider {
      &.is-primary {
         .b-slider-fill {
            background-color: var(--pastel-primary) !important;
         }
         .tooltip-content {
            background-color: var(--accent-primary) !important;
            font-weight: 500;

            &:before {
               border-top-color: var(--accent-primary) !important;
            }
         }
      }
      .b-slider-thumb {
         border-radius: 50% !important;
         border: 1px solid #fff !important;
         background: var(--pastel-primary) !important;
      }
   }
}
</style>
