<template>
   <div class="rent-a-car-date-time-picker-container">
      <!-- #development -->

      <label>{{ label }}</label>
      <div class="rent-a-car-date-inputs-container">
         <div class="rent-a-car-date-inputs-wrapper">
            <!-- <div class="rent-a-car-datepicker-wrapper">{{ date }} - {{ time }}</div> -->
            <div class="rent-a-car-datepicker-wrapper">
               <RentACarDatePicker v-model="date" @date="handlerSetDate" :min-date="MinDate" />
            </div>
            <div class="rent-a-car-timepicker-wrapper">
               <RentACarTimePicker v-model="time" @time="handlerSetTime" ref="timePicker" :min-time="MinTime" />
            </div>
         </div>
         <div class="g-form-warning-badge" v-if="!ValidationStatus">{{ $t("common_cannotBeEmpty") }}</div>
      </div>
   </div>
</template>

<script>
import moment from "moment";

import RentACarDatePicker from "@/components/structural/rentacar/search/search-forms/RentACarDatePicker.vue";
import RentACarTimePicker from "@/components/structural/rentacar/search/search-forms/RentACarTimePicker.vue";

export default {
   name: "RentACarDateTimePicker",
   props: {
      label: String,
      date: String,
      time: String,
      isValid: Boolean,
      identifier: Number,
      minDate: String,
      sibling: {
         type: [String, Boolean],
         default: false,
      },
   },
   components: {
      RentACarDatePicker,
      RentACarTimePicker,
   },
   data() {
      return {
         validated: false,
         minTime: null,
      };
   },
   computed: {
      MinDate() {
         return this.identifier == 2
            ? moment(this.minDate, "YYYY-MM-DD").format(this.$constants.dateFormat)
            : moment().add(1, "d").format(this.$constants.dateFormat);
      },
      MinTime() {
         return this.minTime;
      },
      ValidationStatus() {
         let validation = [this.isValid, this.validated];

         return validation.some((i) => {
            return i;
         });
      },
   },

   methods: {
      handlerSetDate(date) {
         this.$emit("update-date", date);
         if (this.time == null && this.sibling == false) {
            this.$refs.timePicker.dropdownStatus = true;
         }
         if (this.time == null && typeof this.sibling == "string") {
            this.$refs.timePicker.dropdownStatus = true;
         }
      },
      handlerSetTime(time) {
         this.$emit("update-time", time);
         this.validated = true;
      },
   },
   watch: {
      date: {
         handler: function (val) {
            val;
            if (this.identifier == 1) {
               const isToday = val > moment().add(1, "d").format(this.$constants.dateFormat) ? false : true;
               if (isToday) {
                  let h = moment().format("HH");
                  let m = moment().format("mm");

                  if (moment().format("mm") > 30) {
                     h = moment(h, "HH").add(1, "h").format("HH");
                  }

                  this.minTime = `${h}:${m}`;
                  // console.log("date watcher", val);
               } else {
                  this.minTime = null;
               }
               this.$nextTick(() => {
                  this.$refs.timePicker.handlerGenerateDropdown();
               });
            }
         },
         immediate: true,
      },
   },
};
</script>
<style lang="scss">
.gts-input-form {
   display: flex;
   flex-direction: column;
   position: relative;
   border: 1px solid #ccc;
   // padding: 1rem 0.75rem;
   background: #f8f8f8;
   border-radius: 0.5rem;
   height: 48px;
   cursor: pointer;
   // background: red;

   .gts-input-wrapper {
      display: flex;
      align-items: center;
      padding: 0 0.75rem;
      width: 100%;
      height: 100%;
      // background: red;
      i {
      }
      span.input-value {
         font-weight: 500;
         margin-left: 0.5rem;
      }
   }
   .gts-input-dropdown-wrapper {
      position: absolute;
      top: 100%;
   }

   @keyframes dropdown {
      from {
         opacity: 0;
         top: 110%;
      }
      to {
         opacity: 1;
         top: 100%;
      }
   }
}
</style>
<style lang="scss" scoped>
.rent-a-car-date-time-picker-container {
   display: flex;
   flex-direction: column;
   width: 100%;

   label {
      font-weight: 500;
      margin-bottom: 0.5rem;
      min-height: 29px;
   }

   .rent-a-car-date-inputs-container {
      display: flex;
      flex-direction: column;
      position: relative;
      // border: 1px solid #ccc;
      // padding: 0.5rem;
      .rent-a-car-date-inputs-wrapper {
         display: flex;
         width: 100%;

         .rent-a-car-datepicker-wrapper {
            width: 100%;
            margin-right: 1rem;

            ::v-deep {
               .gts-input-form {
                  // border: 1px solid red;
               }
            }
         }
         .rent-a-car-timepicker-wrapper {
            max-width: 100px;
            ::v-deep {
               .gts-input-form {
                  // border: 1px solid red;
               }
            }
         }
      }

      .g-form-warning-badge {
         position: absolute;
         top: 100%;
      }
   }
}
</style>
<style lang="scss" scoped>
.rent-a-car-date-container2 {
   display: flex;
   flex-direction: column;

   label {
      font-weight: 500;
      margin-bottom: 0.5rem;
   }
   .inputs-wrapper {
      display: flex;
      flex-direction: row;

      section {
         // width: 100%;
         // height: 100%;
         // border: 1px solid #ccc;
         // background: #f8f8f8;
         // border-radius: 0.5rem;
         // height: 48px;
         // position: relative;
         // display: flex;
         // align-items: center;
         // padding-left: 0.5rem;

         &:first-child {
            margin-right: 1rem;
         }
         i {
            width: 20px !important;
            margin-right: 0.25rem;
         }

         &.rent-a-car-datepicker-wrapper {
            // max-width: 150px !important;
            .input-click-mapping {
               cursor: pointer;
               background: transparent !important;
               display: flex;
               width: 100%;
               height: 100%;
               align-items: center;
               span {
                  font-size: var(--normal);
                  // font-weight: 500;
                  color: var(--txt-black);
                  padding-left: 0.25rem;
               }
            }
            .datepicker-wrapper {
               display: flex;
               width: 100%;
               height: 100%;
               position: absolute;
               left: 0;
               top: 100%;
            }
         }

         &.rent-a-car-timepicker-wrapper {
            max-width: 80px !important;
         }

         span {
            font-size: var(--small);
         }
      }
   }
}
</style>
