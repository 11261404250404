const state = {
   isRoutedDirection: false,
};

const mutations = {
   setIsRoutedDirection(state, value) {
      state.isRoutedDirection = value;
   },
};

const actions = {
   updateIsRoutedDirection({ commit }, value) {
      commit("setIsRoutedDirection", value);
   },
};

const getters = {
   getIsRoutedDirection: (state) => state.isRoutedDirection,
};

export const direction = {
   namespaced: true,
   state,
   mutations,
   actions,
   getters,
};
