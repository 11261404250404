<template>
   <div id="reservation-information-wrapper">
      <Badge2 :msg="BadgeFMT(data.exPnr, data.exPnrOrderId, TaskId)" error v-if="showBadge" />

      <div class="reservation-information-header-wrapper">
         <span class="section-title">{{ $t("orderDetail_titleReservationInfo") }}</span>

         <div class="pdf-action-buttons-wrapper">
            <template v-if="data.exPnr != null">
               <button @click="funcRedirection(`/order/detail/${data.exPnrOrderUuid}?pnrId=${data.exPnrId}`)" id="expnr">
                  <i></i>
                  <div class="item-wrapper">
                     <span class="item-explanation">{{ $t("orderDetail_exOrderId") }}</span>
                     <span
                        >{{ data.exPnrOrderId }} <strong>({{ data.exPnr }})</strong></span
                     >
                  </div>
               </button>
            </template>
            <template v-if="data.status == 3">
               <button id="document-eticket" :class="eticketLoading ? 'loading' : ''" @click="handlerDocumentViewer(1)">
                  <span>{{ $t("orderDetail_btnEticket") }}</span>
                  <span class="g-radial-loader" v-if="eticketLoading"></span>
                  <i class="gts-icon icon-eticket" v-else></i>
               </button>
               <button id="document-booking-confirmation" :class="bookingConfirmationLoading ? 'loading' : ''" @click="handlerDocumentViewer(4)">
                  <span>{{ $t("orderDetail_btnBookingConfirmation") }}</span>
                  <span class="g-radial-loader" v-if="bookingConfirmationLoading"></span>
                  <i class="gts-icon icon-booking-confirmation" v-else></i>
               </button>
            </template>

            <template v-if="data.status == 2">
               <button id="document-eticket" :class="eticketLoading ? 'loading' : ''" @click="handlerDocumentViewer(1)">
                  <span>{{ $t("orderDetail_btnReserved") }}</span>
                  <span class="g-radial-loader" v-if="eticketLoading"></span>
                  <i class="gts-icon icon-eticket" v-else></i>
               </button>
            </template>
         </div>
      </div>

      <div id="information-table-wrapper" class="masked-background" v-if="false">
         <table id="information-table">
            <thead>
               <tr>
                  <th>
                     <span>{{ $t("orderDetail_thStatus") }}</span>
                  </th>
                  <th>
                     <span class="limited-width">{{ $t("orderDetail_thProvider") }}</span>
                  </th>
                  <th>
                     <span>{{ $t("orderDetail_thPnr") }}</span>
                  </th>
                  <th>
                     <span>{{ $t("orderDetail_thAirlinePnr") }}</span>
                  </th>
                  <th>
                     <span>{{ $t("orderDetail_thReservationDateTime") }}</span>
                  </th>
                  <th>
                     <span>{{ $t("orderDetail_thLastPurchaseDateTime") }}</span>
                  </th>
                  <th>
                     <span>{{ $t("orderDetail_thTicketDateTime") }}</span>
                  </th>
               </tr>
            </thead>
            <tbody>
               <tr>
                  <td>
                     <span class="masked">{{ $options.filters.EFltPnrStatus(data.status) }} </span>
                  </td>
                  <td>
                     <span class="limited-width masked">{{ data.providerName }}</span>
                  </td>
                  <td>
                     <span class="masked">{{ data.pnr }}</span>
                  </td>
                  <td>
                     <span class="masked">{{ data.airlinePnr }}</span>
                  </td>
                  <td>
                     <div class="date-container">
                        <span class="masked">{{ data.reserveDateTime | fullDateTextFormat }}</span>
                        <span class="masked">{{ data.reserveDateTime | TimeFormat }}</span>
                        <span class="masked">{{ data.reserveDateTime | onlyDayFullName }}</span>
                     </div>
                  </td>
                  <td>
                     <div class="date-container">
                        <span class="masked">{{ data.lastTicketDateTime | fullDateTextFormat }}</span>
                        <span class="masked">{{ data.lastTicketDateTime | TimeFormat }}</span>
                        <span class="masked">{{ data.lastTicketDateTime | onlyDayFullName }}</span>
                     </div>
                  </td>
                  <td>
                     <div class="date-container">
                        <span class="masked">{{ data.ticketDateTime | fullDateTextFormat }}</span>
                        <span class="masked">{{ data.ticketDateTime | TimeFormat }}</span>
                        <span class="masked">{{ data.ticketDateTime | onlyDayFullName }}</span>
                     </div>
                  </td>
               </tr>
            </tbody>
         </table>
      </div>

      <div class="reservation-information-inner-container" :class="{ reservable: reservable, reprice: reprice }">
         <ul>
            <li>
               <span class="reservation-information-table-title">{{ $t("orderDetail_thStatus") }}</span>
               <span>{{ $options.filters.EFltPnrStatus(data.status) }}</span>
            </li>
            <li>
               <span class="reservation-information-table-title">{{ $t("orderDetail_thProvider") }}</span>
               <span>{{ data.providerName }}</span>
            </li>
            <li>
               <span class="reservation-information-table-title">{{ $t("orderDetail_thPnr") }}</span>
               <span>{{ data.pnr }}</span>
            </li>
            <li>
               <span class="reservation-information-table-title">{{ $t("orderDetail_thAirlinePnr") }}</span>
               <span>{{ data.airlinePnr }} - </span>
            </li>
            <li>
               <span class="reservation-information-table-title">{{ $t("orderDetail_thReservationDateTime") }}</span>
               <div class="date-container">
                  <span class="masked">{{ data.reserveDateTime | fullDateTextFormat }}</span>
                  <span class="masked">{{ data.reserveDateTime | TimeFormat }}</span>
                  <span class="masked">{{ data.reserveDateTime | onlyDayFullName }}</span>
               </div>
            </li>
            <li>
               <span class="reservation-information-table-title">{{ $t("orderDetail_thLastPurchaseDateTime") }}</span>
               <div class="date-container" :class="{ dateExpired: showReoption }">
                  <span class="masked">{{ data.lastTicketDateTime | fullDateTextFormat }}</span>
                  <span class="masked">{{ data.lastTicketDateTime | TimeFormat }}</span>
                  <span class="masked">{{ data.lastTicketDateTime | onlyDayFullName }}</span>
               </div>
            </li>
            <li>
               <span class="reservation-information-table-title">{{ $t("orderDetail_thTicketDateTime") }}</span>
               <div class="date-container">
                  <span class="masked">{{ data.ticketDateTime | fullDateTextFormat }}</span>
                  <span class="masked">{{ data.ticketDateTime | TimeFormat }}</span>
                  <span class="masked">{{ data.ticketDateTime | onlyDayFullName }}</span>
               </div>
            </li>
         </ul>
      </div>

      <div class="reservable-pnr-wrapper" v-if="showReoption">
         <div class="helper-button-wrapper reservable-btn" @mouseenter="reservable = true" @mouseleave="reservable = false" @click="funcReReserve()">
            <i class="gts-icon icon-notification-list icon-renew icon-sm"></i>

            <template v-if="data.status == 2">
               <span> {{ $t("orderDetail_btnReReserve_lblRezerve") }} </span>
            </template>
            <template v-else>
               <span> {{ $t(`orderDetail_btnReReserve_lblDefault`) }} </span>
            </template>
         </div>

         <!-- New -->
         <div
            v-if="false"
            class="helper-button-wrapper reprice-btn"
            @mouseenter="reprice = true"
            @mouseleave="reprice = false"
            @click="funcReReserve()">
            <i class="gts-icon icon-notification-list icon-renew icon-sm"></i>
            <span>
               {{ $t(`orderDetail_btnReReserve_lblReoption`) }}
            </span>
         </div>
      </div>
   </div>
</template>

<script>
import Popup from "@/gts-popup.js";
import Badge2 from "@/components/unitary/Badge2.vue";

import { generateUuid } from "@/utils.js";
import { flightService } from "@/service/index.js";

export default {
   name: "ReservationInformation",
   components: { Badge2 },
   props: {
      data: Object,
      status: Number,
      showReoption: Boolean,
   },
   data() {
      return {
         showBadge: false,
         eticketLoading: false,
         bookingConfirmationLoading: false,
         reservable: false,
         reprice: false,
      };
   },
   computed: {
      TaskId() {
         return this.$router.history.current.query?.task;
      },
   },
   methods: {
      BadgeFMT(pnrId, orderId, taskId) {
         return this.$t("orderDetailFlight_txtTaskMessage").replace("#PNRID#", pnrId).replace("#ORDERID#", orderId).replace("#TASKID#", taskId);
      },
      handlerDocumentViewer(type) {
         const self = this;
         if (type == 1) {
            self.eticketLoading = true;
            self.$store.state.app.documentViewer.request.showPrice = false;
            self.$store.state.app.documentViewer.hasPrice = true;
         } else {
            self.bookingConfirmationLoading = true;
            self.$store.state.app.documentViewer.request.showPrice = false;
            self.$store.state.app.documentViewer.hasPrice = false;
         }
         self.$store.state.app.documentViewer.request.documentType = type;
         self.$store.state.app.documentViewer.request.fileType = 2;
         self.$store.state.app.documentViewer.activeProductStatus = self.data.status;
         self.$store.commit("app/setDocumentViewer", true);
      },
      funcRedirection(url, reload) {
         const isMobile = this.$store.state.app.clientInformations.isMobile;
         if (isMobile) {
            this.$router.push(url);
         } else {
            window.open(url);
         }
         if (reload) {
            setTimeout(() => {
               window.location.reload();
            }, 1000);
         }
      },
      funcReReserve() {
         let request = {
            uuid: generateUuid(),
            orderUuid: this.$store.state.order.orderUuid,
            pnrId: this.data.id,
         };
         let msg = this.$i18n.t(`${this.$options.name}_progressGif_ReReserveFlight`);
         this.$store.commit("app/showProgressGifMsg", msg);

         flightService.flightReReserve(request).then((response) => {
            this.$store.commit("app/hideProgressGif");
            response.service = "RERESERVE_FLIGHT";
            const checkoutUrl = `/flight/checkout/${response.issueSearchUuid}?refresh=${response.issueCheckoutUuid}&pnr=${this.data.id}`;

            if (response.result.success) {
               if (response.checkoutEnabled) {
                  this.funcRedirection(checkoutUrl);
                  return;
               } else if (response.reservationEnabled) {
                  Popup.priceChange(
                     this.$i18n.t("reservationInfo_lblPopupPriceChangeTitle"),
                     undefined,
                     this.$options.filters.price(response.oldPrice),
                     this.$options.filters.price(response.newPrice),
                     this.$options.filters.price(response.priceDiff),
                     response.reservationEnabled,
                     undefined
                  );

                  this.$nextTick(() => {
                     const reserveitButton = document.body.querySelector("#reserveit");

                     if (reserveitButton) {
                        document.body.querySelector("#reserveit").addEventListener("click", () => {
                           this.$store.commit("app/showProgressGif");
                           const issueCheckoutUuid = response.issueCheckoutUuid;
                           const query = {
                              issueCheckoutUuid,
                              uuid: generateUuid(),
                              orderUuid: this.$store.state.order.orderUuid,
                              pnrId: this.data.id,
                           };

                           flightService.flightReReserve(query).then((response) => {
                              this.$store.commit("app/hideProgressGif");
                              response.service = "RERESERVE_FLIGHT";
                              if (response.result.success) {
                                 const addTaskId = response.taskId > 0;
                                 const urlGenerator = addTaskId
                                    ? `/order/detail/${response.newOrderUuid}?task=${response.taskId}`
                                    : `/order/detail/${response.newOrderUuid}`;
                                 this.funcRedirection(urlGenerator, true);
                              } else {
                                 Popup.result(response.result);
                              }
                           });
                        });
                     }
                  });
               }
            } else {
               Popup.result(response.result);
            }
         });
      },
   },
   watch: {
      "$store.state.app.documentViewer.isLoaded": {
         handler: function (status) {
            if (status) {
               this.eticketLoading = false;
               this.bookingConfirmationLoading = false;
            }
         },
      },
      $router: {
         handler: function (val) {
            const isTaskExist = val.history.current.query?.task ? true : false;
            if (isTaskExist) {
               this.showBadge = true;
            }
         },
         immediate: true,
      },
   },
};
</script>

<style lang="scss" scoped>
#reservation-information-wrapper {
   ::v-deep {
      .ui-badge-container {
         margin-bottom: 2rem;
      }
   }
   .reservation-information-header-wrapper {
      display: flex;
      flex-direction: column;

      margin-bottom: 1.5rem;

      @media (min-width: 576px) {
         flex-direction: row;
         justify-content: space-between;
         align-items: center;
      }

      .section-title {
         margin-bottom: 0 !important;
      }

      .pdf-action-buttons-wrapper {
         display: flex;

         button {
            margin: 0 0.5rem;
            height: 44px;
            display: flex;
            align-items: center;
            border-radius: 24rem;
            padding-left: 1.75rem;
            padding-right: 1.125rem;
            font-size: var(--large);
            font-weight: 600;
            transform: scale(1);
            transition-property: transform;
            transition-timing-function: ease-in;
            transition-duration: 0.05s;

            i {
               margin-left: 1rem;
            }

            &:last-child {
               margin-right: 0 !important;
            }

            &#expnr {
               background: #c17df2;
               padding: 0 1rem !important;
               outline: 3px solid #ecdef6;
               border: 1px solid #fff;

               &:hover {
                  background: #ab4fee;
               }

               i {
                  background-image: url("data:image/svg+xml;base64,ICA8c3ZnIHdpZHRoPSIzNiIgaGVpZ2h0PSIzNiIgdmlld0JveD0iMCAwIDI0IDI0IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgogICAgICAgICAgICAgICA8cGF0aAogICAgICAgICAgICAgICAgICBkPSJNMjIuNyAxMy41TDIwLjcwMDUgMTEuNUwxOC43IDEzLjVNMjEgMTJDMjEgMTYuOTcwNiAxNi45NzA2IDIxIDEyIDIxQzcuMDI5NDQgMjEgMyAxNi45NzA2IDMgMTJDMyA3LjAyOTQ0IDcuMDI5NDQgMyAxMiAzQzE1LjMwMTkgMyAxOC4xODg1IDQuNzc4MTQgMTkuNzU0NSA3LjQyOTA5TTEyIDdWMTJMMTUgMTQiCiAgICAgICAgICAgICAgICAgIHN0cm9rZT0iI2ZmZmYiCiAgICAgICAgICAgICAgICAgIHN0cm9rZS13aWR0aD0iMS41IgogICAgICAgICAgICAgICAgICBzdHJva2UtbGluZWNhcD0icm91bmQiCiAgICAgICAgICAgICAgICAgIHN0cm9rZS1saW5lam9pbj0icm91bmQiIC8+CiAgICAgICAgICAgIDwvc3ZnPg==");
                  background-repeat: no-repeat;
                  background-size: contain;
                  display: inline-block;
                  width: 28px;
                  height: 28px;
                  margin: 0 !important;
                  margin-right: 0.75rem !important;
               }
               .item-wrapper {
                  display: flex;
                  flex-direction: column;
                  text-align: left;
                  line-height: 16px;
                  color: #fff;
                  padding-right: 1rem;
                  span.item-explanation {
                     font-size: var(--xsmall);
                     font-weight: 400;
                  }

                  span {
                     strong {
                        color: #fff;
                        font-size: var(--small);
                        font-weight: 400;
                     }
                  }
               }
            }

            &#document-eticket {
               background-color: #fff;
               border: 2px solid var(--primary);
               color: var(--primary);

               &:hover {
                  background-color: var(--primary);
                  border: 2px solid var(--primary);
                  color: #fff;

                  i {
                     filter: brightness(10);
                  }
               }
            }

            &#document-booking-confirmation {
               background-color: var(--success);
               color: #2d4c77;
            }

            &:hover {
               transform: scale(1.1);
            }

            &.loading {
               background-color: var(--accent-primary) !important;
               border: 2px solid var(--accent-primary) !important;
               transform: scale(1.1);
               color: #fff !important;

               .g-radial-loader {
                  margin-left: 1rem;
               }
            }
         }
      }
   }
   #information-table-wrapper {
      background-color: #f3f3f3;
      border: 1px solid #cccccc;
      width: 100%;
      border-radius: 16px;
      padding: 1.75rem;
      #information-table {
         width: 100%;

         thead {
            th {
               text-align: center;
               padding: 1rem 2rem;
               vertical-align: middle;
               span {
                  font-size: var(--small);
                  opacity: 0.8;
               }
            }
         }
         tbody {
            border-top: 1px solid #ccc;
            td {
               text-align: center;
               padding: 1rem 2rem;
               span {
                  font-weight: 600;
               }
               .date-container {
                  display: flex;
                  text-align: center;
                  flex-direction: column;
               }
            }
         }
      }
   }

   .reservation-information-inner-container {
      background-color: #f3f3f3;
      border: 1px solid #cccccc;
      width: 100%;
      border-radius: 16px;
      padding: 1.75rem 0.75rem;

      @media (min-width: 992px) {
         padding: 1.75rem 1.75rem;
      }

      ul {
         display: flex;
         flex-wrap: wrap;

         @media (min-width: 576px) {
            display: grid;
            grid-template-columns: repeat(4, 1fr) repeat(3, 1.5fr);
         }

         li {
            width: 50%;
            display: flex;
            flex-direction: column;
            margin-bottom: 1.125rem;

            @media (min-width: 576px) {
               width: 100%;
               margin-bottom: 0;
            }

            span {
               font-size: var(--large);
               font-weight: 600;
               @media (min-width: 576px) {
                  font-size: var(--small);
               }

               &.reservation-information-table-title {
                  font-size: var(--small);
                  opacity: 0.8;
                  font-weight: 400;
                  border-bottom: 1px solid #ccc;
                  padding-bottom: 0.5rem;
                  margin-bottom: 0.5rem;
               }
            }
            .date-container {
               display: flex;
               flex-direction: column;
               position: relative;

               &.dateExpired {
                  color: #f15a73;
                  transform: scale(1.2);
                  transform-origin: left top;
                  font-weight: 800;
                  &::before {
                     // content: "!";
                  }
               }
            }
         }
      }

      &.reservable {
         position: relative;
         border: 1px solid #47d88f;
         background-color: #f9fffc;
      }
      &.reprice {
         position: relative;
         border: 1px solid #6233ff;
         background-color: #6233ff17;
      }
   }

   .reservable-pnr-wrapper {
      display: flex;
      justify-content: flex-end;

      .helper-button-wrapper {
         display: flex;
         width: fit-content;
         // background: #4ce699;

         padding: 0.5rem 1.5rem;
         border-radius: 0.5rem;
         border-top-left-radius: 0;
         border-top-right-radius: 0;
         margin-right: 1rem;
         cursor: pointer;
         transition-duration: 0.2s;
         transition-timing-function: ease-out;
         transform-origin: top right;

         &.reservable-btn {
            background: #39dc8b;
         }
         &.reprice-btn {
            background: #6233ff;
         }

         i {
            transform: rotate(0deg);
            transition-duration: 0.2s;
            transition-timing-function: ease-out;
         }

         &:hover {
            &.reservable-btn {
               background: #47d88f;
            }

            transform: scale(1.05);
            i {
               transform: rotate(180deg);
            }
         }
         span {
            color: #fff;
            font-size: var(--large);
            font-weight: 500;
            margin-left: 0.5rem;
            line-height: 1.5rem;
         }
      }
   }
}
</style>
