export const ERacAncillaryType = {
   "-1": "ALL",
   0: "UNKNOWN",
   1: "BABY_SEAT",
   2: "NAVIGATION",
   3: "INTERNET",
   4: "SNOW_TIRE",
   5: "SNOW_CHAIN",
   6: "INSURANCE_ECO",
   7: "INSURANCE_PREMIUM",
   21: "EXTRA_DAMAGE",
   22: "EXTRA_PICKUP_DROPOFF",
   23: "EXTRA_TRAFFIC_PENALTY",
   51: "MANDAT_ONEWAY_TRANSFER",
};

export const ERacAncillaryEnums = {
   "-1": "ALL",
   0: "UNKNOWN",
   1: "BABY_SEAT",
   2: "NAVIGATION",
   3: "INTERNET",
   4: "SNOW_TIRE",
   5: "SNOW_CHAIN",
   6: "INSURANCE_ECO",
   7: "INSURANCE_PREMIUM",
   21: "EXTRA_DAMAGE",
   22: "EXTRA_PICKUP_DROPOFF",
   23: "EXTRA_TRAFFIC_PENALTY",
   51: "MANDAT_ONEWAY_TRANSFER",
};

export const ERacAncillaryTypeIcon = {
   1: "baby-seat",
   2: "navigation",
   3: "mobil-internet",
   4: "snow-tires",
   5: "snow-chain",
   6: "insurance-eco",
   7: "insurance-pro",
   21: "extra-damage",
   22: "extra-pickup-dropoff",
   23: "extra-traffic-penalty",
   51: "mandat-oneway-transfer",
};

export const ERacPassengerCapacity = {
   "-1": "ALL",
   0: "UNKNOWN",
   3: "3",
   5: "5",
   7: "7",
   9: "9",
};

export const ERacGearType = {
   "-1": "ALL",
   0: "UNKNOWN",
   1: "MANUEL",
   2: "AUTOMATIC",
};

export const ERacFuelType = {
   "-1": "ALL",
   0: "UNKNOWN",
   1: "DIESEL",
   2: "GASOLINE",
   3: "ELECTRIC",
};

export const ERacBodyType = {
   "-1": "ALL",
   0: "UNKNOWN",
   1: "SEDAN",
   2: "HATCHBACK",
   3: "SUV",
   4: "MINIVAN",
   5: "VAN",
};

export const ERacFacilityType = {
   "-1": "ALL",
   0: "UNKNOWN",
   1: "FREE_CANCELLATION",
   2: "UNLIMITED_MILEAGE",
   3: "AIRPORT_SURCHARGE",
   4: "COLLISION_DAMAGE_WAIVER",
   5: "THEFT_WAIVER",
   6: "THIRD_PARTY_LIABILITY_INSURANCE",
};

export const ERacRnrStatus = {
   "-1": "ALL",
   0: "UNKNOWN",
   1: "NEW",
   2: "RESERVED",
   3: "ISSUED",
   4: "CANCELED",
   5: "VOIDED",
   6: "REFUNDED",
   7: "REISSUED",
   8: "ERROR",
   9: "OPEN",
   10: "PENDING",
};
export const EOdrSearchRentACarDateType = {
   "-1": "ALL",
   0: "UNKNOWN",
   1: "PROCESS",
   2: "PICKUP",
};

export const EReportRentACarDateType = {
   "-1": "ALL",
   0: "UNKNOWN",
   1: "SALE",
   2: "PICKUP",
};

export const EReportRentACarReportType = {
   "-1": "ALL",
   0: "UNKNOWN",
   1: "VEHICLE",
   2: "ANCILLARY",
};
