<template>
   <div class="widget-extras-card-container" v-if="status">
      <button class="card-navigation-arrow" :class="rows.length < 4 ? 'navigation-disabled' : ''" @click="handlerPrev()">
         <i class="gts-icon icon-arrow-left" :class="rows.length < 4 ? '' : 'gts-animation'"></i>
      </button>

      <!-- Table Cards Wrapper -->

      <Section class="table-cards-wrapper" :class="'card-count-' + rows.length">
         <template v-for="(item, index) in Cards">
            <div class="table-card" :key="index" :class="item.highlightDate ? 'highlighted-date' : ''" @click="handlerRoute($event, item)">
               <div class="table-card-header">
                  <!-- DD MMM YYYY  -->

                  <div class="table-card-header-date-wrapper">
                     <div class="date-dd">
                        <span> {{ item.dateTime | FormatterDD }}</span>
                     </div>
                     <div class="date-mm-yy">
                        <span> {{ item.dateTime | FormatterMMM }}</span>
                        <span> {{ item.dateTime | FormatterYYYY }}</span>
                     </div>
                  </div>

                  <!-- HH:mm -->
                  <div class="table-card-header-time-wrapper">
                     <span class="txt-black">{{ item.dateTime | FormatterTime }}</span>
                  </div>
               </div>
               <div class="table-card-body">
                  <span>{{ item.paxFullName }}</span>
                  <!-- Is onlineCheckInUrl Exist? We can understand the type of table widget -->

                  <span class="widget-td-online-checkin online-checkin-router" :class="item.onlineCheckInUrl ? 'show' : ''">
                     {{ $t("gtsDataTableComponent_checkIn") }}
                  </span>
               </div>
               <div class="table-card-footer">
                  <span class="txt-black"> {{ item.entity.trimmer(7) }}</span>
                  <button class="table-card-footer-action">
                     <i class="gts-icon icon-arrow-full icon-sm"></i>
                  </button>
               </div>
            </div>
         </template>
      </Section>
      <button
         class="card-navigation-arrow"
         :class="rows.length < 4 ? 'navigation-disabled' : ''"
         @click="rows.length > 3 ? handlerNext() : undefined">
         <i class="gts-icon icon-arrow-right" :class="rows.length < 4 ? '' : 'gts-animation'"></i>
      </button>
   </div>
</template>

<script>
export default {
   name: "Card",
   props: {
      status: Boolean,
      rows: [Object, Array],
   },
   data() {
      return {
         pagination: {
            enabled: true,
            perPage: 3,
            activepage: 1,
         },
      };
   },

   created() {
      if (this.$constants.isMobile) {
         this.pagination.perPage = 2;
      } else if (window.innerWidth > 2270) {
         this.pagination.perPage = 4;
      }
   },

   computed: {
      Cards() {
         let sliceFrom = this.pagination.activepage - 1;
         let sliceTo = this.pagination.activepage;
         let perPage = this.pagination.perPage;
         return this.rows.slice(perPage * sliceFrom, perPage * sliceTo);
      },
   },
   methods: {
      handlerRoute(ev, row) {
         // console.log(ev, row);
         if (ev.target.classList.contains("online-checkin-router")) {
            window.open(row.onlineCheckInUrl, "_blank");
         } else {
            window.open(`/order/detail/${row.orderUuid}`);
         }
      },
      handlerPrev() {
         if (this.pagination.activepage != 1) {
            this.pagination.activepage--;
         }
      },
      handlerNext() {
         if (parseInt(this.rows.length / 3) != this.pagination.activepage) {
            this.pagination.activepage++;
         }
      },
   },
};
</script>

<style lang="scss" scoped>
.widget-extras-card-container {
   display: flex;
   justify-content: space-between;
   margin-top: 1rem;
   margin-bottom: 1.5rem;
   padding-bottom: 1.5rem;
   border-bottom: 1px solid #ccc;
   button.card-navigation-arrow {
      background: transparent;

      &.navigation-disabled {
         opacity: 0.2;
      }
   }
   section.table-cards-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      width: 100%;

      &.card-count-2 {
         width: 60%;
      }
      .table-card {
         width: 136px;
         // height: 238px !important;
         border: 1px solid #c6cdd8;
         border-radius: 0.25rem 1.5rem 0.25rem 1.5rem;
         display: flex;
         flex-direction: column;
         justify-content: space-between;
         margin: 0 0.5rem;
         $childPadding: 0.5rem 0.5rem;
         transition-property: transform;
         transition-timing-function: ease-in-out;
         transition-duration: 0.05s;
         cursor: pointer;

         @media (max-width: 1200px) {
            width: 138px;
         }

         &:hover {
            // transform: scale(1.05);
         }

         .table-card-header {
            display: flex;
            align-items: flex-end;
            justify-content: space-between;
            padding: $childPadding;

            padding-bottom: 0;
            .table-card-header-date-wrapper {
               display: flex;

               .date-dd {
                  font-size: 28px;
                  font-weight: 600;
               }
               .date-mm-yy {
                  display: flex;
                  flex-direction: column;
                  line-height: 12px;
                  font-size: 0.75rem;
                  justify-content: center;
                  margin-left: 0.25rem;
               }
            }
            .table-card-header-time-wrapper {
               top: -8px;
               position: relative;
            }
         }

         .table-card-body {
            display: flex;
            flex-direction: column;
            padding: 0 0.5rem;
            line-height: 16px;

            span {
               font-size: var(--small);
            }

            .widget-td-online-checkin {
               font-size: 0.8rem;
               font-weight: 600;
               color: var(--primary);
               text-decoration: underline !important;
               height: 22px;
               opacity: 0;
               margin-top: 0.5rem;

               &.show {
                  opacity: 1;
               }
            }
         }

         .table-card-footer {
            background-color: #00000020;
            border-bottom-left-radius: 1.5rem;
            border-bottom-right-radius: 0.25rem;
            padding: $childPadding;
            padding-left: 0.75rem;
            padding-right: 0.75rem;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 0.125rem;

            span {
               font-size: var(--small);
            }

            .table-card-footer-action {
               border-radius: 0.5rem;
               padding: 0.25rem 0.75rem;
               background: #ffffff90;
               transition: all;

               &:hover {
                  background: #ffffff;
               }
            }
         }

         &.highlighted-date {
            background-color: #ff5959;
            border: none !important;

            .table-card-header,
            .table-card-body,
            .table-card-footer {
               color: #fff;

               .widget-td-online-checkin {
                  color: #fff;
               }
            }
         }
      }
   }
}
</style>
