<template>
   <div id="pickup-location-container" :class="dropoffLocation ? 'dropoff-location' : ''">
      <!-- Labels -->

      <div class="pickup-location-label-wrapper">
         <label class="pickup-label">{{ $t("rentacar_lblPickupLocaton") }}</label>
         <label class="drop-off-label" v-if="dropoffLocation" @click="handlerDropOffLocation">{{ $t("rentacar_lblDropoffLocation") }}</label>
         <label class="add-drop-off" v-else @click="handlerDropOffLocation">{{ $t("rentacar_lblAddDropoffLocation") }}</label>

         <!-- <div class="drop-off-highlighter">Add Dropoff Location</div> -->
      </div>
      <!-- Inputs -->

      <div class="pickup-location-input-wrapper">
         <div class="pickup-location-form">
            <!-- :isValid="isValid?.pickUp" -->
            <RentACarLocationInput
               :isValid="isValid"
               :identifier="1"
               @clear-input="
                  (val) => {
                     handlerClearInput(val, 1);
                  }
               " />
         </div>
         <div class="dropoff-location-form" v-if="dropoffLocation">
            <RentACarLocationInput
               :isValid="isValid"
               :identifier="2"
               @clear-input="
                  (val) => {
                     handlerClearInput(val, 2);
                  }
               " />
         </div>
      </div>
   </div>
</template>

<script>
import RentACarLocationInput from "@/components/structural/rentacar/search/search-forms/RentACarLocationInput.vue";

export default {
   name: "PickupLocation",
   components: {
      RentACarLocationInput,
   },
   props: {
      isValid: Boolean,
   },
   data() {
      return {
         dropoffLocation: false,
      };
   },

   methods: {
      handlerClearInput(status, identifier) {
         // console.log(status, identifier);
         if (status && identifier == 1) {
            // this.$parent.isValid.pickUp = false;
            this.$parent.query.pickUpLocationId = null;
         }
         if (status && identifier == 2) {
            // this.$parent.isValid.dropOff = false;
            this.$parent.query.dropOffLocationId = null;
         }
      },
      handlerDropOffLocation() {
         this.dropoffLocation = !this.dropoffLocation;
         // console.log(this.$parent);
         if (this.dropoffLocation) {
            this.$parent.query.dropOffLocationId = null;
            // this.$parent.isValid.dropOff = false;
         } else {
            // this.$parent.isValid.dropOff = true;
            this.$parent.query.dropOffLocationId = null;
         }
      },
   },
};
</script>

<style lang="scss" scoped>
#pickup-location-container {
   display: flex;
   flex-direction: column;

   .pickup-location-label-wrapper {
      position: relative;
      display: flex;
      justify-content: space-between;
      margin-bottom: 0.5rem;

      label {
         font-weight: 500;
         &.drop-off-label {
            cursor: pointer;
         }

         $color: #363636;

         @keyframes dropoffAnimation {
            0% {
               background-color: #fff;
               color: $color;
            }

            100% {
               background-color: #fff;
               color: var(--accent-primary);
            }
         }

         &.add-drop-off {
            cursor: pointer;
            background-color: $color;
            color: #fff;
            padding: 0.25rem 1rem;
            font-weight: 500;
            font-size: var(--small);
            text-decoration: underline;
            border-radius: 0.25rem;
            animation-name: dropoffAnimation;
            animation-iteration-count: infinite;
            animation-duration: 1s;
         }
         &.add-drop-off2 {
            cursor: pointer;
            color: var(--accent-primary);
            font-weight: 500;
            font-size: var(--small);
            text-decoration: underline;
         }
      }
      .drop-off-highlighter {
         position: absolute;
         right: 0;
         background-color: #363636;
         color: #fff;
         font-weight: 500;
         padding: 0.5rem 1rem;
         border-radius: 0.25rem;
         bottom: 100%;
      }
   }

   .pickup-location-input-wrapper {
      display: grid;
      grid-template-columns: 1fr;

      .pickup-location-form,
      .dropoff-location-form {
         width: 100%;
         border: 1px solid #ccc;
         background: #f8f8f8;
         border-radius: 0.5rem;
         height: 48px;
         position: relative;
      }
   }

   &.dropoff-location {
      .pickup-location-input-wrapper,
      .pickup-location-label-wrapper {
         display: grid;
         grid-template-columns: 1fr 1fr;
         grid-column-gap: 1rem;
      }
   }
}
</style>
